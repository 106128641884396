/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { IEndpoint, Companion } from "companion";
import { LocalizationService } from "../../localization/localization.service";

@Component({
  selector: "notepad",
  templateUrl: "./notepad.component.html"
})

/**
 * notepad view
 */
export class NotepadComponent implements OnInit {
  @Input()
  endpoint: IEndpoint;
  @Output("exit")
  exitEventEmitter: EventEmitter<void> = new EventEmitter<void>();
  viewModel: any = {
    id: "",
    note: "",
    editorTheme: "snow",
    editorModules: {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image"]
      ]
    }
  };
  constructor(public localizationService: LocalizationService) {}
  getlatestNote() {
    if (this.endpoint && _.isEmpty(this.viewModel.note)) {
      this.viewModel.id = "notepad-" + this.endpoint.rtcId;
      let defaultTemplate: string = this.localizationService.myLocalizationData.notepad.template || "";
      defaultTemplate = defaultTemplate.replace(/\[GUEST_NAME\]/, this.endpoint.name);
      defaultTemplate = defaultTemplate.replace(/\[OPERATOR_NAME\]/, Companion.getEndpointService().myEndpoint.name);
      defaultTemplate = defaultTemplate.replace(/\[GUEST_FORM_DATA\]/, this.endpoint.lastMessage || "");
      this.viewModel.note = this.endpoint.note || defaultTemplate;
    }
  }
  ngOnInit() {
    this.getlatestNote();
    let getLatestNoteTimer = setTimeout(() => {
      clearTimeout(getLatestNoteTimer);
      this.getlatestNote();
    }, 3 * 1000);
  }
  contentChanged(change: any) {
    this.endpoint.note = this.viewModel.note;
    Companion.getRTCService().sendServerMessage("note", { rtcId: this.endpoint.rtcId, note: this.viewModel.note });
  }
  exit() {
    this.exitEventEmitter.emit();
  }
  insertImageWithBlobData(data: Blob) {
    var reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = () => {
      let base64data = reader.result;
      this.viewModel.note += "<img src='" + base64data + "' />";
    };
  }
}
