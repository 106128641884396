/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  jporter
 */
/**
 * File Sharing Component
 */
import { Component, Input, AfterViewInit, NgZone, ViewChild, ElementRef} from "@angular/core";
import { IRTCService, ISharedFile, Companion, IEndpoint, FileTransferStatus, IActiveConference, ConferenceService, ISharedFileRef } from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { RestService } from "client/scripts/shared/services/rest.service";

@Component({
  selector: "sharedfolder-panel",
  templateUrl: "./sharedfolder-panel.component.html"
})
export class SharedFolderPanelComponent implements AfterViewInit {
  /**
   * current conference joined
   */
  @Input() conference: IActiveConference;

  /**
   * my current endpoint
   */
  myEndpoint: IEndpoint = Companion.getEndpointService().myEndpoint;

  /**
   * RTCService from Companion Lib
   */
  rtcService: IRTCService = Companion.getRTCService();

  /**
   * file transfer status class for template use
   */
  public FileTransferStatus = FileTransferStatus;

  @ViewChild("fileLoaderInput", { static: false })
  private fileLoader : ElementRef;

  /**
   * flag if upload shall be enabled
   */
  get uploadEnabled(): boolean {
    let result: boolean = true;
    if (
      this.localizationService.myLocalizationData.toolbar.toolbar_items["sharedFolder"] &&
      this.localizationService.myLocalizationData.toolbar.toolbar_items["sharedFolder"].hostOnly &&
      !this.myEndpoint.userId
    ) {
      result = false;
    } else if (
      this.localizationService.myLocalizationData.toolbar.toolbar_items["sharedFolder"] &&
      this.localizationService.myLocalizationData.toolbar.toolbar_items["sharedFolder"].guestOnly &&
      this.myEndpoint.userId
    ) {
      result = false;
    }
    return result;
  }
  
  isInConf(): boolean {
    return !!Companion.getConferenceService().currentActiveConference;
  }

  /**
   * get percentage of transmit
   */
  getPercentOfTransmit(file: ISharedFile): string {
    let result : number|string = Math.round((file.transmittedSize / file.fileSize) * 100);
    // Squidge it to always be same width (3 chars.)
    if (result < 10) {
      result = "  " + result.toString();
    } else if (result < 100) {
      result = " " + result.toString();
    }
    return result.toString();
  }

  constructor(public localizationService: LocalizationService, private restService: RestService, private zone: NgZone) {
    // nothing needed here
  }

  getLocalFiles() : ISharedFile[] {
    if (ConferenceService.getSharedInstance()?.sharedFiles?.size) {
      return Array.from(ConferenceService.getSharedInstance().sharedFiles.values());
    } else {
      return [];
    }    
  }

  getOfferedFiles(): ISharedFileRef[] {
    return ConferenceService.getSharedInstance().currentActiveConference?.filesOffered || [];
  }

  /**
   * after view init event handler
   */
  ngAfterViewInit() {
    if (this.uploadEnabled) {
      Companion.getSharedFileService().buildDragNDropRegion(jQuery("#sharedFolderModal")[0]);
    }
  }

  /**
   * download file
   */
  download(sharedFile: ISharedFile) {
    Companion.getRTCService().downloadFile(sharedFile.fileBlob, sharedFile.filename);
  }

  /**
   * close the modal window
   */
  exit(): void {
    jQuery("#sharedFolderModal").modal("hide");
  }

  /**
   * new file selected to upload
   */
  fileChangeHandler(fileInput: any) {
    if (fileInput) {
      var files: FileList = fileInput.target.files;
      Companion.getSharedFileService().newFilesUploadedHandler(files);
      this.fileLoader.nativeElement.value = null;
    }
  }

  /**
   * get endpoint name by rtcId
   */
  getEndpointNameById(rtcId: string): string {
    let result: string = "";
    let ep = Companion.getEndpointService().getEndpointById(rtcId);
    if (ep) {
      result = ep.name;
    }
    return result;
  }

  isMyFile(fileRef: ISharedFileRef) {
    return fileRef?.ownerId == this.myEndpoint.rtcId;
  }

  offer(file: ISharedFile) {
    let activeConf = Companion.getConferenceService().currentActiveConference;
    if (activeConf) {
      // Proceed to offer this to the server.
      this.restService
      .post("/offerSharedFile", {confId: activeConf.id, fileRef: file.toRef()})
      .subscribe({
        next: () => {
          console.log("File offered!");
        },
        error: () => {
          console.log("Failed to offer file!");
        }
      });
    }
  }

  withdraw(fileRef: ISharedFileRef) {
    let activeConf = Companion.getConferenceService().currentActiveConference;
    if (activeConf) {
      // Proceed to withdraw this to the server.
      this.restService
      .post("/withdrawSharedFile", {confId: activeConf.id, fileRef: fileRef})
      .subscribe({
        next: () => {
          console.log("File withdrawed!");
        },
        error: () => {
          console.log("Failed to withdraw file!");
        }
      });
    }
  }

  request(fileRef: ISharedFileRef) {
    let activeConf = Companion.getConferenceService().currentActiveConference;
    if (activeConf) {
      // Proceed to request this file from the owner (via the server).
      this.restService
      .post("/requestSharedFile", {requestorId: this.myEndpoint.rtcId, fileRef: fileRef})
      .subscribe({
        next: () => {
          console.log("File requested!");
        },
        error: () => {
          console.log("Failed to request file!");
        }
      });
    }
  }

  requestAll() {
    let activeConf = Companion.getConferenceService().currentActiveConference;
    if (activeConf) {
      // Issue a request for every available file that is not mine!
      _.forEach((activeConf.filesOffered), (fileRef: ISharedFileRef) => {
        if (fileRef.ownerId != this.myEndpoint.rtcId) {
          // Proceed to request this file from the owner (via the server).
          this.restService
          .post("/requestSharedFile", {requestorId: this.myEndpoint.rtcId, fileRef: fileRef})
          .subscribe({
            next: () => {
              console.log("File requested!");
            },
            error: () => {
              console.log("Failed to request file!");
            }
          });
        }
      });
    }
  }

  remove(fileRef: ISharedFileRef) {
    Companion.getConferenceService().removeSharedFile(fileRef.id);
  }
}
