/**
 * Copyright Compunetix Incorporated 2017-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta, kbender
 */

import {RestService} from "../shared/services/rest.service";
import {Injectable} from "@angular/core";
import {ITreeViewItem, TreeViewItem} from "../shared/components/tree-view/tree-item";
import {AlertService} from "../alert/alert.service";
import {
  AlertCode,
  AlertLevel,
  IAgent,
  IAgentsStatus,
  ICallsCurrentStatus,
  ICallsDailyStatus,
  ICustomer,
  ICustomerStatus,
  ITrendsData,
  Companion,
  IFilter,
  ISkillTags,
  AsyncTaskStatus
} from "companion";
import { format, fromUnixTime } from 'date-fns';

export interface IDashboardApi {
  data: any;
  lastUpdate: string;
}

export interface ICallsDailyStatusApi extends IDashboardApi {
  data: ICallsDailyStatus;
}

export interface ICallsCurrentStatusApi extends IDashboardApi {
  data: ICallsCurrentStatus;
}


export interface ICustomersCallsApi extends IDashboardApi {
  data: {
    calls: ITrendsData,
    customersInRange: ICustomer[]
  };
}

export interface ICustomersCurrentStatusApi extends IDashboardApi {
  data: {
    customers: ICustomer[],
    customersStatus: ICustomerStatus
  };
}

export interface IAgentsCallsApi extends IDashboardApi {
  data: ITrendsData;
}

export interface IAgentsCurrentStatusApi extends IDashboardApi {
  data: {
    agents: IAgent[],
    agentsStatus: IAgentsStatus
  };
}

export interface AsyncTaskCallbacks {
  taskId: string,
  completeFn: (data: any) => void,
  errorFn: () => void
}

@Injectable()
export class DashboardService {
  constructor(private restService: RestService,
              private alertService: AlertService) {
  }

  isLoading: boolean = false;
  selectedGroup: TreeViewItem;
  isAuthenticated = false;
  clientRefreshRate: number;
  activeAsyncTasks: AsyncTaskCallbacks[] = [];
  asyncTaskChecker: any;
  completedTasks: string[] = [];

  static getTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Simply filter out of the active tasks any taskIds that have completed.
   * Then clear the completed list.
   */
  private clearCompletedAsyncTasks(): void {
    this.activeAsyncTasks = this.activeAsyncTasks.filter((async: AsyncTaskCallbacks) => {
      return !this.completedTasks.some(e => e === async.taskId);
    });
    this.completedTasks = [];
  }

  /**
   * This operation creates a promise promise chain for all active tasks and registers the completion
   * callbacks and updates the tracking data accordingly. After creating the check promises
   * it will clear any completed tasks and check if any remain. If they do, the timer will
   * recreated by calling the same function again after clearing the timer reference.
   */
  private processAsyncCheckTimeout() : void {
    this.asyncTaskChecker = "in-progress"; // Set this to something while we wait.
    let checks: Promise<any>[] = [];
    this.activeAsyncTasks.forEach((async: AsyncTaskCallbacks) => {
      // Check task status
      console.log("Checking task: " + async.taskId);
      checks.push(this.fetchAsyncTaskStatus(async.taskId)
      .then((status: AsyncTaskStatus) => {
        switch (status) {
          case AsyncTaskStatus.pending:
            // do nothing
            return Promise.resolve();
          break;
          case AsyncTaskStatus.failed:
            async.errorFn();
            this.completedTasks.push(async.taskId);
            return Promise.resolve();
          break;
          case AsyncTaskStatus.complete:
            // do complete case
            return this.fetchAsyncTaskData(async.taskId)
            .then((data) => {
              async.completeFn(data);
            })
            .catch((err) => {
              console.log("Problem completing async task", err);
              async.errorFn();
            })
            .finally(() => {
              this.completedTasks.push(async.taskId);
            });
          break;
        }
      })
      .catch(() => {
        this.completedTasks.push(async.taskId);
      }));
    });

    Promise.all(checks).then(() => {
      // double check if any cleared.
      this.clearCompletedAsyncTasks();

      this.asyncTaskChecker = null; // triggers new timeout creation on next call to checkPending.

      // If there are tasks left, reset the timeout.
      if (this.activeAsyncTasks.length > 0) {
        this.checkPendingAsyncTasks();
      } 
    })    
  }
  
  /**
   * This operation check to see if there is a pending checker. If not, it will
   * clear any previously completed tasks (promise completions) and check to see if
   * there are any async tasks registered. If so, it will create a checker that
   * performs processes them. This is the main entry point to checking for tasks.
   * It can be called as frequently as you need but recommended after registering
   * a new task from (monitorAsyncTask) is enough.
   */
  private checkPendingAsyncTasks(): void {
    if (this.asyncTaskChecker) {
      // We are already checking, just wait.
      return;
    }

    // Make sure to stop checking completed tasks.
    this.clearCompletedAsyncTasks();
    
    // Do we even have anything to do?
    if (this.activeAsyncTasks.length == 0) {
      this.asyncTaskChecker = null;
      return;
    }
    this.asyncTaskChecker = setTimeout(this.processAsyncCheckTimeout.bind(this), 3000); // Check pending tasks on repeat every 3 seconds.
  }

  /**
   * This operation puts the callback and task id into the activeAsyncTasks list to monitor it.
   * This should be called by a client component after getting an async task ID back from the serverside-service it requested one from.
   * @param async The AsyncTaskCallbacks object to monitor.
   */
  monitorAsyncTask(async: AsyncTaskCallbacks) {
    this.activeAsyncTasks.push(async);
    this.checkPendingAsyncTasks(); // make sure the checker is running
  }

  private interceptError(error) {
    console.error(error);
    this.isLoading = false;
    if (error.status === 403) {
      this.isAuthenticated = false;
      Companion.getUserService().currentUser.isAuthenticated = false;
    }

    const message = error?.error?.message || error?.error?.msg || "Failed to get data from server";
    this.alertService.createAlert(AlertCode.getDashboardFailed, message, AlertLevel.warning);
  }

  static msToMin(milliseconds: number): string {
    // 1- Convert to seconds:
    let seconds : number = Math.round(milliseconds / 1000);
    // 2- Extract hours:
    const hours : number =  Math.floor(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    const minutes = Math.floor(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    let hoursStr = ("00" + hours).slice(-2);
    let minutesStr = ("00" + minutes).slice(-2);
    let secondsStr = ("00" + seconds).slice(-2);
    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }

  /**
   * get the status of each agent
   */
  fetchAgentsCurrentStatus(skillTags? : ISkillTags, showOffline : boolean = false): Promise<IAgentsCurrentStatusApi> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/restapi/agents/status/current", {groupIds: this.getSelectedGroupAndDescendantIds(), 
        skillTags: skillTags, showOffline : showOffline})
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          this.interceptError(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get the current status calls
   */
  fetchCallsCurrentStatus(skillTags? : ISkillTags): Promise<ICallsCurrentStatusApi> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/restapi/calls/status/current", {groupIds: this.getSelectedGroupAndDescendantIds(), skillTags: skillTags})
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          this.interceptError(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get the calls status calls
   */
  fetchCallsDailyStatus(skillTags? : ISkillTags): Promise<ICallsDailyStatusApi> {
    let startOfDay : Date = new Date() // get start of day in clients local time
    startOfDay.setHours(0,0,0,0);
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/restapi/calls/status/daily", {
        groupIds: this.getSelectedGroupAndDescendantIds(),
        skillTags: skillTags,
        startOfDay: startOfDay
      })
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          this.interceptError(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get the calls status calls
   */
  fetchAgentsCallsStatisticsAsync(
    startDate: Date,
    endDate: Date,
    granularity: string,
    agentUsername: string,
    skillTags: ISkillTags
  ): Promise<string> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/restapi/agents/calls", {
        groupIds: this.getSelectedGroupAndDescendantIds(),
        timezone: DashboardService.getTimezone(),
        agentUsername: agentUsername,
        startDate: startDate,
        endDate: endDate,
        granularity: granularity,
        skillTags: skillTags
      })
      .subscribe(
        (response: any) => {
          resolve(response.data);
        },
        (error: any) => {
          this.interceptError(error);
          reject(error);
        }
      );
    });
  }

   /**
   * get async task request status
   */
   fetchAsyncTaskStatus(taskId: string): Promise<AsyncTaskStatus> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/restapi/tasks/status", {taskId:taskId})
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          this.interceptError(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get async task request data
   */
  fetchAsyncTaskData(taskId: string): Promise<any> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/restapi/tasks/result", {taskId:taskId})
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          this.interceptError(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get the status of each agent
   */
  fetchCustomersCurrentStatus(skillTags? : ISkillTags): Promise<ICustomersCurrentStatusApi> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/restapi/customers/status/current", {groupIds: this.getSelectedGroupAndDescendantIds(), skillTags: skillTags})
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          this.interceptError(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get the calls status calls
   */
  fetchCustomersCallsStatisticsAsync(
    startDate: Date,
    endDate: Date,
    granularity: string,
    customerName: string,
    customerTheme: string, 
    skillTags?: ISkillTags,
  ): Promise<string> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/restapi/customers/calls", {
        groupIds: this.getSelectedGroupAndDescendantIds(),
        timezone: DashboardService.getTimezone(),
        customerName: customerName,
        customerTheme: customerTheme,
        startDate: startDate,
        endDate: endDate,
        granularity: granularity,
        skillTags: skillTags
      })
      .subscribe(
        (response: any) => {
          resolve(response.data);
        },
        (error: any) => {
          this.interceptError(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get report
   */
  fetchReportAsync(filter: IFilter): Promise<string> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/restapi/reports", filter)
      .subscribe(
        (taskId: string) => {
          resolve(taskId);
        },
        (error: any) => {
          this.interceptError(error);
          reject(error);
        }
      );
    });
  }

  /**
   * Helper function to get 
   */
  getSelectedGroupAndDescendantIds() : string[]
  {
    let groupIds : string[] = [];

    if(!this.selectedGroup)
    {
      return groupIds;
    }

    let descendants : ITreeViewItem[] = this.getAllDescendantsOfItem(this.selectedGroup);
    groupIds = _.map(descendants, (descendant : ITreeViewItem) =>
    {
      return descendant.value._id;
    });
    groupIds.push(this.selectedGroup.value._id);
    return groupIds;
  }

  /**
   * helper function to recursively find all the descendants of th group
   * @param item 
   * @returns 
   */
  private getAllDescendantsOfItem(item) : ITreeViewItem[]
  {
      let descendants : ITreeViewItem[] = [];

      item.children?.forEach((child : ITreeViewItem) =>
      {
        descendants.push(child);
        descendants = descendants.concat(this.getAllDescendantsOfItem(child));
      });

      return descendants;
  }
}
