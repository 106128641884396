/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { IChatRoom, IChatRoomService, Companion } from "companion";

@Component({
  selector: "chatroom-sidebar",
  templateUrl: "./chatroom-sidebar.template.html"
})
/**
 * Chat room side bar view
 */
export class ChatRoomSidebar {
  public inEditState: boolean = false;
  @Input() selectedRoom: IChatRoom;
  @Input() canModifyChatRoomParticipants: boolean;
  @Output("enter") enterEmitter: EventEmitter<IChatRoom> = new EventEmitter<IChatRoom>();
  @Output("delete") deleteEmitter: EventEmitter<IChatRoom> = new EventEmitter<IChatRoom>();
  @Output("closePanel") closePanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("toggleMenuPanel") toggleMenuPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("toggleEndpointSelectListPanel") toggleEndpointSelectListPanelEmitter: EventEmitter<
    IChatRoom
  > = new EventEmitter<IChatRoom>();

  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean;

  chatRoomService: IChatRoomService = Companion.getChatRoomService();

  /**
   * toggle edit mode
   */
  toggleEdit(): void {
    this.inEditState = !this.inEditState;
  }

  /**
   * enter chat room
   * @param room: IChatRoom - chat room to enter
   */
  enter(room: IChatRoom): void {
    this.enterEmitter.emit(room);
  }

  /**
   * delete chat room
   * @param room: IChatRoom - chat room to delete
   */
  delete(room: IChatRoom): void {
    this.deleteEmitter.emit(room);
  }

  /**
   * trigger close chat room panel
   */
  closePanel(closeMenu = false): void {
    this.closePanelEmitter.emit(true);
    if (this.isMobileApp && closeMenu) {
      this.closeMenuPanel();
    }
  }

  /**
   * close menu panel
   */
  private closeMenuPanel(): void {
    this.toggleMenuPanelEmitter.emit(true);
  }

  /**
   * trigger open and close endpoint selection panel
   */
  toggleEndpointSelectListPanel(event: any): void {
    event.stopPropagation();
    this.toggleEndpointSelectListPanelEmitter.emit(null);
  }

  /**
   * back to menu panel
   */
  backToMenu() {
    this.closePanel();
  }

  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }
}
