/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Browser} from "companion";
import {LocalizationService} from "../../localization/localization.service";
import {NavBarMenuItem, NavBarMenuItemKey, NavBarService} from "../../layout/nav-bar/nav-bar.service";
import {Dispatcher} from "../../shared/services/dispatcher";

@Component({
  selector: "menu-panel",
  templateUrl: "./menu-panel.template.html"
})
/**
 * settings panel
 */
export class MenuPanelComponent implements OnInit {
  @Output("toggleMenuPanel") toggleMenuPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public localizationService: LocalizationService,
              private navBarService: NavBarService) {
    // nothing needed here
  }

  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean;

  /**
   * list of menu items
   */
  menuItems: NavBarMenuItem[];

  /**
   * close panel
   */
  closePanel() {
    this.toggleMenuPanelEmitter.emit(true);
  }

  /**
   * get menu item title
   * @param menu: NavBarMenuItem - menu item
   */
  getTitle(menu: NavBarMenuItem) {
    return menu.title ? this.localizationService.getValueByPath(menu.title) || menu.defaultTitle : menu.defaultTitle;
  }

  /**
   * menu item onclick event handler
   */
  onMenuItemClick(menuItem: NavBarMenuItem) {
    if (menuItem.clickAction != null) {
      Dispatcher.dispatch(menuItem.clickAction, menuItem.payload);
    }
  }

  ngOnInit() {
    const allMenu = this.navBarService.allMenu;

    this.menuItems = allMenu.filter(menu => {
      return menu.key === NavBarMenuItemKey.Settings ||
        menu.key === NavBarMenuItemKey.Chat;
    });
  }
}
