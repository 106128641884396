/**
 * Copyright Compunetix Incorporated 2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";

import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { QRCodeModule } from "angularx-qrcode";

import { LayoutModule } from "../layout/layout.module";
import { LicenseQRComponent } from "./license-qr.component";
import { LicenseScannerComponent } from "./license-scanner.component";

 @NgModule({
   imports: [BrowserModule, FormsModule, ZXingScannerModule, QRCodeModule, LayoutModule],
   declarations: [LicenseScannerComponent, LicenseQRComponent],
   exports: [LicenseScannerComponent, LicenseQRComponent]
 })
 export class LicenseModule {}
