/**
 * Copyright Compunetix Incorporated 2017-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

/**
 * browser util
 */
var MobileDetect = require('mobile-detect');

export class Browser {
  static minVersionByOSAndBrowser = {
    Desktop: {
      Chrome: 67,
      Firefox: 52,
      Safari: 11,
    },
    AndroidOS: {
      Chrome: 67,
      Firefox: 52,
      Opera: 46,
    },
    iOS: {
      Chrome: 67,
      Firefox: 52,
      Safari: 11,
      GenericBrowser: 0
    },
  };
  static downloadLinkByOSAndBrowser = {
    Desktop: {
      Default: "https://www.google.com/chrome/",
      Chrome: "https://www.google.com/chrome/",
      Firefox: "https://www.mozilla.org/en-US/firefox/new/",
    },
    AndroidOS: {
      Default: "https://play.google.com/store/apps/details?id=com.android.chrome",
      Chrome: "https://play.google.com/store/apps/details?id=com.android.chrome",
      Firefox: "https://play.google.com/store/apps/details?id=org.mozilla.firefox",
      Opera: "https://play.google.com/store/apps/details?id=com.opera.browser",
    },
  };

  /**
   * check if browser support
   */
  static isBrowserSupport(): boolean {
    if (!navigator || !navigator.getUserMedia) {
      return false;
    }
    return this.whichBrowserVersion() >= this.getMinBrowserVersion();
  }

  /**
   * detect which os
   */
  static whichOS(): string {
    let detector: any = new MobileDetect(window.navigator.userAgent);
    let os: string = detector.mobile() ? detector.os() : "Desktop";
    return os;
  }

  /**
   * detect which browser version
   */
  static whichBrowserVersion(browser: string = this.whichBrowser()): number {
    let detector: any = new MobileDetect(window.navigator.userAgent);
    let version: number = detector.version(browser);
    if (isNaN(version) || version == null) {
      return 0;
    }
    return version;
  }

  /**
   * detect which browser
   */
  static whichBrowser(): string {
    let result: string = null;
    let detector: any = new MobileDetect(window.navigator.userAgent);
    result = detector.userAgent();
    if (!result) {
      if (window.navigator.userAgent.toLowerCase().indexOf("firefox") > 1) {
        result = "Firefox";
      } else if (window.navigator.userAgent.toLowerCase().indexOf("trident") > 1) {
        result = "IE";
      } else if (window.navigator.userAgent.toLowerCase().indexOf("msie") > 1) {
        result = "IE";
      } else if (window.navigator.userAgent.toLowerCase().indexOf("edge") > 1) {
        result = "Edge";
      } else if (window.navigator.userAgent.toLowerCase().indexOf("opera") > 1) {
        result = "Opera";
      } else if (window.navigator.userAgent.toLowerCase().indexOf("chrome") > 1) {
        result = "Chrome";
      } else if (window.navigator.userAgent.toLowerCase().indexOf("safari") > 1) {
        result = "Safari";
      } else {
        result = "GenericBrowser";
      }
    }
    return result;
  }

  /**
   * get min version by os and browser
   */
  static getMinBrowserVersion(os: string = this.whichOS(), browser: string = this.whichBrowser()): number {
    let result: number = null;
    if (this.minVersionByOSAndBrowser && this.minVersionByOSAndBrowser[os]) {
      result = this.minVersionByOSAndBrowser[os][browser];
    }
    return result;
  }

  /**
   * get download link by os and browser
   */
  static getDownloadLink(os: string = this.whichOS(), browser: string = this.whichBrowser()): string {
    let result: string = null;
    if (this.downloadLinkByOSAndBrowser && this.downloadLinkByOSAndBrowser[os]) {
      result = this.downloadLinkByOSAndBrowser[os][browser] || this.downloadLinkByOSAndBrowser[os]["Default"];
    }
    return result;
  }

  /**
   * detect is mobile
   */
  static isMobile(): boolean {
    let detector: any = new MobileDetect(window.navigator.userAgent);
    return !!detector.mobile();
  }

  /**
   * detect if device is in landscape mode
   */
  static isLandscape() {
    return window.matchMedia("(orientation: landscape)").matches;
  }

  /**
   * get min required browser and version by os
   */
  static getMinBrowserAndVersion(os: string = this.whichOS()): any {
    return this.minVersionByOSAndBrowser[os];
  }

  /**
   * handle browser visibility change event
   */
  static onBrowserVisibilityChange(callback: (isVisible: boolean) => void) {
    // Set the name of the hidden property and the change event for visibility
    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document["msHidden"] !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document["webkitHidden"] !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === "undefined" || hidden === undefined) {
      console.log("This requires a valid browser that supports the Page Visibility API.");
    } else {
      // Handle page visibility change
      document.addEventListener(
        visibilityChange,
        () => {
          callback(!document[hidden]);
        },
        false
      );
    }
  }

  /**
   * copy text to clip board in traditional way
   */
  private static fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }

  /**
   * copy text to clip board in async way
   */
  static copyTextToClipboard(text) {
    if (!(navigator as any).clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    (navigator as any).clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        alert("Copied to clipboard!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
        alert("Failed to copy!");
      }
    );
  }
}
