/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SharedModule } from "../shared/shared.module";
import { FlavorFactoryModule } from "../flavor-factory/flavor-factory.module";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { LayoutModule } from "./../layout/layout.module";
import { FormModule } from "../form/form.module";

import { GroupManagementComponent } from "./group-management.component";
import { GroupManagementService } from "./group-management.service";
import { GroupDetailsComponent } from "./group-details.component";
import { PurgeReportFormComponent } from "./purge-report-form.component";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    NgxDatatableModule,
    SharedModule,
    FlavorFactoryModule,
    AccordionModule.forRoot(),
    LayoutModule,
    FormModule
  ],
  declarations: [GroupManagementComponent, GroupDetailsComponent, PurgeReportFormComponent],
  providers: [GroupManagementService],
  exports: [GroupManagementComponent]
})
export class GroupManagementModule {}
