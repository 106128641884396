/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
 const jsSHA = require("jssha");
/**
 * hash util
 */
export class Hash {
  /**
   * hash text to HEX
   * @param text: string - original text
   * @param algorithm: string = 'SHA-512' - algorithm use to hash, default SHA-512
   */
  public static hashTextToHEX(text: string, algorithm: string = "SHA-512"): string {
    var shaObj = new jsSHA(algorithm, "TEXT", { encoding: "UTF8" });
    shaObj.update(text);
    return shaObj.getHash("HEX");
  }
}
