/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi
 */

import { Component, Input, Output, EventEmitter, SimpleChanges, SimpleChange, OnChanges } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { Browser, PresenceStatus, IQueueStatus, ISkillTags } from "companion";
import { IDialOutData } from "../../dial-out/dial-out.component";

@Component({
  selector: "guest-wait",
  templateUrl: "./guest-wait.component.html",
  styleUrls: ["./guest-wait.component.scss"]
})
export class GuestWaitComponent implements OnChanges {

  /**
   * flag if any operator is online
   */
  @Input() hasOperatorOnline: boolean;
  @Input() queueStatus: IQueueStatus;
  @Input() contextConnectingTitle: string;
  @Input() onHold: boolean;
  @Input() touchless: boolean;
  @Input() showAutodropWarning: boolean;
  @Input() touchlessAutodropCount: number;

  /**
   * The skill tags associated with the Queue.
   */
  @Input() skillTags: ISkillTags;

  @Output("guestDisconnect") disconnectEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("callBackGuest") callBackGuestEmitter: EventEmitter<IDialOutData> = new EventEmitter<IDialOutData>();

  public presenceStatusClass = PresenceStatus;
  public connectInfoPositionLabel: string;
  public connectInfoWaitTimeLabel: string;
  showCallbackPanel: boolean = false;


  constructor(public localizationService: LocalizationService) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentQueueStatus: SimpleChange = changes.queueStatus;
    if (currentQueueStatus && currentQueueStatus.currentValue) {
      this.connectInfoWaitTimeLabel = this.localizationService.myLocalizationData?.connect_screen?.connectPanelWaitTimeLabel || 
                                      "Your estimated wait is [wait] minutes.";

      // SHOW N/A here when there are no operators available.
      if(this.hasOperatorOnline)
      {
        this.connectInfoWaitTimeLabel = this.connectInfoWaitTimeLabel?.replace("[wait]", currentQueueStatus.currentValue.estimatedWaitTime.toString());
      }
      else
      {
        this.connectInfoWaitTimeLabel = this.connectInfoWaitTimeLabel?.replace("[wait]", "n/a");
      }

      if (currentQueueStatus.currentValue.estimatedWaitTime === 1 && this.connectInfoWaitTimeLabel?.includes("minutes")) {
        this.connectInfoWaitTimeLabel = this.connectInfoWaitTimeLabel.replace("minutes", "minute");
      }
      this.connectInfoPositionLabel = this.localizationService.myLocalizationData?.connect_screen?.connectPanelPositionLabel ||
                                      "Your position in queue is [position].";
      this.connectInfoPositionLabel = this.connectInfoPositionLabel?.replace("[position]", currentQueueStatus.currentValue.position.toString());
    }
  }

  disconnect(e: Event) {
    this.disconnectEmitter.emit();
    e.preventDefault();
  }

  callBack() {
    this.showCallbackPanel = true;
  }

  dialTelephoneNumber(dialOutData: IDialOutData) {
    this.callBackGuestEmitter.emit(dialOutData);
  }

  cancel() {
    this.showCallbackPanel = false;
  }

  get isMobile() : boolean
  {
    return Browser.isMobile();
  }
}
