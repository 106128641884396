/**
 * Copyright Compunetix Incorporated 2016-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IAuthenticationConfig, ILicense, IMessagingConfig, IGroup, IThirdPartyAuthConfig, IMessagingTemplate, ITwilioAccount } from "./group.interface";
import { IReportDefinition } from "../report/report.interface";

export class Group implements IGroup {
  constructor(
    public name: string,
    public billingCode?: string,
    public expireEnabled: boolean = false,
    public expireDate?: Date,
    public parentId?: string,
    public children_model: IGroup[] = [],
    public report_definitions: { [key: string]: IReportDefinition } = {},
    public twilioAccount: ITwilioAccount = {},
    public messagingConfig: IMessagingConfig = {},
    public messagingTemplate: IMessagingTemplate = {},
    public connectors: string[] = [],
    public licenseKey?: string,
    public license?: ILicense,
    public maxOpCount?: number,
    public AuthenticationConfig: IAuthenticationConfig = {},
    public licenseProof?: string
  ) {
    // nothing needed here
  }
  static generateThirdPartyCallbackUrl(authConfig: IThirdPartyAuthConfig, groupId: string) {
    let result: string = window ? window.location.origin : "";
    result += "/" + authConfig.protocol;
    result += "/" + authConfig.provider;
    result += "/callback";
    result += "/" + groupId;
    return result;
  }
  static generateThirdPartyCallbackRoute(authConfig: IThirdPartyAuthConfig, groupId: string, addProtocol: boolean = false) {
    let result: string = "";
    if (addProtocol)
      result += "/" + authConfig.protocol;
    result += "/" + authConfig.provider;
    result += "/callback";
    result += "/" + groupId;
    return result;
  }
  static generateThirdPartySPLoginRoute(authConfig: IThirdPartyAuthConfig, groupId: string, addProtocol: boolean = false) {
    let result: string = "";
    if (addProtocol)
      result += "/" + authConfig.protocol;
    result += "/" + authConfig.provider;
    result += "/login";
    result += "/" + groupId;
    return result;
  }
  static generateThirdPartyIdPLoginRoute(authConfig: IThirdPartyAuthConfig, groupId: string, addProtocol: boolean = false) {
    let result: string = "";
    if (addProtocol)
      result += "/" + authConfig.protocol;
    result += "/" + authConfig.provider;
    result += "/:module";
    result += "/" + groupId;
    return result;
  }
}
