/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, TemplateRef, ViewChild, ElementRef, OnInit } from "@angular/core";
import { UUID } from "companion";

@Component({
  selector: "list-container",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"]
})
/**
 * list component
 */
export class ListComponent implements OnInit {
  viewModel: any = { expanded: {}, selected: {}, minimized: {} };
  isEditing: boolean = false;
  isSearching: boolean = false;
  @Input()
  maxHeight: number;
  @Input()
  addInList: boolean;
  @Input()
  addEnabled: boolean = true;
  @Input()
  expandEnabled: boolean;
  @Input()
  isExpanded: boolean;
  @Input()
  isEditable: boolean;
  @Input()
  isAccordion: boolean;
  @Input()
  title: string;
  @Input()
  items: any[];
  @Input()
  searchByFields: string[];
  @Input()
  searchEnabled: boolean;
  @Input()
  multiSelect: boolean;
  @Input()
  listHeaderTemplate: TemplateRef<any>;
  @Input()
  listItemAdditionTemplate: TemplateRef<any>;
  @Input()
  listItemTemplate: TemplateRef<any>;
  @Input()
  listItemContentTemplate: TemplateRef<any>;

  /**
   * the flag to determine if to show tag
   */
  @Input() showTag: boolean;

  @Input() highlight: boolean;

  /**
   * tag content
   */
  @Input() tagContent: boolean;
  /**
   * property name options for the list to be sorted by
   */
  @Input() sortKeyOptions: {name: string, value: string}[] = [];
  /**
   * the property name for the list to be sorted by
   */
  @Input() sortKey;
  /**
   * the direction for the list to be sorted at
   */
  @Input() sortDirection;
  /**
   * the flag to determine if to display sort buttons
   */
  @Input() sortEnabled: boolean;
  @Output("addItem")
  addEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("editItem")
  editEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("deleteItem")
  deleteEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("selectItem")
  selectEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("enterEdit")
  enterEditEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("exitEdit")
  exitEditEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("submitNewItem")
  submitNewItemEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("cancelNewItem")
  cancelNewItemEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("cancelSelection")
  cancelSelectionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("submitSelection")
  submitSelectionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("expandToggle")
  expandToggleEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("searchbox")
  searchbox: ElementRef;

  ngOnInit() {
    this.updateItemIds();
  }
  updateItemIds() {
    _.forEach(this.items, (item: any) => {
      if (!item.componentId) {
        item.componentId = UUID.UUID();
      }
    });
    let updateItemIdsTimer = setTimeout(() => {
      clearTimeout(updateItemIdsTimer);
      this.updateItemIds();
    }, 1 * 1000);
  }
  add() {
    if (this.addInList) {
      this.viewModel.toBeAddedItem = {};
    } else {
      this.addEventEmitter.emit();
    }
  }
  submitNewItem(item: any) {
    this.submitNewItemEventEmitter.emit(item);
    this.viewModel.toBeAddedItem = null;
  }
  cancelNewItem() {
    this.cancelNewItemEventEmitter.emit();
    this.viewModel.toBeAddedItem = null;
  }
  enterEdit() {
    this.isEditing = true;
    this.enterEditEventEmitter.emit();
  }
  exitEdit() {
    this.isEditing = false;
    this.exitEditEventEmitter.emit();
  }
  edit(item: any) {
    this.editEventEmitter.emit(item);
  }
  delete(item: any) {
    this.deleteEventEmitter.emit(item);
  }
  select(item: any) {
    if (this.multiSelect) {
      this.viewModel.selected[item.componentId] = !this.viewModel.selected[item.componentId];
    } else {
      this.selectEventEmitter.emit(item);
    }
  }
  resetSearch() {
    this.viewModel.search = null;
    this.searchbox.nativeElement.focus();
  }
  toggleExpand(item: any) {
    this.viewModel.expanded[item.componentId] = !this.viewModel.expanded[item.componentId];
  }
  toggleMinimized(item: any) {
    this.viewModel.minimized[item.componentId] = !this.viewModel.minimized[item.componentId];
  }
  cancelSelection() {
    this.viewModel.selected = {};
    this.cancelSelectionEventEmitter.emit();
  }
  submitSelection() {
    let selectedItems: any[] = [];
    _.forEach(this.viewModel.selected, (selected: boolean, key: number) => {
      if (selected) {
        selectedItems.push(this.items[key]);
      }
    });
    this.submitSelectionEventEmitter.emit(selectedItems);
    this.viewModel.selected = {};
  }
  enterSearchMode(e: Event) {
    this.isSearching = true;
  }
  exitSearchMode(e: Event) {
    this.isSearching = !!this.viewModel.search;
  }
  expandToggle() {
    this.isExpanded = !this.isExpanded;
    this.expandToggleEventEmitter.emit(this.isExpanded);
  }
  sortBy(sortKey) {
    if (!this.sortDirection || sortKey === this.sortKey) {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    } else if (sortKey !== this.sortKey) {
      this.sortDirection = "asc";
    }
    this.sortKey = sortKey;
  }
}
