import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RemoteDesktopComponent } from './remote-desktop.component';
import { DisplayComponent } from './display/display.component';

@NgModule({
  declarations: [
    RemoteDesktopComponent,
    DisplayComponent
  ],
  imports: [
    BrowserModule,
  ],
  providers: [],
  bootstrap: [RemoteDesktopComponent, DisplayComponent],
  exports: [RemoteDesktopComponent, DisplayComponent]
})
export class RemoteDesktopModule { }
