/**
 * Copyright Compunetix Incorporated 2018-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
/**
 * troubleshoot window Component
 */
import { Directive } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { Companion } from "companion";

@Directive({
  selector: "troubleshoot-window"
})
export class TroubleshootWindowComponent {
  troubleshooterUrl: any;
  troubleshooterWindow: Window;
  getTroubleshooterUrl(): any {
    let url: string = "https://test.webrtc.org/?";
    let paramUri = "";
    let iceServers: any[] = Companion.getRTCClient().peerconnectionConfig.iceServers;
    if (iceServers) {
      let turnServerToTest: string;
      let stunServerToTest: string;
      _.forEach(iceServers, (iceServer: any) => {
        if (iceServer.urls && !Array.isArray(iceServer.urls)) {
          iceServer.urls = [iceServer.urls];
        } else if (!iceServer.urls && iceServer.url) {
          iceServer.urls = [iceServer.url];
        }
        _.forEach(iceServer.urls, (iceServerUrl: string) => {
          let matched = /^(\S{4}):(\S+)$/.exec(iceServerUrl);
          if (matched) {
            let protocol = matched[1].toLowerCase();
            let iceServerHost = matched[2].toLowerCase();
            if (protocol && iceServerHost) {
              if (protocol === "turn" && !turnServerToTest) {
                turnServerToTest = "turnURI=" + iceServerUrl;
                turnServerToTest += "&turnUsername=" + iceServer.username;
                turnServerToTest += "&turnCredential=" + iceServer.credential;
              } else if (protocol === "stun" && !stunServerToTest) {
                stunServerToTest = "stunURI=" + iceServerUrl;
              }
            }
          }
        });
      });
      if (turnServerToTest) {
        paramUri += "&" + turnServerToTest;
      }
      if (stunServerToTest) {
        paramUri += "&" + stunServerToTest;
      }
      paramUri = paramUri.substr(1);
    }
    url += paramUri;
    return url;
  }
  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }
  open() {
    let windowWidth = 480;
    let windowHeight = screen.height;
    let windowLeft = screen.width - windowWidth;
    let windowTop = 0;
    this.troubleshooterWindow = window.open(
      this.getTroubleshooterUrl(),
      "TroubleshooterWindow",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,left=" +
        windowLeft +
        ",top=" +
        windowTop +
        ",width=" +
        windowWidth +
        ",height=" +
        windowHeight
    );
  }
}
