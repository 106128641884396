/**
 * Copyright Compunetix Incorporated 2016
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";

/**
 * find in a list of chat rooms for the given roomId.
 * @param	collection to find in
 * @param	id to be find for.
 * @param	value to be find for.
 * @example Usage:
 * ```html
 * <li [room] = "room | find : 'roomId' : roomId" }}
 * ```
 */
@Pipe({ name: "find" })
export class FindPipe {
  transform(list: any[], id: string, value: string): any {
    if (id && value) {
      return _.find(list, (item: any) => {
        return item[id] === value;
      });
    } else {
      return null;
    }
  }
}
