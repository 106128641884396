/**
 * Copyright Compunetix Incorporated 2017-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { Component, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges} from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  PresenceStatus,
  Companion,
  CameraPermission,
  IQueueStatus,
  ISkillTags
} from "companion";
import { IDialOutData } from "../../dial-out/dial-out.component";
import { FaceDetector, Detection, FilesetResolver } from "@mediapipe/tasks-vision";
import { differenceInSeconds } from "date-fns";

@Component({
  selector: "guest-connect",
  templateUrl: "./guest-connect.template.html",
  styleUrls: ["./guest-connect.component.scss"]
})
export class GuestConnectComponent implements AfterViewInit, OnChanges {
  /**
   * guest endpoint current status
   */
  @Input() presenceStatus: PresenceStatus;

  /**
   * The skill tags associated with the Queue.
   */
  @Input() skillTags: ISkillTags;
  
  /**
   * flag if any operator is online
   */
  @Input() hasOperatorOnline: boolean;

  /**
   * flag if we want to operate in touchless entry mode
   */
  @Input()  touchless: boolean = false;

  /**
   * flag if it's in locked mode
   * the lock mode is a special UI mode which will
   * hide or disable all toolbar features
   */
  @Input() isLocked: boolean = false;

  /**
   * flag if top toolbar is showing
   */
  @Input() isNavShown: boolean;


  /**
   * volume of waiting video
   */
  @Input() videoVolume: number = 1;

  /**
   * Queue info in realtime
   */
  @Input() queueStatus: IQueueStatus;

  /**
   * Camera permission info
   */
  @Input() cameraPermission: CameraPermission;

  /**
   * Indicates that a transfer of this guest is occuroing
   */
  @Input() transferOccurring: boolean;

  /**
   * guest connect event trigger
   */
  @Output("guestConnect") connectEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * guest disconnect event trigger
   */
  @Output("guestDisconnect") disconnectEmitter: EventEmitter<any> = new EventEmitter<any>();
  /**
   * guest call back event trigger
   */
  @Output("callBackGuest") callBackEmitter: EventEmitter<IDialOutData> = new EventEmitter<IDialOutData>();

  /**
   * export PresenceStatus class to ng template
   */
  public presenceStatusClass = PresenceStatus;

  /**
   * export Camera permission class to ng template
   */
  public cameraPermissionClass = CameraPermission;
  
  /** 
  * embedded link
  */
  public embeddedLink: SafeResourceUrl

  /**
   * The face detector
   */
  private faceDetector: FaceDetector;

  /**
   * Interval set to check the face detector for a detection.
   */
  private faceDetectionLoop;

  /**
   * The video element and constraints that get updated for use with the face detector.
   */
  private faceDetectorVideo: HTMLVideoElement;
  private faceDetectorVideoConstraints: MediaTrackConstraints;

  /**
   * Tracker for the last face data received.
   */
  private lastFace: any = null;

  /**
   * Tracker for the last time we received face data.
   */
  private lastFaceTime: Date;

  /**
   * Tracker for the first instance of a face detected in this tracker session.
   */
  private initFaceTime: Date;

  /**
   * Tracker for if we have seen a face continuously.
   */
  private trackingFace: boolean = false;

  /**
   * track whether we are attempting to disconnect from the guest connect screen
   */
  private isDisconnecting: boolean;

 /**
  * component constructor
  */
  constructor(public localizationService: LocalizationService, private sanitizer: DomSanitizer) {
  }
 /**
  * After component init event handler
  */
  ngAfterViewInit() {
    this.isDisconnecting = false;
    $("#username_keyboard").hide();
    if (this.localizationService.myLocalizationData.connect_screen.autoConnect && 
        this.presenceStatus === PresenceStatus.available && // Only actually do a connect if not transferring and available
        this.cameraPermission === CameraPermission.allowed && !this.transferOccurring) {
      // DON'T AUTO CONNECT IF NOT PERMITTED!
      this.connectEmitter.emit(true);
    }
    let bindLoginLinkTimer = setTimeout(() => {
      clearTimeout(bindLoginLinkTimer);
      jQuery(".login-link")
        .attr("data-target", "#loginModal")
        .attr("data-toggle", "modal");
    }, 100);
    let updateWaitVideoTimer = setTimeout(() => {
      clearTimeout(updateWaitVideoTimer);
      let waitVideo: any = document.getElementById("waiting-video");
      if (waitVideo) {
        waitVideo.play().catch();
        waitVideo.muted = !this.isLocked;
        waitVideo.controls = !this.isLocked;
      }
    }, 500);
    if (this.localizationService.myLocalizationData.connect_screen.waitScreenEmbeddedUrl) {
      this.embeddedLink = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.localizationService.myLocalizationData.connect_screen.waitScreenEmbeddedUrl
      );
    }
    if (this.touchless) {
      this.ensureVideoCapture();
      if (this.presenceStatus === PresenceStatus.available || this.presenceStatus === PresenceStatus.connecting) {
        this.startTouchlessTracker();
      }
      
    }
  }

  initFaceDetector() {
    // Init the face detector!
    FilesetResolver.forVisionTasks(
      // path/to/wasm/root
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
    ).then(vision => {
        FaceDetector.createFromOptions(vision,
        {
          baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite`,
            delegate: "GPU"
          },
          minDetectionConfidence: (this.localizationService.getValueByPath(".connect_screen.touchless_detection_confidence") || 50) / 100,
          runningMode: "VIDEO",

        }).then(detector => {
          if (this.faceDetector) {
            delete this.faceDetector;
          }
          this.faceDetector = detector;
        });
    });
  }

  ensureVideoCapture() {
    // Init media pipe face detection loop.
    if (this.faceDetectorVideo) {      
      delete this.faceDetectorVideo;
    }

    this.faceDetectorVideo = document.createElement('video') as HTMLVideoElement;
    Companion.getRTCService().getCameraStream(false).then((stream: MediaStream) => {
      this.faceDetectorVideo.srcObject = stream;
      this.faceDetectorVideoConstraints = stream.getVideoTracks()[0].getConstraints();
    })
    .then(() => {
      this.initFaceDetector();
    })
    .catch((error) => {
      console.error("GuestConnect:init - Failed to getCameraStream: ", error);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentPresence: SimpleChange = changes.presenceStatus;
    const currentCameraPermission: SimpleChange = changes.cameraPermission;
    
    if (currentPresence) {
      if (currentPresence.currentValue === PresenceStatus.available || currentPresence.currentValue === PresenceStatus.connecting) {
        // Touchless checks?
        if (this.touchless) {
          this.startTouchlessTracker()
        };
      }
      else { // ! available or connecting
        // Touchless checks?
        if (this.touchless) {
          this.stopTouchlessTracker();
        }
      }
    }

    // if either changed, check auto connect here
    if (currentCameraPermission || currentPresence)
    {
      if(this.localizationService.myLocalizationData.connect_screen.autoConnect && 
          this.presenceStatus === PresenceStatus.available &&
          this.cameraPermission === CameraPermission.allowed &&
          !this.isDisconnecting &&
          !this.transferOccurring) {
        // DON'T AUTO CONNECT IF NOT PERMITTED!
        this.connectEmitter.emit(true);
      }
    }
  }

  /**
   * get connecting label content
   */
  get connectingLabelText(): string {
    let result: string = this.localizationService.myLocalizationData.connect_screen.connectingStatusLabel;
    if (this.presenceStatus === PresenceStatus.onhold) {
      result = this.localizationService.myLocalizationData.connect_screen.onholdStatusLabel;
    } else if (this.presenceStatus === PresenceStatus.invisible) {
      result = this.localizationService.myLocalizationData.connect_screen.invisibleStatusLabel;
    } else if (
      !this.hasOperatorOnline &&
      this.localizationService.myLocalizationData.connect_screen.noOperatorAvailableLabel
    ) {
      result = this.localizationService.myLocalizationData.connect_screen.noOperatorAvailableLabel;
    }

    return result;
  }

  /**
   * check if should display wait video
   */
  get displayWaitVideo(): boolean {
    return (
      this.localizationService.myLocalizationData.connect_screen.waitVideo &&
      !(!this.hasOperatorOnline && this.localizationService.myLocalizationData.connect_screen.noOperatorAvailableLabelDominant)
    );
  }

  /**
   * check if should display wait image
   */
  get displayWaitImage(): boolean {
    return (
      this.localizationService.myLocalizationData.connect_screen.waitImage &&
      !(!this.hasOperatorOnline && this.localizationService.myLocalizationData.connect_screen.noOperatorAvailableLabelDominant)
    );
  }

  /**
   * check if wait video is muted
   */
  get isWaitVideoMuted(): boolean {
    let waitVideo: HTMLVideoElement = document.getElementById("waiting-video") as HTMLVideoElement;
    if (waitVideo) {
      return waitVideo.muted;
    }
    return false;
  }

  /**
   * disconnect button click event
   */
  disconnect(e: Event = null) {
    this.isDisconnecting = true;
    this.disconnectEmitter.emit();
    if (e) {
      e.preventDefault();
    }
  }

  /**
   * call back button click event
   */
  callBackGuest($event: IDialOutData) {
    this.callBackEmitter.emit($event);
  }

  newFace(currentTime: Date, currentFace: any){
    this.trackingFace = true;
    this.initFaceTime = currentTime;
    this.lastFace = {...currentFace};
    this.lastFaceTime = currentTime;
  }

  noFace() {
    this.trackingFace = false;
    this.lastFace = null;
    this.initFaceTime = null;
  }



  startTouchlessTracker() {
    if (this.faceDetectionLoop) {
      clearInterval(this.faceDetectionLoop);
    }
    this.faceDetectionLoop = setInterval(() => {
      this.faceDetectorVideo.muted = true; // be sure to mute because you might end up looping yourself with feedback.
      this.faceDetectorVideo.play().catch(err => {
        console.error("Failure to play face detection video stream", err);
      }).then(() => {
        let detections = this.faceDetector?.detectForVideo(this.faceDetectorVideo, performance.now()).detections;
        if (detections?.length > 0) {
          this.trackTouchlessDetections(detections[0]);
        } else {
          this.trackTouchlessUndetections();
        }
      });
    }, 500)
  }

  stopTouchlessTracker() {
    if (this.faceDetectionLoop) {
      clearInterval(this.faceDetectionLoop);
    }
  }

  get timeStillSeconds(): number {
    let currentTime = new Date();    
    return differenceInSeconds(currentTime, this.initFaceTime) || 0
  }

  trackTouchlessDetections(primaryDetection: Detection) {
    // helper pythagoras
    function getDistance(x1, y1, x2, y2){
      let y = x2 - x1;
      let x = y2 - y1;
      
      return Math.sqrt(x * x + y * y);
    }
    // helper face size to screen ratio
    function getFaceSize(faceDetect, constraint) {
      let imageW = constraint?.width?.max || constraint?.width;
      let imageH = constraint?.height?.max || constraint?.height;
      return ((faceDetect.width*faceDetect.height)/(imageW*imageH));
    }

    function getMotionPercent(motion, constraint) {
      let imageW = constraint?.width?.max || constraint?.width;
      let imageH = constraint?.height?.max || constraint?.height;
      return (motion / getDistance(0, 0, imageW, imageH));
    }

    // Read the tunables.
    let detectionTime: number = this.localizationService.getValueByPath(".connect_screen.touchless_detection") || 3;
    let detectionSizeRatio: number = (this.localizationService.getValueByPath(".connect_screen.touchless_detection_ratio") || 10) / 100;
    let detectionMovement: number = (this.localizationService.getValueByPath(".connect_screen.touchless_detection_movement") || 10) / 100;

    let currentTime = new Date();
    let faceDetect = primaryDetection.boundingBox;
    let faceRatio = getFaceSize(faceDetect, this.faceDetectorVideoConstraints);

    // ABORT ON TINY FACE!
    if (faceRatio < detectionSizeRatio) {
      this.trackTouchlessUndetections();
      return;
    }
    if (differenceInSeconds(currentTime,this.lastFaceTime) > 1 ) {
      // later face?
      this.newFace(currentTime, faceDetect);
    } else if (this.lastFace && this.initFaceTime) {
      // same face, we have a new face and it is not more than 1s since last face?
      let moved = getDistance(this.lastFace.originX, this.lastFace.originY, faceDetect.originX, faceDetect.originY);
      if (getMotionPercent(moved, this.faceDetectorVideoConstraints) < detectionMovement) {
        let timeDiff = differenceInSeconds(currentTime, this.initFaceTime);
       
        if (this.trackingFace && timeDiff > detectionTime) {
          this.noFace();

          // If in available state, do a connect.
          if (this.presenceStatus === PresenceStatus.available && !this.transferOccurring){
            this.connectEmitter.emit(true);
          }
        } 
        this.lastFace = {...faceDetect};
        this.lastFaceTime = currentTime;
      } else {
        this.newFace(currentTime, faceDetect);
      }
    } else {
      // first face?
      this.newFace(currentTime, faceDetect);
    }
  }

  trackTouchlessUndetections() {
    // No face anymore...
    this.noFace();

    // If we haven't detected a face for the auto-drop time.
    if (this.touchlessAutodropCount == 0 ) {
      // If in connecting state, do a disconnect.
      if (this.presenceStatus === PresenceStatus.connecting) {
        this.isDisconnecting = true;
        this.disconnectEmitter.emit();
      }
    }
  }

  get touchlessAutodropCount(): number {
    let autoDropTime: number = this.localizationService.getValueByPath(".connect_screen.touchless_autodrop") || 30;
    let count = autoDropTime - differenceInSeconds(new Date(), this.lastFaceTime);
    return (count >= 0 ? ((count > 99) ? 99 : count) : 0);
  }

  get showAutodropWarning(): boolean {
    return differenceInSeconds(new Date(),this.lastFaceTime) > 0
  }

  /**
   * connect button click event
   */
  connect(e: Event): void {
    this.connectEmitter.emit(true);
    e.preventDefault();
  }
  /**
   * intro video trigger play event
   */
  play() {
    let waitVideo: any = document.getElementById("waiting-video");
    if (waitVideo) {
      waitVideo.pause();
      waitVideo.play().catch();
      waitVideo.muted = false;
    }
  }
}
