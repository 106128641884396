/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Injectable } from "@angular/core";

import { RestService } from "./rest.service";
import { AlertLevel, AlertCode, Companion } from "companion";

@Injectable()
/**
 * time methods delegate
 */
export class TimeService {
  serverTimeDelay: number = 0;
  constructor(private restService: RestService) {
    this.getServerTime();
  }

  /**
   * get current server time to keep sync
   */
  getServerTime(callback?: (serverTime: number) => void): void {
    this.restService
      .post("/getServerTime", {})
      .subscribe(
        (data: ServerTime) => {
          this.serverTimeDelay = Date.now() - data.serverTime;
          if (callback) {
            callback(data.serverTime);
          }
        },
        (error: any) => {
          Companion.getConferenceService().alertHandler(AlertCode.getServerTimeFailed, error ? error.message : null, AlertLevel.warning);
        }
      );
  }
}

class ServerTime {
  constructor(public serverTime: number) {
    // nothing needed here
  }
}
