<div id="voice-conference-container"
     [style.background]="
       localizationService.myLocalizationData.page ? localizationService.myLocalizationData.page.backgroundColor : null"
     [style.backgroundImage]="
       'url(' + (localizationService.myLocalizationData.page ? localizationService.myLocalizationData.page.backgroundImage : null) + ')'">

  <div
    id="connect-screen"
  >
    <div
      class="join-conference-container container pull-center t-20"
      [style.top]="localizationService.myLocalizationData.connect_screen.top"
      [style.color]="localizationService.myLocalizationData.connect_screen.fontColor"
      [style.fontSize]="localizationService.myLocalizationData.connect_screen.fontSize"
      [style.backgroundColor]="localizationService.myLocalizationData.connect_screen.backgroundColor">
      <div
        class="title-container animated fadeIn text-center"
      >
        <img
          class="mb-3"
          *ngIf="localizationService.myLocalizationData?.connect_screen?.logo"
          [attr.src]="localizationService.myLocalizationData?.connect_screen?.logo"
          crossorigin="anonymous"
        />
        <h1
          *ngIf="localizationService.myLocalizationData?.connect_screen?.voiceCallScreenTitle"
          class="text-center mt-3"
          [innerHTML]="localizationService.myLocalizationData?.connect_screen?.voiceCallScreenTitle | safeHtml"
        >
        </h1>
      </div>
    </div>
  </div>
</div>
