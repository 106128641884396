/**
 * Copyright Compunetix Incorporated 2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  jporter
 */

import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
const { convert } = require('html-to-text');

/**
 * This pipe is used to sanitize possible bad content that is supplied by a user. 
 * This can be from the theme manager or URI or EntryForm etc.
 * Either instantiate it and call transform or use it in the angular template via injection.
 */
@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  /**
   * Used for populating innerHTML directly with formatted safe rendering html.
   * This translates unicode into renderable HTML #codepoints.
   * @param value unsafe text
   * @returns safe text for innerHTML
   */
  transform(value) : any {
    return this.sanitized.sanitize(SecurityContext.HTML, convert(decodeURIComponent(value)));
  }

  /**
   * Used for populating attributes like title with formatted safe but not char scrubbed text.
   * This doesn't tranlsate unicode into #codepoints.
   * @param value unsafe text
   * @returns safe text for attr insertion
   */
  nonScrub(value): string {
    return convert(decodeURIComponent(value));
  }
}
