/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, TemplateRef } from "@angular/core";

@Component({
  selector: "list-item",
  templateUrl: "./list-item.component.html",
  styleUrls: ["./list-item.component.scss"]
})
/**
 * list item component
 */
export class ListItemComponent {
  viewModel: any = {};
  @Input()
  isEditing: boolean;
  @Input()
  isEditable: boolean;
  @Input()
  isAccordion: boolean;
  @Input()
  expanded: boolean;
  @Input()
  minimized: boolean;
  @Input()
  multiSelect: boolean;
  @Input()
  selected: boolean;
  @Input()
  item: any;
  @Input()
  listItemTemplate: TemplateRef<any>;
  @Input()
  listItemContentTemplate: TemplateRef<any>;
  @Output("editItem")
  editEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("deleteItem")
  deleteEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("selectItem")
  selectEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("toggleExpand")
  toggleExpandEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  edit(item: any) {
    this.editEventEmitter.emit(item);
  }
  delete(item: any) {
    this.deleteEventEmitter.emit(item);
  }
  select(item: any) {
    this.selectEventEmitter.emit(item);
  }
  // function
  switchAccordion(e: any) {
    this.toggleExpandEventEmitter.emit();
  }
}
