/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import {Component, Input, Output, EventEmitter, OnInit} from "@angular/core";
import { IExpandedActiveConference, VideoAspect } from "companion";
import { LocalizationService } from "../../localization/localization.service";

@Component({
  selector: "settings-panel",
  templateUrl: "./settings-panel.template.html"
})
/**
 * settings panel
 */
export class SettingsPanelComponent implements OnInit {
  /**
   * flag if conference is already started
   */
  @Input() started: boolean;
  @Input() conference: IExpandedActiveConference;
  @Input() isKioskUnlocked: boolean;
  @Input() cropperEnabled: boolean;
  @Input() videoAspect: VideoAspect;
  @Output("toggleSettingsPanel") toggleSettingsPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("toggleMenuPanel") toggleMenuPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("updateSpeaker") updateSpeakerEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output("updateVolume") updateVolumeEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output("updateLocalStream") updateLocalStreamEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("toggleMic") toggleMicEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("toggleCamera") toggleCameraEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }


  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean;

  /**
   * close panel
   */
  closePanel(closeMenu = false): void {
    this.toggleSettingsPanelEmitter.emit(true);
    if (this.isMobileApp && closeMenu) {
      this.closeMenuPanel();
    }
  }

  /**
   * close menu panel
   */
  private closeMenuPanel(): void {
    this.toggleMenuPanelEmitter.emit(true);
  }


  /**
   * change speaker to use
   * @param speakerDeviceId: string - speaker device id
   */
  updateSpeaker(speakerDeviceId: string) {
    this.updateSpeakerEmitter.emit(speakerDeviceId);
  }

  /**
   * udpate local stream
   * @param forceNew: boolean - flag if force new
   */
  updateLocalStream(forceNew: boolean) {
    this.updateLocalStreamEmitter.emit(forceNew);
  }

  /**
   * toggle microphone
   * @param muted: boolean - flag if to mute microphone
   */
  toggleMic(muted: boolean) {
    this.toggleMicEmitter.emit(muted);
  }

  /**
   * toggle camera
   * @param muted: boolean - flag if to mute camera
   */
  toggleCamera(muted: boolean): void {
    this.toggleCameraEmitter.emit(muted);
  }

  ngOnInit() {
    this.updateFocus();
  }

  updateFocus() {
    let updateFocusTimer = setTimeout(() => {
      clearTimeout(updateFocusTimer);
      let container = document.getElementById("settings-panel");
      if (container) {
        container.focus();
      } else {
        this.updateFocus();
      }
    }, 1000);
  }

  /**
   * back to menu panel
   */
  backToMenu() {
    this.closePanel();
  }

  /**
   * update user volume
   */
  updateVolume(value: number) {
    this.updateVolumeEmitter.emit(value);
  }
}
