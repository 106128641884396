/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import {Companion} from "companion";

@Component({
  selector: "style-item",
  templateUrl: "./style-item.template.html"
})

/**
 * style item view
 */
export class StyleItemComponent implements OnInit {
  /**
   * flavor style name
   */
  @Input() style: string;

  /**
   * flag if this item is selected
   */
  @Input() isSelected: boolean;

  /**
   * flag if in edit mode
   */
  inEditMode: boolean = false;

  /**
   * use permission
   */
  userPermissions: any;

  /**
   * select style event
   */
  @Output("selectStyle") selectEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * update style event
   */
  @Output("rename") renameEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * delete style event
   */
  @Output("delete") deleteEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * new flavor style selected
   */
  select() {
    this.selectEmitter.emit(this.style);
  }

  /**
   * rename flavor style
   */
  update(event: Event) {
    event.preventDefault();
    this.renameEmitter.emit(this.style);
    this.inEditMode = false;
  }

  /**
   * delete flavor style
   */
  delete(event: Event) {
    event.preventDefault();
    this.deleteEmitter.emit(this.style);
  }

  /**
   * enter edit mode
   */
  enterEditMode(event: Event) {
    event.preventDefault();
    this.inEditMode = true;
    let styleNameFocusTimer = setTimeout(() => {
      clearTimeout(styleNameFocusTimer);
      if ($("#styleNameBox")[0]) { $("#styleNameBox")[0].focus(); }
    }, 500);
  }



  ngOnInit() {
    this.userPermissions = Companion.getUserService().currentUser.permissions;
    if (!this.style || this.style.length === 0) {
      this.inEditMode = true;
      let styleNameFocusTimer = setTimeout(() => {
        clearTimeout(styleNameFocusTimer);
        if ($("#styleNameBox")[0]) { $("#styleNameBox")[0].focus(); }
      }, 500);
    }
  }
}
