/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import {Component, Input, Output, EventEmitter, NgZone, OnDestroy, ViewChild, OnInit, ElementRef} from "@angular/core";
import {
  Browser,
  Companion,
  DeviceInfo,
  FlavorParameterKey,
  GlobalService,
  IConfig,
  IDeviceService,
  IRTCService,
  IUser,
  IUserService,
  IVideoFilter,
  MediaUtil,
  VideoResolution,
  EasyRTCService,
  VideoAspect,
  LogUtil,
  FlavorUtil
} from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { TroubleshootWindowComponent } from "../troubleshoot/troubleshoot-window.component";
import { Dispatcher, ActionType } from "../../shared/services/dispatcher";
import { NavBarService, NavBarMenuItemKey } from "../../layout/nav-bar/nav-bar.service";
import { Options } from "ngx-slider-v2";
import { Flavor } from "../../flavor-factory/flavor/flavor";
import { Store } from "client/scripts/Store/store.service";
import { ClientLogService } from "client/scripts/shared/services/client-log.service";

@Component({
  selector: "conference-settings",
  templateUrl: "settings.template.html",
  styleUrls: ["./settings.component.scss"]
})

/**
 * settings view
 */
export class SettingsComponent implements OnInit, OnDestroy {

  constructor(
    public localizationService: LocalizationService,
    public navBarService: NavBarService,
    public store: Store,
    private logService: ClientLogService
  ) {
    this.userModel = this.userService.currentUser;
    this.videoAspect = this.userModel.preferedVideoAspect || VideoAspect.fit;
    this.videoVolume = this.userModel.preferedVolume || 1;
    this.showCustomizedVideoMutedPlaceholder = localizationService.getValueByPath(".settings_panel.showCustomizedVideoMutedPlaceholder");
  }

  /**
   * flag if conference is already started
   */
  @Input() started: boolean;

  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean;

  /**
   * flag if the kiosk has been unlocked
   */
  @Input() isKioskUnlocked: boolean;

  /**
   * flag if the photo editor is enabled
   */
  @Input() cropperEnabled = true;

  /**
   * video aspect
   */
  @Input() videoAspect: VideoAspect;

  @ViewChild('virtualBgSelect')
  virtualBgSelect: ElementRef;

  /**
   * the view user model
   */
  userModel: IUser;

  /**
   * flag if in edit mode
   */
  editing: boolean = false;

  /**
   * error message
   */
  errorMessage: string = "";

  /**
   * flag if all stream paused
   */
  paused: boolean = false;

  /**
   * flag if in microphone test mode
   */
  micStartTesting: boolean = false;

  /**
   * flag if in speaker test mode
   */
  speakerStartTesting: boolean = false;

  /**
   * Video aspect enum used in settings template
   */
  VideoAspectEnum = VideoAspect;

  /**
   * Contains camera rotations
   */
  cameraRotations: { [cameraId: string]: string } = {};

  /**
   * flag if display customized video muted placeholder field
   */
  showCustomizedVideoMutedPlaceholder: boolean;


  get audioButtonText(): string {
    return this.navBarService.getTitle(this.navBarService.getMenuItem(NavBarMenuItemKey.Audio));
  }

  get audioIconClass(): string {
    return this.navBarService.getIcon(this.navBarService.getMenuItem(NavBarMenuItemKey.Audio));
  }

  get audioButtonAriaLabel(): string {
    return this.navBarService.getAriaLabel(this.navBarService.getMenuItem(NavBarMenuItemKey.Audio));
  }

  get audioButtonFunction(): () => void {
    if (!this.rtcService.rtcClient.microphoneAccessible) {
      // If microphone is not accessible, clicking the audio button will be ignored (if it is still enabled by chance)
      return (() => { });
    }
    if (!this.rtcService.rtcClient.audioMuted) {
      return this.muteAudio;
    }
    return this.unmuteAudio;
  }

  get videoButtonText(): string {
    return this.navBarService.getTitle(this.navBarService.getMenuItem(NavBarMenuItemKey.Video));
  }

  get videoIconClass(): string {
    return this.navBarService.getIcon(this.navBarService.getMenuItem(NavBarMenuItemKey.Video));
  }

  get videoButtonAriaLabel(): string {
    return this.navBarService.getAriaLabel(this.navBarService.getMenuItem(NavBarMenuItemKey.Video));
  }

  get videoButtonFunction(): () => void {
    if (!this.rtcService.rtcClient.cameraAccessible) {
      // If camera is not accessible, clicking the video button will be ignored (if it is still enabled by chance)
      return (() => { });
    }
    if (!this.rtcService.rtcClient.videoMuted) {
      return this.muteVideo;
    }
    return this.unmuteVideo;
  }

  get virtualBackgroundButtonText(): string {
    return this.navBarService.getTitle(this.navBarService.getMenuItem(NavBarMenuItemKey.VirtualBackground));
  }

  get virtualBackgroundIconClass(): string {
    return this.navBarService.getIcon(this.navBarService.getMenuItem(NavBarMenuItemKey.VirtualBackground));
  }

  get virtualBackgroundButtonAriaLabel(): string {
    return this.navBarService.getAriaLabel(this.navBarService.getMenuItem(NavBarMenuItemKey.VirtualBackground));
  }

  get virtualBackgroundButtonFunction(): () => void {
    return this.rtcService.rtcClient.filteredStreamEnabled ? this.stopVirtualBackground : this.startVirtualBackground;
  }

  get ngIf_CameraRotation(): boolean {
    return this.localizationService.myLocalizationData.settings_panel.camera_rotation && this.isKioskUnlocked && !this.isMobileApp
  }
  /**
   * video filter util object
   */
  videoFilter: IVideoFilter = Companion.getRTCClient().videoFilter;

  public rtcService: IRTCService = Companion.getRTCService();
  private userService: IUserService = Companion.getUserService();
  public deviceService: IDeviceService = Companion.getDeviceService();
  public videoVolume: number;

  public sliderOptions: Options = {
    floor: 0,
    ceil: 1,
    step: 0.1,
    showSelectionBar: true,
    hideLimitLabels: true,
    autoHideLimitLabels: true,
    translate: (value, label) => {
      return (value * 100).toString();
    }
  };

  @Output("updateSpeaker") updateSpeakerEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output("updateVolume") updateVolumeEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output("updateLocalStream") updateLocalStreamEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("toggleMic") toggleMicEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("toggleCamera") toggleCameraEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * troubleshoot window
   */
  @ViewChild(TroubleshootWindowComponent)
  troubleshootWindow: TroubleshootWindowComponent;

  ngOnInit(): void {
    this.cameraRotations = this.deviceService.getCameraRotations();
  }

  /**
   * toggle microphone testing mode
   */
  toggleMicTesting(forceDisabled: boolean = false) {
    this.micStartTesting = !forceDisabled && !this.micStartTesting;
    if (this.micStartTesting) {
      this.toggleSpeakerTesting(true);
    }
  }

  /**
   * toggle speaker testing mode
   */
  toggleSpeakerTesting(forceDisabled: boolean = false) {
    this.speakerStartTesting = !forceDisabled && !this.speakerStartTesting;
    this.resetSpeakerTester();
    if (this.speakerStartTesting) {
      this.toggleMicTesting(true);
    }
  }

  /**
   * update speaker for the tester
   */
  resetSpeakerTester() {
    if (this.speakerStartTesting) {
      MediaUtil.startSpeakerTestAudio();
    } else {
      MediaUtil.stopSpeakerTestAudio();
    }
  }

  /**
   * component destroy event
   */
  ngOnDestroy() {
    if (this.rtcService.rtcClient.speakerTestAudioSourceNode) {
      MediaUtil.stopSpeakerTestAudio();
    }
  }

  /**
   * mute microphone
   */
  muteAudio(): void {
    this.toggleMic(true);
  }

  /**
   * unmute microphone
   */
  unmuteAudio(): void {
    this.toggleMic(false);
  }

  /**
   * mute or unmute microphone
   */
  toggleMic(muted: boolean) {
    this.toggleMicEmitter.emit(muted);
  }

  /**
   * mute camera
   */
  muteVideo(): void {
    this.toggleCamera(true);
  }

  /**
   * unmute camera
   */
  unmuteVideo(): void {
    this.toggleCamera(false);
  }

  /**
   * stop virtual background
   */
  stopVirtualBackground(): void {
    this.rtcService.toggleCameraVirtualBackground(false);
    this.userModel.preferedVirtualBackgroundEnabled = false;
    GlobalService.setSessionUser(this.userModel);
  }

  /**
   * start virtual background
   */
  startVirtualBackground(): void {
    this.rtcService.toggleCameraVirtualBackground(true);
    this.userModel.preferedVirtualBackgroundEnabled = true;
    GlobalService.setSessionUser(this.userModel);
  }

  /**
   * mute or unmute camera
   */
  toggleCamera(muted: boolean): void {
    this.toggleCameraEmitter.emit(muted);
  }

  /**
   * select new device
   */
  setCameraRotation() {
    this.deviceService.saveCameraRotations(this.cameraRotations);
    this.deviceService.selectPrimaryCamera(this.rtcService.rtcClient.selectedPrimaryCameraOption);
  }

  /**
   * select new device
   * @param deviceType: string - new device selection type
   * @param deviceInfo: DeviceInfo - new device info
   */
  selectDevice(deviceType: string, deviceInfo: DeviceInfo): void {
    switch (deviceType) {
      case "mic":
        this.deviceService.selectMicrophone(deviceInfo);
        this.toggleMicTesting(true);
        break;
      case "camera":
        this.deviceService.selectPrimaryCamera(deviceInfo);
        this.toggleMicTesting(true);
        break;
      case "secondary-camera":
        this.deviceService.selectSecondaryCamera(deviceInfo);
        break;
      case "speaker":
        this.deviceService.selectSpeaker(deviceInfo);
        this.toggleSpeakerTesting(true);
        this.updateSpeakerEmitter.emit(deviceInfo.deviceId);
        break;
      default:
        break;
    }
  }

  /**
   * update video resolution
   * @param resolution: VideoResolution - new selected resolution
   */
  selectPrimaryResolution(resolution?: VideoResolution) {
    this.rtcService.setVideoResolution(true, resolution);
  }

  /**
   * update video bandwidth
   * @param kbitsPerSecond: number - new selected bandwidth
   */
  selectBandwidth(kbitsPerSecond?: number) {
    // until bridge support renegotiation disable bandwitdh change
    if (this.started) {
      return false;
    }
    // set bandwidth limit to kbitsPerSecond and true to save in cookie
    // and true to update with connected peers immediately
    this.rtcService.setVideoBandwidth(kbitsPerSecond, true, true);
  }

  /**
   * open trouble shoot dialog window
   */
  openTroubleshoot() {
    Dispatcher.dispatch(ActionType.OpenModalDialog, "helper-wizard", "helper-wizard");
  }

  /**
   * enable secondary camera source
   */
  addSecondaryCamera(): void {
    this.deviceService.enableSecondaryCamera();
  }

  /**
   * disable secondary camera source
   */
  removeSecondaryCamera(): void {
    this.deviceService.disableSecondaryCamera();
  }

  /**
   * update video resolution
   * @param resolution: VideoResolution - new selected resolution
   */
  selectSecondaryResolution(resolution?: VideoResolution) {
    this.rtcService.setVideoResolution(false, resolution);
  }

  /**
   * update video aspect
   * @param videoAspect: VideoAspect - new selected video aspect
   */
  updateVideoAspect(videoAspect: VideoAspect): void {
    this.userModel.preferedVideoAspect = videoAspect;
    GlobalService.setSessionUser(this.userService.currentUser);
  }

  /**
   * toggle use of cropper during photo acquisition
   */
  toggleCropper(): void {
    this.cropperEnabled = !this.cropperEnabled;
    this.userService.currentUser.preferredCropperEnabled = this.cropperEnabled;
    GlobalService.setSessionUser(this.userService.currentUser);
  }

  /**
   * update user volume
   */
  sliderChanged(value) {
    this.updateVolumeEmitter.emit(value);
  }

  /**
   * upload image to be used as video muted placeholder
   */
  uploadVideoMutedPlaceholder(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let objUrl = reader.result as string;
        this.rtcService.rtcClient.avatarImageSrc = objUrl;
        this.userModel.preferedVideoMutedPlaceholder = objUrl;
        GlobalService.setSessionUser(this.userModel);
        this.resetVideoMutedPlaceholderOnDelay();
      };
    }
  }

  /**
   * upload image to be used as virtual background image
   */
  uploadVirtualBackgroundImage(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let objUrl = reader.result as string;
        this.videoFilter.virtualBackgroundImage = objUrl;
        this.userModel.preferedVirtualBackgroundImage = objUrl;
        GlobalService.setSessionUser(this.userModel);
      };
    }
  }

  /**
   * remove video muted placeholder
   */
  removeVideoMutedPlaceholder() {
    this.rtcService.rtcClient.avatarImageSrc = this.localizationService.getValueByPath(".settings_panel.avatarImageSrc") ||
      FlavorUtil.getDefaultLocalizationValues(this.localizationService.myLocalizationData.language).settings_panel.avatarImageSrc;
    this.userModel.preferedVideoMutedPlaceholder = null;
    GlobalService.setSessionUser(this.userModel);
    this.resetVideoMutedPlaceholderOnDelay();
  }

  /**
   * reset avatar stream on delay
   */
     resetVideoMutedPlaceholderOnDelay(delay: number = 0.3 * 1000) {
      let delayTimer = setTimeout(() => {
        clearTimeout(delayTimer);
        this.rtcService.drawAvatarCanvas();
      }, delay);
    }

  /**
   * remove virtual background image
   */
  removeVirtualBackgroundImage() {
    this.videoFilter.virtualBackgroundImage = null;
    this.userModel.preferedVirtualBackgroundImage = null;
    this.virtualBgSelect.nativeElement.value = "";
    GlobalService.setSessionUser(this.userModel);
  }

  setLogsPermissions(permission: string) {
    const settings: IConfig = this.store.getState().settings;
    switch (permission) {
      case "statistics":
        settings.CollectLogs.webrtcStatistics = !settings.CollectLogs.webrtcStatistics;
        const statFrequency = settings.CollectLogs.webrtcStatistics ?
          settings.CollectLogs.statFrequency || 1000 : 0;
          this.logService.enableWebrtcStats(statFrequency);
        break;
    }
    LogUtil.getLogInstance().logsSettings = settings.CollectLogs;
    this.store.update("settings", settings);
  }

  /**
   * send logs to server
   */
  sendLogs() {
    this.logService.sendLogs(true).catch((error) => console.error("Failed to sendLogs: ", error));
  }

  /**
   * Determine if we are a guest
   */
  isGuest()
  {
    return !Companion.getUserService().currentUser.isOperator && !Companion.getUserService().currentUser.isRep;
  }

  isMobileDevice() : boolean
  {
    return Browser.isMobile();
  }
}
