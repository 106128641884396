/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
/**
 * it's a structure for video resolution
 */
export class VideoResolution {
  constructor(public width: number, public height: number) {}

  static systemDefaultResolutions: VideoResolution[] = [
    { width: 1920, height: 1080 },
    { width: 1280, height: 720 },
    { width: 704, height: 576 },
    { width: 768, height: 448 },
    { width: 512, height: 288 },
    { width: 352, height: 288 }
  ];
}
