/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
/**
 * UUID util to generate unique id
 */
export class UUID {
  /**
   * generate new uuid
   */
  public static UUID(): string {
    if (typeof window !== "undefined" && typeof window.crypto !== "undefined" && typeof window.crypto.getRandomValues !== "undefined") {
      var buf = new Uint16Array(8);
      window.crypto.getRandomValues(buf);
      return (
        UUID.pad4(buf[0]) +
        UUID.pad4(buf[1]) +
        "-" +
        UUID.pad4(buf[2]) +
        "-" +
        UUID.pad4(buf[3]) +
        "-" +
        UUID.pad4(buf[4]) +
        "-" +
        UUID.pad4(buf[5]) +
        UUID.pad4(buf[6]) +
        UUID.pad4(buf[7])
      );
    } else {
      return (
        UUID.random4() +
        UUID.random4() +
        "-" +
        UUID.random4() +
        "-" +
        UUID.random4() +
        "-" +
        UUID.random4() +
        "-" +
        UUID.random4() +
        UUID.random4() +
        UUID.random4()
      );
    }
  }
  /**
   * convert number to 4 charactor string
   */
  public static pad4(num: number) {
    var ret = num.toString(16);
    while (ret.length < 4) {
      ret = "0" + ret;
    }
    return ret;
  }
  /**
   * generate random 4 charactor string
   */
  public static random4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  /**
   * generate random string at any length
   */
  public static randomAtAnyLength(length) {
    var result           = "";
    var characters       = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
}
