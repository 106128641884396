/**
 * Copyright Compunetix Incorporated 2016-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { Injectable } from "@angular/core";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { AlertLevel, IAlert, Alert } from "companion";
/**
 * alert methods delegate
 */
@Injectable()
export class AlertService {
  public alerts: IAlert[];
  constructor() {
    this.alerts = [];
    Dispatcher.register(ActionType.Alert, this.alertEventHandler.bind(this));
  }

  /**
   * Custom dialog using bootbox. Use buttons' callback options to be notified.
   * Defaults to single OK button with no callback.
   * See: https://bootboxjs.com/documentation.html#dialog-options
   */
  static createCustomDialog(options: BootboxDialogOptions) : void {
    let defaultOptions: BootboxDialogOptions = {
      className: "modal-desktop alert-box",
      onEscape: false,
      backdrop: false,
      closeButton: false,
      buttons: {
        ok: {
          label: "OK",
          className: "btn-success",
        }
      },
      message: ""
    }
    bootbox.dialog({...defaultOptions, ...options});
  }

  /**
   * alert with buttons
   * This promise never rejects, the callback issues a resolve with the dialog result.
   * See: https://bootboxjs.com/documentation.html#dialog-options
   * For confirm dialogs such as this, the button callbacks are ignored in favor of the global callback.
   * The global callback is handled by this operation.
   */
  static createAlertWithButtons(messageBody: string, buttonsConfig?: any): Promise<boolean> {
    return new Promise((resolve: (result: boolean) => void, reject: (reason: string) => void) => {
        bootbox.confirm({
          className: "modal-desktop alert-box",
          message: messageBody,
          onEscape: false,
          backdrop: false,
          closeButton: false,
          buttons: buttonsConfig || {
            confirm: {
              label: "Confirm",
              className: "btn-success",
            },
            cancel: {
              label: "Cancel",
              className: "btn-default",
            }
          },
          callback: (result: boolean) => {
            resolve(result);
          },
        });
    });
  }

  /**
   * alert event handler
   * @param payload: any - event payload
   */
  private alertEventHandler(payload: any) : void {
    if (!payload || !payload.alert) {
      return;
    }
    console.log("companion_web_log", payload.alert);
    if (payload.alert.alertLevel !== AlertLevel.log) {
      this.createAlert(
        payload.alert.alertCode,
        payload.alert.alertText,
        payload.alert.alertLevel,
        payload.alert.alertCountdown
      );
    }
  }

  /**
   * create alert with IAlert object
   * @param alert: IAlert - the alert object
   */
  private create(alert: IAlert): void {
    this.alerts.push(alert);
    if (alert.alertLevel !== AlertLevel.error) {
      this.setTimeoutToDelete(alert);
    }
    if (this.alerts.length > 3) {
      this.alerts.pop();
    }
  }

  createAlert(alertCode: string, alertText?: string, alertLevel?: AlertLevel, alertCountdown: number = 5) {
    // Only display error/prominent alerts at this time.
    if (alertLevel == AlertLevel.error || alertLevel == AlertLevel.prominent) {
      this.create(new Alert(alertCode, alertText, alertLevel, alertCountdown));
    }
  }

  /**
   * set timeout to delete
   */
  private setTimeoutToDelete(alert: IAlert) {
    let toDeleteTimer = setTimeout(() => {
      clearTimeout(toDeleteTimer);
      this.delete(alert);
    }, alert.alertCountdown * 1000);
  }

  /**
   * delete the specific alert
   * @param alert: IAlert - the alert object
   */
  delete(alert: IAlert): void {
    if (this.alerts.indexOf(alert) >= 0) {
      this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
  }

  /**
   * delete all alerts
   */
  cleanAll(): void {
    this.alerts.length = 0;
  }
}
