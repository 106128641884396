/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Component, Input, OnInit, IterableDiffers, IterableDiffer, DoCheck } from "@angular/core";
import { Companion, IEndpoint, AlertCode } from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { AlertService } from "../../alert/alert.service";
import { Loader } from "@googlemaps/js-api-loader"

var DEFAULT_LATITUDE: number = 37.8507939;
var DEFAULT_LONGITUDE: number = -102.4671333;
var DEFAULT_ZOOM: number = 5;

@Component({
  selector: "map-view",
  templateUrl: "./map.template.html"
})

/**
 * map view
 */
export class MapComponent implements OnInit, DoCheck {
  centerLatitude: number = DEFAULT_LATITUDE;
  centerLongitude: number = DEFAULT_LONGITUDE;
  zoom: number = DEFAULT_ZOOM;
  map: any;
  markers: any[];
  iterableDiffer: any;

  @Input()
  endpoints: IEndpoint[] = [];

  @Input() googleMapsAPIKey: string = "";

  numberOfEndpointsWithLocation: number = 0;
  get endpointsWithLocation(): IEndpoint[] {
    let result: IEndpoint[] = _.filter(this.endpoints, (ep: IEndpoint) => {
      return ep.location != null && ep.location.latitude != null && ep.location.longitude != null;
    });
    if (this.map && result.length > 0 && result.length !== this.numberOfEndpointsWithLocation) {
      this.numberOfEndpointsWithLocation = result.length;
    }
    return result;
  }


  constructor(public localizationService: LocalizationService, private alertService: AlertService, private iterableDiffers: IterableDiffers) {
    // create our differ sniffer to check if our endpoints array changed.
    this.iterableDiffer = iterableDiffers.find([]).create(null);  
    this.centerLatitude = localizationService.myLocalizationData.map_panel.defaultLatitude || DEFAULT_LATITUDE;
    this.centerLongitude = localizationService.myLocalizationData.map_panel.defaultLongitude || DEFAULT_LONGITUDE;
    this.zoom = localizationService.myLocalizationData.map_panel.defaultZoom || DEFAULT_ZOOM;
  }

  // Pan & Zoom map to show all markers
  fitToMarkers(endpoints: IEndpoint[]) {
    let lats = _.compact(_.map(endpoints, "location.latitude"));
    if (lats.length === 0) {
      return;
    }
    let centerLat = _.sum(lats) / lats.length;
    let minLat = _.min(lats);
    let maxLat = _.max(lats);
    let lngs = _.compact(_.map(endpoints, "location.longitude"));
    if (lngs.length === 0) {
      return;
    }
    let centerLng = _.sum(lngs) / lngs.length;
    let minLng = _.min(lngs);
    let maxLng = _.max(lngs);
    if(this.map)
    {
      this.map.setCenter({ lat: centerLat, lng: centerLng });
      this.map.initialZoom = true;
      this.map.fitBounds({ east: minLng, south: minLat, north: maxLat, west: maxLng });
    }

    Companion.getConferenceService().alertHandler(
      AlertCode.mapCenterUpdate,
        JSON.stringify({
          east: minLng,
          south: minLat,
          north: maxLat,
          west: maxLng
        })
    );
  }

  /**
   * Place markers on the map
   */
  placeMarkers()
  {
    if(!this.map)
    {
      return;
    }

    // clear the markers.
    _.forEach(this.markers, (marker: any) => {
      marker.setMap(null);
    });

    this.markers = [];

    console.log("Place EPs", this.numberOfEndpointsWithLocation);
    _.forEach(this.endpointsWithLocation, (ep: IEndpoint) => {
      let location : any = {lat: ep.location.latitude, lng: ep.location.longitude}
      this.markers.push(new google.maps.Marker({
        title: ep.name,
        position: location,
        map: this.map
      }));
    });

    this.fitToMarkers(this.endpointsWithLocation);
  }

  ngOnInit()
  {
    //console.log("call center conference map component ngOnInit googleMapsAPIKey:", this.googleMapsAPIKey);
    this.markers = [];
    const loader = new Loader({
      apiKey: this.googleMapsAPIKey,
      version: "weekly"
    });

    loader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: { lat: this.centerLatitude, lng: this.centerLongitude },
        zoom: this.zoom,
      });

      this.placeMarkers();
    });
  }

  ngDoCheck() {
    let changed: boolean = false;
    let changes = this.iterableDiffer.diff(this.endpoints);
    if (changes) {
      let addedEndpoints: Map<string, IEndpoint> = new Map();
      let removedEndpoints: Map<string, IEndpoint> = new Map();
      addedEndpoints.clear();
      removedEndpoints.clear();
      changes.forEachAddedItem((record) => {
        addedEndpoints.set(record.item.rtcId, record.item);
      });
      changes.forEachRemovedItem((record) => {
        removedEndpoints.set(record.item.rtcId, record.item);
      });
      if ((addedEndpoints.size > 0) &&
          (addedEndpoints.size != removedEndpoints.size))
      {
        // mismatched list size indicate changes!
        changed = true;
      }
      if (!changed) {
        addedEndpoints.forEach((ep) => {
          if (removedEndpoints.has(ep.rtcId)) {
            // we only care if the endpoint locations changed and not the rest of the data
            if (_.isEqual(addedEndpoints.get(ep.rtcId).location, removedEndpoints.get(ep.rtcId).location) == false) {
              changed = true;
            }
            addedEndpoints.delete(ep.rtcId);
            removedEndpoints.delete(ep.rtcId);
          }
          else {
            changed = true;
          }
        });
      }
      if (!changed) {
        if (addedEndpoints.size > 0 ||
            removedEndpoints.size > 0) {
          // If there are any items left, it indicates mismatched list i.e. changes
          changed = true;
        }
      }
      addedEndpoints.clear();
      removedEndpoints.clear();
    }
    if (changed) {
      this.placeMarkers();
    }
  }
}
