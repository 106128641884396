/**
 * Copyright Compunetix Incorporated 2016-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { Injectable } from "@angular/core";
import { RestService } from "client/scripts/shared/services/rest.service";
@Injectable()
export class ReportService {
  constructor(private restService: RestService) {
    // nothing need to do
  }
}
