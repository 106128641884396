/**
 * Copyright Compunetix Incorporated 2016-2020
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { IChatRoom, IEndpoint } from "companion";
import { ActionType, Dispatcher } from "client/scripts/shared/services/dispatcher";

@Component({
  selector: "chatroom-messagehistory",
  templateUrl: "./chatroom-messagehistory.template.html",
})

/**
 * Chat Messages view
 */
export class ChatRoomMessageHistory implements AfterViewInit {
  @ViewChild("scrollMe") private myScrollContainer: ElementRef;
  @Input() room: IChatRoom;
  @Input() currentEndpoint: IEndpoint;
  autoScrollToBottom: boolean = true;
  autoScrollToBottomTimer: any;
  displayArrow: boolean = false;
  constructor() {
    Dispatcher.register(ActionType.ResetMessageHistoryScrollBar, this.scrollToBottom.bind(this));
  }

  /**
   * component load handler
   */
  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  onScroll(event: Event) {
    let visibleY = this.myScrollContainer.nativeElement.scrollTop + $("#message-list-container").height();
    let containerHeight = this.myScrollContainer.nativeElement.scrollHeight;
    let arrowY = visibleY + 40;
    if (visibleY < containerHeight) {
      if (arrowY < containerHeight) {
        this.displayArrow = true;
      } else {
        this.displayArrow = false;
      }
      clearTimeout(this.autoScrollToBottomTimer);
      this.autoScrollToBottom = false;
    } else {
      this.autoScrollToBottom = true;
      this.displayArrow = false;
      this.scrollToBottom();
    }
  }

  /**
   * scroll view to bottom
   */
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      if (!this.myScrollContainer.nativeElement.hidden) {
        Dispatcher.dispatch(ActionType.ResetUnreadMessageCountOfCurrentRoom);
      }
    } catch (err) {
      console.error("Cannot scroll to container bottom.");
    }
    if (this.autoScrollToBottom) {
      this.autoScrollToBottomTimer = setTimeout(() => {
        clearTimeout(this.autoScrollToBottomTimer);
        this.scrollToBottom();
      }, 100);
    }
  }
}
