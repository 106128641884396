/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Output, EventEmitter } from "@angular/core";
import { FlavorService } from "../flavor/flavor.service";
import { AlertService } from "../../alert/alert.service";
import { Companion, PermissionLevel, IUser, AlertLevel } from "companion";

@Component({
  selector: "style-list",
  templateUrl: "./style-list.template.html"
})

/**
 * style list view
 */
export class StyleListComponent {
  /**
   * current selected flavor style
   */
  selectedStyle: string;

  /**
   * all available styles
   */
  styles: string[];

  /**
   * search term
   */
  search: string;

  /**
   * select style event
   */
  @Output("selectStyle") selectEmitter: EventEmitter<string> = new EventEmitter<string>();

  currentUser: IUser = Companion.getUserService().currentUser;

  /**
   * new flavor style selected
   */
  select(style: string) {
    if (this.selectedStyle !== style) {
      this.selectedStyle = style;
      this.selectEmitter.emit(style);
    }
  }

  /**
   * rename flavor style
   */
  rename(oldStyle: string, newStyle: string) {
    let oldStyleIndex = this.styles.indexOf(oldStyle);
    if (oldStyleIndex < 0) {
      return;
    }
    this.styles[oldStyleIndex] = newStyle;
    this.styles = _.sortBy(this.styles, (style) => {
      return style.toLowerCase();
    });
    if (!oldStyle || oldStyle.length === 0) {
      this.select(newStyle);
      return;
    }
    this.alertService.createAlert("STYLE_UPDATING", "Updating...", AlertLevel.success);
    this.flavorService
    .updateStyleName(oldStyle, newStyle)
      .then((message: string) => {
        this.alertService.createAlert("STYLE_NAME_UPDATED", "Style Name Updated Successfully!", AlertLevel.success);
        this.select(newStyle);
      })
      .catch((err: Error) => {
        this.alertService.createAlert("STYLE_NAME_UPDATED_FAIL", err.message, AlertLevel.warning);
      });
  }

  /**
   * delete flavor style
   */
  delete(style: string) {
    AlertService.createAlertWithButtons(
      "Are you sure that you want to permanently delete this style of UI customization?",
      {
        confirm: {
          label: "Confirm to delete",
          className: "btn-success"
        },
        cancel: {
          label: "Cancel",
          className: "btn-danger"
        }
      }
    ).then((result: boolean) => {
      if (result) {
        this.alertService.createAlert("STYLE_DELETING", "Deleting...", AlertLevel.success);
        this.flavorService
        .getLanguagesByStyle(style)
        .then((languages: string[]) => {
          if (languages.length === 0) {
            this.alertService.createAlert(
              "STYLE_DELETED",
              "Style " + style + " is Deleted Successfully!",
              AlertLevel.success
            );
            this.styles.splice(this.styles.indexOf(style), 1);
          } else {
            _.forEach(languages, (language: string, index: number) => {
              this.flavorService.deleteFlavor(style, language).then((message: string) => {
                if (index === languages.length - 1) {
                  this.alertService.createAlert(
                    "STYLE_DELETED",
                    "Style " + style + " is Deleted Successfully!",
                    AlertLevel.success
                  );
                  this.styles.splice(this.styles.indexOf(style), 1);
                }
              }).catch((err: Error) => {
                this.alertService.createAlert("STYLE_DELETE_FAIL", err.message, AlertLevel.warning);
              });
            });
          }
        })
        .catch((err: Error) => {
          this.alertService.createAlert("STYLE_DELETE_FAIL", err.message, AlertLevel.warning);
        });
      }
    });
  }

  /**
   * add new style
   */
  addNewStyleToPendingEdit() {
    if (_.includes(this.styles, "")) {
      return;
    }
    this.styles.unshift("");
  }

  constructor(private flavorService: FlavorService, private alertService: AlertService) {
    this.flavorService
    .getStyles()
    .then((styles: string[]) => {
      this.styles = _.sortBy(styles, (style) => {
        return style.toLowerCase();
      });
    })
    .catch((err: Error) => {
      this.alertService.createAlert("STYLE_GET_FAIL", err.message, AlertLevel.warning);
    });
  }
}
