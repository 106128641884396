import {Component, Input, OnInit} from "@angular/core";

export interface IPercentageData {
  value: number;
  color: string;
  arrow: string;
  label: string;
}

// Overview tab tile
@Component({
  selector: "tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"]
})
export class TileComponent implements OnInit {

  @Input() tileStyle: ("realtime" | "dailyTotal1" | "dailyTotal2");
  @Input() title: string;
  @Input() icon: string;
  @Input() value: number;
  @Input() label: string;

  @Input() subValue: number;
  @Input() subLabel: string;

  @Input() comparePercentage: IPercentageData;
  @Input() comparePercentage2: IPercentageData;

  ngOnInit(): void {
  }
}
