/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, TemplateRef } from "@angular/core";

@Component({
  selector: "list-new-item",
  templateUrl: "./list-new-item.component.html"
})
/**
 * list new item component
 */
export class ListNewItemComponent {
  viewModel: any = {};
  @Input()
  item: any;
  @Input()
  listItemAdditionTemplate: TemplateRef<any>;
  @Output("submit")
  submitEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("cancel")
  cancelEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  submit() {
    this.submitEventEmitter.emit(this.item);
  }
  cancel() {
    this.cancelEventEmitter.emit();
  }
}
