/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { VideoResolution } from "../settings/video-resolution";
const DEFAULT_PROFILE_LEVEL_ID = "42e01f";
const DEFAULT_PROFILE_LEVEL_ID_PREFIX = "42e0";
export class H264Util {
  /**
   * H264 profile level details database
   */
  static resolutionDict: { [levelId: string]: IProfileLevelDetail } = {
    "0a": { level: "1", maxBlocks: 99, maxBlocksPerSec: 1485 },
    "0b*": { level: "1B", maxBlocks: 99, maxBlocksPerSec: 1485 },
    "0b": { level: "1.1", maxBlocks: 396, maxBlocksPerSec: 3000 },
    "0c": { level: "1.2", maxBlocks: 396, maxBlocksPerSec: 6000 },
    "0d": { level: "1.3", maxBlocks: 396, maxBlocksPerSec: 11880 },
    "14": { level: "2", maxBlocks: 396, maxBlocksPerSec: 11880 },
    "15": { level: "2.1", maxBlocks: 792, maxBlocksPerSec: 19800 },
    "16": { level: "2.2", maxBlocks: 1620, maxBlocksPerSec: 20250 },
    "1e": { level: "3", maxBlocks: 1620, maxBlocksPerSec: 40500 },
    "1f": { level: "3.1", maxBlocks: 3600, maxBlocksPerSec: 108000 },
    "20": { level: "3.2", maxBlocks: 5120, maxBlocksPerSec: 216000 },
    "28": { level: "4", maxBlocks: 8192, maxBlocksPerSec: 245760 },
    "29": { level: "4.1", maxBlocks: 8192, maxBlocksPerSec: 245760 },
    "2a": { level: "4.2", maxBlocks: 8704, maxBlocksPerSec: 522240 },
    "32": { level: "5", maxBlocks: 22080, maxBlocksPerSec: 589824 },
    "33": { level: "5.1", maxBlocks: 36864, maxBlocksPerSec: 983040 }
  };

  /**
   * get acceptable video resolutions according to profileid
   */
  static getAcceptableResolutions(
    profileId: string,
    videoResolutionOptions: VideoResolution[] = VideoResolution.systemDefaultResolutions,
    MinFrameRate: number = 30
  ): VideoResolution[] {
    let result: VideoResolution[] = videoResolutionOptions;
    if (!this.isValidProfileId(profileId)) {
      return result;
    }
    result = _.filter(videoResolutionOptions, (videoResolution: VideoResolution) => {
      return !this.isResolutionOverLimit(profileId, videoResolution, MinFrameRate);
    });
    return result;
  }

  /**
   * check if video resolution is over limit according to profileid
   */
  static isResolutionOverLimit(
    profileId: string,
    videoResolution: VideoResolution,
    MinFrameRate: number = 30
  ): boolean {
    if (!this.isValidProfileId(profileId) || !videoResolution) {
      return false;
    }
    let blocks: number = (videoResolution.width * videoResolution.height) / (16 * 16);
    let blocksPerSec: number = blocks * MinFrameRate;
    let profileLevelDetail = this.getProfileLevelDetail(profileId);
    if (!profileLevelDetail) {
      return false;
    }
    return blocks > profileLevelDetail.maxBlocks || blocksPerSec > profileLevelDetail.maxBlocksPerSec;
  }

  /**
   * check if profile id is valid
   */
  private static isValidProfileId(profileid: string): boolean {
    if (!profileid) {
      return false;
    }
    if (profileid.length !== 6) {
      return false;
    }
    if (isNaN(Number.parseInt(profileid.substring(0, 2), 16))) {
      return false;
    }
    if (isNaN(Number.parseInt(profileid.substring(2, 4), 16))) {
      return false;
    }
    if (isNaN(Number.parseInt(profileid.substring(4, 6), 16))) {
      return false;
    }
    return true;
  }

  /**
   * get profile level id
   */
  private static getProfileLevelId(profileId: string): string {
    let profileOptionId: string = profileId.substring(2, 4);
    let profileOptionDecimal: number = Number.parseInt(profileOptionId, 10);
    let levelId: string = profileId.substring(4, 6);
    if (levelId === "0b" && profileOptionDecimal % 2 === 1) {
      levelId = "0b*";
    }
    return levelId;
  }

  /**
   * get profile level detail
   */
  static getProfileLevelDetail(profileId: string): IProfileLevelDetail {
    return this.resolutionDict[this.getProfileLevelId(profileId)];
  }

  /**
   * get profile level id by resolution and framerate
   */
  static getProfileLevelIdByResolutionAndFramerate(resolution: VideoResolution, framerate: number): string {
    let result = DEFAULT_PROFILE_LEVEL_ID;
    result = DEFAULT_PROFILE_LEVEL_ID_PREFIX + _.find(_.keys(this.resolutionDict), (levelId: string) => {
      let profileLevelId = DEFAULT_PROFILE_LEVEL_ID_PREFIX + levelId;
      return !this.isResolutionOverLimit(profileLevelId, resolution, framerate);
    });
    return result;
  }
}

/**
 * interface for profile level detail
 */
export interface IProfileLevelDetail {
  level: string;
  maxBlocks: number;
  maxBlocksPerSec: number;
}
