/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  jporter
 */
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PasscodeFormComponent } from "./passcode-form.component";
import { PasscodeModalComponent } from "./passcode-modal.component";


@NgModule({
  imports: [FormsModule],
  declarations: [PasscodeFormComponent, PasscodeModalComponent],
  exports: [PasscodeFormComponent, PasscodeModalComponent]
})
export class PasscodeModule {}
