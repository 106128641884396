/**
 * Copyright Compunetix Incorporated 2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { RestService } from "../shared/services/rest.service";
@Component({
  selector: "license-qr",
  templateUrl: "./license-qr.component.html",
  styleUrls: ["./license-qr.component.scss"],
})
/**
 * license qr layout
 */
export class LicenseQRComponent implements OnInit {
  viewModel: any = {};
  constructor(private restService: RestService) {
    this.reset();
  }
  ngOnInit() {
    this.getMachineID()
    .then((data: any) => {
      this.viewModel.machineId = data.MACHINE_ID;
    });
  }

  handleGoToNextStep(step) {
    this.viewModel.step = step;
  }

  handleLicenseKeyChange(event) {
    this.viewModel.key = event.target.value;
  }

  handleLicenseKeySubmit(event) {
    event.preventDefault();

    const payload = JSON.stringify({
      key: this.viewModel.key,
      fingerprint: this.viewModel.machineId
    });
    this.viewModel.qrcode = btoa(payload);
    this.handleGoToNextStep(1);
  }

  handleActivationProofChange(event) {
    this.viewModel.proof = event.target.value;
  }

  handleActivationProofSubmit(event) {
    event.preventDefault();
    this.viewModel.isVerified = false;
    this.viewModel.errors = null;

    if (this.viewModel.proof == null) {
      return;
    }

    this.verify(this.viewModel.proof, this.viewModel.key)
    .then((result) => {
      this.viewModel.isVerified = result.isVerified;
      this.viewModel.errors = result.errors;
      this.handleGoToNextStep(3);
    });
  }

  /**
   * try verify proof
   */
   verify(proof, key) {
    return new Promise((resolve: (value: any) => void, reject: (reason?: any) => void) => {
      this.restService
        .post("/verify-proof", { proof, key })
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error: any) => {
            console.error(error);
            reject(new Error(error._body));
          }
        );
    });
  }

  /**
   * get machine id
   */
   getMachineID() {
    return new Promise((resolve: (value: any) => void, reject: (reason?: any) => void) => {
      this.restService
        .post("/machine-id", {})
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error: any) => {
            console.error(error);
            reject(new Error(error._body));
          }
        );
    });
  }

  reset() {
    this.viewModel = {};
    this.viewModel.step = 0;
  }
}
