<!--
    Copyright Compunetix Incorporated 2024
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  jporter
-->
<div class="container" id="sharedFolder-panel">
  <div class="row table-responsive">
    <table class="table">
      <tbody>
    <!-- Files Offered -->
        <tr *ngIf="isInConf()">
          <td colspan="8">
          <h3>{{localizationService.myLocalizationData.sharedFolder_panel?.conference_files || "Shared Files"}}</h3>
          </td>
        </tr>
        <tr *ngFor="let fileRef of getOfferedFiles()">
          <td colspan="2">
            <i
              class="fas fa-file-excel fa-xl text-danger"
              [title]="localizationService.myLocalizationData.sharedFolder_panel?.remove_conf || 'Remove'"
              *ngIf="isMyFile(fileRef)"
              (click)="withdraw(fileRef)"
            ></i>
            <i
              class="fas fa-file-arrow-down fa-xl text-success"
              [title]="localizationService.myLocalizationData.sharedFolder_panel?.request_file || 'Request'"
              *ngIf="!isMyFile(fileRef)"
              (click)="request(fileRef)"
            ></i>
          </td>
          <td colspan="1">
            {{fileRef.filename}}
          </td>
          <td colspan="1">
            {{getEndpointNameById(fileRef.ownerId)}}
          </td>
          <td colspan="4"><!-- Placeholder to fit table properly for lower Status and Save -->
            <span>
              &nbsp;&nbsp;
            </span>
          </td>
        </tr>
    <!-- Request All Files Available!!!-->
        <tr *ngIf="getOfferedFiles().length > 0">
          <td colspan="2">
            <i
              class="fas fa-file-arrow-down fa-xl"
              [title]="localizationService.myLocalizationData.sharedFolder_panel?.request_all || 'Request All'"
              (click)="requestAll()"
            ></i>
            <i
              class="fas fa-file-arrow-down fa-xl"
              [title]="localizationService.myLocalizationData.sharedFolder_panel?.request_all || 'Request All'"
              (click)="requestAll()"
            ></i>
            <i
              class="fas fa-file-arrow-down fa-xl"
              [title]="localizationService.myLocalizationData.sharedFolder_panel?.request_all || 'Request All'"
              (click)="requestAll()"
            ></i>
          </td>
          <td colspan="1">
            {{localizationService.myLocalizationData.sharedFolder_panel?.request_all || 'Request All'}}
          </td>
          <td colspan="5"><!-- Placeholder to fit table properly for lower Status and Save -->
            <span>
              &nbsp;&nbsp;
            </span>
          </td>
        </tr>
    <!-- Files Stored-->
        <tr>
          <td colspan="8">
            <h3>{{localizationService.myLocalizationData.sharedFolder_panel?.local_files || "My Files"}}</h3>
          </td>
        </tr>
        <tr *ngFor="let file of getLocalFiles()">
          <td colspan="2"> <!-- Buttons-->
            <i
              class="fas fa-file-arrow-up fa-xl text-success"
              [title]="localizationService.myLocalizationData.sharedFolder_panel?.offer_file || 'Offer'"
              *ngIf="isMyFile(file) && isInConf()"
              (click)="offer(file)"
            >&nbsp;&nbsp;</i>
            <i
              class="fas fa-file-excel fa-xl text-danger"
              [title]="localizationService.myLocalizationData.sharedFolder_panel?.remove_local || 'Remove'"
              (click)="remove(file)"
            ></i>
          </td>
          <td colspan="1"> <!-- file name-->
            {{file.filename}}
          </td>
          <td colspan="1"> <!-- owner name -->
            {{getEndpointNameById(file.ownerId)}}
          </td>
          <td colspan="4" style="text-align:left"><!-- Status and Save -->
             <!-- Pending status info-->
             <span
             *ngIf="localizationService.myLocalizationData.sharedFolder_panel &&
                   file.status !== FileTransferStatus.finished &&
                   !isMyFile(file)"
             [ngClass]="'text-' + (file.status | fileStatusStyle)"
           >
             {{ localizationService.myLocalizationData.sharedFolder_panel[FileTransferStatus[file.status]] }}
           </span>
            <span [ngClass]="'tag tag-' + (file.status | fileStatusStyle)">
              <b *ngIf="!isMyFile(file)">
                {{ getPercentOfTransmit(file) }}%
              </b>
            </span>
            <!-- Download a finished file .-->
            <i
              *ngIf="file.status === FileTransferStatus.finished"
              class="fas fa-file-export fa-xl text-warning"
              [attr.title]="file.filename"
              (click)="download(file)"
            ></i>
            <i
              *ngIf="file.status !== FileTransferStatus.finished && !isMyFile(file)"
              class="fas fa-file-waveform fa-xl text-primary"
              [attr.title]="file.filename"
            ></i>
          </td>
        </tr>
    <!-- Loading tool section -->
        <tr *ngIf="uploadEnabled">
          <td colspan="1">
            <div class="col-xs-12 file-container">
              <div class="container-fluid file-box file-upload">
                <label>
                  <a
                    tabindex
                    class="btn"
                  >
                    <i
                      [title]="localizationService.myLocalizationData.sharedFolder_panel?.load_file || 'Load File'"
                      class="fas fa-file-import fa-xl"
                    ></i>
                    <span>&nbsp;{{localizationService.myLocalizationData.sharedFolder_panel?.load_file || 'Load File'}}</span>
                  </a>
                  <input
                    #fileLoaderInput
                    type="file"
                    style="display: none;"
                    (change)="fileChangeHandler($event)"
                    class="attachFileInput"
                  />
                </label>
              </div>
            </div>
          </td>
          <td colspan="7"> <!-- fill in the rest of the row -->
            &nbsp;&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>