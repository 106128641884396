<!--
    Copyright Compunetix Incorporated 2017
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->


<div class="row">
  <div class="col-12">
    <label class="navbar-brand">
      Style List
    </label>
    <a *ngIf="currentUser.permissions && currentUser.permissions.createThemes > 0"
      tabindex
      class="btn pull-right"
      (click)="addNewStyleToPendingEdit()"
    >
      <i class="fas fa-plus"></i>
    </a>
  </div>
  <div class="col-12">
    <input
      type="text"
      class="form-control"
      placeholder="Search"
      [(ngModel)]="search"
      #searchbox="ngModel"
      name="searchbox"
    >
  </div>
</div>
<div class="row">
  <div class="col-12">
    <ul
      class="list-group none-style-list"
      id="style-list"
    >
      <li
        *ngFor="let style of styles | styleSearch: search"
        class="container-fluid btn text-left chatroom-item list-group-item"
        [ngClass]="{'active': style === selectedStyle}"
      >
        <style-item
          [style]='style'
          (selectStyle)="select($event)"
          (rename)="rename(style, $event)"
          (delete)="delete($event)"
          [isSelected]="style === selectedStyle"
        ></style-item>
      </li>
    </ul>
  </div>
</div>