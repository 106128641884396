/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender, lcheng
 */

import { Component, ViewChild, OnInit } from "@angular/core";
import { UserManagementService } from "./user-management.service";
import { IUser, Alert, AlertLevel, Cookie } from "companion";
import { UserFormComponent } from "../user-profile/user-form.component";
import { IRole, Companion } from "companion";
import { GroupManagementService } from "../group-management/group-management.service";
import { Dispatcher, ActionType } from "client/scripts/shared/services/dispatcher";
import { NavBarMenuItemKey } from "./../layout/nav-bar/nav-bar.service";
import { AlertService } from "../alert/alert.service";
import { DatatableComponent } from "@swimlane/ngx-datatable";

@Component({
  selector: "user-management-page",
  templateUrl: "./user-management.template.html"
})
export class UserManagementPageComponent implements OnInit {
  // the user array
  rows: any[] = [];
  selected: any = [];
  selectedUser: IUser;

  /**
   *  available roles to use
   */
  availableRoles: any[];

  /**
   * hold the filter value
   */
  filterValue: string;

  // The users table
  @ViewChild("userTable")
  table: any;

  /**
   * Create edit/reservation user window.
   */
  @ViewChild(UserFormComponent)
  modalForm: UserFormComponent;

  @ViewChild(DatatableComponent) userTable: DatatableComponent;

  /**
   * Hold my role information here.
   */
  myUserRoles: any[];

  /**
   * set the limit
   */
  limit = 10;

  /**
   * User profile service
   */
  private userManagementService: UserManagementService;

  constructor(
    userManagementService: UserManagementService,
    private groupManagementService: GroupManagementService,
    private alertService: AlertService
  ) {
    this.userManagementService = userManagementService;
    Dispatcher.register(ActionType.LoadUserData, this.getUsers.bind(this));
  }

  ngOnInit() {
    let currentUser = Companion.getUserService().currentUser;
    if (!currentUser.isAuthenticated || !(currentUser.permissions.createUsers > 0)) {
      Dispatcher.dispatch(ActionType.OpenDefaultScreen);
      return;
    }
    Dispatcher.dispatch(ActionType.LoadHostUserNavBar, { active: NavBarMenuItemKey.UserManagement });
    Dispatcher.dispatch(ActionType.LoadTopBarMenu);
    this.getUsers();
    this.getRoles();
    this.getSubGroups();
    if(Cookie.getCookie("userLimit"))
    {
      this.limit = Number(Cookie.getCookie("userLimit"));
    }
  }

  onSelect($event: Event) {
    this.selectedUser = this.selected[0];
  }

  /**
   * Create user form
   */
  createUser() {
    Dispatcher.dispatch(ActionType.OpenUserProfileForm, { isEditing: false });
  }

  /**
   * Delete a user
   */
  deleteUser() {
    if (this.selectedUser) {
      var self = this;
      // confirm that we want to delete the user
      AlertService.createAlertWithButtons("Are you sure you want to delete " + this.selectedUser.username + "?", {
        confirm: {
          label: "Yes",
          className: "btn-success"
        },
        cancel: {
          label: "No",
          className: "btn-default"
        }
      }).then((result: boolean) => {
        if (result) {
          this.userManagementService.deleteUser(this.selectedUser.username).then((success: boolean) => {
            if (success) {
              // update the users after a delete
              this.getUsers();
              this.selectedUser = null;
            }
          }).catch((error: any) => {
            console.log(`Failed to deleteUser: ${JSON.stringify(error)}`);
          });
        }
      });
    }
  }

  /**
   * Force password reset
   */
  forcePasswordReset(user: IUser) {
    if (user) {
      var self = this;
      // confirm that we want to force reset the user
      AlertService.createAlertWithButtons(
        "Are you sure you want to force a password reset for " +
          user.username +
          "?" +
          "The user will be prompted to reset their password before logging in.",
        {
          confirm: {
            label: "Yes",
            className: "btn-success"
          },
          cancel: {
            label: "No",
            className: "btn-default"
          }
        }
      ).then((result: boolean) => {
        if (result) {
          this.userManagementService.forcePasswordReset(user.username).then((success: boolean) => {
            if (success) {
              Dispatcher.dispatch(ActionType.Alert, {
                alert: new Alert(
                  "FORCE_PASSWORD_SUCCESS",
                  "The password of user " + user.username + " has been force reset successfully.",
                  AlertLevel.success
                )
              });
            } else {
              Dispatcher.dispatch(ActionType.Alert, {
                alert: new Alert("FORCE_PASSWORD_FAILED", "The password force reset failed.", AlertLevel.error)
              });
            }
          }).catch((error: any) => {
            console.log(`Failed to forcePasswordReset: ${JSON.stringify(error)}`);
          });
        }
      });
    }
  }

  get hasPermissionToEdit(): boolean {
    return this.checkPermissionToEdit(this.selectedUser);
  }

  get hasPermissionToDelete(): boolean {
    let result: boolean = true;
    if (!this.selectedUser) {
      return false;
    }
    if (this.selectedUser["_id"] == Companion.getUserService().currentUser["_id"]) {
      return false;
    }
    if (!this.userManagementService.hasPermissionToDelete(this.selectedUser)) {
      return false;
    }
    if (
      this.selectedUser.roles[0] &&
      !_.some(this.availableRoles, (role: IRole) => {
        return role["_id"] == this.selectedUser.roles[0]["_id"];
      })
    ) {
      return false;
    }
    return result;
  }

  checkPermissionToEdit(user: IUser) {
    let result: boolean = true;
    if (!user) {
      return false;
    }
    if (user["_id"] == Companion.getUserService().currentUser["_id"]) {
      return false;
    }
    if (!this.userManagementService.hasPermissionToEdit(user)) {
      return false;
    }
    if (
      !_.includes(_.map(this.availableRoles, "_id"), user.roles[0]["_id"]) &&
      !_.includes(_.map(this.availableRoles, "_id"), user.roles[0])
    ) {
      return false;
    }
    return result;
  }

  /**
   * edit a user
   */
  editUser() {
    // get the selected user
    if (this.selectedUser) {
      // update the form info with the selected user
      let user = _.clone(this.selectedUser);
      user.roles = _.map(user.roles, "_id");
      user.groups = _.map(user.groups, "_id");
      Dispatcher.dispatch(ActionType.OpenUserProfileForm, { isEditing: true, user: user });
    }
  }

  /**
   * enable a user
   */
  enableUser(user: IUser) {
    if (user) {
      this.userManagementService.disableUser(user, false).then(() => {
        // update the users after a disable/enable
        this.getUsers();
      }).catch((error: any) => {
        console.log(`Failed to disableUser: ${JSON.stringify(error)}`);
      });
    }
  }

  /**
   * disable a user
   */
  disableUser(user: IUser) {
    if (user) {
      // confirm that we want to force reset the user
      AlertService.createAlertWithButtons(
        "Are you sure you want disable user " +
          user.username +
          "?" +
          "The user will be logged out and the account will be disabled",
        {
          confirm: {
            label: "Yes",
            className: "btn-success"
          },
          cancel: {
            label: "No",
            className: "btn-default"
          }
        }
      ).then((result: boolean) => {
        if (result) {
          this.userManagementService.disableUser(user, true).then(() => {
            // update the users after a disable
            this.getUsers();
          }).catch((error: any) => {
            console.log(`Failed to disableUser: ${JSON.stringify(error)}`);
          });
        }
      });
    }
  }

  formSubmitted($event: any) {
    // nothing needed
  }

  getUsers() {
    // get the users
    this.userManagementService.getMyAccessibleUsersWithRolesAndGroups()
    .then((data: any[]) => {
      _.forEach(data, (userData: any) => {
        if (userData.roles) {
          userData.roleNames = _.join(_.map(userData.roles, "name"), ", ");
        }
        if (userData.groups) {
          userData.groupNames = _.join(_.map(userData.groups, "name"), ", ");
        }

        if (userData.lockoutUntil && new Date(userData.lockoutUntil) > new Date()) {
          userData.lockoutDisplay = Math.round(
            (new Date(userData.lockoutUntil).getTime() - new Date().getTime()) / 60000
          );
        } else {
          userData.lockoutDisplay = "";
        }

        if (userData.lastLoginDate) {
          const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          let diffDays: number = Math.floor(
            Math.abs((new Date(userData.lastLoginDate).getTime() - new Date().getTime()) / oneDay)
          );

          if (diffDays == 0) {
            userData.lastActive = "Today";
          } else if (diffDays == 1) {
            userData.lastActive = "Yesterday";
          } else {
          userData.lastActive = diffDays + " Days Ago";
          }
        } else {
          userData.lastActive = "";
        }

        userData.isDisabled ? (userData.accountStatus = "Disabled") : (userData.accountStatus = "Active");
      });

      let results = data;
      // filter our data
      if(this.filterValue)
      {
        results = _.filter(data, (user: IUser) => {
          return user.username.toLowerCase().indexOf(this.filterValue) > -1 || !this.filterValue;
        });
      }

      this.updateRows(results);
    }).catch((error: any) => {
      console.log(`Failed to getMyAccessibleUsersWithRolesAndGroups: ${JSON.stringify(error)}`);
    });
  }

  /**
   * This is used by ngx-datatable-column for lockedOutUntil and others. It checks the actual displayed value.
   * NOTE: using sortDirection did not appear to work so it is ignored i the parameters list. This seems to function correctly.
   * @param valueA The A side value
   * @param valueB The B side value
   * @returns 1,0,-1 to indicate which is larger
   */
  protected numericSort(valueA,valueB): number {
    // Return -1|0|1
    const leftIsBefore = 1;
    const rightIsBefore = -1;
    const bothSame = 0;

    if (valueA > valueB) {
      return 1;
    }else if (valueA < valueB) {
      return -1;
    }else {
      return 0;
    }
  }

  /**
   * This is used by ngx-datatable-column for lastActive. It checks the loginDate instead of the field in the table.
   * NOTE: using sortDirection did not appear to work so it is ignored i the parameters list. This seems to function correctly.
   * @param valueA The A side lastActive modified text
   * @param valueB The B side lastActive modified text
   * @param rowA The A side object
   * @param rowB The B side object.
   * @returns 1,0,-1 to indicate which is larger
   */
  protected lastActiveSort(valueA, valueB, rowA: IUser, rowB: IUser) : number {
    // Return -1|0|1
    const leftIsBefore = 1;
    const rightIsBefore = -1;
    const bothSame = 0;

    let leftDatum = new Date(rowA.lastLoginDate);
    let rightDatum = new Date(rowB.lastLoginDate);

    if (leftDatum > rightDatum) {
      return 1;
    }else if (leftDatum < rightDatum) {
      return -1;
    }else {
      return 0;
    }
  }

  getSubGroups() {
    // get the groups
    this.groupManagementService.getSubGroups().catch((error: any) => {
        console.log(`Failed to getSubGroups : ${JSON.stringify(error)}`);
      })
  }

  getRoles() {
    // get the roles
    this.userManagementService.getRoles().then((data: IRole[]) => {
      this.availableRoles = data;
    }).catch((error: any) => {
      console.log(`Failed to getRoles: ${JSON.stringify(error)}`);
    });
  }

  updateRows(users: IUser[]) {
    this.rows = users;
  }

  setLimit(limit : number)
  {
    this.limit = limit;
    // update COOKIE
    Cookie.setCookie("userLimit", limit.toString());
  }

  /**
   * filter table by name
   */
  updateFilter(event) {
    this.filterValue = event.target.value.toLowerCase();
    // filter our data
    const results = _.filter(this.userManagementService.users, (user: IUser) => {
      return user.username.toLowerCase().indexOf(this.filterValue) > -1 || !this.filterValue;
    });
    // update rows
    this.updateRows(results);
    // Whenever the filter changes, always go back to the first page
    this.userTable.offset = 0;
  }
}
