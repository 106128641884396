<ng-container *ngIf="appLoaded">
  <alert-box></alert-box>
  <router-outlet></router-outlet>
  <login-modal></login-modal>
  <dialog-container-modal
    [id]="'pdf-viewer-modal'"
    [cssClass]="'modal-lg'"
    [hasFooter]="true"
    [hasSubmitButton]="true"
    [hasExitButton]="false"
    [footerSubmitClass]="'btn-lg btn-success'"
    [footerSubmitText]="'Acknowledge'"
  >
    <pdf-viewer></pdf-viewer>
  </dialog-container-modal>
  <dialog-container-modal [id]="'notification'">
    <notification></notification>
  </dialog-container-modal>
  <img
    id="video-muted-placeholder"
    crossorigin="anonymous"
    [attr.src]="rtcClient.avatarImageSrc"
    hidden
  />
  <img
    *ngIf="videoFilter"
    id="virtual-background-image"
    crossorigin="anonymous"
    [attr.src]="videoFilter.virtualBackgroundImage"
    hidden
  />
</ng-container>
