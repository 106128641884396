/**
 * Copyright Compunetix Incorporated 2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  jporter
 */

/**
 * This enum identifies the various states an async task can be in.
 */
export enum AsyncTaskStatus {
    // Unknown is reserved for the initialization or failure to retrieve data. Services who set status should not use this item.
    unknown,
    // Pending is the default state for an async task that is created. It identifies that someone is working on it.
    pending,
    // Complete is the finished successfully state for an async task. It identifies that the request is done and the data can be retrieved.
    complete,
    // Failed is the finished unsuccessfully state for an async task. It identifies that there was an error in procesing the request and no data will be returned.
    failed
  }