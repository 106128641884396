/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { InspectPanelComponent } from "./inspect-panel.component";
import { EndpointWithStatsPipe } from "./endpoint-with-stats.pipe";
import { InspectThumbnailComponent } from "./inspect-thumbnail.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
  imports: [BrowserModule, SharedModule],
  declarations: [InspectPanelComponent, EndpointWithStatsPipe, InspectThumbnailComponent],
  exports: [InspectPanelComponent, InspectThumbnailComponent]
})
export class InspectModule {}
