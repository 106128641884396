/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */
import {Component, Input, OnInit} from "@angular/core";

export interface IBarChartItem {
  label: string;
  value: number;
  barColor: string;
}

// Realtime status bar chart, used in Overview, Agent's and Customer's
@Component({
  selector: "bar-chart",
  templateUrl: "./bar-chart.component.html",
  styleUrls: ["./bar-chart.component.scss"],
})
export class BarChartComponent implements OnInit {
  @Input() set items(value: IBarChartItem[]) {
    this._items = value;
    this.calculateMax();
  }
  get items(): IBarChartItem[] {
    return this._items;
  }

  private _items: IBarChartItem[];
  max: number;

  ngOnInit(): void {
  }

  calculateMax() {
    if (this.items) {
      this.max = Math.max(...this.items.map(item => item.value));
    }
  }
}
