<ng-progress #progressBar></ng-progress>
<aw-wizard
  [navBarLayout]="'large-empty-symbols'"
  aria-live="off"
  *ngIf="localizationService.myLocalizationData"
>
  <aw-wizard-step *ngIf="testerStep == wizardStepClass['BrowserTest']">
    <ng-template awWizardStepSymbol>
      <i class="fas fa-shield-alt" aria-label="Step of Browser Compatibility for Secure WebRTC Communication"></i>
    </ng-template>
    <div class="row">
      <h1 class="col-md-12 text-center h4">Browser Compatibility for Secure WebRTC Communication</h1>
      <div class="offset-sm-1 col-sm-10">
        <p>This product requires latest WebRTC Technology in order to support secure web audio/video communication.
          Click the button below to test your browser compatibility.</p>
        <p>
          <a
            class="btn btn-lg"
            (click)="startTest('browser')"
          >Press here to start testing: <i
              class="fas fa-play-circle"
              [hidden]="viewModel.tests.browser.isTesting"
            ></i><b
              [hidden]="!viewModel.tests.browser.isTesting"
              class="text-primary"
            >Testing. Please Wait.</b></a>
        </p>
        <h5 class="ml-2">Test Result: <b
            class=""
            [ngClass]="{'text-success':viewModel.tests.browser.isPassed, 'text-danger': !viewModel.tests.browser.isPassed}"
          >{{localizationService.myLocalizationData?.errorMessages?.[viewModel.tests.browser.result] || viewModel.tests.browser.result}}</b></h5>
        <div
          class="offset-md-1 col-md-10 text-center border p-1"
          [hidden]="!viewModel.tests.browser.result || viewModel.tests.browser.isPassed || !viewModel.tests.browser.solutionMessage"
        >
          <p>{{viewModel.tests.browser.solutionMessage}}</p> <a
            tabindex=""
            class="btn btn-secondary"
            (click)="viewModel.tests.browser.solutionCancel()"
          >Cancel</a><a
            tabindex=""
            class="btn btn-success"
            (click)="viewModel.tests.browser.solutionConfirm()"
          >{{viewModel.tests.browser.solutionConfirmButtonText}}</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button
          type="button"
          class="btn btn-primary"
          awNextStep
        >Next Step</button>
      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step *ngIf="testerStep == wizardStepClass['CameraTest']">
    <ng-template awWizardStepSymbol>
      <i class="fas fa-video" aria-label="Step of Camera Capability Checking"></i>
    </ng-template>
    <div class="row">
      <h1 tabindex="0" class="col-md-12 text-center h4">Camera Capability Checking</h1>
      <div class="offset-sm-1 col-sm-10">
        <p>This product requires access to your camera. Your browser may prompt a dialog for your consent. Please click
          "Allow" in that dialog. Click the button below to test your camera capability.</p>
        <p
          class="text-center"
          [hidden]="viewModel.tests.camera.isPassed"
        ><button
            class="btn btn-primary"
            tabindex=""
            (click)="startTest('camera')"
            [hidden]="viewModel.tests.camera.isTesting"
          >{{!viewModel.tests.camera.started ? 'Press here to start testing: ' : 'Press here to test again: '}}<i
              class="fas fa-play-circle"
            ></i></button>
          <button
            class="btn btn-primary"
            tabindex=""
            [hidden]="!viewModel.tests.camera.isTesting"
            (click)="stopTest('camera')"
          >Testing. Please Wait. <i class="fas fa-stop-circle"></i></button></p>
        <div
          class="offset-md-2 col-md-8 text-center border p-1 confirm-box"
          *ngIf="viewModel.tests.camera.isConfirming"
          tabindex="0"
        >
          <ng-container *ngFor="let message of viewModel.tests.camera.confirmMessages; let i = index">
            <h4 *ngIf="i===0">{{message}}</h4>
            <p *ngIf="i > 0">{{message}}</p>
          </ng-container>
          <button
            tabindex=""
            class="btn btn-danger"
            (click)="viewModel.tests.camera.confirm(false)"
          >{{viewModel.tests.camera.hasNextOption ? 'NO. Try another device.' : 'NO'}}</button><button
            tabindex=""
            class="btn btn-success"
            (click)="viewModel.tests.camera.confirm(true)"
          >YES</button>
        </div>
        <h4
          class="offset-md-2 col-md-8 text-center border p-1"
          [hidden]="!viewModel.tests.camera.pendingUserAction"
        >
          <p>{{viewModel.tests.camera.promptMessage}}</p>
          <ul class="list-group">
            <button
              *ngFor="let optionKey of viewModel.tests.camera.options | keys"
              class="list-group-item btn btn-secondary"
              (click)="viewModel.tests.camera.selectionChange(optionKey)"
            >
              {{viewModel.tests.camera.options[optionKey]}}
          </button>
          </ul>
        </h4>
        <p>
          <video
            muted
            autoplay
            playsinline
            #pipVideo
            class="full-width"
            [hidden]="!viewModel.tests.camera.isTesting && !viewModel.tests.camera.isPassed"
          ></video>
        </p>
        <h5
          class="mt-1 ml-2"
          [hidden]="!viewModel.tests.camera.started || viewModel.tests.camera.isTesting"
        >Camera Capability Test Result: <b
            class=""
            [ngClass]="{'text-success':viewModel.tests.camera.isPassed, 'text-danger': !viewModel.tests.camera.isPassed}"
          >{{localizationService.myLocalizationData?.errorMessages?.[viewModel.tests.camera.result] || viewModel.tests.camera.result}}</b>
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button
          *ngIf="isEmbeded"
          type="button"
          class="btn btn-secondary"
          (click)="finish()"
        >Skip</button>
        <button
          [disabled]="!viewModel.tests.camera.isPassed"
          type="button"
          class="btn btn-success"
          (click)="nextStep()"
        >Go to next step</button>
      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step *ngIf="testerStep == wizardStepClass['SpeakerTest']">
    <ng-template awWizardStepSymbol>
      <i class="fas fa-headset" aria-label="Step of Microphone and Speaker Capability Checking"></i>
    </ng-template>
    <div class="row">
      <h1 class="col-md-12 text-center h4">Microphone and Speaker Capability Checking</h1>
      <div class="offset-sm-1 col-sm-10">
        <p>This product requires access to your microphone and speaker. Your browser may prompt a dialog for your
          consent. Please click
          "Allow" in that dialog. Click the button below to test your microphone and speaker capability.</p>
        <p
          class="text-center"
          [hidden]="viewModel.tests.speaker.isPassed"
        ><button
            class="btn btn-primary"
            tabindex=""
            (click)="startTest('speaker')"
            [hidden]="viewModel.tests.speaker.isTesting"
          >{{!viewModel.tests.speaker.started ? 'Press here to start testing: ' : 'Press here to test again: '}}<i
              class="fas fa-play-circle"
            ></i></button>
          <button
            class="btn btn-primary"
            tabindex=""
            [hidden]="!viewModel.tests.speaker.isTesting"
            (click)="stopTest('speaker')"
          >Testing. Please Wait. <i class="fas fa-stop-circle"></i></button></p>
        <div
          class="offset-md-2 col-md-8 text-center border p-1 confirm-box"
          *ngIf="viewModel.tests.speaker.isConfirming"
          tabindex="0"
        >
          <ng-container *ngFor="let message of viewModel.tests.speaker.confirmMessages; let i = index">
            <h4 *ngIf="i===0">{{message}}</h4>
            <p *ngIf="i > 0">{{message}}</p>
          </ng-container>
          <button
            tabindex=""
            class="btn btn-danger"
            (click)="viewModel.tests.speaker.confirm(false)"
          >{{viewModel.tests.speaker.hasNextOption ? 'NO. Try another device.' : 'NO'}}</button><button
            tabindex=""
            class="btn btn-success"
            (click)="viewModel.tests.speaker.confirm(true)"
          >YES</button>
        </div>
        <h4
          class="offset-md-2 col-md-8 text-center border p-1"
          [hidden]="!viewModel.tests.speaker.pendingUserAction"
        >
          <p>{{viewModel.tests.speaker.promptMessage}}</p>
          <ul class="list-group">
            <button
              *ngFor="let optionKey of viewModel.tests.speaker.options | keys"
              class="list-group-item btn btn-secondary"
              (click)="viewModel.tests.speaker.selectionChange(optionKey)"
            >
              {{viewModel.tests.speaker.options[optionKey]}}
            </button>
          </ul>
        </h4>
        <h5
          class="mt-1 ml-2 row"
          *ngIf="viewModel.tests.speaker.isTesting"
        ><span
            *ngIf="viewModel.tests.speaker.stream"
            class=""
          >Output Level: </span>
          <volume-meter
            class="col-md-8"
            #speakerVolumeMeter
            *ngIf="viewModel.tests.speaker.stream"
            [id]="'speaker-volume-meter'"
            [stream]="viewModel.tests.speaker.stream"
            [height]="5"
          ></volume-meter>
        </h5>
        <h5
          class="mt-1 ml-2"
          [hidden]="!viewModel.tests.speaker.started || viewModel.tests.speaker.isTesting"
        >Speaker Capability Test Result: <b
            class=""
            [ngClass]="{'text-success':viewModel.tests.speaker.isPassed, 'text-danger': !viewModel.tests.speaker.isPassed}"
          >{{localizationService.myLocalizationData?.errorMessages?.[viewModel.tests.speaker.result] || viewModel.tests.speaker.result}}</b>
        </h5>
      </div>
    </div>
    <div
      class="row mt-1"
      [hidden]="!viewModel.tests.speaker.isPassed"
    >
      <div class="offset-sm-1 col-sm-10">
        <p
          class="text-center"
          [hidden]="viewModel.tests.microphone.isPassed"
        ><button
            class="btn btn-primary"
            tabindex=""
            (click)="startTest('microphone')"
            [hidden]="viewModel.tests.microphone.isTesting"
          >{{!viewModel.tests.microphone.started ? 'Press here to start testing: ' : 'Press here to test again: '}}<i
              class="fas fa-play-circle"
            ></i></button>
          <button
            class="btn btn-primary"
            tabindex=""
            [hidden]="!viewModel.tests.microphone.isTesting"
            (click)="stopTest('microphone')"
          >Testing. Please Wait. <i class="fas fa-stop-circle"></i></button></p>
        <div
          class="offset-md-2 col-md-8 text-center border p-1 confirm-box"
          *ngIf="viewModel.tests.microphone.isConfirming"
          tabindex="0"
        >
          <p>Read this line at your normal voice level: <b>This is a test of my microphone audio level.</b>
          </p>
          <ng-container *ngFor="let message of viewModel.tests.microphone.confirmMessages; let i = index">
            <h4 *ngIf="i===0">{{message}}</h4>
            <p *ngIf="i > 0">{{message}}</p>
          </ng-container>

          <button
            tabindex=""
            class="btn btn-danger"
            (click)="viewModel.tests.microphone.confirm(false)"
          >{{viewModel.tests.microphone.hasNextOption ? 'NO. Try another device.' : 'NO'}}</button><button
            tabindex=""
            class="btn btn-success"
            (click)="viewModel.tests.microphone.confirm(true)"
          >YES</button>
        </div>
        <h4
          class="offset-md-2 col-md-8 text-center border p-1"
          [hidden]="!viewModel.tests.microphone.pendingUserAction"
        >
          <p>{{viewModel.tests.microphone.promptMessage}}</p>
          <ul class="list-group">
            <button
              *ngFor="let optionKey of viewModel.tests.microphone.options | keys"
              class="list-group-item btn btn-secondary"
              (click)="viewModel.tests.microphone.selectionChange(optionKey)"
            >
              {{viewModel.tests.microphone.options[optionKey]}}
            </button>
          </ul>
        </h4>
        <h5
          class="mt-1 ml-2 row"
          *ngIf="viewModel.tests.microphone.isTesting"
        ><span
            *ngIf="viewModel.tests.microphone.stream"
            class=""
          >Input Level: </span>
          <volume-meter
            class="col-md-8"
            #micVolumeMeter
            *ngIf="viewModel.tests.microphone.stream"
            [id]="'mic-volume-meter'"
            [stream]="viewModel.tests.microphone.stream"
            [height]="15"
          ></volume-meter>
        </h5>
        <h5
          class="mt-1 ml-2"
          [hidden]="!viewModel.tests.microphone.started || viewModel.tests.microphone.isTesting"
        >Microphone Capability Test Result: <b
            class=""
            [ngClass]="{'text-success':viewModel.tests.microphone.isPassed, 'text-danger': !viewModel.tests.microphone.isPassed}"
          >{{localizationService.myLocalizationData?.errorMessages?.[viewModel.tests.microphone.result] || viewModel.tests.microphone.result}}</b>
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="previousStep()"
        >Previous Step</button>
        <button
          [disabled]="!viewModel.tests.microphone.isPassed"
          type="button"
          class="btn btn-success"
          (click)="nextStep()"
        >Go to next step</button>
      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step *ngIf="testerStep == wizardStepClass['NetworkTest']">
    <ng-template awWizardStepSymbol>
      <i class="fas fa-wifi" aria-label="Step of Network Connectivity Checking"></i>
    </ng-template>
    <div class="row">
      <h1 class="col-md-12 text-center h4">Network Connectivity Checking</h1>
      <div class="offset-sm-1 col-sm-10">
        <p>This product requires stable network connection. Click the button below to test your network connectivity.
        </p>
        <p
          class="text-center"
          [hidden]="viewModel.tests.network.isPassed"
        ><button
            class="btn btn-primary"
            tabindex=""
            (click)="startTest('network')"
            [hidden]="viewModel.tests.network.isTesting"
          >{{!viewModel.tests.network.started ? 'Press here to start testing: ' : 'Press here to test again: '}}<i
              class="fas fa-play-circle"
            ></i></button>
          <button
            class="btn btn-primary"
            tabindex=""
            [hidden]="!viewModel.tests.network.isTesting"
            (click)="stopTest('network')"
          >Testing. Please Wait. <i class="fas fa-stop-circle"></i></button></p>
        <h5
          class="mt-1 ml-2"
          [hidden]="!viewModel.tests.network.started || viewModel.tests.network.isTesting"
        >Network Connectivity Test Result: <b
            class=""
            [ngClass]="{'text-success':viewModel.tests.network.isPassed, 'text-danger': !viewModel.tests.network.isPassed}"
          >{{localizationService.myLocalizationData?.errorMessages?.[viewModel.tests.network.result] || viewModel.tests.network.result}}</b>
        </h5>
        <div *ngIf="viewModel.tests.network.started && viewModel.tests.network.isPassed === false">
          <p
            class="ml-2"
            *ngFor="let log of viewModel.tests.network.logs"
          >
            {{log}}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="previousStep()"
        >Previous Step</button>
        <button
          [disabled]="!viewModel.tests.network.isPassed"
          type="button"
          class="btn btn-success"
          (click)="nextStep()"
        >Go to next step</button>
      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step *ngIf="testerStep == wizardStepClass['Finished']">
    <ng-template awWizardStepSymbol>
      <i class="fas fa-check"></i>
    </ng-template>
    <h4 class="col-md-12 text-center">All Done!</h4>
    <div class="offset-sm-1 col-sm-10">
      <p>Great Job! You are 100% powered up for the best audio/video communication experience. We are honored to serve
        you. Enjoy!
      </p>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button
          *ngIf="isEmbeded"
          type="button"
          class="btn btn-success"
          (click)="finish()"
        >Finish</button>
      </div>
    </div>
  </aw-wizard-step>
</aw-wizard>