<!--
    Copyright Compunetix Incorporated 2017-2023
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, amaggi
-->

<!--HEADER-->
<div class="d-flex">
  <div class="col-2 thumbnail left-padding-only">
    <div class="avatar"
        (click)="openOperatorInfoModal()">
      <img
        class="avatar-image"
        alt="Avatar Image"
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        [ngStyle]="{'background-image': 'url(' + getAvatarImageUrl() + ')'}"
      >
      <div>
        <ng-container *ngIf="showCallQuality()">
        <signal-bars
          [quality]="epCallQuality()"
          [hidden]="!epCallQuality()">
        </signal-bars>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-10 details no-padding">
    <div class="participant-list-row d-flex h-100">
      <div class="col-8 no-padding no-overflow">
        <label [title]="name" class="text-capitalize">{{ name }}</label>
        <div class="control-section col-2 no-padding">
          <button
            *ngIf="
            endpoint.rtcId === endpointService.myEndpoint.rtcId" 
            title="Dial out"
            tabindex
            class="btn btn-link text-info p-0"
            [hidden]="!localizationService.myLocalizationData?.twilioConfig?.allowOperatorDialout"
            [disabled]="!canMakePhoneCall || disableActions"
            (click)="dialOut()"
          >
            <i
              class="fas fa-phone"
              style="font-size: 1.3rem;"
              aria-hidden="true"
            ></i>
          </button>
          <button
            *ngIf="endpoint.rtcId === endpointService.myEndpoint.rtcId && connectedToVoice"
            title="Keypad"
            tabindex
            class="btn btn-link text-info p-0"
            (click)="openKeypad()"
          >
            <i
              class="fas fa-th"
              aria-hidden="true"
            ></i>
          </button>
          <a
            *ngIf="endpoint.rtcId !== endpointService.myEndpoint.rtcId  && !!localizationService.myLocalizationData.chat_panel"
            tabindex
            [title]="localizationService.myLocalizationData.participant_panel?.chatWithConjunctionText||'Chat With' + endpoint.uiName"
            class="btn btn-link p-0"
            (click)='peerChat()'
          >
            <i class="fa-regular fa-comment" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="present-section col-4 no-padding no-overflow">
        <label class="tag" [ngClass]="'text-' + (endpoint.status | labelStyle)">
          <span [title]="localizationService.myLocalizationData.presenceStatus[presenceStatusClass[endpoint.status]] || presenceStatusClass[endpoint.status]"
                class="text-uppercase"
                *ngIf="endpoint.status !== presenceStatusClass.busy"
          >
            {{localizationService.myLocalizationData.presenceStatus[presenceStatusClass[endpoint.status]] || presenceStatusClass[endpoint.status] }}
          </span>
          <span [title]="localizationService.myLocalizationData.participant_panel?.participantStatusText || 'CONFERENCING'"
                *ngIf="endpoint.status === presenceStatusClass.busy"
          >
            {{ localizationService.myLocalizationData.participant_panel?.participantStatusText || "CONFERENCING"}}
          </span>
          <span title="{{getTargetEndpointsList()}}"
                *ngIf="getAssociatedConfName() && endpoint.status === presenceStatusClass.busy"
          >
            {{getTargetEndpointsList() | truncate:12:'...'}}
          </span>
        </label>
      </div>
    </div>
  </div>
</div>

<!--BUTTONS-->
<div
  class="buttons-section margin-top text-center"
>
  <button
    tabindex
    [title]="localizationService.myLocalizationData.participant_panel?.connectButtonText || 'Connect'"
    class="btn btn-outline-primary btn-control wrapOrHide"
    *ngIf="showConnectButton()"
    [disabled]="disableActions"
    (click)='peerVideoChat()'
  >
    {{ localizationService.myLocalizationData.participant_panel?.connectButtonText || 'Connect'}}
  </button>
  <button
    tabindex
    [title]="localizationService.myLocalizationData.participant_panel?.selectButtonText || 'Select'"
    class="btn btn-outline-primary btn-control wrapOrHide"
    *ngIf="showSelectButton()"
    [disabled]="disableActions"
    (click)='peerVideoChat()'
  >
    {{ localizationService.myLocalizationData.participant_panel?.selectButtonText || 'Select'}}
  </button>
  <button
    tabindex
    [title]="localizationService.myLocalizationData.participant_panel?.unselectButtonText || 'Unselect'"
    class="btn btn-outline-primary btn-control wrapOrHide"
    *ngIf="endpointService.connectingOperator && 
           endpoint.rtcId === endpointService.connectingOperator?.endpoint.rtcId && 
           endpointService.connectingOperator?.isCaller === true"
    [disabled]="disableActions"
    (click)='cancelCall()'
  >
    {{ localizationService.myLocalizationData.participant_panel?.unselectButtonText || 'Unselect'}}
  </button>
  <button
    [title]="localizationService.myLocalizationData.participant_panel?.disconnectButtonText || 'Disconnect'"
    tabindex
    class="btn btn-outline-primary btn-control wrapOrHide"
    *ngIf="(!isMyEndpoint && endpointService.connectedWithMe(endpoint)) || (endpointService.myEpConnectedAsGuest() && isMyEndpoint)"
    [disabled]="disableActions"
    (click)='disconnectPeer()'
  >
    {{ localizationService.myLocalizationData.participant_panel?.disconnectButtonText || 'Disconnect'}}
  </button>
  <button
    tabindex
    [title]="localizationService.myLocalizationData.participant_panel?.startMonitorButtonText || 'Monitor'"
    class="btn btn-outline-primary btn-control wrapOrHide"
    *ngIf="ngIf_monitorButton()"
    [disabled]="disableActions"
    (click)='startMonitor()'
  >
    {{ localizationService.myLocalizationData.participant_panel?.startMonitorButtonText || "Start Monitoring"}}
  </button>
  <button
    tabindex
    [title]="localizationService.myLocalizationData.participant_panel?.whisperButtonText || 'Whisper'"
    class="btn btn-control wrapOrHide"
    [ngClass]="isWhispering ? 'btn-outline-success whispering-button' : 'btn-outline-primary'"
    *ngIf="!isMyEndpoint && hasPermissionToMonitor && endpointService.monitoring(endpoint)"
    [disabled]="disableActions"
    (mousedown)="pushToTalk({talk: true, endpoint: endpoint})"
    (mouseup)="pushToTalk({talk: false, endpoint: endpoint})"
  >
    {{ localizationService.myLocalizationData.participant_panel?.whisperButtonText || "Whisper"}}
  </button>
  <button
    tabindex
    [title]="localizationService.myLocalizationData.participant_panel?.exitMonitorButtonText || 'Exit Monitoring'"
    class="btn btn-outline-primary btn-control wrapOrHide"
    *ngIf="hasPermissionToMonitor && endpointService.monitoring(endpoint)"
    [disabled]="disableActions"
    (click)='exitMonitor()'
  >
    {{ localizationService.myLocalizationData.participant_panel?.exitMonitorButtonText || "Exit Monitor"}}
  </button>
</div>
