/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender
 */

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgWhiteboardModule } from 'ng-whiteboard';
import { ContentPresenterComponent } from './content-presenter.component';

import { ThumbnailComponent } from './thumbnails/thumbnail.component';

@NgModule({
  declarations: [
    ContentPresenterComponent, ThumbnailComponent
  ],
  imports: [
    BrowserModule, PdfViewerModule, NgWhiteboardModule
  ],
  providers: [],
  exports: [ContentPresenterComponent, ThumbnailComponent],
  bootstrap: [ContentPresenterComponent, ThumbnailComponent]
})
export class ContentPresenterModule { }
