/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import {Component, Output, EventEmitter, Input} from "@angular/core";
import { Languages } from "companion";
import { LocalizationService } from "../../localization/localization.service";

@Component({
  selector: "top-nav-language",
  templateUrl: "./top-nav-language.template.html"
})

/**
 * top nav language switch component in the login bar
 */
export class TopNavLanguageComponent {

  /**
   * emit event when language changed
   */
  @Output("languageChanged") languageChangedEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(public localizationService: LocalizationService) {
    // nothing to do
  }

  /**
   * get all supported language codes
   */
  getLanguageCodes(): string[] {
    return Object.keys(this.localizationService.myLocalizationData?.topnav?.language_selector?.options ?? [Languages.en]);
  }

  /**
   * handler on new language selected
   * @param code: string - new language code
   */
  selectLanguage(code: string) {
    this.languageChangedEmitter.emit(code);
  }
}
