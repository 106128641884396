<!--
    Copyright Compunetix Incorporated 2016-2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<nav
  class="navbar bg-footer-nav text-center d-print-none"
  id="footer-nav"
  *ngIf="localizationService.myLocalizationData.footer"
  [ngClass]="{'fixed-bottom': positionFixed, 'fadeIn': isNavShown, 'animated fadeOut': !isNavShown && !isMobileApp}"
  [style.backgroundColor]="localizationService.myLocalizationData.footer.backgroundColor"
  [style.fontFamily]="localizationService.myLocalizationData.footer.fontFamily"
  [style.borderColor]="borderColor"
  [style.borderTopWidth]="borderWidth"
>
  <div
    class="container"
  >
    <ul class="nav navbar-nav float-sm-left d-none d-md-block">
      <li
        class="nav-item"
        *ngFor="let menu of localizationService.myLocalizationData.footer.menu"
      >
        <a
          tabindex
          class="nav-link cursor-pointer"
          (click)="openMenu(menu)"
          [style.color]="localizationService.myLocalizationData.footer.fontColor"
        >
          {{menu.text}}
        </a>
      </li>
    </ul>
    <ul class="nav navbar-nav full-width">
      <li class="copyright" [ngClass]="{'mobile': isMobileApp}">
        <label
          class="nav-link"
          [style.color]="localizationService.myLocalizationData.footer.fontColor"
          [innerHTML]="copyright | safeHtml"
        ></label>
      </li>
    </ul>
    <ul class="nav navbar-nav  float-sm-right d-none d-md-block">
      <li
        class="nav-item"
        *ngFor="let menu of localizationService.myLocalizationData.footer.social_menu"
      >
        <a
          tabindex
          class="nav-link"
          [style.color]="localizationService.myLocalizationData.footer.fontColor"
          [attr.href]="menu.url"
          [innerHTML]="menu.text | safeHtml"
        ></a>
      </li>
    </ul>
  </div>
</nav>

<nav
  *ngIf="localizationService.myLocalizationData.print"
  class="navbar fixed-bottom d-none d-print-block text-center"
>
  <h1>
    {{printFooter}}
  </h1>
</nav>
