/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ConfigService } from "./config.service";
import { IConfig, IParameter, Companion, AlertLevel } from "companion";
import { AlertService } from "../alert/alert.service";
import { FormService } from "../form/form.service";
import { Dispatcher, ActionType } from "client/scripts/shared/services/dispatcher";
import { NavBarMenuItemKey } from "./../layout/nav-bar/nav-bar.service";

@Component({
  selector: "config-panel",
  templateUrl: "./config.template.html"
})

/**
 * config panel
 */
export class ConfigComponent implements OnInit {
  /**
   * current form for the selected config
   */
  currentForm: FormGroup;

  /**
   * current form for the selected config
   */
  currentParameters: { [key: string]: IParameter };

  /**
   * select language
   */
  load() {
    this.configService
      .getConfig()
      .then((lData: IConfig) => {
        this.currentParameters = this.configService.convertConfigToParameters(lData);
        this.currentForm = FormService.toFormGroup(this.currentParameters);
      })
      .catch((err: Error) => {
        this.alertService.createAlert("GET_CONFIG_FAIL", err.message, AlertLevel.warning);
      });
  }

  /**
   * config save
   */
  save() {
    this.alertService.createAlert("CONFIG_UPDATING", "Updating...", AlertLevel.success);
    FormService.updateParametersByForm(this.currentForm, this.currentParameters);
    this.configService
    .saveConfig(this.configService.convertParametersToConfig(this.currentParameters))
    .then((message: string) => {
      this.alertService.createAlert("CONFIG_UPDATED", "Config Updated Successfully!", AlertLevel.success);
      AlertService.createAlertWithButtons(
        "Do you want to restart the server with new configurations?" +
          " Notice: server will lose all temporary data and connections" +
          " and may take a few seconds to be available again.",
        {
          confirm: {
            label: "Confirm to restart",
            className: "btn-danger"
          },
          cancel: {
            label: "Cancel",
            className: "btn-default"
          }
        }
      ).then((result: any) => {
        if (result) {
          this.alertService.createAlert("SERVER_RESTART", "Server Restarting...", AlertLevel.success);
          this.configService
          .serverRestart()
          .then(() => {
            this.alertService.createAlert(
              "SERVER_RESTART_SUCCESS",
              "Server Restarted Successfully",
              AlertLevel.success
            );
          })
          .catch((err: Error) => {
            this.alertService.createAlert("SERVER_RESTART_FAILED", err.message, AlertLevel.warning);
          });
        }
      }).catch((error: any) => {
        console.log(`Failed to createAlertWithButtons: ${JSON.stringify(error)}`);
      });
    })
    .catch((err: Error) => {
      this.alertService.createAlert("CONFIG_SAVE_FAIL", err.message, AlertLevel.error);
    });
  }

  constructor(
    private configService: ConfigService,
    private formService: FormService,
    private alertService: AlertService
  ) {
    // nothing needed here
  }

  /**
   * update from with imported file
   */
  import(event: any) {
    var configData = JSON.parse(event.target.result);
    let uploadedConfigParameters = this.configService.convertConfigToParameters(configData);
    this.currentForm = FormService.toFormGroup(uploadedConfigParameters);
  }

  /**
   * export current view data
   */
  export() {
    let filename: string = "system_config.json";
    let blob: Blob = new Blob([JSON.stringify(this.configService.convertParametersToConfig(this.currentParameters))], {
      type: "text/plain;charset=utf-8"
    });
    saveAs(blob, filename);
  }

  /**
   * page init handler
   */
  ngOnInit() {
    if (
      !Companion.getUserService().currentUser.isAuthenticated ||
      !(Companion.getUserService().currentUser.permissions.seeMcuConfig > 0)
    ) {
      Dispatcher.dispatch(ActionType.OpenDefaultScreen);
      return;
    }
    Dispatcher.dispatch(ActionType.LoadHostUserNavBar, { active: NavBarMenuItemKey.SystemConfiguration });
    Dispatcher.dispatch(ActionType.LoadTopBarMenu);
    this.load();
  }

  cancel() {
    this.load();
  }
}
