/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";
import { VideoResolution } from "companion";
import { LocalizationService } from "../../localization/localization.service";

/**
 * struct resolution names.
 * @example Usage:
 * ```html
 * <p>
 *   {{ resolution | resolutionName }}
 * </p>
 * ```
 */
@Pipe({ name: "resolutionName" })
export class ResolutionNamePipe {
  transform(resolution: VideoResolution): any {
    var name: string;
    if (!resolution) {
      return this.localizationService.myLocalizationData.settings_panel.resolution_setting.autoTitle || "auto";
    }
    if (resolution.width && resolution.height) {
      name = resolution.width + " x " + resolution.height;
    }
    return name;
  }
  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }
}
