/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Injectable } from "@angular/core";
import { RestService } from "./../../../client/scripts/shared/services/rest.service";
import { Companion, IRole } from "companion";

@Injectable()
export class RoleManagementService {
  constructor(private restService: RestService) {}

  roles: IRole[] = [];

  // Get the role details
  getRole(roleId: string): Promise<IRole> {
    return new Promise((resolve: (response: IRole) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getRole", { roleId: roleId })
      .subscribe(
        (data: IRole) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get my roles
   */
  getMyRoles(): Promise<IRole[]> {
    return new Promise((resolve: (response: IRole[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getMyRoles", { user: Companion.getUserService().currentUser })
      .subscribe(
        (data: IRole[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   *  create a role
   */
  createRole(role: IRole): Promise<IRole> {
    return new Promise((resolve: (response: IRole) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/createRole", role)
      .subscribe(
        (data: IRole) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  /**
   * delete a role
   * promises a boolean indicating if the delete succeeded
   */
  deleteRole(role: IRole): Promise<any> {
    return new Promise((resolve: (response: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/deleteRole", role)
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * edit role details
   */
  editRole(role: IRole): Promise<void> {
    return new Promise((resolve: () => void, reject: (error: Error) => void) => {
      this.restService.post("/editRole", role).subscribe(
        (res: any) => {
          resolve();
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get my assignable roles
   */
  getMyAssignableRoles(): Promise<IRole[]> {
    return new Promise((resolve: (response: IRole[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getMyAssignableRoles", {
        user: Companion.getUserService().currentUser
      })
      .subscribe(
        (data: IRole[]) => {
          this.roles = data;
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  // Get the permissions
  getAllPermissions(): Promise<any[]> {
    return new Promise((resolve: (response: any[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getAllPermissions", {})
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
}
