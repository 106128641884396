/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */

import { Pipe } from "@angular/core";
import { format } from 'date-fns'

/**
 * Format time
 * @example Usage:
 * ```html
 * <p>
 *   {{ date | formatDate : 'YYYY, Do MMMM - hh:mm:ss a' }}
 * </p>
 * ```
 */
@Pipe({ name: "formatDate" })
export class FormatDatePipe {
  transform(date: string, formatToApply: string): any {
    if (!date) {
      return date;
    }

    return format(new Date(date), formatToApply);
  }
}
