<!--
    Copyright Compunetix Incorporated 2019
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->

<ng-container *ngIf="!isAccordion">
  <div
    class="list-group-item text-left container-fluid py-0"
    [hidden]="!isEditing"
  >
    <div class="row">
      <span class="col-8 text-left p-0">
        <a
          class="btn btn-link p-075 container-fluid text-left"
          (click)="edit(item)"
        >
          <ng-container *ngTemplateOutlet="listItemTemplate; context: {item: item}"></ng-container>
        </a>
      </span>
      <span class="col-4 text-right pl-0">
        <a
          class="btn btn-link text-primary p-075"
          (click)="edit(item)"
        >
          <i
            aria-hidden="true"
            class="fas fa-edit"
          >
          </i>
          Edit
        </a>
        <a
          class="btn btn-link text-danger p-075"
          (click)="delete(item)"
        >
          |
          <i
            aria-hidden="true"
            class="fas fa-minus-circle"
          >
          </i>
          Delete
        </a>
      </span>
    </div>
  </div>
  <a
    class="list-group-item btn btn-secondary text-left"
    (click)="select(item)"
    [ngClass]="{'text-white bg-primary': selected}"
    [hidden]="isEditing"
  >
    <ng-container *ngTemplateOutlet="listItemTemplate; context: {item: item}"></ng-container>
    <a
      class="btn btn-link p-0 absolute-right"
      [hidden]="!multiSelect"
    >
      <i
        aria-hidden="true"
        class="fas fa-plus-circle"
      >
      </i>
    </a>
  </a>
</ng-container>
<ng-container *ngIf="isAccordion">
  <dt>
    <a
      [attr.aria-expanded]="expanded"
      class="cursor-pointer accordion-title accordionTitle js-accordionTrigger"
      [ngClass]="{'is-expanded': expanded, 'py-05': minimized}"
      (click)="switchAccordion($event)"
    >
      <ng-container *ngTemplateOutlet="listItemTemplate; context: {item: item}"></ng-container>
    </a>
  </dt>
  <dd
    class="accordionItem"
    [ngClass]="{'is-expanded': expanded, 'is-collapsed': !expanded}"
    [attr.aria-hidden]="!expanded"
  >
    <div class="accordion-content">
      <ng-container *ngTemplateOutlet="listItemContentTemplate; context: {item: item}"></ng-container>
    </div>
  </dd>
</ng-container>