<!--
    Copyright Compunetix Incorporated 2017-2024
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender, jporter
-->

<div
  class="guest-queue-container pull-center"
  [style.top]="localizationService.myLocalizationData.connect_screen.top"
  [style.color]="localizationService.myLocalizationData.connect_screen.fontColor"
  [style.backgroundColor]="localizationService.myLocalizationData.connect_screen.backgroundColor"
  [style.width]="localizationService.myLocalizationData.connect_screen.logoSize ?
  localizationService.myLocalizationData.connect_screen.logoSize + '%' : '50%'"
>
  <div class="guest-queue-selection animated fadeIn mb-5 pb-5"
       [style.fontSize]="localizationService.myLocalizationData.connect_screen.fontSize"
       [hidden]="localizationService.isLoading">
    <img
      class="mb-3"
      *ngIf="localizationService.myLocalizationData.connect_screen.logo"
      [attr.src]="localizationService.myLocalizationData.connect_screen.logo"
      crossorigin="anonymous"
    />
    <h1
      *ngIf="localizationService.myLocalizationData.connect_screen.title"
      class="text-center mt-3"
      [innerHTML]="localizationService.myLocalizationData.connect_screen.title | trustHtml"
    >
    </h1>
    <h4
      *ngIf="localizationService.myLocalizationData.connect_screen.subtitle"
      class="text-center"
      id="connect-subtitle"
      [innerHTML]="localizationService.myLocalizationData.connect_screen.subtitle | trustHtml"
    >
    </h4>

    <div class="">
      <div class="row">
        <div [ngClass]="'col-' + 12 / queuesList?.length"
             *ngFor="let item of queuesList">
          <span class="guest-queue text-uppercase">{{getButtonLabel(item)}}</span>
        </div>
      </div>
      <div class="row">
        <div [ngClass]="'col-' + 12 / queuesList?.length"
             *ngFor="let item of queuesList">
          <a
            class="btn btn-success col-md-10 btn-lg text-uppercase shadow-lg mt-2"
            [title]="item.startButtonText"
            (click)="changeQueueAndConnect(item)"
            (touchstart)="changeQueueAndConnect(item)"
            (mousedown)="changeQueueAndConnect(item)"
            [ngStyle]="{
              'color': localizationService.myLocalizationData.connect_screen.connectButtonTextColor,
              'background-color': localizationService.myLocalizationData.connect_screen.connectButtonBackgroundColor,
              'border-color': localizationService.myLocalizationData.connect_screen.connectButtonBackgroundColor,
              'padding': '0.5em'
            }"
            [ngClass]="{'btn-round': !localizationService.myLocalizationData.connect_screen.useRectangleButton}"
          >
            {{item.startButtonText}}
          </a>

        </div>
      </div>
    </div>
  </div>
</div>



