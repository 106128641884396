/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */



import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

// Shows a button to update manually and last update date.
// Used in Overview, Agent's and Customer's
@Component({
  selector: "last-update",
  templateUrl: "./last-update.component.html",
  styleUrls: ["./last-update.component.scss"],
})
export class LastUpdateComponent implements OnInit {

  @Input() lastUpdate;
  @Output("refresh") refreshEmitter = new EventEmitter();

  timeZoneOffset: string;

  ngOnInit() {
    const tzo = -new Date().getTimezoneOffset();
    const dif = tzo >= 0 ? "+" : "-";
    const pad = function(num) {
      return (num < 10 ? "0" : "") + num;
    };

    this.timeZoneOffset = "UTC" + dif + pad(Math.floor(Math.abs(tzo) / 60)) + ":" + pad(Math.abs(tzo) % 60);
  }

  refresh() {
    this.refreshEmitter.emit();
  }
}
