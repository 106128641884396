/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CallCenterComponent } from "./call-center/call-center.component";

import { PageNotFoundComponent } from "./page-not-found.component";
import { ConfigComponent } from "./config/config.component";
import { FlavorFactoryComponent } from "./flavor-factory/flavor-factory.component";
import { GroupManagementComponent } from "./group-management/group-management.component";
import { RoleManagementComponent } from "./role-management/role-management.component";
import { UserManagementPageComponent } from "./user-management/user-management.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { CallCenterListComponent } from "./call-center/call-center-list.component";
import { BrowserNotSupportedComponent } from "./browser-not-supported.component";
import { HelperComponent } from "./helper/helper.component";
import { LoginComponent } from "./login/login.component";
import { LicenseQRComponent } from "./license/license-qr.component";
import { LicenseScannerComponent } from "./license/license-scanner.component";
import { DashboardOverviewComponent } from "client/scripts/dashboard/overview/overview.component";
import { DashboardAgentComponent } from "client/scripts/dashboard/agents/agent.component";
import { DashboardCustomerComponent } from "client/scripts/dashboard/customers/customer.component";
import { DashboardReportsComponent } from "client/scripts/dashboard/reports/reports.component";
import { DashboardComponent } from "client/scripts/dashboard/dashboard.component";
import { InvalidGuestEntryComponent } from "./invalid-guest-entry.component";

const routes: Routes = [
  { path: "operators", component: CallCenterComponent },
  { path: "operators/:language", component: CallCenterComponent },
  { path: "operators/:language/:location", component: CallCenterComponent },

  { path: ":style/operators", component: CallCenterComponent },
  { path: ":style/operators/:language", component: CallCenterComponent },
  { path: ":style/operators/:language/:location", component: CallCenterComponent },
/*
// TODO: Use for client code separation? kiosk or guest mode perhaps or default is operator?
  { path: ":style/:mode/operators", component: CallCenterComponent },
  { path: ":style/:mode/operators/:language", component: CallCenterComponent },
  { path: ":style/:mode/operators/:language/:location", component: CallCenterComponent },
*/
  { path: "", component: LoginComponent },
  { path: "portal", component: LoginComponent },
  { path: "themes", component: FlavorFactoryComponent },
  { path: "config", component: ConfigComponent },
  { path: "users", component: UserManagementPageComponent },
  { path: "roles", component: RoleManagementComponent },
  { path: "groups", component: GroupManagementComponent },
  { path: "my-account", component: UserProfileComponent },
  { path: "resetPassword/:resetToken", component: PasswordResetComponent },
  { path: "vcc/:displayMode", component: CallCenterListComponent },
  { path: ":style/dashboard", component: DashboardComponent,
    children: [
      { path: "overview", component: DashboardOverviewComponent },
      { path: "agent", component: DashboardAgentComponent},
      { path: "customer", component: DashboardCustomerComponent},
      { path: "reports", component: DashboardReportsComponent},
    ]
  },
  { path: ":style/portal", component: LoginComponent },
  { path: ":style/themes", component: FlavorFactoryComponent },
  { path: ":style/config", component: ConfigComponent },
  { path: ":style/users", component: UserManagementPageComponent },
  { path: ":style/roles", component: RoleManagementComponent },
  { path: ":style/groups", component: GroupManagementComponent },
  { path: ":style/my-account", component: UserProfileComponent },
  { path: ":style/resetPassword/:resetToken", component: PasswordResetComponent },
  { path: ":style/vcc/:displayMode", component: CallCenterListComponent },


  { path: "license/qr", component: LicenseQRComponent },
  { path: "license/scan", component: LicenseScannerComponent },

  { path: "helper", component: HelperComponent },
  { path: "not-found", component: PageNotFoundComponent },
  { path: "not-supported", component: BrowserNotSupportedComponent },
  { path: "invalid-guest-entry", component: InvalidGuestEntryComponent },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
