/**
 * Copyright Compunetix Incorporated 2017-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import {
  ILocalization,
  IFlavor,
  FlavorParameterKey,
  IParameter,
  ParameterType,
  ConferenceMode,
  Languages,
  FlavorUtil, VideoResolution, Companion, IUser, IGroup
} from "companion";
import { FormGroup } from "@angular/forms";
import { FormService } from "../../form/form.service";
import { FlavorService } from "./flavor.service";

export class Flavor implements IFlavor {
  private static fontOptions: { [p: string]: string } = {
    Lato: "Lato",
    Merriweather: "Merriweather",
    Montserrat: "Montserrat",
    OpenSans: "Open Sans",
    Oswald: "Oswald",
    Poppins: "Poppins",
    Raleway: "Raleway",
    Roboto: "Roboto",
    SourceSerif4: "Source Serif",
    "Calibri Light": "Calibri Light"
  };

  constructor(
    public style: string,
    public language: string = "en",
    public mode: string = "call_center",
    public parameters?: { [key: string]: IParameter },
  ) {
    this.parameters = this.parameters || {};
  }

  static getDefaultFlavor(style: string = "default", language: string = "en", mode: string = "call_center", groups: IGroup[]): IFlavor {
    let defaultFlavor: IFlavor = this.getFlavorTemplate(style, language, mode, groups);
    defaultFlavor.seedDefaultValues(language);
    return defaultFlavor;
  }

  static getFlavorTemplate(style: string = "default", language: string = "en", mode: string = "call_center", groups: IGroup[]): IFlavor {
    let defaultFlavorTemplate: IFlavor = new Flavor(style, language, mode);

    defaultFlavorTemplate.parameters = {
      mode_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.mode_enabled],
        title: "Association",
        type: ParameterType[ParameterType.hidden]
      },
      mode_groupId: {
        key: FlavorParameterKey[FlavorParameterKey.mode_groupId],
        title: "Group",
        type: ParameterType[ParameterType.dropdown],
      },
      mode_mode: {
        key: FlavorParameterKey[FlavorParameterKey.mode_mode],
        title: "Conference Mode (Call Center | Embed)",
        type: ParameterType[ParameterType.hidden],
        options: {
          call_center: ConferenceMode.call_center,
          embed: ConferenceMode.embed
        }
      },
      page_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.page_enabled],
        title: "Page",
        type: ParameterType[ParameterType.hidden]
      },
      page_title: {
        key: FlavorParameterKey[FlavorParameterKey.page_title],
        title: "Page Title",
        type: ParameterType[ParameterType.string]
      },
      page_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.page_font_color],
        title: "Page Text Color",
        type: ParameterType[ParameterType.color]
      },
      page_font_family: {
        key: FlavorParameterKey[FlavorParameterKey.page_font_family],
        title: "Page Font Family",
        type: ParameterType[ParameterType.dropdown],
        options: this.fontOptions
      },
      page_font_size: {
        key: FlavorParameterKey[FlavorParameterKey.page_font_size],
        title: "Page Font Size",
        type: ParameterType[ParameterType.string]
      },
      page_button_prefer_sharp_edge: {
        key: FlavorParameterKey[FlavorParameterKey.page_button_prefer_sharp_edge],
        title: "Page Button Style - Prefer Sharp Edge",
        type: ParameterType[ParameterType.checkbox]
      },
      page_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.page_background_color],
        title: "Page Background Color",
        type: ParameterType[ParameterType.color]
      },
      page_background_image: {
        key: FlavorParameterKey[FlavorParameterKey.page_background_image],
        title: "Page Background Image",
        type: ParameterType[ParameterType.image]
      },
      page_full_screen_mode: {
        key: FlavorParameterKey[FlavorParameterKey.page_full_screen_mode],
        title: "Page Full Screen Mode",
        type: ParameterType[ParameterType.checkbox]
      },
      page_simplified_view_for_mobile_user: {
        key: FlavorParameterKey[FlavorParameterKey.page_simplified_view_for_mobile_user],
        title: "Enable Simplified View for Mobile User",
        type: ParameterType[ParameterType.checkbox]
      },
      page_redirect_url_on_leave: {
        key: FlavorParameterKey[FlavorParameterKey.page_redirect_url_on_leave],
        title: "Page Redirect URL on Leave",
        type: ParameterType[ParameterType.string],
      },
      topnav_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.topnav_enabled],
        title: "Top Navigation",
        type: ParameterType[ParameterType.hidden]
      },
      topnav_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.topnav_font_color],
        title: "Top Nav Font Color",
        type: ParameterType[ParameterType.color]
      },
      topnav_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.topnav_background_color],
        title: "Top Nav Background Color",
        type: ParameterType[ParameterType.color]
      },
      topnav_language_selector_options: {
        key: FlavorParameterKey[FlavorParameterKey.topnav_language_selector_options],
        title: "Top Nav Language Selector Options",
        type: ParameterType[ParameterType.key_value_list],
        options: { key: "Language Code", value: "Language Name" }
      },
      topnav_language_selector_default: {
        key: FlavorParameterKey[FlavorParameterKey.topnav_language_selector_default],
        title: "Top Nav Language Selector Default",
        type: ParameterType[ParameterType.string]
      },
      topnav_menu_items: {
        key: FlavorParameterKey[FlavorParameterKey.topnav_menu_items],
        title: "Top Nav Menu (Agents Only)",
        type: ParameterType[ParameterType.key_value_list],
        options: { key: "Link Text", value: "Link URL" }
      },
      toolbar_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_enabled],
        title: "Toolbar",
        type: ParameterType[ParameterType.hidden]
      },
      toolbar_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_font_color],
        title: "Toolbar Text Color",
        type: ParameterType[ParameterType.color]
      },
      toolbar_font_hover_color: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_font_hover_color],
        title: "Toolbar Text Hover Color",
        type: ParameterType[ParameterType.color]
      },
      toolbar_font_active_color: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_font_active_color],
        title: "Toolbar Text Active Color",
        type: ParameterType[ParameterType.color]
      },
      toolbar_font_family: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_font_family],
        title: "Toolbar Font Family",
        type: ParameterType[ParameterType.dropdown],
        options: this.fontOptions
      },
      toolbar_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_background_color],
        title: "Toolbar Background Color",
        type: ParameterType[ParameterType.color]
      },
      toolbar_border_color: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_border_color],
        title: "Toolbar Border Color",
        type: ParameterType[ParameterType.color]
      },
      toolbar_brand_title_left: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_brand_title_left],
        title: "Toolbar Brand Title on Left Side of Logo",
        type: ParameterType[ParameterType.string]
      },
      toolbar_brand_title_right: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_brand_title_right],
        title: "Toolbar Brand Title on Right Side of Logo",
        type: ParameterType[ParameterType.string]
      },
      toolbar_brand_title_color: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_brand_title_color],
        title: "Toolbar Brand Title Color",
        type: ParameterType[ParameterType.color]
      },
      toolbar_brand_logo: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_brand_logo],
        title: "Toolbar Brand Logo",
        type: ParameterType[ParameterType.image]
      },
      toolbar_brand_logo_aria_label: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_brand_logo_aria_label],
        title: "Toolbar Brand Logo Aria Label | Center Brand Logo Alternative",
        type: ParameterType[ParameterType.string]
      },
      toolbar_items_settings_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_settings_toggle],
        title: "Toolbar Button - Settings",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text-UNUSED" }
      },
      toolbar_items_enter_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_enter_toggle],
        title: "Toolbar Button - Enter/Leave",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_lock_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_toggle],
        title: "Toolbar Button - Lock Client",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_chat_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_chat_toggle],
        title: "Toolbar Button - Chat",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text-UNUSED" }
      },
      toolbar_items_chat_toggle_host_only: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_chat_toggle_host_only],
        title: "Chat - Agent Only",
        type: ParameterType[ParameterType.checkbox]
      },
      toolbar_items_incoming_queue_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_incoming_queue_toggle],
        title: "Toolbar Button - Incoming Queue",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text-UNUSED" }
      },
      toolbar_items_operators_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_operators_toggle],
        title: "Toolbar Button - Operators",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text-UNUSED" }
      },
      toolbar_items_share_screen_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_share_screen_toggle],
        title: "Toolbar Button - Share Screen",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_optimize_for_video_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_optimize_for_video_toggle],
        title: "Share Screen - Optimize for Video",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text-UNUSED" }
      },
      toolbar_items_share_screen_toggle_host_only: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_share_screen_toggle_host_only],
        title: "Share Screen - Agent Only",
        type: ParameterType[ParameterType.checkbox]
      },
      toolbar_items_content_share_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_content_share_toggle],
        title: "Toolbar Button - Content Share (PDF)",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_content_share_toggle_host_only: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_content_share_toggle_host_only],
        title: "Content Share - Agent Only",
        type: ParameterType[ParameterType.checkbox]
      },
      toolbar_items_share_folder_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_share_folder_toggle],
        title: "Toolbar Button - Share Folder",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text-UNUSED" }
      },
      toolbar_items_inspector_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_inspector_toggle],
        title: "Toolbar Button - Diagnostics Inspector",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text-UNUSED" }
      },
      toolbar_items_inspector_toggle_host_only: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_inspector_toggle_host_only],
        title: "Diagnostics Inspector - Agent Only",
        type: ParameterType[ParameterType.checkbox]
      },
      toolbar_items_record_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_record_toggle],
        title: "Toolbar Button - Record",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_record_toggle_host_only: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_record_toggle_host_only],
        title: "Record - Agent Only",
        type: ParameterType[ParameterType.checkbox]
      },
      toolbar_items_snapshot_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_snapshot_toggle],
        title: "Toolbar Button - Snapshot",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text-UNUSED" }
      },
      toolbar_items_snapshot_toggle_host_only: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_snapshot_toggle_host_only],
        title: "Snapshot - Agent Only",
        type: ParameterType[ParameterType.checkbox]
      },
      toolbar_items_map_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_map_toggle],
        title: "Toolbar Button - Map",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_map_toggle_host_only: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_map_toggle_host_only],
        title: "Map - Agent Only",
        type: ParameterType[ParameterType.checkbox]
      },
      toolbar_items_audio_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_audio_toggle],
        title: "Toolbar Button - Audio",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_video_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_video_toggle],
        title: "Toolbar Button - Video",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_full_screen_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_full_screen_toggle],
        title: "Toolbar Button - Full Screen",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_video_aspect_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_video_aspect_toggle],
        title: "Toolbar Button - Video Aspect",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      toolbar_items_dashboard_open: {
        key: FlavorParameterKey[FlavorParameterKey.toolbar_items_dashboard_open],
        title: "Toolbar Button - Dashboard Tab",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text-UNUSED" }
      },
      title_screen_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.title_screen_enabled],
        title: "Title Screen",
        type: ParameterType[ParameterType.checkbox]
      },
      title_screen_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.title_screen_font_color],
        title: "Title Screen Text Color",
        type: ParameterType[ParameterType.color]
      },
      title_screen_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.title_screen_background_color],
        title: "Title Screen Background Color",
        type: ParameterType[ParameterType.color]
      },
      title_screen_title: {
        key: FlavorParameterKey[FlavorParameterKey.title_screen_title],
        title: "Title Screen Title",
        type: ParameterType[ParameterType.string]
      },
      title_screen_logo: {
        key: FlavorParameterKey[FlavorParameterKey.title_screen_logo],
        title: "Title Screen Logo",
        type: ParameterType[ParameterType.image]
      },
      video_screen_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.video_screen_enabled],
        title: "Video Screen",
        type: ParameterType[ParameterType.hidden]
      },
      video_screen_pip_position_fixed: {
        key: FlavorParameterKey[FlavorParameterKey.video_screen_pip_position_fixed],
        title: "Side Panels Overlap Video Screen",
        type: ParameterType[ParameterType.checkbox],
      },
      video_screen_mic_mute_warning_message: {
        key: FlavorParameterKey[FlavorParameterKey.video_screen_mic_mute_warning_message],
        title: "Mic Muted Warning Message",
        type: ParameterType[ParameterType.string],
      },
      video_screen_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.video_screen_font_color],
        title: "Video Screen Text Color",
        type: ParameterType[ParameterType.color]
      },
      video_screen_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.video_screen_background_color],
        title: "Video Screen Background Color",
        type: ParameterType[ParameterType.color]
      },
      video_screen_data_title: {
        key: FlavorParameterKey[FlavorParameterKey.video_screen_data_title],
        title: "Data Stream Title",
        type: ParameterType[ParameterType.string]
      },
      loopback_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.loopback_panel_enabled],
        title: "Loopback Panel",
        type: ParameterType[ParameterType.checkbox]
      },
      settings_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_enabled],
        title: "Settings Panel",
        type: ParameterType[ParameterType.checkbox]
      },
      settings_panel_title: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_title],
        title: "Settings Panel Title",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_relay_candidate_only: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_relay_candidate_only],
        title: "Force Using Relay Candidates",
        type: ParameterType[ParameterType.checkbox]
      },
      settings_panel_disable_audio_only: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_disable_audio_only],
        title: "Disable This Client to Join by Audio Only",
        type: ParameterType[ParameterType.checkbox]
      },
      settings_panel_audio_codecs: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_audio_codecs],
        title: "Audio codecs to not support",
        type: ParameterType[ParameterType.list]
      },
      settings_panel_audio_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_audio_toggle],
        title: "Settings Panel Audio Toggle",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Audio On Title", falseValue: "Audio Off Title" }
      },
      settings_panel_primary_video_codecs: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_primary_video_codecs],
        title: "Primary Video codecs to not support",
        type: ParameterType[ParameterType.list]
      },
      settings_panel_secondary_video_codecs: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_secondary_video_codecs],
        title: "Secondary Video codecs to not support",
        type: ParameterType[ParameterType.list]
      },
      settings_panel_video_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_video_toggle],
        title: "Settings Panel Video Toggle",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Video On Title", falseValue: "Video Off Title" }
      },
      settings_panel_avatar_image_src: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_avatar_image_src],
        title: "Video Muted Placeholder",
        type: ParameterType[ParameterType.image],
        valueOnDelete: FlavorUtil.getDefaultLocalizationValues(language).settings_panel.avatarImageSrc
      },
      settings_panel_show_customized_video_muted_placeholder: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_show_customized_video_muted_placeholder],
        title: "Allow User to Customize Video Muted Placeholder",
        type: ParameterType[ParameterType.checkbox]
      },
      settings_panel_virtual_background_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_virtual_background_toggle],
        title: "Settings Panel - Virtual Background Toggle Button",
        type: ParameterType[ParameterType.true_false_value],
        options: { trueValue: "Default Text", falseValue: "Alternative Text" }
      },
      settings_panel_virtual_background_method: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_virtual_background_method],
        title: "Virtual Background Method",
        type: ParameterType[ParameterType.dropdown],
        options: {
          None: "None",
          MediaPipe: "MediaPipe",
          TensorFlow: "TensorFlow"
        }
      },
      settings_panel_microphone_test_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_microphone_test_toggle],
        title: "Settings Panel Microphone Test Button",
        type: ParameterType[ParameterType.true_false_value],
        options: {
          trueValue: "Start Testing Title",
          falseValue: "End Testing Title"
        }
      },
      settings_panel_speaker_test_toggle: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_speaker_test_toggle],
        title: "Settings Panel Speaker Test Button",
        type: ParameterType[ParameterType.true_false_value],
        options: {
          trueValue: "Start Testing Title",
          falseValue: "End Testing Title"
        }
      },
      settings_panel_default_volume: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_default_volume],
        title: "Settings Panel Default Audio Level (0-100)",
        type: ParameterType[ParameterType.number]
      },
      settings_panel_camera_rotation: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_camera_rotation],
        title: "Settings Panel Camera Rotation Text",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_resolution_title: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_title],
        title: "Settings Panel Resolution Field Title",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_resolution_auto_title: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_auto_title],
        title: "Settings Panel Resolution Field Auto Option Title",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_resolution_options: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_options],
        title: "Settings Panel Resolution Options",
        type: ParameterType[ParameterType.size_list]
      },
      settings_panel_resolution_default_pc_value: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_pc_value],
        title: "Settings Panel Resolution Default PC Value",
        type: ParameterType[ParameterType.size],
        options: { width: "Width", height: "Height" }
      },
      settings_panel_resolution_default_mobile_value: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_mobile_value],
        title: "Settings Panel Resolution Default Mobile Value",
        type: ParameterType[ParameterType.size],
        options: { width: "Width", height: "Height" }
      },
      settings_panel_resolution_default_pc_secondary_value: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_pc_secondary_value],
        title: "Settings Panel Resolution Default PC Value - Secondary Video",
        type: ParameterType[ParameterType.size],
        options: { width: "Width", height: "Height" }
      },
      settings_panel_resolution_default_mobile_secondary_value: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_mobile_secondary_value],
        title: "Settings Panel Resolution Default Mobile Value - Secondary Video",
        type: ParameterType[ParameterType.size],
        options: { width: "Width", height: "Height" }
      },
      settings_panel_presentation_sharpness_first_resolution: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_sharpness_first_resolution],
        title: "Settings Panel - Presentation Setting - Sharpness First - Resolution",
        type: ParameterType[ParameterType.size],
        options: { width: "Width", height: "Height" }
      },
      settings_panel_presentation_sharpness_first_framerate: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_sharpness_first_framerate],
        title: "Settings Panel - Presentation Setting - Sharpness First - Frame Rate",
        type: ParameterType[ParameterType.number]
      },
      settings_panel_presentation_motion_first_resolution: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_motion_first_resolution],
        title: "Settings Panel - Presentation Setting - Motion First - Resolution",
        type: ParameterType[ParameterType.size],
        options: { width: "Width", height: "Height" }
      },
      settings_panel_presentation_motion_first_framerate: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_motion_first_framerate],
        title: "Settings Panel - Presentation Setting - Motion First - Frame Rate",
        type: ParameterType[ParameterType.number]
      },
      settings_panel_bandwidth_title: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_title],
        title: "Settings Panel Bandwidth Field Title",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_bandwidth_auto_title: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_auto_title],
        title: "Settings Panel Bandwidth Field Auto Option Title",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_bandwidth_options: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_options],
        title: "Settings Panel Bandwidth Options",
        type: ParameterType[ParameterType.list]
      },
      settings_panel_bandwidth_default_pc_value: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_default_pc_value],
        title: "Settings Panel Bandwidth Default PC Value",
        type: ParameterType[ParameterType.number]
      },
      settings_panel_bandwidth_default_mobile_value: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_default_mobile_value],
        title: "Settings Panel Bandwidth Default Mobile Value",
        type: ParameterType[ParameterType.number]
      },
      settings_panel_run_tester_on_first_visit: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_run_tester_on_first_visit],
        title: "Settings Panel Run Tester on First Visit",
        type: ParameterType[ParameterType.checkbox]
      },
      settings_panel_troubleshoot_text: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_troubleshoot_text],
        title: "Settings Panel Troubleshoot Text",
        type: ParameterType[ParameterType.string],
      },
      settings_panel_device_names: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_device_names],
        title: "Settings Panel Device Names",
        type: ParameterType[ParameterType.key_value_list],
        options: { key: "Default Name", value: "Translation" }
      },
      settings_panel_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_font_color],
        title: "Settings Panel Text Color",
        type: ParameterType[ParameterType.color]
      },
      settings_panel_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_background_color],
        title: "Settings Panel Background Color",
        type: ParameterType[ParameterType.color]
      },
      settings_panel_top: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_top],
        title: "Settings Panel Position - Top",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_bottom: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_bottom],
        title: "Settings Panel Position - Bottom",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_left: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_left],
        title: "Settings Panel Position - Left",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_right: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_right],
        title: "Settings Panel Position - Right",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_width: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_width],
        title: "Settings Panel Position - Width",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_video_aspect_title: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_video_aspect_title],
        title: "Settings Panel Video Aspect Title",
        type: ParameterType[ParameterType.string]
      },
      settings_panel_video_aspect_default: {
        key: FlavorParameterKey[FlavorParameterKey.settings_panel_video_aspect_default],
        title: "Settings Panel Video Aspect Default",
        type: ParameterType[ParameterType.dropdown],
        options: {
          fit: "fit",
          fill: "fill",
        }
      },
      diagnostic_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.diagnostic_panel_enabled],
        title: "Diagnostic Panel",
        type: ParameterType[ParameterType.checkbox]
      },
      diagnostic_panel_on_screen_inbound_quality_guages: {
        key: FlavorParameterKey[FlavorParameterKey.diagnostic_panel_on_screen_inbound_quality_guages],
        title: "Diagnostic On-Screen Call Inbound Quality Gauges (Agent Only)",
        type: ParameterType[ParameterType.checkbox]
      },
      diagnostic_panel_on_screen_outbound_quality_guages: {
        key: FlavorParameterKey[FlavorParameterKey.diagnostic_panel_on_screen_outbound_quality_guages],
        title: "Diagnostic On-Screen Call Outbound Quality Gauges (Agent Only)",
        type: ParameterType[ParameterType.checkbox]
      },
      diagnostic_panel_info_dialog_quality_gauges: {
        key: FlavorParameterKey[FlavorParameterKey.diagnostic_panel_info_dialog_quality_gauges],
        title: "Diagnostic Info-Dialog Call Quality Gauges (Agent Only)",
        type: ParameterType[ParameterType.checkbox]
      },
      diagnostic_panel_watchrtc_active: {
        key: FlavorParameterKey[FlavorParameterKey.diagnostic_panel_watchrtc_active],
        title: "WatchRTC Active",
        type: ParameterType[ParameterType.checkbox]
      },
      diagnostic_panel_watchrtc_api_key: {
        key: FlavorParameterKey[FlavorParameterKey.diagnostic_panel_watchrtc_api_key],
        title: "WatchRTC API Key",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_enabled],
        title: "Chat Panel",
        type: ParameterType[ParameterType.checkbox]
      },
      chat_panel_title: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_title],
        title: "Chat Panel - Title",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_exit_button: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_exit_button],
        title: "Chat Panel - Exit Button Text",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_edit_button: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_edit_button],
        title: "Chat Panel - Edit Button Text",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_done_button: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_done_button],
        title: "Chat Panel - Done Button Text",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_back_button: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_back_button],
        title: "Chat Panel - Back Button Text",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_add_participant_button_text: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_add_participant_button_text],
        title: "Chat Panel - Add Participant Button Text",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_add_participant_button_tooltip: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_add_participant_button_tooltip],
        title: "Chat Panel - Add Participant Button Tooltip",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_start_chat_button: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_start_chat_button],
        title: "Chat Panel - Start Chat Button Text",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_send_message_title: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_send_message_title],
        title: "Chat Panel - Send Message Field Title",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_start_group_chat_button: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_start_group_chat_button],
        title: "Chat Panel - Start Group Chat Button Text",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_chat_disabled_message: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_chat_disabled_message],
        title: "Chat Panel - Chat Disabled Message",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_font_color],
        title: "Chat Panel - Text Color",
        type: ParameterType[ParameterType.color]
      },
      chat_panel_font_family: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_font_family],
        title: "Chat Panel - Font Family",
        type: ParameterType[ParameterType.dropdown],
        options: this.fontOptions
      },
      chat_panel_font_size: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_font_size],
        title: "Chat Panel - Font Size",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_background_color],
        title: "Chat Panel - Background Color",
        type: ParameterType[ParameterType.color]
      },
      chat_panel_top: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_top],
        title: "Chat Panel Position - Top",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_bottom: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_bottom],
        title: "Chat Panel Position - Bottom",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_left: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_left],
        title: "Chat Panel Position - Left",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_right: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_right],
        title: "Chat Panel Position - Right",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_width: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_width],
        title: "Chat Panel Position - Width",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_chat_audio_alert_option: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_option],
        title: "Chat Audio Alert Option",
        type: ParameterType[ParameterType.dropdown],
        options: {
          none: "none",
          once: "once",
          repetitive: "repetitive",
        },
        value: "none"
      },
      chat_panel_chat_audio_alert_type: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_type],
        title: "Chat Audio Alert Type",
        type: ParameterType[ParameterType.dropdown],
        options: {
          sine: "sine",
          square: "square",
          triangle: "triangle",
        },
        value: "sine"
      },
      chat_panel_chat_audio_alert_frequency: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_frequency],
        title: "Chat Audio Alert Frequency (in Hz)",
        type: ParameterType[ParameterType.number],
        value: 800
      },
      chat_panel_chat_audio_alert_duration: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_duration],
        title: "Chat Audio Alert Duration (in Milliseconds)",
        type: ParameterType[ParameterType.number],
        value: 500
      },
      chat_panel_chat_audio_alert_interval: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_interval],
        title: "Chat Audio Alert Interval (in Milliseconds)",
        type: ParameterType[ParameterType.number],
        value: 3000
      },
      chat_panel_silence_chat_audio_alerts: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_silence_chat_audio_alerts],
        title: "Silence Chat Audio Alerts",
        type: ParameterType[ParameterType.string]
      },
      chat_panel_silence_chat_audio_alerts_host_only: {
        key: FlavorParameterKey[FlavorParameterKey.chat_panel_silence_chat_audio_alerts_host_only],
        title: "Silence Chat Audio Alerts - Agent Only",
        type: ParameterType[ParameterType.checkbox]
      },
      participant_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_enabled],
        title: "Participant Panel",
        type: ParameterType[ParameterType.checkbox]
      },
      participant_panel_max_participants_to_connect: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_max_participants_to_connect],
        title: "Maximum Participants to Connect",
        type: ParameterType[ParameterType.number]
      },
      participant_panel_title_color: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_title_color],
        title: "Participant List Title Color",
        type: ParameterType[ParameterType.color]
      },
      participant_panel_exit_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_exit_button],
        title: "Participant Panel Exit Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_connect_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_connect_button],
        title: "Participant Panel Connect Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_disconnect_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_disconnect_button],
        title: "Participant Panel Disconnect Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_start_monitor_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_start_monitor_button],
        title: "Participant Panel Start Monitoring Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_whisper_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_whisper_button],
        title: "Participant Panel Whisper Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_exit_monitor_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_exit_monitor_button],
        title: "Participant Panel Exit Monitoring Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_select_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_select_button],
        title: "Participant Panel Select Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_unselect_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_unselect_button],
        title: "Participant Panel Unselect Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_hold_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_hold_button],
        title: "Participant Panel Hold Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_resume_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_resume_button],
        title: "Participant Panel Resume Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_take_over_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_take_over_button],
        title: "Participant Panel Take Over Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_transfer_to_queue_button_disabled: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_transfer_to_queue_button_disabled],
        title: "Participant Panel Transfer To Queue Disallowed",
        type: ParameterType[ParameterType.checkbox]
      },
      participant_panel_transfer_to_queue_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_transfer_to_queue_button],
        title: "Participant Panel Transfer To Queue Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_callback_confirm_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_callback_confirm_button],
        title: "Participant Panel Callback Acknowledge Button Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_callback_handle_external_button : {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_callback_handle_external_button],
        title: "Participant Panel Callback Handle Externally Text",
        type: ParameterType[ParameterType.string]        
      },
      participant_panel_chat_with_conjunction_text: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_chat_with_conjunction_text],
        title: "Participant Panel Chat With Conjunction Text",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_hold_conference_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_hold_conference_button],
        title: "Participant Panel Hold Conference",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_resume_conference_button: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_resume_conference_button],
        title: "Participant Panel Resume Conference",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_participant_status: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_participant_status],
        title: "Participant Panel Participant Status",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_list_type: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_list_type],
        title: "Participant Panel List Type(op/sp/publicWait)",
        type: ParameterType[ParameterType.key_value_list],
        options: { key: "List Type Code", value: "List Title" }
      },
      participant_panel_list_sort_by_enter_time: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_list_sort_by_enter_time],
        title: "Participant Panel List Sort by First Come First Serve",
        type: ParameterType[ParameterType.checkbox]
      },
      participant_panel_list_show_offline_endpoints: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_list_show_offline_endpoints],
        title: "Participant Panel List Show Offline Guests",
        type: ParameterType[ParameterType.checkbox]
      },
      participant_panel_list_show_offline_users: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_list_show_offline_users],
        title: "Participant Panel List Show Offline Users",
        type: ParameterType[ParameterType.checkbox]
      },
      participant_panel_disable_operator_guest_connect: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_disable_operator_guest_connect],
        title: "Participant Panel Disable Operator Guest Connect",
        type: ParameterType[ParameterType.checkbox]
      },
      participant_panel_hide_guests_with_device_problems: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_hide_guests_with_device_problems],
        title: "Participant Panel Hide Guests with Device Problems",
        type: ParameterType[ParameterType.checkbox]
      },
      participant_panel_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_font_color],
        title: "Participant Panel Text Color",
        type: ParameterType[ParameterType.color]
      },
      participant_panel_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_background_color],
        title: "Participant Panel Background Color",
        type: ParameterType[ParameterType.color]
      },
      participant_panel_top: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_top],
        title: "Participant Panel Position - Top",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_bottom: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_bottom],
        title: "Participant Panel Position - Bottom",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_left: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_left],
        title: "Participant Panel Position - Left",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_right: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_right],
        title: "Participant Panel Position - Right",
        type: ParameterType[ParameterType.string]
      },
      participant_panel_width: {
        key: FlavorParameterKey[FlavorParameterKey.participant_panel_width],
        title: "Participant Panel Position - Width",
        type: ParameterType[ParameterType.string]
      },
      presence_status_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_enabled],
        title: "Presence Status",
        type: ParameterType[ParameterType.hidden]
      },
      presence_status_offline: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_offline],
        title: "Presence Status - Offline",
        type: ParameterType[ParameterType.string]
      },
      presence_status_online: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_online],
        title: "Presence Status - Online",
        type: ParameterType[ParameterType.string]
      },
      presence_status_available: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_available],
        title: "Presence Status - Available",
        type: ParameterType[ParameterType.string]
      },
      presence_status_alone_in_conf: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_alone_in_conf],
        title: "Presence Status - Alone",
        type: ParameterType[ParameterType.string]
      },
      presence_status_busy: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_busy],
        title: "Presence Status - Connected",
        type: ParameterType[ParameterType.string]
      },
      presence_status_invisible: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_invisible],
        title: "Presence Status - Invisible",
        type: ParameterType[ParameterType.string]
      },
      presence_status_away: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_away],
        title: "Presence Status - Away",
        type: ParameterType[ParameterType.string]
      },
      presence_status_work: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_work],
        title: "Presence Status - Work",
        type: ParameterType[ParameterType.string]
      },
      presence_status_connecting: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_connecting],
        title: "Presence Status - Connecting",
        type: ParameterType[ParameterType.string]
      },
      presence_status_ringing: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_ringing],
        title: "Presence Status - Ringing",
        type: ParameterType[ParameterType.string]
      },
      presence_status_onhold: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_onhold],
        title: "Presence Status - Onhold",
        type: ParameterType[ParameterType.string]
      },
      presence_status_ready: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_ready],
        title: "Presence Status - Ready",
        type: ParameterType[ParameterType.string]
      },
      presence_status_ready_timelapse: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_ready_timelapse],
        title: "Presence Status Ready - Timelapse",
        type: ParameterType[ParameterType.number]
      },
      presence_status_custom1: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_custom1],
        title: "Presence Status - Operator Custom #1",
        type: ParameterType[ParameterType.string]
      },
      presence_status_custom2: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_custom2],
        title: "Presence Status - Operator Custom #2",
        type: ParameterType[ParameterType.string]
      },
      presence_status_custom3: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_custom3],
        title: "Presence Status - Operator Custom #3",
        type: ParameterType[ParameterType.string]
      },
      presence_status_custom4: {
        key: FlavorParameterKey[FlavorParameterKey.presence_status_custom4],
        title: "Presence Status - Operator Custom #4",
        type: ParameterType[ParameterType.string]
      },
      content_share_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.content_share_panel_enabled],
        title: "Content Share Panel",
        type: ParameterType[ParameterType.checkbox]
      },
      content_share_panel_title: {
        key: FlavorParameterKey[FlavorParameterKey.content_share_panel_title],
        title: "Content Share Title",
        type: ParameterType[ParameterType.string]
      },
      content_share_panel_exit_button: {
        key: FlavorParameterKey[FlavorParameterKey.content_share_panel_exit_button],
        title: "Content Share Exit Button",
        type: ParameterType[ParameterType.string]
      },
      content_share_panel_cursor_image: {
        key: FlavorParameterKey[FlavorParameterKey.content_share_panel_cursor_image],
        title: "Content Share Cursor Image",
        type: ParameterType[ParameterType.image]
      },
      content_share_panel_cursor_px: {
        key: FlavorParameterKey[FlavorParameterKey.content_share_panel_cursor_px],
        title: "Content Share Cursor Pixels",
        type: ParameterType[ParameterType.number]
      },
      share_folder_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_enabled],
        title: "Sharefolder Panel",
        type: ParameterType[ParameterType.checkbox]
      },
      share_folder_panel_title: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_title],
        title: "Sharefolder Panel Title",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_conference_files_title: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_conference_files_title],
        title: "Sharefolder Conference Files Title",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_remove_conf_text: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_remove_conf_text],
        title: "Sharefolder Remove from Conference Text",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_request_file_text: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_request_file_text],
        title: "Sharefolder Request from Conference Text",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_request_all_text: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_request_all_text],
        title: "Sharefolder Request All files from Conference Text",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_local_files_title: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_local_files_title],
        title: "Sharefolder Local Files Title",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_remove_local_text: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_remove_local_text],
        title: "Sharefolder Remove from Local Text",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_offer_file_text: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_offer_file_text],
        title: "Sharefolder Offer to Conference Text",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_load_file_text: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_load_file_text],
        title: "Sharefolder Load File Text",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_file_sharing_status_idle: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_idle],
        title: "File Transmit Status - Idle",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_file_sharing_status_started: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_started],
        title: "File Transmit Status - Started",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_file_sharing_status_transmitting: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_transmitting],
        title: "File Transmit Status - Transmitting",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_file_sharing_status_finished: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_finished],
        title: "File Transmit Status - Finished",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_file_sharing_status_failed: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_failed],
        title: "File Transmit Status - Failed",
        type: ParameterType[ParameterType.string]
      },
      share_folder_panel_autosave: {
        key: FlavorParameterKey[FlavorParameterKey.share_folder_panel_autosave],
        title: "File Transmit Automatic Save On Request",
        type: ParameterType[ParameterType.checkbox]
      },
      record_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_enabled],
        title: "Recording Panel",
        type: ParameterType[ParameterType.checkbox]
      },
      record_panel_auto_record: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_auto_record],
        title: "Auto Record",
        type: ParameterType[ParameterType.checkbox]
      },
      record_panel_send_to_ftp: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_send_to_ftp],
        title: "Send to FTP directly",
        type: ParameterType[ParameterType.checkbox]
      },
      record_panel_audio_mode: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_audio_mode],
        title: "Record Audio Mode",
        type: ParameterType[ParameterType.dropdown],
        options: {
          disabled: "Disabled",
          local: "Local Only",
          remote: "Remote Only",
          both: "Both Sides"
        }
      },
      record_panel_video_mode: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_video_mode],
        title: "Record Video Mode",
        type: ParameterType[ParameterType.dropdown],
        options: {
          disabled: "Disabled",
          local: "Local Only",
          remote: "Remote Only",
          both: "Both Sides"
        }
      },
      record_panel_title: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_title],
        title: "Recording Panel Title",
        type: ParameterType[ParameterType.string]
      },
      record_panel_warning_message: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_warning_message],
        title: "Recording Panel Warning Message",
        type: ParameterType[ParameterType.string]
      },
      record_panel_confirm_button: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_confirm_button],
        title: "Recording Panel Confirm Button Text",
        type: ParameterType[ParameterType.string]
      },
      record_panel_exit_button: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_exit_button],
        title: "Recording Panel Exit Button Text",
        type: ParameterType[ParameterType.string]
      },
      record_panel_audio_mode_text: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_audio_mode_text],
        title: "Recording Panel Audio Mode Text",
        type: ParameterType[ParameterType.string]
      },
      record_panel_video_mode_text: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_video_mode_text],
        title: "Recording Panel Video Mode Text",
        type: ParameterType[ParameterType.string]
      },
      record_panel_disabled_text: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_disabled_text],
        title: "Recording Panel Audio/Video Mode Disabled Option Text",
        type: ParameterType[ParameterType.string]
      },
      record_panel_local_only_text: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_local_only_text],
        title: "Recording Panel Audio/Video Mode Local Only Option Text",
        type: ParameterType[ParameterType.string]
      },
      record_panel_remote_only_text: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_remote_only_text],
        title: "Recording Panel Audio/Video Mode Remote Only Option Text",
        type: ParameterType[ParameterType.string]
      },
      record_panel_both_sides_text: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_both_sides_text],
        title: "Recording Panel Audio/Video Mode Both Sides Option Text",
        type: ParameterType[ParameterType.string]
      },
      record_panel_filename_pattern: {
        key: FlavorParameterKey[FlavorParameterKey.record_panel_filename_pattern],
        title: "Recording File Name Pattern (Keywords: [DATE], [TIME], [REMOTE_NAME], [LOCAL_NAME], [GUEST_PASSCODE], [HOST_PASSCODE], [CONFERENCE_NAME])",
        type: ParameterType[ParameterType.string]
      },
      snapshot_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.snapshot_panel_enabled],
        title: "Snapshot",
        type: ParameterType[ParameterType.checkbox]
      },
      snapshot_panel_video_mode: {
        key: FlavorParameterKey[FlavorParameterKey.snapshot_panel_video_mode],
        title: "Snapshot Video Mode",
        type: ParameterType[ParameterType.dropdown],
        options: {
          disabled: "Disabled",
          local: "Local Only",
          remote: "Remote Only",
          both: "Both Sides"
        }
      },
      snapshot_panel_file_name_pattern: {
        key: FlavorParameterKey[FlavorParameterKey.snapshot_panel_file_name_pattern],
        title: "Snapshot File Name Pattern ([DATE]_[TIME]_[REMOTE_NAME]_[LOCAL_NAME])",
        type: ParameterType[ParameterType.string]
      },
      take_photo_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.take_photo_enabled],
        title: "Take Photo",
        type: ParameterType[ParameterType.checkbox]
      },
      take_photo_resolution: {
        key: FlavorParameterKey[FlavorParameterKey.take_photo_resolution],
        title: "Take Photo - Resolution",
        type: ParameterType[ParameterType.size],
        options: { width: "Width", height: "Height" }
      },
      take_photo_framerate: {
        key: FlavorParameterKey[FlavorParameterKey.take_photo_framerate],
        title: "Take Photo - Photo Frame Rate",
        type: ParameterType[ParameterType.number]
      },
      map_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.map_panel_enabled],
        title: "Map View",
        type: ParameterType[ParameterType.checkbox]
      },
      map_panel_hide_by_default: {
        key: FlavorParameterKey[FlavorParameterKey.map_panel_hide_by_default],
        title: "Hide Map View by Default",
        type: ParameterType[ParameterType.checkbox]
      },
      map_panel_access_geo_title: {
        key: FlavorParameterKey[FlavorParameterKey.map_panel_access_geo_title],
        title: "Access Geolocation Field Title",
        type: ParameterType[ParameterType.string]
      },
      map_panel_default_latitude: {
        key: FlavorParameterKey[FlavorParameterKey.map_panel_default_latitude],
        title: "Default Map Center Latitude",
        type: ParameterType[ParameterType.number]
      },
      map_panel_default_longitude: {
        key: FlavorParameterKey[FlavorParameterKey.map_panel_default_longitude],
        title: "Default Map Center Longitude",
        type: ParameterType[ParameterType.number]
      },
      map_panel_default_zoom: {
        key: FlavorParameterKey[FlavorParameterKey.map_panel_default_zoom],
        title: "Default Map Center Zoom",
        type: ParameterType[ParameterType.number]
      },
      notepad_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.notepad_enabled],
        title: "Notepad View",
        type: ParameterType[ParameterType.checkbox]
      },
      notepad_template: {
        key: FlavorParameterKey[FlavorParameterKey.notepad_template],
        title: "Note Template",
        type: ParameterType[ParameterType.string]
      },
      ring_tone_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.ring_tone_panel_enabled],
        title: "Ringtone Panel",
        type: ParameterType[ParameterType.checkbox]
      },
      ring_tone_panel_title: {
        key: FlavorParameterKey[FlavorParameterKey.ring_tone_panel_title],
        title: "Ringtone Panel Message",
        type: ParameterType[ParameterType.string]
      },
      ring_tone_panel_confirm_button: {
        key: FlavorParameterKey[FlavorParameterKey.ring_tone_panel_confirm_button],
        title: "Ringtone Panel Confirm Button Text",
        type: ParameterType[ParameterType.string]
      },
      ring_tone_panel_confirm_voice_button: {
        key: FlavorParameterKey[FlavorParameterKey.ring_tone_panel_confirm_voice_button],
        title: "Ringtone Panel Confirm Voice Button Text",
        type: ParameterType[ParameterType.string]
      },
      ring_tone_panel_exit_button: {
        key: FlavorParameterKey[FlavorParameterKey.ring_tone_panel_exit_button],
        title: "Ringtone Panel Exit Button Text",
        type: ParameterType[ParameterType.string]
      },
      lock_panel_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_enabled],
        title: "Lock Panel",
        type:  ParameterType[ParameterType.checkbox]
      },
      lock_panel_auto_lock: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_auto_lock],
        title: "Lock Panel Auto Lock",
        type:  ParameterType[ParameterType.checkbox]
      },
      lock_panel_modalTitle: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_modalTitle],
        title: "Lock Panel Title",
        type:  ParameterType[ParameterType.string]
      },
      lock_panel_exitText: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_exitText],
        title: "Lock Panel Exit",
        type:  ParameterType[ParameterType.string]
      },
      lock_panel_submitText: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_submitText],
        title: "Lock Panel Submit",
        type:  ParameterType[ParameterType.string]
      },
      lock_panel_entryPlaceholder: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_entryPlaceholder],
        title: "Lock Panel Entry Placeholder",
        type:  ParameterType[ParameterType.string]
      },
      lock_panel_textColor: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_textColor],
        title: "Lock Panel Text Color",
        type:  ParameterType[ParameterType.color]
      },
      lock_panel_backgroundColor: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_backgroundColor],
        title: "Lock Panel Background",
        type:  ParameterType[ParameterType.color]
      },
      lock_panel_submitTextColor: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_submitTextColor],
        title: "Lock Panel Sumbit Text Color",
        type:  ParameterType[ParameterType.color]
      },
      lock_panel_submitBgColor: {
        key: FlavorParameterKey[FlavorParameterKey.lock_panel_submitBgColor],
        title: "Lock Panel Submit Background",
        type:  ParameterType[ParameterType.color]
      },
      footer_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.footer_enabled],
        title: "Footer",
        type: ParameterType[ParameterType.checkbox]
      },
      footer_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.footer_font_color],
        title: "Footer Text Color",
        type: ParameterType[ParameterType.color]
      },
      footer_font_family: {
        key: FlavorParameterKey[FlavorParameterKey.footer_font_family],
        title: "Footer Font Family",
        type: ParameterType[ParameterType.dropdown],
        options: this.fontOptions
      },
      footer_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.footer_background_color],
        title: "Footer Background Color",
        type: ParameterType[ParameterType.color]
      },
      footer_border_color: {
        key: FlavorParameterKey[FlavorParameterKey.footer_border_color],
        title: "Footer Border Color",
        type: ParameterType[ParameterType.color]
      },
      footer_copyright: {
        key: FlavorParameterKey[FlavorParameterKey.footer_copyright],
        title: "Footer Copyright (Substitution Keys: [CURRENT_YEAR])",
        type: ParameterType[ParameterType.string]
      },
      footer_menu: {
        key: FlavorParameterKey[FlavorParameterKey.footer_menu],
        title: "Footer Menu (prefix URL with [pdf] to use PDF viewer instead of new tab)",
        type: ParameterType[ParameterType.key_value_list],
        options: { key: "Link Text", value: "Link URL" }
      },
      footer_social_menu: {
        key: FlavorParameterKey[FlavorParameterKey.footer_social_menu],
        title: "Footer Social Menu",
        type: ParameterType[ParameterType.key_value_list],
        options: { key: "Link Text", value: "Link URL" }
      },
      reporting_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.reporting_enabled],
        title: "Reporting",
        type: ParameterType[ParameterType.hidden]
      },
      reporting_max_preview: {
        key: FlavorParameterKey[FlavorParameterKey.reporting_max_preview],
        title: "Max number of days to view the preview",
        type: ParameterType[ParameterType.number],
      },
      reporting_max_download: {
        key: FlavorParameterKey[FlavorParameterKey.reporting_max_download],
        title: "Max number of days to download a report",
        type: ParameterType[ParameterType.number],
      },
      login_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.login_enabled],
        title: "Login",
        type: ParameterType[ParameterType.hidden]
      },
      login_panel_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_font_color],
        title: "Login Panel Font Color",
        type: ParameterType[ParameterType.color]
      },
      login_panel_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_background_color],
        title: "Login Panel Background Color",
        type: ParameterType[ParameterType.color]
      },
      login_panel_title: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_title],
        title: "Login Panel Title",
        type: ParameterType[ParameterType.string]
      },
      login_panel_exit_button: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_exit_button],
        title: "Login Panel Exit Button Text",
        type: ParameterType[ParameterType.string]
      },
      login_panel_email_field_title: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_email_field_title],
        title: "Login Panel Email Field Title",
        type: ParameterType[ParameterType.string]
      },
      login_panel_password_field_title: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_password_field_title],
        title: "Login Panel Password Field Title",
        type: ParameterType[ParameterType.string]
      },
      login_panel_username_field_title: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_username_field_title],
        title: "Login Panel Username Field Title",
        type: ParameterType[ParameterType.string]
      },
      login_panel_submit_button_text: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_submit_button_text],
        title: "Login Panel Submit Button Text",
        type: ParameterType[ParameterType.string]
      },
      login_panel_submit_button_color: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_submit_button_color],
        title: "Login Panel Submit Button Color",
        type: ParameterType[ParameterType.color]
      },
      login_panel_help_message_1: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_help_message_1],
        title: "Login Panel Help Message Line 1",
        type: ParameterType[ParameterType.string]
      },
      login_panel_help_message_2: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_help_message_2],
        title: "Login Panel Help Message Line 2",
        type: ParameterType[ParameterType.string]
      },
      login_panel_forgot_username_button_text: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_forgot_username_button_text],
        title: "Login Panel Forgot Username Button Text",
        type: ParameterType[ParameterType.string]
      },
      login_panel_forgot_password_button_text: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_forgot_password_button_text],
        title: "Login Panel Forgot Password Button Text",
        type: ParameterType[ParameterType.string]
      },
      login_panel_send_button_text: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_send_button_text],
        title: "Login Panel Forgot Send Button Text",
        type: ParameterType[ParameterType.string]
      },
      login_panel_cancel_button_text: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_cancel_button_text],
        title: "Login Panel Forgot Cancel Button Text",
        type: ParameterType[ParameterType.string]
      },
      login_panel_openidc_url: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_openidc_url],
        title: "Login With OpenIDConnect Url",
        type: ParameterType[ParameterType.string]
      },
      login_panel_openidc_button: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_openidc_button],
        title: "Login With OpenIDConnect Button Text",
        type: ParameterType[ParameterType.string]
      },
      login_panel_desktop_only_text: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_desktop_only_text],
        title: "Login Panel Desktop Only Text",
        type: ParameterType[ParameterType.string]
      },
      login_panel_login_as_available: {
        key: FlavorParameterKey[FlavorParameterKey.login_panel_login_as_available],
        title: "Set Agents to Available on Login",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_enabled],
        title: "Connect Entry Screen",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_font_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_font_color],
        title: "Connect Entry Screen - Text Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_font_size: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_font_size],
        title: "Connect Entry Screen - Font Size",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_top_position: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_top_position],
        title: "Connect Entry Screen - Position Top",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_background_color],
        title: "Connect Entry Screen - Background Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_title: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_title],
        title: "Connect Entry Screen - Title",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_logo: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_logo],
        title: "Connect Entry Screen Logo",
        type: ParameterType[ParameterType.image]
      },
      connect_screen_logo_size: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_logo_size],
        title: "Connect Entry Screen Logo Size [0-100]",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_connecting_image: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_image],
        title: "Connect Entry Screen - Connecting Image",
        type: ParameterType[ParameterType.image]
      },
      connect_screen_subtitle: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_subtitle],
        title: "Connect Entry Screen - Subtitle",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_connect_button: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button],
        title: "Connect Entry Screen - Connect Button Text",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_connect_button_text_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_text_color],
        title: "Connect Entry Screen - Connect Button Text Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_connect_button_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_background_color],
        title: "Connect Entry Screen - Connect Button Background Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_connect_panel_bottom: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_bottom],
        title: "Connect Entry Screen - Connect Panel Position - Bottom",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_connect_panel_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_background_color],
        title: "Connect Entry Screen - Connect Panel Background Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_connect_info_visible: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_info_visible],
        title: "Connect Entry Screen - Connect Info Enabled",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_connect_panel_waittime_label: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_waittime_label],
        title: "Connect Entry Screen - Connect Panel Wait Time Label (use [wait] as value placeholder)",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_connect_panel_position_label: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_position_label],
        title: "Connect Entry Screen - Connect Panel Queue Position Label (use [position] as value placeholder)",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_callback_button_visible: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button_visible],
        title: "Connect Entry Screen - Callback Button Enabled",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_dropcall_button_visible: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button_visible],
        title: "Connect Entry Screen - Drop Call Button Enabled",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_dropcall_button: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button],
        title: "Connect Entry Screen - Drop Call Button Text",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_dropcall_button_text_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button_text_color],
        title: "Connect Entry Screen - Drop Call Button Text Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_dropcall_button_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button_background_color],
        title: "Connect Entry Screen - Drop Call Button Background Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_callback_button: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button],
        title: "Connect Entry Screen - Callback Button Text",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_callback_button_text_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button_text_color],
        title: "Connect Entry Screen - Callback Button Text Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_callback_button_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button_background_color],
        title: "Connect Entry Screen - Callback Button Background Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_callback_phone_number_text: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_callback_phone_number_text],
        title: "Connect Entry Screen - Callback Phone Number Label",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_callback_send_request_Button: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_callback_send_request_Button],
        title: "Connect Entry Screen - Callback Send Request Button Text",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_callback_cancel_Button: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_callback_cancel_Button],
        title: "Connect Entry Screen - Callback Cancel Button Text",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_callback_close_browser_label: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_callback_close_browser_label],
        title: "Connect Entry Screen - Callback Close Browser Text",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_reject_camera_permission_label: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_reject_camera_permission_label],
        title: "Connect Entry Screen - Guest Reject Camera Permission",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_connect_button_auto_full_screen: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_auto_full_screen],
        title: "Connect Entry Screen - Connect Button Auto Full Screen",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_connect_button_hidden: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_hidden],
        title: "Connect Entry Screen - Connect Button Hidden",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_use_rectangle_button: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_use_rectangle_button],
        title: "Connect Entry Screen - Use Rectangle Button",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_connecting_status_label: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_status_label],
        title: "Connect Entry Screen - Connecting Status Label",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_connecting_status_audio: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_status_audio],
        title: "Connect Entry Screen - Connecting Status Audio",
        type: ParameterType[ParameterType.video]
      },
      connect_screen_onhold_status_label: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_onhold_status_label],
        title: "Connect Entry Screen - Onhold Status Label",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_no_operator_available_label: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_no_operator_available_label],
        title: "Connect Entry Screen - No Operator Available Label",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_info_position_top: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_info_position_top],
        title: "Connect Entry Screen - Info Position Top",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_info_position_width: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_info_position_width],
        title: "Connect Entry Screen - Info Position Width",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_invisible_status_label: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_invisible_status_label],
        title: "Connect Entry Screen - Invisible Status Label",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_connecting_label_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_label_color],
        title: "Connect Entry Screen - Connecting Status Label Text Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_connecting_label_size: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_label_size],
        title: "Connect Entry Screen - Connecting Status Label Text Size",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_connecting_label_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_label_background_color],
        title: "Connect Entry Screen - Connecting Status Label Background Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_voice_call_screen_title: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_voice_call_screen_title],
        title: "Connect Entry Screen Voice Call Title",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_wait_image: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_wait_image],
        title: "Connect Entry Screen - Wait Image",
        type: ParameterType[ParameterType.image]
      },
      connect_screen_wait_video: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_wait_video],
        title: "Connect Entry Screen - Wait Video",
        type: ParameterType[ParameterType.video]
      },
      connect_screen_wait_background_color: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_wait_background_color],
        title: "Connect Entry Screen - Wait Background Color",
        type: ParameterType[ParameterType.color]
      },
      connect_screen_wait_screen_embedded_url: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_wait_screen_embedded_url],
        title: "Connect Entry Screen - Wait Embedded URL",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_wait_screen_embedded_title: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_wait_screen_embedded_title],
        title: "Connect Entry Screen - Wait Embedded Title",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_wait_screen_embedded_style: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_wait_screen_embedded_style],
        title: "Connect Entry Screen - Wait Embedded Style",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_auto_connect: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_auto_connect],
        title: "Connect Entry Screen - Auto Connect Enabled",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_auto_leave: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_auto_leave],
        title: "Connect Entry Screen - Auto Leave Enabled",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_auto_answer: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_auto_answer],
        title: "Connect Entry Screen - Auto Answer Enabled",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_touchless: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_touchless],
        title: "Connect Entry Screen Touchless Connect",
        type: ParameterType[ParameterType.checkbox]
      },
      connect_screen_touchless_message: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_message],
        title: "Connect Entry Screen Touchless Message",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_touchless_detection: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection],
        title: "Connect Entry Screen Touchless Detection Time as [1-99] seconds",
        type: ParameterType[ParameterType.number]
      },
      connect_screen_touchless_detection_ratio: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection_ratio],
        title: "Connect Entry Screen Touchless Detection Face Size [1-100]%",
        type: ParameterType[ParameterType.number]
      },
      connect_screen_touchless_detection_movement: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection_movement],
        title: "Connect Entry Screen Touchless Detection Allowed Movement [1-100]%",
        type: ParameterType[ParameterType.number]
      },
      connect_screen_touchless_detection_confidence: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection_confidence],
        title: "Connect Entry Screen Touchless Detection Allowed Confidence [1-100]%",
        type: ParameterType[ParameterType.number]
      },
      connect_screen_touchless_autodrop: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_autodrop],
        title: "Connect Entry Screen Touchless Auto-Drop Time in seconds",
        type: ParameterType[ParameterType.number]
      },
      connect_screen_touchless_autodrop_message: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_autodrop_message],
        title: "Connect Entry Screen Touchless Auto-Drop Message",
        type: ParameterType[ParameterType.string]
      },
      connect_screen_mobile_footer: {
        key: FlavorParameterKey[FlavorParameterKey.connect_screen_mobile_footer],
        title: "Connect Entry Screen Mobile Device Message",
        type: ParameterType[ParameterType.string] 
      },
      queue_screen_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.queue_screen_enabled],
        title: "Queue Selection Screen",
        type: ParameterType[ParameterType.checkbox]
      },
      queue_screen_list: {
        key: FlavorParameterKey[FlavorParameterKey.queue_screen_list],
        title: "Queue list",
        type: ParameterType[ParameterType.queue_screen_list],
        options: {queueCat: "Queue Category", queueLang: "Queue Language", langCode: "Language Code", languageText: "Language Text", categoryText: "Category Text", startButtonText: "Start Button Text"}
      },
      ice_config_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.ice_config_enabled],
        title: "Custom ICE Configuration",
        type: ParameterType[ParameterType.checkbox]
      },
      ice_config_list: {
        key: FlavorParameterKey[FlavorParameterKey.ice_config_list],
        title: "ICE Servers",
        type: ParameterType[ParameterType.account_list]
      },
      error_message_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.error_message_enabled],
        title: "Error Messages",
        type: ParameterType[ParameterType.hidden]
      },
      error_message_list: {
        key: FlavorParameterKey[FlavorParameterKey.error_message_list],
        title: "Error Message Overrides",
        type: ParameterType[ParameterType.key_value_list],
        options: { key: "Error Code", value: "Error Message" }
      },
      twilio_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.twilio_enabled],
        title: "Voice Configuration",
        type: ParameterType[ParameterType.checkbox]
      },
      twilio_number: {
        key: FlavorParameterKey[FlavorParameterKey.twilio_number],
        title: "Number",
        type: ParameterType[ParameterType.string]
      },
      twilio_allow_dial_out: {
        key: FlavorParameterKey[FlavorParameterKey.twilio_allow_dial_out],
        title: "Allow Operator Dialout",
        type: ParameterType[ParameterType.checkbox]
      },
      twilio_error_message: {
        key: FlavorParameterKey[FlavorParameterKey.twilio_error_message],
        title: "Error message",
        type: ParameterType[ParameterType.string]
      },
      twilio_gather_active: {
        key: FlavorParameterKey[FlavorParameterKey.twilio_gather_active],
        title: "Enable pin gather",
        type: ParameterType[ParameterType.checkbox]
      },
      twilio_gather_message: {
        key: FlavorParameterKey[FlavorParameterKey.twilio_gather_message],
        title: "Gather message",
        type: ParameterType[ParameterType.string]
      },
      twilio_gather_message_delay: {
        key: FlavorParameterKey[FlavorParameterKey.twilio_gather_message_delay],
        title: "Gather message delay  (seconds)",
        type: ParameterType[ParameterType.number]
      },
      twilio_gather_timeout: {
        key: FlavorParameterKey[FlavorParameterKey.twilio_gather_timeout],
        title: "Gather timeout (seconds)",
        type: ParameterType[ParameterType.number]
      },
      twilio_gather_pin_length: {
        key: FlavorParameterKey[FlavorParameterKey.twilio_gather_pin_length],
        title: "Gather pin length",
        type: ParameterType[ParameterType.number]
      },
      custom_css_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.custom_css_enabled],
        title: "Custom CSS",
        type: ParameterType[ParameterType.checkbox]
      },
      custom_css_apply_ada_styles: {
        key: FlavorParameterKey[FlavorParameterKey.custom_css_apply_ada_styles],
        title: "Apply ADA Styles",
        type: ParameterType[ParameterType.checkbox]
      },
      custom_css_content: {
        key: FlavorParameterKey[FlavorParameterKey.custom_css_content],
        title: "Custom CSS Content",
        type: ParameterType[ParameterType.textarea]
      },
      rtc_config_enabled: {
        key: FlavorParameterKey[FlavorParameterKey.rtc_config_enabled],
        title: "RTC Configuration",
        type: ParameterType[ParameterType.checkbox]
      },
      rtc_config_bundle_policy: {
        key: FlavorParameterKey[FlavorParameterKey.rtc_config_bundle_policy],
        title: "Bundle Policty",
        type: ParameterType[ParameterType.dropdown],
        options: {
          "balanced": "balanced",
          "max-compat": "max-compat",
          "max-bundle": "max-bundle"
        },
        value: "balanced"
      }
    };
    let currentUser: IUser = Companion.getUserService().currentUser;

    defaultFlavorTemplate.parameters.mode_groupId.options = _.fromPairs(
      _.map(groups, (group: any) => {
        return [group["_id"], group["name"]];
      })
    );
    console.log("GroupSelection", currentUser.groups);
    if (currentUser.groups && currentUser.groups[0]) {
      let group: IGroup =  currentUser.groups[0] as any;
      if (group.twilioAccount && group.twilioAccount.phoneNumbers) {
        let options = _.keyBy(group.twilioAccount.phoneNumbers, function(number) {
          return number;
        });
        defaultFlavorTemplate.parameters["twilio_number"].type = ParameterType[ParameterType.dropdown];
        defaultFlavorTemplate.parameters["twilio_number"].options = options;
        defaultFlavorTemplate.parameters["twilio_number"].value = group.twilioAccount.phoneNumbers[0];
      }

      if (currentUser.groups.length > 0) {
        defaultFlavorTemplate.parameters.mode_groupId.value = currentUser.groups[0]["_id"];
      }
    }
    return defaultFlavorTemplate;
  }

  seedDefaultValues(language: string = "en"): void {
    let lData = FlavorUtil.getDefaultLocalizationValues(language);
    let flavorTemplate = Flavor.getFlavorTemplate(lData.style, language, lData.mode, [])
    let defaultValues: { [flavorKey: string]: any } = FlavorUtil.convertLocalizationToFlavor(flavorTemplate, lData);
    defaultValues["mode_mode"] = this.mode;
    _.forEach(defaultValues, (value: any, key: string) => {
      if (this.parameters[key]) {
        this.parameters[key].value = value;
      }
    });
  }

  collectParameterValues(): { [key: string]: any } {
    let parameterValues: { [key: string]: any } = {};
    parameterValues = _.mapValues(this.parameters, (parameter: IParameter) => {
      return parameter.value;
    });
    return parameterValues;
  }

  convertToLocalization(): ILocalization {
    let parameterValues: { [key: string]: any } = this.collectParameterValues();
    let localization: ILocalization = {};
    localization.style = this.style;
    localization.language = this.language;
    _.forEach(parameterValues, (value: any, parameterKey: string) => {
      FlavorUtil.setLocalizationValueByFlavorKey(localization, parameterKey, value);
    });
    _.forEach(FlavorParameterKey, (enumValue: number, parameterKey: string) => {
      if (parameterKey.endsWith("_enabled")) {
        FlavorUtil.setLocalizationValueByFlavorKey(localization, parameterKey, parameterValues[parameterKey]);
      }
    });
    return localization;
  }

  /**
   * create form group
   */
  toFormGroup(): FormGroup {
    return FormService.toFormGroup(this.parameters);
  }

  /**
   * update value by Form values
   */
  updateFlavorByForm(form: FormGroup): void {
    FormService.updateParametersByForm(form, this.parameters);
  }
}
