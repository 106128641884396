/**
 * Copyright Compunetix Incorporated 2022-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta, kbender
 */

import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {DashboardService} from "client/scripts/dashboard/dashboard.service";
import {
  IAgentsStatus,
  AgentStatus,
  IAgent,
  ITrendsData,
  ISkillTags
} from "companion";
import {TimeService} from "client/scripts/shared/services/time.service";
import {distinctUntilChanged, map} from "rxjs/operators";
import {Store} from "client/scripts/Store/store.service";
import {Subscription} from "rxjs";
import {IBarChartItem} from "../shared/bar-chart.component";
import {IDateRange, PickerMode} from "../shared/range-picker.component";
import { IChartData } from "../shared/trends-chart.component";
import { format, formatDuration, subDays, parseISO } from "date-fns";
import { FormatTimePipe } from "../shared/format-time.pipe";

// Dashboard Agent's Performances tab
@Component({
  selector: "dashboard-agent",
  templateUrl: "./agent.component.html",
  styleUrls: ["./agent.component.scss"],
  providers: [FormatTimePipe]
})
export class DashboardAgentComponent implements OnInit, OnDestroy {

  constructor(
    private timeService: TimeService,
    private store: Store,
    public dashboardService: DashboardService,
    private formatTimePipe: FormatTimePipe
  ) {}

  @Input() groupId: string;

  callsBackgroundColors: string[];
  callsHoverBackgroundColors: string[];
  timeBackgroundColors: string[];
  timeHoverBackgroundColors: string[];
  storeSubscription: Subscription;
  agents: IAgent[] = [];
  selectedAgent: IAgent = null;
  selectedAgentId: string = null;
  agentStatus = AgentStatus;
  refreshTimer: any;
  statusDurationTimeString = "";
  lastUpdate: string;

  agentsStatus: IAgentsStatus;
  allAgents: IAgent;

  selectedDates: Date[];
  selectedAggregation: PickerMode;

  trendsCallsData: IChartData;
  trendsTimeData: IChartData;

  barChartItems: IBarChartItem[] = [];

  ngOnInit(): void {
    this.init();
    this.refreshData(true);
    this.refreshTimer = setInterval(() => {
      this.refreshData(false);
    }, this.dashboardService.clientRefreshRate * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshTimer);
    this.storeSubscription.unsubscribe();
  }

  init() {
    this.callsBackgroundColors = ["#21D59B", "#18A377", "#000000", "#0058FF", "#FFC700"];
    this.callsHoverBackgroundColors =  ["rgba(33,213,155,0.6)", "rgba(24,163,119,0.6)", "rgba(0,0,0,0.6)", "rgba(0,88,255,0.6)", "rgba(255,199,0,0.6)"];

    this.timeBackgroundColors = ["#21D59B", "rgba(41, 203, 151, 0.3)", "#0058FF", "rgba(0, 88, 255, 0.3)"];
    this.timeHoverBackgroundColors = ["rgba(33,213,155,0.6)", "rgba(41, 203, 151, 0.2)", "rgba(0,88,255,0.6)", "rgba(0, 88, 255, 0.2)"];

    this.agentsStatus = {
      totalConferencing: 0,
      totalAvailable: 0,
      totalUnavailable: 0,
      totalOffline: 0
    };

    this.allAgents = {
      id: "all-agents",
      avatarImageUrl: "images/dashboard/users.png",
      username: "All Agents",
      role: null,
      status: null,
      statusStartedAt: null,
      skillSet: ""
    };

    const startDate = subDays(new Date(), 6);
    const endDate = new Date();
    this.selectedDates = [startDate, endDate];

    this.storeSubscription = this.store.changes
      .pipe(map(data => data.dashboardGroup), distinctUntilChanged())
      .subscribe(dashboardGroup => {
        if (dashboardGroup) {
          this.refreshData(true);
        }
      });

    this.storeSubscription = this.store.changes
      .pipe(map(data => data.language), distinctUntilChanged())
      .subscribe(language => {
        if (language) {
          this.refreshData(true);
        }
      });
      this.storeSubscription = this.store.changes
      .pipe(map(data => data.category), distinctUntilChanged())
      .subscribe(category => {
        if (category) {
          this.refreshData(true);
        }
      });
  }

  refreshData(refreshTrends) {
    let filterTags : ISkillTags = {
      language : this.store.getState().language === "All" ? null : this.store.getState().language,
      category : this.store.getState().category === "All" ? null : this.store.getState().category,
    };
    
    if(!filterTags.category && !filterTags.language)
    {
      filterTags = null;
    }
    
    this.dashboardService.fetchAgentsCurrentStatus(filterTags, true)
    .then((response) => {
      this.lastUpdate = response.lastUpdate;
      this.agents = response.data.agents;
      this.agents.unshift(this.allAgents);

      if (!this.selectedAgent) {
        this.selectedAgent = this.agents[0];
        refreshTrends = true;
      }

      this.agentsStatus = response.data.agentsStatus;
      this.createBarChartItems(this.agentsStatus);
      this.selectAgent(this.selectedAgent.id, refreshTrends);
    })
    .catch((error) => {
      console.log("Failed to fetchAgentsCurrentStatus: ", error);
    });
  }

  getTrends(selection?: IDateRange) {
    if (selection) {
      this.selectedAggregation = selection.mode;
      this.selectedDates = selection.range;
    }

    if (!this.selectedAgent) {
      return;
    }
    
    // Determine if we are filtering the results or not.
    let filterTags : ISkillTags = {
      language : this.store.getState().language === "All" ? null : this.store.getState().language,
      category : this.store.getState().category === "All" ? null : this.store.getState().category,
    };
    
    if(!filterTags.category && !filterTags.language)
    {
      filterTags = null;
    }
    
    let from = this.selectedDates[0];
    let to = this.selectedDates[1];
    from.setHours(0, 0, 0, 0);
    to.setHours(23, 59, 59, 999);

    const username = this.selectedAgent.username === this.allAgents.username ? null : this.selectedAgent.username;
    this.dashboardService.fetchAgentsCallsStatisticsAsync(from, to, this.selectedAggregation + "s", username, filterTags)
    .then((taskId) => {
      this.dashboardService.isLoading = true;
      this.dashboardService.monitorAsyncTask({
        taskId: taskId, 
        completeFn: (data: any) => {
          this.generateChartData(data);
          this.dashboardService.isLoading = false;
        },
        errorFn: () => {
          this.dashboardService.isLoading = false;
          console.log("Async agent stats fetch failed. Check server for details.");
        }
      });
    })
    .catch((error) => {
      console.log("Failed to fetchAgentsCallsStatistics: ", error);
    });
  }

  getStatusDurationTime() {
    if (this.selectedAgent.statusStartedAt) {
      const timePassed: number = (Date.now() - this.timeService.serverTimeDelay - this.selectedAgent.statusStartedAt);
      this.statusDurationTimeString = this.formatTimePipe.transform(timePassed);
    } else {
      this.statusDurationTimeString = "-";
    }
  }

  selectAgent(agentId: string, refreshTrends = true) {
    const foundAgent = this.agents.find(agent => agent.id === agentId);
    this.selectedAgent = foundAgent ? foundAgent : this.allAgents;
    this.selectedAgentId = this.selectedAgent.id;
    this.getStatusDurationTime();
    if (refreshTrends) {
      this.getTrends();
    }
  }
  
  formatTime(milliseconds: number): string {
    return DashboardService.msToMin(milliseconds);
  }

  formatDates(dates: Date[], granularity: PickerMode): string[] {
    return dates.map(date => {
      if(typeof date === "string")
      {
        date = parseISO(date);
      }
      switch (granularity) {
        case PickerMode.day:
          return format(date, "do MMM");
        case PickerMode.month:
          return format(date, "MMM yyyy");
        case PickerMode.week:
          return format(date, "yyyy w");
      }
    });
  }

  generateChartData(trendsData: ITrendsData) {
    this.trendsCallsData = {
      chartName: "Calls",
      stacked: false,
      labels: this.formatDates(trendsData.dates, this.selectedAggregation),
      dataSets: [
        {
          label: "Inbound",
          data: trendsData.inboundCalls
        },
        {
          label: "Outbound",
          data: trendsData.outboundCalls
        },
        {
          label: "Rejected",
          data: trendsData.rejectedCalls
        },
        {
          label: "Transferred",
          data: trendsData.transferredCalls
        },
        {
          label: "Transfer received",
          data: trendsData.transferReceivedCalls
        }
      ]
    };

    this.trendsTimeData = {
      chartName: "Time",
      stacked: true,
      labels: this.formatDates(trendsData.dates, this.selectedAggregation),
      dataSets: [
        {
          label: "Average Handle Time",
          stack: "handle time",
          data: trendsData.handleTimesAvg
        },
        {
          label: "Maximum Handle Time",
          stack: "handle time",
          data: trendsData.handleTimesMax
        },
        {
          label: "Average Hold Time",
          stack: "hold time",
          data: trendsData.holdTimesAvg
        },
        {
          label: "Maximum Hold Time",
          stack: "hold time",
          data: trendsData.holdTimesMax
        }
      ]
    };
  }

  createBarChartItems(value: IAgentsStatus) {
    this.barChartItems = [
      {
        label: "Conferencing",
        value: value.totalConferencing,
        barColor: "bar-green"
      },
      {
        label: "Available",
        value: value.totalAvailable,
        barColor: "bar-blue"
      },
      {
        label: "Unavailable",
        value: value.totalUnavailable,
        barColor: "bar-yellow"
      },
      {
        label: "Offline",
        value: value.totalOffline,
        barColor: "bar-black"
      },
    ];
  }
}
