/**
 * Copyright Compunetix Incorporated 2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
/**
 * Inspect Thumbnail Component includes:
 * mic indicator,
 * camera indicator,
 * speaker indicator,
 * network indicator,
 * framerate indicator,
 * resolution indicator,
 * bandwidth indicator
 */
import { Component, OnInit, Input, ViewChildren, QueryList } from "@angular/core";
import { LocalizationService } from "client/scripts/localization/localization.service";
import { VolumeMeterComponent } from "client/scripts/shared/components/volume-meter/volume-meter.component";
import { ActionType, Dispatcher } from "client/scripts/shared/services/dispatcher";
import {
  Companion,
  IEndpoint,
  IPCStats,
  IRTCClient,
  IRtpStats,
  AlertLevel,
  Alert,
  IExpandedActiveConference,
} from "companion";

@Component({
  selector: "inspect-thumbnail",
  templateUrl: "./inspect-thumbnail.component.html",
  styleUrls: ["./inspect-thumbnail.component.scss"],
})
export class InspectThumbnailComponent implements OnInit {
  /**
   * current conference
   */
  @Input() conference: IExpandedActiveConference;
  /**
   * mic muted warning threshold
   */
  @Input() threshold: number = -50;
  /**
   * volume meter child view
   */
  @ViewChildren(VolumeMeterComponent) volumeMeterComponents: QueryList<VolumeMeterComponent>;
  public rtcClient: IRTCClient = Companion.getRTCClient();
  public myEndpoint: IEndpoint = Companion.getEndpointService().myEndpoint;
  public get myLatestStats(): IPCStats {
    return this.myEndpoint.lastStats;
  }

  public microphone: string;
  public camera: string;
  public speaker: string;
  public isExpanded: boolean = false;
  public micStream: MediaStream;
  public mainStream: MediaStream;
  public micMuted: boolean = this.rtcClient.audioMuted;
  public lastTimeWarnMicMuted: Date;

  /**
   * every few seconds
   */
  public micMutedWarningFrequency: number = 5;

  public get participants(): IEndpoint[] {
    return this.conference?.active;
  }

  public get totalSenderBitrateInKbps(): number {
    return Math.round(
      _.sumBy(this.participants, (ep: IEndpoint) => {
        if (!ep.lastStats || !ep.lastStats.senders) {
          return 0;
        }
        return _.sumBy(ep.lastStats.senders, (sender: IRtpStats) => {
          return sender.bitrate || 0;
        });
      }) / 1024
    );
  }

  public get totalReceiverBitrateInKbps(): number {
    return Math.round(
      _.sumBy(this.participants, (ep: IEndpoint) => {
        if (!ep.lastStats || !ep.lastStats.receivers) {
          return 0;
        }
        return _.sumBy(ep.lastStats.receivers, (receiver: IRtpStats) => {
          return receiver.bitrate || 0;
        });
      }) / 1024
    );
  }

  public updateMyDevice() {
    this.camera = this.rtcClient.selectedPrimaryCameraOption
      ? this.rtcClient.selectedPrimaryCameraOption.label
      : "NOT_AVAILABLE";
    this.microphone = this.rtcClient.selectedMicOption
      ? this.rtcClient.selectedMicOption.label
      : "NOT_AVAILABLE";
    this.speaker = this.rtcClient.selectedSpeakerOption
      ? this.rtcClient.selectedSpeakerOption.label
      : "NOT_AVAILABLE";

    if (
      this.mainStream !== this.rtcClient.cameraStream ||
      this.micStream !== this.rtcClient.micOnlyStream ||
      this.micMuted !== this.rtcClient.audioMuted
    ) {
      this.mainStream = this.rtcClient.cameraStream;
      this.micStream = this.rtcClient.micOnlyStream;
      this.micMuted = this.rtcClient.audioMuted;
      this.lastTimeWarnMicMuted = new Date();
      this.micMutedWarningFrequency = 5;
      if (this.volumeMeterComponents) {
        this.volumeMeterComponents.forEach((volumeMeterComponent: VolumeMeterComponent) => {
          if (volumeMeterComponent) {
            volumeMeterComponent.refresh();
          }
        });
      }
    }
    let updateMyDeviceTimer = setTimeout(() => {
      clearTimeout(updateMyDeviceTimer);
      this.updateMyDevice();
    }, 3 * 1000);
  }

  toggleThumbnailView() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }

  ngOnInit() {
    this.updateMyDevice();
  }

  micVolumeChanged(volume: number) {
    // ADD mute indication warning... if there is nothing don't show it...
    if (this.localizationService?.myLocalizationData?.video_screen?.micMuteWarningMessage &&
      volume > this.threshold &&
      this.rtcClient.audioMuted &&
      (!this.lastTimeWarnMicMuted || new Date().getTime() - this.lastTimeWarnMicMuted.getTime() > this.micMutedWarningFrequency * 1000)
    ) {
      this.lastTimeWarnMicMuted = new Date();
      this.micMutedWarningFrequency += 3;
      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert("MIC_MUTED_WARNING", 
          this.localizationService?.myLocalizationData?.video_screen?.micMuteWarningMessage, AlertLevel.prominent),
      });
    }
  }
}
