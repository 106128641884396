/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, NgZone, OnInit } from "@angular/core";
import { IEndpoint, IEndpointService, Companion, IChatRoom } from "companion";

@Component({
  selector: "endpoint-select-list",
  templateUrl: "./endpoint-select-list.template.html"
})
/**
 * endpoint selection list view
 */
export class EndpointSelectListComponent {
  /**
   * this list type
   */
  @Input() listType: string;
  /**
   * the endpoints which parent component passed in for this list view to render
   */
  @Input() endpoints: IEndpoint[];

  /**
   * the current chat room
   */
  @Input() room: IChatRoom;

  private endpointService: IEndpointService = Companion.getEndpointService();

  myEndpoint: IEndpoint = this.endpointService.myEndpoint;

  /**
   * count selected
   */
  get countSelected(): number {
    let result = 0;
    _.forEach(this.endpoints, (ep: any) => {
      if (ep.selected) {
        result++;
      }
    });
    return result;
  }

  constructor(private _ngZone: NgZone) {
    // nothing needed here
  }

  /**
   * select an endpoint and add it into room
   * @param endpoint: IEndpoint - endpoint selected
   */
  select(endpoint: any) {
    endpoint.selected = true;
  }

  /**
   * unselect an endpoint and remo it from room
   * @param endpoint: IEndpoint - endpoint unselected
   */
  unselect(endpoint: any) {
    endpoint.selected = false;
  }

  selectAll() {
    _.forEach(this.endpoints, (ep: any) => {
      ep.selected = true;
    });
  }

  unselectAll() {
    _.forEach(this.endpoints, (ep: any) => {
      if (ep.rtcId !== this.myEndpoint.rtcId) {
        ep.selected = false;
      }
    });
  }
}
