/**
 * Copyright Compunetix Incorporated 2022-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, kbender
 */

import {Component, EventEmitter, Output} from "@angular/core";
import {PhoneNumber, PhoneNumberFormat, PhoneNumberUtil} from "google-libphonenumber";
import {IDialOutData} from "client/scripts/dial-out/dial-out.component";
import {LocalizationService} from "client/scripts/localization/localization.service";


@Component({
  selector: "call-back-panel",
  templateUrl: "./call-back-panel.component.html",
  styleUrls: ["./call-back-panel.component.scss"]
})
export class CallBackPanelComponent {


  @Output("dialOutData") dialOutData: EventEmitter<IDialOutData> = new EventEmitter<IDialOutData>();
  @Output("cancelCallBack") cancelCallBack: EventEmitter<any> = new EventEmitter<any>();

  phoneNumber: string = "";
  parsedPhoneNumber: PhoneNumber = null;
  validPhoneNumber = false;
  internationalPhoneNumber: string;
  regionCode: string;

  constructor(public localizationService: LocalizationService) {}

  checkNumber(number) {
    let phoneUtil = PhoneNumberUtil.getInstance();
    try {
      this.parsedPhoneNumber = phoneUtil.parse(number, "US");
      if (this.parsedPhoneNumber) {
        this.regionCode = phoneUtil.getRegionCodeForNumber(this.parsedPhoneNumber);
        this.internationalPhoneNumber = phoneUtil.format(this.parsedPhoneNumber, PhoneNumberFormat.INTERNATIONAL);
        this.validPhoneNumber = phoneUtil.isPossibleNumber(this.parsedPhoneNumber);
      }
    } catch (e) {
      this.regionCode = null;
      this.internationalPhoneNumber = null;
      this.validPhoneNumber = false;
    }
  }

  callBack(): void {
    this.dialOutData.emit({
      name: null,
      displayPhoneNumber: this.internationalPhoneNumber,
      phone: this.phoneNumber
    });
  }

  cancel(): void {
    // clear the number
    this.phoneNumber = "";
    this.cancelCallBack.emit();
  }
}
