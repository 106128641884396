/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { VideoResolution } from "../settings/video-resolution";
import { IRTCServiceConnectionEventHandler } from "./rtc.service.connection-event-handler.interface";
import { IRTCServiceFileEventHandler } from "./rtc.service.file-event-handler.interface";
import { IRTCServiceServerMessageHandler } from "./rtc.service.server-message-handler.interface";
import { IRTCServiceStreamEventHandler } from "./rtc.service.stream-event-handler.interface";
import { AlertLevel } from "../alert/alert.interface";
import { DeviceInfo } from "../settings/device-info";
import { IEndpoint } from "../endpoint/endpoint.interface";
import { IVideoFilter } from "../video/video-filter.interface";
import { SegmentMethod } from "../video/video-filter.factory";

export interface IRTCClient {
  /**
   * session id of the web client
   */
  rtcId: string;

  /**
   * selected microphone
   */
  selectedMicOption: DeviceInfo;

  /**
   * selected camera
   */
  selectedPrimaryCameraOption: DeviceInfo;

  /**
   * selected camera rotation
   */
  selectedPrimaryCameraRotation: string;

  /**
   * selected secondary camera
   */
  selectedSecondaryCameraOption: DeviceInfo;

  /**
   * selected speaker
   */
  selectedSpeakerOption: DeviceInfo;

  /**
   * default streams
   */
  defaultStreams: {
    [streamId: string]: MediaStream;
  };

  /**
   * recording canvas stream
   */
  recordingCanvasStream: any;

  /**
   * broadcast canvas stream
   */
  broadcastCanvasStream: any;

  /**
   * camera stream
   */
  cameraStream: any;

  /**
   * flag if current camera is accessible
   */
  cameraAccessible: boolean;

  /**
   * flag if current microphone is accessible
   */
  microphoneAccessible: boolean;

  /**
   * mic only stream
   */
  micOnlyStream: any;

  /**
   * recording stream
   */
  recordingStream: any;

  /**
   * broadcast stream
   */
  broadcastStream: any;

  /**
   * avatar stream
   */
  avatarStream: any;

  /**
   * avatar image
   */
  avatarImageSrc: string;

    /**
   * flag if show content
   */
  showContentEnabled: boolean;

  /**
  * content stream
  */
  contentStream: any;

  /**
   * secondary stream
   */
  secondaryStream: any;

  /**
   * stream to transmit
   */
  streamToTransmit: any;

  /**
   * flag if show camera stream
   */
  cameraStreamEnabled: boolean;

  /**
   * flag if show filtered camera stream
   */
  filteredStreamEnabled: boolean;

  /**
   * virtual background segmentation method
   */
  virtualBackgroundMethod: SegmentMethod;

  /**
   * video filter util
   */
  videoFilter: IVideoFilter;

  /**
   * filtered camera stream
   */
  filteredStream: any;

  /**
   * flag if show screen capture
   */
  screenShareEnabled: boolean;

  /**
   * screen capture stream
   */
  screenCaptureStream: any;

  /**
   * screen source id
   */
  screenSourceid: string;

  /**
   * flag if audio muted
   */
  audioMuted: boolean;

  /**
   * flag if video muted
   */
  videoMuted: boolean;

  /**
   * flag if monitoring
   */
  monitoring: boolean;

  /**
   * flag if this client cannot join by audio only
   */
  disableAudioOnly: boolean;

  /**
   * the current system primary audio bandwidth
   */
  sysPrimaryAudioBandwidth?: number;

  /**
   * the current system secondary audio bandwidth
   */
  sysSecondaryAudioBandwidth?: number;

  /**
   * the current video bandwidth
   */
  userSelectedVideoBandwidth?: number;

  /**
   * max primary video bandwidth
   */
  maxPrimaryBandwidth?: number;

  /**
   * the current system primary video bandwidth
   */
  sysPrimaryVideoBandwidth?: number;

  /**
   * max secondary video bandwidth
   */
  maxSecondaryBandwidth?: number;

  /**
   * the current system secondary video bandwidth
   */
  sysSecondaryVideoBandwidth?: number;

  /**
   * resolution options
   */
  resolutionOptions: VideoResolution[];

  /**
   * bandwidth options
   */
  bandwidthOptions: number[];

  /**
   * pc default primary resolution
   */
  PC_DEFAULT_PRIMARY_RESOLUTION: VideoResolution;

  /**
   * mobile default primary resolution
   */
  MOBILE_DEFAULT_PRIMARY_RESOLUTION: VideoResolution;

  /**
   * auto mode primary resolution
   */
  AUTO_MODE_PRIMARY_RESOLUTION: VideoResolution;

  /**
   * default primary resolution
   */
  DEFAULT_PRIMARY_RESOLUTION_IN_THEME: VideoResolution;

  /**
   * the user selected primary video resolution
   */
  userSelectedPrimaryResolution?: VideoResolution;

  /**
   * max primary video resolution
   */
  maxPrimaryResolution?: VideoResolution;

  /**
   * the current system primary video resolution
   */
  currentSysPrimaryResolution: VideoResolution;

  /**
   * pc default secondary resolution
   */
  PC_DEFAULT_SECONDARY_RESOLUTION: VideoResolution;

  /**
   * mobile default secondary resolution
   */
  MOBILE_DEFAULT_SECONDARY_RESOLUTION: VideoResolution;

  /**
   * auto mode secondary resolution
   */
  AUTO_MODE_SECONDARY_RESOLUTION: VideoResolution;

  /**
   * default secondary resolution
   */
  DEFAULT_SECONDARY_RESOLUTION_IN_THEME: VideoResolution;

  /**
   * the user selected secondary video resolution
   */
  userSelectedSecondaryResolution?: VideoResolution;

  /**
   * max secondary video resolution
   */
  maxSecondaryResolution?: VideoResolution;

  /**
   * the current system secondary video resolution
   */
  currentSysSecondaryResolution: VideoResolution;

  /**
   * video source id
   */
  primaryVideoSrcId: string;

  /**
   * ideal video facingMode
   */
  facingMode: string;

  /**
   * audio source id
   */
  audioSrcId: string;

  /**
   * ideal primary video framerate
   */
  primaryVideoFrameRate: number;

  /**
   * ideal secondary video framerate
   */
  secondaryVideoFrameRate: number;

  /**
   * default primary max framerate
   */
  maxPrimaryFramerate: number;

  /**
  * default secondary max framerate
  */
  maxSecondaryFramerate: number;

  /**
   * ideal filtered video framerate
   */
  filteredVideoFrameRate: number;

  /**
   * default max framerate of filtered video
   */
  maxFilteredVideoFramerate: number;

  /**
   * theme configured presentation sharpness first resolution
   */
  PRESENTATION_SHARPNESS_FIRST_RESOLUTION: VideoResolution;

  /**
   * theme configured presentation sharpness first framerate
   */
  PRESENTATION_SHARPNESS_FIRST_FRAMERATE: number;


  /**
   * theme configured presentation motion first resolution
   */
  PRESENTATION_MOTION_FIRST_RESOLUTION: VideoResolution;

  /**
   * theme configured presentation motion first framerate
   */
  PRESENTATION_MOTION_FIRST_FRAMERATE: number;

  /**
   * current presentation mode
   */
  presentationMode: PresentationMode;

  /**
   * pc default bandwidth
   */
  PC_DEFAULT_BANDWIDTH: number;

  /**
   * mobile default bandwidth
   */
  MOBILE_DEFAULT_BANDWIDTH: number;

  /**
   * default bandwidth
   */
  DEFAULT_BANDWIDTH_IN_THEME: number;

  /**
   * shared audio context
   */
  audioContext: any;

  /**
   * destination node of audio mix as virtual mic
   */
  virtualMicAudioMixDestinationNode: any;

  /**
   * source node of mic audio
   */
  micAudioSourceNode: MediaStreamAudioSourceNode;

  /**
   * source node of desktop audio
   */
  desktopAudioSourceNode: MediaStreamAudioSourceNode;

  /**
   * destination node of audio mix as transmitting audio source
   */
  transmittingAudioMixDestinationNode: any;

  /**
   * rtc connection event handler
   */
  connectionEventHandler: IRTCServiceConnectionEventHandler;

  /**
   * file event handler
   */
  fileEventHandler: IRTCServiceFileEventHandler;

  /**
   * server message event handler
   */
  serverMessageHandler: IRTCServiceServerMessageHandler;

  /**
   * stream event handler
   */
  streamEventHandler: IRTCServiceStreamEventHandler;

  /**
   * dual channel enabled
   */
  dualChannelEnabled: boolean;

  /**
   * support dual channel
   */
  supportDualChannel: boolean;

  /**
   * auto reconnect server
   */
  autoReconnectServer: boolean;

  /**
   * peerconnection config
   */
  peerconnectionConfig: any;

  /**
   * avatar image element
   */
  avatarImageElement: HTMLImageElement;

  /**
   * avatar canvas element
   */
  avatarCanvasElement: HTMLCanvasElement;

  /**
   * gifler animate object
   */
  gifAnimator: any;

  /**
   * timer for media permission check
   */
  mediaPermissionsCheckTimer: any;

  /**
   * avatar canvas draw timer
   */
  avatarCanvasDrawTimer: any;

  /**
   * secondary canvas draw interval
   */
  secondaryCanvasDrawInterval: any;

  /**
   * secondary canvas element
   */
  secondaryCanvasElement: HTMLCanvasElement;

  /**
   * screen capture video element
   */
  secondaryVideoElement: HTMLVideoElement;

  /**
   * camera request queue
   */
  cameraRequestProcess: Promise<void>;
  cameraRequestQueue: {
    forceNew: boolean;
    resolve: (stream: MediaStream) => void;
    reject: (error: Error) => void;
  }[];

  /**
   * secondary camera request queue
   */
  secondaryCameraRequestProcess: Promise<void>;
  secondaryCameraRequestQueue: {
    forceNew: boolean;
    resolve: (stream: MediaStream) => void;
    reject: (error: Error) => void;
  }[];

  /**
   * file senders
   */
  fileSenders: {
    [rtcId: string]: any;
  };

  /**
   * customized audio codecs
   */
  audioCodecBlackList: string[];

  /**
   * customized video codecs
   */
  videoCodecBlackList: string[];

  /**
   * customized video codecs for secondary video channel
   */
  secondaryVideoCodecBlackList: string[];

  /**
   * the audio source element to feed speaker test
   */
  speakerTestAudioSourceElement: HTMLAudioElement;

  /**
   * the audio element to play speaker test
   */
  speakerTestAudioPlayElement: HTMLAudioElement;

  /**
   * speaker test audio source node
   */
  speakerTestAudioSourceNode: MediaElementAudioSourceNode;

  /**
   * speaker test audio destination node
   */
  speakerTestAudioDestinationNode: any;

  /**
   * flag if secondary camera source enabled
   */
  secondaryCameraEnabled: boolean;

  /**
   * secondary camera stream
   */
  secondaryCameraStream: any;

  /**
   * secondary video source id
   */
  secondaryVideoSrcId: string;

  /**
   * use relay candidate only
   */
  relayCandidateOnly: boolean;

  /**
   * use ipv4 candidate only
   */
  ipv4CandidateOnly: boolean;

  /**
   * use rtp candidate only
   */
  rtpCandidateOnly: boolean;

  /**
   * server configured disconnect timeout
   */
  SERVER_DISCONNECT_TIMEOUT;

  /**
   * server disconnect error message
   */
  serverDisconnectErrorCode: string;

  /**
   * flag if client shall send logs to server
   */
  sendLogsToServer: boolean;

  /**
   * set alert handler
   */
  alertHandler(alertCode?: string, alertText?: string, alertLevel?: AlertLevel): void;

  /**
   * prepare AudioContext
   */
  prepareAudioContext(): void;

  /**
   * check if local dual channel supported
   */
  isLocalDualChannelSupported(): boolean;

  /**
   * to reset max primary resolution for a new call
   */
  resetMaxPrimaryResolution();

  /**
   * to reset max secondary resolution for a new call
   */
  resetMaxSecondaryResolution();

  /**
   * current silence chat audio alerts status
   */
  silenceChatAudioAlerts: boolean;

}

export enum VideoMediaConnectionMode {
  None = 0,
  Conference,
  Broadcasting
}

export enum PresentationMode {
  Sharpness_First,
  Motion_First
}
