/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";

import { HttpClientModule } from "@angular/common/http";
import { LoginModalComponent } from "./login-modal.component";
import { LoginFormComponent } from "./login-form.component";
import { LoginScreenComponent } from "./login-screen.component";
import { LoginComponent } from "./login.component";
import { SharedModule } from "../shared/shared.module";
import { LayoutModule } from "../layout/layout.module";

@NgModule({
  imports: [BrowserModule, FormsModule, HttpClientModule, SharedModule, LayoutModule],
  declarations: [LoginFormComponent, LoginModalComponent, LoginScreenComponent, LoginComponent],
  exports: [LoginFormComponent, LoginModalComponent, LoginScreenComponent, LoginComponent]
})
export class LoginModule {}
