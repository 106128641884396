/**
 * Copyright Compunetix Incorporated 2022-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author: amaggi, frivolta, kbender
 */

import {Component, OnDestroy, OnInit} from "@angular/core";
import {DashboardService} from "client/scripts/dashboard/dashboard.service";
import {
  IAgentsStatus,
  ICallsCurrentStatus,
  ICallsDailyStatus,
  ISkillTags
} from "companion";
import {distinctUntilChanged, map} from "rxjs";
import {Store} from "client/scripts/Store/store.service";
import {Subscription} from "rxjs";
import {IBarChartItem} from "../shared/bar-chart.component";
import {IPercentageData} from "./tile.component";
import { GroupManagementService } from "client/scripts/group-management/group-management.service";

interface IOverviewPercentages {
  inQueue: IPercentageData;
  inProgress: IPercentageData;
  onHold: IPercentageData;
  yesterdayWaitTimePercentage: IPercentageData;
  previousWeekWaitTimePercentage: IPercentageData;
  yesterdayTalkTimePercentage: IPercentageData;
  previousWeekTalkTimePercentage: IPercentageData;
}

// Dashboard Overview tab
@Component({
  selector: "dashboard-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"]
})
export class DashboardOverviewComponent implements OnInit, OnDestroy {

  constructor(private dashboardService: DashboardService, private groupManagementService: GroupManagementService, private store: Store) {}

  lastUpdate: string;
  storeSubscription: Subscription;
  callsStatus: ICallsCurrentStatus;
  agentsStatus: IAgentsStatus;
  inOutCallsChartData: IBarChartItem[];
  dailyTotal: ICallsDailyStatus;
  percentages: IOverviewPercentages;
  barChartItems: IBarChartItem[] = [];

  refreshTimer;

  ngOnInit(): void {
    this.init();
    this.refreshData();
    this.refreshTimer = setInterval(() => {
      this.refreshData();
    }, this.dashboardService.clientRefreshRate * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshTimer);
    this.storeSubscription.unsubscribe();
  }

  init() {
    this.callsStatus = {
      realtimeStatistics: {
        inQueue: 0,
        inProgress: 0,
        onHold: 0
      },
      previousHourStatistics: {
        available: 0,
        inQueue: 0,
        inProgress: 0,
        onHold: 0
      }
    };

    this.agentsStatus = {
      totalConferencing: 0,
      totalAvailable: 0,
      totalUnavailable: 0,
      totalOffline: 0
    };

    this.dailyTotal = {
      daily: {
        inbound: 0,
        outbound: 0,
        answered: 0,
        abandoned: 0,
        rejected: 0,
        transferred: 0,
        transferReceived: 0,
        waitTime: {
          avg: 0,
          max: 0
        },
        talkTime: {
          avg: 0,
          max: 0
        },
        holdTime: {
          avg: 0,
          max: 0
        }
      }
    };

    this.percentages = {
      inQueue: {
        value: null,
        color: "text-black2",
        arrow: "none",
        label: ""
      },
      inProgress: {
        value: null,
        color: "text-black2",
        arrow: "none",
        label: ""
      },
      onHold: {
        value: null,
        color: "text-black2",
        arrow: "none",
        label: ""
      },
      yesterdayWaitTimePercentage: {
        value: null,
        color: "text-black2",
        arrow: "none",
        label: ""
      },
      previousWeekWaitTimePercentage: {
        value: null,
        color: "text-black2",
        arrow: "none",
        label: ""
      },
      yesterdayTalkTimePercentage: {
        value: null,
        color: "text-black2",
        arrow: "none",
        label: ""
      },
      previousWeekTalkTimePercentage: {
        value: null,
        color: "text-black2",
        arrow: "none",
        label: ""
      }
    };

    this.storeSubscription = this.store.changes
      .pipe(map(data => data.dashboardGroup), distinctUntilChanged())
      .subscribe(dashboardGroup => {
        if (dashboardGroup) {
          this.refreshData();
        }
      });

      this.storeSubscription = this.store.changes
      .pipe(map(data => data.language), distinctUntilChanged())
      .subscribe(language => {
        if (language) {
          this.refreshData();
        }
      });
      this.storeSubscription = this.store.changes
      .pipe(map(data => data.category), distinctUntilChanged())
      .subscribe(category => {
        if (category) {
          this.refreshData();
        }
      });
  }

  createBarChart(inbound: number, outbound: number) {
    this.inOutCallsChartData = [
      {
        label: "Inbound",
        value: inbound,
        barColor: "bar-green"
      },
      {
        label: "Outbound",
        value: outbound,
        barColor: "bar-blue"
      }
    ];
  }

  refreshData() {
    let filterTags : ISkillTags = {
      language : this.store.getState().language === "All" ? null : this.store.getState().language,
      category : this.store.getState().category === "All" ? null : this.store.getState().category,
    };
    
    if(!filterTags.category && !filterTags.language)
    {
      filterTags = null;
    }
    
    this.dashboardService.fetchCallsCurrentStatus(filterTags)
    .then((response) => {
      this.callsStatus = response.data;
      this.lastUpdate = response.lastUpdate;
      this.updateRealtimePercentages();
    })
    .catch((error) => {
      console.log("Failed to fetchCallsCurrentStatus: ", error);
    });

    this.dashboardService.fetchAgentsCurrentStatus(filterTags)
    .then((response) => {
      this.agentsStatus = response.data.agentsStatus;
      this.createBarChartItems(this.agentsStatus);
    })
    .catch((error) => {
      console.log("Failed to fetchAgentsCurrentStatus: ", error);
    });

    this.dashboardService.fetchCallsDailyStatus(filterTags)
    .then((response) => {
      this.dailyTotal = response.data;
      this.updateDailyPercentages();
      this.createBarChart(this.dailyTotal.daily.inbound, this.dailyTotal.daily.outbound);
    })
    .catch((error) => {
      console.log("Failed to fetchCallsDailyStatus: ", error);
    });
  }

  updateRealtimePercentages() {
    const inQueue = this.callsStatus.previousHourStatistics.inQueue;
    const inProgress = this.callsStatus.previousHourStatistics.inProgress;
    const onHold = this.callsStatus.previousHourStatistics.onHold;
    const label = "than last hour";

    this.percentages.inQueue = this.calculateTilePercentage(inQueue, label, true);
    this.percentages.inProgress = this.calculateTilePercentage(inProgress, label);
    this.percentages.onHold = this.calculateTilePercentage(onHold, label, true);
  }

  updateDailyPercentages() {
    const yesterdayWait =   this.calculatePercentage(this.dailyTotal.daily.waitTime.avg, this.dailyTotal.yesterday.waitTime.avg);
    const previousWeekWait = this.calculatePercentage(this.dailyTotal.daily.waitTime.avg, this.dailyTotal.previousWeek.waitTime.avg);
    const yesterdayTalk = this.calculatePercentage(this.dailyTotal.daily.talkTime.avg, this.dailyTotal.yesterday.talkTime.avg);
    const previousWeekTalk = this.calculatePercentage(this.dailyTotal.daily.talkTime.avg, this.dailyTotal.previousWeek.talkTime.avg);

    const previousWeekLabel = "than same day previous week";
    const yesterdayLabel = "than yesterday";
    this.percentages.yesterdayWaitTimePercentage = this.calculateTilePercentage(yesterdayWait, yesterdayLabel, true);
    this.percentages.previousWeekWaitTimePercentage = this.calculateTilePercentage(previousWeekWait, previousWeekLabel, true);
    this.percentages.yesterdayTalkTimePercentage = this.calculateTilePercentage(yesterdayTalk, yesterdayLabel);
    this.percentages.previousWeekTalkTimePercentage = this.calculateTilePercentage(previousWeekTalk, previousWeekLabel);
  }

  calculatePercentage(value1: number, value2: number) {
    if (value2 != null && value2 > 0) {
      return value1 / value2 - 1;
    }

    return null;
  }

  calculateTilePercentage(value: number, label: string, downIsGood?: boolean) {
    const obj = {
      value: value,
      color: "text-black2",
      arrow: "none",
      label: label
    };

    if (value != null) {
      if (value > 0) {
        obj.arrow = "up";
        if (downIsGood != null) {
          obj.color = downIsGood ? "text-red" : "text-green";
        }
      } else if (value < 0) {
        obj.arrow = "down";
        if (downIsGood != null) {
          obj.color = downIsGood ? "text-green" : "text-red";
        }
      }
    }
    return obj;
  }

  createBarChartItems(value: IAgentsStatus) {
    this.barChartItems = [
      {
        label: "Conferencing",
        value: value.totalConferencing,
        barColor: "bar-green"
      },
      {
        label: "Available",
        value: value.totalAvailable,
        barColor: "bar-blue"
      },
      {
        label: "Unavailable",
        value: value.totalUnavailable,
        barColor: "bar-yellow"
      },
      {
        label: "Offline",
        value: value.totalOffline,
        barColor: "bar-black"
      },
    ];
  }
}
