<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<top-bar
    [isConfig]="false"
    (languageChanged)="languageChanged($event)"
    (themeFilterChanged)="themeFilterChanged($event)"
    [isLoggedin]="userService.currentUser.isAuthenticated"
    [accessibleThemes]="callCenterService.accessibleThemes"
    [presenceStatus]="endpointService.myEndpoint.status"
    [themeFilter]="style"
    (themeChanged)="themeChanged()"
    (login)="login($event)"
  >
</top-bar>
<nav-bar 
  (leaveRoom)="logout()"
  >
</nav-bar>
<main-container *ngIf="userService.currentUser.isAuthenticated">
   <div class="container vcc">

      <!-- Filter Selections -->
      <div 
         class="row col-md-8" 
         style="margin-bottom:10px" 
      >
         <!-- Filter by Skill Category selection -->
         <div 
            class="input-group col-4 text-left"
         >
            <a *ngIf="availableCategories">
               <select class="form-control"
                  [(ngModel)]="currentCategory"
                  [hidden]="!availableCategories"
                  (change)="updateSkillSetFilter()">
                  <option
                  *ngFor="let category of availableCategories"
                  [ngValue]="category">{{getLocalizedCategoryText(category)}}</option>
               </select>
            </a>
         </div>
         <!-- Filter by Skill Language Selection -->
         <div
            class="input-group col-4 text-center"
         >
            <a *ngIf="availableLanguages">
               <select class="form-control"
                  [(ngModel)]="currentLanguage"
                  [hidden]="!availableLanguages"
                  (change)="updateSkillSetFilter()">
                  <option
                     *ngFor="let language of availableLanguages"
                     [ngValue]="language">{{getLocalizedLanguageText(language)}}
                  </option>
               </select>
            </a>
         </div>
         <!-- Filter by Name 'starts with'...-->
         <div class="input-group col-4 text-right align-items-center">
            <label
               for="searchString"
               class="col-form-label"
            >search: 
            </label>
            <input
               type="tel"
               class="form-control"
               id="searchString"
               name="searchString"
               [(ngModel)]="searchString"
               (ngModelChange)="searchStringUpdated()"
            >
         </div>
      </div>

      <!-- Template for Items Display -->
      <ng-template
         #listItemTemplate
         let-call="item"
      >
         <!-- Display Name, Theme, Categories, Languages, Presense Status ... -->
         <div class="d-inline-block col-8 text-left p-0 text-dark" *ngIf="call">
            <i
               class="fas fa-headset mr-1"
               aria-hidden="true"
            >
            </i>
            {{getCallTagLine(call)}}
         </div>
         <!-- Info Dialog Button ... -->
         <div class="d-inline-block col-2 text-center pl-0">
            <a
               tabindex
               class="btn btn-info btn-sm"
               (click)="openInfoModal(call)"
            >
               <i class="fas fa-info-circle"></i>{{getInfoButtonLabel(call)}}
            </a>
         </div>
         <!-- Reload Client Button ... -->
         <div class="d-inline-block col-2 text-right pl-0" *ngIf="hasPermissionToReloadClient">
            <a
               tabindex
               class="btn btn-danger btn-sm"
               (click)="reload(call)"
               *ngIf="isReloadButtonAvailable(call)"
            >
               <i class="fas fa-redo"></i> Reload Web Client
            </a>
            <a
               tabindex
               *ngIf="!isReloadButtonAvailable(call)"
            >
            </a>
         </div>
      </ng-template>

      <!-- In Rooms Calls List -->
      <div class="row">
         <list-container
            class="list-group list-group-flush col-md-8"
            [title]="'In Room Calls'"
            [items]="sortedInRoomCalls"
            [listItemTemplate]="listItemTemplate"
            [isEditable]="false"
            [addEnabled]="false"
            [searchEnabled]="false"
         >
         </list-container>
      </div>

      <!-- Hanging Calls List -->
      <div class="row">
         <list-container
            class="list-group list-group-flush col-md-8"
            [title]="'Hanging Calls'"
            [items]="sortedHungCalls"
            [listItemTemplate]="listItemTemplate"
            [isEditable]="false"
            [addEnabled]="false"
            [searchEnabled]="false"
         >
         </list-container>
      </div>

      <!-- Guest Info Dialog Modal -->
      <dialog-container-modal
         [id]="'guestInfoModal'"
         [title]="getSafeText(infoDialogEp?.name) + ' Info'"
         [dragDisabled]="true"
         [dialogWidth]="'400px'"
      >
         <guest-info
            [endpoint]="infoDialogEp"
            [canSendLogs]="isInfoDialogSendsLogsButtonAvailable()"
            (requestToSendLogs)="requestToSendLogs($event)"
         ></guest-info>
      </dialog-container-modal>

      <!-- Op Info Dialog Modal -->
      <dialog-container-modal
         [id]="'operatorInfoModal'"
         [title]="getSafeText(infoDialogEp?.name) + ' Info'"
         [dragDisabled]="true"
         [dialogWidth]="'400px'"
      >
         <operator-info
            [endpoint]="infoDialogEp"
            [canSendLogs]="isInfoDialogSendsLogsButtonAvailable()"
            (requestToSendLogs)="requestToSendLogs($event)"
         ></operator-info>
      </dialog-container-modal>

   </div>
</main-container>
<div
      class="join-conference-container container pull-center"
      [ngClass]="{'fullScreenMode': fullScreenMode, 'mobile': isMobileApp}"
      *ngIf="!userService.currentUser.isAuthenticated"
    >
      <login-screen
        (login)="login($event)"
        [isMobileApp]="isMobileApp"
      >
      </login-screen>
    </div>
<footer></footer>
