/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author: kbender
 */

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RemoteDesktopManager } from './remote-desktop-manager.service';
import { WebSocketTunnel } from '@illgrenoble/guacamole-common-js';
import { RestService } from '../shared/services/rest.service';
import { IEndpoint } from 'companion';
import { EndpointService } from 'companion/endpoint/endpoint.service';
import { LocalizationService } from '../localization/localization.service';

@Component({
  selector: 'remote-desktop',
  templateUrl: './remote-desktop.component.html',
  styleUrls: ['./remote-desktop.component.css']
})
export class RemoteDesktopComponent implements OnInit {

/**
 * Client that manages the connection to the remote desktop
 */
  public manager: RemoteDesktopManager;

  /**
   * Guacamole has more states than the list below however for the component we are only interested
   * in managing four states.
   */
  public states = {
    CONNECTING: 'CONNECTING',
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
    ERROR: 'ERROR'
  };
  
  /**
   * Manage the component state
   */
  public state: BehaviorSubject<string> = new BehaviorSubject<string>(this.states.CONNECTING);

  constructor(private restService: RestService, private localizationService : LocalizationService) { }

  /**
   * Subscribe to the connection state  and full screen state when the component is initialised
   */
  ngOnInit(): void {
  }

  /** 
   * Remove all subscriptions when the component is destroyed
   */
  ngOnDestroy(): void {
      this.unbindSubscriptions();
      if(this.manager?.isConnected)
      {
        this.manager.disconnect();
      }
  }

  /**
   * Subscriptions
   */
  private subscriptions: Subscription[] = [];

  /**
   * Bind the subscriptions
   */
  private bindSubscriptions(): void {
    this.subscriptions.push(this.manager?.onStateChange.subscribe(this.handleState.bind(this)));
    //this.subscriptions.push(this.manager.onFullScreen.subscribe(this.handleFullScreen.bind(this)));
  }

  /**
   * Unbind the subscriptions
   */
  private unbindSubscriptions(): void {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  
  /**
   * Handle the display mouse movement
   * @param event Mouse event
   */
  public handleDisplayMouseMove($event: any): void {
    /**if (!this.manager.isFullScreen()) {
        return;
    }
    const toolbarWidth = this.toolbar.nativeElement.clientWidth;
    if ($event.x >= toolbarWidth) {
        this.toolbarVisible = false;
    } */ 
    // nothing to do here yet.
  }

  /**
 * Receive the state from the desktop client and update this components state
 * @param newState - state received from the guacamole client
 */
  private handleState(newState: string) {
    switch (newState) {
        case RemoteDesktopManager.STATE.CONNECTED:
            this.setState(this.states.CONNECTED);
            break;
        case RemoteDesktopManager.STATE.DISCONNECTED:
            // this.exitFullScreen();
            this.setState(this.states.DISCONNECTED);
            break;
        case RemoteDesktopManager.STATE.CONNECTING:
        case RemoteDesktopManager.STATE.WAITING:
            this.setState(this.states.CONNECTING);
            break;
        case RemoteDesktopManager.STATE.CLIENT_ERROR:
        case RemoteDesktopManager.STATE.TUNNEL_ERROR:
            // this.exitFullScreen();
            this.setState(this.states.ERROR);
            break;
    }
  }
        
  /**
   * Set the component state to the new guacamole state
   * @param newState
   */
  private setState(newState: string): void {
    this.state.next(newState);
  }


  startConnect(endpoint : IEndpoint)
  {
    // FETCH the connect credentials
    this.fetchConnectionCredentials(endpoint).then((data : any) => {
      // Setup tunnel. The tunnel can be either: WebsocketTunnel, HTTPTunnel or ChainedTunnel
      // wss://' + window.location.host + ':4242/guac
      const tunnel = new WebSocketTunnel(data.connectionString);
      //const tunnel = new HTTPTunnel("/guac/", false, null);

      /**
       *  Create an instance of the remote desktop manager by 
       *  passing in the tunnel
       */
      this.manager = new RemoteDesktopManager(tunnel);
      this.bindSubscriptions();

      const encryptParams = 
      {
        token : data.token
      }

      this.manager.connect(encryptParams);
    }).catch((error: any) => {
      console.log("Problem getting remote details.");
    });
  }

  closeConnection()
  {
    this.manager.disconnect();
  }

  /**
   * Fetch the connection credentials from the remote Id of the endpoint
   */
  fetchConnectionCredentials(endpoint : IEndpoint) : Promise<string>
  {
    return new Promise((resolve: (response: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getRemoteConnectionData", { remoteId: endpoint.remoteIdentity, userId : EndpointService.getSharedInstance().myEndpoint.userId, 
        theme: this.localizationService.myLocalizationData.style})
      .subscribe({next : (data: any) => { resolve(data);}, error : (error: any) => { console.error(error); reject(error);}}
      );
    });
  }
}
