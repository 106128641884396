/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */

import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges} from "@angular/core";
import {LocalizationService} from "client/scripts/localization/localization.service";
import {Loader} from "@googlemaps/js-api-loader";
import {CustomerStatus, ICustomer} from "companion";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// Customer's Experience google map
@Component({
  selector: "map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnChanges {

  @Input() customers: ICustomer[] = [];
  @Input() selectedCustomerId: string;
  @Input() googleMapsAPIKey: string = "";

  defaultLatitude: number = 37.8507939;
  defaultLongitude: number = -102.4671333;
  defaultZoom: number = 5;
  map: google.maps.Map;
  markers: google.maps.Marker[];
  infoWindow: google.maps.InfoWindow;

  constructor(public localizationService: LocalizationService) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentCustomers: SimpleChange = changes.customers;
    const currentSelected: SimpleChange = changes.selectedCustomerId;
    if ((currentCustomers && currentCustomers.currentValue &&
      JSON.stringify(currentCustomers.currentValue) !== JSON.stringify(currentCustomers.previousValue)) ||
      (currentSelected && currentSelected.currentValue)
    ) {
      this.placeMarkers();
    }
  }

  ngOnInit() {
    const mapPanel = this.localizationService.myLocalizationData.map_panel;
    this.defaultLatitude = mapPanel ? mapPanel.defaultLatitude : this.defaultLatitude;
    this.defaultLongitude = mapPanel ? mapPanel.defaultLongitude : this.defaultLongitude;
    this.defaultZoom = mapPanel ? mapPanel.defaultZoom : this.defaultZoom;
    this.markers = [];
    //console.log("dashboard customer map component ngOnInit googleMapsAPIKey:", this.googleMapsAPIKey);
    const loader = new Loader({
      apiKey: this.googleMapsAPIKey,
      version: "weekly"
    });

    loader.load().then(() => {
      const map = document.getElementById("map") as HTMLElement;
      if (map) {
        this.map = new google.maps.Map(map, {
          center: { lat: this.defaultLatitude, lng: this.defaultLongitude },
          zoom: this.defaultZoom,
          maxZoom: this.defaultZoom + 11,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          controlSize: 20,
          styles: [
            {
              featureType: "poi.business",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            }
          ]
        });

        this.infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        });

        this.placeMarkers();
      }
    });
  }

  /**
   * Place markers and center the map
   */
  placeMarkers() {
    if (!this.map) {
      return;
    }
    // clear the markers.
    this.markers.forEach(marker => marker.setMap(null));
    this.markers = [];

    let customersWithLocation;

    if (this.selectedCustomerId === "all-customers") {
      customersWithLocation = this.customers
        .filter(customer => customer.latitude !== null && customer.longitude !== null);
    } else {
      customersWithLocation = this.customers
        .filter(customer => customer.latitude !== null && customer.longitude !== null && customer.id === this.selectedCustomerId);
    }

    customersWithLocation.forEach(customer => {
      let fillColor = "#000";
      switch (customer.status) {
        case CustomerStatus.Conferencing:
          fillColor = "#21D59B";
          break;
        case CustomerStatus.Available:
          fillColor = "#0058FF";
          break;
        case CustomerStatus.InQueue:
          fillColor = "#FFC700";
          break;
        case CustomerStatus.OnHold:
          fillColor = "#FF9900";
          break;
      }

      const icon = {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: fillColor,
        fillOpacity: 1.0,
        strokeWeight: 1,
        strokeOpacity: 0.8,
        strokeColor: "#999",
        rotation: 0,
        scale: 8
      };

      const marker = new google.maps.Marker({
        title: customer.name,
        position: {lat: customer.latitude, lng: customer.longitude},
        icon: icon,
        map: this.map
      });
      marker.addListener("click", () => {
        this.infoWindow.setContent(
          "<div style='color: #1a1a1a'>" + customer.name + "</div>" +
          "<div style='font-size: 1rem;'>" + customer.theme + "</div>"
        );
        this.infoWindow.open(this.map, marker);
      });


      this.markers.push(marker);
    });

    // Create markers cluster
    const markerCluster = new MarkerClusterer({map: this.map, markers: this.markers});

    // Center map according to markers
    if (customersWithLocation.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      customersWithLocation.forEach((customer) => {
        bounds.extend(new google.maps.LatLng(customer.latitude, customer.longitude));
      });
      this.map.fitBounds(bounds);
    } else {
      this.map.setCenter({ lat: this.defaultLatitude, lng: this.defaultLongitude });
      this.map.setZoom(this.defaultZoom);
    }
  }
}
