/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, OnInit } from "@angular/core";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { ActivatedRoute } from "@angular/router";
import { Companion, IPasswordPolicy } from "companion";
import { RestService } from "../shared/services/rest.service";
import { LocalizationService } from "../localization/localization.service";

@Component({
  selector: "password-reset",
  templateUrl: "./password-reset.component.html"
})
/**
 * password reset component
 */
export class PasswordResetComponent implements OnInit {
  /**
   * The reset token, if one is available
   */
  resetToken: string;

  /**
   * The policy the password must adhere to
   */
  passwordPolicy: IPasswordPolicy;

  /**
   * Handle to the rest service
   */

  constructor(private route: ActivatedRoute, private restService: RestService, public localizationService: LocalizationService) {
    this.resetToken = this.route.snapshot.params["resetToken"];
  }

  ngOnInit() {
    if (!Companion.getUserService().currentUser.isAuthenticated) {
      Dispatcher.dispatch(ActionType.LoadGuestNavBar);
    } else {
      Dispatcher.dispatch(ActionType.LoadHostUserNavBar);
      Dispatcher.dispatch(ActionType.LoadTopBarMenu);
    }
    // ask the server for the policy based on our reset token
    this.restService
      .post("/getPolicyForResetToken", {
        resetToken: this.resetToken
      })
      .subscribe(
        (data: any) => {
          if (data.policy) {
            this.passwordPolicy = data.policy;
          } else {
            console.error("Problem getting password policy");
          }
        },
        (error: any) => {
          console.error("Problem getting password policy");
        }
      );
  }
  resetOccured(event: boolean) {
    if (event) {
      Dispatcher.dispatch(ActionType.LogOut);
      Dispatcher.dispatch(ActionType.OpenLoginModal, { callback: ActionType.OpenMyAccount });
    }
  }
}
