<!--
    Copyright Compunetix Incorporated 2016
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<div class="row chatroom-listitem">
  <div
    *ngIf="inEditState"
    class="col-2 left-padding-only edit-action"
  >
    <a
      tabindex
      class="btn btn-danger btn-sm"
      (click)="delete()"
    >
      <i
        class="fas fa-trash"
        aria-hidden="true"
      ></i>
    </a>
  </div>
  <div class="col-2 thumbnail left-padding-only">
    <label
      class="badge badge-pill badge-danger room-unread-count-label"
      [hidden]="room.unreadMessageCount==0"
    >
      {{room.unreadMessageCount}}
    </label>
    <i
      class="xx-large fas fa-comments"
      aria-hidden="true"
    ></i>
  </div>
  <div
    [ngClass]="{'col-8': inEditState, 'col-10': !inEditState}"
    class="brief"
  >
    <div class="row chatroom-listitem-header">

      <div class="col-9 left-padding-only user-list">
        {{filteredRoomTargets | concatEndpointNames | truncate : 28 : '...'}}
      </div>
      <div
        *ngIf="room.messages.length>0"
        class="col-3 left-padding-only small date-stamp"
      >
        {{(room.messages | sortBy:['sendDate']:['desc'])[0].sendDate | date:'HH:mm:ss'}}
      </div>
    </div>
    <div class="row chatroom-listitem-body">
      <div
        *ngIf="room.messages.length>0"
        class="col-12 left-padding-only small message-content"
      >
        {{(room.messages | sortBy:['sendDate']:['desc'])[0].content | truncate : 95 : '...'}}
      </div>
    </div>
  </div>
</div>