/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";
import { IChatRoom, IEndpoint, IEndpointRef } from "companion";

/**
* search in a list of chat rooms for the given text.
* @param	collection to search in
* @param	string to be search for.
* @example Usage:
* ```html
* <li #ngFor in roomlist | searchRoom: 'value' }}
</p>
* ```
*/
@Pipe({ name: "searchRoom" })
export class SearchRoomPipe {
  transform(list: IChatRoom[], value: string[]): any {
    if (list && value[0]) {
      var names: string[] = _.words(value[0]);
      return _.filter(list, (room: IChatRoom) => {
        return (
          _.filter(room.targets, (endpoint: IEndpointRef) => {
            return (
              _.filter(names, (name: string) => {
                return endpoint.name.toLowerCase().indexOf(name) > -1;
              }).length > 0
            );
          }).length > 0
        );
      });
    } else {
      return list;
    }
  }
}
