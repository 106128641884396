/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";

@Component({
  selector: "voice-view",
  templateUrl: "./voice-view.template.html"
})
export class VoiceViewComponent {
  /**
   * conponent constructor
   */
  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }
}
