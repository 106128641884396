/**
 * Copyright Compunetix Incorporated 2016-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { UserService } from "../user/user.service";
import { IUserService } from "../user/user.service.interface";
import { VideoResolution } from "../settings/video-resolution";
import { GlobalService } from "./global.service";
import { SDPUtil } from "../util/sdp";
import { IRTCServiceConnectionEventHandler } from "./rtc.service.connection-event-handler.interface";
import { IRTCServiceFileEventHandler } from "./rtc.service.file-event-handler.interface";
import { IRTCServiceServerMessageHandler } from "./rtc.service.server-message-handler.interface";
import { IRTCServiceStreamEventHandler } from "./rtc.service.stream-event-handler.interface";
import { IRTCClient, VideoMediaConnectionMode } from "./rtc.client.interface";
import { IICEConfig, IRTCConfig } from "../settings/config.interface";
import { MediaUtil } from "../util/media";
import { DeviceService } from "./device.service";
import { AlertCode, AlertLevel } from "../alert/alert.interface";
import { StatsUtil } from "../util/stats";
import { EndpointService } from "../endpoint/endpoint.service";
import { IEndpoint, ICoordinates } from "../endpoint/endpoint.interface";
import { IPosition } from "../video/position.interface";
import { Browser } from "../util/browser";
import { CryptoUtil } from "../util/crypto";
import { Companion, ConferenceService } from "..";
import { LogUtil } from "../util/log";
import { ISkillSet, ISkillTags } from "../skills/skill.interface";
import { RTCClient } from "./rtc.client";
import { IRTCService } from "./rtc.service.interface";

const APP_NAME: string = "Companion";
const EASY_SOCKET_PATH = "/easy";
/**
 * WebRTC methods delegate
 */
export class EasyRTCService implements IRTCService {
  private static sharedInstanceEasy: EasyRTCService;
  /**
   * rtc client
   */
  private _rtcClient: IRTCClient;

  /**
   * web rtc client with easyrtc library
   */
  private _easyrtcClient: any = easyrtc;

  /**
   * file client
   */
  private _easyFileClient: any = easyrtc_ft;

  /**
   * This array holds the actively managed pending state changes in FIFO order.
   * As a change comes in, it is added to the queue and only removed from the front of the line
   * when successfully acknowledged.
   */
  private _pendingStateChangeQueue = [];

  /**
   * This holds the current timer object used to determine if there is a pending state change request.
   * If it expires, the retry sends the head of pendingStateChangeQueue again.
   * When a response comes in to a state change, this object is cleared.
   * When a new request is sent, this object is configured.
   */
  private _stateUpdatePendingTimer = null;

  private broadcastStream = null;

  clearBroadcastStream(): void {
    this.broadcastStream = null;
  }

  get rtcClient() : IRTCClient {
    return this._rtcClient;
  }

  constructor(private userService: IUserService = UserService.getSharedInstance()) {
    if (!this._rtcClient) {
      this._rtcClient = new RTCClient();
    }
  }

  /**
   * get shared singleton object
   */
  static getSharedInstance(): EasyRTCService {
    if (!EasyRTCService.sharedInstanceEasy) {
      EasyRTCService.sharedInstanceEasy = new EasyRTCService();
    }
    return EasyRTCService.sharedInstanceEasy;
  }

  /**
   * init the webrtc init connection
   * @param rtcId: Client rtcId
   * @param connectionEventHandler?: IRTCServiceConnectionEventHandler - event handler
   * @param fileEventHandler?: IRTCServiceFileEventHandler - event handler
   * @param serverMessageHandler?: IRTCServiceServerMessageHandler - event handler
   * @param streamEventHandler?: IRTCServiceStreamEventHandler - event handler
   * @param alertHandler?: (alertCode: string, alertText?: string) => void - alert handler
   * @param audioCodecList?: string[] - customized audio codec list
   * @param videoCodecList?: string[] - customized video codec list
   * @param secondaryVideoCodecList?: string[] - customized video codec list for secondary video
   */
  init(
    rtcId: string,
    connectionEventHandler?: IRTCServiceConnectionEventHandler,
    fileEventHandler?: IRTCServiceFileEventHandler,
    serverMessageHandler?: IRTCServiceServerMessageHandler,
    streamEventHandler?: IRTCServiceStreamEventHandler,
    alertHandler?: (alertCode: string, alertText?: string) => void,
    audioCodecList?: string[],
    videoCodecList?: string[],
    secondaryVideoCodecList?: string[]
  ) {
    this.rtcClient.rtcId = rtcId;
    
    const socketHostPath: string = EASY_SOCKET_PATH;
    // parse input host address to link structure
    const parser = document.createElement("a");
    parser.href = "/";
    // build socketIO server address
    let socketHostAddress = parser.protocol + "//" + parser.hostname + (parser.port ? ":" + parser.port : "");
    // set socketIO url
    this._easyrtcClient.setSocketUrl(socketHostAddress, {
      path: socketHostPath,
      timeout: this.rtcClient.SERVER_DISCONNECT_TIMEOUT,
      maxHttpBufferSize: 5000000, // 5M max
      transportOptions: {
        polling: {
          extraHeaders: {
            client_rtcid: rtcId,
          },
        },
      },
    });

    //this._easyrtcClient.enableDebug(true);

    this.rtcClient.connectionEventHandler = connectionEventHandler;
    this.rtcClient.fileEventHandler = fileEventHandler;
    this.rtcClient.serverMessageHandler = serverMessageHandler;
    this.rtcClient.streamEventHandler = streamEventHandler;
    if (alertHandler) {
      this.rtcClient.alertHandler = alertHandler;
    }
    this._easyrtcClient.setAutoInitUserMedia(false);
    this._easyrtcClient.setOnError((error: any) => {
      if (error.errorCode === "MSG_REJECT_TARGET_EASYRTCID") {
        if (rtcId !== error.targetRtcId) {
          connectionEventHandler.callDisconnectedListener(error.targetRtcId);
        }
      }
      this.rtcClient.alertHandler(error.errorCode, error.errorText);
    });
    this.rtcClient.audioCodecBlackList = audioCodecList;
    this.rtcClient.videoCodecBlackList = videoCodecList;
    this.rtcClient.secondaryVideoCodecBlackList = secondaryVideoCodecList;
    this._easyrtcClient.setSdpFilters(this.localSdpFilter.bind(this), this.remoteSdpFilter.bind(this));
    if (streamEventHandler) {
      this._easyrtcClient.setStreamAcceptor(streamEventHandler.streamAcceptListener.bind(streamEventHandler));
      this._easyrtcClient.setOnStreamClosed(streamEventHandler.streamClosedListener.bind(streamEventHandler));
    }
    this._easyrtcClient.setAcceptChecker(this.inboundCallAcceptChecker.bind(this));
    this._easyrtcClient.setCallAnswerListener((peerRtcId: string, pc: any) => {
      this.rtcClient.connectionEventHandler.connectionCreated(peerRtcId, pc);
      this.registerPCEventHandlers(peerRtcId, pc);
    });
    if (connectionEventHandler) {
      this._easyrtcClient.setDisconnectListener(
        connectionEventHandler.serverDisconnectListener.bind(connectionEventHandler)
      );
      this._easyrtcClient.setPeerClosedListener((peerRtcId: string) => {
        this.rtcClient.alertHandler("PEER_CLOSED: " + peerRtcId);
        connectionEventHandler.callDisconnectedListener(peerRtcId);
      });
      this._easyrtcClient.setIceConnectionStateChangeListener(
        connectionEventHandler.iceStateChangeListener.bind(connectionEventHandler)
      );
      this._easyrtcClient.enableDataChannels(true);
    }
    if (serverMessageHandler) {
      this._easyrtcClient.setServerListener(serverMessageHandler.serverMessageListener.bind(serverMessageHandler));
    }
    if (fileEventHandler) {
      this._easyFileClient.buildFileReceiver(
        fileEventHandler.fileAcceptChecker.bind(fileEventHandler),
        fileEventHandler.fileReceivedHandler.bind(fileEventHandler),
        fileEventHandler.fileReceiveStatusHandler.bind(fileEventHandler)
      );
    }
    this.setTrickleIceCandidateFilter(); // process candidates from easyrtc.
  }

  enableDebug(enable: boolean) {
    this._easyrtcClient.enableDebug(enable);
  }

  /**
   * set to default bandwidth
   */
  setToDefaultBandwidth() {
    this.setVideoBandwidth(
      _.find(this.rtcClient.bandwidthOptions, (bandwidth: number) => {
        return bandwidth === this.userService.currentUser.preferedBandwidth;
      })
    );
  }

  /**
   * set to default resolution
   */
  setToDefaultResolution(isForPrimary: boolean) {
    let matchedResolution = _.find(this.rtcClient.resolutionOptions, (resolution: VideoResolution) => {
      if (isForPrimary) {
        return (
          this.userService.currentUser.preferedPrimaryResolution &&
          resolution.width === this.userService.currentUser.preferedPrimaryResolution.width &&
          resolution.height === this.userService.currentUser.preferedPrimaryResolution.height
        );
      } else {
        return (
          this.userService.currentUser.preferedSecondaryResolution &&
          resolution.width === this.userService.currentUser.preferedSecondaryResolution.width &&
          resolution.height === this.userService.currentUser.preferedSecondaryResolution.height
        );
      }
    });
    this.setVideoResolution(isForPrimary, matchedResolution);
  }

  /**
   * check if the peer is currently connected
   * @param rtcId: string - rtcId of the peer
   */
  isPeerConnected(rtcId: string): boolean {
    return this._easyrtcClient.getConnectStatus(rtcId) === this._easyrtcClient.IS_CONNECTED;
  }

  /**
   * hangup with peer
   * @param rtcId: string - rtcId of the peer
   */
  hangupPeer(rtcId: string): void {
    this._easyrtcClient.hangup(rtcId);
  }

  /**
   * hangup with all peers
   */
  hangupAll(): void {
    this._easyrtcClient.hangupAll();
  }

  /**
   * start connection to peer
   * @param rtcId: string - rtcId of the peer
   * @param allowReceive: boolean - flag if allow endpoint receive stream from far end
   * @param customStream: MediaStream - media stream to start call with
   */
  establishConnection(
    rtcId: string,
    allowReceive: boolean = true,
    customStream?: MediaStream
  ): Promise<any> {
    // start the timeout
    let disconnectTimer = setTimeout(() => {
      this.cleanupFailedCall(rtcId);
      Promise.reject(new Error("ESTABLISH_CONNECTION_TIMEOUT"));
    }, EndpointService.getSharedInstance().disconnectTimeOut * 2); // Double RT TTL for remote messages

    return new Promise((resolve: (pc: any) => void, reject: (error: Error) => void) => {
      this._easyrtcClient.enableAudioReceive(allowReceive); // enable endpoint receiving audio if not able to send audio
      this._easyrtcClient.enableVideoReceive(allowReceive); // enable endpoint receiving video if not able to send video
      return this.getTransmittingStreams(rtcId)
      .then((streams: MediaStream[]) => {
        const streamsToSend: MediaStream[] = customStream ? [customStream] : streams;
        console.log("EC: Sending streams to", rtcId, streamsToSend);
        this._easyrtcClient.call(
          rtcId,
          (id: string, type: string) => {
            if (type == "connection") { // connection even generated this (could also be audiovideo but we don't care here)
              clearTimeout(disconnectTimer);
              this.outboundCallSuccess(rtcId, type);
              this.registerFileSender(rtcId);
              resolve(this.getPeerConnectionById(rtcId));
            }
          },
          (errorCode: string, errorText: string) => {
            clearTimeout(disconnectTimer);
            console.log("Call Failed", errorText);
            this.cleanupFailedCall(rtcId);
            reject(new Error(errorCode));
          },
          this.outboundCallAcceptChecker.bind(this),
          _.compact(_.map(streamsToSend, (stream) => {
            return stream ? stream["streamName"] : null;
          }))
        );
      }).catch((reason : any) => {
        clearTimeout(disconnectTimer);
        console.warn("failed to get streams for call:", reason);
        this.cleanupFailedCall(rtcId);
        return Promise.reject(reason);
      });
    });
  }

  /**
   * Helper function to clean up a failed call, disconnects us from sub conference and calls
   * the call failure listener.
   */
  private cleanupFailedCall(rtcId : string)
  {
    console.warn("Cleanup failed call:" + rtcId);
    //this.hangupPeer(rtcId); // Make sure nothing is dangling.
  }

  /**
   * default outbound call success handler
   */
  private outboundCallSuccess(rtcId: string, type: string) {
    this.rtcClient.alertHandler("outbound call success: " + rtcId + "-" + type);
    this.rtcClient.connectionEventHandler.connectionCreated(rtcId, this.getPeerConnectionById(rtcId));
    let pc = this.getPeerConnectionById(rtcId);
    this.registerPCEventHandlers(rtcId, pc);
  }

  /**
   * default outbound call accept handler
   */
  private outboundCallAcceptChecker(accepted: boolean, rtcid: string) {
    if (!accepted) {
      this.rtcClient.alertHandler("Sorry, your call to " + this._easyrtcClient.idToName(rtcid) + " was rejected");
    }
  }

  /**
   * handler on inbound call accept check
   * @param rtcId: string - the rtcId of whom is calling
   * @param callback: (isAccepted: boolean, streamNames?: string[]) => void - accept callback
   */
  private inboundCallAcceptChecker(rtcId: string, callback: (isAccepted: boolean, streamNames?: string[]) => void) {
    this.registerFileSender(rtcId);
    let callerEp: IEndpoint = EndpointService.getSharedInstance().getEndpointById(rtcId);
    if (callerEp && callerEp.transmitMode === VideoMediaConnectionMode.None) {
      console.log("Accepting an observer monitoring call. Going audio only for my end.");
      this.getAudioStream()
      .then((stream: MediaStream) => {
        console.log("ICAC: Sending audio only stream to", rtcId, stream);
        console.log("Got audio stream:", stream);
        callback(true, stream ? stream["streamName"] : null);
      })
      .catch(() => {
        // getAudioStreams does not reject.
      });
    } else {
      this.getTransmittingStreams(rtcId)
      .then((streams: MediaStream[]) => {
        console.log("ICAC: Sending streams to", rtcId, streams);
        callback(true, _.compact(_.map(streams, (stream) => {
          return stream ? stream["streamName"] : null;
        })));
      })
      .catch(() => {
        // getTransmittingStreams does not reject.
      });
    }
  }

  /**
   * get current peer username
   */
  getCurrentUsername(): string {
    return this.getUsernameById(this._easyrtcClient.myEasyrtcid);
  }

  /**
   * set current peer username
   */
  setCurrentUsername(username: string): void {
    this._easyrtcClient.setUsername(username);
  }

  /**
   * get username of peer
   * @param rtcId: string - rtcId of the peer
   */
  getUsernameById(rtcid: string): string {
    return this._easyrtcClient.idToName(rtcid);
  }

  /**
   * get peerconnection by id
   * @param rtcId: string - rtcId of the peer
   */
  getPeerConnectionById(rtcId: string): any {
    return this._easyrtcClient.getPeerConnectionByUserId(rtcId);
  }

  /**
   * get peerconnection config by id
   * @param rtcId: string - rtcId of the peer
   */
  getPeerConnectionConfigById(rtcId: string): any {
    let connection = this.getPeerConnectionById(rtcId);
    return connection ? connection.getConfiguration() : null;
  }

  /**
   * set video source to dom element
   * @param element: HTMLVideoElement - video dom element
   * @param stream: any - video stream
   * @param muted?: boolean - mute audio by default
   */
  setVideoSrcByElement(element: HTMLVideoElement, stream: any, muted: boolean = false): Promise<void> {
    if (!element || !stream) {
      return Promise.reject("Invalid parameters.");
    }
    try {
      let currentStream = element.srcObject as MediaStream;
      if (currentStream && _.isEqual(_.map(currentStream?.getTracks(), "id").sort(), _.map(stream.getTracks(), "id").sort())) {
        return Promise.resolve();
      }
    } catch (e) {
      console.error("Failed to set video src.", e);
    }
    console.log("Setting video src ", element, stream.getTracks());
    element.srcObject = stream;
    if (muted) {
      element.volume = 0;
      element.muted = true;
    }
    return this.playVideo(element);
  }

  /**
   * play video
   * @param element: HTMLVideoElement - video dom element
   */
  playVideo(element: HTMLVideoElement): Promise<void> {
    return new Promise((resolve: () => void, reject: (error: Error) => void) => {
      if (element.readyState >= 2 && !element.paused) {
        element.pause();
      }
      if (element.readyState < 2) {
        element.addEventListener("loadeddata", (event) => {
          this.playVideo(element).then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
        });
        element.load();
      } else {
        return element
        .play()
        .then(() => {
          resolve();
        })
        .catch((e: Error) => {
          this.rtcClient.alertHandler(e.message);
          if (/interact/i.exec(e.message)) {
            bootbox.confirm({
              onEscape: false,
              backdrop: false,
              closeButton: false,
              message: 'This site would like to use your speaker to play sound. Please click "Allow" to confirm.',
              buttons: {
                confirm: {
                  label: "Allow",
                  className: "btn-success",
                },
                cancel: {
                  label: "Reject",
                  className: "btn-danger",
                },
              },
              callback: (result: boolean) => {
                if (result) {
                  this.playVideo(element).then(() => {
                    resolve();
                  })
                  .catch((error) => {
                    reject(error);
                  });
                  this.rtcClient.prepareAudioContext();
                } else {
                  reject(e);
                }
              },
            });
          } else {
            let playVideoTimer = setTimeout(() => {
              clearTimeout(playVideoTimer);
              this.playVideo(element).then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
            }, 1 * 1000);
          }
        });
      }
    });
  }

  /**
   * mute video of video dom element
   * @param elementId: string - element id of the video dom element
   * @param muted: boolean - flag if set to mute
   */
  muteVideoByElementId(elementId: string, muted: boolean) {
    this._easyrtcClient.muteVideoObject(elementId, muted);
  }

  /**
   * Update Audio Output of Element
   * @param element: any - the video element
   * @param speakerId: string - the speaker device id
   * @param save: boolean - flag if save the setting in cookie, default true
   */
  setAudioOutput(element: any, speakerId: string, save: boolean = true): Promise<void> {
    if (save) {
      this.userService.currentUser.preferedSpeakerDeviceId = speakerId;
      GlobalService.setSessionUser(this.userService.currentUser);
    }
    if (!element) {
      return Promise.resolve();
    }
    if (typeof element.sinkId !== "undefined") {
      return element.setSinkId(speakerId)
      .catch((error) => {
        var errorMessage = error;
        if (error.name === "SecurityError") {
          errorMessage = "You need to use HTTPS for selecting audio output " +
              "device: " + error;
        }
        
        return Promise.reject(error);
      });
    } else {
      
      return Promise.reject('Browser does not support selection.');
    }
  }

  /**
   * get current peer rtcId
   */
  getCurrentRTCId(): string {
    return this._easyrtcClient.myEasyrtcid;
  }

  /**
   * This operation builds a function used for processing ICE candidates as they are received (via easyrtc).
   * We can use this hook to modify or remove or passthrough any candidate before it gets either sent or processed.
   * Most useful presently is the ability to check the type of candidate and approve it based on the settings from the rtcClient.
   */
  setTrickleIceCandidateFilter(): void {
    /**
     * Build a callback function (using this.rtcClient parameters) to check if the candidate is allowed to be used.
     * Always allow remote candidates and check local ones based on the parameters.
     * @param record : {type: 'candidate', label: sdpMLineIndex, id: sdpMid, candidate: candidateString}
     * @param remote : boolean
     * @returns one of the following: the input candidate record, a modified candidate record, or null (indicating that the candidate
     * should be discarded).
     */
    let filter = (record: any, remote: boolean ) => {   
      // Regex found in sdp-transform
      let candiReg = /^candidate:(\S*) (\d*) (\S*) (\d*) (\S*) (\d*) typ (\S*)(?: raddr (\S*) rport (\d*))?(?: tcptype (\S*))?(?: generation (\d*))?(?: network-id (\d*))?(?: network-cost (\d*))?/;
      let matchNames = [
        "foundation",
        "component",
        "transport",
        "priority",
        "ip",
        "port",
        "type",
        "raddr",
        "rport",
        "tcptype",
        "generation",
        "network-id",
        "network-cost"
      ];
      let candidate = {};

      // Matching names to items in regex (also in sdp-transform)
      let matches = record.candidate.match(candiReg);
      for (var i = 0; i < matchNames.length; i += 1) {
        if (matches[i + 1] != null) {
          candidate[matchNames[i]] = matches[i + 1];
        }
      }
      if (remote) {
        return record;
      } else if (
        // only filter local candidates
        (!this.rtcClient.relayCandidateOnly || candidate['type'] === "relay")
      ) {
        return record;
      } else {
        return null;
      }      
    }
    // Register this bound function as the callback for ice candidate filtering
    this._easyrtcClient.setIceCandidateFilter(filter.bind(this));
  }

  /**
   * filter local sdp
   * @param sdp: string - the original sdp
   */
  localSdpFilter(sdp: string, type: string, rtcId: string): string {
    console.log("Original LOCAL SDP:\n", sdp);
    let newSdp = sdp;
    if (this.rtcClient.audioCodecBlackList) {
      newSdp = SDPUtil.codecFilter(newSdp, "audio", this.rtcClient.audioCodecBlackList);
    }
    if (this.rtcClient.videoCodecBlackList) {
      newSdp = SDPUtil.codecFilter(newSdp, "video", this.rtcClient.videoCodecBlackList);
    }

    if (this.rtcClient.supportDualChannel && this.rtcClient.secondaryVideoCodecBlackList) {
      newSdp = SDPUtil.codecFilter(newSdp, "video", this.rtcClient.secondaryVideoCodecBlackList, 1);
    }

    newSdp = SDPUtil.stripDanglingFmtpFromRtpmap(newSdp);

    newSdp = SDPUtil.videoBandwidthSdpFilterOnVideoSection(newSdp, this.rtcClient.sysPrimaryVideoBandwidth);

    let currentPeerconnection = this.getPeerConnectionById(rtcId);
    let currentPeerconnectionConfig = this.getPeerConnectionConfigById(rtcId);
    newSdp = SDPUtil.unifiedPlanSdpFilterForMidAndBundle(newSdp);
    console.log("MODIFIED LOCAL SDP:\n", newSdp);
    return newSdp;
  }

  /**
   * filter remote sdp
   * @param sdp: string - the original sdp
   */
  remoteSdpFilter(sdp: string, type: string, rtcId: string): string {
    let newSdp = sdp;
    console.log("RECEIVED REMOTE SDP:\n", newSdp);
    return newSdp;
  }

  /**
   * disconnect this peer with webrtc server
   */
  disconnect() {
    this._easyrtcClient.disconnect();
    if (this.getWebSocket()) {
      this.getWebSocket()?.close();
    }
  }

  /**
   * connect with webrtc server
   */
  connect() {
    // no need to connect if we are already connected.
    if (this.getWebSocket()) {
      this.rtcClient.connectionEventHandler.serverConnectSuccessListener.call(this.rtcClient.connectionEventHandler);
    } else {
      this._easyrtcClient.connect(
        APP_NAME,
        this.rtcClient.connectionEventHandler.serverConnectSuccessListener.bind(this.rtcClient.connectionEventHandler),
        this.rtcClient.connectionEventHandler.serverConnectFailureListener.bind(this.rtcClient.connectionEventHandler))
    }
  }

  /**
   * set listener on room occupent changes
   */
  setRoomOccupantListener(
    roomOccupantListener: (roomName: string, otherPeople: string[], isPrimary: boolean) => void
  ): void {
    this._easyrtcClient.setRoomOccupantListener(roomOccupantListener);
  }

  /**
   * get local camera stream
   * @param forceNew: boolean - flag if force to get new stream
   */
  getCameraStream(forceNew: boolean): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      this.rtcClient.cameraRequestQueue.push({ forceNew: forceNew, resolve: resolve, reject: reject });
      this.flushCameraRequests();
    });
  }

  /**
   * get secondary camera stream
   * @param forceNew: boolean - flag if force to get new stream
   */
  getSecondaryCameraStream(forceNew: boolean): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      this.rtcClient.secondaryCameraRequestQueue.push({ forceNew: forceNew, resolve: resolve, reject: reject });
      this.flushSecondaryCameraRequests();
    });
  }

  flushCameraRequests(): void {
    if (this.rtcClient.cameraRequestProcess) {
      console.log("Camera request pending");
      return;
    }
    let request = this.rtcClient.cameraRequestQueue.shift();
    if (!request) {
      console.log("Camera requests complete");
      return;
    }
    this.rtcClient.cameraRequestProcess = this.getCameraStreamPromise(request.forceNew, true)
    .then((stream: MediaStream) => {
      request.resolve(stream);
    })
    .catch((error: Error) => {
      request.reject(error);
    })
    .finally(() => {
      delete this.rtcClient.cameraRequestProcess;
      this.flushCameraRequests();
    });
  }

  flushSecondaryCameraRequests(): void {
    if (this.rtcClient.secondaryCameraRequestProcess) {
      return;
    }
    let request = this.rtcClient.secondaryCameraRequestQueue.shift();
    if (!request) {
      return;
    }
    this.rtcClient.secondaryCameraRequestProcess = this.getCameraStreamPromise(request.forceNew, false)
    .then((stream: MediaStream) => {
      request.resolve(stream);
    })
    .catch((error: Error) => {
      request.reject(error);
    })
    .finally(() => {
      delete this.rtcClient.secondaryCameraRequestProcess;
      this.flushSecondaryCameraRequests();
    });
  }

  getCameraStreamPromise(forceNew: boolean, isForPrimaryCamera: boolean = true): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      let cameraStream = isForPrimaryCamera ? this.rtcClient.cameraStream : this.rtcClient.secondaryCameraStream;
      if (!forceNew && cameraStream) {
        MediaUtil.applyConstraintsOnStream(cameraStream, this.rtcClient, false, isForPrimaryCamera, this.rtcClient.microphoneAccessible, this.rtcClient.cameraAccessible);
        resolve(cameraStream);
      } else {
        let previousAccessible = { cameraAccessible: this.rtcClient.cameraAccessible, microphoneAccessible: this.rtcClient.microphoneAccessible };
        this._easyrtcClient.setUserMediaConstraints(
          MediaUtil.getUserMediaConstraints(this.rtcClient, false, true, true, isForPrimaryCamera)
        );
        this.initMediaSourceTry(isForPrimaryCamera, isForPrimaryCamera ? "camera" : "secondary-camera")
        .then((stream: any) => {
          this.rtcClient.cameraAccessible = true;
          this.rtcClient.microphoneAccessible = true;
          return Promise.resolve(stream);
        })
        .catch((error: Error) => {
          // try audio only media source
          this.rtcClient.alertHandler(
            "GET_CAMERA_FAILED",
            "Fail to access camera and microphone. Fallback to audio only.",
            AlertLevel.warning
          );
          this.rtcClient.cameraAccessible = false;
          this.rtcClient.microphoneAccessible = true;
          return this.prepareCameraWithAvatarStream(isForPrimaryCamera, true);
        })
        .catch((error: Error) => {
          // try for avatar only media source
          this.rtcClient.alertHandler(
            "GET_AUDIO_ONLY_FAILED",
            "Fail to access microphone, Fallback to muted icon only.",
            AlertLevel.warning
          );
          this.rtcClient.cameraAccessible = false;
          this.rtcClient.microphoneAccessible = false;
          if (LogUtil.getLogInstance().logsSettings.deviceAcquisition) {
            const logsPromises: Promise<any>[] = [LogUtil.getLogInstance().sendLogs()];
            if (LogUtil.getLogInstance().logsSettings.webrtcStatistics) {
              logsPromises.push(LogUtil.getLogInstance().sendWebRTCStats());
            }
            Promise.all(logsPromises)
            .then((res) => this.rtcClient.alertHandler(
              "Automatic device acquisition fail logs sent.",
              null,
              AlertLevel.warning
            ))
            .catch(err => this.rtcClient.alertHandler(
              "Automatic device acquisition fail logs sending failed.",
              null,
              AlertLevel.warning
            ));
          }
          return this.prepareCameraWithAvatarStream(isForPrimaryCamera, false);
        })
        .catch((error: Error) => {
          this.rtcClient.alertHandler("GET_AVATAR_ONLY_FAILED", error.message, AlertLevel.warning);
          this.rtcClient.cameraAccessible = false;
          this.rtcClient.microphoneAccessible = false;
          delete this.rtcClient.cameraStream;
          return Promise.reject(error);
        })
        .then((stream: any) => {
          cameraStream = stream;
          if (this.rtcClient.microphoneAccessible) {
            MediaUtil.enableAudioTracks(cameraStream, !this.rtcClient.audioMuted);
          }
          if (this.rtcClient.cameraAccessible) {
            MediaUtil.enableVideoTracks(cameraStream, !this.rtcClient.videoMuted);
          }
          return Promise.resolve(cameraStream);
        })
        .then((stream: any) => {
          if (isForPrimaryCamera) {
            this.rtcClient.cameraStream = stream;
            this.rtcClient.prepareAudioContext();
            if (this.rtcClient.micAudioSourceNode) {
              this.rtcClient.micAudioSourceNode.disconnect();
            }
            if (this.rtcClient.audioContext && stream && stream.getAudioTracks()[0]) {
              this.rtcClient.micAudioSourceNode = this.rtcClient.audioContext.createMediaStreamSource(stream);
              this.rtcClient.micAudioSourceNode.connect(this.rtcClient.transmittingAudioMixDestinationNode);
            }
          } else {
            this.rtcClient.secondaryCameraStream = stream;
          }
          MediaUtil.applyConstraintsOnStream(cameraStream, this.rtcClient, false, isForPrimaryCamera, this.rtcClient.microphoneAccessible, this.rtcClient.cameraAccessible);
          resolve(cameraStream);
        })
        .catch((error: Error) => {
          reject(error);
        });
      }
    });
  }

  private prepareCameraWithAvatarStream(isForPrimaryCamera: boolean, withAudio: boolean): Promise<void|MediaStream> {
    if (isForPrimaryCamera) {
      this.rtcClient.userSelectedPrimaryResolution = null;
      delete this.rtcClient.AUTO_MODE_PRIMARY_RESOLUTION;
    } else {
      this.rtcClient.userSelectedSecondaryResolution = null;
      delete this.rtcClient.AUTO_MODE_SECONDARY_RESOLUTION;
    }
    if (withAudio && this.rtcClient.disableAudioOnly) {
      return Promise.reject(new Error("AUDIO_ONLY_DISABLED"));
    }
    return Promise.resolve()
    .then(() => {
      if (withAudio) {
        this._easyrtcClient.setUserMediaConstraints(
          MediaUtil.getUserMediaConstraints(this.rtcClient, false, withAudio, false, isForPrimaryCamera)
        );
        return this.initMediaSourceTry(isForPrimaryCamera, isForPrimaryCamera ? "camera" : "secondary-camera")
        .then((cameraStream: MediaStream) => {
          MediaUtil.enableAudioTracks(cameraStream, !this.rtcClient.audioMuted);
          return Promise.resolve(cameraStream);
        })
        .catch((error: Error) => {
          this.rtcClient.cameraAccessible = false;
          this.rtcClient.microphoneAccessible = false;
          return Promise.resolve(null);
        });
      } else {
        return Promise.resolve(null);
      }
    })
    .then((cameraStream: MediaStream) => {
      return this.getAvatarStream(false).then((avatarStream: MediaStream) => {
        if (!cameraStream) {
          cameraStream = avatarStream;
          this.rtcClient.prepareAudioContext();
          if (this.rtcClient.audioContext && this.rtcClient.audioContext.createMediaStreamDestination) {
            this.rtcClient.virtualMicAudioMixDestinationNode =
              this.rtcClient.audioContext.createMediaStreamDestination();
            let virtualMicStream = this.rtcClient.virtualMicAudioMixDestinationNode.stream;
            if (cameraStream.getAudioTracks().length === 0 && virtualMicStream.getAudioTracks().length > 0) {
              cameraStream.addTrack(virtualMicStream.getAudioTracks()[0]);
            }
          }
        }
        if (cameraStream.getVideoTracks().length === 0 && avatarStream.getVideoTracks().length > 0) {
          cameraStream.addTrack(avatarStream.getVideoTracks()[0]);
        }
        return Promise.resolve(cameraStream);
      });
    }).catch((error: any) => {
      console.log(`Failed to prepareCameraWithAvatarStream: ${JSON.stringify(error)}`);
      return Promise.reject(new Error("PROBLEM_PREPARING_STREAM"));
    });
  }

  /**
   * try get local media device stream
   * @param streamName? string - stream name to be assigned
   */
  initMediaSourceTry(isForPrimary: boolean, streamName: string): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      let userPreferedResolution = this.userService.currentUser.preferedPrimaryResolution;
      let defaultResolutionInTheme = this.rtcClient.DEFAULT_PRIMARY_RESOLUTION_IN_THEME;
      // Don't allow setting higher than what is allowed in theme.
      let presetResolution: VideoResolution = userPreferedResolution || defaultResolutionInTheme;
      this.rtcClient.resetMaxPrimaryResolution();// make sure this value is updated.
      presetResolution =  _.minBy(_.compact([presetResolution, this.rtcClient.maxPrimaryResolution]), 
        MediaUtil.videoResolutionSortValue);
      this._easyrtcClient.initMediaSource(
        (stream: any) => {
          if (LogUtil.getLogInstance().logsSettings.webrtcStatistics) 
          {
            // if we are logging web RTC status update GET USER MEDIA HERE
            LogUtil.getLogInstance().updateGetUserMedia(stream, this.rtcClient.rtcId);
          }
          let currentSysResolution = this.rtcClient.currentSysPrimaryResolution;
          if (currentSysResolution.height > presetResolution.height) {
            this.setToDefaultResolution(isForPrimary);
          }
          resolve(stream);
        },
        (errorCode: string, errorMessage: string) => {
          console.warn("init media source failed", errorCode, errorMessage);
          switch (errorMessage) {
            case "NotFoundError":
            case "DevicesNotFoundError":
              reject(new Error("DEVICE_NOT_FOUND"));
              break;
            case "NotReadableError":
            case "TrackStartError":
              reject(new Error("DEVICE_NOT_READABLE"));
              break;
            case "NotAllowedError":
            case "PermissionDeniedError":
              reject(new Error("DEVICE_NOT_ALLOWED"));
              break;
            case "TypeError":
              reject(new Error("MEDIA_CONSTRAINT_NOT_PROVIDED"));
              break;
            case "OverconstrainedError":
            case "ConstraintNotSatisfiedError":
            default:
              reject(new Error("MEDIA_CONSTRAINT_NOT_SATISFIED"));
              break;
          }
        },
        streamName
      );
    });
  }

  register3rdPartyLocalMediaStream(stream: MediaStream, streamname: string) {
    if (stream) {
      this._easyrtcClient.register3rdPartyLocalMediaStream(stream, streamname);
    }
  }

  /**
   * get screen capture stream
   * @param forceNew: boolean - flag if force to get new stream
   */
  getScreenCaptureStream(forceNew: boolean): Promise<MediaStream> {
    if (!forceNew && this.rtcClient.screenCaptureStream) {
      MediaUtil.applyConstraintsOnStream(this.rtcClient.screenCaptureStream, this.rtcClient, true);
      return Promise.resolve(this.rtcClient.screenCaptureStream);
    } else {
      return this.getScreenCaptureStreamPromise().then((stream: any) => {
        this.register3rdPartyLocalMediaStream(stream, "screen");
        this.rtcClient.screenCaptureStream = stream;
        this.rtcClient.prepareAudioContext();
        if (this.rtcClient.desktopAudioSourceNode) {
          this.rtcClient.desktopAudioSourceNode.disconnect();
        }
        if (this.rtcClient.audioContext && stream && stream.getAudioTracks()[0]) {
          this.rtcClient.desktopAudioSourceNode = this.rtcClient.audioContext.createMediaStreamSource(stream);
          this.rtcClient.desktopAudioSourceNode.connect(this.rtcClient.transmittingAudioMixDestinationNode);
        }
        this.rtcClient.screenCaptureStream.getVideoTracks()[0].onended = () => {
          this.toggleScreenSharing(false);
        };
        if (!this.rtcClient.secondaryVideoElement) {
          this.rtcClient.secondaryVideoElement = document.createElement("video");
          document.body.appendChild(this.rtcClient.secondaryVideoElement);
          this.rtcClient.secondaryVideoElement.style.display = "none";
        }
        return this.setVideoSrcByElement(this.rtcClient.secondaryVideoElement, this.rtcClient.screenCaptureStream, true)
        .then(() => {
          return Promise.resolve(this.rtcClient.screenCaptureStream);
        })
        .catch((error)=>{
          return Promise.reject(error)
        });
      });
    }
  }

  /**
   * get screen capture stream
   * @param forceNew: boolean - flag if force to get new stream
   */
  getScreenCaptureStreamPromise(): Promise<MediaStream> {
    if ((navigator.mediaDevices as any).getDisplayMedia) {
      return (navigator.mediaDevices as any).getDisplayMedia(MediaUtil.getUserMediaConstraints(this.rtcClient, true));
    } else {
      this._easyrtcClient.setUserMediaConstraints(MediaUtil.getUserMediaConstraints(this.rtcClient, true));
      return this.initMediaSourceTry(false, "screen").then((stream: any) => {
        return this._easyrtcClient.getLocalStream("screen");
      });
    }
  }

  /**
   * get recording stream
   * @param forceNew: boolean - flag if force to get new stream
   * @param frameRate: number - frame rate to record
   * @param audioTracks: Array<MediaStreamTrack> - audio tracks to record
   * @param audioOnly: boolean - flag if audio only
   */
  getRecordingStream(
    forceNew: boolean,
    frameRate: number,
    audioTracks: Array<MediaStreamTrack>,
    audioOnly: boolean = false
  ): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      if (!forceNew && this.rtcClient.recordingStream) {
        resolve(this.rtcClient.recordingStream);
      } else {
        if (audioOnly) {
          this.rtcClient.recordingStream = this._easyrtcClient.buildLocalMediaStream("recording", audioTracks, []);
          MediaUtil.enableAudioTracks(this.rtcClient.recordingStream, true);
          resolve(this.rtcClient.recordingStream);
        } else {
          var canvas: any = <HTMLCanvasElement>document.getElementById("video-scene-canvas");
          if (!canvas) {
            reject(new Error("CANVAS_NOT_EXIST"));
          } else {
            canvas.getContext("2d");
            this.rtcClient.recordingCanvasStream = canvas.captureStream(frameRate);
            this.rtcClient.recordingStream = this._easyrtcClient.buildLocalMediaStream(
              "recording",
              audioTracks,
              this.rtcClient.recordingCanvasStream.getVideoTracks()
            );
            MediaUtil.enableAudioTracks(this.rtcClient.recordingStream, true);
            MediaUtil.enableVideoTracks(this.rtcClient.recordingStream, true);
            resolve(this.rtcClient.recordingStream);
          }
        }
      }
    });
  }

  /**
   * get broadcast stream
   * @param forceNew: boolean - flag if force to get new stream
   * @param frameRate: number - frame rate to record
   * @param audioTracks: Array<MediaStreamTrack> - audio tracks to record
   * @param audioOnly: boolean - flag if audio only
   */
  getBroadcastStream(
    forceNew: boolean,
    frameRate: number,
    audioTracks: Array<MediaStreamTrack>,
    audioOnly: boolean = false
  ): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      if (!forceNew && this.rtcClient.broadcastStream) {
        resolve(this.rtcClient.broadcastStream);
      } else {
        if (audioOnly) {
          this.rtcClient.broadcastStream = this._easyrtcClient.buildLocalMediaStream("broadcast", audioTracks, []);
          MediaUtil.enableAudioTracks(this.rtcClient.broadcastStream, true);
          resolve(this.rtcClient.broadcastStream);
        } else {
          var canvas: any = <HTMLCanvasElement>document.getElementById("video-scene-canvas");
          if (!canvas) {
            reject(new Error("CANVAS_NOT_EXIST"));
          } else {
            canvas.getContext("2d");
            this.rtcClient.broadcastCanvasStream = canvas.captureStream(frameRate);
            this.rtcClient.broadcastStream = this._easyrtcClient.buildLocalMediaStream(
              "broadcast",
              audioTracks,
              this.rtcClient.broadcastCanvasStream.getVideoTracks()
            );
            MediaUtil.enableAudioTracks(this.rtcClient.broadcastStream, true);
            MediaUtil.enableVideoTracks(this.rtcClient.broadcastStream, true);
            resolve(this.rtcClient.broadcastStream);
          }
        }
      }
    }).
    then((stream) => { 
      this.broadcastStream = stream;
      return this.broadcastStream;
    });
  }

  /**
   * get avatar stream
   * @param forceNew: boolean - flag if force to get new stream
   */
  getAvatarStream(forceNew: boolean): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      if (!forceNew && this.rtcClient.avatarStream) {
        if (
          this.rtcClient.avatarStream.getVideoTracks().length > 0 &&
          this.rtcClient.avatarStream.getVideoTracks()[0].enabled &&
          this.rtcClient.avatarStream.getVideoTracks()[0].readyState === "live"
        ) {
          resolve(this.rtcClient.avatarStream);
          return;
        }
      }
      this.rtcClient.avatarStream = null;
      this.rtcClient.avatarStream = new MediaStream();
      _.forEach(this.createAvatarVideoStream().getVideoTracks(), (videoTrack: MediaStreamTrack) => {
        this.rtcClient.avatarStream.addTrack(videoTrack);
      });
      this.rtcClient.avatarStream.streamName = "avatar";
      this.register3rdPartyLocalMediaStream(this.rtcClient.avatarStream, "avatar");
      resolve(this.rtcClient.avatarStream);
    });
  }

  /**
   * get the content stream
   */
  getContentStream(forceNew): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      if(!forceNew)
      {
        if (
          this.rtcClient.contentStream.getVideoTracks().length > 0 &&
          this.rtcClient.contentStream.getVideoTracks()[0].enabled &&
          this.rtcClient.contentStream.getVideoTracks()[0].readyState === "live"
        ) {
          resolve(this.rtcClient.contentStream);
          return;
        }
      }

      if(this.rtcClient.contentStream)
      {
        this.rtcClient.contentStream.streamName = "screen";
        // make sure it's named properly.
        this.register3rdPartyLocalMediaStream(this.rtcClient.contentStream, "screen");

        if (!this.rtcClient.secondaryVideoElement) {
          this.rtcClient.secondaryVideoElement = document.createElement("video");
          document.body.appendChild(this.rtcClient.secondaryVideoElement);
          this.rtcClient.secondaryVideoElement.style.display = "none";
        }
        this.setVideoSrcByElement(this.rtcClient.secondaryVideoElement, this.rtcClient.contentStream, true)
        .then(() => {
          // register it.
          resolve(this.rtcClient.contentStream);  
        })
        .catch((error)=>{reject(error)});
      }
      else
      {
        reject(new Error("NO_CONTENT_AVAILABLE"));
      }
    });
  }

  /**
   * get secondary stream
   * @param forceNew: boolean - flag if force to get new stream
   */
  getSecondaryStream(forceNew: boolean = false): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      if (
        !forceNew &&
        this.rtcClient.secondaryStream &&
        this.rtcClient.secondaryStream.getVideoTracks().length > 0 &&
        this.rtcClient.secondaryStream.getVideoTracks()[0].enabled &&
        this.rtcClient.secondaryStream.getVideoTracks()[0].readyState === "live"
      ) {
        resolve(this.rtcClient.secondaryStream);
      } else {
        this.rtcClient.secondaryStream = new MediaStream();
        this.createSecondaryVideoStream().then((stream: MediaStream) => {
          this.rtcClient.secondaryStream = stream;
          this.rtcClient.secondaryStream.streamName = "screen";
          this.register3rdPartyLocalMediaStream(this.rtcClient.secondaryStream, "screen");
          resolve(this.rtcClient.secondaryStream);
        }).catch((error: any) => {
          // createSecondaryVideoStream literally does not reject but possibly could in future
          // it possibly should not even be a promise
        console.log(`Failed to createSecondaryVideoStream: ${JSON.stringify(error)}`);
      });
      }
    });
  }

  /**
   * create avatar video stream
   */
  createAvatarVideoStream(): MediaStream {
    this.drawAvatarCanvas();
    return (this.rtcClient.avatarCanvasElement as any).captureStream(this.rtcClient.primaryVideoFrameRate);
  }

  createAvatarCanvas() {
    if (!this.rtcClient.avatarCanvasElement) {
      this.rtcClient.avatarCanvasElement = document.createElement("canvas") as HTMLCanvasElement;
      this.rtcClient.avatarCanvasElement.style.display = "none";
      this.rtcClient.avatarCanvasElement.id = "avatar-canvas";
      document.body.appendChild(this.rtcClient.avatarCanvasElement);
    }
  }

  drawAvatarCanvas() {
    this.createAvatarCanvas();
    if (/\.gif$/i.exec(this.rtcClient.avatarImageSrc)) {
      this.drawGifAvatarCanvas();
    } else {
      clearInterval(this.rtcClient.avatarCanvasDrawTimer);
      this.rtcClient.avatarImageElement = document.getElementById("video-muted-placeholder") as HTMLImageElement;
      this.drawAvatarCanvasByImage();
    }
  }

  drawAvatarCanvasByImage() {
    if (this.rtcClient.avatarImageElement) {
      let canvasWidth = this.rtcClient.currentSysPrimaryResolution.width;
      let canvasHeight = this.rtcClient.currentSysPrimaryResolution.height;
      this.rtcClient.avatarCanvasElement.width = canvasWidth;
      this.rtcClient.avatarCanvasElement.height = canvasHeight;
      let avatarCanvasContext = this.rtcClient.avatarCanvasElement.getContext("2d");
      let imageWidth = this.rtcClient.avatarImageElement.width;
      let imageHeight = this.rtcClient.avatarImageElement.height;
      if (imageHeight && canvasHeight) {
        let imageRatio = imageWidth / imageHeight;
        let canvasRatio = canvasWidth / canvasHeight;
        if (imageRatio > canvasRatio) {
          imageWidth = canvasWidth;
          imageHeight = imageWidth / imageRatio;
        } else {
          imageHeight = canvasHeight;
          imageWidth = imageHeight * imageRatio;
        }
      }
      avatarCanvasContext.clearRect(0, 0, canvasWidth, canvasHeight);
      avatarCanvasContext.drawImage(
        this.rtcClient.avatarImageElement,
        (canvasWidth - imageWidth) / 2,
        (canvasHeight - imageHeight) / 2,
        imageWidth,
        imageHeight
      );
    }
    this.rtcClient.avatarCanvasDrawTimer = setTimeout(() => {
      clearTimeout(this.rtcClient.avatarCanvasDrawTimer);
      this.drawAvatarCanvasByImage();
    }, 1000);
  }

  drawGifAvatarCanvas() {
    let canvasWidth = this.rtcClient.currentSysPrimaryResolution.width;
    let canvasHeight = this.rtcClient.currentSysPrimaryResolution.height;
    this.rtcClient.avatarCanvasElement.width = canvasWidth;
    this.rtcClient.avatarCanvasElement.height = canvasHeight;
    if (!!this.rtcClient.gifAnimator) {
      return;
    }
    this.rtcClient.gifAnimator = gifler(this.rtcClient.avatarImageSrc).frames(
      "#avatar-canvas",
      (ctx: CanvasRenderingContext2D, frame: any) => {
        // Match width/height to remove distortion
        let imageWidth = frame.width;
        let imageHeight = frame.height;
        if (imageHeight && canvasHeight) {
          let imageRatio = imageWidth / imageHeight;
          let canvasRatio = canvasWidth / canvasHeight;
          if (imageRatio > canvasRatio) {
            imageWidth = canvasWidth;
            imageHeight = imageWidth / imageRatio;
          } else {
            imageHeight = canvasHeight;
            imageWidth = imageHeight * imageRatio;
          }
        }
        ctx.drawImage(
          frame.buffer,
          frame.x + (canvasWidth - imageWidth) / 2,
          frame.y + (canvasHeight - imageHeight) / 2,
          imageWidth,
          imageHeight
        );
      }
    );
  }

  /**
   * draw video on canvas
   * @param canvas: HTMLCanvasElement - canvas element
   * @param videoElement: HTMLVideoElement - video element
   * @param videoPosition: IPosition - position to draw
   * @param cameraRotation: string
   */
  drawVideo(
    canvas: HTMLCanvasElement,
    videoElement: HTMLVideoElement,
    videoPosition: IPosition,
    cameraRotation?: string
  ) {
    let context = canvas.getContext("2d");
    const rotationDegrees = cameraRotation ? parseInt(cameraRotation, 10) : 0;
    const isRotated = rotationDegrees && (rotationDegrees === 90 || rotationDegrees === -90);
    if (videoElement) {
      const videoCanvasX = (videoPosition.leftPercent / 100) * canvas.width;
      const videoCanvasY = (videoPosition.topPercent / 100) * canvas.height;
      const videoCanvasWidth = (videoPosition.widthPercent / 100) * canvas.width;
      const videoCanvasHeight = (videoPosition.heightPercent / 100) * canvas.height;
      // draw background for canvas
      context.fillRect(videoCanvasX, videoCanvasY, videoCanvasWidth, videoCanvasHeight);
      // default draw size as canvas size of endpoint
      let videoDrawWidth: number = videoCanvasWidth;
      let videoDrawHeight: number = videoCanvasHeight;
      // compare canvas ratio and video radio to find the smaller to fit the space
      const videoDrawRatio: number = videoElement.videoWidth / videoElement.videoHeight;
      const mainCanvasRatio: number = videoCanvasWidth / videoCanvasHeight;
      if (mainCanvasRatio > videoDrawRatio) {
        videoDrawHeight = isRotated ? videoCanvasWidth : videoDrawHeight;
        videoDrawWidth = videoDrawHeight * videoDrawRatio;
      } else {
        videoDrawWidth = isRotated ? videoCanvasHeight : videoDrawWidth;
        videoDrawHeight = videoDrawWidth / videoDrawRatio;
      }
      // adjust white space to put video in center
      const videoDrawX: number = videoCanvasX + (videoCanvasWidth - videoDrawWidth) / 2;
      const videoDrawY: number = videoCanvasY + (videoCanvasHeight - videoDrawHeight) / 2;
      // apply rotation
      if (rotationDegrees) {
        const translateX = videoDrawX + videoDrawWidth / 2;
        const translateY = videoDrawY + videoDrawHeight / 2;
        context.translate(translateX, translateY);
        context.rotate((rotationDegrees * Math.PI) / 180);
        context.translate(-translateX, -translateY);
      }
      // draw video image to canvas
      context.drawImage(videoElement, videoDrawX, videoDrawY, videoDrawWidth, videoDrawHeight);
    }
  }

  /**
   * draw image on canvas
   * @param canvas: HTMLCanvasElement - canvas element
   * @param imageElement: HTMLImageElement - image element
   * @param videoPosition: IPosition - position to draw
   */
  drawImage(canvas: HTMLCanvasElement, imageElement: HTMLImageElement, videoPosition: IPosition) {
    let context = canvas.getContext("2d");
    if (imageElement) {
      let videoCanvasX = (videoPosition.leftPercent / 100) * canvas.width;
      let videoCanvasY = (videoPosition.topPercent / 100) * canvas.height;
      let videoCanvasWidth = (videoPosition.widthPercent / 100) * canvas.width;
      let videoCanvasHeight = (videoPosition.heightPercent / 100) * canvas.height;
      // draw background for canvas
      context.fillRect(videoCanvasX, videoCanvasY, videoCanvasWidth, videoCanvasHeight);
      // default draw size as canvas size of endpoint
      var videoDrawWidth: number = videoCanvasWidth;
      var videoDrawHeight: number = videoCanvasHeight;
      // compare canvas ratio and video radio to find the smaller to fit the space
      let videoDrawRatio: number = imageElement.width / imageElement.height;
      let mainCanvasRatio: number = videoCanvasWidth / videoCanvasHeight;
      if (mainCanvasRatio > videoDrawRatio) {
        videoDrawWidth = videoDrawHeight * videoDrawRatio;
      } else {
        videoDrawHeight = videoDrawWidth / videoDrawRatio;
      }
      // adjust white space to put video in center
      var videoDrawX: number = videoCanvasX + (videoCanvasWidth - videoDrawWidth) / 2;
      var videoDrawY: number = videoCanvasY + (videoCanvasHeight - videoDrawHeight) / 2;
      // draw video image to canvas
      context.drawImage(imageElement, videoDrawX, videoDrawY, videoDrawWidth, videoDrawHeight);
    }
  }

  /**
   * create empty video stream
   */
  createSecondaryVideoStream(): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      clearInterval(this.rtcClient.secondaryCanvasDrawInterval);
      if (!this.rtcClient.secondaryCanvasElement) {
        this.rtcClient.secondaryCanvasElement = document.createElement("canvas") as HTMLCanvasElement;
        this.rtcClient.secondaryCanvasElement.style.display = "none";
        this.rtcClient.secondaryCanvasElement.id = "secondary-canvas"
        document.body.appendChild(this.rtcClient.secondaryCanvasElement);
      }
      let secondaryCanvasContext = this.rtcClient.secondaryCanvasElement.getContext("2d");
      this.rtcClient.secondaryCanvasDrawInterval = setInterval(() => {
        let canvasWidth = this.rtcClient.currentSysSecondaryResolution.width;
        let canvasHeight = this.rtcClient.currentSysSecondaryResolution.height;
        this.rtcClient.secondaryCanvasElement.width = canvasWidth;
        this.rtcClient.secondaryCanvasElement.height = canvasHeight;
        secondaryCanvasContext.clearRect(0, 0, canvasWidth, canvasHeight);
        if (
          (this.rtcClient.screenShareEnabled || this.rtcClient.showContentEnabled) &&
          this.rtcClient.secondaryVideoElement
        ) {
          this.drawVideo(this.rtcClient.secondaryCanvasElement, this.rtcClient.secondaryVideoElement, {
            leftPercent: 0,
            topPercent: 0,
            widthPercent: 100,
            heightPercent: 100,
          });
        }
      }, 1000 / this.rtcClient.secondaryVideoFrameRate);
      let stream = (this.rtcClient.secondaryCanvasElement as any).captureStream(this.rtcClient.secondaryVideoFrameRate);
      resolve(stream);
    });
  }

  /**
   * get current peer connection count
   */
  getConnectionCount(): number {
    return this._easyrtcClient.getConnectionCount();
  }

  /**
   * send files to peer
   * @param rtcId: string - the rtcId of peer to send to
   * @param files: any - the files to be sent
   */
  sendFiles(rtcId: string, files: any[]): void {
    if (this.rtcClient.fileSenders[rtcId]) {
      this.rtcClient.fileSenders[rtcId](files, true);
    } else {
      this.rtcClient.alertHandler("FILE_SENDER_NOT_EXIST", undefined, AlertLevel.warning);
    }
  }

  /**
   * download file
   * @param blob: any - file to download in blob format
   * @param filename: any - filename to be saved as
   */
  downloadFile(blob: any, filename: string) {
    this._easyFileClient.saveAs(blob, filename);
  }

  /**
   * create drop file area on the dom element
   * @param element: HTMLElement - dom element to receive dropped files
   * @param filesDropHandler: (files: any) => void - handler on file drop
   */
  buildDragNDropRegion(element: HTMLElement, filesDropHandler: (files: any) => void) {
    this._easyFileClient.buildDragNDropRegion(element, filesDropHandler);
  }

  /**
   * send message to server
   * @param msgType: string - message type
   * @param msgBody: any - message data to be sent
   * @param successHandler?: (msgType: string, msgBody: any) => void - success handler
   * @param failureHandler?: (errorCode: string, errorText: string) => void - fail handler
   */
  sendServerMessage(
    msgType: string,
    msgBody: any,
    successHandler?: (msgType: string, msgData: any) => void,
    failureHandler?: (errorCode: string, errorText: string) => void
  ): void {
    this.waitForServerConnected(() => {
      this._easyrtcClient.sendServerMessage(msgType, msgBody, successHandler, failureHandler);
    });
  }

  /**
   * set listener on server message receiving
   * @param messageListener: (msgType: string, msgData: any, targeting: any) => void - server message receiving handler
   */
  setServerMessageListener(messageListener: (msgType: string, msgData: any, targeting: any) => void): void {
    this._easyrtcClient.setServerListener(messageListener);
  }

  /**
   * have current peer join into room
   * @param roomName: string - room name
   * @param roomParameters?: string - room parameters
   * @param successHandler?: (roomName: string) => void - success handler
   * @param failureHandler?: (errorCode: string, errorText: string, roomName: string) => void - fail handler
   */
  joinRoom(
    roomName: string,
    roomParameters?: string,
    successHandler?: (roomName: string) => void,
    failureHandler?: (errorCode: string, errorText: string, roomName: string) => void
  ): void {
    this._easyrtcClient.joinRoom(roomName, roomParameters, successHandler, failureHandler);
  }

  /**
   * have current peer leave from room
   * @param roomName: string - room name
   * @param successHandler?: (roomName: string) => void - success handler
   * @param failureHandler?: (errorCode: string, errorText: string, roomName: string) => void - fail handler
   */
  leaveRoom(
    roomName: string,
    successHandler?: (roomName: string) => void,
    failureHandler?: (errorCode: string, errorText: string, roomName: string) => void
  ): void {
    this._easyrtcClient.leaveRoom(roomName, successHandler, failureHandler);
  }

  /**
   * get all rooms this peer currently joined
   */
  getRoomsJoined(): { [roomName: string]: boolean } {
    return this._easyrtcClient.getRoomsJoined();
  }

  /**
   * get peerConnection statistics in a human readable format for the inspector tool
   */
  getPeerStatistics(rtcId: string, callback: (rtcId: string, stats: any) => any): void {
    let peerConnection: any = this.getPeerConnectionById(rtcId);
    let ep: IEndpoint = EndpointService.getSharedInstance().getEndpointById(rtcId);
    if (peerConnection) {
      StatsUtil.getPCStats(peerConnection, ep ? ep.lastStats : null)
      .then((result: any) => {
        callback(rtcId, result);
      })
      .catch((err: Error) => {
        this.rtcClient.alertHandler(err.message);
        callback(rtcId, {});
      });
    } else {
      callback(rtcId, {});
    }
  }

  /**
   * getStats for Peerconnection in a raw format that can be read by webrtc dump tool
   */
  getRawPeerConnectionStatistics(rtcId: string) : Promise<any>
  {
    return new Promise((resolve: (result: any) => void, reject: (err: Error) => void) => {
      let pc: any = this.getPeerConnectionById(rtcId);
      if (!pc) {
        reject(new Error("NO PEER CONNECTION FOUND"));
        return;
      }
      pc.getStats()
      .then((stats: any) => {
        resolve(Array.from(stats.entries()));
        return;
      })
      .catch((err: Error) => {
        reject(err);
        return;
      });
    });
  }

  /**
   * get server ice configs
   */
  getServerIce(): any {
    return this._easyrtcClient.getServerIce();
  }

  /**
   * dialDTMF
   * @param rtcIds: string[] - targets to dial
   * @param key: string - dial key tone
   */
  dialDTMF(rtcIds: string[], key: string) {
    _.forEach(rtcIds, (rtcId: string) => {
      let peerconnection = this.getPeerConnectionById(rtcId);
      if (peerconnection) {
        this.rtcClient.alertHandler("sending DTMF tone " + key + " to " + rtcId);
        let audioSender = _.find(peerconnection.getSenders(), (rtpSender: any) => {
          return rtpSender.kind === "audio";
        });
        if (audioSender) {
          audioSender.dtmf.insertDTMF(key);
        }
      }
    });
  }

  /**
   * useCustomIceConfig
   * @param iceConfigs: IICEConfig[] - list of ice server info
   */
  useCustomIceConfig(iceConfigs: IICEConfig[]) {
    this.sendServerMessage("getTURNCredentials", iceConfigs, (msgType, msgData) => {
      let iceServers = JSON.parse(CryptoUtil.decrypt(msgData, this.rtcClient.rtcId));
      this.rtcClient.peerconnectionConfig.iceServers = iceServers;
      this.applyPeerconnectionConfig();
    });
  }

  /**
   * apply peerconnection config
   */
  applyPeerconnectionConfig() {
    if (!this.rtcClient.peerconnectionConfig.iceServers) {
      let encryptedIceServers = this._easyrtcClient.getServerIce().iceServers;
      let iceServers = JSON.parse(CryptoUtil.decrypt(encryptedIceServers, this.rtcClient.rtcId));
      this.rtcClient.peerconnectionConfig.iceServers = iceServers;
    }
    this._easyrtcClient.setIceUsedInCalls(this.rtcClient.peerconnectionConfig);
  }

  /**
   * set video max bandwidth
   * @param kbitsPerSecond: number - bandwidth kb per second number
   * @param save: boolean - flag if save to cookie
   * @param updateWithOthers: boolean - flag if want to update with others
   */
  setVideoBandwidth(kbitsPerSecond?: number, save: boolean = true, updateWithOthers: boolean = false) {
    this.rtcClient.userSelectedVideoBandwidth = kbitsPerSecond;
    if (save) {
      this.userService.currentUser.preferedBandwidth = kbitsPerSecond;
      GlobalService.setSessionUser(this.userService.currentUser);
    }
    if (updateWithOthers && this.rtcClient.streamEventHandler) {
      this.rtcClient.streamEventHandler.renegotiationRequiredListener();
    }
  }

  /**
   * set video resolution
   * @param isForPrimary: boolean - flag if this is for primary video source
   * @param resolution: VideoResolution - new resolution
   * @param framerate: number - ideal framerate
   * @param save: boolean - flag if save to cookie
   */
  setVideoResolution(
    isForPrimary: boolean,
    resolution?: VideoResolution,
    framerate: number = 30,
    save: boolean = true
  ) {
    if (isForPrimary) {
      this.rtcClient.userSelectedPrimaryResolution = resolution;
    } else {
      this.rtcClient.userSelectedSecondaryResolution = resolution;
    }
    _.forEach(this.rtcClient.defaultStreams, (stream: any) => {
      if (isForPrimary && (stream.streamName === "screen" || stream.streamName === "secondary-camera")) {
        return;
      }
      if (!isForPrimary && (stream.streamName === "camera" || stream.streamName === "avatar")) {
        return;
      }
      this.updateLocalStream(stream.streamName, false)
      .then((newStream: any) => {
        this.rtcClient.alertHandler("Successfully update resolution on stream of " + stream.streamName);
      })
      .catch((error: Error) => {
        this.rtcClient.alertHandler(
          "Failed to update local stream after new resolution configured: ",
          error ? error.message : undefined
        );
      });
    });
    if (save) {
      if (isForPrimary) {
        this.userService.currentUser.preferedPrimaryResolution = resolution;
      } else {
        this.userService.currentUser.preferedSecondaryResolution = resolution;
      }
      GlobalService.setSessionUser(this.userService.currentUser);
    }
  }

  /**
   * mute microphone
   * @param muted: boolean - flag if set to mute
   */
  muteMicrophone(muted: boolean) {
    console.log(`Microphone: ${!muted?"un":""}muted`);
    if (this.rtcClient.cameraStream) {
      MediaUtil.enableAudioTracks(this.rtcClient.cameraStream, !muted);
    }
  }

  /**
   * mute camera
   * @param muted: boolean - flag if set to mute
   */
  muteCamera(muted: boolean) {
    if (this.rtcClient.videoMuted === muted) {
      return;
    }
    this.rtcClient.videoMuted = muted;
    this.userService.currentUser.preferedVideoMute = muted;
    GlobalService.setSessionUser(this.userService.currentUser);
    MediaUtil.enableVideoTracks(this.rtcClient.cameraStream, !this.rtcClient.videoMuted);
    this.toggleCameraStream(true);
  }

  /**
   * set local microphone device
   * @param audioSrcId: string - microphone device id
   * @param save: boolean - flag if save the setting in cookie, default true
   */
  setAudioSource(audioSrcId: string): Promise<void> {
    this.rtcClient.audioSrcId = audioSrcId;
    this._easyrtcClient.setAudioSource(audioSrcId);
    this.userService.currentUser.preferedMicrophoneDeviceId = audioSrcId;
    GlobalService.setSessionUser(this.userService.currentUser);
    return this.updateLocalStream("camera", true)
    .then((stream: MediaStream) => {
      if (this.rtcClient.streamEventHandler) {
        this.rtcClient.streamEventHandler.updateLocalStreamListener();
      }
    })
    .catch((error: Error) => {
      this.rtcClient.alertHandler(
        "Failed to update local stream after new audio source selected: ",
        error ? error.message : undefined
      );
    });
  }

  /**
   * set local camera device
   * @param audioSrcId: string - camera device id
   * @param save: boolean - flag if save the setting in cookie, default true
   */
  updateVideoSource(isForPrimary: boolean): Promise<void> {
    if (isForPrimary) {
      delete this.rtcClient.AUTO_MODE_PRIMARY_RESOLUTION;
    } else {
      delete this.rtcClient.AUTO_MODE_SECONDARY_RESOLUTION;
    }
    if (isForPrimary) {
      this.userService.currentUser.preferedPrimaryCameraDeviceId = this.rtcClient.primaryVideoSrcId;
      this.userService.currentUser.preferredPrimaryCameraRotation = this.rtcClient.selectedPrimaryCameraRotation;
    } else {
      this.userService.currentUser.preferedSecondaryCameraDeviceId = this.rtcClient.primaryVideoSrcId;
    }
    this.userService.currentUser.preferedSecondaryCameraDeviceId = this.rtcClient.secondaryVideoSrcId;
    GlobalService.setSessionUser(this.userService.currentUser);
    return (isForPrimary
      ? this.rtcClient.filteredStreamEnabled
        ? this.updateLocalStream("filtered-camera", true)
        : this.updateLocalStream("camera", true)
      : this.updateLocalStream("secondary-camera", true)
    )
    .then(() => {
      if (this.rtcClient.streamEventHandler) {
        this.rtcClient.streamEventHandler.updateLocalStreamListener();
      }
    })
    .catch((error: Error) => {
      this.rtcClient.alertHandler(
        "Failed to update local stream after new video source selected ",
        error ? error.message : undefined
      );
    });
  }

  /**
   * Add a local stream to calls.
   * @param {String[]} rtcIds The array of id of client receiving the stream.
   * @param {String} the stream.
   */
  addStreamToCalls(rtcIds: string[], stream: any): void {
    _.forEach(rtcIds, (rtcId: string) => {
      var peerConnection = this.getPeerConnectionById(rtcId);
      if (peerConnection) {
        MediaUtil.addStreamToPeerConnection(stream, peerConnection);
      }
    });
  }

  /**
   * Removes a mediastream from calls
   * @param rtcIds: string[] - the array of id of client to remove from
   * @param stream: any - the stream
   */
  removeStreamFromCalls(rtcIds: string[], stream: any): void {
    _.forEach(rtcIds, (rtcId: string) => {
      var peerConnection = this.getPeerConnectionById(rtcId);
      if (peerConnection) {
        MediaUtil.removeStreamFromPeerConnection(stream, peerConnection);
      }
    });
  }

  /**
   * Removes a media stream from peer
   * @param rtcId: string - the id of client to remove from
   * @param stream: any - the stream
   */
  removeStreamFromPeer(rtcId: string, stream: any): void {
    const peerConnection = this.getPeerConnectionById(rtcId);
    if (peerConnection) {
      MediaUtil.removeStreamFromPeerConnection(stream, peerConnection);
    }
  }

  /**
   * toggle camera stream
   * @param enabled: boolean - flag if camera stream shall be enabled
   */
  toggleCameraStream(enabled: boolean): Promise<void> {
    if (enabled) {
      return this.updateLocalStream(this.rtcClient.videoMuted ? "avatar" : "camera", false)
      .then((stream: any) => {
        this.rtcClient.cameraStreamEnabled = true;
        if (this.rtcClient.streamEventHandler) {
          this.rtcClient.streamEventHandler.updateLocalStreamListener();
        }
        return Promise.resolve();
      })
      .catch((error: Error) => {
        this.rtcClient.alertHandler("Failed to update local stream with camera: ", error ? error.message : undefined);
        return Promise.reject(error);
      });
    } else {
      this.rtcClient.cameraStreamEnabled = false;
      MediaUtil.stopStream(this.rtcClient.cameraStream);
      delete this.rtcClient.cameraStream;
      if (this.rtcClient.streamEventHandler) {
        this.rtcClient.streamEventHandler.updateLocalStreamListener();
      }
      return Promise.resolve();
    }
  }

  /**
   * toggle screen sharing
   * @param enabled: boolean - flag if screen sharing shall be enabled
   */
  toggleScreenSharing(enabled: boolean): void {
    if (enabled) {
      this.updateLocalStream("screen", true)
      .then(() => { return this.getSecondaryStream(true); })
      .then((stream: any) => {
        this.rtcClient.screenShareEnabled = true;
        MediaUtil.applyConstraintsOnStream(this.rtcClient.cameraStream, this.rtcClient, false);
        if (this.rtcClient.streamEventHandler) {
          this.rtcClient.streamEventHandler.updateLocalStreamListener();
        }
      })
      .catch((error: Error) => {
        if ("Permission denied" === error?.message) {
          this.rtcClient.alertHandler("FAIL_TO_START_PRESENTATION", "Presentation cancelled.", AlertLevel.info);
        } else {
          this.rtcClient.alertHandler("FAIL_TO_START_PRESENTATION", "Presentation cannot start.", AlertLevel.warning);
          this.rtcClient.alertHandler(
            "Failed to update local stream with screen capture: ",
            error ? error.message : undefined
          );
        }
      });
    } else {
      if (this.rtcClient.screenShareEnabled) {
        this.rtcClient.screenShareEnabled = false;
        MediaUtil.applyConstraintsOnStream(this.rtcClient.cameraStream, this.rtcClient, false);
        MediaUtil.stopStream(this.rtcClient.screenCaptureStream);
        clearInterval(this.rtcClient.secondaryCanvasDrawInterval);
        this.toggleCameraStream(true)
        .catch((error: any) => {
          console.log("toggleScreenSharing - Failed to toggleCameraStream:", error);
        });
      }
    }
  }

  /**
   * update local stream
   * @param type: string - stream type 'camera' or 'screen'
   * @param forceNew: boolean - flag if force to get new stream
   */
  updateLocalStream(type: string, forceNew: boolean): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: Error) => void) => {
      if (type === "camera") {
        this.getCameraStream(forceNew)
        .then((stream: MediaStream) => {
          resolve(stream);
        })
        .catch((error: Error) => {
          reject(error);
        });
      } else if (type === "filtered-camera") {
        this.getFilteredStream(forceNew)
        .then((stream: MediaStream) => {
          resolve(stream);
        })
        .catch((error: Error) => {
          reject(error);
        });
      } else if (type === "secondary-camera") {
        this.getSecondaryCameraStream(forceNew)
        .then((stream: MediaStream) => {
          resolve(stream);
        })
        .catch((error: Error) => {
          reject(error);
        });
      } else if (type === "screen") {
        this.getScreenCaptureStream(forceNew)
        .then((stream: MediaStream) => {
          resolve(stream);
        })
        .catch((error: Error) => {
          reject(error);
        });
      } else if (type === "avatar") {
        this.getAvatarStream(forceNew)
        .then((stream: MediaStream) => {
          resolve(stream);
        })
        .catch((error: Error) => {
          reject(error);
        });
      } else if (type === "content") {
        this.getContentStream(forceNew)
        .then((stream: MediaStream) => {
          resolve(stream);
        })
        .catch((error: Error) => {
          reject(error);
        });
      }
    });
  }

  /**
   * update local stream to others
   * @param otherPeers: string[] - the rtcIds of other peers
   */
  updateLocalStreamToOthers(otherPeers: string[]): Promise<void> {
    let _tasks = [];
    _.forEach(otherPeers, (rtcId: string) => {
      const ep = EndpointService.getSharedInstance().getEndpointById(rtcId);
      _tasks.push(
        this.getTransmittingStreams(rtcId).then((streams: MediaStream[]) => {
          let _subtasks = [];
          // media tracks
          let audio0 = streams[0] ? streams[0].getAudioTracks()[0] : null;
          let audio1 = streams[1] ? streams[1].getAudioTracks()[0] : null;
          let video0 = streams[0] ? streams[0].getVideoTracks()[0] : null;
          let video1 = streams[1] ? streams[1].getVideoTracks()[0] : null;
          var peerConnection = this.getPeerConnectionById(rtcId);
          if (peerConnection) {
            // audio senders
            let existingAudioSenders = _.filter(peerConnection.getSenders(), (sender: any) => {
              return sender.track && sender.track.kind === "audio";
            });
            // video senders
            let existingVideoSenders = _.filter(peerConnection.getSenders(), (sender: any) => {
              return sender.track && sender.track.kind === "video";
            });
            let audioSender0 = existingAudioSenders[0];
            let audioSender1 = existingAudioSenders[1];
            let videoSender0 = existingVideoSenders[0];
            let videoSender1 = existingVideoSenders[1];
            // replace or add track to peer connection
            if (audio0) {
              _subtasks.push(MediaUtil.updateTrackInSender(audio0, streams[0], peerConnection, audioSender0));
            } else if (audioSender0) {
              MediaUtil.removeSenderFromPeerConnection(audioSender0, peerConnection);
            }
            if (audio1) {
              _subtasks.push(MediaUtil.updateTrackInSender(audio1, streams[1], peerConnection, audioSender1));
            } else if (audioSender1) {
              MediaUtil.removeSenderFromPeerConnection(audioSender1, peerConnection);
            }
            if (video0) {
              _subtasks.push(MediaUtil.updateTrackInSender(video0, streams[0], peerConnection, videoSender0));
            } else if (videoSender0) {
              MediaUtil.removeSenderFromPeerConnection(videoSender0, peerConnection);
            }
            if (video1) {
              _subtasks.push(MediaUtil.updateTrackInSender(video1, streams[1], peerConnection, videoSender1));
            } else if (videoSender1) {
              MediaUtil.removeSenderFromPeerConnection(videoSender1, peerConnection);
            }
          }
          return Promise.all(_subtasks);
        })
      );
    });
    return Promise.all(_tasks).then(() =>
    {
      return Promise.resolve();
    });
  }

  /**
   * trigger renegotiate with other peer
   * @param rtcId: string - the rtcId of the other peer
   */
  renegotiate(rtcId: string, enableICERestart: boolean = true, allowReceive: boolean = true) {
    this._easyrtcClient.renegotiate(rtcId, {
      iceRestart: enableICERestart,
      offerToReceiveAudio: allowReceive,
      offerToReceiveVideo: allowReceive,
    });
  }

  /**
   * register file sender
   */
  registerFileSender(rtcId: string): void {
    if (this.rtcClient.fileEventHandler && !this.rtcClient.fileSenders[rtcId]) {
      this.rtcClient.fileSenders[rtcId] = this._easyFileClient.buildFileSender(
        rtcId,
        this.rtcClient.fileEventHandler.fileSendProgressHandler.bind(this.rtcClient.fileEventHandler)
      );
    }
  }

  /**
   * get rtp receivers of peerconnection by rtcId
   */
  getReceivers(rtcId: string): RTCRtpReceiver[] {
    let peerConnection = this.getPeerConnectionById(rtcId);
    return peerConnection ? peerConnection.getReceivers() : [];
  }

  /**
   * gain media access
   */
  gainMediaAccess(): Promise<void> {
    return this.getCameraStream(false)
      .catch((error: Error) => {
        this.rtcClient.alertHandler("GET_MEDIA_FAILED");
        console.error("GET_MEDIA_FAILED", error);
        return Promise.reject(error);
      })
      .then((stream: MediaStream) => {
        return DeviceService.getSharedInstance().getDevices();
      })
      .catch((error: Error) => {
        this.rtcClient.alertHandler("GET_DEVICES_ERROR", error.name + " " + error.message, AlertLevel.error);
        return Promise.reject(error);
      })
      .then(() => {
        this.rtcClient.alertHandler(AlertCode.deviceInfoUpdated, null, AlertLevel.log);
      })
      .then(() => {
        return this.toggleCameraStream(true);
      })
      .then(() => {
        this.rtcClient.alertHandler(AlertCode.mediaStreamLoaded, null, AlertLevel.log);
      })
      .catch((error: Error) => {
        this.rtcClient.alertHandler("GET_MEDIA_FAILED");
        return Promise.reject(error);
      })
      .finally(() => {
        this.mediaPermissionsCheck();
      });
  }

  /**
   * get the current socketio socket
   */
  getWebSocket() {
    return this._easyrtcClient.webSocket;
  }

  /**
   * check if is connected with server
   */
  isServerConnected(): boolean {
    return this._easyrtcClient.webSocketConnected;
  }

  /**
   * check if a full reconnect is required
   */
  isReconnectRequired(rtcId: string) {
    let pc = this.getPeerConnectionById(rtcId);
    if (!pc) {
      return;
    }
    return pc.iceConnectionState === "closed" || pc.connectionState === "closed";
  }

  /**
   * check if reinvite is required
   */
  isReinviteRequired(rtcId: string) {
    let pc = this.getPeerConnectionById(rtcId);
    if (!pc) {
      return;
    }
    return pc.iceConnectionState === "failed" || pc.connectionState === "failed";
  }

  /**
   * register peerconnection event handlers
   */
  registerPCEventHandlers(rtcId: string, pc: any) {
    if (!rtcId || !pc) {
      return;
    }
    pc.oniceconnectionstatechange = (event) => {
      var eventTarget = event.currentTarget || event.target || pc,
        connState = eventTarget.iceConnectionState || "unknown";
      this.rtcClient.connectionEventHandler.iceStateChangeListener(rtcId, pc, connState);
    };

    pc.onicegatheringstatechange = (event) => {
      this.rtcClient.connectionEventHandler.iceGatheringStateChangeListener(rtcId, pc);
    };

    pc.onnegotiationneeded = () => {
      this.waitForServerConnected(this.renegotiate.bind(this, rtcId));
    };

    pc.onconnectionstatechange = (event) => {
      this.rtcClient.connectionEventHandler.connectionStateChangeListener(rtcId, pc);
    };

    pc.ontrack = (event: any) => {
      this.rtcClient.streamEventHandler.streamAcceptListener(rtcId, event.streams[0]);
    };
  }

  /**
   * Should this even be a promise?
   * @returns the first audio track from the current camera stream.
   */
  getAudioStream(): Promise<MediaStream> {
    let audioStream = new MediaStream();
    audioStream.addTrack(this.rtcClient.cameraStream.getAudioTracks()[0]);
    audioStream["streamName"] = "primary-stream";
    this.register3rdPartyLocalMediaStream(audioStream, audioStream["streamName"]);
    return Promise.resolve(audioStream);
  }

  /**
   * Find the streams to send / are sending to a specific endpoint.
   * @param rtcId - The ID of the target to fetch our streams for.
   * @returns Returns up to two streams that are currently transmitting or should be transmitting to a specified target.
   */
  getTransmittingStreams(rtcId: string): Promise<MediaStream[]> {
    let targetEp: IEndpoint = EndpointService.getSharedInstance().getEndpointById(rtcId);
    // determine primary video source
    let primaryVideoStream = new MediaStream();
    if (this.broadcastStream && targetEp?.transmitMode == VideoMediaConnectionMode.Broadcasting) {
      if (this.broadcastStream.getAudioTracks()?.length) {
        primaryVideoStream.addTrack(this.broadcastStream.getAudioTracks()[0]);
      }
      if (this.broadcastStream.getVideoTracks()?.length) {
        primaryVideoStream.addTrack(this.broadcastStream.getVideoTracks()[0]);
      }
    }
    else if (
      this.rtcClient.screenShareEnabled &&
      this.rtcClient.screenCaptureStream &&
      !this.rtcClient.supportDualChannel
    ) {
      if (this.rtcClient.secondaryStream && this.rtcClient.secondaryStream.getVideoTracks()[0]) {
        primaryVideoStream.addTrack(this.rtcClient.secondaryStream.getVideoTracks()[0]);
      }
      if (
        this.rtcClient.transmittingAudioMixDestinationNode &&
        this.rtcClient.transmittingAudioMixDestinationNode.stream.getAudioTracks()[0]
      ) {
        // prefer send mixed audio
        primaryVideoStream.addTrack(this.rtcClient.transmittingAudioMixDestinationNode.stream.getAudioTracks()[0]);
      } else if (this.rtcClient.screenCaptureStream && this.rtcClient.screenCaptureStream.getAudioTracks()[0]) {
        // second preference to send screen audio
        primaryVideoStream.addTrack(this.rtcClient.screenCaptureStream.getAudioTracks()[0]);
      } else if (this.rtcClient.cameraStream && this.rtcClient.cameraStream.getAudioTracks()[0]) {
        // third preference to send mic audio
        primaryVideoStream.addTrack(this.rtcClient.cameraStream.getAudioTracks()[0]);
      }
    } else if (this.rtcClient.videoMuted && this.rtcClient.avatarStream) {
      if (this.rtcClient.cameraStream) {
        primaryVideoStream.addTrack(this.rtcClient.cameraStream.getAudioTracks()[0]);
      }
      primaryVideoStream.addTrack(this.rtcClient.avatarStream.getVideoTracks()[0]);
    } else if (this.rtcClient.filteredStreamEnabled && this.rtcClient.filteredStream) {
      if (this.rtcClient.cameraStream) {
        primaryVideoStream.addTrack(this.rtcClient.cameraStream.getAudioTracks()[0]);
      }
      primaryVideoStream.addTrack(this.rtcClient.filteredStream.getVideoTracks()[0]);
    } else if (this.rtcClient.cameraStream) {
      primaryVideoStream.addTrack(this.rtcClient.cameraStream.getAudioTracks()[0]);
      primaryVideoStream.addTrack(this.rtcClient.cameraStream.getVideoTracks()[0]);
    }
    primaryVideoStream["streamName"] = "primary-stream";
    this.register3rdPartyLocalMediaStream(primaryVideoStream, primaryVideoStream["streamName"]);
    // determine secondary video source
    let secondaryVideoStream;
    if (this.rtcClient.supportDualChannel) {
      if (this.rtcClient.screenShareEnabled) {
        secondaryVideoStream = new MediaStream();
        if (this.rtcClient.secondaryStream && this.rtcClient.secondaryStream.getVideoTracks()[0]) {
          secondaryVideoStream.addTrack(this.rtcClient.secondaryStream.getVideoTracks()[0]);
        }
        if (this.rtcClient.screenCaptureStream && this.rtcClient.screenCaptureStream.getAudioTracks()[0]) {
          secondaryVideoStream.addTrack(this.rtcClient.screenCaptureStream.getAudioTracks()[0]);
        }
      } else if (this.rtcClient.secondaryCameraEnabled && this.rtcClient.secondaryCameraStream) {
        secondaryVideoStream = new MediaStream();
        secondaryVideoStream.addTrack(this.rtcClient.secondaryCameraStream.getAudioTracks()[0]);
        secondaryVideoStream.addTrack(this.rtcClient.secondaryCameraStream.getVideoTracks()[0]);
      }
    }
    if (secondaryVideoStream) {
      secondaryVideoStream["streamName"] = "secondary-stream";
      this.register3rdPartyLocalMediaStream(secondaryVideoStream, secondaryVideoStream["streamName"]);
    }
    return Promise.resolve([primaryVideoStream, secondaryVideoStream]);
  }

  /**
   * wait to execute until server connected
   */
  waitForServerConnected(callback: () => any, delay: number = 0.5 * 1000) {
    if (ConferenceService.getSharedInstance().isServerTimeoutFired) {
      // We need to abort everything!
      console.log("ABORT PENDING MESSAGE!");
      return;
    }
    if (this.isServerConnected()) {
      callback();
    } else {
      let waitTimer = setTimeout(() => {
        clearTimeout(waitTimer);
        this.waitForServerConnected(callback, delay);
      }, delay);
    }
  }

  /**
   * get filtered camera stream
   * @param forceNew: boolean - flag if force to get new stream
   */
  getFilteredStream(forceNew: boolean): Promise<MediaStream> {
    if (!forceNew && this.rtcClient.filteredStream) {
      if (
        this.rtcClient.filteredStream.getVideoTracks().length > 0 &&
        this.rtcClient.filteredStream.getVideoTracks()[0].enabled &&
        this.rtcClient.filteredStream.getVideoTracks()[0].readyState === "live"
      ) {
        return Promise.resolve(this.rtcClient.filteredStream);
      }
    }
    this.rtcClient.filteredStream = null;
    this.rtcClient.filteredStream = new MediaStream();
    return this.getCameraStreamPromise(forceNew, true).then((cameraStream) => {
      this.rtcClient.videoFilter.stream = cameraStream;
      return this.rtcClient.videoFilter.createFilteredStream().then((filteredStream: MediaStream) => {
        this.rtcClient.filteredStream = filteredStream;
        this.rtcClient.filteredStream.streamName = "filtered-stream";
        this.register3rdPartyLocalMediaStream(filteredStream, this.rtcClient.filteredStream.streamName);
        return Promise.resolve(filteredStream);
      });
    });
  }

  /**
   * toggle filtered camera stream vs original camera stream
   */
  toggleCameraVirtualBackground(enabled: boolean, forceNew: boolean = false): void {
    if (!this.rtcClient.cameraStream) {
      this.rtcClient.filteredStreamEnabled = false;
      return;
    }
    this.rtcClient.filteredStreamEnabled = enabled;
    if (!enabled && this.rtcClient.filteredStream == null) {
      return;
    }
    if (this.rtcClient.filteredStreamEnabled) {
      this.getFilteredStream(forceNew)
      .then((stream: any) => {
        if (this.rtcClient.streamEventHandler) {
          this.rtcClient.streamEventHandler.updateLocalStreamListener();
        }
      })
      .catch((error: Error) => {
        this.rtcClient.alertHandler(
          "Failed to update local stream to filtered stream: ",
          error ? error.message : undefined
        );
      });
    } else {
      this.rtcClient.videoFilter.stopFilteredStream();
      delete this.rtcClient.filteredStream;
      if (this.rtcClient.streamEventHandler) {
        this.rtcClient.streamEventHandler.updateLocalStreamListener();
      }
    }
  }

  getContentType(rtcId, trackId) {
    return null;
  }

  /**
  * Set the content stream. A special stream for sharing multimedia content
  * from the content presenter.
  */
  public shareContentStream(contentStream: MediaStream, failureHandler?: (error: Error) => void): void
  {
    this.rtcClient.contentStream = contentStream;
    this.updateLocalStream("content", true)
    .catch((error: Error) => {
      console.error(error);
      this.rtcClient.alertHandler(
        "FAIL_TO_Get_Content_SCREEN",
        "Failed to content screen.",
        AlertLevel.warning
      );
      throw error;
    }).then((stream: MediaStream) => {
      this.rtcClient.showContentEnabled = true;
      MediaUtil.applyConstraintsOnStream(this.rtcClient.cameraStream, this.rtcClient, false);
      if (this.rtcClient.streamEventHandler) {
        this.rtcClient.streamEventHandler.updateLocalStreamListener();
      }
    })
    .catch((error: Error) => {
      console.error(error);
      if(failureHandler)
      {
        failureHandler(error);
      }
      else
      {
        this.stopContentSharing();
      }
    });
  }

  /**
  * stop content sharing.
  */
  public stopContentSharing()
  {
    this.rtcClient.showContentEnabled = false;
    MediaUtil.stopStream(this.rtcClient.contentStream);
    this.rtcClient.contentStream = null;
    this.toggleCameraStream(true)
    .catch((error) => {
      console.log("stopContentSharing - Failed to toggleCameraStream:", error);
    });
  }

  setRTCConfig(rtcConfig: IRTCConfig) {
    if (rtcConfig && rtcConfig.bundlePolicy) {
      this.rtcClient.peerconnectionConfig.bundlePolicy = rtcConfig.bundlePolicy;
    }
  }

  checkMediaPermissions(constraints: any): Promise<MediaStream> {
    return new Promise((resolve: (stream: MediaStream) => void, reject: (error: string) => void) => {
      this._easyrtcClient.checkMediaPermissions(
        constraints,
        (stream: any) => {
          resolve(stream);
        },
        (errorCode: string, errorMessage: string) => {
          reject(errorMessage);
        }
      );
    });
  }

  mediaPermissionsCheck() {
    let previousAccessible = { cameraAccessible: this.rtcClient.cameraAccessible, microphoneAccessible: this.rtcClient.microphoneAccessible };
    let newAccessible = { cameraAccessible: this.rtcClient.cameraAccessible, microphoneAccessible: this.rtcClient.microphoneAccessible };
    let constraints = MediaUtil.getUserMediaConstraints(this.rtcClient, false, true, true, true);
    this.checkMediaPermissions(constraints)
      .then(() => {
        newAccessible.cameraAccessible = true;
        newAccessible.microphoneAccessible = true;
        return Promise.resolve();
      })
      .catch((error: Error) => {
        // try audio only media source
        newAccessible.cameraAccessible = false;
        newAccessible.microphoneAccessible = true;
        constraints = MediaUtil.getUserMediaConstraints(this.rtcClient, false, true, false, true);
        return this.checkMediaPermissions(constraints)
          .then(() => {
            return Promise.resolve();
          })
      })
      .catch((error: Error) => {
        // try for avatar only media source
        newAccessible.cameraAccessible = false;
        newAccessible.microphoneAccessible = false;
      })
      .then(() => {
        if (previousAccessible.cameraAccessible != newAccessible.cameraAccessible ||
            previousAccessible.microphoneAccessible != newAccessible.microphoneAccessible) {
          return this.getCameraStreamPromise(true, true)
            .then(() => {
              this.toggleCameraStream(true);
            })
            .catch((error: Error) => {
              console.log("mediaPermissionsCheck getCameraStreamPromise error");
            });
        }
        else {
          return Promise.resolve();
        }
      })
      .finally(() => {
        this.rtcClient.mediaPermissionsCheckTimer = setTimeout(() => {
          clearTimeout(this.rtcClient.mediaPermissionsCheckTimer);
          this.mediaPermissionsCheck();
        }, 10000);
      });
  }

}
