/**
 * Copyright Compunetix Incorporated 2020
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, OnInit } from "@angular/core";
import { ActionType, Dispatcher } from "client/scripts/shared/services/dispatcher";
import { Companion, IChatRoom, IChatRoomService, ChatRoom, IEndpointService, PresenceStatus, IEndpoint, IConferenceExpanded} from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { ParticipantList } from "../participant/participant-list";
@Component({
  selector: "chatroom-select-participant",
  templateUrl: "./chatroom-selectParticipant.component.html",
})
/**
 * Chat room component
 */
export class ChatRoomSelectParticipantComponent implements OnInit {
  currentRoomId: string;
  endpointSelectListShown: boolean;
  endpointSelectListRoom: IChatRoom;
  isCreatingNewRoom: boolean = false;
  lists: ParticipantList[] = [];
  @Input() isMobileApp: boolean;
  @Input() listTypes: string[];
  @Input() conference: IConferenceExpanded;
  private endpointService: IEndpointService = Companion.getEndpointService();
  public chatRoomService: IChatRoomService = Companion.getChatRoomService();

  constructor(public localizationService: LocalizationService) {
    Dispatcher.register(ActionType.ToggleChatParticipantSelectList, this.toggleEndpointSelectListPanel.bind(this));
  }

  /**
   * count selected
   */
  get countSelected(): number {
    let result = 0;
    _.forEach(this.lists, (list: ParticipantList) => {
      _.forEach(list._endpoints, (ep: any) => {
        if (ep.selected) {
          result++;
        }
      });
    });
    return result;
  }

  /**
   * component load handler
   */
  ngOnInit() {
    this.updateLists();
  }

  /**
   * update list
   */
  updateLists() {
    for (var i = 0; i < this.listTypes.length; ++i) {
      let participantList: ParticipantList = ParticipantList.getParticipantList(this.listTypes[i], this.conference,
        this.localizationService.myLocalizationData);
      let existingList: ParticipantList = _.find(this.lists, {listType: this.listTypes[i]});

      // filter out offline endpoints...
      participantList._endpoints = _.filter(participantList._endpoints, (ep: IEndpoint) => {
        return ep.status !== PresenceStatus.offline;
      });

      if (existingList) {
        existingList._endpoints = _.intersectionBy(participantList._endpoints, existingList._endpoints, "rtcId");
        existingList._endpoints = _.unionBy(participantList._endpoints, existingList._endpoints, "rtcId");
      } else {

        this.lists.push(participantList);
      }
    }
    this.updateSelected();
  }

  /**
   * update status if already a joined endpoint in the chat room
   */
  updateSelected(room: IChatRoom = this.endpointSelectListRoom) {
    if (!room) {
      return;
    }
    _.forEach(this.lists, (list: ParticipantList) => {
      _.forEach(list._endpoints, (ep: any) => {
        if (_.find(room.targets, (tgt) => (tgt.rtcId == ep.rtcId))) {
          ep.selected = true;
        } else {
          ep.selected = false;
        }
      });
    });
  }

  /**
   * create and join a new chat room with selected others
   */
  createAndJoinWithSelected(event: any) {
    event.stopPropagation();
    this.endpointSelectListRoom.targets = [];
    _.forEach(this.lists, (list: ParticipantList) => {
      _.forEach(list._endpoints, (ep: any) => {
        if (ep.selected && ep.rtcId !== this.endpointService.myEndpoint.rtcId) {
          this.endpointSelectListRoom.targets.push(ep);
        }
      });
    });
    if (this.endpointSelectListRoom.targets.length > 0) {
      let targets = _.uniqBy(this.endpointSelectListRoom.targets, "rtcId");
      let room = this.chatRoomService.findExistingRoomByTargets(targets);
      if (!room) {
        room = this.chatRoomService.createChatRoomByMessageTargets(targets, this.endpointService.myEndpoint.rtcId);
      }
      this.chatRoomService.addEndpointToRoom(this.endpointService.myEndpoint, room);
      this.chatRoomService.currentRoom = room;
      Dispatcher.dispatch(ActionType.ResetUnreadMessageCountOfCurrentRoom);
      this.endpointSelectListShown = false;
      Dispatcher.dispatch(ActionType.ToggleChatPanel, { forceOpen: true });
    }
  }

  /**
   * open or close endpoint selection panel
   * @param room: IChatRoom - the room which endpoints belongs to
   */
  toggleEndpointSelectListPanel(payload: {room?: IChatRoom, targetId: string, forceOpen?: boolean, forceClose?: boolean}): void {
    this.isCreatingNewRoom = payload == null || payload.room == null;
    if (!this.isCreatingNewRoom) {
      this.endpointSelectListRoom = new ChatRoom(payload.room.targets, [], payload.room.ownerRtcId);
    } else {
      this.endpointSelectListRoom = new ChatRoom([this.endpointService.myEndpoint], [], this.endpointService.myEndpoint.rtcId);
    }
    this.endpointSelectListShown = !this.endpointSelectListShown;
    if (payload && payload.forceOpen) {
      this.endpointSelectListShown = true;
    }
    if (payload && payload.forceClose) {
      this.endpointSelectListShown = false;
    }
    if (this.endpointSelectListShown) {
      this.endpointSelectListShown = true;
      this.updateLists();
      if (payload && payload.targetId) {
        let popover = new Tether({
          element: jQuery("#endpoint-select-list-panel"),
          target: jQuery("#" + payload.targetId),
          attachment: "top left",
          targetAttachment: "top right"
        });
      }
    }
  }

  /**
   * close select participant window without further action
   */
  closePanel() {
    this.endpointSelectListShown = false;
  }
}
