<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->

<div
  id="video-controls"
  class="video-controls d-print-none"
>
  <div
    class="control position-4 d-none"
    (mouseenter)="onMouseEnter($event)"
    (mouseleave)="onMouseLeave($event)"
  >
    <div class="range-slider pull-center animated">
      <input
        id="volume-control"
        type="range"
        orient="vertical"
        min="0"
        max="1"
        step="0.01"
        [(ngModel)]="overallVolume"
        (input)="volumeChange()"
        (change)="volumeChange()"
      />
      <div class="range-slider__bar"></div>
      <div class="range-slider__thumb">
        {{Math.round(overallVolume * 100)}}%</div>
    </div>
    <a
      tabindex
      class="btn text-white text-center volume-toggle"
      (click)="toggleMuteAudio()"
    >
      <i
        class="fas fa-volume-up"
        aria-hidden="true"
        [hidden]="isSoundMuted"
      ></i>
      <i
        class="fas fa-volume-off"
        aria-hidden="true"
        [hidden]="!isSoundMuted"
      ></i>
    </a>
  </div>
</div>