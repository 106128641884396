/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { NotificationComponent } from "./notification.component";
import { QuillModule } from "ngx-quill";
import { FormsModule } from "@angular/forms";
import { NotificationService } from "./notification.service";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  imports: [BrowserModule, FormsModule, QuillModule, SharedModule],
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
  providers: [NotificationService]
})
export class NotificationModule {}
