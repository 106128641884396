/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  jporter
 */

// Category for general selection
export interface ISkillSet {
  category?: string[];
  // Category for language selection
  language: string[];
}

export interface ISkillsSet {
  categorySubskills?: ICategory[];
  language: string[];
}

export interface ICategory {
  name: string;
  subskills?: ICategory[];
}

export interface ISkillTags {
  // Specified skill tag for general selection
  category: string;
  // Specified skill tag for language selection
  language: string;
}
