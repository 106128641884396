import { IPasswordPolicy } from "../group/group.interface";
const _ = require("lodash");

export class PasswordChecker {
  // make the patterns
  static numberRegex = new RegExp("[\\d]", "g");
  static lowercaseRegex = new RegExp("[a-z]", "g");
  static uppercaseRegex = new RegExp("[A-Z]", "g");
  static specialCharRegex = new RegExp("[\\W_]", "g");

  /**
   * @returns Checks a password against the policy, and returns a string indicating where
   * it doesn't conform, null if the password conforms to the policy,
   */
  static CheckPasswordAgainstPolicy(policy: IPasswordPolicy, password: string): string {
    if (policy == null || password == null) {
      return null;
    }
    // check min length
    if (policy.minimumLength > 0 && password.length < policy.minimumLength) {
      return "The password is too short";
    }

    // check max length
    if (policy.maximumLength > 0 && password.length > policy.maximumLength) {
      return "The password is too long";
    }

    // check num of numbers
    if (
      policy.minimumNumbers > 0 &&
      (password.match(PasswordChecker.numberRegex) == null ||
        password.match(PasswordChecker.numberRegex).length < policy.minimumNumbers)
    ) {
      return "The password contains too few numbers";
    }

    // check uppercase
    if (
      policy.minimumUppercase > 0 &&
      (password.match(PasswordChecker.uppercaseRegex) == null ||
        password.match(PasswordChecker.uppercaseRegex).length < policy.minimumUppercase)
    ) {
      return "The password contains too few uppercase characters";
    }

    // check lowercase
    if (
      policy.minimumLowercase > 0 &&
      (password.match(PasswordChecker.lowercaseRegex) == null ||
        password.match(PasswordChecker.lowercaseRegex).length < policy.minimumLowercase)
    ) {
      return "The password contains too few lowercase characters";
    }

    // check special chars
    if (
      policy.minimumSpecialCharacters > 0 &&
      (password.match(PasswordChecker.specialCharRegex) == null ||
        password.match(PasswordChecker.specialCharRegex).length < policy.minimumSpecialCharacters)
    ) {
      return "The password contains too few special characters";
    }

    let hasForbidden: boolean = false;
    // make sure the password does not contain any forbidden characters
    _.forEach(policy.forbiddenCharacters, (forbidden: string) => {
      if (password.includes(forbidden)) {
        hasForbidden = true;
        return false;
      }
    });

    if (hasForbidden) {
      return "The password contains a forbidden character";
    }

    return null;
  }
}
