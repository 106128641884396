/**
 * Copyright Compunetix Incorporated 2017-2014
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
export interface IParameter {
  key: string;
  title?: string;
  type: string;
  value?: any;
  options?: any;
  displaySection?: number;
  displayOrder?: number;
  displayInLine?: boolean;
  required?: boolean;
  maxLength?: number;
  disabled?: boolean;
  column?: number;
  pattern?: string;
  errorMessage?: string;
  isHidden?: boolean;
  valueOnDelete?: string;
  tooltip?: string;
  lockable?: boolean;
}

export enum ParameterType {
  number,
  string,
  date,
  time,
  checkbox,
  checkbox_list,
  guest_host_feature_toggle,
  color,
  image,
  video,
  list,
  true_false_value,
  key_value_list,
  dropdown,
  account,
  account_list,
  third_party_auth_list,
  hidden,
  header,
  size,
  size_list,
  email,
  phone,
  auto_complete_list,
  tree,
  label,
  theme_package,
  password,
  wysiwyg,
  queue_screen_list,
  textarea,
  remote_desktop_conn_list,
  dynamic_tree,
  category_subskills_new,
  multiselect_tree
}

export interface IAccountParameter {
  host: string;
  username: string;
  password: string;
}

export declare interface ISize {
  width: number;
  height: number;
}
