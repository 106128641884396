/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { IChatRoom, IChatRoomService, Companion } from "companion";
@Component({
  selector: "chatroom-roomlist",
  templateUrl: "chatroom-roomlist.template.html"
})

/**
 * Chat room list view
 */
export class ChatRoomList {
  @Input() inEditState: boolean;
  @Input() selectedRoom: IChatRoom;
  @Output("enter") enterEmitter: EventEmitter<IChatRoom> = new EventEmitter<IChatRoom>();
  @Output("delete") deleteEmitter: EventEmitter<IChatRoom> = new EventEmitter<IChatRoom>();

  chatRoomService: IChatRoomService = Companion.getChatRoomService();

  /**
   * enter a chat room
   */
  enter(room: IChatRoom): void {
    if (!this.inEditState) {
      this.enterEmitter.emit(room);
    }
  }

  /**
   * delete a chat room
   */
  delete(room: IChatRoom): void {
    this.deleteEmitter.emit(room);
  }
}
