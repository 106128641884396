/**
 * Copyright Compunetix Incorporated 2016
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";

/**
 * Translate predefined device names.
 * @example Usage:
 * ```html
 * <p>
 *   {{ device.label | deviceNameTranslate }}
 * </p>
 * ```
 */
@Pipe({ name: "deviceNameTranslate" })
export class DeviceNameTranslatePipe {
  transform(label: string, languageCode: string): any {
    if (!label || label.length === 0) {
      return label;
    }
    for (var key in this.localizationService.myLocalizationData.deviceNames) {
      if (label.startsWith(key)) {
        var value = this.localizationService.myLocalizationData.deviceNames[key];
        return label.replace(key, value);
      }
    }
    return label;
  }
  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }
}
