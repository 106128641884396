/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Component } from "@angular/core";
import { GroupManagementService, GroupFormKey } from "./group-management.service";
import { FlavorService } from "../flavor-factory/flavor/flavor.service";
import { IParameter, IGroupStatus, IGroup, IReportDefinition } from "companion";
import { TreeViewItem } from "../shared/components/tree-view/tree-item";
import { Dispatcher, ActionType } from "client/scripts/shared/services/dispatcher";
import { FormService } from "./../form/form.service";
import { AlertService } from "./../alert/alert.service";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "group-form",
  templateUrl: "./group-details.template.html"
})
export class GroupDetailsComponent {
  submitted: boolean;
  failed: boolean;
  errorMessage: string;

  roots: TreeViewItem[];
  themes: string[];

  /**
   * flag if is editing mode
   */
  isEditing: boolean;

  /**
   * current form data
   */
  group: IGroup;

  /**
   * current form for the selected group
   */
  currentForm: FormGroup;

  /**
   * current form for the selected group
   */
  currentParameters: { [key: string]: IParameter };

  resetForm() {
    this.submitted = false;
    this.failed = false;
    this.errorMessage = null;
  }

  treeSelect(item: TreeViewItem) {
    this.group.parentId = item.value._id;
  }

  loadReportDefinitions() {
    this.groupManagementService
    .getDefaultReportDefinitions()
    .then((defaultReportDefinitions: { [key: string]: IReportDefinition }) => {
      this.group.report_definitions = this.group.report_definitions || {};
      _.forEach(defaultReportDefinitions, (reportDefinition: IReportDefinition, key: string) => {
        this.group.report_definitions[key] = this.group.report_definitions[key] || reportDefinition;
      });
    })
    .catch((err: Error) => {
      console.error("Failed to load default report definitions", err);
    });
  }

  /**
   * load form
   */
load(group?: IGroup): Promise<void> {
  if (group) {
    this.group = group;
    return this.groupManagementService
    .convertGroupToParameters(group)
    .then((parameters: { [key: string]: IParameter }) => {
      this.currentParameters = parameters;
      this.currentForm = FormService.toFormGroup(this.currentParameters);
      this.currentForm.valueChanges.subscribe((value: any) => {
        this.valueChanges(value);
      });
    })
    .catch((err: Error) => {
      console.error(err);
      this.alertService.createAlert(err.message);
    });
  } else {
    return this.groupManagementService
    .getDefaultGroupFormParameters()
    .then((parameters: { [key: string]: IParameter }) => {
      this.currentParameters = parameters;
      this.addDefaultStatusToCurrentParameter(GroupFormKey.messaging_config_status);
      this.addDefaultStatusToCurrentParameter(GroupFormKey.message_template_status);
      this.addDefaultStatusToCurrentParameter(GroupFormKey.password_status);
      this.addDefaultStatusToCurrentParameter(GroupFormKey.auth_third_party_status);
      this.addDefaultStatusToCurrentParameter(GroupFormKey.twilio_account_status);
      this.addDefaultStatusToCurrentParameter(GroupFormKey.skill_set_status);
      this.currentForm = FormService.toFormGroup(this.currentParameters);
      this.currentForm.valueChanges.subscribe((value: any) => {
        this.valueChanges(value);
      });
    })
    .catch((err: Error) => {
      console.error(err);
      this.alertService.createAlert(err.message);
    });
  }
}

  /**
   * Add default status to current parameter
   * @param key
   */
  addDefaultStatusToCurrentParameter(key: GroupFormKey) {
    const parameter = this.currentParameters[GroupFormKey[key]];
    if (parameter) {
      parameter.value = IGroupStatus.inherited;
      this.groupManagementService.loadStatus(key, this.currentParameters);
    }
  }

  /**
   * group save
   */
  save() {
    FormService.updateParametersByForm(this.currentForm, this.currentParameters);
    Dispatcher.dispatch(ActionType.SubmitGroupForm, { isEditing: this.isEditing, data: this.currentParameters });
  }

  OpenGroupForm(payload: any) {
    this.isEditing = payload.isEditing;
    this.load(payload.group).then(() => {
      Dispatcher.dispatch(ActionType.OpenModalDialog, "group-form");
    }).catch((error: any) => {
      // should be handled in load
    });
    // focus the name control
    if ($("#group_name")[0]) { $("#group_name")[0].focus(); }
  }

  valueChanges(value: any) {
    // listen for category or language adds here...
    if (value?.skill_set) {
      this.currentParameters[GroupFormKey[GroupFormKey.skill_set_default_category]].options = this.groupManagementService.getCategoriesList();
    }

    if(value?.skill_set?.skill_set_languages)
    {
      this.currentParameters[GroupFormKey[GroupFormKey.skill_set_default_language]].options = value?.skill_set?.skill_set_languages;
    }
  }

  delete() {
    Dispatcher.dispatch(ActionType.HideModalDialog, "group-form");
    AlertService.createAlertWithButtons("Are you sure to delete this group?", {
      confirm: {
        label: "Delete",
        className: "btn-danger"
      },
      cancel: {
        label: "Cancel",
        className: "btn-default"
      }
    }).then((result: boolean) => {
      if (result) {
        Dispatcher.dispatch(ActionType.DeleteGroup, { group: this.group });
      }
    });
  }

  cancel() {
    Dispatcher.dispatch(ActionType.HideModalDialog, "group-form");
  }

  ngOnInit() {}

  constructor(
    private groupManagementService: GroupManagementService,
    private flavorService: FlavorService,
    private alertService: AlertService
  ) {
    Dispatcher.register(ActionType.OpenGroupForm, this.OpenGroupForm.bind(this));
    Dispatcher.register(ActionType.AddCategorySubskill, this.AddCategorySubskill.bind(this));
  }

  formValueChanged(fieldKey: string) {
    const statusSuffix = GroupManagementService.STATUS_SUFFIX;
    const enableSuffix = GroupManagementService.ENABLE_SUFFIX;
    if (fieldKey.endsWith(statusSuffix)) {
      const section = fieldKey.replace(statusSuffix, "");
      const enabledKey = section + enableSuffix;
      const sectionKey = enabledKey.substring(0, enabledKey.lastIndexOf(enableSuffix));
      const currentValue = this.currentForm.value[sectionKey][fieldKey];

      const control = this.currentForm.controls[sectionKey] as FormGroup;
      if (!control) {
        return;
      }

      this.groupManagementService.updateVisibilityFields(section, currentValue, this.currentParameters);
    }
  }

  AddCategorySubskill(payload: any) {
    this.groupManagementService.AddCategorySubskill(payload?.newCategorySubskillName, payload?.parentCategorySubskillName);
  }

}
