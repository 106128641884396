/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { differenceInDays, differenceInMonths, differenceInWeeks } from "date-fns";
import {BsDatepickerConfig, BsDatepickerViewMode} from "ngx-bootstrap/datepicker";

export enum PickerMode {
  day = "day",
  week = "week",
  month = "month"
}

export interface IDateRange {
  mode: PickerMode;
  range: Date[];
}

// Time range picker, used in Agent's and Customer's
@Component({
  selector: "range-picker",
  templateUrl: "./range-picker.component.html",
  styleUrls: ["./range-picker.component.scss"],
})
export class RangePickerComponent implements OnInit {

  @Input() selectedAggregation: PickerMode;
  @Input() selectedDates: Date[];
  @Output("rangePickerChanged") rangePickerChangedEmitter = new EventEmitter<IDateRange>();

  rangePickerConfig: Partial<BsDatepickerConfig>;
  pickerMode = PickerMode;
  today: Date;

  boundary = {
    day: 60,
    week: 52,
    month: 24
  };

  ngOnInit() {
    this.changeAggregation(PickerMode.day, false);
  }

  datePickerChanged() {
    this.refresh();
  }

  private validateDates(): string {
    if (this.selectedAggregation === PickerMode.month) {
      this.selectedDates[0] = new Date(this.selectedDates[0].getFullYear(), this.selectedDates[0].getMonth(), 1);
      this.selectedDates[1] = new Date(this.selectedDates[1].getFullYear(), this.selectedDates[1].getMonth() + 1, 0);
    }

    this.today = new Date();
    const start = this.selectedDates[0];
    const end   = this.selectedDates[1];
    const diffDays = differenceInDays(end, start) + 1;
    const diffWeeks = differenceInWeeks(end, start) + 1;
    const diffMonths = differenceInMonths(end, start) + 1;
    let error: string = null;
    switch (this.selectedAggregation) {
      case PickerMode.day:
        if (diffDays > this.boundary.day) {
          error = `The range is too large for day aggregation. You can select up to ${this.boundary.day} days`;
        }
        break;
      case PickerMode.week:
        if (diffWeeks > this.boundary.week) {
          error = `The range is too large for week aggregation. You can select up to ${this.boundary.week} weeks`;
        }
        break;
      case PickerMode.month:
        if (diffMonths > this.boundary.month) {
          error = `The range is too large for month aggregation. You can select up to ${this.boundary.month} months`;
        }
        break;
    }

    return error;
  }

  changeAggregation(mode: PickerMode, refreshData = true) {
    this.today = new Date();

    this.selectedAggregation = mode;
    this.rangePickerConfig = {
      containerClass: "theme-default",
      minMode : PickerMode.day as BsDatepickerViewMode,
      rangeInputFormat: "L"
    };

    if (mode === PickerMode.week) {
      this.rangePickerConfig.rangeInputFormat = "YYYY [W]w";
    }

    if (mode === PickerMode.month) {
      this.rangePickerConfig.minMode = PickerMode.month;
      this.rangePickerConfig.rangeInputFormat = "MMM YYYY";
    }

    if (refreshData) {
      this.refresh();
    }
  }

  refresh() {
    const message = this.validateDates();
    if (message) {
      bootbox.alert({
        message: message
      });
      return;
    }

    this.rangePickerChangedEmitter.emit({
      mode: this.selectedAggregation,
      range: this.selectedDates
    });
  }
}
