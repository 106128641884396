/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Injectable } from "@angular/core";

import { RestService } from "../shared/services/rest.service";
import { ILocalization, Companion, Browser } from "companion";

@Injectable({
  providedIn: "root"
})
export class LocalizationService {
  myLocalizationData: ILocalization = {};
  customStyleSheet: any;
  isLoading: boolean;
  constructor(private restService: RestService) {
    // nothing need to do
  }

  /**
   * get localization data by style and language
   * @param style: string - style name
   * @param language: string - language code
   */
  getLocalizationData(style: string, language?: string): Promise<ILocalization> {
    this.isLoading = true;
    return new Promise((resolve: (data: ILocalization) => any, reject: (err: Error) => any) => {
      this.restService
      .post("/localization", { style: style, language: language })
      .subscribe(
        (data: ILocalization) => {
          if (data) {
            console.log("update localization: "+style+" "+language);
            this.myLocalizationData = data;
            resolve(this.myLocalizationData);
            this.isLoading = false;
            let pageTitle = this.getValueByPath(".page.title");
            if (pageTitle) {
              $("title").html(pageTitle);
            } else {
              $("title").html("");
            }
            if (this.getValueByPath(".page.preferSharpEdgeButton")) {
              if (!this.customStyleSheet) {
                let customStyle: any = document.createElement("style");
                customStyle.appendChild(document.createTextNode(""));
                document.head.appendChild(customStyle);
                this.customStyleSheet = customStyle.sheet;
              }
              this.customStyleSheet.insertRule(".btn {border-radius: 0;}");
            }
            if (this.getValueByPath(".customCSS.applyADAStyles")) {
              $("#ada-custom-css-container").html(this.getADAStyles());
            }
            if (this.getValueByPath(".customCSS.content")) {
              $("#custom-css-container").html(this.getValueByPath(".customCSS.content"));
            }
          } else {
            reject(new Error("GET_LOCALIZATION_DATA_FAIL: " + style + ", " + language));
            this.isLoading = false;
          }
        },
        (error: any) => {
          this.isLoading = false;
          console.error(error);
          reject(new Error("GET_LOCALIZATION_DATA_FAIL: " + style + ", " + language));
        }
      );
    });
  }

  /**
   * get acceptable theme names by passcode
   * @param passcode: string - conference passcode or hash
   */
  getAcceptableThemesByPasscode(passcode: string): Promise<string[]> {
    return new Promise((resolve: (themes: string[]) => any, reject: (err: Error) => any) => {
      this.restService
      .post("/getAcceptableThemesByPasscode", { passcode: passcode })
      .subscribe(
        (themes: string[]) => {
          resolve(_.compact(themes) || []);
        },
        (error: any) => {
          reject(new Error("GET_ACCEPTABLE_THEMES_BY_PASSCODE_FAIL: " + passcode));
        }
      );
    });
  }

  /**
   * get browser not supported message string
   */
  get browserNotSupportedMessage(): string {
    let message = this.getValueByPath(".errorMessages.BROWSER_NOT_SUPPORT");
    if (!message) {
      message = "The browser you are using does not support audio-visual communications.";
    }
    let currentBrowsersInString = "[";
    currentBrowsersInString += " " + Browser.whichBrowser() + "/" + Browser.whichBrowserVersion();
    currentBrowsersInString += " ]";
    message = message.replace(/\[CURRENT_BROWSER\]/gim, currentBrowsersInString);
    let supportedBrowsersInString = "[";
    _.forOwn(Browser.getMinBrowserAndVersion(), (version: number, browser: string) => {
      supportedBrowsersInString += " " + browser + "/" + version;
    });
    supportedBrowsersInString += " ]";
    message = message.replace(/\[SUPPORTED_BROWSERS\]/gim, supportedBrowsersInString);
    return message;
  }

  /**
   * get browser install message string
   */
  get browserInstallMessage(): string {
    let message = this.getValueByPath(".errorMessages.CONFIRM_TO_INSTALL_BROWSER");
    if (!message) {
      message = "Press install button to upgrade or install the compatible browser.";
    }
    return message;
  }

  /**
   * get value by path
   */
  getValueByPath(path: string): any {
    let pathList: string[] = _.compact(path.split("."));
    var current = this.myLocalizationData;
    while (pathList.length) {
      if (current == null || typeof current !== "object") {
        return undefined;
      }
      current = current[pathList.shift()];
    }
    return current;
  }

  /**
   * get ada styles
   */
  getADAStyles(): string {
    return `
      .alert-default, .tag-default { color: #fff; background-color: #333; }
      .alert-danger, .btn-danger, .tag-danger { color: #fff; background-color: #b40000; }
      .alert-success, .btn-success, .tag-success { color: #fff; background-color: #006600; }
      .alert-warning, .btn-warning, .tag-warning { color: #fff; background-color: #734d17; }
      .alert-info, .btn-info, .tag-info { color: #fff; background-color: #185d72; }
      .close { opacity: 1; }
      .form-control::placeholder { color: #333; }
    `;
  }
}
