<!--
    Copyright Compunetix Incorporated 2017-2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<div
  id="conference-fullscreen-container"
  class="vcc"
  [hidden]="ngHidden_MainConferenceFullscreenContainer"
  (click)="hideAllPopovers()"
  [ngClass]="{'kiosk': isKiosk()}"
  [style.color]="
    localizationService.myLocalizationData.page ? localizationService.myLocalizationData.page.fontColor : null
  "
  [style.fontFamily]="
    localizationService.myLocalizationData.page && localizationService.myLocalizationData.page.fontFamily ? localizationService.myLocalizationData.page.fontFamily : 'Roboto, sans-serif'
  "
  [style.background]="
    localizationService.myLocalizationData.page ? localizationService.myLocalizationData.page.backgroundColor : null
  "
  [style.backgroundImage]="
    'url(' + (localizationService.myLocalizationData.page ? localizationService.myLocalizationData.page.backgroundImage : null) + ')'
  "
  (mousemove)="showToolbar()"
  (window:resize)="onResize($event)"
>
  <passcode-modal
    #KioskUnlockPasscodeModal
    (validate)="unlockPasscodeSumitted($event)"
    [modalTitle]="localizationService.getValueByPath('lock_panel.modalTitle') || 'Unlock Passcode'"
    [exitText]="localizationService.getValueByPath('lock_panel.exitText') || 'Cancel'"
    [submitText]="localizationService.getValueByPath('lock_panel.submitText') || 'Unlock'"
    [submitBgColor]="localizationService.getValueByPath('lock_panel.submitBgColor') || 'darkblue'"
    [submitTextColor]="localizationService.getValueByPath('lock_panel.submitTextColor') || 'white'"
    [backgroundColor]="localizationService.getValueByPath('lock_panel.backgroundColor') || 'khaki'"
    [textColor]="localizationService.getValueByPath('lock_panel.textColor') || 'black'"
    [entryPlaceholder]="localizationService.getValueByPath('lock_panel.entryPlaceholder') || 'Passcode'"
    (exit)="unlockPasscodeExited($event)">
  </passcode-modal>
  <top-bar
    *ngIf="ngIf_TopBar"
    [hidden]="ngHidden_TopBar"
    [isLocked]="isLocked"
    [isConfig]="false"
    (languageChanged)="languageChanged($event)"
    (themeFilterChanged)="themeFilterChanged($event)"
    [isLoggedin]="isLoggedin"
    [accessibleThemes]="callCenterService.accessibleThemes"
    [presenceStatus]="endpointService.myEndpoint.status"
    [themeFilter]="this.style"
    [isMobile]="isMobile"
    (login)="login($event)"
    (logout)="logout()"
    (themeChanged)="themeChanged()"
    [isNavShown]="isNavShown"
    [statusChangeable]="viewMode === 'op' || viewMode === 'sp'"
    (updateMyStatus)="updateMyStatusFromToolbar($event)"
  >
  </top-bar>

  <nav-bar
    class="d-print-none"
    [hidden]="ngHidden_NavBar"
    [ngClass]="{'mobile-flex':isMobileApp}"
    [isNavShown]="isNavShown"
    [showAdvance]="viewMode === 'op' || viewMode === 'sp'"
    (toggleMic)="toggleMic($event)"
    (toggleCamera)="toggleCamera($event)"
    (toggleParticipantPanel)="toggleParticipantPanel($event)"
    (toggleIncomingQueue)="toggleIncomingQueue($event)"
    (toggleOperators)="toggleOperatorsPanel($event)"
    (toggleSettingsPanel)="toggleSettingsPanel($event)"
    (toggleScreenCapture)="toggleScreenCapture($event)"
    (toggleSharedFolder)="toggleSharedFolder($event)"
    [unreadFileCount]="unreadFileCount"
    [totalFileCount]="totalFileCount"
    (toggleFullScreen)="toggleFullScreen()"
    (toggleVideoAspect)="toggleVideoAspect()"
    (snapshot)="snapshot()"
    (leaveRoom)="leaveRoomClicked()"
    [connected]="joined"
    [isLocked]="isLocked"
    [viewMode]="viewMode"
    (lock)="lock()"
    (unlock)="unlock()"
    [isRecording]="isRecording"
    (startRecord)="startRecord()"
    (stopRecord)="stopRecord()"
    [isWebRTCSupport]="isWebRTCSupport"
    [isMapShown]="isMapShown"
    (toggleMap)="toggleMap($event)"
    [isMobileApp]="isMobileApp"
    [changedWindowHeight]="changedWindowHeight"
  >
  </nav-bar>

  <dialog-container-modal
    [id]="'helper-wizard'"
    [cssClass]="'modal-lg'"
    [clickOutsideToHide]="false"
  >
    <helper-wizard [isEmbeded]="true"></helper-wizard>
  </dialog-container-modal>

  
  <div 
    *ngIf="ngIf_GuestOfflineStatus"
    class="guest-offline-status"
    [innerText]="guestOfflineMessage"
  >
  </div>

  <div class="d-print-none">
    <loopback
      *ngIf="ngIf_Loopback"
      [conference]="activeConference"
      [statusChangeable]="viewMode === 'op' || viewMode === 'sp'"
      [loopbackOn]="showLoopback"
      [isLocked]="isLocked"
      [isNavShown]="isNavShown && !fullScreenMode"
      [isMobileApp]="isMobileApp"
      (toggleLoopback)="toggleLoopback($event)"
    >
    </loopback>

    <dialog-container-modal
      [id]="'sharedFolderModal'"
      *ngIf="joined"
      [title]="localizationService.getValueByPath('sharedFolder_panel.title') || 'File Sharing'"
    >
      <sharedfolder-panel [conference]="conferenceService.currentActiveConference">
      </sharedfolder-panel>
    </dialog-container-modal>

    <dialog-container-modal
      [id]="'inspectModal'"
      [cssClass]="'modal-lg'"
      *ngIf="joined"
    >
      <inspect-panel
        [isMobileApp]="isMobileApp"
        [conference]="activeConference"
      >
      </inspect-panel>
    </dialog-container-modal>

    <transfer-dialog></transfer-dialog>

    <dialog-container-modal
      [id]="'dialOutModal'"
      [title]="'Dial Out'"
      [dragBoundary]="'#dialOutModal'"
      [dragDisabled]="false"
      [dialogWidth]="'415px'"
    >
      <dial-out
        class="w-100"
        [myEndpoint]="endpointService.myEndpoint"
        [participants]="getKnownEndpoints"
        (dialOutData)="dialTelephoneNumber($event)"
        (hangupDialOutCall)="hangupDialOutCall($event)"
      ></dial-out>
    </dialog-container-modal>

    <dialog-container-modal
      [id]="'keypadModal'"
      [title]="'Keypad'"
      [cssClass]="'modal-sm'"
      [dragDisabled]="false"
      [dragBoundary]="'#keypadModal'"
      [dialogWidth]="'270px'"
      (dialogClosed)="keypadComponent.exit()">
      <keypad
        #keypadComponent
        (pressedKey)="dialVoiceDtmf($event)">
      </keypad>
    </dialog-container-modal>

    <dialog-container-modal
      [id]="'guestInfoModal'"
      [title]="getSafeText(infoDialogEp?.name) + ' Info'"
      [dragDisabled]="true"
      [dialogWidth]="'400px'"
    >
      <guest-info
        [endpoint]="infoDialogEp"
        [canSendLogs]="isLogCollectionEnabled()"
        (requestToSendLogs)="requestToSendLogs($event)"
      ></guest-info>
    </dialog-container-modal>

    <dialog-container-modal
      [id]="'operatorInfoModal'"
      [title]="getSafeText(opInfoDialogEp?.name) + ' Info'"
      [dragDisabled]="true"
      [dialogWidth]="'400px'"
    >
      <operator-info
        [endpoint]="opInfoDialogEp"
        [canSendLogs]="isLogCollectionEnabled()"
        (requestToSendLogs)="requestToSendLogs($event)"
      ></operator-info>
    </dialog-container-modal>

  </div>

  <dialog-container-modal
    [ngStyle]="{'visibility': cropperEnabled ? 'visible' : 'hidden'}"
    [id]="'cropperModal'"
    [title]="'Edit and Save your photo'"
    [dragBoundary]="'#cropperModal'"
    [dragDisabled]="false"
    [dialogWidth]="'80vw'"
    [cssClass]="'cropper-modal'"
    [dialogStyle]="'header'"
  >
    <cropper
      [imageToBeSaved]="imageToBeSaved"
      [imageToBeSavedDimensions]="imageToBeSavedDimensions"
      [cameraRotation]="cameraRotation"
      [autoCrop]="!cropperEnabled"
    ></cropper>
  </dialog-container-modal>

  <button
    *ngIf="isMobileApp"
    [hidden]="isCleanViewMode || isPanelOpen"
    class="mobile btn btn-link text-white bd-toolbar-toggle p-0 animated zoomIn"
    id="menu-button"
    type="button"
    data-toggle="collapse"
    data-target="#bd-main-nav"
    aria-controls="bd-main-nav"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="toggleMenuPanel(false)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="6rem"
      height="5rem"
      focusable="false"
      role="img"
    >
      <title>Menu</title>
      <path
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-miterlimit="10"
        d="M4 7h22M4 15h22M4 23h22"
      >
      </path>
    </svg>

    <label
      class="badge badge-pill badge-{{chatMenuItem.tagColor}}"
      *ngIf="chatMenuItem && chatMenuItem.tagCount > 0"
    >
      {{chatMenuItem.tagCount}}
    </label>
  </button>


  <voice-view
    *ngIf="ngIf_VoiceView"
    [hidden]="ngHidden_VoiceView">
  </voice-view>

  <video-list
    *ngIf="ngIf_VideoList"
    [hidden]="ngHidden_VideoList"      
    [videoEndpoints]="getActiveVideoEndpoints()"
    [autoSelect]="true"
    [isSelfViewSideUp]="isSelfViewSideUp"
    [leftSidebarShown]="
      incomingQueueShown
      || settingsPanelShown
      || chatBoxShown"
    [rightSidebarShown]="operatorsPanelShown"
    [isNavShown]="isNavShown"
    [inPagePanel]="isMapShown || isNotepadShown"
    [isLocked]="isLocked"
    [isMobileApp]="isMobileApp"
    [isOperator]="viewMode !== 'guest'"
    [videoAspect]="userService.currentUser.preferedVideoAspect"
    [isCleanViewMode]="isCleanViewMode"
    [isRecordingActive]="isRecordingActive"
    [videoVolume]="videoVolume"
    [takingPhoto]="takingPhoto"
    (toggleFullScreen)="toggleFullScreen()"
    (toggleCleanViewMode)="isCleanViewMode = !isCleanViewMode"
    (takePic)="requestToTakePhoto($event)"
  >
  </video-list>
  <div
    id="in-page-panel"
    class="full-screen"
    [ngClass]="{'right-sidebar-shown': operatorsPanelShown}"
    *ngIf="joined && (isMapShown || isNotepadShown)"
  >
    <in-page-panel
    [fullscreen]="true"
    *ngIf="ngIf_MapPanel">
      <map-view
        [endpoints]="ng_MapPanelEndpoints"
        [googleMapsAPIKey]="ng_MapPanelGoogleMapsAPIKey"
      ></map-view>
    </in-page-panel>
    <in-page-panel
    [title]="'Note'"
    *ngIf="ngIf_NotepadPanel"
      (exit)="toggleNotepad(null)"
    >
      <notepad
        [conference]="activeConference"
        [endpoint]="noteOnEndpoint"
      ></notepad>
    </in-page-panel>
  </div>
  <canvas
    id="in-page-canvas"
    *ngIf="isMapShown || isNotepadShown"
  ></canvas>
  <h1
    *ngIf="localizationService.myLocalizationData.print"
    class="watermark d-none d-print-block"
  >
    {{localizationService.myLocalizationData.print.watermarkText}}
  </h1>
  <div class="d-print-none">

    <div
      class="join-conference-container container pull-center"
      [ngClass]="{'fullScreenMode': fullScreenMode, 'mobile': isMobileApp}"
      *ngIf="!joined"
    >
      <page-title [hidden]="joined"></page-title>
      <guest-login
        *ngIf="guestLoginRequired"
        [isMobileApp]="isMobileApp"
        (guestLogin)="guestLogin($event)"
        [mediaAccessFinished]="mediaAccessFinished"
        [mediaAccessFailed]="mediaAccessFailed"
      >
      </guest-login>
      <login-screen
        *ngIf="loginRequired"
        [hidden]="!mediaAccessFinished"
        (login)="login($event)"
        [isMobileApp]="isMobileApp"
      >
      </login-screen>
    </div>

    <guest-queue-screen
      *ngIf="ngIf_GuestQueueScreen"
      [hidden]="ngHidden_GuestQueueScreen"
      [style]="style"
      [defaultLanguage]="language"
      (guestConnect)="tryConnectToWaitingRoomFromQueue($event)">
    </guest-queue-screen>
    <guest-connect
      *ngIf="ngIf_GuestConnect"
      [hidden]="ngHidden_GuestConnect"
      (guestConnect)="tryConnectToWaitingRoom()"
      (guestDisconnect)="disconnectFromWaitingRoom()"
      (callBackGuest)="callBackGuest($event)"
      [skillTags]="skillTags"
      [transferOccurring]="transferOccurring"
      [presenceStatus]="endpointService.myEndpoint.status"
      [cameraPermission]="endpointService.myEndpoint.cameraPermission"
      [hasOperatorOnline]="hasOperatorOnline"
      [touchless]="touchlessGuestConnect"
      [isNavShown]="isNavShown"
      [isLocked]="isLocked"
      [videoVolume]="videoVolume"
      [queueStatus]="queueStatus"
    >
    </guest-connect>
    <participant-panel
      *ngIf="ngIf_ParticipantPanelLeft"
      [listTypes]="listTypesLeft"
      [layout]="layoutLeft"
      [viewMode]="viewMode"
      [position]="'left'"
      [transferHasSelected]="transferHasSelected"
      [conference]="getPanelConferenceLeft"
      [guestToAnswerEp]="guestToAnswerEp"
      [isMobileApp]="isMobileApp"
      (toggleParticipantPanel)="toggleIncomingQueue($event)"
      (toggleTransferSelection)="toggleTransferSelection($event)"
      (peerChat)="peerChat($event)"
      (peerVideoChat)="peerVideoChat($event, connectionType.outbound)"
      (disconnectPeer)="disconnectPeer($event)"
      (stepAwayFromConference)="stepAwayFromConference($event, true)"
      (rejoinConference)="stepAwayFromConference($event, false)"
      (resumeSubConference)="resumeSubConference($event)"
      (hangupRingingCall)="hangupDialOutCall($event)"
      (retrievePeer)="updateGuestEndpoint($event)"
      (toggleEndpointVideo)="toggleEndpointVideo($event)"
      (toggleNotepad)="toggleNotepad($event)"
      (openGuestInfoModal)="openGuestInfoModal($event)"
      (dialOut)="dialOut()"
      (openKeypad)="openKeypad()"
    >
    </participant-panel>

    <participant-panel
      *ngIf="ngIf_ParticipantPanelRight"
      [listTypes]="listTypesRight"
      [layout]="layoutRight"
      [viewMode]="viewMode"
      [isMobileApp]="isMobileApp"
      [position]="'right'"
      [transferHasSelected]="transferHasSelected"
      [connectingOperator]="endpointService?.connectingOperator"
      [conference]="getPanelConferenceRight"
      [canMakePhoneCall] = "voiceDialoutEnabled"
      (toggleParticipantPanel)="toggleOperatorsPanel($event)"
      (peerChat)="peerChat($event)"
      (peerVideoChat)="peerVideoChat($event, connectionType.internal)"
      (disconnectPeer)="disconnectPeer($event)"
      (resumeSubConference)="resumeSubConference($event)"
      (hangupRingingCall)="hangupDialOutCall($event)"
      (retrievePeer)="updateGuestEndpoint($event)"
      (toggleEndpointVideo)="toggleEndpointVideo($event)"
      (startMonitor)="startMonitor($event)"
      (exitMonitor)="exitMonitor($event)"
      (pushToTalk)="pushToTalk($event)"
      (dialOut)="dialOut()"
      (cancelCall)="cancelCall($event.rtcId)"
      (openKeypad)="openKeypad()"
      [canTransferTo]="canTransferTo"
      [canConferenceIn]="canConferenceIn"
      (openOperatorInfoModal)="openOperatorInfoModal($event)"
    >
    </participant-panel>

    <chatroom
      *ngIf="ngIf_Chatroom"
      [hidden]="ngHidden_Chatroom"
      [isMobileApp]="isMobileApp"
      [chatBoxShown]="chatBoxShown"
      [conference]="chattableConference"
      [listTypes]="chatroomListTypes"
      (fileUploaded)="fileUploaded($event)"
    >
    </chatroom>

    <settings-panel
      *ngIf="ngIf_SettingsPanel"
      [isMobileApp]="isMobileApp"
      [isKioskUnlocked]="isKioskUnlockedCheck()"
      [conference]="activeConference"
      [cropperEnabled]="cropperEnabled"
      [videoAspect]="userService.currentUser.preferedVideoAspect"
      (toggleSettingsPanel)="toggleSettingsPanel($event)"
      (toggleMenuPanel)="toggleMenuPanel(true)"
      (updateSpeaker)="updateSpeaker($event)"
      (updateVolume)="updateVolume($event)"
      (toggleMic)="toggleMic($event)"
      (toggleCamera)="toggleCamera($event)"
    >
    </settings-panel>

    <menu-panel
      *ngIf="ngIf_MenuPanel"
      [isMobileApp]="isMobileApp"
      (toggleMenuPanel)="toggleMenuPanel($event)"
    >
    </menu-panel>
  </div>
  <footer
    *ngIf="localizationService.myLocalizationData.footer"
    [positionFixed]="true"
    [isLocked]="isLocked"
    [showHelper]="!joined"
    [isNavShown]="isNavShown"
    [hidden]="isCleanViewMode"
    [isMobileApp]="isMobileApp"
  >
  </footer>
</div>
<div
  class="loading-screen"
  [hidden]="!showSpinner"
>
  <img class="spinner" src="images/spinner.gif" />
  <div class="message p-4 text-center" [hidden]="!isServerConnected || mediaAccessFinished">
    <span>Camera and Microphone Access Required. Please allow them in your browser pop-up</span>
  </div>
  <div class="message" [hidden]="isAttemptingReconnect">Reconnecting...</div>
</div>


<div
  class="volume-adjust"
  [style.color]="
    localizationService.myLocalizationData.page ? localizationService.myLocalizationData.page.fontColor : 'white'
  "
  *ngIf="joined && connected && isKiosk()">
  <a title="Turn Up Volume" class="volume-button" (click)="raiseVolume()">
    <i class="fas fa-caret-up"></i>
  </a>
  <div class="volume-value">
    <i class="fas"
    [ngClass]="videoVolume > 0 ? 'fa-volume-down' : 'fa-volume-mute'">
    </i>
    <span class="value">{{videoVolume * 100 | number}}</span>
  </div>
  <a title="Turn Down Volume" class="volume-button" (click)="turnDownVolume()">
    <i class="fas fa-caret-down"></i>
  </a>
</div>
