/**
 * Copyright Compunetix Incorporated 2018-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
/**
 * troubleshoot modal Component
 */
import { Component, OnInit } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { Companion } from "companion";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "troubleshoot-modal",
  templateUrl: "./troubleshoot-modal.component.html"
})
export class TroubleshootModalComponent implements OnInit {
  troubleshooterUrl: any;
  getTroubleshooterUrl(): any {
    let url: string = "https://test.webrtc.org/?";
    let paramUri = "";
    let iceServers: any[] = Companion.getRTCClient().peerconnectionConfig.iceServers;
    if (iceServers) {
      let turnServerToTest: string;
      let stunServerToTest: string;
      _.forEach(iceServers, (iceServer: any) => {
        if (iceServer.urls && !Array.isArray(iceServer.urls)) {
          iceServer.urls = [iceServer.urls];
        } else if (!iceServer.urls && iceServer.url) {
          iceServer.urls = [iceServer.url];
        }
        _.forEach(iceServer.urls, (iceServerUrl: string) => {
          let matched = /^(\S{4}):(\S+)$/.exec(iceServerUrl);
          if (matched) {
            let protocol = matched[1].toLowerCase();
            let iceServerHost = matched[2].toLowerCase();
            if (protocol && iceServerHost) {
              if (protocol === "turn" && !turnServerToTest) {
                turnServerToTest = "turnURI=" + iceServerUrl;
                turnServerToTest += "&turnUsername=" + iceServer.username;
                turnServerToTest += "&turnCredential=" + iceServer.credential;
              } else if (protocol === "stun" && !stunServerToTest) {
                stunServerToTest = "stunURI=" + iceServerUrl;
              }
            }
          }
        });
      });
      if (turnServerToTest) {
        paramUri += "&" + turnServerToTest;
      }
      if (stunServerToTest) {
        paramUri += "&" + stunServerToTest;
      }
      paramUri = paramUri.substr(1);
    }
    url += paramUri;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  constructor(public localizationService: LocalizationService, private sanitizer: DomSanitizer) {
    // nothing needed here
  }
  ngOnInit() {
    this.troubleshooterUrl = this.getTroubleshooterUrl();
  }
  /**
   * close the modal window
   */
  exit() {
    $("#TroubleshootModal").modal("hide");
  }
}
