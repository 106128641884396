/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  Jordan Porter
 */
import { CommonModule } from "@angular/common";
import {AfterViewChecked, AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import { Gauge } from "gaugeJS";
import { DomChangeDirective } from "../../directives/dom-change.directive";

@Component({
  standalone: true,
  imports: [CommonModule, DomChangeDirective],
  selector: "meter-gauge",
  templateUrl: "./meter-gauge.template.html"
})
export class MeterGaugeComponent implements AfterViewInit, AfterViewChecked, OnInit, OnChanges {
    observer!: IntersectionObserver;

    @Input() options: any;
    @Input() canvasWidth: number;
    @Input() canvasHeight: number;
    
    @Input() max: number;
    @Input() min: number;
    @Input() animationSpeed: number;
    @Input() value: number;

    @ViewChild('root') innerContent: ElementRef;

    gaugeLib: Gauge;
    visible: boolean = false;

    constructor(private ref: ElementRef, private ngZone: NgZone) {
      // got elem ref.
    }

    public onDomChange(mutationRecord: MutationRecord): void {
      const addedNodes   = mutationRecord.addedNodes;
      const removedNodes = mutationRecord.removedNodes;
     
      if (  addedNodes.length > 0
        && (addedNodes.item(0) as HTMLElement).tagName === 'CANVAS') {
        this.setupGauge(addedNodes.item(0) as HTMLCanvasElement);
      } else if (removedNodes.length > 0
             && (removedNodes.item(0) as HTMLElement).tagName === 'CANVAS') {
        if (this.gaugeLib) {
          delete this.gaugeLib;
        }
      }
    }

    setupGauge(canvas: HTMLCanvasElement) {
      this.gaugeLib = new Gauge(canvas).setOptions(this.options);
      this.gaugeLib.maxValue = (this.max || 100);
      this.gaugeLib.minValue = (this.min || 0);
      this.gaugeLib.animationSpeed = this.animationSpeed || 32;
    }

    ngOnInit() {
      // Detect when the component goes into or out of view.
      this.ngZone.runOutsideAngular(() => {
        this.observer = new IntersectionObserver((entries) => {
          let wasVisible = this.visible;
          this.visible = entries[entries.length - 1].isIntersecting;
          if (!wasVisible && this.visible) {
            this.initView();
          } else if (!this.visible) {
            this.deInitView();
          }
        });
      });
    }

    deInitView(){
      this.innerContent.nativeElement.innerHTML = "";
    }

    ngAfterViewChecked() {
      this.checkVisibleManually();
    }

    checkVisibleManually(): void
    {
        if (this.visible == false && this.ref.nativeElement.offsetParent != null)
        {
            this.visible = true;
            this.initView();
        }
        else if (this.visible == true && this.ref.nativeElement.offsetParent == null)
        {
            this.visible = false;
            this.deInitView();
        }
    }

    ngAfterViewInit(): void {
      this.checkVisibleManually();
    }

    initView() {
      let content: Element = this.innerContent.nativeElement;
      if (!content || !this.visible) return;
      try{
        let canvas : HTMLCanvasElement = document.createElement('canvas');
        canvas.height = this.canvasHeight;
        canvas.width = this.canvasWidth;
        content.appendChild(canvas);
      } catch (error) {
        console.log(error);
      }
    }

    ngOnChanges(changes: SimpleChanges) {
      if (!this.gaugeLib || !this.visible) {
        return;
      }
      let updateValue, updateMax, updateMin, updateSpeed = false;
      for (const propName in changes) {
        if (changes.hasOwnProperty(propName)) {
          switch (propName) {
            case 'value': {
              updateValue = true;
            }
            break;
            case 'max': {
              updateMax = true;
            }
            break;
            case 'min': {
              updateMin = true;
            }
            break;
            case 'animationSpeed': {
              updateSpeed = true;
            }
            break;
          }
        }
      }
      let reInit: boolean = false;
      if (updateValue)
        this.gaugeLib.set(this.value);

      if (updateMin) {
        this.gaugeLib.minValue = this.min;
        reInit = true;
      }
      if (updateMax) {
        this.gaugeLib.maxValue = this.max;
        reInit = true;
      }
      if (updateSpeed) {
        this.gaugeLib.animationSpeed = this.animationSpeed;
        reInit = true;
      }

      if (reInit)
        this.checkVisibleManually();
    }
}