<!--
    Copyright Compunetix Incorporated 2018-2021
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender
-->
<div
  class="container no-padding"
  *ngIf="parameters && form"
>
  <form [formGroup]="form">
    <div
      class="row"
      *ngIf="topControlsEnabled"
    >
      <div class="col-md-12 text-left">
        <div
          class="pull-left"
          *ngIf="importExportEnabled"
        >
          <label>
            <a
              tabindex
              class="btn btn-sm"
            >
              Import
            </a>
            <input
              type="file"
              style="display: none;"
              (change)="import($event)"
            />
          </label>
          &nbsp;|&nbsp;
          <a
            tabindex
            class="btn btn-sm"
            (click)="export()"
          >
            Export
          </a>
        </div>
        <div class="pull-right">
          <button
            *ngIf="deleteEnabled"
            type="button"
            class="btn btn-sm btn-danger"
            (click)="delete()"
          >
            Delete
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            [disabled]="!isValid"
            (click)="save()"
            [ngClass]="saveButtonClass"
          >
            {{saveButtonText}}
          </button>
        </div>
      </div>
    </div>
    <accordion
      *ngIf="inAccordionLayout"
      [closeOthers]="true"
      id="accordion-field-list"
    >
      <ng-container *ngFor="let controlSectionKey of form.controls | keys; index as i">
        <accordion-group
          *ngIf="parameters[controlSectionKey+'_enabled']"
          [hidden]="parameters[controlSectionKey+'_enabled'].disabled"
          [heading]="
            parameters[controlSectionKey+'_enabled'] ?
              parameters[controlSectionKey+'_enabled'].title : controlSectionKey"
          [isOpen]="i === 0"
        >
          <div class="row">
            <form-field
              *ngFor="let controlKey of form.controls[controlSectionKey].controls | keys"
              class="col-{{parameters[controlKey].column == null ? 12 : parameters[controlKey].column}} d-{{parameters[controlKey].type === 'hidden' ? 'none' : 'inline-block'}}"
              [hidden]="parameters[controlKey].type==='hidden'"
              [parameter]="parameters[controlKey]"
              [form]="form.controls[controlSectionKey]"
              [sectionKey]="controlSectionKey"
              [controlKey]="controlKey"
              [isEditing]="isEditing"
              [togglePasswords]="togglePasswords"
              (lockableEnabled)="toggleLockables($event)"
              (valueChanged)="valueChanged($event)"
              #FormField
            ></form-field>
          </div>
        </accordion-group>
      </ng-container>
    </accordion>
    <div
      *ngIf="!inAccordionLayout"
      id="field-list"
      class="row"
    >
      <ng-container *ngFor="let controlSectionKey of form.controls | keys">
        <form-field
          *ngFor="let controlKey of form.controls[controlSectionKey].controls | keys"
          class="col-{{parameters[controlKey].column == null ? 12 : parameters[controlKey].column}} d-{{parameters[controlKey].type === 'hidden' ? 'none' : 'inline-block'}}"
          [hidden]="parameters[controlKey].type==='hidden'"
          [parameter]="parameters[controlKey]"
          [form]="form.controls[controlSectionKey]"
          [sectionKey]="controlSectionKey"
          [controlKey]="controlKey"
          [isEditing]="isEditing"
          [togglePasswords]="togglePasswords"
          (lockableEnabled)="toggleLockables($event)"
          (valueChanged)="valueChanged($event)"
          #FormField
        ></form-field>
      </ng-container>
    </div>

    <div class="row text-right">
      <div class="col-12">
        <button
          type="button"
          class="btn"
          (click)="cancel()"
          [ngClass]="(cancelButtonClass || 'btn-default') + ' ' + (bottomButtonSmall ? 'btn-sm' : '')"
        >
          {{cancelButtonText || 'Cancel'}}
        </button>
        <button
          *ngIf="secondCancelButtonText"
          type="button"
          class="btn"
          (click)="secondCancel()"
          [ngClass]="(secondCancelButtonClass || 'btn-default') + ' ' + (bottomButtonSmall ? 'btn-sm' : '')"
        >
          {{secondCancelButtonText}}
        </button>
        <button
          *ngIf="deleteEnabled"
          type="button"
          class="btn btn-danger"
          [ngClass]="{'btn-sm': bottomButtonSmall}"
          (click)="delete()"
        >
          Delete
        </button>
        <button
          *ngIf="secondSaveButtonText"
          type="submit"
          class="btn"
          [disabled]="!isValid"
          (click)="secondSave()"
          [ngClass]="(secondSaveButtonClass || 'btn-primary') + ' ' + (bottomButtonSmall ? 'btn-sm' : '')"
        >
          {{secondSaveButtonText}}
        </button>
        <button
          *ngIf="saveButtonEnabled"
          type="submit"
          class="btn"
          [disabled]="!isValid"
          (click)="save()"
          [ngClass]="(saveButtonClass || 'btn-primary') + ' ' + (bottomButtonSmall ? 'btn-sm' : '')"
        >
          {{saveButtonText || 'Save'}}
        </button>
      </div>
    </div>
  </form>
</div>
