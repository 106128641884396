/**
 * Copyright Compunetix Incorporated 2017-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { IRTCServiceServerMessageHandler } from "../services/rtc.service.server-message-handler.interface";
import { AlertLevel} from "../alert/alert.interface";
import { PresenceStatus } from "../endpoint/endpoint.interface";
import { IEndpointService } from "../endpoint/endpoint.service.interface";
import { EndpointService } from "../endpoint/endpoint.service";
import { IEndpoint } from "../endpoint/endpoint.interface";
import { DeviceService } from "../services/device.service";
import { ConferenceService } from "./conference.service";
import { IConferenceService } from "./conference.service.interface";
import { EasyRTCService } from "../services/rtc.service.easy";
import { Companion} from "..";

export class RTCServiceServerMessageHandler implements IRTCServiceServerMessageHandler {
  /**
   * message handlers on server messages
   */
  private serverMessageHandlers: { [commandName: string]: (msgData: any) => void } = {};

  /**
   * handlers on server message code
   */
  private serverMessageCodeHandlers: { [messageCode: string]: (msgData: any) => void } = {};

  constructor(
    private conferenceService: IConferenceService = ConferenceService.getSharedInstance(),
    private endpointService: IEndpointService = EndpointService.getSharedInstance()
  ) {
    this.serverMessageHandlersRegister();
  }

  /**
   * server update peer name handler
   * @param msgData: any -  message data received
   */
  private serverUpdatePeerNameCommandHandler(msgData: any): void {
    let ep: IEndpoint = this.endpointService.getEndpointById(msgData.rtcId);
    if (ep) {
      ep.name = msgData.name;
    }
  }

  /**
   * server message listener
   * @param msgType: string - the type of message received
   * @param msgData: any -  message data received
   * @param targeting: any - targeting peer
   */
  serverMessageListener(msgType: string, msgData: any, targeting: any): void {
    if (msgType === "cmd") {
      if (this.serverMessageHandlers[msgData.commandName]) {
        this.serverMessageHandlers[msgData.commandName](msgData);
      }
    }
  }

  /**
   * set server message handler
   */
  setServerMessageHandler(message: string, handler: (msgData: any) => void): void {
    this.serverMessageHandlers[message] = handler;
  }

  /**
   * set server message code handler
   */
  setServerMessageCodeHandler(messageCode: string, handler: (msgData: any) => void): void {
    this.serverMessageCodeHandlers[messageCode] = handler;
  }

  /**
   * register all server message handlers
   */
  private serverMessageHandlersRegister() {
    this.serverMessageHandlers["success"] = this.serverSuccessResponseHandler.bind(this);
    this.serverMessageHandlers["failure"] = this.serverFailureResponseHandler.bind(this);
    this.serverMessageHandlers["update_peer_name"] = this.serverUpdatePeerNameCommandHandler.bind(this);
    this.serverMessageHandlers["alert"] = this.serverAlertMessageHandler.bind(this);
    this.serverMessageHandlers["reload_client"] = this.reloadClientCommandHandler.bind(this);
    this.serverMessageHandlers["switch_camera"] = this.switchCameraCommandHandler.bind(this);
    this.serverMessageHandlers["update_audio_video_state"] = this.updateAudioVideoStateCommandHandler.bind(this);
    this.serverMessageHandlers["newConsumer"] = this.onNewConsumerHandler.bind(this);
    this.serverMessageHandlers["newDataConsumer"] = this.onNewDataConsumerHandler.bind(this);
    this.serverMessageHandlers["update_system_config"] = this.updateSystemConfigHandler.bind(this);
    
    this.serverMessageCodeHandlers["OTHER_ERROR"] = this.otherErrorMessageHandler.bind(this);
  }

 
  /**
   * server response message handler on OTHER_ERROR keyword
   * @param msgData: any -  message data received
   */
  private otherErrorMessageHandler(msgData: any): void {
    console.error(msgData);
  }


  /**
   * server succeed response message handler
   * @param msgData: any -  message data received
   */
  private serverSuccessResponseHandler(msgData: any): void {
    if (!msgData.message) {
      return;
    }
    if (this.serverMessageCodeHandlers[msgData.message]) {
      this.serverMessageCodeHandlers[msgData.message](msgData);
    }
  }

  /**
   * server failure response message handler
   * @param msgData: any -  message data received
   */
  private serverFailureResponseHandler(msgData: any): void {
    if (!msgData.message) {
      return;
    }
    if (this.serverMessageCodeHandlers[msgData.message]) {
      this.serverMessageCodeHandlers[msgData.message](msgData);
    } 
  }


  /**
   * server alert message handler
   * @param msgData: any -  message data received
   */
  serverAlertMessageHandler(msgData: any): void {
    this.conferenceService.alertHandler(
      msgData.code,
      msgData.text,
      AlertLevel[msgData.level as keyof typeof AlertLevel],
      msgData.seconds
    );
  }

  /**
   * server message handler on reload_client keyword
   */
  private reloadClientCommandHandler(): void {
    this.conferenceService.preventReload = false;
    location.reload();
  }

  /**
   * server message handler on switch_camera keyword
   */
  private switchCameraCommandHandler(): void {
    DeviceService.getSharedInstance().switchCamera();
  }

  /*
   * server message handler on update_audio_video_state keyword
   * @param msgData: any -  message data received
   */
  private updateAudioVideoStateCommandHandler(msgData: any): void {
    if (msgData.isAudioMuted != null && msgData.isAudioMuted !== EasyRTCService.getSharedInstance().rtcClient.audioMuted) {
      EasyRTCService.getSharedInstance().muteMicrophone(msgData.isAudioMuted);
    }
    if (msgData.isVideoMuted != null && msgData.isVideoMuted !== EasyRTCService.getSharedInstance().rtcClient.videoMuted) {
      EasyRTCService.getSharedInstance().muteCamera(msgData.isVideoMuted);
    }
  }

  private onNewConsumerHandler(msgData: any) {

  }

  private onNewDataConsumerHandler(msgData: any) {

  }

  /*
   * server message handler on update_system_config keyword
   * @param msgData: any -  message data received
   */
  private updateSystemConfigHandler(msgData: any): void {
    EasyRTCService.getSharedInstance().rtcClient.sendLogsToServer = msgData.sendLogsToServer;
  }
}
