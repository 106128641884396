/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
/**
 * File Sharing Component
 */
import { Component, Input } from "@angular/core";
import { IEndpoint, IExpandedActiveConference } from "companion";

@Component({
  selector: "inspect-panel",
  templateUrl: "./inspect-panel.component.html"
})
export class InspectPanelComponent {
  /**
   * current conference joined
   */
  @Input() conference: IExpandedActiveConference;

  /**
   * is mobile app
   */
  @Input() isMobileApp: boolean;

  constructor() {
    // nothing needed here
  }

  get activeEndpoints() : Readonly<IEndpoint>[] {
    return this.conference?.active;
  }

  /**
   * Take a bits per second and format it by scaling to b Kb Mb Gb etc.
   * @param bps The number of bits per second.
   * @returns A nicer number equivelant to the input with a units per second notation.
   */
  formatBps(bps: number) : string {
    if(!bps) return '0 b/s';
    var k = 1000,
        sizes = [' b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'],
        i = Math.floor(Math.log(bps) / Math.log(k));
    let result = parseFloat((bps / Math.pow(k, i)).toFixed(0));
    return  result + ' ' + sizes[i] + "/s";
  }
}
