/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, ViewChild, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { FlavorPanelComponent } from "./flavor/flavor-panel.component";
import { Dispatcher, ActionType } from "client/scripts/shared/services/dispatcher";
import { Companion, ILocalization, ConferenceMode } from "companion";
import { NavBarMenuItemKey } from "./../layout/nav-bar/nav-bar.service";

@Component({
  selector: "flavor-factory",
  templateUrl: "./flavor-factory.template.html"
})

/**
 * flavor-factory view
 */
export class FlavorFactoryComponent implements OnInit {
  /**
   * current selected flavor style
   */
  selectedStyle: string;

  /**
   * preview href
   */
  previewHref: SafeResourceUrl;

  /**
   * preview frame src
   */
  previewSrc: SafeResourceUrl;

  /**
   * encoded preview src string
   */
  encodedPreviewSrc: string;

  /**
   * child component flavor panel
   */
  @ViewChild("flavorPanel")
  flavorPanelComponent: FlavorPanelComponent;

  /**
   * new flavor style selected
   */
  styleSelected(style: string) {
    this.selectedStyle = style;
    console.log(this.selectedStyle);
    this.flavorPanelComponent.updateStyle(style);
  }

  /**
   * reload preview
   */
  reloadPreview(data: ILocalization) {
    let style = data.style;
    let language = data.language;
    let url: string;
    switch (ConferenceMode[data.mode]) {
      case ConferenceMode.call_center:
      case ConferenceMode.embed:
      default:
        url = "/" + style + "/operators";
        break;
    }
    if (/-(kiosk|public)$/i.exec(style)) {
      url = "/" + style.replace(/-(kiosk|public)$/i, "") + "/operators/" + language;
    } else if (_.endsWith(style.toLowerCase(), "-embed")) {
      url =
        "/" +
        style.substring(0, style.toLowerCase().lastIndexOf("-embed")) +
        "/embed/operators/" +
        language +
        "/Flavor Factory";
    }
    if (url.startsWith("/default")) {
      url = url.substring("/default".length);
    }

    this.previewHref = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.previewSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url.concat("?preview=true"));
    this.encodedPreviewSrc = encodeURI("https://" + window.location.host + url);
  }

  constructor(private sanitizer: DomSanitizer) {
    // nothing needed here
  }

  ngOnInit() {
    if (
      !Companion.getUserService().currentUser.isAuthenticated ||
      !(Companion.getUserService().currentUser.permissions.seeThemes > 0)
    ) {
      Dispatcher.dispatch(ActionType.OpenDefaultScreen);
      return;
    }
    Dispatcher.dispatch(ActionType.LoadHostUserNavBar, { active: NavBarMenuItemKey.ThemeManager });
    Dispatcher.dispatch(ActionType.LoadTopBarMenu);
  }
}
