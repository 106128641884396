/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, OnInit } from "@angular/core";
import { Dispatcher, ActionType } from "client/scripts/shared/services/dispatcher";
import { Companion, IUser, Alert, AlertLevel } from "companion";
import { NavBarMenuItemKey } from "./../layout/nav-bar/nav-bar.service";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { RestService } from "./../shared/services/rest.service";
import { LocalizationService } from "./../localization/localization.service";
import { UserManagementService } from "./../user-management/user-management.service";

@Component({
  selector: "user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"]
})
export class UserProfileComponent implements OnInit {
  user: IUser;
  mode: string = "profile";
  passwordResetToken: string;
  uploadAvatarShown: boolean;

  constructor(
    private restService: RestService,
    private route: ActivatedRoute,
    private router: Router,
    private userManagementService: UserManagementService,
    private localizationService: LocalizationService
  ) {
    Dispatcher.register(ActionType.LoadUserProfileData, (payload: any) => {
      if (!payload.userId) {
        return;
      }
      this.userManagementService.getUserWithRolesAndGroupsById(payload.userId)
      .then((user: any) => {
        this.user = user;
      })
      .catch((error: any) => {
        console.log(`Failed to getUserWithRolesANdGroupsById: ${JSON.stringify(error)}`);
      })
      .then(() => {
        Dispatcher.dispatch(ActionType.LoadTopBarMenu);
      });
    });
    this.route.params.subscribe((params: any) => {
      Dispatcher.dispatch(ActionType.LoadUserProfileData, {
        userId: params["userId"] || Companion.getUserService().currentUser["_id"]
      });
    });
  }
  
  ngOnInit() {
    if (!Companion.getUserService().currentUser.isAuthenticated) {
      Dispatcher.dispatch(ActionType.OpenDefaultScreen);
      return;
    }
    Dispatcher.dispatch(ActionType.LoadHostUserNavBar, { active: NavBarMenuItemKey.MyAccount });
    Dispatcher.dispatch(ActionType.LoadTopBarMenu);
  }
  openProfile() {
    this.mode = "profile";
  }
  openResetPassword() {
    this.restService
      .post("/requestResetTokenForUser", {})
      .subscribe(
        (data: any) => {
          this.router.navigate(["/resetPassword/" + data.passwordResetToken]);
        },
        (error: any) => {
          console.error(error);
        }
      );
  }
  displayUploadAvatar() {
    this.uploadAvatarShown = true;
    $(".avatar-upload-button").css(
      "top",
      ($(".profile-userpic").height() + Math.abs($(".avatar-upload-button").height())) / 2
    );
  }
  hideUploadAvatar() {
    this.uploadAvatarShown = false;
  }
  uploadAvatar(event: any) {
    this.hideUploadAvatar();
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append("image", file, file.name);
      this.restService
        .upload("/uploadAvatar", formData)
        .subscribe(
          (data: any) => {
            this.user.avatar = data.filePath;
          },
          (error: any) => {
            Dispatcher.dispatch(ActionType.Alert, {
              alert: new Alert("UPLOAD_AVATAR_FAILED", "Failed to upload the avatar image.", AlertLevel.warning)
            });
          }
        );
    }
  }
}
