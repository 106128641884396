<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<div class="list-group list-group-flush full-height">
  <div class="container-fluid p-1 bg-gray-opacity">
    <div class="row">
      <b *ngIf="title" class="col-8 text-left">
        {{title}}
        <span
          *ngIf="showTag"
          class="badge badge-pill badge-info counter-tag"
        >
          {{tagContent}}
        </span>
        <div class="btn-group" *ngIf="isEditable">
          <a
            class="btn btn-link"
            (click)="enterEdit()"
            *ngIf="!isEditing && isEditable"
          >
            <i class="fas fa-edit" title="Edit Mode"></i>
          </a>
          <a
            class="btn btn-link"
            (click)="exitEdit()"
            *ngIf="isEditing && isEditable"
          >
            <i class="fas fa-check-circle" title="Finish Editing"></i>
          </a>
        </div>
        <div class="btn-group" *ngIf="addEnabled">
          <a
            class="btn btn-link"
            (click)="add()"
            *ngIf="addEnabled && !isEditing"
          >
            <i class="fas fa-plus-circle" title="Add New"></i>
          </a>
        </div>
        <ng-container *ngTemplateOutlet="listHeaderTemplate"></ng-container>
      </b>
      <div class="col-4" [ngClass]="{'stretchLeftSimple col-12': isSearching}">
        <div
          class="search-box w-100 d-inline-block"
          [ngClass]="{'pr-4': sortEnabled}"
          *ngIf="searchEnabled"
        >
          <form class="form-inline">
            <input
              type="text"
              class="form-control w-100"
              placeholder="Search"
              [(ngModel)]="viewModel.search"
              #searchbox
              name="searchbox"
              (focusin)="enterSearchMode($event)"
              (focusout)="exitSearchMode($event)"
            >
            <a
              class="btn reset-btn"
              (click)="resetSearch()"
              [hidden]="!viewModel.search"
            ><i class="fas fa-times"></i></a>
          </form>
        </div>
        <div *ngIf="sortEnabled" class="pull-right" [ngStyle]="{'margin-left': (searchEnabled ? '-3rem' : '0px') }">
          <div class="dropdown">
            <a class="btn btn-link px-0 dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-sort-amount-down-alt" *ngIf="sortDirection === 'asc'"></i>
              <i class="fas fa-sort-amount-down" *ngIf="sortDirection === 'desc'"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <ng-container *ngFor="let option of sortKeyOptions">
                <a class="btn dropdown-item text-capitalize py-1" (click)="sortBy(option.value)">{{option.name}}
                  <i class="pull-right fas fa-sort-amount-down-alt" *ngIf="option.value === sortKey && sortDirection === 'asc'"></i>
                  <i class="pull-right fas fa-sort-amount-down" *ngIf="option.value === sortKey && sortDirection === 'desc'"></i>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div [ngClass]="{'accordion': isAccordion}" class="full-height">
    <dl [style.maxHeight]="maxHeight + 'px'" class="full-height">
      <list-new-item
        *ngIf="viewModel.toBeAddedItem"
        [item]="viewModel.toBeAddedItem"
        [listItemAdditionTemplate]="listItemAdditionTemplate"
        (submit)="submitNewItem($event)"
        (cancel)="cancelNewItem()"
      ></list-new-item>
      <list-item
        *ngFor="let item of items | listSearch : viewModel.search : searchByFields | sortBy:[sortKey]:[sortDirection]; index as i"
        [item]="item"
        [expanded]="viewModel.expanded[item.componentId]"
        [minimized]="viewModel.minimized[item.componentId]"
        [multiSelect]="multiSelect"
        [selected]="viewModel.selected[item.componentId]"
        [isEditable]="isEditable"
        [isEditing]="isEditing"
        [isAccordion]="isAccordion"
        [listItemTemplate]="listItemTemplate"
        [listItemContentTemplate]="listItemContentTemplate"
        (editItem)="edit(item)"
        (selectItem)="select(item)"
        (deleteItem)="delete(item)"
        (toggleExpand)="toggleExpand(item)"
      ></list-item>
    </dl>
  </div>
  <div
    class="row"
    *ngIf="multiSelect"
  >
    <div class="col-md-6 offset-md-6">
      <a
        class="col-md-6 btn"
        tabindex=""
        (click)="cancelSelection()"
      >
        Cancel
      </a>
      <a
        class="col-md-6 btn btn-primary"
        tabindex=""
        (click)="submitSelection()"
      >
        Select
      </a>
    </div>
  </div>
</div>