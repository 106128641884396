/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  frivolta
 */

import {Component, Output, EventEmitter, Input} from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";

@Component({
  selector: "keypad",
  templateUrl: "./keypad.template.html"
})
export class KeypadComponent {
  /**
   * all keys entered by user
   */
  keysEntered: string = " ";

  /**
   * whether the key entered is visible or not
   */
  @Input() isKeyEnteredVisible = true;

  /**
   * whether it's disabled or not
   */
  @Input() disabled = false;

  /**
   * dtmf dial event trigger
   */
  @Output("pressedKey") pressedKeyEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }

  keyboard = [
    { number: "1", letters: ""},
    { number: "2", letters: "abc"},
    { number: "3", letters: "def"},
    { number: "4", letters: "ghi"},
    { number: "5", letters: "jkl"},
    { number: "6", letters: "mno"},
    { number: "7", letters: "pqrs"},
    { number: "8", letters: "tuv"},
    { number: "9", letters: "wxyz"},
    { number: "*", letters: ""},
    { number: "0", letters: ""},
    { number: "#", letters: ""},
  ];

  /**
   * user close the pin pad
   */
  exit(): void {
    this.keysEntered = "";
  }

  /**
   * user click pin pad key
   * @param key: string - key tone
   */
  pressedKey(key: string) {
    if (!this.disabled) {
      this.keysEntered += key;
      this.pressedKeyEmitter.emit(key);
    }
  }
}
