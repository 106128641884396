/**
 * Copyright Compunetix Incorporated 2017-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { RestService } from "../../shared/services/rest.service";
import {
  ILocalization,
  IFlavor,
  FlavorParameterKey,
  FlavorUtil,
  IGroup
} from "companion";
import { Flavor } from "./flavor";
import { Injectable } from "@angular/core";

@Injectable()
/**
 * flavor methods delegate
 */
export class FlavorService {
  constructor(private restService: RestService) {}

  /**
   * get all styles
   */
  getAllStyles(): Promise<string[]> {
    return new Promise((resolve: (data: string[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getAllStyles", {})
      .subscribe(
        (data: string[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  /**
   * get styles
   */
  getStyles(): Promise<string[]> {
    return new Promise((resolve: (data: string[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getStyles", {})
      .subscribe(
        (data: string[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get styles by mode
   */
  getStylesByMode(mode: string): Promise<string[]> {
    return new Promise((resolve: (data: string[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getThemeNamesByUserAndMode", { mode: mode })
      .subscribe(
        (data: string[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  /**
   * get languages by style
   * @param style: string - flavor style
   */
  getLanguagesByStyle(style: string): Promise<string[]> {
    return new Promise((resolve: (data: string[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getLanguagesByStyle", { style: style })
      .subscribe(
        (data: string[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get flavor details by style and language
   * @param style: string - flavor style
   * @param language: string - language
   */
  getLocalizationDataByStyleAndLanguage(style: string, language: string): Promise<ILocalization> {
    return new Promise((resolve: (data: ILocalization) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/localization", { style: style, language: language })
      .subscribe(
        (data: ILocalization) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * save flavor details
   */
  saveFlavor(flavor: ILocalization): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/saveFlavor", flavor)
      .subscribe(
        (data: string) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * delete flavor
   */
  deleteFlavor(style: string, language: string): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/deleteFlavor", { style: style, language: language })
      .subscribe(
        (data: string) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * update style name
   */
  updateStyleName(oldStyle: string, newStyle: string): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/updateStyleName", { oldStyle: oldStyle, newStyle: newStyle })
      .subscribe(
        (data: string) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
}
