/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */

import {NgModule} from "@angular/core";
import {DashboardOverviewComponent} from "client/scripts/dashboard/overview/overview.component";
import {NgChartsModule} from "ng2-charts";
import {MainContainerModule} from "../layout/main-container/main-container.module";
import {NavBarModule} from "../layout/nav-bar/top-nav.module";
import {TopBarModule} from "../layout/top-bar/top-bar.module";
import {TileComponent} from "client/scripts/dashboard/overview/tile.component";
import {CommonModule} from "@angular/common";
import {DashboardAgentComponent} from "client/scripts/dashboard/agents/agent.component";
import {DashboardCustomerComponent} from "client/scripts/dashboard/customers/customer.component";
import {DashboardReportsComponent} from "client/scripts/dashboard/reports/reports.component";
import {DashboardService} from "client/scripts/dashboard/dashboard.service";
import {TreeViewModule} from "../shared/components/tree-view/tree-view.module";
import {DashboardComponent} from "./dashboard.component";
import {PopoverModule} from 'ngx-bootstrap/popover';
import {RouterModule} from "@angular/router";
import {LoginModule} from "../login/login.module";
import {BarChartComponent} from "client/scripts/dashboard/shared/bar-chart.component";
import {LastUpdateComponent} from "client/scripts/dashboard/shared/last-update.component";
import {FormatTimePipe} from "client/scripts/dashboard/shared/format-time.pipe";
import {FormatDatePipe} from "client/scripts/dashboard/shared/format-date.pipe";
import {NameFilterPipe} from "client/scripts/dashboard/shared/name-filter.pipe";
import {TrendsChartComponent} from "client/scripts/dashboard/shared/trends-chart.component";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {RangePickerComponent} from "./shared/range-picker.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {PercCompareComponent} from "client/scripts/dashboard/shared/perc-compare.component";
import {MapComponent} from "client/scripts/dashboard/customers/map.component";
import {FormsModule} from "@angular/forms";
import { GroupManagementModule } from "../group-management/group-management.module";

@NgModule({
  imports: [
    NgChartsModule,
    CommonModule,
    MainContainerModule,
    NavBarModule,
    TopBarModule,
    TreeViewModule,
    PopoverModule,
    RouterModule,
    LoginModule,
    BsDatepickerModule,
    NgxDatatableModule,
    FormsModule,
    GroupManagementModule
  ],
  declarations: [
    DashboardComponent,
    DashboardOverviewComponent,
    DashboardAgentComponent,
    DashboardCustomerComponent,
    DashboardReportsComponent,
    TileComponent,
    BarChartComponent,
    TrendsChartComponent,
    LastUpdateComponent,
    RangePickerComponent,
    FormatTimePipe,
    FormatDatePipe,
    NameFilterPipe,
    PercCompareComponent,
    MapComponent
  ],
  providers: [DashboardService],
  exports: []
})
export class DashboardModule {}
