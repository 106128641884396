/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

export interface ISharedFileRef {
  id: string;
  ownerId: string;
  filename: string;
  fileSize?: number;
}

export interface ISharedFile extends ISharedFileRef{
  fileBlob: any;
  status?: FileTransferStatus;
  transmittedSize?: number;
  toRef(): ISharedFileRef
}

export enum FileTransferStatus {
  idle,
  started,
  transmitting,
  finished,
  failed
}
