/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  jporter
 */
import { Component, Output, Input, EventEmitter, ViewChild, OnInit } from "@angular/core";
import { PasscodeFormComponent } from "./passcode-form.component";

@Component({
  selector: "passcode-modal",
  templateUrl: "./passcode-modal.template.html"
})
export class PasscodeModalComponent implements OnInit {
  /**
   * modal exit event trigger (This happens on submit, too. Could block but no real reason.)
   */
  @Output("exit") exitEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  /**
   * form submit event trigger
   */
  @Output("validate") validationEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Localization Inputs
   */
  
  @Input("modalTitle") modalTitle: string;
  @Input("exitText") exitText: string;
  @Input("submitText") submitText: string;
  @Input("submitBgColor") submitBgColor: string;
  @Input("submitTextColor") submitTextColor: string;
  @Input("textColor") textColor: string;
  @Input("backgroundColor") bgColor: string;
  @Input("entryPlaceholder") entryPlaceholder: string;

  /**
   * The child form itself (text field and submit button).
   */
  @ViewChild("passcodeForm", {static: false}) passcodeForm: PasscodeFormComponent;

  constructor() {
  }

  /**
   * Localization Data
   */
  get styleData(): any {
    return {
      exitText: this.exitText || "Exit",
      modalTitle: this.modalTitle || "Enter Passcode",
      entryPlaceholder: this.entryPlaceholder || "Passcode",
      submitText: this.submitText || "Submit",
      submitBgColor: this.submitBgColor || 'blue',
      submitTextColor: this.submitTextColor || 'white',
      textColor: this.textColor || 'black',
      bgColor: this.bgColor || 'white'
    };
  }

  ngOnInit() {
    $("#passcodeModal").on('hidden.bs.modal', (event) => {
      this.exitEventEmitter.emit(event);
    })
  }

  /**
   * validation event handler
   */
  validate(passcode:string) {
    if (passcode && typeof(passcode) === 'string') {
      this.validationEmitter.emit(passcode);
      this.close();
    }
  }
  /**
   * open modal
   */
  open() : void {
    this.passcodeForm.resetForm();
    $("#passcodeModal").modal("show");
  }

  /**
   * close modal
   */
  close(): void {
    $("#passcodeModal").modal("hide");
  }
}
