<!--
    Copyright Compunetix Incorporated 2016-2023
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender
-->


<div
  id="conference-video-container"
  class="video-top"
  aria-live="off"
  tabindex="0"
  [style.color]="localizationService.myLocalizationData.video_screen?.fontColor"
  [style.background]="localizationService.myLocalizationData.page?.backgroundColor"
  [style.backgroundImage]="'url(' + localizationService.myLocalizationData.page?.backgroundImage + ')'"
  [style.top]="localizationService.myLocalizationData.video_screen?.top"
  [style.bottom]="localizationService.myLocalizationData.video_screen?.bottom"
  [style.left]="localizationService.myLocalizationData.video_screen?.left"
  [style.right]="localizationService.myLocalizationData.video_screen?.right"
  [style.width]="localizationService.myLocalizationData.video_screen?.width"
  [style.marginRight.px]="inPagePanel ? panelWidth : null"
  [ngClass]="{
    'left-sidebar-shown': leftSidebarShown && !localizationService.myLocalizationData.video_screen?.pipPositionFixed,
    'right-sidebar-shown': rightSidebarShown && !localizationService.myLocalizationData.video_screen?.pipPositionFixed,
    'expand-to-top': (!localizationService.myLocalizationData.topnav || !isNavShown) && !isMobileApp,
    'expand-to-bottom': !localizationService.myLocalizationData.footer || !isNavShown,
    'mobileVCC': isMobileApp,
    'mobile': isMobileApp,
    'position-absolute': !localizationService.myLocalizationData.video_screen?.pipPositionFixed,
    'position-fixed': localizationService.myLocalizationData.video_screen?.pipPositionFixed}"
  (dblclick)="dblClick($event)"
  (mousemove)="drag($event)"
  (mouseup)="dragEnd($event)"
  (touchmove)="touchMove($event)"
  (touchend)="dragEnd($event)"
><i [hidden]="!isRecordingActive" class="fa fa-circle text-danger Blink">REC</i>
  <div
    class="container-fluid">
    <div
      class="collapse-video-container">
      <div [hidden]="!isSharingContent" class="float-left h-100" [style.width.%]="isLocked || isMobileApp ? '100' : mainScreenWidth" [style.margin-top]="'1em'">
        <content-presenter class="float-left h-100 w-100" [isSharingContent]="isSharingContent">
        </content-presenter>
        <div
        class="video-view-control bottom text-left"
        [style.width.%]="isLocked || isMobileApp? '100' : mainScreenWidth"
        >
          <button
            role="button"
            aria-label="Window Resizer"
            tabindex
            class='btn cursor-pointer control-link pull-right bg-gray-opacity'
            (mousedown)="dragStart($event)"
            (touchstart)="dragStart($event)"
            [hidden]="isLocked || isMobileApp">
            <i aria-hidden="true" class="fa fa-exchange-alt"></i>
          </button>
        </div>
      </div>
      <div [hidden]="!isRemoteDesktop" class="float-left h-100" [style.width.%]='100' [style.margin-top]="'1em'">
        <remote-desktop></remote-desktop>
      </div>
      <div
        *ngIf="collapseMode && !isSharingContent && !isRemoteDesktop"
        class="col-md-12 col-sm-12 col-xs-12 p-0 h-100"
        [ngClass]="{'float-left': !isMobileApp, 'col-lg-12 d-block': isLocked, 'col-sm-12': isMobileApp}"
        [style.width.%]="isLocked || isMobileApp ? '100' : mainScreenWidth"
        id="main-video-container"
        (click)="isMobileApp && collapseMode && clickOnMainVisibleVideos($event)">
        <div
        class="video-view-control bottom text-left"
        [style.width]="'100%'"
        >
          <button
            role="button"
            aria-label="Window Resizer"
            tabindex
            class='btn cursor-pointer control-link pull-right bg-gray-opacity'
            (mousedown)="dragStart($event)"
            (touchstart)="dragStart($event)"
            [hidden]="isLocked || isMobileApp">
            <i aria-hidden="true" class="fa fa-exchange-alt"></i>
          </button>
        </div>
        <video-item
          class="col-md-12 p-0 h-100"
          *ngIf="mainViewVideo"
          [endpoint]="mainViewVideo.endpoint"
          [stream]="mainViewVideo.stream"
          [displayPopup]="false"
          [displayExpand]="false"
          [displayCompress]="false"
          [displayFlip]="false"
          [displayRotate]="isOperator"
          [displayTitle]="!isMobileApp"
          [displayTakePhoto]="localizationService.myLocalizationData.take_photo && isOperator"
          [displayVideoBlock]="isOperator"
          [videoElementId]="'main-video'"
          [isMainVideo]="true"
          [videoAspect]="videoAspect"
          [videoVolume]="videoVolume"
          [takingPhoto]="takingPhoto"
          (takePic)="takePicture($event)"
        >
        </video-item>
      </div>
      <div
        id="video-list-container"
        [hidden]="isCleanViewMode || isLocked"
        [style.width.%]= "isMobileApp ? 100 : (100 - mainScreenWidth)"
        [style.maxWidth]="isMobileApp && !collapseMode ? '18rem' : ''"
        [style.maxHeight]="isMobileApp && !collapseMode ? '18rem' : ''"
        [ngClass]="{
        'p-0': collapseMode && !isMobileApp,
        'h-100': !isMobileApp,
        'pip-mobile': isMobileApp,
        'float-right': !isMobileApp,
        'position-absolute': true
        }">
        <ng-container
          *ngIf="showPrimaryPIP && !loopbackInMainWindow">
          <video-item
            class="col-md-12 p-0 video"
            [endpoint]="primaryPIP.endpoint"
            [stream]="primaryPIP.stream"
            [videoElementId]="'ep-video-'+primaryPIP.endpoint.rtcId+'-'+primaryPIP.stream.id"
            [displayPopup]="!isLocked"
            [displayExpand]="!isLocked"
            [displayCompress]="!isLocked"
            [displayRotate]="false"
            [participants]="conference?.active?.length"
            [displayTitle]="!isMobileApp"
            [videoVolume]="videoVolume"
            [isPrimaryPIP]="true"
            [isMobileApp]="isMobileApp"
          (showInMainView)="showInMainView($event, true)">
          </video-item>
        </ng-container>
        <ng-container *ngIf="collapseMode && !isMobileApp">
          <ng-container *ngFor="let videoKey of myVideos | keys">
            <video-item
              class="col-md-12 p-0 video"
              [endpoint]="myVideos[videoKey].endpoint"
              [stream]="myVideos[videoKey].stream"
              [videoElementId]="'ep-video-'+myVideos[videoKey].endpoint.rtcId+'-'+videoKey"
              [displayPopup]="collapseMode && !isLocked"
              [displayExpand]="collapseMode && !isLocked"
              [displayCompress]="collapseMode && !isLocked"
              [displayFlip]="isMobileApp && myVideos[videoKey].stream.streamName === 'camera'"
              [displayRotate]="false"
              [displayTitle]="!isMobileApp"
              [participants] ="conference?.active?.length"
              [videoAspect]="videoAspect"
              [videoVolume]="videoVolume"
              [isMobileApp]="isMobileApp"
              (showInMainView)="showInMainView($event, isMobileApp && myVideos[videoKey].stream.streamName === 'camera')">
            </video-item>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="collapseMode || loopbackInMainWindow">
          <ng-container *ngFor="let videoKey of otherVideos | keys; let index = index">
            <video-item
              class="col-md-12 p-0 video"
              [hidden]="isMobileApp && mainViewVideo && mainViewVideo.stream.id == otherVideos[videoKey].stream.id"
              [endpoint]="otherVideos[videoKey].endpoint"
              [ngStyle]="!collapseMode ? otherVideos[videoKey].style : null"
              [stream]="otherVideos[videoKey].stream"
              [videoElementId]="'ep-video-'+otherVideos[videoKey].endpoint.rtcId+'-'+videoKey"
              [displayPopup]="collapseMode && !isLocked"
              [displayExpand]="collapseMode && !isLocked"
              [displayCompress]="collapseMode && !isLocked"
              [displayFlip]="false"
              [displayRotate]="!collapseMode && isOperator"
              [displayTitle]="!isMobileApp"
              [participants] ="conference?.active?.length"
              [displayTakePhoto]="localizationService.myLocalizationData.take_photo && isOperator"
              [displayVideoBlock]="isOperator"
              [videoAspect]="videoAspect"
              [videoVolume]="videoVolume"
              [isMobileApp]="isMobileApp"
              (showInMainView)="showInMainView($event)">
            </video-item>
          </ng-container>
        </ng-container>
      </div>
      <div
        *ngIf="!collapseMode && !isRemoteDesktop"
        id="video-scene"
        [style.width.%]="isLocked || isMobileApp ? '100' : mainScreenWidth"
        class="full-screen"
        [ngClass] = "{ 'float-left':!isMobileApp }"
        (click)="isMobileApp && clickOnMainVisibleVideos($event)"
      >
        <div
        class="video-view-control bottom text-left"
        [style.width.%]="isLocked || isMobileApp ? '100' : mainScreenWidth"
        >
          <button
            role="button"
            aria-label="Window Resizer"
            tabindex
            class='btn cursor-pointer control-link pull-right bg-gray-opacity'
            (mousedown)="dragStart($event)"
            (touchstart)="dragStart($event)"
            [hidden]="isLocked || isMobileApp">
            <i aria-hidden="true" class="fa fa-exchange-alt"></i>
          </button>
        </div>
        <ng-container *ngFor="let video of broadcastingVideos">
          <video-item
            class="p-0 video"
            [hidden]="true"
            [endpoint]="video.endpoint"
            [stream]="video.stream"
            [videoElementId]="'ep-video-'+video.endpoint.rtcId+'-'+video.stream.id"
            [isMainVideo]="true"
            [displayPopup]="false"
            [displayExpand]="false"
            [displayCompress]="false"
            [displayTitle]="false"
            [displayFlip]="false"
            [participants] ="conference?.active?.length"
            [videoAspect]="videoAspect"
            [videoVolume]="videoVolume"
            [isMobileApp]="isMobileApp">
          </video-item>
        </ng-container>
        <ng-container *ngFor="let video of visibleVideos">
          <video-item
            class="p-0 video"
            *ngIf="video.position"
            [endpoint]="video.endpoint"
            [style.top]="video.position.topPercent+'%'"
            [style.left]="video.position.leftPercent + '%'"
            [style.width]="video.position.widthPercent +'%'"
            [style.height]="video.position.heightPercent + '%'"
            [style.color]="localizationService.myLocalizationData.video_screen ? localizationService.myLocalizationData.video_screen.backgroundColor : 'black'"
            [stream]="video.stream"
            [videoElementId]="'ep-video-'+video.endpoint.rtcId+'-'+video.stream.id"
            [isMainVideo]="true"
            [displayPopup]="false"
            [displayExpand]="false"
            [displayCompress]="false"
            [displayTitle]="false"
            [displayFlip]="false"
            [displayRotate]="isOperator"
            [displayTakePhoto]="localizationService.myLocalizationData.take_photo && isOperator"
            [displayVideoBlock]="isOperator"
            [participants] ="conference?.active?.length"
            [videoAspect]="videoAspect"
            [videoVolume]="videoVolume"
            [takingPhoto]="takingPhoto"
            (takePic)="takePicture($event)"
            [isMobileApp]="isMobileApp">
          </video-item>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<canvas
  id="video-scene-canvas"
  class="boxCommon full-screen"
  [hidden]="true"
></canvas>
<canvas
  id="snapshot-canvas"
  class="boxCommon full-screen"
  [hidden]="true"
></canvas>
<video-control [videos]="allVideos"
               [overallVolume]="videoVolume" ></video-control>
