<!--
    Copyright Compunetix Incorporated 2022-2023
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  amaggi, frivolta, kbender
-->

<div class="overview-container mt-3">
  <div class="overview-body container-fluid">
    <div class="row">
      <div class="section">
        Real time
      </div>
      <last-update class="ml-auto"
        [lastUpdate]="lastUpdate | formatDate : 'hh:mm:ss a'"
        (refresh)="refreshData()">
      </last-update>
    </div>

    <div class="row">
      <div class="col-md-3 mb-4">
        <tile
          [tileStyle]="'realtime'"
          [title]="'Calls in queue'"
          [value]="callsStatus.realtimeStatistics.inQueue"
          [comparePercentage]="percentages.inQueue"
          [subValue]="callsStatus.realtimeStatistics.inCallback"
          [subLabel]="'Callback'">
        </tile>
      </div>

      <div class="col-md-3 mb-4">
        <tile
          [tileStyle]="'realtime'"
          [title]="'Calls in progress'"
          [value]="callsStatus.realtimeStatistics.inProgress"
          [comparePercentage]="percentages.inProgress"
          [icon]="'/images/dashboard/inProgress.png'">
        </tile>
      </div>

      <div class="col-md-3 mb-4">
        <tile
          [tileStyle]="'realtime'"
          [title]="'Calls on hold'"
          [value]="callsStatus.realtimeStatistics.onHold"
          [comparePercentage]="percentages.onHold"
          [icon]="'/images/dashboard/onHold.png'">
        </tile>
      </div>

      <div class="col-md-3 mb-4">
        <tile
          [title]="'Agent Status'"
        >
          <div class="mt-3">
            <bar-chart [items]="barChartItems"></bar-chart>
          </div>
        </tile>
      </div>
    </div>


    <div class="row">
      <div class="section">
        Daily total
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 mb-4">
        <tile
          [tileStyle]="'dailyTotal1'"
          [title]="'Wait Time'"
          [value]="dailyTotal.daily.waitTime.avg"
          [subValue]="dailyTotal.daily.waitTime.max"
          [label]="'Average'"
          [subLabel]="'Maximum'"
          [comparePercentage]="percentages.yesterdayWaitTimePercentage"
          [comparePercentage2]="percentages.previousWeekWaitTimePercentage"
        >
        </tile>
      </div>

      <div class="col-md-4 mb-4">
        <tile
          [tileStyle]="'dailyTotal1'"
          [title]="'Talk Time'"
          [value]="dailyTotal.daily.talkTime.avg"
          [subValue]="dailyTotal.daily.talkTime.max"
          [label]="'Average'"
          [subLabel]="'Maximum'"
          [comparePercentage]="percentages.yesterdayTalkTimePercentage"
          [comparePercentage2]="percentages.previousWeekTalkTimePercentage"
        >
        </tile>
      </div>

      <div class="col-md-4 mb-4">
        <tile
          [title]="'Inbound / Outbound Calls'"
        >
          <div class="mt-3">
            <bar-chart [items]="inOutCallsChartData"></bar-chart>
          </div>

        </tile>
      </div>

      <div class="col-md-4 mb-4">
        <tile
          [tileStyle]="'dailyTotal2'"
          [title]="'Answered calls'"
          [value]="dailyTotal.daily.answered"
          [icon]="'/images/dashboard/answered.png'"
        >
        </tile>
      </div>

      <div class="col-md-4 mb-4">
        <tile
          [tileStyle]="'dailyTotal2'"
          [title]="'Abandoned calls'"
          [value]="dailyTotal.daily.abandoned"
          [icon]="'/images/dashboard/abandoned.png'"
        >
        </tile>
      </div>

      <div class="col-md-4 mb-4">
        <tile
          [tileStyle]="'dailyTotal2'"
          [title]="'Transferred calls'"
          [value]="dailyTotal.daily.transferred"
          [icon]="'/images/dashboard/transferred.png'"
        >
        </tile>
      </div>

    </div>
  </div>

</div>
