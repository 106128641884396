/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
//import { ArchwizardModule } from "angular-archwizard";
import { NgProgressModule } from "ngx-progressbar";

import { AlertModule } from "../alert/alert.module";
import { SharedModule } from "../shared/shared.module";
import { LayoutModule } from "../layout/layout.module";
import { HelperComponent } from "./helper.component";
import { HelperWizardComponent } from "./helper-wizard.component";
import { HelperService } from "./helper.service";

@NgModule({
  imports: [
    BrowserModule,
  //  ArchwizardModule,
    NgProgressModule.withConfig({
      meteor: false,
      thick: true,
      min: 2,
      max: 100
    }),
    SharedModule,
    LayoutModule,
    AlertModule
  ],
  declarations: [HelperComponent, HelperWizardComponent],
  exports: [HelperComponent, HelperWizardComponent],
  providers: [HelperService]
})
export class HelperModule {}
