<div
  class="col-8 col-sm-9 col-md-7 col-lg-6 col-xl-4"
  id="InspectThumbnailContainer"
>
  <div id="InspectThumbnailBody" (click)="toggleThumbnailView()">
    <ul class="InspectThumbnailContainer" [ngClass]="{'row': !isExpanded}">
      <ng-container *ngIf="!isExpanded">

        <li class="col-4" *ngIf="mainStream">
          <i
            class="fas fa-microphone"
            [hidden]="rtcClient.audioMuted"
          ></i>
          <i
            class="fas fa-microphone-slash"
            [hidden]="!rtcClient.audioMuted"
          ></i>
          <volume-meter
            class="volume-meter"
            [id]="'inspect-thumbnail-mic-volume-meter'"
            [height]='15'
            [stream]='mainStream'
            [hidden]="rtcClient.audioMuted"
          ></volume-meter>
          <volume-meter *ngIf="micStream" hidden [stream]="micStream" (volumeChanged)="micVolumeChanged($event)"></volume-meter>
        </li>
        <li class="col-4">
          <i class="fas fa-long-arrow-alt-up"></i>
          Upload: {{totalSenderBitrateInKbps}} kbps
        </li>
        <li class="col-4">
          <i class="fas fa-long-arrow-alt-down"></i>
          Download: {{totalReceiverBitrateInKbps}} kbps
        </li>


      </ng-container>

      <ng-container *ngIf="isExpanded">

        <ng-container>
          <li>Microphone: {{microphone}}</li>
          <li *ngIf="mainStream">
            <i
              class="fas fa-microphone"
              [hidden]="rtcClient.audioMuted"
            ></i>
            <i
              class="fas fa-microphone-slash"
              [hidden]="!rtcClient.audioMuted"
            ></i>
            <volume-meter
              [id]="'inspect-thumbnail-mic-volume-meter'"
              [height]='5'
              [stream]='mainStream'
              [hidden]="rtcClient.audioMuted"
            ></volume-meter>
          </li>
          <li>Camera: {{camera}}</li>
          <li>Speaker: {{speaker}}</li>
        </ng-container>
        
        <ng-container *ngFor="let ep of participants">
          <hr />
          <li *ngIf="ep.rtcId && ep.rtcId !== myEndpoint.rtcId">
            <ul>
              <li *ngIf="ep.name">Name: {{ep.name}}</li>
              <ng-container *ngFor="let streamKey of ep.streams | keys; let i = index">
                <li *ngIf="i === 0">
                  <i
                    class="fas fa-volume-up"
                    [hidden]="ep.isMicMuted || ep.isAudioMuted"
                  ></i>
                  <i
                    class="fas fa-volume-mute"
                    [hidden]="!ep.isMicMuted && !ep.isAudioMuted"
                  ></i>
                  <volume-meter
                    class="volume-meter"
                    [id]="'inspect-thumbnail-' + streamKey +'-volume-meter'"
                    [height]='5'
                    [stream]='ep.streams[streamKey]'
                  ></volume-meter>
                </li>
              </ng-container>
              <ng-container *ngIf="ep.lastStats">
                <li *ngFor="let sender of ep.lastStats.senders | sortBy:['mediaType','mediaSourceId']:['asc','desc']">
                  <hr />
                  <ul *ngIf="sender">
                    <li>Direction: Outbound</li>
                    <li>
                      Media Type: {{sender.mediaType}}
                    </li>
                    <li>
                      Bitrate: {{(sender.bitrate || 0) / 1024}} kbps
                    </li>
                    <li>
                      Packets Lost: {{sender.packetsLost || 0}}
                    </li>
                    <ng-container *ngIf="sender.mediaType === 'video'">
                      <li>
                        Resolution: {{sender.frameSize}}
                      </li>
                      <li>
                        Frame Rate: {{sender.frameRate}}
                      </li>
                    </ng-container>
                  </ul>
                </li>
                <li
                  *ngFor="let receiver of ep.lastStats.receivers | sortBy:['mediaType','mediaSourceId']:['asc','desc']"
                >
                  <hr />
                  <ul *ngIf="receiver">
                    <li>Direction: Inbound</li>
                    <li>
                      Media Type: {{receiver.mediaType}}
                    </li>
                    <li>
                      Bitrate: {{(receiver.bitrate || 0) / 1024}} kbps
                    </li>
                    <li>
                      Packets Lost: {{receiver.packetsLost || 0}}
                    </li>
                    <ng-container *ngIf="receiver.mediaType === 'video'">
                      <li>
                        Resolution: {{receiver.frameSize}}
                      </li>
                      <li>
                        Frame Rate: {{receiver.frameRate}}
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ng-container>

    </ul>
  </div>
</div>