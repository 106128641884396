<!--
    Copyright Compunetix Incorporated 2021
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<top-bar></top-bar>
<nav-bar></nav-bar>
<main-container></main-container>
<dialog-container-in-page>
  <page-title></page-title>
  <h1 class="container text-center">License QR Code Scanner</h1>
  <hr>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div *ngIf="!qrcode">
          <zxing-scanner (scanSuccess)="scanSuccessHandler($event)"></zxing-scanner>
        </div> 
        <p class="h5">
          Please target your camera at the QR Code on the license activation page.
        </p>   
        <div *ngIf="qrcode">
          <p class="token-box">{{qrcode}}</p>
        </div>  
      </div>
      <div class="col-md-6" *ngIf="error">
        <p class="alert-danger p-1">{{error}}</p>
        <button class="btn" (click)="reset()">Try Again</button>
      </div>
      <div class="col-md-6" *ngIf="proof">
        <p class="h5">
          To finish the activation process, press "continue" on the device you're activating and input the following activation proof:
        </p>
        <p class="alert-success token-box p-1">{{proof}}</p>
        <button class="btn" (click)="copy()">Copy</button>
      </div>
    </div>
  </div>
  <hr>
</dialog-container-in-page>
<footer></footer>

