/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */
import {Component, Input, OnInit} from "@angular/core";
import {IPercentageData} from "../overview/tile.component";

// USed in Overview and Customer's to show percentage comparison
@Component({
  selector: "perc-compare",
  templateUrl: "./perc-compare.component.html",
  styleUrls: ["./perc-compare.component.scss"],
})
export class PercCompareComponent implements OnInit {
  @Input() percentageData: IPercentageData;

  ngOnInit(): void {
  }
}
