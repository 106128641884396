/**
 * Copyright Compunetix Incorporated 2018-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, jporter
 */
import { Pipe } from "@angular/core";
import { FileTransferStatus } from "companion";

/**
* Translate file transfer status to label style class name.
* @example Usage:
* ```html
* <p>
  {{ file.status | fileStatusStyle }}
</p>
* ```
*/
@Pipe({ name: "fileStatusStyle" })
export class FileStatusStylePipe {
  transform(status: FileTransferStatus): any {
    switch (status) {
      case FileTransferStatus.idle:
      case FileTransferStatus.started:
      case FileTransferStatus.transmitting:
        return "info";
      case FileTransferStatus.failed:
        return "danger";
      case FileTransferStatus.finished:
        return "success";
    }
  }
}
