<!--
    Copyright Compunetix Incorporated 2017-2023
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender
-->
<div id="connect-screen">

  <!--  Guest Available-->
  <div
    class="join-conference-container pull-center t-20"
    [style.top]="localizationService.myLocalizationData.connect_screen.top"
    [style.color]="localizationService.myLocalizationData.connect_screen.fontColor"
    [style.fontSize]="localizationService.myLocalizationData.connect_screen.fontSize"
    [style.backgroundColor]="localizationService.myLocalizationData.connect_screen.backgroundColor"
    [style.width.%]="localizationService.myLocalizationData.connect_screen.logoSize ?
      localizationService.myLocalizationData.connect_screen.logoSize : '50'"
    *ngIf="
      presenceStatus === presenceStatusClass.available && 
      !localizationService.myLocalizationData.connect_screen.autoConnect &&
      (cameraPermission === cameraPermissionClass.allowed ||
       microphonePermission === cameraPermissionClass.allowed)
      "
  >
    <div
      class="title-container animated fadeIn text-center"
    >
      <img
        *ngIf="localizationService.myLocalizationData.connect_screen.logo"
        crossorigin="anonymous"
        [attr.src]="localizationService.myLocalizationData.connect_screen.logo"
      />
      <h1
        *ngIf="localizationService.myLocalizationData.connect_screen.title"
        class="text-center"
        [innerHTML]="localizationService.myLocalizationData.connect_screen.title | trustHtml"
      >
      </h1>
      <h4
        *ngIf="localizationService.myLocalizationData.connect_screen.subtitle"
        class="text-center"
        id="connect-subtitle"
        [innerHTML]="localizationService.myLocalizationData.connect_screen.subtitle | trustHtml"
      >
      </h4>
      <p
        class="text-center"
        [hidden]="localizationService.myLocalizationData.connect_screen.connectButtonHidden || touchless"
      >
        <a
          tabindex
          [title]="localizationService.myLocalizationData.connect_screen.connectButtonText"
          class="btn btn-success col-md-10 btn-lg text-uppercase shadow-lg"
          (touchstart)="connect($event)"
          (mousedown)="connect($event)"
          [ngStyle]="{
              'color': localizationService.myLocalizationData.connect_screen.connectButtonTextColor,
              'background-color': localizationService.myLocalizationData.connect_screen.connectButtonBackgroundColor,
              'border-color': localizationService.myLocalizationData.connect_screen.connectButtonBackgroundColor
            }"
          [ngClass]="{'btn-round': !localizationService.myLocalizationData.connect_screen.useRectangleButton}"
        >
          {{localizationService.myLocalizationData.connect_screen.connectButtonText}}
        </a>
      </p>
      <div class="text-center" [hidden]="!touchless">
        <label id="touchless-counter-label" style="font-size: 2.5rem;" for="touchless-counter">
          {{localizationService.myLocalizationData.connect_screen.touchless_message || ''}}
        </label>
        
        <div class="spinner">
          <div class="spinner-label" [style.color]="localizationService.myLocalizationData.connect_screen.fontColor">
            <span id="touchless-counter">{{timeStillSeconds | number: '2.0-0'}}</span>
          </div>
          <div class="spinner-anim">
            <div class="spinner-item"></div>
            <div class="spinner-item"></div>
            <div class="spinner-item"></div>
          </div>
        </div>
      
      </div>
    </div>
  </div>

  <!--  Guest Connecting || On Hold || Invisible-->
  <div
    id="waiting-video-container"
    *ngIf="
      presenceStatus === presenceStatusClass.connecting
      || presenceStatus === presenceStatusClass.onhold
      || presenceStatus === presenceStatusClass.invisible"
    [ngClass]="{
        'full-screen': !localizationService.myLocalizationData.topnav
                    && !localizationService.myLocalizationData.toolbar
                    && !localizationService.myLocalizationData.footer,
        'expand-to-top': !localizationService.myLocalizationData.topnav || !isNavShown,
        'expand-to-bottom': !localizationService.myLocalizationData.footer || !isNavShown
      }"
    [style.top]="localizationService.myLocalizationData.connect_screen.top"
    [style.color]="localizationService.myLocalizationData.connect_screen.fontColor"
    [style.fontSize]="localizationService.myLocalizationData.connect_screen.fontSize"
    [style.backgroundColor]="localizationService.myLocalizationData.connect_screen.backgroundColor"
  >
    <!--    Embedded Url-->
    <iframe *ngIf="localizationService.myLocalizationData.connect_screen.waitScreenEmbeddedUrl"
            [src]="embeddedLink"
            [attr.title]="localizationService.myLocalizationData.connect_screen.waitScreenEmbeddedTitle"
            [style]="localizationService.myLocalizationData.connect_screen.waitScreenEmbeddedStyle">
    </iframe>

    <!--    Connecting Image-->
    <img
      *ngIf="localizationService.myLocalizationData.connect_screen.connectingImage"
      [style.top]="localizationService.myLocalizationData.connect_screen.top"
      [attr.src]="localizationService.myLocalizationData.connect_screen.connectingImage"
      crossorigin="anonymous"
      class="connecting-image"
    />
    <!--    Unmute Button-->
    <button id="unmuteButton" *ngIf="displayWaitVideo && isWaitVideoMuted" class="btn btn-lg btn-secondary" (click)="play()">Tap here to unmute</button>
    <!--    Wait Video-->
    <video
      *ngIf="displayWaitVideo"
      crossorigin="anonymous"
      [attr.src]="localizationService.myLocalizationData.connect_screen.waitVideo"
      playsinline
      loop
      class="waiting-media"
      id="waiting-video"
      muted
      [controls]="!isLocked"
      [volume]="videoVolume"
      (touchstart)="play()"
    ></video>
    <!--    Wait Image-->
    <div
      *ngIf="!displayWaitVideo && displayWaitImage"
      [style.backgroundImage]="'url(' + localizationService.myLocalizationData.connect_screen.waitImage + ')'"
      class="waiting-media waiting-image"
    ></div>
    <div class="animated fadeIn" [hidden]="localizationService.isLoading">
      <!--    Connect info panel-->
      <guest-wait
        [hasOperatorOnline]="hasOperatorOnline"
        [queueStatus]="queueStatus"
        [contextConnectingTitle]="connectingLabelText"
        [skillTags]="skillTags"
        [onHold]="presenceStatus === presenceStatusClass.onhold"
        (guestDisconnect)="disconnect()"
        (callBackGuest)="callBackGuest($event)"
        [touchless]="touchless"
        [showAutodropWarning]="showAutodropWarning"
        [touchlessAutodropCount]="touchlessAutodropCount"
      ></guest-wait>
    </div>
  </div>

  <!-- permission to devices was rejected by guest-->
  <div
    *ngIf="(cameraPermission === cameraPermissionClass.disallowed &&
            microphonePermission === cameraPermissionClass.disallowed) &&
           presenceStatus !== presenceStatusClass.callback"
    class="guest-reject-permission"
    [innerText]="localizationService.myLocalizationData.connect_screen.guestRejectCameraPermissionMessage ||
    'Please allow the website to use your camera and microphone, Please change site permissions and refresh the web page
  or use the callback number to be contacted by telephone'">
  </div>

  <!-- callback option -->
  <call-back-panel
  *ngIf="localizationService.myLocalizationData.connect_screen.callBackButtonVisible &&
         (cameraPermission === cameraPermissionClass.disallowed &&
          microphonePermission === cameraPermissionClass.disallowed) &&
         presenceStatus !== presenceStatusClass.callback"
  class="w-50"
  style="position:absolute; left:25%;"
  (dialOutData)="callBackGuest($event)"
  (cancelCallBack)="cancelCallBack()"
  ></call-back-panel>

  <!--  Guest in callback status-->
  <div
    *ngIf="presenceStatus === presenceStatusClass.callback"
    class="guest-callback-status"
    [innerText]="localizationService.myLocalizationData.connect_screen.callBackCloseBrowserMessage ||
      'You can now close the browser.\n You will be contacted at the phone number you provided as soon as possible.'"
  >
  </div>

</div>
