/**
 * Copyright Compunetix Incorporated 2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component } from "@angular/core";
import { Browser } from "companion";
import { RestService } from "../shared/services/rest.service";

@Component({
  selector: "license-scanner",
  templateUrl: "./license-scanner.component.html",
  styleUrls: ["./license-scanner.component.scss"],
})
/**
 * license scanner layout
 */
export class LicenseScannerComponent {
  proof: string;
  qrcode: string;
  error: string;
  constructor(private restService: RestService) {
    //
  }

  /**
   * success event handler for scanner
   * @param qrcode: string - scan result
   */
  scanSuccessHandler(qrcode: string) {
    this.qrcode = qrcode;
    this.error = null;
    this.proof = null;
    this.activate(qrcode)
      .then((data) => {
        this.proof = data.activation.proof;
      })
      .catch((error) => {
        this.error = error;
      });
  }

  /**
   * try activate license
   */
  activate(qrcode: string) {
    return new Promise((resolve: (value: any) => void, reject: (reason?: any) => void) => {
      this.restService
        .post("/activate-license", JSON.parse(atob(qrcode)))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error: any) => {
            reject(error._body);
          }
        );
    });
  }

  reset() {
    this.qrcode = null;
    this.error = null;
    this.proof = null;
  }

  copy() {
    Browser.copyTextToClipboard(this.proof);
  }
}
