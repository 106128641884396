/**
 * Copyright Compunetix Incorporated 2016-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, dcantoni, kbender
 */

import {RestService} from "./rest.service";
import {Injectable} from "@angular/core";
import {
  AlertLevel,
  IEndpoint,
  IExpandedActiveConference,
  IRTCClient,
  PresenceStatus,
  RecordMode,
  EndpointService,
  ISkillTags,
  Companion,
  IEndpointRef,
  PeerConnectionEvent,
  PeerConnectionSm
} from "companion";
import {LocalizationService} from "../../localization/localization.service";
import { Device, Call } from "@twilio/voice-sdk";

const AUDIO_TAG_ID: string = "audio-voice";

@Injectable({
  providedIn: "root"
})
export class VoiceService {
  private myEndpoint: IEndpoint;
  private isMuted: boolean;
  private twilioDevice: Device;
  private twilioCall: Call;
  private accepted: boolean;

  initializationPromise: Promise<void>;
  rtcClient: IRTCClient = Companion.getRTCClient();

  constructor(private restService: RestService, private localizationService: LocalizationService) {
  }

  /**
   * Initialize the voice service: it get the token from the server and create twilio device
   * @param {boolean} isMuted - set the initial mute state
   * @param {boolean} inviterRTCId - RTC ID of the operator that is calling - it is required only for guest
   * @param updateHandler
   * @returns {Promise<void>}
   */
  init(isMuted: boolean = false, updateHandler?: (state, data?) => void): Promise<void> {
    this.destroy();
    return new Promise((resolve: () => void, reject: (error: Error) => void) => {
      this.isMuted = isMuted;
      this.myEndpoint = EndpointService.getSharedInstance().myEndpoint;

      this.getToken()
      .subscribe(
        (data: any) => {
          const {token, NTS_Token, edgeLocations} = data;
          this.initializeTwilioDevice(token, edgeLocations, NTS_Token.iceServers, updateHandler);
          resolve();
        },
        (error: any) => {
          this.rtcClient.alertHandler("VOICE_SERVICE_TOKEN_ERROR",
            "[VoiceService] Could not get a token from Voice Service");
          reject(error);
        }
      );
    });
  }

  /**
   * get voice token
   * @param inviterRTCId
   * @private
   */
  private getToken(): any {
    return this.restService
      .post("/voice-token", {
        rtcId: this.myEndpoint.rtcId,
        groupId: this.myEndpoint.groupId, // the theme to fetch twillio acct.
      });
  }

  /**
   * Initialize Twilio device
   * @param token
   * @param edgeLocations
   * @param iceServers
   * @param updateHandler
   * @private
   */
  private initializeTwilioDevice(token: string, edgeLocations: string[], iceServers, updateHandler: (state, data?) => void) {
    const options: Device.Options = {
      disableAudioContextSounds: true,
      sounds: {
        incoming: "null",
        outgoing: "null",
        disconnect: "null"
      }
    };

    if (edgeLocations && edgeLocations.length > 0) {
      options.edge = edgeLocations;
      this.rtcClient.alertHandler("Voice Region:", edgeLocations.join(", "), AlertLevel.success);
    }

    this.twilioDevice = new Device(token, options);
    this.twilioDevice.register();

    this.registerEvents(updateHandler, iceServers);
  }

  /**
   * register twilio events
   * @param updateHandler
   * @private
   */
  private registerEvents(updateHandler: (state, data?) => void, iceServers: any) {
    this.twilioDevice.on(Device.EventName.Registered, device => {
      updateHandler ? updateHandler("registered", device) : this.rtcClient.alertHandler("VOICE_SERVICE_READY",
        "[VoiceService] Device registered");
    });

    this.twilioDevice.on(Device.EventName.Unregistered, device => {
      updateHandler && this.twilioDevice ? updateHandler("unregistered", device) : this.rtcClient.alertHandler("VOICE_SERVICE_OFFLINE",
        "[VoiceService] Device is unregistered");
    });

    this.twilioDevice.on(Device.EventName.TokenWillExpire, () => {
      this.getToken().subscribe((data: any) => {
        if (data) {
          this.twilioDevice.updateToken(data.token);
        }
      });
    });

    this.twilioDevice.on(Device.EventName.Error, (twilioError, call) => {
      updateHandler ? updateHandler("error", twilioError) : this.rtcClient.alertHandler("VOICE_SERVICE_ERROR",
      `[VoiceService] Device error: ${twilioError.code} (${call?.parameters?.From})`);
    });

    this.twilioDevice.on(Device.EventName.Incoming, (call: Call) => {
      if (!this.twilioCall || (this.twilioCall?.parameters != call?.parameters)) {
        this.updateInputDevice();
        this.rtcClient.alertHandler("VOICE_SERVICE_INCOMING", "[VoiceService] Incoming connection from " + call.parameters.From);

        call.on("accept", () => {
          this.accepted = true;
          // update the peer SM to connected.
          let voiceRtcId = call.parameters.CallSid
          this.rtcClient.alertHandler(`VOICE_SERVICE_INCOMING_ACCEPTED", "[VoiceService] Incoming accepted ${voiceRtcId}`);
          if(this.getVoicePeerSm())
          {
            this.getVoicePeerSm().event(PeerConnectionEvent.connected);
          }
          // take the mut value of the rtcCLient
          call.mute(this.rtcClient.audioMuted);
        });

        call.on("disconnect", () => {
          let voiceRtcId = call.parameters.CallSid
          this.rtcClient.alertHandler(`VOICE_SERVICE_INCOMING_DISCONNECTED", "[VoiceService] Incoming disconnect ${voiceRtcId}`);
          if(this.getVoicePeerSm())
          {
            this.getVoicePeerSm().event(PeerConnectionEvent.closed);
          }
          this.deleteTwilioConnection();
        });

        call.on("error", (error: any) => {
          this.rtcClient.alertHandler("VOICE_SERVICE_INCOMING_ERROR", "[VoiceService] Incoming call error: " + error.code);
          this.deleteTwilioConnection();
        });

        call.on("mute", (muted: boolean) => {
          this.rtcClient.alertHandler("VOICE_SERVICE_MUTE", "[VoiceService] Incoming call mute" + muted);
        });

        this.twilioCall = call;

        const acceptOptions: Call.AcceptOptions = {};
        if (iceServers) {
          acceptOptions.rtcConfiguration = {
            iceServers: iceServers
          };
          this.rtcClient.alertHandler("Voice Ice Servers", iceServers);
        }
        this.twilioCall.accept(acceptOptions);
      } else {
        this.rtcClient.alertHandler("VOICE_SERVICE_INCOMING (DUP)", `[VoiceService] ${call.parameters}`);
      }
    });
  }

  /**
   * Send a string of digits
   * @param digits
   */
  sendDigit(digits: string) {
    if (this.twilioCall) {
      this.twilioCall.sendDigits(digits);
    }
  }

  /**
   * update audio source device
   */
  updateInputDevice() {
    if (this.rtcClient.audioSrcId) {
      this.twilioDevice.audio.setInputDevice(this.rtcClient.audioSrcId)
      .then(() => {
        this.rtcClient.alertHandler("VOICE_SERVICE_UPDATE_INPUT_DEVICE",
          "[VoiceService] Input device updated: " + this.rtcClient.audioSrcId);
      })
      .catch(error => {
        this.rtcClient.alertHandler("VOICE_SERVICE_UPDATE_INPUT_DEVICE_ERROR",
          "[VoiceService] Input device update error: " + error.code);
      });
    }
  }

  /**
   * Adjust twilio volume by creating a tag audio and associating the remote stream to it
   * @param volume
   */
  adjustVolume(volume: number) {
    if (this.twilioCall) {
      try {
        let audio: HTMLAudioElement = document.getElementById(AUDIO_TAG_ID) as HTMLAudioElement;
        if (!audio) {
          const htmlEl = document.createElement("audio");
          htmlEl.style.display = "none";
          htmlEl.setAttribute("id", AUDIO_TAG_ID);
          document.body.appendChild(htmlEl);
          audio = htmlEl;
        }

        const stream = this.twilioCall.getRemoteStream();
        audio.muted = false;
        audio.srcObject = stream.clone();
        audio.volume = volume;
        stream.getAudioTracks().forEach(track => track.stop());
        audio.play().catch();
      } catch (e) {
        this.rtcClient.alertHandler("VOICE_SERVICE", "[VoiceService] failed to adjust volume");
      }
    }
  }

  /**
   * return remote stream
   * @private
   */
  private getRemoteStream(): MediaStream {
    let audio: HTMLAudioElement = document.getElementById(AUDIO_TAG_ID) as HTMLAudioElement;
    if (this.twilioCall && audio) {
      return audio.srcObject as MediaStream;
    }

    return null;
  }

  /**
   * return local stream
   * @private
   */
  private getLocalStream(): MediaStream {
    if (this.twilioCall) {
      const stream = this.twilioCall.getLocalStream();
      if (stream) {
        stream["streamName"] = "voice";
        return stream;
      }
    }

    return null;
  }

  /**
   * add local stream to endpoint
   * @param endpointsToBeRecorded
   * @param localStream
   * @param endpoint
   * @private
   */
  private addLocalStreamToEp(endpointsToBeRecorded: IEndpoint[], localStream: MediaStream, endpoint: IEndpoint) {
    if (endpoint && localStream) {
      endpoint.streams[localStream.id] = localStream;
    }
  }

  /**
   * add remote stream to endpoint
   * @param endpointsToBeRecorded
   * @param remoteStream
   * @param endpoint
   * @private
   */
  private addRemoteStreamToEp(endpointsToBeRecorded: IEndpoint[], remoteStream: MediaStream, endpoint: IEndpoint) {
    if (remoteStream && endpoint && endpoint.status == PresenceStatus.busy) {
      endpoint.streams[remoteStream.id] = remoteStream;
      endpointsToBeRecorded.push(endpoint);
    }
  }

  /**
   * update endpoints streams to handle voice audio to record
   * @param audioMode
   * @param videoEndpoints
   * @param endpointsToBeRecorded
   */
  updateEndpointStreamsToRecord(audioMode: RecordMode, videoEndpoints: IEndpointRef[], endpointsToBeRecorded: IEndpoint[]) {
    const voiceEp = videoEndpoints.find((ep) => ep.isVoiceEp);
    const remoteStream = this.getRemoteStream();
    const localStream = this.getLocalStream();

    if (localStream) {
      delete this.myEndpoint.streams[localStream.id];
    }
    if (remoteStream) {
      let expandedVoiceEp = EndpointService.getSharedInstance().getEndpointById(voiceEp.rtcId);
      delete expandedVoiceEp.streams[remoteStream.id];
    }

    switch (audioMode) {
      case RecordMode.disabled:
        break;
      case RecordMode.local:
        this.addLocalStreamToEp(endpointsToBeRecorded, localStream, this.myEndpoint);
        break;
      case RecordMode.remote:
        this.addRemoteStreamToEp(endpointsToBeRecorded, remoteStream, voiceEp);
        break;
      case RecordMode.both:
        this.addLocalStreamToEp(endpointsToBeRecorded, localStream, this.myEndpoint);
        this.addRemoteStreamToEp(endpointsToBeRecorded, remoteStream, voiceEp);
        break;
      default:
        break;
    }
  }

  /**
   * update endpoints to remove voice streams
   * @param endpointsToBeRecorded
   */
  removeVoiceEndpointStreamsToRecord(endpointsToBeRecorded: IEndpoint[]) {
    endpointsToBeRecorded.forEach(ep => {
      _.keys(ep.streams).forEach(key => {
        if (ep.streams[key]["streamName"] === "voice") {
          delete ep.streams[key];
        }
      });
    });
  }

  /**
   * delete Twilio connection
   * @private
   */
  private deleteTwilioConnection() {
    this.twilioCall = null;
    this.accepted = false;
    let audio = document.getElementById(AUDIO_TAG_ID);
    if (audio) {
      document.body.removeChild(audio);
    }
  }

  /**
   * This function return true if the voice service is initialized
   * @returns {boolean}
   */
  isDeviceInitialized(): boolean {
    return !!this.twilioDevice;
  }

  public acceptedCall(): boolean {
    return (this.twilioCall && this.accepted);
  }

  isInitializationStarted(): boolean {
    return !!this.initializationPromise;
  }

  /**
   * This function return true if the init process has not started
   * @returns {boolean}
   */
  isNotInitialized(): boolean {
    return !this.isInitializationStarted() && !this.isDeviceInitialized();
  }

  /**
   * This function return true if the voice service is initialized and is ready to make and receive voice call
   * @returns {boolean}
   */
  isReady() {
    return this.twilioDevice && this.twilioDevice.state === Device.State.Registered;
  }

  /**
   * Mute Twilio audio
   *  @param muted: boolean - flag if set to mute
   */
  mute(muted: boolean) {
    this.rtcClient.alertHandler("VOICE_SERVICE_TOGGLE_MUTE",
      `[VoiceService] ${muted ? "muted" : "unmuted"}`);
    this.isMuted = muted;
    if (this.twilioDevice && this.twilioCall) {
      this.twilioCall.mute(muted);
    }
  }

  /**
   * Play digit tone
   * @param digit: string
   */
  playDigit(digit: string) {
    const filename = digit
      .replace("*", "star")
      .replace("#", "hash");
    var RELEASE_VERSION = '2.7.2';
    var SOUNDS_BASE_URL = 'https://sdk.twilio.com/js/client/sounds/releases/1.0.0';
    const soundUrl = SOUNDS_BASE_URL + `/dtmf-${filename}.mp3?cache=` + RELEASE_VERSION;
    let audio = new Audio(soundUrl);
    audio.load();
    audio.play().catch();
  }

  /**
   * Disconnect current device form the ongoing conference
   */
  disconnect() {
    if (this.twilioDevice && this.twilioCall) {
      this.rtcClient.alertHandler("VOICE_SERVICE_DISCONNECTED",
        `[VoiceService] Disconnected`);
      this.twilioCall.disconnect();
    }
  }

  /**
   * Destroy the voice service
   */
  destroy() {
    if (this.twilioDevice) {
      this.rtcClient.alertHandler("VOICE_SERVICE_DESTROYED",
        `[VoiceService] Destroyed`);
      this.twilioDevice.destroy();
    }
    this.twilioDevice = null;
    this.initializationPromise = null;
  }

  /**
   * This function add the current endpoint to the specified conference.
   *
   * @param {string} voiceRtcId - rtcId of the voice client. (can be different then conference ID in callback case)
   * @param {string} ownerRtcId - the user ID of the user that owns this voice conference (for looking up twilio acct.)
   * @returns {Promise<any>}
   */
  joinVoiceConference(voiceRtcId: string, ownerId : string): Promise<any> {
    return new Promise<void>((resolve: () => void, reject: (error: Error) => void) => {
      this.restService
      .post("/join-conference", {
        callerId: this.myEndpoint.rtcId,
        voiceRtcId: voiceRtcId,
        ownerId: ownerId,
        theme: this.myEndpoint.theme
      })
      .subscribe(
        () => {
          this.rtcClient.alertHandler("VOICE_SERVICE_JOIN_CONFERENCE",
            "[VoiceService] Join voice conference successfully!");
          resolve();
        },
        (error: any) => {
          this.rtcClient.alertHandler("VOICE_SERVICE_JOIN_CONFERENCE_ERROR",
            "[VoiceService] Could not join voice conference");
          reject(error);
        }
      );
    });
  }

  /**
   * Disconnect a voice participant from the current conference
   *
   * @param {string} voiceRtcId - rtcId voice client to disconnect
   * @param {string} confId - the conf ID to disconmect
   * @param {string} twilioConference - current conference
   * @returns {Promise<any>}
   */
  removeVoiceParticipant(voiceRtcId: string, confId : string): Promise<any> {
    return new Promise<void>((resolve: () => void, reject: (error: Error) => void) => {
      this.restService
      .post("/remove-participant", {
        groupId: this.myEndpoint.groupId,
        participantId: voiceRtcId,
        confId: confId
      })
      .subscribe(
        () => {
          this.rtcClient.alertHandler("VOICE_SERVICE_REMOVE_VOICE_PEER",
            `[VoiceService] Participant ${voiceRtcId} has been removed from the conference`);
          resolve();
        },
        (error: any) => {
          this.rtcClient.alertHandler("VOICE_SERVICE_REMOVE_VOICE_PEER_ERROR",
            `[VoiceService] An error occurred while removing the participant ${voiceRtcId} from the conference`);
          reject(error);
        }
      );
    });
  }

  /**
   * Perform a dial out
   *
   * @param {string} guestPhone - phone number to call
   * @param displayPhoneNumber - phone number to display
   * @param {string} guestName - name of the person to call
   * @param {string} conferenceId - the conference to use
   * @param skillTags - the skill tags to associate with the call
   * @param rtcIdCallback - the rtc id of the call back endpoint (null if endpoint is not callback)
   */
  dial(guestPhone: string, displayPhoneNumber: string, guestName: string, conferenceId: string,
    rtcIdCallback: string, skillTags?: ISkillTags) : Promise<void>{

    return new Promise ((resolve : () => void, reject: (error: Error) => void) => 
    {
      this.restService
      .post("/voice-out", {
        userId: this.myEndpoint.userId,
        userRtcId: this.myEndpoint.rtcId,
        from: this.localizationService.myLocalizationData.twilioConfig.number,
        to: guestPhone,
        displayPhoneNumber: displayPhoneNumber,
        guestName: guestName,
        rtcIdCallback: rtcIdCallback,
        skillTags: skillTags,
        queuesGroupId: Companion.getEndpointService().myEndpoint.groupId,
        conferenceId: conferenceId,
      })
      .subscribe(
        (response: any) => {
          this.rtcClient.alertHandler("VOICE_SERVICE_DIAL_OUT",
            `[VoiceService] Calling ${guestPhone} ...`);
           resolve();
        },
        (error: any) => {
          this.rtcClient.alertHandler("VOICE_SERVICE_DIAL_OUT_ERROR",
            `[VoiceService] Error during dial out`, AlertLevel.warning);
            // clear out dial it
            reject(new Error("Error during dial out"));
        }
      );
    });
  }

  /**
   * for now we only maintain one peer connection to a single voice service... look that up here
   */
  private getVoicePeerSm() : PeerConnectionSm
  {
    let voicePeer : PeerConnectionSm = 
      _.find([...Companion.getConferenceService().peerSmMap.values()], (peer : PeerConnectionSm) => {return peer.isVoicePeer});

    return voicePeer;
  }

  /**
   * Hangs up ringing call
   * @param phoneNumber - the phone number to hang up
   */
  hangupRingingCall(phoneNumber: string): Promise<void> {
    return new Promise<void>((resolve: () => void, reject: (error: Error) => void) => {
      this.restService
        .post("/remove-ringing-call", {
          userId: this.myEndpoint.userId,
          phoneNumber: phoneNumber
        })
        .subscribe(
          () => {
            this.rtcClient.alertHandler("VOICE_SERVICE_HANG_UP_RINGING",
              `[VoiceService] Hangup ringing call: ${phoneNumber}`);
            resolve();
          },
          (error: any) => {
            this.rtcClient.alertHandler("VOICE_SERVICE_HANG_UP_RINGING_ERROR",
              `[VoiceService] An error occurred while hanging up a ringing call`);
            reject(error);
          }
        );
    });
  }
}
