/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { RoleManagementService } from "./role-management.service";
import { IRole } from "companion";

@Component({
  selector: "role-details",
  templateUrl: "./role-details.template.html"
})
export class RoleDetailsComponent implements OnInit {
  @Input()
  role: IRole;

  submitted: boolean;
  failed: boolean;
  errorMessage: string;
  allPersmissions: any = {};
  roles: IRole[] = [];
  assignableRoles: { [roleId: string]: boolean } = {};

  /**
   * Emits events for the form when creates/edits/deletes occur.
   */
  @Output("onSubmit")
  changeEmitter: EventEmitter<IRole> = new EventEmitter<IRole>();

  /**
   * Emits events for the form when cancel occur.
   */
  @Output("onCancel")
  cancelEmitter: EventEmitter<void> = new EventEmitter<void>();

  onSubmit() {
    this.submitted = true;
    this.failed = false;
    this.errorMessage = null;
    this.role.rolesAssignable = [];
    _.forEach(this.assignableRoles, (checked: boolean, roleId: string) => {
      if (checked) {
        this.role.rolesAssignable.push(roleId);
      }
    });
    this.roleManagementService
      .editRole(this.role)
      .then(() => {
        this.submitted = false;
        this.changeEmitter.emit(this.role);
      })
      .catch((err: Error) => {
        this.submitted = false;
        this.failed = true;
        this.errorMessage = err.message;
      });
  }

  onCancel() {
    this.cancelEmitter.emit();
  }

  resetForm() {
    this.submitted = false;
    this.failed = false;
    this.errorMessage = null;
  }

  ngOnInit() {
    this.resetForm();
    this.getAllPermissions();
    this.loadAssignableRoles();
  }

  getAllPermissions() {
    this.roleManagementService
    .getAllPermissions()
    .then((permissions: any) => {
      this.allPersmissions = permissions;
    })
    .catch((err: Error) => {
      this.failed = true;
      this.errorMessage = err.message;
    });
    if (this.role.permissions == null) {
      this.role.permissions = {};
    }
  }

  loadAssignableRoles() {
    this.assignableRoles = {};
    _.forEach(this.role.rolesAssignable, (roleId: string) => {
      this.assignableRoles[roleId] = true;
    });
  }

  updateView(role: IRole, isEdit: boolean) {
    if (!isEdit) {
      let myRole: any = this.role as any;
      // delete the backing id so the edit creates a new record
      // rather than updating the old.
      delete myRole._id;

      // update the name to append _CLONE
      myRole.name = role.name + "_CLONE";
      role = myRole as IRole;
    }

    this.role = role;
    this.loadAssignableRoles();
  }

  constructor(private roleManagementService: RoleManagementService) {
    this.roleManagementService = roleManagementService;
    this.roles = this.roleManagementService.roles
  }
}
