/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "sidebar",
  templateUrl: "./sidebar.template.html"
})

/**
 * style list view
 */
export class SidebarComponent {
  /**
   * current selected flavor style
   */
  selectedStyle: string;

  /**
   * select style event
   */
  @Output("selectStyle") selectEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * new flavor style selected
   */
  select(style: string) {
    this.selectedStyle = style;
    this.selectEmitter.emit(style);
  }
}
