/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { VideoResolution } from "../settings/video-resolution";
import { IUser } from "./user.interface";
import { IMessagingTemplate } from "../group/group.interface";

export class User implements IUser {
  constructor(
    // credential
    public username?: string, // username
    public password?: string, // password
    public passwordDate?: Date, // the date the password was set (checked for expiration)
    public passwordResetToken?: string, // password reset token when a user requests a password reset
    public requirePasswordReset?: boolean, // flag indsicating that the user should be prompted to reset password.
    public loginAttempts?: number, // the amount of times login was attempted, reset after every successful login
    public lockoutUntil?: Date, // don't allow login before this date.
    // authenticated status
    public isAuthenticated?: boolean, // flag if the user is already authenticated successfully

    // the user account is disabled
    public isDisabled?: boolean,

    // the last time the user logged in (or was enabled/created)
    public lastLoginDate?: Date,

    // VCC flag
    public isOperator?: boolean, // flag if this is an operator
    public isRep?: boolean, // flag if this is a rep
    // access control
    public groups?: string[], // list of group ids which user belongs to
    public roles?: string[], // list of role ids which user assigned to
    public permissions?: any, // permissions this user has
    // preference kept in cookie
    public preferedBandwidth?: number, // prefered video bandwidth setting
    public preferedResolution?: VideoResolution, // prefered video resolution setting
    public preferedCameraDeviceId?: string,
    public preferedMicrophoneDeviceId?: string,
    public preferedSpeakerDeviceId?: string,
    public preferedLanguage?: string,
    public preferedAudioMute?: boolean,
    public preferedVideoMute?: boolean,
    public preferedCalendarView?: string, // The preferred calendar view for reservations
    // profile info
    public name?: string,
    public email?: string, // email address
    public phone?: string, // phone
    public company?: string, // company
    public reminder?: number, // When to send reservation reminders (in minutes before the conference begins.)
    public avatar?: string,
    // my contact info id
    public myContactInfos: string[] = [],
    // messaging template
    public messagingTemplate?: IMessagingTemplate
  ) {
    // nothing needed here
  }
}
