/**
 * Copyright Compunetix Incorporated 2017-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  andrea maggi
 */
import {Component, EventEmitter, Input, Output} from "@angular/core";
import { Companion, IEndpoint, IEndpointService, ISkillTags, StatsUtil } from "companion";
import { LocalizationService } from "../localization/localization.service";

@Component({
  selector: "guest-info",
  templateUrl: "./guest-info.template.html"
})
export class GuestInfoComponent {
  @Input() endpoint: IEndpoint;
  @Input() canSendLogs: boolean;
  @Output("requestToSendLogs") requestToSendLogs: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  endpointService: IEndpointService;

  constructor(protected localizationService: LocalizationService) {
    this.endpointService = Companion.getEndpointService();
  }

  get connectionInfoHtml() : string {
    let liveEp = this.endpointService.getEndpointById(this.endpoint?.rtcId);
    if (liveEp?.callQuality)
      return StatsUtil.htmlQualityData(liveEp.callQuality)
    return ""
  }

  get epCallQualityInboundValue(): number {
    let liveEp = this.endpointService.getEndpointById(this.endpoint?.rtcId);
    if (liveEp?.callQuality)
      return liveEp.callQuality?.mos?.[0]?.inbound;
    return 0;
  }
  get epCallQualityOutboundValue(): number {
    let liveEp = this.endpointService.getEndpointById(this.endpoint?.rtcId);
    if (liveEp?.callQuality)
      return liveEp.callQuality?.mos?.[0]?.outbound;
    return 0;
  }

  sendLogs() {
    this.requestToSendLogs.emit(this.endpoint);
  }

  getSkillString(tags: ISkillTags): string {
    return tags?.category + " / " + tags?.language;
  }

  getGuestIdentString(rtcId: string, callSid: string): string {
    let result = ""
    if (rtcId) {
      result += rtcId;
    }
    if (callSid) {
      if (rtcId) {
        result += " "
      }
      result += `(Voice: ${callSid})`;
    }
    return result;
  }
}
