/**
 * Copyright Compunetix Incorporated 2017-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { RestService } from "../../shared/services/rest.service";
import {
  ILocalization,
  IFlavor,
  FlavorParameterKey,
  FlavorUtil,
  IGroup
} from "companion";
import { Flavor } from "./flavor";
import { Injectable } from "@angular/core";

@Injectable()
/**
 * flavor methods delegate
 */
export class FlavorService {
  constructor(private restService: RestService) {}

  /**
   * get all styles
   */
  getAllStyles(): Promise<string[]> {
    return new Promise((resolve: (data: string[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getAllStyles", {})
      .subscribe(
        (data: string[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  /**
   * get styles
   */
  getStyles(): Promise<string[]> {
    return new Promise((resolve: (data: string[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getStyles", {})
      .subscribe(
        (data: string[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get styles by mode
   */
  getStylesByMode(mode: string): Promise<string[]> {
    return new Promise((resolve: (data: string[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getThemeNamesByUserAndMode", { mode: mode })
      .subscribe(
        (data: string[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  /**
   * get languages by style
   * @param style: string - flavor style
   */
  getLanguagesByStyle(style: string): Promise<string[]> {
    return new Promise((resolve: (data: string[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getLanguagesByStyle", { style: style })
      .subscribe(
        (data: string[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get flavor details by style and language
   * @param style: string - flavor style
   * @param language: string - language
   */
  getLocalizationDataByStyleAndLanguage(style: string, language: string): Promise<ILocalization> {
    return new Promise((resolve: (data: ILocalization) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/localization", { style: style, language: language })
      .subscribe(
        (data: ILocalization) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * save flavor details
   */
  saveFlavor(flavor: ILocalization): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/saveFlavor", flavor)
      .subscribe(
        (data: string) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * delete flavor
   */
  deleteFlavor(style: string, language: string): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/deleteFlavor", { style: style, language: language })
      .subscribe(
        (data: string) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * update style name
   */
  updateStyleName(oldStyle: string, newStyle: string): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/updateStyleName", { oldStyle: oldStyle, newStyle: newStyle })
      .subscribe(
        (data: string) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * convert localization data to flavor
   */
  convertLocalizationToFlavor(data: ILocalization, groups: IGroup[]): IFlavor {
    let flavor: IFlavor = Flavor.getFlavorTemplate(data.style, data.language, data.mode, groups);
    FlavorUtil.setFlavorValuesByLocalizationData(flavor, data);
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.mode_enabled]].value = !!data.mode;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.page_enabled]].value = !!data.page;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.topnav_enabled]].value = !!data.topnav;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.toolbar_enabled]].value = !!data.toolbar;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.queue_screen_enabled]].value = !!data.queue_screen;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.video_screen_enabled]].value = !!data.video_screen;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.loopback_panel_enabled]].value = !!data.loopback_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.settings_panel_enabled]].value = !!data.settings_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_enabled]].value = !!data.diagnostic_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.chat_panel_enabled]].value = !!data.chat_panel;
    flavor.parameters[
      FlavorParameterKey[FlavorParameterKey.participant_panel_enabled]
    ].value = !!data.participant_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.presence_status_enabled]].value = !!data.presenceStatus;
    flavor.parameters[
      FlavorParameterKey[FlavorParameterKey.share_folder_panel_enabled]
    ].value = !!data.sharedFolder_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.inspector_panel_enabled]].value = !!data.inspector_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.record_panel_enabled]].value = !!data.record_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.snapshot_panel_enabled]].value = !!data.toolbar
      ?.toolbar_items?.["snapshot"];
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.take_photo_enabled]].value = !!data.take_photo;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.map_panel_enabled]].value = !!data.map_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_enabled]].value = !!data.ring_tone_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.lock_panel_enabled]].value = !!data.lock_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.footer_enabled]].value = !!data.footer;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.reporting_enabled]].value = true;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.error_message_enabled]].value = !!data.errorMessages;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.login_enabled]].value = !!data.loginMenu;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.login_enabled]].value = !!data.loginPanel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.connect_screen_enabled]].value = !!data.connect_screen;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.ice_config_enabled]].value = !!data.iceConfigs;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.notepad_enabled]].value = !!data.notepad;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.twilio_enabled]].value = !!data.twilioConfig;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.rtc_config_enabled]].value = !!data.rtcConfig;
    return flavor;
  }

}
