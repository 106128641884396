/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "keys",
  pure: false
})
export class KeysPipe implements PipeTransform {
  tmp: string[] = [];
  transform(value: any): string[] {
    this.tmp.length = 0;
    this.tmp = _.keys(value);
    return this.tmp;
  }
}
