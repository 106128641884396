/**
 * Copyright Compunetix Incorporated 2016-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from "@angular/core";
import {
  Browser,
  Companion,
  IEndpoint,
  IEndpointService,
  IExpandedActiveConference,
  IRTCService,
  IPosition,
  Position
} from "companion";
import {LocalizationService} from "../../localization/localization.service";
import {VideoWindowComponent} from "../video/video-window.component";

@Component({
  selector: "loopback",
  templateUrl: "./loopback.template.html"
})
/**
 * loopback view
 */
export class LoopbackComponent implements OnInit {
  @Input() loopbackOn: boolean;

  /**
   * flag if it's in locked mode
   */
  @Input() isLocked: boolean;

  /**
   * flag if top toolbar is showing
   */
  @Input() isNavShown: boolean = true;

  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean = false;

  @Input() conference: IExpandedActiveConference;
  @Output("toggleLoopback") toggleLoopbackEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * video windows for streams
   */
  @ViewChildren(VideoWindowComponent) videoWindows: QueryList<VideoWindowComponent>;
  inSubWindow: { [streamId: string]: boolean } = {};
  clientX: number = 0;
  clientY: number = 0;
  right: number = 0;
  top: number = 0;

  private endpointService: IEndpointService = Companion.getEndpointService();
  private rtcService: IRTCService = Companion.getRTCService();
  private setLoopbackVideoTimer: any;

  myEndpoint: IEndpoint = this.endpointService.myEndpoint;
  
  // if this is a mobile device
  isMobileDevice: boolean = Browser.isMobile();

  /**
   * endpoint video count
   */
  get videoCount(): number {
    return _.keys(this.myEndpoint.streams).length;
  }

  /**
   * endpoint has screen stream
   */
  get hasScreenShare(): boolean {
    return _.includes(_.map(_.values(this.myEndpoint.streams), "streamName"), "screen");
  }

  /**
   * endpoint has avatar stream
   */
  get hasAvatar(): boolean {
    return _.includes(_.map(_.values(this.myEndpoint.streams), "streamName"), "avatar");
  }

  currentWindowSize: WindowSize = WindowSize.normal;
  WindowSize = WindowSize;

  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }

  /**
   * component load handler
   */
  ngOnInit() {
    this.setLoopbackVideo();
  }

  /**
   * update loopback video
   */
  setLoopbackVideo() {
    clearTimeout(this.setLoopbackVideoTimer);
    let setLoopbackVideoSuccess: boolean = true;
    if (_.keys(this.rtcService.rtcClient.defaultStreams).length > 0) {
      _.forOwn(this.rtcService.rtcClient.defaultStreams, (stream: MediaStream, streamId: string) => {
        let videoElement: HTMLVideoElement = document.getElementById("loopback-video-" + streamId) as HTMLVideoElement;
        if (videoElement) {
          this.rtcService.setVideoSrcByElement(videoElement, stream, true)
          .catch((error) => {
            console.log("setLoopbackVideo - Failed to setVideoSrcByElement: ", error);
          });
        } else {
          setLoopbackVideoSuccess = false;
        }
      });
    } else {
      setLoopbackVideoSuccess = false;
    }
    if (!setLoopbackVideoSuccess) {
      this.setLoopbackVideoTimer = setTimeout(() => {
        clearTimeout(this.setLoopbackVideoTimer);
        this.setLoopbackVideo();
      }, 1000);
    }
  }

  /**
   * hide loopback video
   */
  hideLoopback() {
    this.toggleLoopbackEmitter.emit(false);
  }

  /**
   * show loopback video
   */
  showLoopback() {
    this.toggleLoopbackEmitter.emit(true);
  }

  /**
   * handler on drag start event
   * @event event:any - event data
   */
  drag_start(event: any) {
    var style = window.getComputedStyle(event.target, null);
    var targ = event.target;
    // calculate event X, Y coordinates
    if (event.clientX) {
      this.clientX = event.clientX;
    } else if (event.touches && event.touches[0] && event.touches[0].clientX) {
      this.clientX = event.touches[0].clientX;
    }

    if (event.clientX) {
      this.clientY = event.clientY;
    } else if (event.touches && event.touches[0] && event.touches[0].clientY) {
      this.clientY = event.touches[0].clientY;
    }

    // assign default values for top and right properties
    if (!targ.style.right) {
      targ.style.right = style.getPropertyValue("right");
    }

    if (!targ.style.top) {
      targ.style.top = style.getPropertyValue("top");
    }
    // calculate integer values for top and right
    this.right = parseInt(targ.style.right, 10);
    this.top = parseInt(targ.style.top, 10);

    // for firefox drag event
    if (event.dataTransfer) {
      event.dataTransfer.setData("right", this.right);
      event.dataTransfer.setData("top", this.top);
      event.dataTransfer.setData("clientX", this.clientX);
      event.dataTransfer.setData("clientY", this.clientY);
    }
  }

  /**
   * handler on move event
   * @event event:any - event data
   */
  move(event: any) {
    var target = document.getElementById("loopback-video-container");
    if (event.clientX) {
      target.style.right = this.right + (this.clientX - event.clientX) + "px";
    } else if (event.touches && event.touches[0] && event.touches[0].clientX) {
      target.style.right = this.right + (this.clientX - event.touches[0].clientX) + "px";
    }

    if (event.clientX) {
      target.style.top = this.top + (event.clientY - this.clientY) + "px";
    } else if (event.touches && event.touches[0] && event.touches[0].clientY) {
      target.style.top = this.top + (event.touches[0].clientY - this.clientY) + "px";
    }

    event.preventDefault();
    return false;
  }

  /**
   * minimize window
   */
  minWindow() {
    this.currentWindowSize = WindowSize.minimized;
    // to prevent cascade events triggered on mobile devices
    return false;
  }

  /**
   * maximize window
   */
  maxWindow() {
    this.currentWindowSize = WindowSize.maximized;
    // to prevent cascade events triggered on mobile devices
    return false;
  }

  /**
   * restore window
   */
  restoreWindow() {
    this.currentWindowSize = WindowSize.normal;
    // to prevent cascade events triggered on mobile devices
    return false;
  }
  openWindow(streamId: string) {
    let videoWindow: VideoWindowComponent = this.videoWindows.find((window: VideoWindowComponent) => {
      return window.stream.id === streamId;
    });
    videoWindow.open(() => {
      this.inSubWindow[streamId] = false;
    });
    this.inSubWindow[streamId] = true;
  }

  getPosition(endpoint: IEndpoint, stream: any): IPosition {
    return new Position(0, 0, 100, 100);
  }

  get canFlipCamera(): boolean {
    let result: boolean = false;
    result = this.isMobileDevice && Companion.getDeviceService().cameraOptions.length > 1;
    return result;
  }

  flipCamera(event: Event): void {
    // to prevent duplicate touch & click event trigger
    event.preventDefault();
    Companion.getDeviceService().switchCamera();
  }
}

enum WindowSize {
  minimized,
  normal,
  maximized
}
