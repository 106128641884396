/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, amaggi
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { LayoutModule } from "../layout/layout.module";
import { SharedModule } from "../shared/shared.module";

import { DialOutComponent } from "./dial-out.component";
import { GroupManagementService } from "../group-management/group-management.service";
import {KeypadComponent} from "./keypad/keypad.component";

@NgModule({
  imports: [BrowserModule, FormsModule, HttpClientModule, LayoutModule, SharedModule],
  declarations: [DialOutComponent, KeypadComponent],
  providers: [GroupManagementService],
  exports: [DialOutComponent, KeypadComponent]
})
export class DialOutModule {}
