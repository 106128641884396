/**
 * Copyright Compunetix Incorporated 2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender
 */

import { ILocalization, EndpointService } from "companion";
import * as watchRTC from "@testrtc/watchrtc-sdk";

/**
 * Service for configuring watchRTC for client
 * connections.
 */
export class AnalyticsService {

    private static sharedInstance: AnalyticsService;

    /**
     * Handle to theme data
     */
    private myLocalizationData: ILocalization;

    /**
     * get shared singleton object
     */
    static getSharedInstance(): AnalyticsService {
      if (!AnalyticsService.sharedInstance) {
        AnalyticsService.sharedInstance = new AnalyticsService();
      }
      return AnalyticsService.sharedInstance;
    }

    constructor() {
    }

    /**
     * Initialize call analytics
     */
    public initializeCallAnalytics(rtcId: string,  myLocalizationData: ILocalization) {
        this.myLocalizationData = myLocalizationData;

        if (myLocalizationData.diagnostic_panel &&
            myLocalizationData.diagnostic_panel.watchRtcActive) {
            watchRTC.init();
            console.log("Activating WATCHRTC", rtcId, myLocalizationData.diagnostic_panel);
        }
    }

    /**
     * Peer connection created handler
     * @param rtcId the RTC id of the peer
     * @param pc the peer connection object
     */
    public peerConnectionCreatedHandler(rtcId: string, pc: any) {
        console.log(rtcId, "PeerConnection:", pc);

        // if N/A, use timestamp...
        let roomId: string = new Date().toISOString();

        if (this.myLocalizationData.diagnostic_panel &&
            (this.myLocalizationData.diagnostic_panel.watchRtcActive)) {
            const watchRtcParams = {
                rtcApiKey: this.myLocalizationData.diagnostic_panel.watchRtcApiKey,
                rtcRoomId: roomId,
                rtcPeerId: rtcId
            };
            console.log("Set Watch RTC", rtcId, watchRtcParams.rtcRoomId);
            watchRTC.setConfig(watchRtcParams);
        }
    }
}
