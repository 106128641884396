/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import {ILocalization, IQueueSelectionItem} from "companion";

@Component({
  selector: "guest-queue-screen",
  templateUrl: "./guest-queue-screen.template.html"
})
export class GuestQueueScreenComponent implements OnInit {

  /**
   * flavor style name
   */
  @Input() style: string;

  /**
   * style language
   */
  @Input() defaultLanguage: string;

  /**
   * connect
   */
  @Output("guestConnect")
  guestConnectEmitter: EventEmitter<IQueueSelectionItem> = new EventEmitter<IQueueSelectionItem>();

  /**
   * queues list
   */
  public queuesList: IQueueSelectionItem[];

  /**
   * change language and connect
   */
  changeQueueAndConnect(item: IQueueSelectionItem): void {
    if (item.langCode !== this.localizationService.myLocalizationData.language) {
      this.getLocalizationData(item.langCode)
      .then(() => {
        this.connect(item);
      })
      .catch((error) => {
        console.log("Filaed to changeQueueAndConnect: ", error);
      });
    } else {
      this.connect(item);
    }
  }

  /**
   * get localization data and language
   */
  getLocalizationData(language): Promise<ILocalization> {
    return this.localizationService.getLocalizationData(this.style, language);
  }

  getButtonLabel(item: IQueueSelectionItem){
    let label: string;
    if (item.categoryText) {
      label = item.categoryText;
      if (item.languageText) {
        label += ' (' + item.languageText + ')';
      }
    } else if (item.languageText) {
      label = item.languageText;
    }
    return label;
  }

  /**
   * connect to waiting room
   */
  connect(item: IQueueSelectionItem) {
    this.guestConnectEmitter.emit(item);
  }

  /**
   * component constructor
   */
  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }

  ngOnInit() {
    this.queuesList = this.localizationService.myLocalizationData.queue_screen.list;
    if (this.queuesList.length > 0) {
      if (this.localizationService.myLocalizationData.language !== this.defaultLanguage) {
        this.getLocalizationData(this.defaultLanguage)
        .catch((error) => {
          console.log("Failed to getLocalizationData: ", error);
        });
      }
    }
  }
}
