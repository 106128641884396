/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  frivolta
 */
import {Pipe} from "@angular/core";
import {IEndpoint, EndpointService} from "companion";
/**
 * filter participant items
 * @param	theme
 * @example Usage:
 * ```html
 * <p>
 *   {{ participantItems | filterParticipant : theme}}
 * </p>
 * ```
 */
@Pipe({ name: "filterParticipant" })
export class FilterParticipantPipe {
  transform(endpoints: IEndpoint[], theme: string): IEndpoint[] {
    if (!endpoints || !theme) {
      return endpoints;
    }

    const endpointService = EndpointService.getSharedInstance();
    return endpoints.filter(ep => {
      if (ep.rtcId === endpointService.myEndpoint.rtcId) {
        return true;
      }
      if (ep && ep.theme) {
        const epTheme = ep.theme.replace(/-(op|sp|public)$/i, "");
        return epTheme === theme;
      }
      return false;
    });
  }
}
