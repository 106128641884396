<!--
    Copyright Compunetix Incorporated 2016-2017
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<div
  class="container-fluid chatroom-chatbox"
  [attr.id]="'chatroom-chatbox-'+room.roomId"
>
  <div class="row panel-header">
    <div class="col-12">
      <a
        tabindex
        class="float-left btn"
        (click)="backToRoomList()"
      >
        <i
          class="fas fa-chevron-left"
          aria-hidden="true"
        ></i>
        {{localizationService.myLocalizationData.chat_panel.backButtonText || "Back"}}
      </a>
      <a
        *ngIf="canDeleteSelf"
        tabindex
        class="float-left btn btn-danger btn-sm"
        (click)="deleteRoom()"
      >
        <i
          class="fas fa-trash"
          aria-hidden="true"
        ></i>
      </a>
      <a
          tabindex
          class="close"
          aria-label="Close"
          (click)="closePanel()"
        >
          <i
            class="fas fa-times"
            aria-hidden="true"
          ></i>
        </a>
    </div>
  </div>
  <div>
    <ul class="list-unstyled">
      <li>
        <chatroom-userbox
          [room]="room"
          [currentEndpoint]="currentEndpoint"
          (toggleEndpointSelectListPanel)="toggleEndpointSelectListPanel($event)"
          [canModifyChatRoomParticipants]="canModifyChatRoomParticipants"
        ></chatroom-userbox>
      </li>
      <li>
        <chatroom-messagehistory
          [room]="room"
          [currentEndpoint]="currentEndpoint"
        ></chatroom-messagehistory>
      </li>
      <li>
        <chatroom-sendbox
          [room]="room"
          (sendMessage)="sendMessage($event)"
          [currentEndpoint]="currentEndpoint"
        ></chatroom-sendbox>
      </li>
    </ul>
  </div>


</div>
