<!--
    Copyright Compunetix Incorporated 2016
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<div
  class="bg-white"
  id="settings-panel"
  role="dialog"
  aria-label="Settings Panel"
  tabindex="0"
  [ngClass]="{'mobile': isMobileApp, 'animated fadeInLeft': !isMobileApp}"
  [style.color]="localizationService.myLocalizationData.settings_panel.fontColor"
  [style.background]="localizationService.myLocalizationData.settings_panel.backgroundColor"
  [style.top]="localizationService.myLocalizationData.settings_panel.top"
  [style.bottom]="localizationService.myLocalizationData.settings_panel.bottom"
  [style.left]="localizationService.myLocalizationData.settings_panel.left"
  [style.right]="localizationService.myLocalizationData.settings_panel.right"
  [style.width]="localizationService.myLocalizationData.settings_panel.width"
>
  <div class="container-fluid">
    <div class="row panel-header">
      <div class="col-12">
        <button
          tabindex
          class="btn btn-link"
          aria-label="Back"
          title="Back"
          *ngIf="isMobileApp"
          (click)="backToMenu()"
        >
          <i
            class="fas fa-chevron-left"
            aria-hidden="true"
          ></i>
          Back
        </button>
        <button
          tabindex
          class="close btn-link"
          aria-label="Close"
          title="Close Panel"
          *ngIf="!isMobileApp"
          (click)="closePanel(true)"
        >
          <i
            class="fas fa-times icon-close"
            aria-hidden="true"
          ></i>
        </button>
        <h2 class="text-center" [title]="localizationService.myLocalizationData.settings_panel.title" role="heading">
          <i
            class="fas fa-cog"
            aria-hidden="true"
          ></i>
          {{localizationService.myLocalizationData.settings_panel.title}}
        </h2>
      </div>
    </div>
    <conference-settings
      [isKioskUnlocked]="isKioskUnlocked"
      [started]="started"
      [isMobileApp]="isMobileApp"
      [cropperEnabled]="cropperEnabled"
      [videoAspect]="videoAspect"
      (updateSpeaker)="updateSpeaker($event)"
      (updateLocalStream)="updateLocalStream($event)"
      (toggleMic)="toggleMic($event)"
      (toggleCamera)="toggleCamera($event)"
      (updateVolume)="updateVolume($event)"
    ></conference-settings>
  </div>
</div>
