/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { IChatRoom, IMessage, Message, IEndpoint } from "companion";
import { LocalizationService } from "../../localization/localization.service";
@Component({
  selector: "chatroom-sendbox",
  templateUrl: "./chatroom-sendbox.template.html"
})
/**
 * Chat room send box
 */
export class ChatRoomSendBox implements AfterViewInit {
  @Input() room: IChatRoom;
  @Output("sendMessage") sendMessageEmitter: EventEmitter<IMessage> = new EventEmitter<IMessage>();
  @Input() currentEndpoint: IEndpoint;
  content: string = "";
  fileInputValue: string;

  constructor(public localizationService: LocalizationService) {}

  /**
   * handle send box submit event
   */
  onSubmit(): void {
    if (!this.content || this.content === "") {
      return;
    }
    var message: IMessage = new Message(
      this.currentEndpoint.rtcId,
      this.currentEndpoint.name,
      _.map(this.room.targets, "rtcId"),
      _.map(this.room.targets, "name"),
      this.content,
      this.room.ownerRtcId
    );
    this.content = "";
    this.sendMessageEmitter.emit(message);
  }

  /**
   * componet loaded handler
   */
  ngAfterViewInit() {
    let sendboxFocusTimer = setTimeout(() => {
      clearTimeout(sendboxFocusTimer);
      if ($("#sendbox")[0]) { $("#sendbox")[0].focus(); }
    }, 500);
  }
}
