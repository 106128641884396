/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */

import { Pipe } from "@angular/core";
import { formatDuration, Duration } from "date-fns";

/**
 * Format time
 * @example Usage:
 * ```html
 * <p>
 *   {{ time | formatTime }}
 * </p>
 * ```
 */
@Pipe({ name: "formatTime" })
export class FormatTimePipe {
  transform(time: number): string {
    if (!time) {
      return "0m 0s";
    }

    const duration: Duration = {
      minutes: Math.floor(time / 60000),
      seconds: Math.round((time % 60000) / 1000)
    }
    return formatDuration(duration, {format: ["minutes", "seconds"]}).replace(RegExp("minutes?"), "m").replace(RegExp("seconds?"), "s");
  }
}
