/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { Dispatcher, ActionType } from "../../shared/services/dispatcher";
import { LocalizationService } from "../../localization/localization.service";
import { SafeHtmlPipe } from "client/scripts/shared/pipes/safe-html.pipe";

@Component({
  selector: "dialog-container-modal",
  templateUrl: "./dialog-container-modal.component.html"
})
/**
 * dialog-modal-container layout
 */
export class DialogContainerModalComponent implements OnInit {
  @Input() id: string;
  @Input() title: string;
  @Input() cssClass: string;
  @Input() clickOutsideToHide: boolean = true;
  @Input() enableKeyboardControl: boolean = true;
  @Input() hasFooter: boolean = false;
  @Input() hasExitButton: boolean = true;
  @Input() hasSubmitButton: boolean = false;
  @Input() hasCancelButton: boolean = false;
  @Input() footerSubmitText: string = "Submit";
  @Input() footerSubmitClass: string = "Submit";
  @Input() footerCancelText: string = "Cancel";
  @Input() submitAction: ActionType;
  @Input() dialogWidth: string;
  @Input() dragDisabled: boolean = true;
  @Input() dragBoundary: string;

  @Output("dialogClosed") dialogClosed: EventEmitter<any> = new EventEmitter<any>();

  getSafeHtml(text: string): string {
    if (text) {
        return this.safeTextPipe.transform(text) as string;
    }
    return "";
  }

  constructor(public localizationService: LocalizationService,protected safeTextPipe: SafeHtmlPipe) {}
  ngOnInit() {
    Dispatcher.register(ActionType.OpenModalDialog, this.open.bind(this));
    Dispatcher.register(ActionType.HideModalDialog, this.hide.bind(this));
    Dispatcher.register(ActionType.OpenModalDialog, this.open.bind(this), this.id);
    Dispatcher.register(ActionType.HideModalDialog, this.hide.bind(this), this.id);
  }
  open(id: string) {

    let options: any  = { show: true, backdrop: this.clickOutsideToHide ? true : "static" }
    $("#" + id).modal(options);
    let resetFocusTimer = setTimeout(() => {
      clearTimeout(resetFocusTimer);
      let dialogWindowBody = document.getElementById(id + "-body");
      if (dialogWindowBody) {
        dialogWindowBody.focus();
      }
    }, 1000);
  }
  hide(id: string) {
    $("#" + id).modal("hide");
    this.dialogClosed.emit();
  }
  submit(id: string) {
    this.hide(id);
    if (this.submitAction) {
      Dispatcher.dispatch(this.submitAction, undefined, id);
    }
  }
}
