/**
 * Copyright Compunetix Incorporated 2017-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, amaggi
 */
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges} from "@angular/core";
import {PhoneNumber, PhoneNumberFormat, PhoneNumberUtil} from "google-libphonenumber";
import {VoiceService} from "../shared/services/voice.service";
import {ISkillTags, IEndpoint, PresenceStatus} from "companion";

export interface IDialOutData {
  name: string;
  displayPhoneNumber: string;
  phone: string;
  skillTags?: ISkillTags;
}

@Component({
  selector: "dial-out",
  templateUrl: "./dial-out.template.html"
})
export class DialOutComponent {
  clearSubmitLock() {
    this.submitted = false;
  }
  /**
   * list of participants
   */
  @Input() participants: IEndpoint[];

  /**
   * logged endpoint
   */
  @Input() myEndpoint: IEndpoint;


  /**
   * the event emitter to send the telephone number to call
   */
  @Output("dialOutData") dialOutData: EventEmitter<IDialOutData> = new EventEmitter<IDialOutData>();
  /**
   * the event emitter to send the telephone number to call
   */
  @Output("hangupDialOutCall") hangupDialOutCall: EventEmitter<string> = new EventEmitter<string>();
  /**
   * form is submitted
   */
  submitted: boolean = false;
  /**
   * name of guest to call
   */
  name: string = "";
  /**
   * phone number to call
   */
  phoneNumber: string = "";
  /**
   * phone number to display
   */
  parsedPhoneNumber: PhoneNumber = null;
  /**
   * boolean to validate form
   */
  validPhoneNumber = false;
  /**
   * international phone number
   */
  internationalPhoneNumber: string;
  /**
   * region of phone number
   */
  regionCode: string;

  /**
   * current skill tags
   */
  currentSkillTags: ISkillTags = {category: null, language: null};

  /**
   * to be used in html template
   */
  PresenceStatus = PresenceStatus;

  /**
   * component constructor
   */
  constructor(private voiceService: VoiceService) {}

  /**
   * refresh the initialization of the skill tags
   */
  refreshSkillTags() {
    if (this.myEndpoint?.skillSet?.category?.length === 1 && this.myEndpoint?.skillSet?.language.length === 1) {
      this.currentSkillTags = {
        language: this.myEndpoint.skillSet.language[0],
        category: this.myEndpoint.skillSet.category[0]
      };
    } else {
      if (this.myEndpoint?.skillSet?.category?.length === 1) {
        this.currentSkillTags.category = this.myEndpoint.skillSet.category[0];
      }
      if (this.myEndpoint?.skillSet?.language.length === 1) {
        this.currentSkillTags.language = this.myEndpoint.skillSet.language[0];
      }
    }
  }

  /**
   * check if is a valid phone number
   * @param number
   */
  checkNumber(number) {
    let phoneUtil = PhoneNumberUtil.getInstance();
    try {
      this.parsedPhoneNumber = phoneUtil.parse(number, "US");
      if (this.parsedPhoneNumber) {
        this.regionCode = phoneUtil.getRegionCodeForNumber(this.parsedPhoneNumber);
        this.internationalPhoneNumber = phoneUtil.format(this.parsedPhoneNumber, PhoneNumberFormat.INTERNATIONAL);
        this.validPhoneNumber = phoneUtil.isPossibleNumber(this.parsedPhoneNumber);
      }
    } catch (e) {
      this.regionCode = null;
      this.internationalPhoneNumber = null;
      this.validPhoneNumber = false;
    }
  }

  /**
   * emit telephone number to call-center
   */
  dialOut(): void {
    if(this.submitted)
    {
      // prevent double submit.
      return;
    }
    this.submitted = true;
    const skillTags: ISkillTags = this.myEndpoint?.skillSet ? this.currentSkillTags : null;
    this.name = this.name === "" ? "NO_NAME" : this.name;
    this.dialOutData.emit({
      name: this.name,
      displayPhoneNumber: this.internationalPhoneNumber,
      phone: this.phoneNumber,
      skillTags: skillTags
    });
  }

  /**
   * emit hangup to call-center
   */
  hangUp(): void {
    this.hangupDialOutCall.emit(this.phoneNumber);
  }

  /**
   * Close the dialout component
   */
  close(): void {
    this.submitted = false;
    $("#dialOutModal")?.modal("hide");
  }

  /**
   * Pinpad pressed key
   * @param $event
   */
  pressedKey($event) {
    this.phoneNumber += $event;
    this.checkNumber(this.phoneNumber);
    this.voiceService.playDigit($event);
  }
}
