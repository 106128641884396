/**
 * Copyright Compunetix Incorporated 2022-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, kbender
 */

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { IClientSettings } from "companion";
import { TreeViewItem } from "client/scripts/shared/components/tree-view/tree-item";
import GenericSemaphore from "../../../server/class/GenericSemaphore";


export interface State {
  settings: IClientSettings;
  dashboardEnabled: boolean;
  dashboardGroup: TreeViewItem;
  // add the queue filtering information from here...
  language : string;
  category : string;
  googleMapsAPIKey?: string;
}

const defaultState: State = {
  settings: {
    VccConfig: {},
    CollectLogs: {
      enabled: false,
      easyrtc: false,
      webrtcStatistics: false,
      machineId: ""
    }
  },
  dashboardEnabled: false,
  dashboardGroup: null,
  language :  "All",
  category: "All",
  googleMapsAPIKey: ""
};

const _STORE = new BehaviorSubject<State>(defaultState);

@Injectable()
export class Store {
  private store = _STORE;
  changes = this.store.asObservable().pipe(distinctUntilChanged());
  updateSynchronizer: GenericSemaphore<void>;

  init() {
    this.updateSynchronizer = null;
  }

  getState() {
    return this.store.value;
  }

  update(prop, state): Promise<void> {
    if (!this.updateSynchronizer) {
      let clearSynchronizer = function () {
        delete this.updateSynchronizer;
        this.updateSynchronizer = null;
      }
      this.updateSynchronizer = new GenericSemaphore<void>(1, clearSynchronizer.bind(this));
    }
    return this.updateSynchronizer.callFunction(this.updateSynchronized.bind(this), prop, state);
  }

  updateSynchronized(prop, state): Promise<void> {
    const currentState = this.store.value;
    this.store.next(Object.assign({}, currentState, { [prop]: state }));
    return Promise.resolve();
  }

}
