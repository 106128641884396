/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";

/**
 * search styles by term
 * ```
 */
@Pipe({ name: "styleSearch" })
export class StyleSearchPipe {
  transform(styles: string[], term: string): any {
    if (term) {
      return _.filter(styles, (style: string) => {
        return _.includes(style.toLowerCase(), term.toLowerCase());
      });
    } else {
      return styles;
    }
  }
}
