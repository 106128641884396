<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<div
  class="modal"
  [ngClass]="{'fade': dragDisabled}"
  [id]="id"
  role="dialog"
  aria-labelledby="DialogModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog {{cssClass}}"
    role="document"
    [ngStyle]="{'min-width': dialogWidth, 'max-width': '75%'}"
  >
    <div class="modal-content">

      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2 class="modal-title">{{title}}</h2>
      </div>

      <div class="modal-body header-modal-body" [ngStyle]="{'overflow-wrap': 'break-word'}">
        <div class="w-100 bg-white"
        >
          <ng-content></ng-content>
        </div>
      </div>

      <div class="modal-footer" *ngIf="hasFooter">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="hide(id)"
          *ngIf="hasCancelButton && footerCancelText"
        >{{footerCancelText}}</button>
        <button
          type="button"
          class="btn btn-primary {{footerSubmitClass}}"
          (click)="submit(id)"
          *ngIf="hasSubmitButton && footerSubmitText"
        >{{footerSubmitText}}</button>
      </div>

    </div>
  </div>
</div>
