/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";
import { Companion, IEndpoint, IEndpointRef } from "companion";

/**
 * concat endpoint list to a name string.
 * @param	collection to concat
 * @example Usage:
 * ```html
 * <p> {{ endpoints | concatEndpointNames }}</p>
 * ```
 */
@Pipe({ name: "concatEndpointNames", pure: false })
export class ConcatEndpointNamePipe {
  result: string = "";
  transform(list: IEndpointRef[], seperator: string = ", "): any {
    this.result = "";
    if (list.length > 0) {
      this.result = list[0].name;
    }

    if (list.length > 1) {
      for (var i = 1; i < list.length; ++i) {
        this.result += seperator + list[i].name;
      }
    }
    return this.result;
  }
}
