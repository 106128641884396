/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, NgZone, Output, EventEmitter } from "@angular/core";
import { Companion, IChatRoom, IEndpoint } from "companion";

@Component({
  selector: "chatroom-listitem",
  templateUrl: "./chatroom-listitem.template.html"
})
/**
 * Chat room list view item
 */
export class ChatRoomListItem {
  constructor(private _ngZone: NgZone) {
    // nothing needed here
  }

  @Output("delete") deleteEmitter: EventEmitter<IChatRoom> = new EventEmitter<IChatRoom>();
  @Output("enter") enterEmitter: EventEmitter<IChatRoom> = new EventEmitter<IChatRoom>();
  @Input() room: IChatRoom;
  @Input() inEditState: boolean;

  /**
   * Identify any targets in the room that are not me to keep the display short.
   */
  get filteredRoomTargets(): IEndpoint[] {
    return _.filter(this.room.targets, (ep) => {
      return ep.rtcId != Companion.getEndpointService().myEndpoint.rtcId;
    })
  }

  /**
   * delete this chat room
   */
  delete() {
    if (this.inEditState) {
      this.deleteEmitter.emit(this.room);
    }
  }
}
