/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IPosition } from "./position.interface";

/**
 * position object to describe video view position
 */
export class Position implements IPosition {
  constructor(
    public leftPercent: number,
    public topPercent: number,
    public widthPercent: number,
    public heightPercent: number
  ) {
    // nothing needed here
  }
}

export const PositionDictionary: { [total: number]: { [mode: number]: IPosition[] } } = {
  1: {
    1: [new Position(0, 0, 100, 100)],
    100: [new Position(0, 0, 100, 100)],
    101: [new Position(0, 0, 100, 100)]
  },
  2: {
    1: [new Position(0, 25, 50, 50), new Position(50, 25, 50, 50)],
    2: [new Position(25, 0, 50, 50), new Position(25, 50, 50, 50)],
    3: [new Position(0, 0, 100, 50), new Position(0, 50, 100, 50)],
    4: [new Position(0, 0, 50, 100), new Position(50, 0, 50, 100)],
    100:  [new Position(0, 0, 100, 50), new Position(0, 50, 100, 50)],
    101:  [new Position(0, 0, 50, 100), new Position(50, 0, 50, 100)],
  },
  3: {
    1: [new Position(25, 0, 50, 50), new Position(0, 50, 50, 50), new Position(50, 50, 50, 50)],
    2: [new Position(0, 0, 50, 50), new Position(50, 0, 50, 50), new Position(25, 50, 50, 50)],
    3: [new Position(0, 0, 100, 50), new Position(0, 50, 50, 50), new Position(50, 50, 50, 50)],
    4: [new Position(0, 0, 50, 50), new Position(50, 0, 50, 50), new Position(0, 50, 100, 50)],
    5: [new Position(0, 0, 50, 100), new Position(50, 0, 50, 50), new Position(50, 50, 50, 50)],
    6: [new Position(0, 0, 50, 50), new Position(0, 50, 50, 50), new Position(50, 0, 50, 100)],
    100:  [new Position(0, 0, 100, 33.33), new Position(0, 33.33, 100, 33.33), new Position(0, 66.67, 100, 33.33)],
    101:  [new Position(0, 0, 33.33, 100), new Position(33.33, 0, 33.33, 100), new Position(66.67, 0, 33.33, 100)],
  },
  4: {
    1: [
      new Position(0, 0, 50, 50),
      new Position(50, 0, 50, 50),
      new Position(0, 50, 50, 50),
      new Position(50, 50, 50, 50)
    ],
    2: [
      new Position(0, 0, 100, 66.67),
      new Position(0, 66.67, 33.33, 33.33),
      new Position(33.33, 66.67, 33.33, 33.33),
      new Position(66.67, 66.67, 33.33, 33.33)
    ],
    100: [
      new Position(0, 0, 50, 50),
      new Position(50, 0, 50, 50),
      new Position(0, 50, 50, 50),
      new Position(50, 50, 50, 50)
    ],
    101: [
      new Position(0, 0, 50, 50),
      new Position(50, 0, 50, 50),
      new Position(0, 50, 50, 50),
      new Position(50, 50, 50, 50)
    ]
  },
  5: {
    1: [
      new Position(33.33, 0, 33.33, 33.33),
      new Position(66.67, 0, 33.33, 33.33),
      new Position(0, 33.33, 33.33, 33.33),
      new Position(33.33, 33.33, 66.67, 66.67),
      new Position(0, 66.67, 33.33, 33.33)
    ],
    2: [
      new Position(0, 0, 33.33, 33.33),
      new Position(33.33, 0, 33.33, 33.33),
      new Position(0, 33.33, 66.67, 66.67),
      new Position(66.67, 33.33, 33.33, 33.33),
      new Position(66.67, 66.67, 33.33, 33.33)
    ],
    3: [
      new Position(0, 0, 66.67, 66.67),
      new Position(66.67, 0, 33.33, 33.33),
      new Position(66.67, 33.33, 33.33, 33.33),
      new Position(0, 66.67, 33.33, 33.33),
      new Position(33.33, 66.67, 33.33, 33.33)
    ],
    4: [
      new Position(0, 0, 33.33, 33.33),
      new Position(33.33, 0, 66.67, 66.67),
      new Position(0, 33.33, 33.33, 33.33),
      new Position(33.33, 66.67, 33.33, 33.33),
      new Position(66.67, 66.67, 33.33, 33.33)
    ],
    5: [
      new Position(0, 0, 100, 75),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    6: [
      new Position(0, 0, 100, 80),
      new Position(0, 80, 25, 25),
      new Position(25, 80, 25, 25),
      new Position(50, 80, 25, 25),
      new Position(75, 80, 25, 25)
    ]
  },
  6: {
    1: [
      new Position(0, 0, 33.33, 33.33),
      new Position(33.33, 0, 33.33, 33.33),
      new Position(66.67, 0, 33.33, 33.33),
      new Position(0, 33.33, 33.33, 33.33),
      new Position(33.33, 33.33, 66.67, 66.67),
      new Position(0, 66.67, 33.33, 33.33)
    ],
    2: [
      new Position(0, 0, 33.33, 33.33),
      new Position(33.33, 0, 33.33, 33.33),
      new Position(66.67, 0, 33.33, 33.33),
      new Position(0, 33.33, 66.67, 66.67),
      new Position(66.67, 33.33, 33.33, 33.33),
      new Position(66.67, 66.67, 33.33, 33.33)
    ],
    3: [
      new Position(0, 0, 66.67, 66.67),
      new Position(66.67, 0, 33.33, 33.33),
      new Position(66.67, 33.33, 33.33, 33.33),
      new Position(0, 66.67, 33.33, 33.33),
      new Position(33.33, 66.67, 33.33, 33.33),
      new Position(66.67, 66.67, 33.33, 33.33)
    ],
    4: [
      new Position(0, 0, 33.33, 33.33),
      new Position(33.33, 0, 66.67, 66.67),
      new Position(0, 33.33, 33.33, 33.33),
      new Position(0, 66.67, 33.33, 33.33),
      new Position(33.33, 66.67, 33.33, 33.33),
      new Position(66.67, 66.67, 33.33, 33.33)
    ],
    5: [
      new Position(0, 0, 100, 75),
      new Position(0, 75, 20, 25),
      new Position(20, 75, 20, 25),
      new Position(40, 75, 20, 25),
      new Position(60, 75, 20, 25),
      new Position(80, 75, 20, 25)
    ]
  },
  7: {
    1: [
      new Position(0, 0, 50, 50),
      new Position(50, 0, 50, 50),
      new Position(0, 50, 50, 50),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    2: [
      new Position(0, 0, 100, 75),
      new Position(0, 75, 16.67, 25),
      new Position(16.67, 75, 16.67, 25),
      new Position(33.33, 75, 16.67, 25),
      new Position(50, 75, 16.67, 25),
      new Position(66.67, 75, 16.67, 25),
      new Position(83.33, 75, 16.67, 25)
    ]
  },
  8: {
    1: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 75, 75),
      new Position(0, 50, 25, 25),
      new Position(0, 75, 25, 25)
    ],
    2: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 75, 75),
      new Position(75, 25, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    3: [
      new Position(0, 0, 75, 75),
      new Position(75, 0, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    4: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 75, 75),
      new Position(0, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ]
  },
  9: {
    1: [
      new Position(0, 0, 33.33, 33.33),
      new Position(33.33, 0, 33.33, 33.33),
      new Position(66.67, 0, 33.33, 33.33),
      new Position(0, 33.33, 33.33, 33.33),
      new Position(33.33, 33.33, 33.33, 33.33),
      new Position(66.67, 33.33, 33.33, 33.33),
      new Position(0, 66.67, 33.33, 33.33),
      new Position(33.33, 66.67, 33.33, 33.33),
      new Position(66.67, 66.67, 33.33, 33.33)
    ]
  },
  10: {
    1: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 50, 50),
      new Position(50, 25, 50, 50),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    2: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 50, 50),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 50, 50),
      new Position(75, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    3: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 50, 50),
      new Position(50, 50, 50, 50)
    ],
    4: [
      new Position(0, 0, 50, 50),
      new Position(50, 0, 50, 50),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    5: [
      new Position(0, 0, 50, 50),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 50, 50),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    6: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 50, 50),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 50, 50),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25)
    ]
  },
  11: {
    1: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 25, 25)
    ]
  },
  12: {
    1: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25)
    ]
  },
  13: {
    1: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 50, 50),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    2: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 50, 50),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    3: [
      new Position(0, 0, 50, 50),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    4: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 50, 50),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    5: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 50, 50),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25)
    ]
  },
  14: {
    1: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25)
    ],
    2: [
      new Position(0, 0, 40, 50),
      new Position(40, 0, 40, 50),
      new Position(80, 0, 20, 25),
      new Position(80, 25, 20, 25),
      new Position(0, 50, 20, 25),
      new Position(20, 50, 20, 25),
      new Position(40, 50, 20, 25),
      new Position(60, 50, 20, 25),
      new Position(80, 50, 20, 25),
      new Position(0, 75, 20, 25),
      new Position(20, 75, 20, 25),
      new Position(40, 75, 20, 25),
      new Position(60, 75, 20, 25),
      new Position(80, 75, 20, 25)
    ]
  },
  15: {
    1: [
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ],
    2: [
      new Position(0, 0, 20, 25),
      new Position(20, 0, 20, 25),
      new Position(40, 0, 20, 25),
      new Position(60, 0, 20, 25),
      new Position(80, 0, 20, 25),
      new Position(0, 25, 20, 25),
      new Position(20, 25, 60, 50),
      new Position(80, 25, 20, 25),
      new Position(0, 50, 20, 25),
      new Position(80, 50, 20, 25),
      new Position(0, 75, 20, 25),
      new Position(20, 75, 20, 25),
      new Position(40, 75, 20, 25),
      new Position(60, 75, 20, 25),
      new Position(80, 75, 20, 25)
    ],
    3: [
      new Position(0, 0, 16.67, 25),
      new Position(16.67, 0, 16.67, 25),
      new Position(33.33, 0, 16.67, 25),
      new Position(50, 0, 16.67, 25),
      new Position(66.67, 0, 16.67, 25),
      new Position(83.33, 0, 16.67, 25),
      new Position(0, 25, 33.33, 25),
      new Position(33.33, 25, 33.33, 25),
      new Position(66.67, 25, 33.33, 25),
      new Position(0, 50, 33.33, 25),
      new Position(33.33, 50, 33.33, 25),
      new Position(66.67, 50, 33.33, 25),
      new Position(0, 75, 16.67, 25),
      new Position(16.67, 75, 16.67, 25),
      new Position(33.33, 75, 16.67, 25),
      new Position(50, 75, 16.67, 25),
      new Position(66.67, 75, 16.67, 25),
      new Position(83.33, 75, 16.67, 25)
    ]
  },
  16: {
    1: [
      new Position(0, 0, 25, 25),
      new Position(25, 0, 25, 25),
      new Position(50, 0, 25, 25),
      new Position(75, 0, 25, 25),
      new Position(0, 25, 25, 25),
      new Position(25, 25, 25, 25),
      new Position(50, 25, 25, 25),
      new Position(75, 25, 25, 25),
      new Position(0, 50, 25, 25),
      new Position(25, 50, 25, 25),
      new Position(50, 50, 25, 25),
      new Position(75, 50, 25, 25),
      new Position(0, 75, 25, 25),
      new Position(25, 75, 25, 25),
      new Position(50, 75, 25, 25),
      new Position(75, 75, 25, 25)
    ]
  },
  17: {
    1: [
      new Position(0, 0, 40, 50),
      new Position(40, 0, 20, 25),
      new Position(60, 0, 20, 25),
      new Position(80, 0, 20, 25),
      new Position(40, 25, 20, 25),
      new Position(60, 25, 20, 25),
      new Position(80, 25, 20, 25),
      new Position(0, 50, 20, 25),
      new Position(20, 50, 20, 25),
      new Position(40, 50, 20, 25),
      new Position(60, 50, 20, 25),
      new Position(80, 50, 20, 25),
      new Position(0, 75, 20, 25),
      new Position(20, 75, 20, 25),
      new Position(40, 75, 20, 25),
      new Position(60, 75, 20, 25),
      new Position(80, 75, 20, 25)
    ]
  },
  18: {
    1: [
      new Position(0, 0, 20, 25),
      new Position(20, 0, 20, 25),
      new Position(40, 0, 20, 25),
      new Position(60, 0, 20, 25),
      new Position(80, 0, 20, 25),
      new Position(0, 25, 20, 25),
      new Position(20, 25, 20, 25),
      new Position(40, 25, 20, 25),
      new Position(60, 25, 20, 25),
      new Position(80, 25, 20, 25),
      new Position(0, 50, 20, 25),
      new Position(20, 50, 20, 25),
      new Position(40, 50, 20, 25),
      new Position(60, 50, 20, 25),
      new Position(80, 50, 20, 25),
      new Position(0, 75, 20, 25),
      new Position(20, 75, 20, 25),
      new Position(40, 75, 20, 25)
    ]
  },
  19: {
    1: [
      new Position(0, 0, 20, 25),
      new Position(20, 0, 20, 25),
      new Position(40, 0, 20, 25),
      new Position(60, 0, 20, 25),
      new Position(80, 0, 20, 25),
      new Position(0, 25, 20, 25),
      new Position(20, 25, 20, 25),
      new Position(40, 25, 20, 25),
      new Position(60, 25, 20, 25),
      new Position(80, 25, 20, 25),
      new Position(0, 50, 20, 25),
      new Position(20, 50, 20, 25),
      new Position(40, 50, 20, 25),
      new Position(60, 50, 20, 25),
      new Position(80, 50, 20, 25),
      new Position(0, 75, 20, 25),
      new Position(20, 75, 20, 25),
      new Position(40, 75, 20, 25),
      new Position(60, 75, 20, 25)
    ]
  },
  20: {
    1: [
      new Position(0, 0, 20, 25),
      new Position(20, 0, 20, 25),
      new Position(40, 0, 20, 25),
      new Position(60, 0, 20, 25),
      new Position(80, 0, 20, 25),
      new Position(0, 25, 20, 25),
      new Position(20, 25, 20, 25),
      new Position(40, 25, 20, 25),
      new Position(60, 25, 20, 25),
      new Position(80, 25, 20, 25),
      new Position(0, 50, 20, 25),
      new Position(20, 50, 20, 25),
      new Position(40, 50, 20, 25),
      new Position(60, 50, 20, 25),
      new Position(80, 50, 20, 25),
      new Position(0, 75, 20, 25),
      new Position(20, 75, 20, 25),
      new Position(40, 75, 20, 25),
      new Position(60, 75, 20, 25),
      new Position(80, 75, 20, 25)
    ]
  }
};
