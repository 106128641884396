import { sm } from 'jssm';
import * as _ from "lodash";

export enum PeerConnectionState {
    peer_not_connected = 1,
    peer_connect_req,
    peer_connect_wait,
    peer_connecting,
    peer_connected,
    peer_closed
}

export enum PeerConnectionEvent {
    conn_req = 1,
    conn_wait,
    conn_accept,
    conn_reject,
    connected,
    conn_fail,
    closed
}

export type PeerStateEventResult = {
    success: Readonly<boolean>,
    inputEvent: Readonly<PeerConnectionEvent>,
    peerRtcId: Readonly<string>,
    oldState: Readonly<PeerConnectionState>,
    newState: Readonly<PeerConnectionState>
};

export class PeerConnectionSm {
    /* 
    // Slimmed-down graphviz edge list with consistent events from labels
    private _machine = sm`
    peer_not_connected 'conn_req' -> peer_connect_req;
    peer_not_connected 'conn_wait' -> peer_connect_wait;
    peer_not_connected 'connected' -> peer_connected;
    peer_not_connected 'closed' -> peer_closed;
    
    peer_connect_req 'conn_accept' -> peer_connecting;
    peer_connect_req 'closed' -> peer_closed;
    peer_connect_req 'conn_reject' -> peer_not_connected;
    peer_connect_req 'connected' -> peer_connected;
    
    peer_connect_wait 'conn_accept' -> peer_connecting;
    peer_connect_wait 'closed' -> peer_closed;
    
    peer_connecting 'connected' -> peer_connected;
    peer_connecting 'conn_fail' -> peer_not_connected;
    peer_connecting 'closed' -> peer_closed;
    
    peer_connected 'conn_fail' -> peer_not_connected;
    peer_connected 'closed' -> peer_closed;
    `;
    */

    // Terse definition is better?
    private _machine = sm`
    peer_not_connected 'conn_req' -> peer_connect_req;
    peer_not_connected 'conn_wait' -> peer_connect_wait;
    peer_not_connected 'connected' -> peer_connected;

    peer_connect_req 'conn_reject' -> peer_not_connected;

    [peer_not_connected peer_connect_req peer_connect_wait] 'conn_accept' -> peer_connecting;

    [peer_connect_req peer_connecting] 'connected' -> peer_connected;

    [peer_connecting peer_connected] 'conn_fail' -> peer_not_connected;

    [peer_not_connected peer_connect_req peer_connect_wait peer_connecting peer_connected] 'closed' -> peer_closed;
    `;

    private _peerId: string;

    /**
     * this can be null if the peer is an orphan
     */
    private _confId: string;

    /**
     * Track if this peer is a voice boolean
     */
    private _isVoicePeer: boolean;


    get peerId() : Readonly<string> {
        return this._peerId;
    }

    get isVoicePeer() : Readonly<boolean> {
        return this._isVoicePeer;
    }

    get confId() : Readonly<string> {
        return this._confId;
    }

    get state(): Readonly<PeerConnectionState> {
        return PeerConnectionState[this._machine.state()];
    }

    public associateWithConfId(confId : string)
    {
        this._confId = confId;
    }

    constructor(peerRtcId: string, confId: string, isVoice : boolean) {
        this._peerId = peerRtcId;
        this._confId = confId;
        this._isVoicePeer = isVoice;
    }

    check(event: Readonly<PeerConnectionEvent>): Readonly<boolean> {
        return this._machine.valid_action(PeerConnectionEvent[event])
    }

    event(event: Readonly<PeerConnectionEvent>): Readonly<PeerStateEventResult> {
        let oldState = _.clone(this.state);
        let result = this._machine.action(PeerConnectionEvent[event]);
        console.log(`Peer ${this.peerId} transition ${PeerConnectionState[oldState]} -> 
            ${PeerConnectionEvent[event]} = ${PeerConnectionState[this.state]}`);
        return {success: result, inputEvent: event, peerRtcId: this.peerId, oldState: oldState, newState: this.state};
    }
}