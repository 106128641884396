<!--
    Copyright Compunetix Incorporated 2021
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<top-bar></top-bar>
<nav-bar></nav-bar>
<main-container></main-container>
<dialog-container-in-page>
  <page-title></page-title>
  <h1 class="container text-center">License Activation QR Code</h1>
  <hr>
  <div class="container" *ngIf="viewModel.machineId">
    <div class="row">
      <div class="col-md-4" *ngIf="viewModel.step === 0">
        <p class="h5">
          Please enter your license key below to begin the activation process.
        </p>
        <form (submit)="handleLicenseKeySubmit($event)">
          <p>
            <input class="form-control" type='text' [(ngModel)]="viewModel.key" (change)="handleLicenseKeyChange($event)" name="key"/>
          </p>
          <p>
            <input type="submit" value="Submit" class="btn btn-primary" />
          </p>
        </form>
      </div>
      <div class="col-md-4" *ngIf="viewModel.step > 0">
        <p class="h5">
          License Key
        </p>
        <p>
          {{viewModel.key}}
        </p>
        <button class="btn" (click)="reset()">Reset All</button>
      </div>
      
      <div class="col-md-4" *ngIf="viewModel.step === 1">
        <p class="h5">
          Scan this QR code using the Activation Portal's scanner on your mobile device.
        </p>
        <br>
        <div id="qr-container">
          <qrcode *ngIf="viewModel.qrcode" [qrdata]="viewModel.qrcode" [size]="256" [level]="'M'" usesvg="true"></qrcode>
        </div>
        <br>
        <button class="btn btn-primary" type='button' (click)="handleGoToNextStep(2)">
          Got the Activation Proof, Continue
        </button>
      </div>
      <div class="col-md-4" *ngIf="viewModel.step > 1">
        <p class="h5">
          QR code
        </p>
        <p>
          <qrcode *ngIf="viewModel.qrcode" [qrdata]="viewModel.qrcode" [size]="256" [level]="'M'" usesvg="true"></qrcode>
        </p>
        <button class="btn" (click)="reset()">Reset All</button>
      </div>
      
      <div class="col-md-4" *ngIf="viewModel.step === 2">
        <p class="h5">
          Enter the activation proof displayed on your mobile device.
        </p>
        <form (submit)="handleActivationProofSubmit($event)">
          <p>      
            <input class="form-control" type='text' [(ngModel)]="viewModel.proof" (change)="handleActivationProofChange($event)" name="proof"/>
          </p>
          <p>
            <input type="submit" value="Submit" class="btn btn-primary" />
          </p>
        </form>
      </div>
      <div class="col-md-4" *ngIf="viewModel.step > 2">
        <p class="h5">
          Activation Proof
        </p>
        <p class="token-box">
          {{viewModel.proof}}
        </p>
        <br>
        <p class="h5 alert-success p-1" *ngIf="viewModel.isVerified">
          Machine has successfully been activated!
        </p>
        <p class="h5 alert-danger p-1" *ngFor="let error of viewModel.errors">
          Proof Verification Error: {{error}}
        </p>
        <button class="btn" (click)="reset()" *ngIf="!viewModel.isVerified">Reset All</button>
      </div>
    </div>
  </div>
  <hr>
</dialog-container-in-page>
<footer></footer>

