/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender, lcheng
 */

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { UserFormComponent } from "./user-form.component";
import { SharedModule } from "../shared/shared.module";
import { GroupManagementModule } from "../group-management/group-management.module";
import { LayoutModule } from "./../layout/layout.module";
import { LoginModule } from "./../login/login.module";
import { PasswordResetModule } from "./../password-reset/password-reset.module";

import { UserProfileComponent } from "./user-profile.component";
import { UserManagementService } from "../user-management/user-management.service";
import { FormModule } from "../form/form.module";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    GroupManagementModule,
    LayoutModule,
    LoginModule,
    PasswordResetModule,
    FormModule
  ],
  declarations: [UserFormComponent, UserProfileComponent],
  providers: [UserManagementService],
  exports: [UserFormComponent, UserProfileComponent]
})
export class UserProfileModule {}
