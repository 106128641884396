/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Injectable } from "@angular/core";
import { RestService } from "../shared/services/rest.service";

@Injectable()
/**
 * notification related rest api client methods delegate
 */
export class NotificationService {
  constructor(private restService: RestService) {
    // nothing needed here
  }

  /**
   * get system notification
   */
  getSystemNotification(): Promise<any> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getSystemNotification", {})
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * send system notification
   * @param content: string - notification content
   */
  sendSystemNotification(content: string): Promise<{}> {
    return new Promise((resolve: ({}) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/sendSystemNotification", { notification: content })
      .subscribe(
        (res: any) => {
          resolve({});
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
}
