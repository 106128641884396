/**
 * Copyright Compunetix Incorporated 2016-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { ISharedFileRef } from "companion/sharedfile/sharedfile.interface";
import { IEndpoint, IEndpointRef } from "../endpoint/endpoint.interface";
import { IConferenceBase } from "./conference.base.interface";

// For containers to hold and use child types but in the same place.
export type IConferenceRef = IActiveConference|IQueueConference|IMonitorConference;
export type IConferenceExpanded = IExpandedActiveConference|IExpandedQueueConference|IExpandedMonitorConference;

export interface IConferenceReferenceBase extends IConferenceBase {
  everyone: IEndpointRef[];
}

export interface IQueueConference extends IConferenceReferenceBase {
  publicWaitList?: IEndpointRef[];
  operators?: IEndpointRef[];
  reps?: IEndpointRef[];
  everyone: IEndpointRef[];
}

export interface IActiveConference extends IConferenceReferenceBase {
  active: IEndpointRef[];
  held: IEndpointRef[];
  recorderRtcIds: string[];
  filesOffered: ISharedFileRef[];
  everyone: IEndpointRef[];
}

export interface IMonitorConference extends IConferenceReferenceBase {
  subjects: IEndpointRef[];
  observers: IEndpointRef[];
  everyone: IEndpointRef[];
}


export interface IConferenceExpandedBase extends IConferenceBase {
  everyone: IEndpoint[];
  toRef(): IConferenceRef;
}

export interface IExpandedQueueConference extends IConferenceExpandedBase {
  publicWaitList?: IEndpoint[];
  operators?: IEndpoint[];
  reps?: IEndpoint[];
  everyone: IEndpoint[];
  toRef(): IQueueConference;
}

export interface IExpandedActiveConference extends IConferenceExpandedBase {
  active: IEndpoint[];
  held: IEndpoint[];
  recorderRtcIds: string[];
  filesOffered: ISharedFileRef[];
  everyone: IEndpoint[];
  toRef(): IActiveConference;
}

export interface IExpandedMonitorConference extends IConferenceExpandedBase {
  subjects: IEndpoint[];
  observers: IEndpoint[];
  everyone: IEndpoint[];
  toRef(): IMonitorConference;
}

export enum RoomType {
  Default = "Default", // Base server room
  Conference = "Conference", // Room for everyone connected in the same media context
  OpWaitRoom = "OpWaitRoom", // Room for operators waiting? Command Distrib?
  OpChatRoom = "OpChatRoom", // Room for operators chatting? Chat messages?
  SpWaitRoom = "SpWaitRoom", // Room for specialists/supervisors waiting? Command Distrib? 
  SpChatRoom = "SpChatRoom", // Room for specialists/supervisors chatting? Chat messages?
  GuestWaitRoom = "GuestWaitRoom", // Room for guests waiting. Commad Distrib?
  Monitor = "Monitor", // Monitoring observation room. Like RoomType.Conference but with special rules.
}

/**
 * The data structure for a client fetching a conference update from the server.
 * It returns context dependant data (based on your client) of all the endpoints and 
 * conferences relevant to you on the system.
 */
export interface IConferenceUpdate {
  queues: IQueueConference[];
  conferences: IActiveConference[];
  monitors: IMonitorConference[];
  endpoints: IEndpoint[];
}
