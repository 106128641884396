/**
 * Copyright Compunetix Incorporated 2018 - 2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender, lcheng
 */
import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import {
  IUser,
  User,
  Companion,
  IRole,
  IGroup,
  Alert,
  AlertLevel,
  ILocalization,
  PasswordChecker,
  IParameter,
  ParameterType,
  IQueueConfig,
  ISkillSet
} from "companion";
import { UserManagementService } from "../user-management/user-management.service";
import { GroupManagementService } from "../group-management/group-management.service";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { FormService } from "../form/form.service";
import { FormGroup } from "@angular/forms";
import { FormComponent } from "../form/form.component";
import { ICategory } from "../../../companion/skills/skill.interface";
import { TreeViewItem } from "../shared/components/tree-view/tree-item";

@Component({
  selector: "user-form",
  templateUrl: "./user-form.component.html",
})
export class UserFormComponent implements OnInit {
  /**
   * Emits events for the form when creates/edits/deletes occur.
   */
  @Output("changeOccured")
  changeEmitter: EventEmitter<IUser> = new EventEmitter<IUser>();

  /**
   * The account associated with the user.
   */
  public userAccount: IUser;

  /**
   *  available roles to use
   */
  availableRoles: any[];

  /**
   * the selected role item
   */
  selectedRole: string;

  /**
   * flag if this is editing
   */
  isEditing: boolean;

  /**
   * if cancel button enabled
   */
  cancelEnabled: boolean = true;

  /**
   * submit button title
   */
  submitButtonText: string = "Submit";

  /**
   * current form for the selected conference
   */
  currentForm: FormGroup;

  /**
   * current form for the selected conference
   */
  currentParameters: { [key: string]: IParameter };

  /**
   * Prevent double submission.
   */
  submitting : boolean = false;

  @ViewChild("FormComponent") formComponent: FormComponent;

  /**
   * the localization theme data.
   */
  @Input() localizationData: ILocalization;

  categoriesTree: TreeViewItem[];

  constructor(
    private userManagementService: UserManagementService,
    private groupManagementService: GroupManagementService
  ) {
    // make a blank user to avoid undefined errors
    this.userAccount = new User();
    Dispatcher.register(ActionType.OpenUserProfileForm, (payload: any) => {
      if (payload.isEditing && payload.user) {
        this.isEditing = true;
        this.submitButtonText = "Save";
        this.OpenUserProfileForm(payload.user);
      } else {
        this.isEditing = false;
        this.submitButtonText = "Create";
        this.OpenUserProfileForm(null);
      }
      this.cancelEnabled = true;
      Dispatcher.dispatch(ActionType.OpenModalDialog, "user-form");
    });
  }

  ngOnInit() {
    this.getRoles();
    this.groupManagementService.getGroupTrees().catch((error: any) => {
      console.log(`Failed to getGroupTrees: ${JSON.stringify(error)}`);
    });
    this.isEditing = true;
    this.submitButtonText = "Update Profile";
    this.cancelEnabled = false;
    this.OpenUserProfileForm(Companion.getUserService().currentUser);
  }


  OpenUserProfileForm(user: IUser) {
    this.setFormDefault();
    this.submitting = false;
    this.load(user).catch((error: any) => {
      // should be handled in load
    });
  }

  setFormDefault() {
    this.userAccount = new User();
    // this.confirmPassword = null;

    if (!this.availableRoles) {
      // empty roles
      this.availableRoles = [{ name: "NONE", _id: "0" }];
      this.selectedRole = "";
    } else {
      // assign the first role to our testrole
      this.selectedRole = "";
    }
  }

  getRoles() {
    // get the roles
    this.userManagementService.getRoles().then((data: IRole[]) => {
      this.availableRoles = data;
    }).catch((error: any) => {
      console.log(`Failed to getRoles: ${JSON.stringify(error)}`);
    });
  }

  /**
   * load form
   */
  load(user?: IUser): Promise<void> {
    if (user) {
      return this.convertUserToParameters(user)
      .then((parameters: { [key: string]: IParameter }) => {
        this.userAccount = user;
        this.currentParameters = parameters;
        this.currentForm = FormService.toFormGroup(this.currentParameters);
        // this.focus(); // TODO: to be fixed, it's always focusing "real name" field
      })
      .catch((err: Error) => {
        Dispatcher.dispatch(ActionType.Alert, {
          alert: new Alert(err.message, "Cannot load user profile.", AlertLevel.warning),
        });
      });
    } else {
      return this.getDefaultUserProfileFormParameters()
      .then((parameters: { [key: string]: IParameter }) => {
        this.currentParameters = parameters;
        this.currentForm = FormService.toFormGroup(this.currentParameters);
        this.focus();
        this.userAccount = this.convertParametersToUser(this.currentParameters);
      })
      .catch((err: Error) => {
        Dispatcher.dispatch(ActionType.Alert, {
          alert: new Alert(err.message, "Cannot load default user profile.", AlertLevel.warning),
        });
      });
    }
  }

  save() {
    if(this.submitting)
    {
      // already sumbitting prevent double submit.
      return;
    }

    this.submitting = true;

    FormService.updateParametersByForm(this.currentForm, this.currentParameters);
    let data = this.convertParametersToUser(this.currentParameters);
    let currentUser: IUser = Companion.getUserService().currentUser;
    let actionPromise = 
      this.isEditing
        ? this.userManagementService.editUserProfile(data).then((updatedUser: IUser) => {
            Dispatcher.dispatch(ActionType.Alert, {
              alert: new Alert("USER_PROFILE_EDIT_SUCCESS", "User profile changed successfully.", AlertLevel.success),
            });
            // if we are the current user, make sure we update our backing data
            // so that the 'My Account' page is correct.
            if (updatedUser["_id"] == currentUser["_id"]) {
              _.assign(currentUser, updatedUser);
              Dispatcher.dispatch(ActionType.LoadUserProfileData, { userId: currentUser["_id"] });
            }
          })
        : this.userManagementService.createUser(data, this.localizationData).then((newUser: IUser) => {
            Dispatcher.dispatch(ActionType.Alert, {
              alert: new Alert("USER_PROFILE_CREATE_SUCCESS", "User created successfully.", AlertLevel.success),
            });
          });
    actionPromise.then(() => {
      Dispatcher.dispatch(ActionType.HideModalDialog, "user-form");
      Dispatcher.dispatch(ActionType.LoadUserData);
    }).catch((error: any) => {
      console.log(`Failed to save user data: ${JSON.stringify(error)}`);
      this.submitting = false;
    });
  }

  cancel() {
    Dispatcher.dispatch(ActionType.HideModalDialog, "user-form");
  }

  /**
   * get default user profile form template parameters
   */
  getDefaultUserProfileFormParameters(): Promise<{ [key: string]: IParameter }> {
    let formParameters: { [key: string]: IParameter } = {};
    formParameters[UserProfileFormKey[UserProfileFormKey.user_account_enabled]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_account_enabled],
      title: "User Account",
      type: ParameterType[ParameterType.hidden],
      value: true,
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_account_id]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_account_id],
      title: "User Id",
      type: ParameterType[ParameterType.hidden],
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_account_group]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_account_group],
      title: "Group",
      type: ParameterType[ParameterType.dropdown],
      required: true,
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_account_username]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_account_username],
      title: "Username/Email Address",
      type: ParameterType[ParameterType.string],
      required: true,
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_account_password]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_account_password],
      title: "Password",
      tooltip: "",
      type: ParameterType[ParameterType.password],
      lockable: true,
      required: true,
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_account_confirm_password]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_account_confirm_password],
      title: "Confirm Password",
      type: ParameterType[ParameterType.password],
      lockable: true,
      required: true,
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_enabled]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_profile_enabled],
      title: "User Profile",
      type: ParameterType[ParameterType.hidden],
      value: true,
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_role]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_profile_role],
      title: "Role",
      type: ParameterType[ParameterType.dropdown],
      required: true,
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_real_name]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_profile_real_name],
      title: "Real Name",
      type: ParameterType[ParameterType.string],
      required: false,
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_company]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_profile_company],
      title: "Company/Department",
      type: ParameterType[ParameterType.string],
      required: false,
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_email]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_profile_email],
      title: "Email Address",
      type: ParameterType[ParameterType.email],
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_phone]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_profile_phone],
      title: "Phone Number",
      type: ParameterType[ParameterType.phone],
    };
    formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_default_reminder]] = {
      key: UserProfileFormKey[UserProfileFormKey.user_profile_default_reminder],
      title: "Default Alert Reminder",
      type: ParameterType[ParameterType.dropdown],
      options: ReminderOptions,
      value: "-1",
      required: true,
    };
    let _tasks: Promise<any>[] = [];
    _tasks.push(
      this.groupManagementService.getAllAccessibleGroups().then((groups: IGroup[]) => {
        formParameters[UserProfileFormKey[UserProfileFormKey.user_account_group]].options = _.fromPairs(
          _.map(groups, (group: IGroup) => {
            return [group["_id"], group.name];
          })
        );
        if (groups.length > 0) {
          formParameters[UserProfileFormKey[UserProfileFormKey.user_account_group]].value = groups[0]["_id"];
        }
        if (groups.length <= 1) {
          formParameters[UserProfileFormKey[UserProfileFormKey.user_account_group]].type = ParameterType[ParameterType.hidden];
        }
        return Promise.resolve(formParameters);
      })
    );
    _tasks.push(
      this.userManagementService.getRoles().then((roles: IRole[]) => {
        formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_role]].options = _.fromPairs(
          _.map(roles, (role: IRole) => {
            return [role["_id"], role.name];
          })
        );
        if (roles.length > 0) {
          formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_role]].value = roles[0]["_id"];
        }
        if (roles.length <= 1) {
          formParameters[UserProfileFormKey[UserProfileFormKey.user_profile_role]].type = ParameterType[ParameterType.hidden];
        }
        return Promise.resolve(formParameters);
      })
    );
    let currentUser: IUser = Companion.getUserService().currentUser;
    this.groupManagementService.initGroupMessageTemplateParameters(
      formParameters,
      currentUser.permissions && currentUser.permissions.canManageMessageTemplate > 0
    );
    _tasks.push(
      this.groupManagementService.getGroup(currentUser.groups[0]).then((group: IGroup) => {
        if (group) {
          this.groupManagementService.convertGroupMessageTemplatesToParameters(group, formParameters);
          formParameters[UserProfileFormKey[UserProfileFormKey.user_account_password]].tooltip = this.passwordPolicyString(group["_id"]);
        }
      })
    );
    _tasks.push(
      // get queue config from special acessor, as they may be inherited from parent.
      this.groupManagementService.getGroupsQueueConfig(currentUser.groups[0])
      .then((queueConfig: IQueueConfig) => {
        if (queueConfig) {
          this.convertQueueConfigToSkillSetOptions(queueConfig, formParameters);
        }
      })
    );

    return Promise.all(_tasks)
    .then(() => {
      return Promise.resolve(formParameters);
    })
    .catch((err: Error) => {
      return Promise.reject(err);
    });
  }

  /**
   * Get the options for skillsets from the group and build the form with them.
   */
  convertQueueConfigToSkillSetOptions(queueConfig: IQueueConfig, formParameters: { [key: string]: IParameter }) {
    if (queueConfig?.skillSet) {
      formParameters[UserProfileFormKey[UserProfileFormKey.skill_enabled]] = {
        key: UserProfileFormKey[UserProfileFormKey.skill_enabled],
        title: "User Skills",
        type: ParameterType[ParameterType.hidden],
        value: true,
      };

      this.categoriesTree = this.groupManagementService.getCategoriesTreeFromCategorySubskills(queueConfig.skillSet.categorySubskills);
      formParameters[UserProfileFormKey[UserProfileFormKey.skill_categories]] = {
        key: UserProfileFormKey[UserProfileFormKey.skill_categories],
        title: "Skill Categories",
        type: ParameterType[ParameterType.multiselect_tree],
        options: this.categoriesTree
      };

      formParameters[UserProfileFormKey[UserProfileFormKey.skill_languages]] = {
        key: UserProfileFormKey[UserProfileFormKey.skill_languages],
        title: "Proficient Languages",
        type: ParameterType[ParameterType.checkbox_list],
        value: _.fromPairs(
          _.map(queueConfig.skillSet.language, (language: string) => {
            return [language, false];
          })),
        required: false,
      };
    }
  }

  /**
   * convert user profile data to parameters
   */
  convertUserToParameters(data: IUser): Promise<{ [key: string]: IParameter }> {
    let isSelfEditing: boolean = data["_id"] && data["_id"] === Companion.getUserService().currentUser["_id"];
    return this.groupManagementService.getGroupsQueueConfig(data.groups[0])
    .then((groupQueueConfig: IQueueConfig) => {
      return this.getDefaultUserProfileFormParameters()
      .then((parameters: { [key: string]: IParameter }) => {
        parameters[UserProfileFormKey[UserProfileFormKey.user_account_id]].value = data["_id"];
        if (data["_id"]) {
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_password]].required = false;
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_password]].lockable = true;
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_password]].disabled = true;
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_password]].value = null;
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_confirm_password]].required = false;
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_confirm_password]].lockable = true;
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_confirm_password]].disabled = true;
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_confirm_password]].value = null;
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_username]].type = ParameterType[ParameterType.label];
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_username]].required = false;
          if (data["_id"] === Companion.getUserService().currentUser["_id"]) {
            parameters[UserProfileFormKey[UserProfileFormKey.user_profile_role]].type = ParameterType[ParameterType.hidden];
            parameters[UserProfileFormKey[UserProfileFormKey.user_account_group]].type = ParameterType[ParameterType.hidden];
          }
        }
        if (isSelfEditing) {
          parameters[UserProfileFormKey[UserProfileFormKey.user_profile_role]].type = ParameterType[ParameterType.hidden];
          parameters[UserProfileFormKey[UserProfileFormKey.user_profile_role]].required = false;
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_group]].type = ParameterType[ParameterType.hidden];
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_group]].required = false;
        }
        parameters[UserProfileFormKey[UserProfileFormKey.user_account_username]].value = data.username;
        parameters[UserProfileFormKey[UserProfileFormKey.user_profile_real_name]].value = data.name;
        parameters[UserProfileFormKey[UserProfileFormKey.user_profile_company]].value = data.company;
        parameters[UserProfileFormKey[UserProfileFormKey.user_profile_email]].value = data.email;
        parameters[UserProfileFormKey[UserProfileFormKey.user_profile_phone]].value = data.phone;
        if (data.roles && data.roles[0]) {
          parameters[UserProfileFormKey[UserProfileFormKey.user_profile_role]].value = data.roles[0]["_id"] || data.roles[0];
        }
        if (data.groups && data.groups[0]) {
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_group]].value = data.groups[0]["_id"] || data.groups[0];
          parameters[UserProfileFormKey[UserProfileFormKey.user_account_password]].tooltip = this.passwordPolicyString(data.groups[0]["_id"] || data.groups[0]);
        }
        if (data.reminder) {
          parameters[UserProfileFormKey[UserProfileFormKey.user_profile_default_reminder]].value = data.reminder;
        }
        if (data.messagingTemplate) {
          this.groupManagementService.convertGroupMessageTemplatesToParameters(data as IGroup, parameters);
        }

        if (groupQueueConfig && groupQueueConfig.status !== "disabled") {
          this.convertQueueConfigToSkillSetOptions(groupQueueConfig, parameters);
        }
        else
        {
          delete parameters[UserProfileFormKey[UserProfileFormKey.skill_enabled]];
          delete parameters[UserProfileFormKey[UserProfileFormKey.skill_categories]];
          delete parameters[UserProfileFormKey[UserProfileFormKey.skill_languages]];
        }

        if (data.skillSet && parameters[UserProfileFormKey[UserProfileFormKey.skill_enabled]]?.value) {
          this.markSelectedCategorySubskills(data.skillSet.category);

          _.forEach(data.skillSet.language, (language: string) => {
            parameters[UserProfileFormKey[UserProfileFormKey.skill_languages]].value[language] = true;
          });
        }
        return Promise.resolve(parameters);
      });
    });
  }

  convertParametersToUser(parameters: { [key: string]: IParameter }): IUser {
    let user: IUser = { roles: [], groups: [] };
    let parameterValues: { [key: string]: any } = FormService.collectParameterValues(parameters);
    if (parameterValues[UserProfileFormKey[UserProfileFormKey.user_account_id]]) {
      user["_id"] = parameterValues[UserProfileFormKey[UserProfileFormKey.user_account_id]];
    }
    user.username = parameterValues[UserProfileFormKey[UserProfileFormKey.user_account_username]];
    if (parameterValues[UserProfileFormKey[UserProfileFormKey.user_account_password]]) {
      user.password = parameterValues[UserProfileFormKey[UserProfileFormKey.user_account_password]];
    }
    if (parameterValues[UserProfileFormKey[UserProfileFormKey.user_profile_role]]) {
      user.roles = [parameterValues[UserProfileFormKey[UserProfileFormKey.user_profile_role]]];
    }
    if (parameterValues[UserProfileFormKey[UserProfileFormKey.user_account_group]]) {
      user.groups = [parameterValues[UserProfileFormKey[UserProfileFormKey.user_account_group]]];
    }
    user.name = parameterValues[UserProfileFormKey[UserProfileFormKey.user_profile_real_name]];
    user.company = parameterValues[UserProfileFormKey[UserProfileFormKey.user_profile_company]];
    user.email = parameterValues[UserProfileFormKey[UserProfileFormKey.user_profile_email]];
    user.phone = parameterValues[UserProfileFormKey[UserProfileFormKey.user_profile_phone]];
    user.reminder = parameterValues[UserProfileFormKey[UserProfileFormKey.user_profile_default_reminder]];
    this.groupManagementService.convertParameterValuesToGroupMessageTemplates(parameterValues, user as IGroup);

    user.skillSet = {category: null, language: null};

    if (parameterValues[UserProfileFormKey[UserProfileFormKey.skill_enabled]]) {
      let categories: string[] = this.groupManagementService.getCategoriesListFromTreeView(this.categoriesTree, true);

      let languages: any[] = _.map(_.filter(_.toPairs(parameterValues[UserProfileFormKey[UserProfileFormKey.skill_languages]]),
      (pairs: string[]) => (pairs[1])),  (pairs: string[]) => {
        return pairs[0];
      });

      user.skillSet.category = categories;
      user.skillSet.language = languages;
    } else {
      user.skillSet = null;
    }
    return user;
  }

  focus() {
    if (this.formComponent) {
      this.formComponent.focusOnFirstField();
    }
  }

  passwordPolicyString(group: string): string {
    if (!group) {
      return "";
    }
    const selectedGroup: IGroup = this.groupManagementService.allAccessibleGroupsDictionary[group];
    const policy = selectedGroup?.AuthenticationConfig?.passwordPolicy;

    if(!policy)
    {
      return "";
    }

    return `Min length: ${policy?.minimumLength},
    Max length: ${policy?.maximumLength}<br>
    Min Numbers: ${policy?.minimumNumbers},
    Min Lowercase: ${policy?.minimumLowercase}<br>
    Min Uppercase: ${policy?.minimumUppercase},
    Min Special Char: ${policy?.minimumSpecialCharacters}`;
  }

  formValueChanged(fieldKey: string) {
    if (
      fieldKey === UserProfileFormKey[UserProfileFormKey.user_account_password] ||
      fieldKey === UserProfileFormKey[UserProfileFormKey.user_account_confirm_password]
    ) {
      FormService.updateParametersByForm(this.currentForm, this.currentParameters);
      // Read data from the params back into the temp user.
      this.userAccount = this.convertParametersToUser(this.currentParameters);

      // Check password against policy.
      let passwordParameter = this.currentParameters[UserProfileFormKey[UserProfileFormKey.user_account_password]];
      let groupParameter = this.currentParameters[UserProfileFormKey[UserProfileFormKey.user_account_group]];
      if (groupParameter.value && passwordParameter.value) {
        const selectedGroup: IGroup = this.groupManagementService.allAccessibleGroupsDictionary[groupParameter.value];
        const passwordProblem = PasswordChecker.CheckPasswordAgainstPolicy(
          selectedGroup.AuthenticationConfig.passwordPolicy,
          passwordParameter.value
        );
        if (passwordProblem) {
          passwordParameter.errorMessage = passwordProblem;
        } else {
          delete passwordParameter.errorMessage;
        }
        let confirmPasswordParameter = this.currentParameters[
          UserProfileFormKey[UserProfileFormKey.user_account_confirm_password]
        ];
        if (passwordParameter.value != confirmPasswordParameter.value) {
          confirmPasswordParameter.errorMessage = "Confirm password must match password";
        } else {
          delete confirmPasswordParameter.errorMessage;
        }
      }
    }

    if (
      fieldKey === UserProfileFormKey[UserProfileFormKey.user_account_group]
    ) {
      FormService.updateParametersByForm(this.currentForm, this.currentParameters);
      // Read data from the params back into the temp user.
      this.userAccount = this.convertParametersToUser(this.currentParameters);

      let groupParameter = this.currentParameters[UserProfileFormKey[UserProfileFormKey.user_account_group]];
      // Detect group change.
      if (groupParameter.value) {
        this.groupManagementService.getGroupsQueueConfig(groupParameter.value)
        .then((queueConfig: IQueueConfig) => {
          if (queueConfig && queueConfig.status !== "disabled") {
            // here it is...
            this.convertQueueConfigToSkillSetOptions(queueConfig, this.currentParameters);
          }
          else
          {
            // remove the skills from the form
            delete this.currentParameters[UserProfileFormKey[UserProfileFormKey.skill_enabled]];
            delete this.currentParameters[UserProfileFormKey[UserProfileFormKey.skill_categories]];
            delete this.currentParameters[UserProfileFormKey[UserProfileFormKey.skill_languages]];
          }

          // Reset the group information.
          this.userAccount.groups = [groupParameter.value];
          this.userAccount.skillSet = {} as ISkillSet;
          this.resetForm();
        })
        .catch((error) => {
          console.log("Failed to getGroupsQueueConfig: ", error);
        });
      }
    }
  }

  resetForm() {
    this.convertUserToParameters(this.userAccount)
      .then((parameters: { [key: string]: IParameter }) => {
        this.currentParameters = parameters;
        this.currentForm = FormService.toFormGroup(this.currentParameters);
        this.focus();
      })
      .catch((err: Error) => {
        Dispatcher.dispatch(ActionType.Alert, {
          alert: new Alert(err.message, "Cannot load user profile.", AlertLevel.warning),
        });
      });
  }

  markSelectedCategorySubskills(categories: string[], roots: TreeViewItem[] = this.categoriesTree) {
    _.forEach(categories, (category: string) => {
      let treeViewItem: TreeViewItem = this.groupManagementService.findTreeItemByCategoryName(category, roots, GroupManagementService.cMAX_CATEGORY_SUBSKILL_LEVELS_SUPPORTED);
      if (treeViewItem) {
        treeViewItem.selected = true;
      }
    });
    return;
  }

}

export enum UserProfileFormKey {
  user_account_enabled,
  user_account_id,
  user_account_group,
  user_account_username,
  user_account_password,
  user_account_confirm_password,
  user_profile_enabled,
  user_profile_role,

  user_profile_real_name,
  user_profile_email,
  user_profile_phone,
  user_profile_default_reminder,
  user_profile_company,
  skill_enabled,
  skill_categories,
  skill_languages
}

export const ReminderOptions: { [value: number]: string } = {
  "-1": "None",
  "15": "15 Minutes",
  "30": "30 Minutes",
  "60": "1 Hour",
  "1440": "1 Day",
};
