/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { GlobalService } from "../services/global.service";
import { IUser } from "./user.interface";
import { User } from "./user";
import { IUserService } from "./user.service.interface";

/**
 * user methods delegate
 */
export class UserService implements IUserService {
  private static sharedInstance: IUserService;
  /**
   * all users
   */
  public users: IUser[] = [];

  /**
   * current user
   */
  public currentUser: IUser;

  constructor() {
    this.currentUser = new User("");
    let cookieUser: IUser = GlobalService.getSessionUser();
    if (cookieUser) {
      this.currentUser.username = cookieUser.username;
      this.currentUser.helperFinished = cookieUser.helperFinished;
      this.currentUser.preferedPrimaryResolution = cookieUser.preferedPrimaryResolution;
      this.currentUser.preferedSecondaryResolution = cookieUser.preferedSecondaryResolution;
      this.currentUser.preferedBandwidth = cookieUser.preferedBandwidth;
      this.currentUser.preferedMicrophoneDeviceId = cookieUser.preferedMicrophoneDeviceId;
      this.currentUser.preferedPrimaryCameraDeviceId = cookieUser.preferedPrimaryCameraDeviceId;
      this.currentUser.preferedSecondaryCameraDeviceId = cookieUser.preferedSecondaryCameraDeviceId;
      this.currentUser.preferedSpeakerDeviceId = cookieUser.preferedSpeakerDeviceId;
      this.currentUser.preferedLanguage = cookieUser.preferedLanguage;
      this.currentUser.preferedAudioMute = cookieUser.preferedAudioMute;
      this.currentUser.preferedVideoMute = cookieUser.preferedVideoMute;
      this.currentUser.preferedVolume = cookieUser.preferedVolume;
      this.currentUser.preferedVideoAspect = cookieUser.preferedVideoAspect;
      this.currentUser.preferedVideoMutedPlaceholder = cookieUser.preferedVideoMutedPlaceholder;
      this.currentUser.preferedVirtualBackgroundImage = cookieUser.preferedVirtualBackgroundImage;
      this.currentUser.preferedVirtualBackgroundEnabled = cookieUser.preferedVirtualBackgroundEnabled;
      this.currentUser.preferredCropperPosition = cookieUser.preferredCropperPosition;
      this.currentUser.preferredCropperEnabled = cookieUser.preferredCropperEnabled;
    }
    this.createUser(this.currentUser);
  }

  /**
   * get shared singleton object
   */
  public static getSharedInstance(): IUserService {
    if (!UserService.sharedInstance) {
      UserService.sharedInstance = new UserService();
    }
    return UserService.sharedInstance;
  }

  /**
   * create a new user
   * @param user: IUser - new user object
   */
  createUser(user: IUser): void {
    this.users.push(user);
  }

  /**
   * @returns Is my current user able to get ringtones for operator functions.?
   */
  amIOperator() : boolean {
    // This is currently anyone that gets ringtone panel.
    return (this.currentUser.permissions.seeActiveEndpointInQueue > 0 &&
            this.currentUser.permissions.actAsOperator > 0);
  }

  /**
   * find or create user by username
   * @param username: string - username to search for
   * @param newUser: IUser - user to create
   */
  findOrCreateUserByName(username: string, newUser?: IUser): IUser {
    let matchedUsers = _.find(this.users, (user: IUser) => {
      return user.username === username;
    });
    if (matchedUsers) {
      if (newUser) {
        matchedUsers.name = newUser.name;
        matchedUsers.skillSet = newUser.skillSet;
        matchedUsers.groups = newUser.groups;
      }
      return matchedUsers;
    } else {
      newUser = newUser || new User(username);
      this.createUser(newUser);
      return newUser;
    }
  }

  /**
   * find user by id
   * @param id: string - id to search for
   */
  findUserById(id: string): IUser {
    return  this.users.find(user => user["_id"] === id);
  }
}
