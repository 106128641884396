/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";
import { IEndpoint } from "companion";

/**
 * filter endpoints by which has stats value.
 * @param	 The collection to iterate over
 * @example Usage:
 * ```html
 * <p>
 *   {{ [] | endpointWithStats }}
 * </p>
 * ```
 */
@Pipe({
  name: "endpointWithStats",
  pure: false,
})
export class EndpointWithStatsPipe {
  tmp: IEndpoint[] = [];
  transform(list: IEndpoint[], args: Array<string[]>): any {
    this.tmp.length = 0;
    this.tmp = _.filter(list, (endpoint: IEndpoint) => {
      return (endpoint.lastStats !== undefined && endpoint.lastStats !== null);
    });
    return this.tmp;
  }
}
