/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { UUID } from "../util/uuid";
import { ISharedFile, FileTransferStatus, ISharedFileRef } from "./sharedfile.interface";

/**
 * the shared or to be shared file class
 */
export class SharedFile implements ISharedFile {
  public ownerId: string;
  public filename: string;
  public fileSize: number = 0;
  public fileBlob: File;
  public status: FileTransferStatus = FileTransferStatus.idle;
  public transmittedSize: number = 0;
  private _id : string;
  get id() : Readonly<string> {
    return this._id;
  }

  constructor(
    ownerId: string,
    filename: string,
    fileSize: number = 0,
    fileBlob: File
  ) {
    this._id = UUID.UUID();
    if (fileBlob) {
      fileBlob['uuid'] = this._id;
      this.fileBlob = fileBlob;
    }
    this.ownerId = ownerId;
    this.filename = filename;
    this.fileSize = fileSize;
  }

  static fromExisting(fileBlob: File, ownerId: string, uuid?: string) {
    let newSharedFile = new SharedFile(ownerId, fileBlob.name, fileBlob.size, null);
    newSharedFile.fileBlob = fileBlob;
    newSharedFile.setId(uuid);
    return newSharedFile;
  }

  static toReceive(ownerId: string, filename: string, fileSize: number, uuid: string) {
    let newSharedFile = new SharedFile(ownerId, filename, fileSize, null)
    newSharedFile.setId(uuid);
    return newSharedFile;
  }

  setId(uuid: string) {
    this._id = uuid;
  }

  toRef(): ISharedFileRef {
    return {
      id: this._id,
      ownerId: this.ownerId,
      filename: this.filename,
      fileSize: this.fileSize
    } as ISharedFileRef;
  }
}
