/**
 * Copyright Compunetix Incorporated 2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { LocalizationService } from "./../../localization/localization.service";
import { Browser  } from "companion";

@Component({
  selector: "side-panel",
  templateUrl: "./side-panel.component.html",
})
/**
 * side-panel layout
 */
export class SidePanelComponent {
  /**
   * if this is mobile app
   */
  @Input() isMobileApp: boolean;
  /**
   * the title of this panel
   */
  @Input() title: string;

  /**
   * the position of this panel
   */
  @Input() position: string;

  /**
   * the flag to determine if to show the close button
   */
  @Input() canClose: boolean;

  /**
   * close panel event emitter
   */
  @Output("close") closeEmitter: EventEmitter<void> = new EventEmitter<void>();

  /**
   * localization data
   */
  sidePanelLocalization: any;
  constructor(public localizationService: LocalizationService) {
    this.sidePanelLocalization = this.localizationService.getValueByPath(".side_panel") || {};
  }

  /**
   * event handler for user click close button
   */
  closePanel() {
    this.closeEmitter.emit();
  }
}
