/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  jporter
 */
import { Component, Output, Input, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import {
  ILocalization,
} from "companion";
import { NgForm } from "@angular/forms";

@Component({
  selector: "passcode-form",
  templateUrl: "./passcode-form.template.html",
})
export class PasscodeFormComponent {
  @Input("localizationData")
  localizationData: ILocalization;

  /**
   * flag if form is submitted
   */
  submitted: boolean = false;
  
  /**
   * submit event trigger
   */
  @Output("validate") validationEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Input("styleData") styleData: any
  
  @ViewChild("passcode") passcodeField: ElementRef;
  
  @ViewChild("PasscodeForm") passcodeForm: NgForm;

  protected dataModel: {passcode:string} = {passcode:""};

  /**
   * component constructor
   */
  constructor(
  ) {
    
  }

  /**
   * submit event handler
   */
  onSubmit(): void {
    if(this.submitted)
    {
      // prevent double submit
      return;
    }
    this.validationEmitter.emit(this.dataModel.passcode);
    this.submitted = true;
  }
  
  /**
   * mouseover event handler on submit button
   * Use this to change style on mouse over.
   */
  onMouseEnter(e: any) {
    let target: HTMLElement = e.target;
    target.style.backgroundColor = this.styleData.submitBgColor;
  }

  /**
   * mouseleave event handler on submit button
   * Use this to reset the style on mouse out.
   */
  onMouseLeave(e: any) {
    let target: HTMLElement = e.target;
    target.style.backgroundColor = this.styleData.submitBgColor;
  }

  /**
   * reset the form.
   */
  resetForm() {
    this.passcodeForm.form.markAsPristine();
    this.dataModel.passcode = "";
    this.submitted = false;
  }
}
