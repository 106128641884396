/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

/**
 * volume meter util
 */
export class VolumeMeter {
  /**
   * the current drawing color
   */
  private color: string;

  /**
   * flag if it's speaking
   */
  private isSpeaking: boolean;

  /**
   * flag if it's too load over threshold
   */
  private isTooLoud: boolean;

  /**
   * volume objects
   */
  private volumes: VolumeMeterObject[];

  /**
   * flag if it's muted which is no volume
   */
  private isMuted: boolean;

  /**
   * meter animation
   */
  private meterAnimation: any;

  /**
   * audio analyzer
   */
  private audioAnalyzer: any;

  /**
   * reset current volume meter view
   */
  constructor(
    public id: string,
    public stream: any,
    public f: number = 20,
    public threshold: number = -30,
    public interval: number = 250
  ) {
    // do nothing
  }

  /**
   * volumes array reload
   */
  private volumesArrayReload() {
    this.volumes = [];
    for (var i = 0; i < this.f; ++i) {
      this.volumes.push(new VolumeMeterObject(0, "black"));
    }
  }

  /**
   * meter render
   */
  render() {
    if (!this.stream) {
      return;
    }
    this.isMuted = true;
    this.isTooLoud = false;
    this.isSpeaking = false;
    this.color = "black";
    // init volumes array
    this.clean();
    // listen on the stream
    this.audioAnalyzer = hark(this.stream, { interval: this.interval, threshold: this.threshold });
    // set event handlers
    this.audioAnalyzer.on("speaking", this.onSpeaking.bind(this));
    this.audioAnalyzer.on("volume_change", this.onVolumeChange.bind(this));
    this.audioAnalyzer.on("stopped_speaking", this.onStoppedSpeaking.bind(this));
  }

  /**
   * start animation
   */
  start() {
    this.meterAnimation = window.requestAnimationFrame(this.draw.bind(this));
  }

  /**
   * meter animation stop
   */
  stop() {
    if (this.meterAnimation) {
      window.cancelAnimationFrame(this.meterAnimation);
      this.meterAnimation = null;
    }
    if (this.audioAnalyzer) {
      this.audioAnalyzer.stop();
    }
    this.clean();
  }

  /**
   * meter animation restart
   */
  restart() {
    this.stop();
    this.meterAnimation = window.requestAnimationFrame(this.draw.bind(this));
  }

  /**
   * clean meter canvas and volume data list
   */
  private clean() {
    let canvas: any = document.getElementById(this.id);
    if (!canvas) {
      return;
    }
    var drawContext = canvas.getContext("2d");
    drawContext.clearRect(0, 0, canvas.width, canvas.height);
    this.volumesArrayReload();
  }

  /**
   * handler on speaking event
   */
  private onSpeaking() {
    this.isSpeaking = true;
  }

  /**
   * handler on volume change event
   * @param volume: number - current volume
   * @param threshold: number - the threshold number current volume meter configured
   */
  private onVolumeChange(volume: number, threshold: number) {
    if (volume > threshold) {
      this.isTooLoud = true;
    } else {
      this.isTooLoud = false;
    }

    if (volume > -100) {
      this.isMuted = false;
    } else {
      this.isMuted = true;
    }
    let color = this.updateColor();
    this.volumes.push(new VolumeMeterObject(volume, color));
    if (this.volumes.length > this.f) {
      this.volumes.shift();
    }
  }

  /**
   * handler on stop speaking event
   */
  private onStoppedSpeaking() {
    this.isSpeaking = false;
  }

  /**
   * draw rect bar for the data
   * @param canvas: any - the canvas dom element
   * @param data: VolumeMeterObject[] - the volumes to be drawn
   */
  private drawRect(canvas: any, data: VolumeMeterObject[]) {
    var drawContext = canvas.getContext("2d");
    for (var i = 0; i < data.length; i++) {
      drawContext.fillStyle = data[i].color;
      if (data[i].volume) {
        // calculate x, y, width, height of each volume meter bar
        // based on volume value, canvas size and display density
        var value = Math.max(0, (data[i].volume as number) + 100);
        var percent = value / 100;
        var width = canvas.width / this.f - 2;
        var height = percent === 0 ? 10 : canvas.height * percent;
        var y = (canvas.height - height) / 2;
        var x = (i * canvas.width) / this.f + 1;
        drawContext.fillRect(x, y, width, height);
      }
    }
  }

  /**
   * draw volume meter
   */
  private draw() {
    let canvas: any = document.getElementById(this.id);
    if (!canvas) {
      return;
    }
    var drawContext = canvas.getContext("2d");
    drawContext.clearRect(0, 0, canvas.width, canvas.height);
    this.drawRect(canvas, this.volumes);
    this.meterAnimation = window.requestAnimationFrame(this.draw.bind(this));
  }

  /**
   * update color of volume meter
   */
  private updateColor() {
    this.color = this.isMuted || this.isTooLoud ? "red" : "green";
    return this.color;
  }
}

/**
 * volume meter element structure
 */
class VolumeMeterObject {
  constructor(public volume: number, public color: string) {
    // nothing needed here
  }
}
