/**
 * Copyright Compunetix Incorporated 2017-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { Component, ViewChild, Output, EventEmitter, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FlavorService } from "./flavor.service";
import { Flavor } from "./flavor";
import { ILocalization, IFlavor, AlertLevel } from "companion";
import { AlertService } from "../../alert/alert.service";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { ActionType, Dispatcher } from "client/scripts/shared/services/dispatcher";
import { GroupManagementService } from "client/scripts/group-management/group-management.service";

@Component({
  selector: "flavor-panel",
  templateUrl: "./flavor-panel.template.html"
})

/**
 * flavor panel
 */
export class FlavorPanelComponent implements OnInit {
  /**
   * current flavor style
   */
  style: string;

  /**
   * all supporting languages for the style
   */
  languages: string[];

  /**
   * current selected language
   */
  selectedLanguage: string;

  /**
   * selected flavor
   */
  selectedFlavor: IFlavor;

  /**
   * current form for the selected flavor
   */
  currentForm: FormGroup;

  /**
   * language code
   */
  newLanguageCode: string;

  /**
   * flag show add new tab
   */
  showAddNewTab: boolean = false;

  /**
   * language select event emitter
   */
  @Output("languageSelected") languageSelectEmitter: EventEmitter<ILocalization> = new EventEmitter<ILocalization>();

  /**
   * language tabs
   */
  @ViewChild("languageTabsComponent") languageTabsComponent: TabsetComponent;

  /**
   * selct tab by index
   */
  selectTab(index: number) {
    if (!this.languageTabsComponent) {
      return;
    }
    this.languageTabsComponent.tabs[index].active = true;
  }

  /**
   * remove tab by index
   */
  removeTab(index: number): void {
    if (!this.languageTabsComponent) {
      return;
    }
    this.languageTabsComponent.tabs.splice(index, 1);
  }

  /**
   * tab selected
   */
  tabSelected(language: string) {
    if (this.selectedLanguage !== language) {
      this.selectLanguage(language);
    }
  }

  /**
   * add new language
   */
  addNewLanguage(languageCode: string) {
    this.languages.push(languageCode);
    this.showAddNewTab = false;
    this.removeTab(this.languages.length - 1);
    this.flavorService
    .saveFlavor(Flavor.getDefaultFlavor(this.style, languageCode, "unattended", _.values(this.groupManagementService.allAccessibleGroupsDictionary)).convertToLocalization())
    .then((message: string) => {
      this.selectTab(this.languages.length - 1);
      this.selectLanguage(this.languages[this.languages.length - 1]);
      this.newLanguageCode = "";
      this.showAddNewTab = true;
    })
    .catch((err: Error) => {
      this.alertService.createAlert("FLAVOR_SAVE_FAIL", err.message, AlertLevel.error);
    });
  }

  /**
   * select language
   */
  selectLanguage(language: string) {
    this.selectedLanguage = language;
    if (this.selectedLanguage) {
      this.flavorService
      .getLocalizationDataByStyleAndLanguage(this.style, this.selectedLanguage)
      .then((lData: ILocalization) => {
        this.selectedFlavor = this.flavorService.convertLocalizationToFlavor(lData, _.values(this.groupManagementService.allAccessibleGroupsDictionary));
        this.currentForm = this.selectedFlavor.toFormGroup();
        this.languageSelectEmitter.emit(lData);
      })
      .catch((err: Error) => {
        this.alertService.createAlert("FLAVOR_GET_FAIL", err.message, AlertLevel.prominent);
      });
    }
  }

  // UPDATE THE MODE?

  /**
   * update component with style
   */
  updateStyle(style: string) {
    this.style = style;
    if (this.languageTabsComponent) {
      this.languageTabsComponent.tabs = [];
      this.languages = [];
      this.showAddNewTab = false;
    }
    this.flavorService
    .getLanguagesByStyle(style)
    .then((languages: string[]) => {
      if (_.includes(languages, "en")) {
        languages.splice(languages.indexOf("en"), 1);
        languages.unshift("en");
      }
      this.languages = languages;
      if (languages.length === 0) {
        this.addNewLanguage("en");
      } else {
        this.selectLanguage(this.languages[0]);
        this.showAddNewTab = true;
      }
    })
    .catch((err: Error) => {
      this.alertService.createAlert("LANGUAGE_GET_FAIL", err.message, AlertLevel.warning);
    });
  }

  /**
   * delete flavor
   */
  delete() {
    AlertService.createAlertWithButtons(
      "Are you sure that you want to permanently delete this style-language of UI customization?",
      {
        confirm: {
          label: "Confirm to delete",
          className: "btn-success"
        },
        cancel: {
          label: "Cancel",
          className: "btn-danger"
        }
      }
    ).then((result: boolean) => {
      if (result) {
        this.alertService.createAlert("FLAVOR_DELETING", "Deleting...", AlertLevel.success);
        this.flavorService
        .deleteFlavor(this.selectedFlavor.style, this.selectedFlavor.language)
        .then((message: string) => {
          this.alertService.createAlert(
            "FLAVOR_DELETED",
            "Style(" +
              this.selectedFlavor.style +
              ")-Language(" +
              this.selectedFlavor.language +
              ") is Deleted Successfully!",
            AlertLevel.success
          );
          this.removeTab(this.languages.indexOf(this.selectedFlavor.language));
          this.languages.splice(this.languages.indexOf(this.selectedFlavor.language), 1);
          this.selectTab(0);
          this.selectLanguage(this.languages[0]);
        })
        .catch((err: Error) => {
          this.alertService.createAlert("FLAVOR_DELETE_FAIL", err.message, AlertLevel.warning);
        });
      }
    });
  }

  /**
   * flavor save
   */
  save() {
    this.alertService.createAlert("FLAVOR_UPDATING", "Updating...", AlertLevel.success);
    this.selectedFlavor.updateFlavorByForm(this.currentForm);
    this.flavorService
    .saveFlavor(this.selectedFlavor.convertToLocalization())
    .then((message: string) => {
      this.alertService.createAlert(
        "FLAVOR_UPDATED",
        "Style(" +
          this.selectedFlavor.style +
          ")-Language(" +
          this.selectedFlavor.language +
          ") is Updated Successfully!",
        AlertLevel.success
      );
      this.selectLanguage(this.selectedFlavor.language);
    })
    .catch((err: Error) => {
      this.alertService.createAlert("FLAVOR_SAVE_FAIL", err.message, AlertLevel.error);
    });
  }

  constructor(private flavorService: FlavorService, private alertService: AlertService, private groupManagementService: GroupManagementService) {
  }


  /**
   * update form with imported file
   */
  import(event: any) {
    var localizationData = JSON.parse(event.target.result);
    let uploadedFlavor = this.flavorService.convertLocalizationToFlavor(localizationData, _.values(this.groupManagementService.allAccessibleGroupsDictionary));
    this.currentForm = uploadedFlavor.toFormGroup();
  }

  /**
   * export current view data
   */
  export() {
    let filename: string = this.selectedFlavor.style + "_" + this.selectedFlavor.language + ".json";
    let blob: Blob = new Blob([JSON.stringify(this.selectedFlavor.convertToLocalization())], {
      type: "text/plain;charset=utf-8"
    });
    saveAs(blob, filename);
  }

  cancel() {
    this.updateStyle(this.style);
  }

  /**
   * a value of the theme form has changed
   */
  valueChanged(event) {
    // if we change the mode, dynamically update the form to display relevant sections
    if (event === "mode_mode") {
      this.selectedFlavor.updateFlavorByForm(this.currentForm);
      // we need to to this to update the form parameters
      this.selectedFlavor =
        this.flavorService.convertLocalizationToFlavor(this.selectedFlavor.convertToLocalization(), 
        _.values(this.groupManagementService.allAccessibleGroupsDictionary));
      this.currentForm = this.selectedFlavor.toFormGroup();
    }
  }

  ngOnInit() {
    Dispatcher.dispatch(ActionType.LoadGroupData); // make sure we get the group data.
  }
}
