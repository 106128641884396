/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { TopBarModule } from "./top-bar/top-bar.module";
import { NavBarModule } from "./nav-bar/top-nav.module";
import { SideBarModule } from "./side-bar/side-bar.module";
import { MainContainerModule } from "./main-container/main-container.module";
import { DialogContainerModule } from "./dialog-container/dialog-container.module";
import { FooterModule } from "./footer/footer.module";
import { ListModule } from "./list/list.module";
import { LayoutService } from "./layout.service";
import { InPagePanelModule } from "./in-page-panel/in-page-panel.module";
import { PDFViewerModule } from "./pdf-viewer/pdf-viewer.module";
import { PageTitleModule } from "./page-title/page-title.module";
import { SidePanelModule } from "./side-panel/side-panel.module";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  imports: [
    BrowserModule,
    TopBarModule,
    NavBarModule,
    SideBarModule,
    MainContainerModule,
    DialogContainerModule,
    FooterModule,
    ListModule,
    InPagePanelModule,
    PDFViewerModule,
    PageTitleModule,
    SidePanelModule,
    SharedModule
  ],
  exports: [
    TopBarModule,
    NavBarModule,
    SideBarModule,
    MainContainerModule,
    DialogContainerModule,
    FooterModule,
    ListModule,
    InPagePanelModule,
    PDFViewerModule,
    PageTitleModule,
    SidePanelModule
  ],
  providers: [LayoutService]
})
export class LayoutModule {}
