<div class="modal fade" id="TransferQueueModal" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" *ngIf="!confirmationShown">
            <div *ngIf="skillSet?.category && skillSet?.language">
                <div *ngIf="!currentModalLanguage" class="modal-body bg-white row">
                    Select Transfer Language
                </div>
                <div *ngIf="!currentModalLanguage" class="modal-body bg-white row">
                    <ng-container *ngFor="let language of skillSet.language"
                    >    
                        <button [ngClass]="'col text-left btn btn-outline-primary btn-control wrapOrHide w-100'"
                        (click)="setModalLanguage(language)"
                        >
                        {{ language }}
                        </button>
                    </ng-container>
                </div>
                <div *ngIf="currentModalLanguage" class="modal-body bg-white row">
                    Select Transfer Category
                </div>
                <div *ngIf="currentModalLanguage" class="modal-body bg-white row">
                    <ng-container *ngFor="let category of skillSet.category; index as i"
                    >    
                        <button [ngClass]="'col-3 text-left btn btn-outline-primary btn-control wrapOrHide w-100'"
                        [disabled]="isOperatorAvailable(category, currentModalLanguage) ? null : true"
                        (click)="setModalCategory(category)"
                        [title]="getDataForCategoryAndLanguage(category, currentModalLanguage)"
                        >
                            {{ category }}
                        </button>
                        <div *ngIf="(i+1) % 4 == 0" class="d-block w-100"><span>  </span></div>
                    </ng-container>
                </div>
            </div>
            <div class="modal-body bg-white row">
                <!-- Back BUTTON -->
                <button *ngIf="currentModalLanguage" tabindex [title]="'Back'" class="btn btn-outline-primary btn-control wrapOrHide"
                    (click)='resetModalSelections()'>
                    Back
                </button>
                <!-- CANCEL BUTTON -->
                <button tabindex [title]="'Cancel'" class="btn btn-outline-primary btn-control wrapOrHide"
                    (click)='cancelTransfer()'>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
