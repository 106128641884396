/**
 * Copyright Compunetix Incorporated 2017-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { toUpper } from "lodash";

/**
 * Stats util
 */
export class StatsUtil {

  static getPCStats(pc: any, lastStats?: IPCStats): Promise<any> {
    return new Promise((resolve: (result: any) => void, reject: (err: Error) => void) => {
      pc.getStats()
      .then((stats: any) => {
        let result: IPCStats = {};
        result.timestamp = new Date();
        stats.forEach((stat: any, statKey: string) => {
          switch (stat.type) {
            case "inbound-rtp":
              result.receivers = result.receivers || [];
              let receiverToUpdate: IRtpStats = {};
              receiverToUpdate.id = stat.id;
              receiverToUpdate.ssrc = stat.ssrc;
              receiverToUpdate.mediaType = stat.mediaType;
              receiverToUpdate.trackId = stat.trackId;
              let receiverMediaTrack = stats.get(stat.trackId);
              if (receiverMediaTrack && receiverMediaTrack.audioLevel) {
                receiverToUpdate.audioLevel = receiverMediaTrack.audioLevel;
              }
              receiverToUpdate.codecId = stat.codecId;
              let receiverCodec = stats.get(stat.codecId);
              if (receiverCodec) {
                receiverToUpdate.codec = receiverCodec.mimeType;
              }
              receiverToUpdate.transportId = stat.transportId;
              let receiverTransport = stats.get(stat.transportId);
              if (receiverTransport) {
                let receiverSelectedCandidatePair = stats.get(receiverTransport.selectedCandidatePairId);
                if (receiverSelectedCandidatePair) {
                  receiverToUpdate.selectedCandidatePairId = receiverTransport.selectedCandidatePairId;
                  let receiverLocalCandidate = stats.get(receiverSelectedCandidatePair.localCandidateId);
                  if (receiverLocalCandidate) {
                    receiverToUpdate.localCandidateId = receiverSelectedCandidatePair.localCandidateId;
                    receiverToUpdate.localCandidateIP = receiverLocalCandidate.ip;
                    receiverToUpdate.localCandidateProtocol = receiverLocalCandidate.protocol;
                    receiverToUpdate.localCandidateType = receiverLocalCandidate.candidateType;
                  }
                  let receiverRemoteCandidate = stats.get(receiverSelectedCandidatePair.remoteCandidateId);
                  if (receiverRemoteCandidate) {
                    receiverToUpdate.localCandidateId = receiverSelectedCandidatePair.remoteCandidateId;
                    receiverToUpdate.remoteCandidateIP = receiverRemoteCandidate.ip;
                    receiverToUpdate.remoteCandidateProtocol = receiverRemoteCandidate.protocol;
                    receiverToUpdate.remoteCandidateType = receiverRemoteCandidate.candidateType;
                  }
                }
              }
              if (stat.packetsLost) {
                receiverToUpdate.packetsLost = stat.packetsLost;
              } else {
                if (stat.remoteId) {
                  let receiverRemoteRtp = stats.get(stat.remoteId);
                  if (receiverRemoteRtp) {
                    receiverToUpdate.packetsLost = receiverRemoteRtp.packetsLost;
                  }
                }
              }
              receiverToUpdate.packetsTransmitted = stat.packetsReceived;
              receiverToUpdate.bytesReceived = stat.bytesReceived;
              if (lastStats) {
                let matchedReceiver = _.find(lastStats.receivers, (receiver: IRtpStats) => receiver.id === receiverToUpdate.id);
                if (matchedReceiver) {
                  receiverToUpdate.bitrate =
                    (receiverToUpdate.bytesReceived - matchedReceiver.bytesReceived) * 8 /
                    (result.timestamp.getTime() - lastStats.timestamp.getTime()) * 1000;
                }
              }
              receiverToUpdate.frameRate = stat.framesPerSecond;
              if (stat.frameWidth && stat.frameHeight) {
                receiverToUpdate.frameSize = `${stat.frameWidth}x${stat.frameHeight}`;
              }
              receiverToUpdate.firReceived = stat.firCount;
              result.receivers.push(receiverToUpdate);
              break;
            case "outbound-rtp":
              result.senders = result.senders || [];
              let senderToUpdate: IRtpStats = {};
              senderToUpdate.id = stat.id;
              senderToUpdate.ssrc = stat.ssrc;
              senderToUpdate.mediaType = stat.mediaType;
              senderToUpdate.mediaSourceId = stat.mediaSourceId;
              let senderMediaSource = stats.get(stat.mediaSourceId);
              if (senderMediaSource && senderMediaSource.audioLevel) {
                senderToUpdate.audioLevel = senderMediaSource.audioLevel;
              }
              senderToUpdate.codecId = stat.codecId;
              let senderCodec = stats.get(stat.codecId);
              if (senderCodec) {
                senderToUpdate.codec = senderCodec.mimeType;
              }
              senderToUpdate.transportId = stat.transportId;
              let senderTransport = stats.get(stat.transportId);
              if (senderTransport) {
                let senderSelectedCandidatePair = stats.get(senderTransport.selectedCandidatePairId);
                senderToUpdate.selectedCandidatePairId = senderTransport.selectedCandidatePairId;
                if (senderSelectedCandidatePair) {
                  let senderLocalCandidate = stats.get(senderSelectedCandidatePair.localCandidateId);
                  if (senderLocalCandidate) {
                    senderToUpdate.localCandidateId = senderSelectedCandidatePair.localCandidateId;
                    senderToUpdate.localCandidateIP = senderLocalCandidate.ip;
                    senderToUpdate.localCandidateProtocol = senderLocalCandidate.protocol;
                    senderToUpdate.localCandidateType = senderLocalCandidate.candidateType;
                  }
                  let senderRemoteCandidate = stats.get(senderSelectedCandidatePair.remoteCandidateId);
                  if (senderRemoteCandidate) {
                    senderToUpdate.remoteCandidateId = senderSelectedCandidatePair.remoteCandidateId;
                    senderToUpdate.remoteCandidateIP = senderRemoteCandidate.ip;
                    senderToUpdate.remoteCandidateProtocol = senderRemoteCandidate.protocol;
                    senderToUpdate.remoteCandidateType = senderRemoteCandidate.candidateType;
                  }
                }
              }
              if (stat.packetsLost) {
                senderToUpdate.packetsLost = stat.packetsLost;
              } else {
                if (stat.remoteId) {
                  let senderRemoteRtp = stats.get(stat.remoteId);
                  if (senderRemoteRtp) {
                    senderToUpdate.packetsLost = senderRemoteRtp.packetsLost;
                  }
                }
              }
              senderToUpdate.packetsTransmitted = stat.packetsSent;
              senderToUpdate.bytesSent = stat.bytesSent;
              if (lastStats) {
                let matchedSender = _.find(lastStats.senders, (sender: IRtpStats) => sender.id === senderToUpdate.id);
                if (matchedSender) {
                  senderToUpdate.bitrate =
                    (senderToUpdate.bytesSent - matchedSender.bytesSent) * 8 /
                    (result.timestamp.getTime() - lastStats.timestamp.getTime()) * 1000;
                }
              }
              senderToUpdate.frameRate = stat.framesPerSecond;
              if (stat.frameWidth && stat.frameHeight) {
                senderToUpdate.frameSize = `${stat.frameWidth}x${stat.frameHeight}`;
              }
              senderToUpdate.firReceived = stat.firCount;
              result.senders.push(senderToUpdate);
              break;
          }
        });
        resolve(result);
      })
      .catch((err: Error) => {
        reject(err);
      });
    });
  }

  /**
   * parse the stats to get packet loss rate
   * @param stats: IPCStats - the original stats
   * @param mediaType: string - the media type to filter (audio | video)
   * @param direction: string - the stream direction (send | recv)
   */
  static getPacketLossRate(stats: IPCStats, mediaType: string, direction: string): number {
    let result: number = 0;
    if (stats) {
      let matchedSSRC: IRtpStats;
      if (direction === "send" && stats.senders) {
        matchedSSRC = _.find(stats.receivers, (receiver: IRtpStats) => {
          return receiver.mediaType === mediaType;
        });
      } else if (direction === "recv" && stats.receivers) {
        matchedSSRC = _.find(stats.senders, (sender: IRtpStats) => {
          return sender.mediaType === mediaType;
        });
      }
      if (matchedSSRC && matchedSSRC.packetsTransmitted && matchedSSRC.packetsLost) {
        result = matchedSSRC.packetsLost / matchedSSRC.packetsTransmitted;
      }
    }
    return result;
  }

  static htmlQualityData(quality: {
    mos?: any,
    issues?: any,
  }) {
    let output = "";
    if (quality) {
      if (quality?.mos) {
        _.forEach(quality?.mos, (scores) => {
          output += "<div>"
          if (scores.inbound) {
            output += "<div>Inbound Score: " + parseFloat(scores.inbound.toString()).toFixed(2);
            if (scores.statsSamples?.inboundStatsSample) {
              output += " - Interval Stats [";
              output += "Lost: " + (scores.statsSamples.inboundStatsSample?.packetsLoss || 0) + '%';
              output += ", RTT: " + (parseFloat(scores.statsSamples.inboundStatsSample?.rtt || 0).toFixed(2)) + "ms";
              output += ", Jitter: " + (parseFloat(scores.statsSamples.inboundStatsSample?.jitter || 0).toFixed(2)) + "ms";
              output += "]";
            }
            output += "</div>"
          }
          if (scores.outbound) {
            output += "<div>Outbound Score: " + parseFloat(scores.outbound.toString()).toFixed(2);
            if (scores.statsSamples?.outboundStatsSample) {
              output += " - Interval Stats [";
              output += "Lost: " + (scores.statsSamples.outboundStatsSample?.packetsLoss || 0) + '%';
              output += ", RTT: " + (parseFloat(scores.statsSamples.outboundStatsSample?.rtt || 0).toFixed(2)) + "ms";
              output += ", Jitter: " + (parseFloat(scores.statsSamples.outboundStatsSample?.jitter || 0).toFixed(2)) + "ms"
              output += "]";
            }
            output += "</div>";
          }
          output += "</div>"
        })
      }
      if (quality?.issues) {
        _.forEach(quality?.issues, (issue) => {
          output += "<div>"
          output += "**"+ toUpper(issue.type) + " ISSUE** - " + issue.reason.toString().replaceAll("-", ' ');
          output += "</div>"
        })
      }
    }
    
    return output;
  }

  static quadBracketRedGreenGaugeOptions() {
      return {
        maxValue: 100,
        angle: 0, // The span of the gauge arc
        lineWidth: 0.6, // The line thickness
        radiusScale: 0.9, // Relative radius
        pointer: {
          length: 0.5, // // Relative to gauge radius
          strokeWidth: 0.1, // The thickness
          color: '#000000' // Fill color
        },
        limitMax: true,     // If false, max value increases automatically if value > maxValue
        limitMin: true,     // If true, the min value of the gauge will be fixed
        colorStart: '#000000',   // Colors
        colorStop: '#FFFFFF',    // just experiment with them
        strokeColor: '#EEEEEE',  // to see which ones work best for you
        generateGradient: true,
        highDpiSupport: true,     // High resolution support
        // renderTicks is Optional
        // renderTicks: {
        //   divisions: 0,
        //   divWidth: 0.1,
        //   divLength: 0.41,
        //   divColor: '#333333',
        //   subDivisions: 0,
        //   subLength: 0.14,
        //   subWidth: 3.1,
        //   subColor: '#ffffff'
        // },
        staticZones: [
         //{strokeStyle: "#000000", min: 0, max: 20}, // black
         {strokeStyle: "#FF0000", min: 0, max: 25}, // red 
         {strokeStyle: "#FF8C00", min: 25, max: 50}, // orange
         {strokeStyle: "#FFDD00", min: 50, max: 75}, // Yellow
         {strokeStyle: "#30B32D", min: 75, max: 100}, // Green
        ],/*
        staticLabels: {
        font: "6px sans-serif",  // Specifies font
        labels: [00, 20, 40, 60, 80, 100],  // Print labels at these values
        color: "#000000",  // Optional: Label text color
        fractionDigits: 0  // Optional: Numerical precision. 0=round off.
      },*/
        
      };
    }
  

  static singleZoneGradientGaugeOptions(options: {pointerColor?:string, inactiveColor?:string, topColor?:string, bottomColor?:string}) {
    let {pointerColor, inactiveColor, topColor, bottomColor} = options;
    return {
      maxValue: 100,
      angle: .25,
      lineWidth: 0.40,
      pointer: {
        length: 0.75,
        strokeWidth: 0.08,
        color: pointerColor || '#000000'
      },
      limitMax: true, 
      limitMin: true,
      strokeColor: inactiveColor || '#E0E0E0',
      colorStart: bottomColor || '#FF00FF',
      colorStop: topColor || '#00FFFF',
      generateGradient: true
    };
  }

  static get callQualityGaugeOptions() {
    return this.quadBracketRedGreenGaugeOptions();
  }

  static get callQualitySingleGaugeOptions() {
    return this.singleZoneGradientGaugeOptions({inactiveColor: '#afe1af', topColor: '#05f51d', bottomColor: '#50c878'});
  }

  static toMosGaugeValue(value, max) {
    return StatsUtil.toMosFilteredValue(value) * (max / 5); // Score 0-5 so multiply by factor for max value
  }

  static toMosFilteredValue(value) {
    return value || 2.5;
  }
}

export interface IPCStats {
  timestamp?: Date;
  senders?: IRtpStats[];
  receivers?: IRtpStats[];
}

export interface ICallQuality {
  mos?: any,
  issues?: any,
}

export interface IRtpStats {
  id?: string;
  mediaSourceId?: string;
  trackId?: string;
  codecId?: string;
  transportId?: string;
  ssrc?: string;
  mediaType?: string;
  codec?: string;
  packetsLost?: number;
  packetsTransmitted?: number;
  bitrate?: number;
  bytesSent?: number;
  bytesReceived?: number;
  frameSize?: string;
  frameRate?: number;
  InputRate?: number;
  outputRate?: number;
  delayMs?: number;
  firReceived?: number;
  audioLevel?: number;
  selectedCandidatePairId?: string;
  localCandidateId?: string;
  localCandidateType?: string;
  localCandidateIP?: string;
  localCandidateProtocol?: string;
  remoteCandidateId?: string;
  remoteCandidateType?: string;
  remoteCandidateIP?: string;
  remoteCandidateProtocol?: string;
}
