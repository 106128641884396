/**
 * Copyright Compunetix Incorporated 2016-2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NavBarMenuItemKey } from "client/scripts/layout/nav-bar/nav-bar.service";
import { ActionType, Dispatcher } from "client/scripts/shared/services/dispatcher";
import {
  Companion,
  IChatRoom,
  IChatRoomService,
  IMessage,
  IEndpoint,
  IEndpointService,
  IConferenceExpanded,
  IEndpointRef,
} from "companion";
import { LocalizationService } from "../../localization/localization.service";
@Component({
  selector: "chatroom",
  templateUrl: "./chatroom.template.html"
})
/**
 * Chat room component
 */
export class ChatRoomComponent {
  @Input() chatBoxShown: boolean;
  @Input() conference: IConferenceExpanded
  @Input() listTypes: string[];
  @Output("toggleMenuPanel") toggleMenuPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private endpointService: IEndpointService = Companion.getEndpointService();
  public chatRoomService: IChatRoomService = Companion.getChatRoomService();

  /**
   * check if theme allow user to modify chat room participants
   * if theme enabled, user can modify chat room participants
   * if not, user can only reply message to the existing chat rooms
   */
get canModifyChatRoomParticipants(): boolean {
    return false; // Always disable the custom chat creation (see RM6687, system manage chat via other UI elements.)
    let result = false;
    if (
      this.localizationService.myLocalizationData.chat_panel &&
      this.localizationService.myLocalizationData.toolbar.toolbar_items.chat
    ) {
      result = true;
      if (
        this.localizationService.myLocalizationData.toolbar.toolbar_items.chat.hostOnly &&
        !Companion.getEndpointService().myEndpoint.isHost
      ) {
        result = false;
      } else if (
        this.localizationService.myLocalizationData.toolbar.toolbar_items.chat.guestOnly &&
        Companion.getEndpointService().myEndpoint.isHost
      ) {
        result = false;
      }
      if (this.localizationService.myLocalizationData.chat_panel.disableChatWithWebUser) {
        result = false;
      }
    }
    return result;
  }

  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean;

  constructor(public localizationService: LocalizationService) {
    Dispatcher.register(ActionType.ResetUnreadMessageCountOfCurrentRoom, this.resetUnreadMessageCountOfCurrentRoom.bind(this));
  }

  /**
   * open chat box and reset unread count
   */
  openChatBox() {
    if (this.chatRoomService.rooms.length === 1) {
      this.enter(this.chatRoomService.rooms[0]);
      this.resetUnreadMessageCountOfCurrentRoom();
    } else {
      this.backToRoomList();
    }
    Dispatcher.dispatch(ActionType.ResetMessageHistoryScrollBar);
  }

  /**
   * file receive handler
   * @param senderId: string - rtcId of the sender
   * @param blob: any - the file content in a blob
   * @param filename: string - the filename of the received file
   */
  blobAcceptor(senderId: string, blob: any, filename: string) {
    var roomsContainSender = _.filter(this.chatRoomService.rooms, (room: IChatRoom) => {
      return _.some(room.targets, (endpoint: IEndpointRef) => {
        return endpoint.rtcId === senderId;
      });
    });
    var roomsContainFileName = _.filter(roomsContainSender, (room: IChatRoom) => {
      var lastFiveMessages = _.orderBy(room.messages, ["sendDate"], ["desc"]).slice(0, 5);
      return _.some(lastFiveMessages, (message: IMessage) => {
        return message.content.indexOf(filename) > -1;
      });
    });
    for (var i = 0; i < roomsContainFileName.length; i++) {
      var lastFiveMessages = _.orderBy(roomsContainFileName[i].messages, ["sendDate"], ["desc"]).slice(0, 5);
      var messagesContainFileName: IMessage[] = _.filter(lastFiveMessages, (message: IMessage) => {
        return message.content.indexOf(filename) > -1;
      });
      for (var j = 0; j < messagesContainFileName.length; j++) {
        messagesContainFileName[j].confirmAction = () => {
          easyrtc_ft.saveAs(blob, filename);
        };
      }
    }
  }

  /**
   * file receiving accept or reject checker
   * @param senderId: string - rtcId of the sender
   * @param fileNameList: any - the files received
   * @param wasAccepted: any - the callback function to accept file
   */
  acceptRejectCB(senderId: string, fileNameList: Array<any>, wasAccepted: any) {
    wasAccepted(true);
  }

  /**
   * delete chat room
   * @param room: IChatRoom - the chat room to be deleted
   */
  delete(room: IChatRoom) {
    this.chatRoomService.delete(room);
    // Recount notification number.
    Dispatcher.dispatch(ActionType.ResetNavBarNotification, {
      key: NavBarMenuItemKey.Chat,
      value: this.chatRoomService.countTotalUnreadMessages(),
    });
  }

  /**
   * enter chat room
   * @param room: IChatRoom - the chat room to enter
   */
  enter(room: IChatRoom) {
    this.chatRoomService.addEndpointToRoom(this.endpointService.myEndpoint, room);
    this.chatRoomService.currentRoom = room;
    this.resetUnreadMessageCountOfCurrentRoom();
  }

  /**
   * return to room list view
   */
  backToRoomList() {
    this.chatRoomService.currentRoom = null;
  }

  /**
   * close the chat room panel
   * @param isCloseButton: boolean - flag if it's triggered by user clicking close button
   */
  closePanel(isCloseButton: boolean): void {
    Dispatcher.dispatch(ActionType.ToggleChatPanel, { forceClose: isCloseButton });
    Dispatcher.dispatch(ActionType.ToggleChatParticipantSelectList, { forceClose: true, targetId: "chatroom-window" });
  }

  toggleMenuPanel() {
    this.toggleMenuPanelEmitter.emit(true);
  }

  /**
   * create and join a new chat room with the endpoint
   * @param peerEndpoint: IEndpoint - the endpoint to chat with
   */
  createAndJoinPeerChatRoom(peerEndpoint: IEndpoint) {
     let existingRoom = this.chatRoomService.findExistingRoomByTargets([
      peerEndpoint
    ]);
    if (!existingRoom) {
      existingRoom = this.chatRoomService.createChatRoomByMessageTargets([peerEndpoint], this.endpointService.myEndpoint.rtcId);
    }
    this.enter(existingRoom);
  }

  /**
   * clear unread message counter of the room currently opening
   * @returns true is alert should be sent...
   */
  resetUnreadMessageCountOfCurrentRoom(): void {
    if (!this.chatRoomService.currentRoom) {
      return;
    }
    for (var i = 0; i < this.chatRoomService.rooms.length; i++) {
      if (this.chatRoomService.currentRoom.roomId === this.chatRoomService.rooms[i].roomId) {
        this.chatRoomService.resetUnreadOfChatRoom(this.chatRoomService.rooms[i]);
        Dispatcher.dispatch(ActionType.ResetNavBarNotification, {
          key: NavBarMenuItemKey.Chat,
          value: this.chatRoomService.countTotalUnreadMessages(),
          alert: false
        });
        break;
      }
    }
  }

  /**
   * open or close endpoint selection panel
   * @param room: IChatRoom - the room which endpoints belongs to
   */
  toggleEndpointSelectListPanel(room: IChatRoom): void {
    Dispatcher.dispatch(ActionType.ToggleChatParticipantSelectList, {room: room, targetId: "chatroom-window"});
  }
}
