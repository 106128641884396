<!--
    Copyright Compunetix Incorporated 2017-2022
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author: amaggi
  -->
<div class="cropper-container"  [ngStyle]="{'visibility': autoCrop ? 'hidden' : 'visible'}">
  <div class="cropper-body container">
    <div class="row">
      <div class="col-xs-8">
        <div class="cropper-source">
          <div class="photo-title">Photo Source ({{imageToBeSavedDimensions.width}}x{{imageToBeSavedDimensions.height}})</div>
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [imageFile]="imageToBeSaved"
            [maintainAspectRatio]="maintainAspectRatio"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            [containWithinAspectRatio]="false"
            [aspectRatio]=LEGAL_PAPER_ASPECT_RATIO
            [alignImage]="'center'"
            format="png"
            [cropper]="cropperPosition"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
          <div class="cropper-buttons">
            <div>
              <button class="btn btn-info" (click)="zoomOut()">Zoom -</button>
              <button class="btn btn-info mr-1" (click)="zoomIn()">Zoom +</button>
              <button class="btn btn-info" (click)="rotateLeft()">Rotate left</button>
              <button class="btn btn-info" (click)="rotateRight()">Rotate right</button>
              <input class="form-control rotation" [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()"/>
            </div>
            <div>
              <button class="btn btn-info" (click)="flipHorizontal()">Flip horizontal</button>
              <button class="btn btn-info mr-1" (click)="flipVertical()">Flip vertical</button>
              <button class="btn btn-info" (click)="toggleMaintainAspectRatio()" style="width: 114px">
                {{maintainAspectRatio?'Fixed Aspect Ratio':'Free Aspect Ratio'}}
              </button>
              <button class="btn btn-info" (click)="resetImage()">Reset image</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-4">
        <div class="">
          <div class="photo-title">Resulting Photo</div>
          <img class="cropper-result" [src]="sanitizedCroppedImage"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-8"></div>
      <div class="col-xs-4 text-right">
        <button class="btn btn-secondary close-button" (click)="closeCropper()">CANCEL</button>
        <button class="btn btn-primary save-button" (click)="saveImage()" [disabled]="croppedImageURL === ''">SAVE</button>
      </div>

    </div>
  </div>

</div>
