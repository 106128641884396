/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */

import { ISkillTags } from "../skills/skill.interface";

export interface ICallsStatistics {
  timestamp: Date;
  groupsData: ICallsGroup[];
}

export interface ICallsGroup {
  groupId: string;
  callsData: ICallsData[];
}

export enum AgentStatus {
  Conferencing,
  Available,
  Unavailable,
  Offline
}

export interface IAgent {
  id: string;
  avatarImageUrl: string;
  username: string;
  role: string;
  status: AgentStatus;
  statusStartedAt: number;
}

export interface IAgentsStatus {
  totalConferencing: number;
  totalAvailable: number;
  totalUnavailable: number;
  totalOffline: number;
}

export interface IAgentsCurrentStatus {
  agents: IAgent[];
  agentsStatus: IAgentsStatus;
}

export enum CustomerStatus {
  Conferencing,
  Available,
  InQueue,
  OnHold,
  Offline
}

export interface ICustomer {
  id: string;
  name: string;
  theme: string;
  latitude: number;
  longitude: number;
  avatarImageUrl: string;
  status: CustomerStatus;
  statusStartedAt: number;
}

export interface ICustomerStatus {
  totalConferencing: number;
  totalAvailable: number;
  totalInQueue: number;
  totalOnHold: number;
  inQueuePreviousHourPercentage: number;
  inQueuePreviousWeekPercentage: number;
}

export interface ICustomersCurrentStatus {
  customers: ICustomer[];
  customersStatus: ICustomerStatus;
}

export interface ICallsDailyStatus {
  daily: ICallsTotal;
  yesterday?: ICallsTotal;
  previousWeek?: ICallsTotal;
}

export interface ICallsTotal {
  waitTime: ICallsTime;
  talkTime: ICallsTime;
  holdTime: ICallsTime;
  inbound: number;
  outbound: number;
  answered: number;
  abandoned: number;
  rejected: number;
  transferred: number;
  transferReceived: number;
}

export interface ICallsTime {
  avg: number;
  max: number;
}

export interface ICallsCurrentStatus {
  realtimeStatistics: ICallsData;
  previousHourStatistics: ICallsData;
  previousWeekStatistics?: ICallsData;
}

export interface ICallsData {
  skillTags?: ISkillTags;
  available?: number;
  inQueue: number;
  inCallback?: number;
  inProgress: number;
  onHold: number;
}

export interface ITrendsData {
  dates: Date[];
  inboundCalls: number[];
  outboundCalls: number[];
  rejectedCalls: number[];
  abandonedCalls: number[];
  transferredCalls: number[];
  transferReceivedCalls: number[];
  handleTimesAvg: number[];
  handleTimesMax: number[];
  holdTimesAvg: number[];
  holdTimesMax: number[];
}
