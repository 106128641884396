import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { PDFDocumentProxy, PDFPageProxy } from "ng2-pdf-viewer";
import { concatAll } from "rxjs";

@Component({
    selector: 'thumbnail-component',
    templateUrl: './thumbnail.component.html',
    styleUrls: ['./thumbnail.style.css']
  })
export class ThumbnailComponent implements OnChanges, AfterViewInit {
    @Input() myPage !: number;
    @Input() parentPdf !: PDFDocumentProxy;
    @Input() thumbnailActive !: boolean;

    // render once only when needed 
    isRendered = false;

    @Output("clicked") clickedEmitter : EventEmitter<number> = new EventEmitter<number>();

    ngAfterViewInit() {
        if (this.thumbnailActive && this.parentPdf)
        {
            this.makeThumb();
        }
    }

    ngOnChanges(changes:SimpleChanges) {
        // if the parent PDF changed, mark rendered as false...
        if(changes.parentPdf)
        {
            this.isRendered = false;
        }

        if (this.thumbnailActive && this.parentPdf) {
            this.makeThumb();
        }
    }

    clickedMe(): void {
        this.clickedEmitter.emit(this.myPage);
    }

    makeThumb() : void {
        if(this.isRendered)
        {
            return;
        }

        let container = document.getElementById("thumb-container-"+this.myPage);
        if (container) {
            container.innerHTML = "";
            container.classList.add("thumb-container");
            this.parentPdf.getPage(this.myPage).then((page: PDFPageProxy) => {
                let vp = page.getViewport({scale: 1});
                let canvas = document.createElement("canvas");
                canvas.id = "thumnail-canvas"
                canvas.width = 280;
                canvas.height = 156;
                let scale = Math.min(canvas.width/vp.width, canvas.height/vp.height);
                return page.render({canvasContext: canvas.getContext("2d") as Object, viewport: page.getViewport({scale: scale})}).promise.then(() => {
                canvas.setAttribute("pdfPage", `${page.pageNumber}`);
                canvas.onclick = this.clickedMe.bind(this);
                container?.appendChild(canvas);
                return Promise.resolve(canvas);
                });
            });
            this.isRendered = true;
        }
    }
}