/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
export interface IAlert {
  alertCode: string;
  alertText?: string;
  alertLevel?: AlertLevel;
  alertCountdown?: number;
  alertStart?: Date;
  alertEnd?: Date;
}

export enum AlertLevel {
  log,
  success,
  info,
  warning,
  error,
  prominent
}

export enum AlertCode {
  getSettingsDataError = "GET_SETTINGS_DATA_ERROR",
  getSettingsDataSuccess = "GET_SETTINGS_DATA_SUCCESS",
  loginFailed = "LOGIN_FAILED",
  wait = "WAIT",
  getGeoLocationError = "GET_GEOLOCATION_ERROR",
  voiceServiceReady = "VOICE_SERVICE_READY",
  voiceServiceOffline = "VOICE_SERVICE_OFFLINE",
  voiceServiceError = "VOICE_SERVICE_ERROR",
  voiceServiceNotReady = "VOICE_SERVICE_NOT_READY",
  snapshotFailed = "SNAPSHOT_FAILED",
  snapshotSuccess = "SNAPSHOT_SUCCESS",
  snapshotProgressing = "SNAPSHOT_PROGRESSING",
  failToGainMediaAccess = "FAIL_TO_GAIN_MEDIA_ACCESS",
  callInvitationReceived = "CALL_INVITATION_RECEIVED",
  callInvitationRevoked = "CALL_INVITATION_REVOKED",
  sendLogsSuccess = "SEND_LOGS_SUCCESS",
  sendLogsError = "SEND_LOGS_ERROR",
  geoAccessSuccess = "GEO_ACCESS_SUCCESS",
  geoAccessFail = "GEO_ACCESS_FAIL",
  geoAccessNotPermit = "GEO_ACCESS_NOT_PERMIT",
  mapCenterUpdate = "MAP_CENTER_UPDATE",
  getLocalizationDataSuccess = "GET_LOCALIZATION_DATA_SUCCESS",
  getLocalizationDataFail = "GET_LOCALIZATION_DATA_FAIL",
  fileReceived = "FILE_RECEIVED",
  fileProgress = "FILE_PROGRESS",
  getDashboardFailed = "GET_DASHBOARD_FAILED",
  getServerTimeFailed = "GET_SERVER_TIME_FAILED",
  mediaStreamLoaded = "MEDIA_STREAM_LOADED",
  recordUploading = "RECORD_UPLOADING",
  recordUploadSuccess = "RECORD_UPLOAD_SUCCESS",
  recordUploadFail = "RECORD_UPLOAD_FAIL",
  recordFinished = "RECORD_FINISHED",
  recordFinishFail = "RECORD_FINISH_FAIL",
  getPresenceStatusByNameFailed = "GET_PRESENCE_STATUS_BY_NAME_FAILED",
  joinQueueFail = "JOIN_QUEUE_FAIL",
  serverConnected = "SERVER_CONNECTED",
  serverDisconnected = "SERVER_DISCONNECTED",
  lostServerConnection = "LOST_SERVER_CONNECTION",
  callFailed = "CALL_FAILED",
  callDisconnected = "CALL_DISCONNECTED",
  mediaConnectionLost = "MEDIA_CONNECTION_LOST",
  logAutomaticTrigger = "LOG_AUTOMATIC_TRIGGER",
  streamReceived = "STREAM_RECEIVED",
  streamClosed = "STREAM_CLOSED",
  serverFailedToConnect = "SERVER_FAILED_TO_CONNECT",
  connectionStateChange = "CONNECTION_STATE_CHANGE",
  iceStateChange = "ICE_STATE_CHANGE",
  addToConferenceSuccess = "ADD_TO_CONFERENCE_SUCCESS",
  addToConferenceFail = "ADD_TO_CONFERENCE_FAIL",
  disconnectFromConferenceSuccess = "DISCONNECT_FROM_CONFERENCE_SUCCESS",
  disconnectFromConferenceFail = "DISCONNECT_FROM_CONFERENCE_FAIL",
  sendInvitationFail = "SEND_INVITATION_FAIL",
  toggleMonitoringSuccess = "TOGGLE_MONITORING_SUCCESS",
  toggleMonitoringFail = "TOGGLE_MONITORING_FAIL",
  joinRoomSuccess = "JOIN_ROOM_SUCCESS",
  joinRoomFail = "JOIN_ROOM_FAIL",
  joinRoomRetry = "JOIN_ROOM_RETRY",
  leaveRoomSuccess = "LEAVE_ROOM_SUCCESS",
  leaveRoomFail = "LEAVE_ROOM_FAIL",
  localVideoUpdate = "LOCAL_VIDEO_UPDATE",
  messageReceived = "MESSAGE_RECEIVED",
  deviceInfoUpdated = "DEVICE_INFO_UPDATED",
  putOnHoldSuccess = "PUT_ON_HOLD_SUCCEEDED",
  putOnHoldFail = "PUT_ON_HOLD_FAILED",
  inviteToConnectSuccess = "INVITE_TO_CONNECT_SUCCESS",
  inviteToConnectFailed = "INVITE_TO_CONNECT_FAILED",
  establishingConnectionWith = "ESTABLISHING_CONNECTION_WITH",
  checkAnyEndpointOnlineByThemeFailed = "CHECK_ANY_ENDPOINT_ONLINE_BY_THEME_FAILED",
  socketAuthenticated = "SOCKET_AUTHENTICATED",
  makeCall = "MAKE_CALL",
  callTargetsFail = "CALL_TARGETS_FAIL",
  joinAccessibleQueuesFailed = "JOIN_ACCESSIBLE_QUEUES_FAILED",
  failToStartPresentation = "FAIL_TO_START_PRESENTATION",
  answerCallFailed = "ANSWER_CALL_FAILED",
  categorySkillAddFailed = "CATEGORY_SKILL_ADD_FAILED",
  callbackFailed = "CALLBACK_FAILED"
}

export type AlertHandlerCallbackOptions = {
  seconds?: number, // Seconds to display the alert or leave to default implementation.
  dialogOptions?: BootboxDialogOptions<string>
};

export type AlertHandlerCallback = (
  alertCode: AlertCode,
  alertText?: string,
  alertLevel?: AlertLevel,
  options?: AlertHandlerCallbackOptions
) => void;
