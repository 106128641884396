/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./page-not-found.component";

import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { LocalizationModule } from "./localization/localization.module";
import { CallCenterModule } from "./call-center/call-center.module";
import { LayoutModule } from "./layout/layout.module";
import { AppService } from "./app.service";
import { FlavorFactoryModule } from "./flavor-factory/flavor-factory.module";
import { ConfigModule } from "./config/config.module";
import { UserManagementModule } from "./user-management/user-management.module";
import { RoleManagementModule } from "./role-management/role-management.module";
import { GroupManagementModule } from "./group-management/group-management.module";
import { UserProfileModule } from "./user-profile/user-profile.module";
import { PasswordResetModule } from "./password-reset/password-reset.module";
import { LoginModule } from "./login/login.module";
import { AlertModule } from "./alert/alert.module";
import { BrowserNotSupportedComponent } from "./browser-not-supported.component";
import { HelperModule } from "./helper/helper.module";
import { NotificationModule } from "./notification/notification.module";
import { DialOutModule } from "./dial-out/dial-out.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "environments/environment";
import { LicenseModule } from "./license/license.module";
import {Store} from "client/scripts/Store/store.service";
import {ClientLogService} from "client/scripts/shared/services/client-log.service";
import {DashboardModule} from "client/scripts/dashboard/dashboard.module";
import { SharedModule } from "./shared/shared.module";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    LocalizationModule,
    HttpClientModule,
    CallCenterModule,
    LayoutModule,
    FlavorFactoryModule,
    ConfigModule,
    DialOutModule,
    UserManagementModule,
    RoleManagementModule,
    GroupManagementModule,
    UserProfileModule,
    PasswordResetModule,
    LoginModule,
    AlertModule,
    HelperModule,
    NotificationModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    LicenseModule,
    DashboardModule,
    SharedModule
  ],
  declarations: [AppComponent, PageNotFoundComponent, BrowserNotSupportedComponent],
  bootstrap: [AppComponent],
  providers: [AppService, Store, ClientLogService, SharedModule],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
