/**
 * Copyright Compunetix Incorporated 2016-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IPosition } from "./position.interface";
import { ICPMode } from "./CPMode.interface";
import { Position, PositionDictionary } from "./position";
/**
 * CPMode object structured as evergreen has
 */
export class CPMode implements ICPMode {
  /**
   * list of positions in this CPMode
   */
  public positions: IPosition[] = [];

  constructor(public total: number, public mode?: number, public isSelfViewOn?: boolean) {
    if (mode == null) {
      this.mode = 1;
    }

    if (isSelfViewOn == null) {
      this.isSelfViewOn = false;
    }

    if (isSelfViewOn) {
      this.positions = CPMode.getPositions(total, mode);
    } else {
      this.positions = CPMode.getPositions(total - 1, mode);
    }
  }

  /**
   * get position data of hidden position
   */
  static getHiddenPosition(): IPosition {
    return new Position(100, 0, 0, 0);
  }

  /**
   * get position data of full screen position
   */
  static getFullScreenPosition(): IPosition {
    return new Position(0, 0, 100, 100);
  }

  /**
   * get position data of sub side position by index
   */
  static getSubSidePosition(cpMode: ICPMode, index: number): IPosition {
    return new Position(80, 25 * index, 20, 25);
  }

  /**
   * get position data of main side position by index
   */
  static getMainSidePosition(cpMode: ICPMode, index: number): IPosition {
    if (index < 0) {
      return this.getHiddenPosition();
    }
    let position = this.getPosition(cpMode, index);
    position.leftPercent = position.leftPercent * 0.8;
    position.widthPercent = position.widthPercent * 0.8;
    return position;
  }

  /**
   * get position data of main side full screen position
   */
  static getMainSideFullScreenPosition(): IPosition {
    return new Position(0, 0, 80, 100);
  }

  /**
   * get position data of position by index
   */
  static getPosition(cpMode: ICPMode, index: number): IPosition {
    return cpMode.positions.length > index ? cpMode.positions[index] : this.getHiddenPosition();
  }

  /**
   * get position data of different cpmodes
   * @param total: number - total number of display endpoints
   */
  static getAllModePositions(total: number): { [mode: number]: IPosition[] } {
    return PositionDictionary[total];
  }

  /**
   * get position data
   * @param total: number - total number of display endpoints
   * @param mode: number - cpmode
   */
  static getPositions(total: number, mode: number): IPosition[] {
    return PositionDictionary[total][mode];
  }
}
