/**
 * Copyright Compunetix Incorporated 2018-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import {Injectable} from "@angular/core";
import {
  Companion,
  IUser,
  RoomType,
  IEndpoint,
  IEntry,
  IEntryResponse,
  IEntryField,
  IRole,
  AlertLevel,
  ISkillSet,
  ISkillTags,
  ISkillProficiency,
  ConferenceUtil,
  IQueueAccessData,
  Endpoint,
  IConferenceUpdate,
  QueueConference,
  IEndpointRef,
  IConferenceRef,
  IEndpointService,
  IUserService,
  IConferenceService,
  IConferenceBase,
  ActiveConference,
  UUID,
  IActiveConference,
  IRTCService,
  PeerConnectionSm,
  PeerConnectionEvent,
  PeerConnectionState,
  ActiveConferenceEventResult,
  ActiveConferenceState,
  AlertCode, 
  AlertHandlerCallbackOptions,
  IQueueConference,
  PresenceStatus,
  StatusReason,
  VideoMediaConnectionMode,
  LogUtil,
  IMonitorConference,
  MonitorConference
} from "companion";
import { RestService } from "../shared/services/rest.service";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { LocalizationService } from "client/scripts/localization/localization.service";
import { FileUtility } from "../shared/services/file-utility";
import { assignIn, filter, forEach } from "lodash";
import { UserManagementService } from "../user-management/user-management.service";
import { SafeHtmlPipe } from "../shared/pipes/safe-html.pipe";
import { PassportLocalDocument } from "mongoose";
import { AlertService } from "../alert/alert.service";
import { ClientLogService } from "../shared/services/client-log.service";
import { MonitorConferenceActivityStatus } from "companion/conference/conference-sm";
import { VoiceService } from "../shared/services/voice.service";
import { ChatRoomService } from "companion/chatroom/chatroom.service";
import { NavBarMenuItemKey } from "../layout/nav-bar/nav-bar.service";

@Injectable()
export class CallCenterService {
  confUpdateNotify: (update : IConferenceUpdate)=>void = null;
  /**
   * Use this to update the call-center component component that we should take this client offline
   */
  notifyConferenceComplete:()=>void = null;
  notifyMonitoringStatus:(ended:boolean,monitoring:boolean,observed:boolean)=>void = null;

  /**
   * Use this to update the call center that the client has entered (or left a voice conference)
   */
  notifyVoiceConference:()=>void = null;

  conferenceUpdater: NodeJS.Timer = null;
  conferenceUpdatePending: boolean = false;
  conferenceUpdateEnabled: boolean = false;

  /**
   * Clear the queue information in order to reset it, use with caution.
   */
  public resetForRefresh() {
    this._accessibleQueues = [];
    this._accessibleThemes = [];
    this._visibleQueues = [];
  }

  // For room-join, especially supervisor
  private _accessibleThemes: string[] = [];
  private _accessibleQueues: string[] = [];

  // For VCC
  private _visibleQueues: string[] = [];
  private _globalPublicWaitList: IEndpoint[] = [];
  private _visibleOperators: IEndpoint[] = [];
  private _visibleReps: IEndpoint[] = [];

  // For VCC-List
  private _hungCalls: IEndpoint[] = [];
  private _roomMasterList: IEndpoint[] = [];

  // For quick compare of last filtered global data update
  private _lastUpdate: IConferenceUpdate;
  // To hold endpoints gained before we receive the next update.
  // This allows us to ensure we don't delete data prematurely if we have a race between Peer and ServerData
  private _probationaryUndocumentedEndpointsSinceLastUpdate: string[] = []; // RTCID Array

  private conferenceService : IConferenceService;
  private endpointService : IEndpointService;
  private rtcService: IRTCService;

  // Trigger this flag in console to see more data.
  // use this.TRACE() to log traces through this flag.
  traceDebug: boolean = false;
  private TRACE(...args) {
    if (this.traceDebug) {
      console.log(...args);
    }
  }

  get accessibleThemes(): string[] {
    return this._accessibleThemes;
  }

  get accessibleQueues(): string[] {
    return this._accessibleQueues;
  }

  get visibleQueues(): string[] {
    return this._visibleQueues;
  }

  get globalPublicWaitList(): IEndpoint[] {
    return this._globalPublicWaitList;
  }

  get globalHungCalls(): IEndpoint[] {
    return this._hungCalls;
  }

  get globalInRoomCalls(): IEndpoint[] {
    return this._roomMasterList;
  }

  get visibleOperators(): IEndpoint[] {
    return this._visibleOperators;
  }

  get visibleReps(): IEndpoint[] {
    return this._visibleReps;
  }

  /**
   * reference to update to available timer
   */
  updateToAvailableTimer;

  /**
   * time lapse between the ready state and the available state in seconds
   */
  readyTimeLapse: number;

  constructor(
    private restService: RestService,
    private localizationService: LocalizationService,
    private userManagementService: UserManagementService,
    private voiceService: VoiceService,
    protected safeTextPipe: SafeHtmlPipe,
    protected alertService: AlertService,
    protected logService: ClientLogService,
    //public store: Store,
    ) {
    this.conferenceService = Companion.getConferenceService();
    this.endpointService = Companion.getEndpointService();
    this.rtcService = Companion.getRTCService();
    Dispatcher.register(ActionType.LoadVCCListData, this.LoadVCCListData.bind(this));
    Dispatcher.register(ActionType.LoadHangingCalls, this.LoadHangingCalls.bind(this));
    Dispatcher.register(ActionType.LoadVCCNewData, this.LoadVCCNewData.bind(this), "updateAccessibleQueues");
    Dispatcher.register(ActionType.UpdateInRoomCalls, this.updateGlobalInRoomCalls.bind(this));
    Companion.getEndpointService().registerLocalizationCallback(() => {
      return this.localizationService.myLocalizationData;
    })
    this.conferenceUpdater = setInterval(() => {
      if (!this.conferenceUpdatePending && this.conferenceUpdateEnabled) {
          // Are we connected and authenticated or we don't even have a userId?
          if (Companion.getRTCService().isServerConnected() && (Companion.getUserService()?.currentUser?.isAuthenticated || !Companion.getEndpointService()?.myEndpoint?.userId)) {
            this.requestConferenceUpdate(); // Actually request the data.
          }
      }
    }, 1000);
    window['call-center'] = this;
  }

  enableConferenceUpdates(enabled: boolean = true) {
    this.conferenceUpdateEnabled = enabled;
  }

  public setConferenceUpdateCallback(fn: (data : IConferenceUpdate)=>void) {
    if (this.confUpdateNotify) {
      throw new Error("Conference update callback already registered!");
    }
    this.confUpdateNotify = fn;
  }

  public setNotifyConferenceCompleteCallback(fn: ()=>void) {
    if (this.notifyConferenceComplete) {
      throw new Error("Conference update callback already registered!");
    }
    this.notifyConferenceComplete = fn;
  }

  public setNotifyVoiceConferenceCallback(fn: ()=>void) {
    if (this.notifyVoiceConference) {
      throw new Error("Voice conference callback already registered!");
    }
    this.notifyVoiceConference = fn;
  }

  public setNotifyMonitoringStatusCallback(fn: (ended:boolean,monitoring:boolean,observed:boolean)=>void) {
    if (this.notifyMonitoringStatus) {
      throw new Error("Monitoring status callback already registered!");
    }
    this.notifyMonitoringStatus = fn;
  }

  requestConferenceUpdate() {
    this.conferenceUpdatePending = true;
    // Ensure we don't lock the client if the message is lost.
    let unfreezeTimer : NodeJS.Timeout = setTimeout(() => {
      console.warn("lost update request, unfreezing...");
      this.conferenceUpdatePending = false;
      clearTimeout(unfreezeTimer);
    }, this.rtcService.rtcClient.SERVER_DISCONNECT_TIMEOUT*2)
    this.conferenceService.fetchConferenceUpdate().then((data : IConferenceUpdate) =>
    {
      clearTimeout(unfreezeTimer);
      this.conferenceUpdatePending = false;
      this.handleConferenceUpdateData(data);
    }).catch((reason : any) =>
    {
      clearTimeout(unfreezeTimer);
      console.warn("fetch conf update failed");
      this.conferenceUpdatePending = false;
    })
  }

  requestConferenceCreate(confRef: IConferenceRef, success: (data)=>void, error: (error)=>void) {
    this.restService
      .post("/createConference", {conference: confRef})
      .subscribe({
        next: success,
        error: error
      });
  }

  requestConferenceDelete(confRef: IConferenceRef, success: (data)=>void, error: (error)=>void) {
    this.restService
      .post("/deleteConference", {conference: confRef})
      .subscribe({
        next: success,
        error: error
      });
  }

  public ensureNextEmptyConf(callback?: (boolean)=>void) {
    if (!this.conferenceService.findEmptyOwnedConference()) {
      let data: IConferenceBase = {
        name: ConferenceUtil.conferenceRoomPrefix + this.endpointService.myEndpoint.userId + "-" + UUID.randomAtAnyLength(8),
        ownerId: this.endpointService.myEndpoint.userId,
        ownerName: this.endpointService.myEndpoint.name,
        groupId: Companion.getUserService().currentUser.groups[0]["_id"]
      };
      let confRef: IConferenceRef = ConferenceUtil.newConferenceData(RoomType.Conference, data);
      this.requestConferenceCreate(confRef,
      (data) => {
        this.TRACE("NEW CONF", data);
        if (callback) {
          callback(true);
        }
      },
      (error) => {
        this.TRACE("Failed to make conf!");
        if (callback) {
          callback(false);
        }
      });
    }
  }

  getSafeText(text: string): string {
    if (text) {
        return this.safeTextPipe.nonScrub(text) as string;
    }
    return "";
  }

  currentUser: IUser = Companion.getUserService().currentUser;

  LoadHangingCalls(): void {
    this.restService
    .post("/getClientsNotInAnyRoom", {})
    .subscribe(
      (data: IEndpoint[]) => {
        this._hungCalls = data;
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  LoadVCCNewData(userData?: any, callback?: ()=>void): void {
    if (Companion.getUserService()?.currentUser?.isAuthenticated)
    {
      (!userData ? this.userManagementService.getMyAccessibleUsersWithRolesAndGroups() : Promise.resolve(userData))
      .then((data: any) => {
        if (!!data) {
          this._visibleOperators = [];
          this._visibleReps = [];
        }
        _.forEach(data, (userData: any) => {
          var userModel = Companion.getUserService().findOrCreateUserByName(userData.username, userData);
          userModel.isOperator = _.some(userData.roles, (role: IRole) => {
            return (
              role.permissions && role.permissions.seeActiveEndpointInQueue > 0 && role.permissions.actAsOperator > 0
            );
          });
          userModel.isRep = _.some(userData.roles, (role: IRole) => {
            return (
              role.permissions &&
              role.permissions.seeActiveEndpointInQueue > 0 &&
              !(role.permissions.actAsOperator > 0)
            );
          });
          let addUser : boolean = false;
          
          // verify we share a skillSet
          let mySkills: ISkillSet = Companion.getEndpointService().myEndpoint.skillSet;
          if(!!mySkills && (mySkills?.categories?.length > 0 || mySkills?.languages?.length > 0))
          {
            // no skills shared
            let sharedSkill : boolean = _.some(mySkills.categories, (skillProficiency: ISkillProficiency) =>
            {
              return (ConferenceUtil.getCategoryListFromSkillProficiencyList(userModel?.skillSet?.categories).includes(skillProficiency.skill))
            });
            // no lanaguages shared
            let sharedLanguage : boolean = _.some(mySkills.languages, (language: string) =>
            {
              return (userModel?.skillSet?.languages.includes(language))
            });

            addUser = sharedSkill && sharedLanguage;
          }
          else
          {
            // skill sets not in use, show all users.
            addUser = true;
          }

          if(addUser || userModel.isRep)
          {
            let newEp = Companion.getEndpointService().getEndpointByUserId((userModel as PassportLocalDocument)._id);
            
            if (newEp && userModel.isOperator) {
              this._visibleOperators.unshift(newEp);
            } else if (newEp && userModel.isRep) {
              this._visibleReps.unshift(newEp);
            }
          }
        });
      })
      .catch((err: any) => {
        console.error(err);
      })
      .then(() => {
        if (callback) {
          callback();
        }
      });
    }
  }

  refreshVCCData() {
    this.LoadVCCNewData();
  }

  LoadVCCListData() {
    this.getAccessibleQueues()
    .then((accessibleQueues: string[]) => {
      this.updateGlobalGuestWaitList();
      this.updateGlobalInRoomCalls();
    })
    .catch((err: Error) => {
      console.error(err);
    });
  }

  updateGlobalInRoomCalls() {
    this._roomMasterList = [];
    _.forEach(this.visibleQueues, (queue: string) => {
       let queueConf: QueueConference = Companion.getConferenceService().findQueueConferenceByName(queue);
       let epRefList: IEndpointRef[] = queueConf?.everyone || [];
      _.forEach(epRefList, (epRef: IEndpointRef) => {
        let ep: IEndpoint = Companion.getEndpointService().getEndpointById(epRef.rtcId);
        if (ep && !this._roomMasterList.includes(ep)) {
          this._roomMasterList.push(ep as Endpoint);
        }
      });
    });
  }

  /**
   * get accessible queues
   * Identify if we are authenticated and can see endpoints in the queue (op/sp/sup).
   * Idenfity if we are actually a guest.
   * Either fetch the accessible styles and queues from the backend or fetch the current queue based on style and specified skillTags.
   * When complete, perform any filtering of the accessible and visible queues as well as the accessible themes. if available.
   * If we are actually a guest, also set my skillTags to the default skillTags as we use this to reset ourselves in certain situations.
   */
  getAccessibleQueues(style?: string, skillTags?: ISkillTags): Promise<string[]> {
    var queueFetchPromise : Promise<IQueueAccessData[]> = null;
    var styleFetchPromise : Promise<string[]> = null;
    if (
      this.currentUser.isAuthenticated &&
      this.currentUser.permissions &&
      (this.currentUser.permissions.seeActiveEndpointInQueue > 0 ||
       this.currentUser.permissions.modifyActiveEndpointInQueue > 0 ||
       this.currentUser.permissions.actAsOperator > 0 ||
       this.currentUser.permissions.silentMonitorCall > 0 ||
       this.currentUser.permissions.canManageVCC > 0)
    ) {
      // Fetch all queues for this user.
      queueFetchPromise = this.getAccessibleQueuesByUser();
      styleFetchPromise = this.getAccessibleStylesByUser();
    } else {
      // Fetch specific queue if the style is call_center type and the requested queue params is valid if specified 
      queueFetchPromise = this.getGuestQueueByStyle(style, skillTags);
      styleFetchPromise = Promise.resolve([style]);
    }

    return styleFetchPromise
    .then((styles:string[]) => {
      if (style && !styles.includes(style))
      {
        return []; // This style is not in the list of styles allowed by this user. Don't return any queues.
      } else {
        // OK style is allowed, go look for all queues as is available.
        return queueFetchPromise
        .then((data: IQueueAccessData[]) => {
          forEach(data, (entry) => {
            // Filter for only style we are looking at right now.
            if(entry.themes.includes(style) && entry.queues && entry.queues.length > 0) {
              // Update my skillset implicitly based on this theme if present.
              if (entry.overrideSkillSet?.categories?.length > 0 || entry.overrideSkillSet?.languages.length > 0) {
                Companion.getEndpointService().myEndpoint.skillSet = entry.overrideSkillSet;
                // Companion.getEndpointService().sendMyStatusToOthers(); // todo not sure this is needed
              }
              if (!Companion.getEndpointService().myEndpoint.groupId) {
                Companion.getEndpointService().myEndpoint.groupId = entry.groupId;
              }
              // Set my filtered queues based on this theme.
              this._visibleQueues = _.uniq(
                _.compact(_.map(entry.queues))
              );
              //console.log("getAccessibleQueues visibleQueues:", this.viewModel.visibleQueues);
            }

            this._accessibleQueues = _.uniq(
              _.compact(_.map(entry.queues)).concat(this._accessibleQueues)
            );
            //console.log("getAccessibleQueues accessibleQueues:", this.viewModel.accessibleQueues);
          
            this._accessibleThemes = _.uniq(_.compact(_.map(_.intersection(entry.themes, styles))).concat(this._accessibleThemes));
          });
          
          if (!this.currentUser.isAuthenticated) {
            // Grab base skill Tags (should only be one anyway in this case.)
            Companion.getEndpointService().myEndpoint.skillTags = data[0].defaultSkillTags;
          }
          
          return Promise.resolve(this._accessibleQueues);
        });
      }
    });
  }

  /**
   * get queues by user
   */
   getAccessibleQueuesByUser(): Promise<IQueueAccessData[]> {
    return new Promise((resolve: (data: IQueueAccessData[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getGetAccessibleQueuesByUser", {})
      .subscribe(
        (data: IQueueAccessData[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  getAccessibleStylesByUser(): Promise<string[]> {
    return new Promise((resolve: (data: string[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getThemeNamesByUserAndMode", {mode: "call_center"})
      .subscribe(
        (data: string[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  getGuestQueueByStyle(style: string, skillTags: ISkillTags): Promise<IQueueAccessData[]> {
    return new Promise((resolve: (data: IQueueAccessData[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getQueueByStyle", { style: style, skillTags: skillTags})
      .subscribe(
        (data: IQueueAccessData[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }    

  /**
   * check if endpoint is in all accessible queues
   */
  checkIfEndpointInAllAccessibleQueues(endpoint: IEndpoint): Promise<boolean> {
    let _tasks: Promise<boolean>[] = _.map(this._accessibleQueues, (queue: string) => {
      let id = Companion.getConferenceService().findQueueConferenceByName(queue)?.id;
      if (id) {
        return this.checkIfEndpointInRoom(endpoint, id)
        .catch((error: any) => {
          console.log(`Failed to checkIfEndpointInRoom: ${JSON.stringify(error)}`);
          return Promise.reject(error);
        });
      } else {
        return Promise.resolve(true);
      }
    });
    return Promise.all(_tasks)
    .then((results: boolean[]) => {
      return Promise.resolve(_.every(results));
    });
  }

  /**
   * check if endpoint is in room
   */
  checkIfEndpointInRoom(endpoint: IEndpoint, confId: string): Promise<boolean> {
  return new Promise((resolve: (data: boolean) => void, reject: (error: Error) => void) => {
    this.restService
      .post("/checkIfEndpointInRoom", { rtcId: endpoint.rtcId, confId: confId })
      .subscribe(
        (data: any) => {
          resolve(data.result);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * post user data
   */
  postUserData(body: any): Promise<IEntry> {
    return new Promise((resolve: (data: IEntry) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/entryFormSubmit", body)
      .subscribe(
        (data: IEntry) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get entry data by id
   */
  confirmAndRetrieveEntryDataById(style: string, entryId: string): Promise<IEntryResponse> {
    return new Promise((resolve: (data: IEntryResponse) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/confirmAndRedeemEntryId", { theme: style, entryId: entryId })
      .subscribe(
        (data: IEntryResponse) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  convertEntryDataToHTMLString(entry: IEntry): string {
    let result: string = "";
    result += "<div>";
    _.forEach(entry.data, (entryField: IEntryField) => {
      result += "<span><b>" + entryField.title + ": </b>" + this.getSafeText(entryField.value) + "</span><br>";
    });
    result += "</div>";
    return result;
  }

  /**
   * join visible queues
   * First update the list of accessible queues and perform a filtering based on style or skillTag depending on our access to the system.
   * The filteredAccessibleQueues property is updated and we use that to validate if we are in a valid situation style vs queues.
   * Then we can add ourselves to the rooms we are currently able to see by cal;ling addToRoom for each filtered queue.
   */
  joinVisibleQueues(style?: string, skillTags?: ISkillTags): Promise<any> {
    //console.log("joinVisibleQueues style:", style, "skillTags:", skillTags);
    return this.getAccessibleQueues(style, skillTags) // updates filteredAccessbileQueues
    .then(() => {
      if (style && !this.visibleQueues.length) {
        return Promise.reject({
          name: "QUEUE_ACCESS_NOT_AUTHORIZED",
          message: `Client not authorized to access queues in ${style}`
        });
      }
      console.log("Attempting to join rooms: ", this.visibleQueues);
      console.log("Current SkillSet: ", Companion.getEndpointService().myEndpoint.skillSet);
      //console.log("myEndpoint: ", Companion.getEndpointService().myEndpoint);
      let joinRoomPromises = [];
      _.forEach(this.visibleQueues, (queue: string) => {
        joinRoomPromises.push(new Promise((resolve, reject) => {
          Companion.getEndpointService().addToRoom(ConferenceUtil.newConferenceData(RoomType.GuestWaitRoom, {name: queue}),
          (roomName: string) => {
            if (joinRoomPromises.length === 1) {
              Companion.getEndpointService().myEndpoint.skillTags == ConferenceUtil.getSkillTagsFromQueueRoomName(roomName);
            }
            resolve(roomName);
          }, (errorCode: string, errorText: string, roomName:string) => {
            console.log("Failed to join room!", roomName, errorCode, errorText);
            reject(errorText);
          });
        }));
      });
      return Promise.all(joinRoomPromises).then(() => {
        Dispatcher.dispatch(ActionType.LoadVCCNewData, null, "updateAccessibleQueues");
        Dispatcher.dispatch(ActionType.LoadVCCNewData, null, "loadParticipants");
        Dispatcher.dispatch(ActionType.UpdateSkillSetDisplay, null, "updateSkillSet");
        this.updateGlobalGuestWaitList();
        this.updateGlobalInRoomCalls();
        this.updateVisibleUsersLists();
        this.endpointService.sendEndpointUpdate();
      });
    })
  }

  /**
   * update guest wait lists
   */
  updateGlobalGuestWaitList() {
    this._globalPublicWaitList = [];
    _.forEach(this.visibleQueues, (queue: string) => {
       let queueConf: QueueConference = Companion.getConferenceService().findQueueConferenceByName(queue);
       let epRefList: IEndpointRef[] = queueConf?.publicWaitList || [];
      _.forEach(epRefList, (epRef: IEndpointRef) => {
        let ep: IEndpoint = Companion.getEndpointService().getEndpointById(epRef.rtcId);
        if (ep && !this._globalPublicWaitList.includes(ep)) {
          this._globalPublicWaitList.push(ep as Endpoint);
        }
      });
    });
  }

  updateVisibleUsersLists() {
    this._visibleOperators = []; // TODO FIX: Breaks offline users.
    this._visibleReps = []; // TODO FIX: Breaks offline users.
    _.forEach(this.visibleQueues, (queue: string) => {
       let queueConf: QueueConference = Companion.getConferenceService().findQueueConferenceByName(queue);
       let opRefList: IEndpointRef[] = queueConf?.operators;
      _.forEach(opRefList, (epRef: IEndpointRef) => {
        let ep: IEndpoint = Companion.getEndpointService().getEndpointById(epRef.rtcId);
        if (ep && !this._visibleOperators.includes(ep)) {
          this._visibleOperators.push(ep as Endpoint);
        }
      });
      let repRefList: IEndpointRef[] = queueConf?.reps;
      _.forEach(repRefList, (epRef: IEndpointRef) => {
        let ep: IEndpoint = Companion.getEndpointService().getEndpointById(epRef.rtcId);
        if (ep && !this._visibleReps.includes(ep)) {
          this._visibleReps.push(ep as Endpoint);
        }
      });
    });
  }

  /**
   * conference update listener
   */
  handleConferenceUpdateData(data: IConferenceUpdate) {
    // For safety
    if (data) {
      try {
        this.TRACE("UPDATE:", data);
        
        // Conference Processing
        let activeStateChanges: ActiveConferenceEventResult[] = this.processActiveConferenceUpdates(data);

        // Queue Processing
        this.processQueueConferenceUpdates(data);

        // Monitors Processing
        let monitorStateChanges: ActiveConferenceEventResult[] = this.processMonitorConferenceUpdates(data);
  
        // Determine which endpoints were added during this last interval.
        // If they are in our endpoint storage data but not in our last update that we latched.
        let currentUndocumented = _.map(_.filter([...this.endpointService.endpoints.values()], (ep) => {
          let foundInLastUpdate = _.some(this._lastUpdate?.endpoints || [], (item) => {return item.rtcId == ep.rtcId});
          let wasKnownOnLastUpdate =  this._probationaryUndocumentedEndpointsSinceLastUpdate.includes(ep.rtcId);
          return (!foundInLastUpdate && !wasKnownOnLastUpdate);
        }), (ep) => {
          return ep.rtcId;
        });
        // Save this information so that it can be used in the subsequent update handling and future calls to this operation.
        this._probationaryUndocumentedEndpointsSinceLastUpdate = currentUndocumented;

        // Endpoints Processing
        this.processEndpointConferenceUpdates(data);

         /* Cleanup */

        // is an agent
        if (this.currentUser.isAuthenticated)
        {
          this.ensureNextEmptyConf();
        }

        // Store last update to compare to next
        this._lastUpdate = _.cloneDeep(data);

        // take action on the state machine results.
        // CALL the peers we need to be in call with...
        // disconnect the peers we should no longer be in call with..
        forEach(activeStateChanges, (stateChange : ActiveConferenceEventResult) =>
        {
          this.processConferenceStateChange(stateChange);
        });

        // take action on the state machine results.
        let updateMonitors = false; // Check if we need to run updates.
        let ended = false;
        let monitoring = false;
        let observed = false;
        // CALL the peers we need to be in call with...
        // disconnect the peers we should no longer be in call with..
        forEach(monitorStateChanges, (stateChange : ActiveConferenceEventResult) =>
        {
          let activity = this.processMonitorStateChange(stateChange);
          if (activity != MonitorConferenceActivityStatus.none) {
            updateMonitors = true;
            if (activity == MonitorConferenceActivityStatus.ended) {
              ended = true;
            } else {
              // Trip flags as necessary.
              activity == MonitorConferenceActivityStatus.observed ? observed = true : monitoring = true;
            }
          }
        });

        if (updateMonitors && this.notifyMonitoringStatus) {
          this.notifyMonitoringStatus(ended, monitoring, observed);
          _.forEach([...this.conferenceService.monitors.values()], (conf) => {
            // Notify everyone if we updated our stream on our end.
            this.rtcService.updateLocalStreamToOthers(_.filter(_.map(conf.everyone, (epRef) => {return epRef.rtcId}), (rtcId) => {return rtcId != this.endpointService.myEndpoint.rtcId})).catch((error: any) => {
              // Should be caught in updateLocalStreamToOthers
            });
          })
        }

        this.cleanupOrphanedPeers();
    
        // Does someone else want to be notified?
        if (this.confUpdateNotify) {
          this.confUpdateNotify(data);
        }
        //console.log(`Processed ${data.conferences.length} conferences and ${data.queues.length} queues and ${data.endpoints.length} endpoints!`);
      } catch(error) {
        console.error(error);
      }
    }
  }

  private cleanupOrphanedPeers() {
    // Break down any orphaned peer connections
    // fetch them again as it some may have been associated
    let orphanedPeerConnections = 
      _.filter([...this.conferenceService.peerSmMap.values()], (sm : PeerConnectionSm) => { return sm.confId == null && !this._probationaryUndocumentedEndpointsSinceLastUpdate.includes(sm.peerId)});
    _.forEach(orphanedPeerConnections, (sm : PeerConnectionSm) => {
      this.TRACE("prune orphaned connection", sm.peerId);
      this.rtcService.hangupPeer(sm.peerId);
      this.conferenceService.peerSmMap.delete(sm.peerId);
    });

    // prune any peer connections that have gone closed...
    // we do this at the end to prevent reconnecting closed peers during conference
    // tear down.
    let closedPeerConnections : PeerConnectionSm[] = 
    _.filter([...this.conferenceService.peerSmMap.values()], (sm : PeerConnectionSm) => { 
    return sm.state == PeerConnectionState.peer_closed });

    _.forEach(closedPeerConnections, (sm : PeerConnectionSm) => {
      this.TRACE("prune peer connection", sm.peerId);
      this.conferenceService.peerSmMap.delete(sm.peerId);
    });

  }

  private processActiveConferenceUpdates(data) : ActiveConferenceEventResult[] {
    /* Actives */
    let newActiveConfIds : string[] = _.map(data.conferences, (conf : IActiveConference) => { return conf.id});
    let oldActiveConfIds : string[] = Array.from(this.conferenceService.conferences.keys());

    // get the removed IDS
    let removedIds = _.differenceWith(oldActiveConfIds, newActiveConfIds, _.isEqual);

    // hold onto the state changes...
    let stateChanges : ActiveConferenceEventResult[] = [];

    // get any orphan peers
    // orphaned peers are connections that have been made to our client, but do not have conferences
    // associated with them yet... we must determine whether to associate these with new conferences 
    // or disconnect them from our system.
    let orphanedPeerConnections : PeerConnectionSm[] = 
      _.filter([...this.conferenceService.peerSmMap.values()], (sm : PeerConnectionSm) => { return sm.confId == null });

    if (orphanedPeerConnections?.length > 0)
    {
      this.TRACE("has orphaned peers");
    }

    // Push the active conference updates into the conference service.
    forEach(data.conferences, (conference : IActiveConference) => {
      let updated : IActiveConference = this.conferenceService.setActiveInfo(conference);
      conference = undefined; // DATA INSURANCE
      if(_.some(updated.everyone, (endpoint : IEndpointRef) => 
        {return endpoint.rtcId == this.endpointService.myEndpoint.rtcId}))
      {
        // associating orphan endpoints with the active conference may require a video update,
        let requiresVideoUpdate : boolean = false;
        // if we exist in this conference, MAKE US ACTIVE
        if(this.conferenceService.activeConference != updated.id)
        {
          this.TRACE("set active conference", updated);
          this.conferenceService.activeConference = updated.id;
          requiresVideoUpdate = true;
        }

        if (updated.id == this.conferenceService.activeConference) {
          this.conferenceService.sharedFilesUpdateHandler(updated.filesOffered); // Update someone about files offered.
        }

        let activeEpIds = _.map(updated.active, (ep : IEndpointRef) => { return ep.rtcId});
        // associate any orphan endpoints with this conference.
        _.forEach(orphanedPeerConnections, (sm : PeerConnectionSm) => {
          if(activeEpIds.includes(sm.peerId))
          {
            this.TRACE("adopt orphan peer connection", {confId : updated.id, peerId : sm.peerId})
            sm.associateWithConfId(updated.id);
            // requires video update
            requiresVideoUpdate = true;
          }
        });

        // make sure that we create and update the statemachine for it.
        if(!this.conferenceService.conferenceSmMap.has(updated.id))
        {
          this.conferenceService.createStateMachineForConference(updated);
        }

        // create any peer connections for this conference that may not exist yet...
        _.forEach(updated.active, (activeEp : IEndpointRef) => {
          // if we are are the "connecting" operator we can clear that here
          if(this.endpointService.connectingOperator?.endpoint?.rtcId == activeEp.rtcId)
          {
            // We are in conference togther and no longer "connecting"
            this.endpointService.connectingOperator = null;
          }

          if(activeEp.rtcId != this.endpointService.myEndpoint.rtcId && 
            !this.conferenceService.peerSmMap.has(activeEp.rtcId))
          {
            this.TRACE("create peer state machine", activeEp.rtcId);
            this.conferenceService.createPeerStateMachine(activeEp.rtcId, updated.id, activeEp.isVoiceEp);
          }});

        if(requiresVideoUpdate)
        {
          this.conferenceService.emitRemoteVideoUpdateEvent();
        }
      }

      if(this.conferenceService.conferenceSmMap.has(updated.id))
      {
        let newState = this.conferenceService.conferenceSmMap.get(updated.id).handleActiveConfUpdate(updated);
        if(newState)
        {
          stateChanges.push(newState);
        }
      }
    });

    // update conferences that no longer exists on server
    forEach(removedIds, (removedId) => {
      if(this.conferenceService.conferenceSmMap.has(removedId))
      {
        // send null conference to trigger SM breakdown.
        let newState = this.conferenceService.conferenceSmMap.get(removedId).handleActiveConfUpdate(null);
        if(newState)
        {
          stateChanges.push(newState);
        }
      }
      else
      {
        // no need to do anything if no SM associated just delete it now.
        this.conferenceService.conferences.delete(removedId);
      }
    });

    return stateChanges;
  }

  private processQueueConferenceUpdates(data) {
    /* Queues */
    // Get a list of all queues we knew about before?
    let oldQueueIds: Readonly<string>[] = Array.from(this.conferenceService.queues.keys());
    let currentQueueIds: Readonly<string>[] = _.map(data.queues, (queue : IQueueConference) => { return queue.id});
    let removedQueueIds = _.differenceWith(oldQueueIds, currentQueueIds, _.isEqual);
    // Push the queue updates into the conference service.
    forEach(data.queues, (queue) => {

      // Don't care to track membership here I think.
      let udpated = this.conferenceService.setQueueInfo(queue);
      queue = undefined; // DATA INSURANCE
    });

    forEach(removedQueueIds, (removedQueueId : string) => {
      this.conferenceService.queues.delete(removedQueueId);   
    });
  }

  private processMonitorConferenceUpdates(data) {
    /* Monitors */
    let newmonitorConfIds : string[] = _.map(data.monitors, (conf : IMonitorConference) => { return conf.id});
    let oldmonitorConfIds : string[] = Array.from(this.conferenceService.monitors.keys());

    // get the removed IDS
    let removedIds = _.differenceWith(oldmonitorConfIds, newmonitorConfIds, _.isEqual);

    // hold onto the state changes...
    let stateChanges : ActiveConferenceEventResult[] = [];

    // get any orphan peers
    // orphaned peers are connections that have been made to our client, but do not have conferences
    // associated with them yet... we must determine whether to associate these with new conferences 
    // or disconnect them from our system.
    let orphanedPeerConnections : PeerConnectionSm[] = 
      _.filter([...this.conferenceService.peerSmMap.values()], (sm : PeerConnectionSm) => { return sm.confId == null });

    if (orphanedPeerConnections?.length > 0)
    {
      this.TRACE("has orphaned peers");
    }

    // Push the monitor conference updates into the conference service.
    forEach(data.monitors, (conference : IMonitorConference) => {
      let updated : IMonitorConference = this.conferenceService.setMonitorInfo(conference);
      conference = undefined; // DATA INSURANCE
      // Check if I'm subjected to monitoring
      if(_.some(updated.subjects, (endpoint : IEndpointRef) => 
        {return endpoint.rtcId == this.endpointService.myEndpoint.rtcId}))
      {
        let observerEpIds = _.map(updated.observers, (monitorEp : IEndpointRef) => {
          // Update transmit mode while identifying our observers.
          this.endpointService.setTransmitModeToEndpointByRtcId(monitorEp.rtcId, VideoMediaConnectionMode.Broadcasting);
          return monitorEp.rtcId
        });
        // associate any orphan endpoints with this conference.
        _.forEach(orphanedPeerConnections, (sm : PeerConnectionSm) => {
          if(observerEpIds.includes(sm.peerId))
          {
            this.TRACE("adopt orphan peer connection for monitor destination", {confId : updated.id, peerId : sm.peerId})
            sm.associateWithConfId(updated.id);
          }
        });

        // make sure that we create and update the statemachine for it.
        if(!this.conferenceService.conferenceSmMap.has(updated.id))
        {
          this.conferenceService.createStateMachineForConference(updated);
        }

        // create any peer connections for this conference that may not exist yet...
        _.forEach(updated.observers, (monitorEp : IEndpointRef) => {
          if(monitorEp.rtcId != this.endpointService.myEndpoint.rtcId && 
            !this.conferenceService.peerSmMap.has(monitorEp.rtcId))
          {
            this.TRACE("create peer state machine for monitor destination", monitorEp.rtcId);
              this.conferenceService.createPeerStateMachine(monitorEp.rtcId, updated.id, monitorEp.isVoiceEp);
        }});
      }

      // Check if I'm doing the monitoring.
      if(_.some(updated.observers, (endpoint : IEndpointRef) => 
        {return endpoint.rtcId == this.endpointService.myEndpoint.rtcId}))
      {
        // Don't iterate on ourselves;
        let partyRefs = _.filter(updated.everyone, (ep) => {return ep.rtcId != this.endpointService.myEndpoint.rtcId});

        let partyIds = _.map(partyRefs, (ep : IEndpointRef) => {
          // Update transmit mode while identifying our monitor session parties..
          this.endpointService.setTransmitModeToEndpointByRtcId(ep.rtcId, VideoMediaConnectionMode.None);
          return ep.rtcId
        });

        // associate any orphan endpoints with this conference.
        _.forEach(orphanedPeerConnections, (sm : PeerConnectionSm) => {
          if(partyIds.includes(sm.peerId))
          {
            this.TRACE("adopt orphan peer connection for monitor session", {confId : updated.id, peerId : sm.peerId})
            sm.associateWithConfId(updated.id);
          }
        });

        // make sure that we create and update the statemachine for it.
        if(!this.conferenceService.conferenceSmMap.has(updated.id))
        {
          this.conferenceService.createStateMachineForConference(updated);
        }

        // create any peer connections for this conference that may not exist yet...
        _.forEach(partyRefs, (ep : IEndpointRef) => {
          if(!this.conferenceService.peerSmMap.has(ep.rtcId))
          {
            this.TRACE("create peer state machine for monitor destination", ep.rtcId);
              this.conferenceService.createPeerStateMachine(ep.rtcId, updated.id, ep.isVoiceEp);
          }
        });
      }

      if(this.conferenceService.conferenceSmMap.has(updated.id))
      {
        let newState = this.conferenceService.conferenceSmMap.get(updated.id).handleMonitorConfUpdate(updated);
        if(newState)
        {
          stateChanges.push(newState);
        }
      }
    });

    // update conferences that no longer exists on server
    forEach(removedIds, (removedId) => {
      if(this.conferenceService.conferenceSmMap.has(removedId))
      {
        // send null conference to trigger SM breakdown.
        let newState = this.conferenceService.conferenceSmMap.get(removedId).handleMonitorConfUpdate(null);
        if(newState)
        {
          stateChanges.push(newState);
        }
      }
      else
      {
        // no need to do anything if no SM associated just delete it now.
        this.conferenceService.monitors.delete(removedId);
      }
    });

    return stateChanges;
  }

  private processEndpointConferenceUpdates(data) {
    /* Endpoints */
    // Get a list of all endpoints we knew about before?
    let oldEndpointIds: Readonly<string>[] = Array.from(this.endpointService.endpoints.keys());
    // get the current ids
    let currentEndpointIds: Readonly<string>[] = _.map(data.endpoints, (ep : IEndpoint) => { return ep.rtcId});
    // get the removed IDS
    let removedEpIds = _.differenceWith(oldEndpointIds, currentEndpointIds, _.isEqual);

    forEach(data.endpoints, (endpoint) => {
      let existing = this.endpointService.getEndpointById(endpoint.rtcId);
      let update = new Endpoint(endpoint.rtcId);

      if(this.endpointService.myEndpoint.rtcId === endpoint.rtcId)
      {
        // MOST data fields of MY endpoint are managed by my client, but some data
        // fields should get updated by the server, do that here now.
        this.endpointService.myEndpoint.skillTags = endpoint.skillTags;
        // status reason and clientState are determined by the server
        this.endpointService.myEndpoint.smState = endpoint.smState;
        this.endpointService.myEndpoint.statusReason = endpoint.statusReason;
      }

      assignIn(update, endpoint);
      if (existing) {
        // streams, volume, ransmit mode, lastStats, lastQuality are stored locally, preserve them
        update.streams = existing.streams;
        update.volume = existing.volume;
        update.transmitMode = existing.transmitMode;
        update.lastStats = existing.lastStats;
        update.callQuality = existing.callQuality;
        this.endpointService.endpoints.set(endpoint.rtcId, update);
      } else {
        this.endpointService.create(update);
      }
    });

    // delete any endpoints that no longer exit
    this.endpointService.deleteByIds(_.filter(removedEpIds, (item) => {return !this._probationaryUndocumentedEndpointsSinceLastUpdate.includes(item)})); // TODO: May want to preseve user-endpoints and set them to offline.
    // Clear chats with no longer visible endpoints
    ChatRoomService.getSharedInstance().deleteChatsByEpIds(removedEpIds);
    Dispatcher.dispatch(ActionType.ResetNavBarNotification, {
      key: NavBarMenuItemKey.Chat,
      value: ChatRoomService.getSharedInstance().countTotalUnreadMessages(),
    });
    this.updateGlobalGuestWaitList();
    this.updateVisibleUsersLists(); // TODO: Related to this section as we clear the visible list each time.
    Dispatcher.dispatch(ActionType.LoadVCCNewData, null, "loadParticipants");
    this.updateGlobalInRoomCalls();
  }
  
  /**
   * Process a confernece state change 
   * (connect or disconnect peers as they are added and removed from the conference)
   * @param stateChange 
   */
  private processConferenceStateChange(stateChange : ActiveConferenceEventResult)
  {
    // get the conference
    let conference : ActiveConference = this.conferenceService.conferences.get(stateChange.confId);

    if(conference == null)
    {
      console.warn("State update for conference that no longer exists!", stateChange.confId) 
      return;
    }

    let isOurActiceConf : boolean = stateChange.confId == this.conferenceService.activeConference;
    let requireVideoUpdate = false;
    // if our active conference changed states, update the video and endpoint status here...
    if(isOurActiceConf && 
      stateChange.newState != stateChange.oldState)
    {
      this.TRACE("Live Conference state update", {confId: stateChange.confId, state : ActiveConferenceState[stateChange.newState]});  
      // call this before handling because the proccessesing may eliminate the data that we need.
      this.updateMyEndpointStatusByConferenceStatus();
      requireVideoUpdate = true;
    }

    let activeEpIds = _.map(conference.active, (ep : IEndpointRef) => { return ep.rtcId});
    let peerConnections = _.filter([...Companion.getConferenceService().peerSmMap.values()], 
      (peer : PeerConnectionSm) => { return peer.confId == stateChange.confId});

    // track the peer connections that we have for the conference... but are no longer in the
    // active endpoint list.
    let removedConnections = _.filter(peerConnections, 
      (peer : PeerConnectionSm) => { return (stateChange.newState == ActiveConferenceState.conference_held) || 
      !activeEpIds.includes(peer.peerId) });

    // check the active conference list and disconnect any that are no longer active...
    _.forEach(removedConnections, (peer : PeerConnectionSm) =>
    {
      if(!peer.isVoicePeer && isOurActiceConf)
      {
        requireVideoUpdate = true;
      }
      this.disconnectPeer(peer);
    });

    if(requireVideoUpdate)
    {
      this.conferenceService.emitRemoteVideoUpdateEvent();
    }

    // handle state changes 
    switch(stateChange.newState)
    {
      case ActiveConferenceState.conference_alone:
        // if i am alone and i own this conference, remove myself from the conference
        if(conference.everyone.length == 1 && 
           conference.ownerId === this.endpointService.myEndpoint.userId)
        {
          this.endpointService.removeFromConference(this.endpointService.myEndpoint.rtcId, stateChange.confId);
        }
      break;
      case ActiveConferenceState.conference_held:
        // nothing to do here...
      break;
      case ActiveConferenceState.conference_connecting:
        // make sure we connect to all the active peers in the conference.
        // update peer state machines for every active peer in the conference
        forEach(conference.active, (endpoint : IEndpointRef) => 
        {
          if(endpoint.rtcId != this.endpointService.myEndpoint.rtcId)
          {
            this.endpointService.setTransmitModeToEndpointByRtcId(endpoint.rtcId, VideoMediaConnectionMode.Conference);
            let peerSm : PeerConnectionSm = this.conferenceService.peerSmMap.get(endpoint.rtcId);
            if(peerSm && peerSm.state == PeerConnectionState.peer_not_connected)
            {
              if(peerSm.isVoicePeer)
              {
                peerSm.event(PeerConnectionEvent.conn_req);
                // we need to handle connecting to this peer by using the voice service
                this.voiceService.joinVoiceConference(endpoint.rtcId, conference.ownerId);
                this.notifyVoiceConference();
              }
              else if(this.endpointService.myEndpoint.rtcId > endpoint.rtcId)
              {
                // it falls to the greater RTC id to initiate the call
                // Move to connect.
                peerSm.event(PeerConnectionEvent.conn_req);
                this.callPeer(endpoint).then(() =>
                {
                  // Wait for completion or failure by event.
                  this.TRACE("peer conn attempting...");
                }).
                catch((reason : any) =>
                {
                  this.TRACE(`peer conn attempt failed to ${endpoint.rtcId}:`, reason)
                  peerSm.event(PeerConnectionEvent.conn_fail);
                });
              }
              else
              {
                // else we wait for the call from the greater rtcId
                peerSm.event(PeerConnectionEvent.conn_wait);
              }
            }
          }
        });
      break; 
      case ActiveConferenceState.conference_connected:
        // Check to see if we have a chat for the other party if I'm a guest.
        if (!this.endpointService.myEndpoint.userId) {
          let chatRoom = Companion.getChatRoomService().findExistingRoomByTargets(this.conferenceService.currentActiveConference.active);
          if (!chatRoom) {
            Companion.getChatRoomService().createChatRoomByMessageTargets(this.conferenceService.currentActiveConference.active, this.endpointService.myEndpoint.rtcId);
          }
        }
        break;
      case ActiveConferenceState.conference_disconnecting:
        // disconnect all the peers in the conference
        this.disconnectAllPeers(peerConnections);
      break;
      case ActiveConferenceState.conference_closed:
        // delete the conf state machine..
        this.conferenceService.conferenceSmMap.delete(stateChange.confId);
        this.TRACE("conference closed", stateChange.confId);
        
        if (this.conferenceService.countEmptyOwnedConferences() > 1) 
        {
          // delete this conference if we don't need it.
          let closedConf : ActiveConference = this.conferenceService.findActiveConferenceById(stateChange.confId);
          // delete this conference off the server as well... if empty
          if(closedConf && closedConf.everyone.length == 0)
          {
            this.requestConferenceDelete(closedConf,
              (data) => {
                this.conferenceService.conferences.delete(closedConf.id);
                this.TRACE("conference deleted from server", data)
              },
              (error) => {this.TRACE("Failed to delete conf!")
                // 
              }
            );
          }
        }
        // if this was the active conference, clear it here
        if(stateChange.confId == this.conferenceService.activeConference)
        {
          this.conferenceService.activeConference = null;
          this.conferenceService.sharedFilesUpdateHandler([]); // Update someone about files offered.
        }
      break;
    }
  }

  /**
   * Process a monitor state change 
   * (connect or disconnect peers as they are added and removed from the conference)
   * @param stateChange 
   */
  private processMonitorStateChange(stateChange : ActiveConferenceEventResult) : MonitorConferenceActivityStatus
  {
    // get the conference
    let conference : MonitorConference = this.conferenceService.monitors.get(stateChange.confId);

    if(conference == null)
    {
      console.warn("State update for conference that no longer exists!", stateChange.confId) 
      return;
    }

    // if our monitor conference changed states, update the videos
    if(stateChange.newState != stateChange.oldState)
    {
      this.TRACE("Monitor Conference state update", 
      {confId: stateChange.confId, state : ActiveConferenceState[stateChange.newState]});
      // call this before handling because the proccessesing may eliminate the data that we need.
      this.conferenceService.emitRemoteVideoUpdateEvent();
    }  

    let possiblePartyIds = _.map(conference.everyone, (ep : IEndpointRef) => { return ep.rtcId});
    let peerConnections = _.filter([...Companion.getConferenceService().peerSmMap.values()], 
      (peer : PeerConnectionSm) => { return peer.confId == stateChange.confId});

    // track the peer connections that we have for the conference... but are no longer in the
    // possible endpoint list.
    let removedConnections = _.filter(peerConnections, 
      (peer : PeerConnectionSm) => { return (stateChange.newState == ActiveConferenceState.conference_held) || 
      !possiblePartyIds.includes(peer.peerId) });

    // check the active conference list and disconnect any that are no longer active...
    _.forEach(removedConnections, (peer : PeerConnectionSm) =>
    {
      this.disconnectPeer(peer);
    });

    let resultingStatus = MonitorConferenceActivityStatus.none;
    // am I monitoring?
    let monitoring = !!_.find(conference.observers, (ep) => {return ep.rtcId == this.endpointService.myEndpoint.rtcId});

    // handle state changes 
    switch(stateChange.newState)
    {
      case ActiveConferenceState.conference_alone:
        // nothing to do here...
      break;
      case ActiveConferenceState.conference_held:
        // nothing to do here...
      break;
      case ActiveConferenceState.conference_connecting:

        // Only look for peer connections in the correct target pool
        let targets = monitoring ? conference.everyone : conference.observers;
      
        // make sure we connect to all the target peers in the conference.
        // update peer state machines for every target peer in the conference
        forEach(targets, (endpoint : IEndpointRef) => 
        {
          // make sure we don't target ourselves.
          if(endpoint.rtcId != this.endpointService.myEndpoint.rtcId)
          {
            let peerSm : PeerConnectionSm = this.conferenceService.peerSmMap.get(endpoint.rtcId);
            if(peerSm && peerSm.state == PeerConnectionState.peer_not_connected)
            {
              if(peerSm.isVoicePeer)
              {
                peerSm.event(PeerConnectionEvent.conn_req);
                // we need to handle connecting to this peer by using the voice service
                this.voiceService.joinVoiceConference(endpoint.rtcId, conference.ownerId);
                this.notifyVoiceConference();
              }
              else if(this.endpointService.myEndpoint.rtcId > endpoint.rtcId)
                {
                  // it falls to the greater RTC id to initiate the call
                  // Move to connect.
                  peerSm.event(PeerConnectionEvent.conn_req);
                  this.callPeer(endpoint).then(() =>
                  {
                    // Wait for completion or failure by event.
                    this.TRACE("peer conn attempting...");
                  }).
                  catch((reason : any) =>
                  {
                    this.TRACE(`peer conn attempt failed to ${endpoint.rtcId}:`, reason)
                    peerSm.event(PeerConnectionEvent.conn_fail);
                  });
                }
                else
                {
                  // else we wait for the call from the greater rtcId
                  peerSm.event(PeerConnectionEvent.conn_wait);
                }
            }
          }
        });
      break; 
      case ActiveConferenceState.conference_connected:
        // Update our current activity status if we are now connected.
        if (monitoring) {
          resultingStatus = MonitorConferenceActivityStatus.monitoring;
        } else {
          resultingStatus = MonitorConferenceActivityStatus.observed;
        }
        break;
      case ActiveConferenceState.conference_disconnecting:
        // disconnect all the peers in the conference
        this.disconnectAllPeers(peerConnections);
      break;
      case ActiveConferenceState.conference_closed:
        resultingStatus = MonitorConferenceActivityStatus.ended;
        // delete the conf state machine..
        this.conferenceService.conferenceSmMap.delete(stateChange.confId);
        this.TRACE("monitor deleted", stateChange.confId);
      break;
    }

    return resultingStatus;
  }
  
  /**
   * input disconnect to all peer state machines
   * disconnect All peers 
   */
  private disconnectAllPeers(peers : PeerConnectionSm[])
  {
    _.forEach(peers, (peer : PeerConnectionSm) =>
    {
      this.disconnectPeer(peer);
    });
  }

  /**
   * disconnect a single peer.
   */
  private disconnectPeer(peer : PeerConnectionSm)
  {
    // if we are not connected at all yet, just set us to closed 
    if(peer.state == PeerConnectionState.peer_not_connected || 
      peer.state == PeerConnectionState.peer_connect_wait)
    {
      this.TRACE("Peer not connected, close");
      peer.event(PeerConnectionEvent.closed);
    } 
    else
    {
      this.TRACE("Disconnect peer", peer.peerId);
      if(peer.isVoicePeer)
      {
        // for now just disconnect ourselves because we only talk to 1 voice EP;
        this.voiceService.disconnect();
        peer.event(PeerConnectionEvent.closed);
        this.notifyVoiceConference();
      }
      else
      {
        this.rtcService.hangupPeer(peer.peerId);
      }
    }
  }

  /**
   * Call another peer endpoint to esthablish connection to them
   *
   * @param {IEndpoint} ep - endpoint to call
   * @param {string} inviterRTCId - rtcId of the client that send the invitation. This parameter is necessary only for the guest user
   * @returns {Promise<any>}
   */
  private callPeer(ep: IEndpoint): Promise<any> {
    this.conferenceService.alertHandler(AlertCode.makeCall,
      `calling... ${ep.rtcId} (${ep.name})`,
      AlertLevel.info);
    
    this.conferenceService.alertHandler(AlertCode.establishingConnectionWith, ep.rtcId, AlertLevel.info);
    return this.rtcService.establishConnection(ep.rtcId)
    .catch((err: Error) => {
      // if enabled send logs to logs collector server
      if (LogUtil.getLogInstance().logsSettings.serverConnection) {
        
        this.logService.sendLogs().catch((error) => console.error("Failed to sendLogs: ", error));
      }
      console.warn("Failed to establish connection:", err);
      return Promise.reject(err);
    });
  }

  getSnapshotFileBasename(): string {
    return FileUtility.generateFileName(
      this.localizationService.getValueByPath(".snapshot_panel.filenamePattern") ||
        "[DATE]_[TIME]_[REMOTE_NAME]_[LOCAL_NAME]",
      this.localizationService, "");
  }

  /*
   * handler for creating an alert in this component when requsted from the underlying code.
   * See AlertHandlerCallback from alert.interface.ts
   * Conditionally, make a dialog with AlertService.createCustomDialog, see bootbox buttons object for more details.
   */
  public alertHandler(
    alertCode: AlertCode,
    alertText?: string,
    alertLevel: AlertLevel = AlertLevel.warning,
    options?: AlertHandlerCallbackOptions
  ): void {
    this.alertService.createAlert(alertCode, alertText, alertLevel, options?.seconds || 5);
    // Check if we want to create a dialog for this alert.
    let dialogOptions: BootboxDialogOptions = options?.dialogOptions;
    // Don't dialog for errors on non-operators.
    if (dialogOptions && Companion.getEndpointService().isOperator(Companion.getEndpointService().myEndpoint)) {
      AlertService.createCustomDialog(dialogOptions);
    }
  }

  /**
   * cancel a call out to a peer
   */
  cancelCall(endpointRtcId: string) {
    const successCallback = () => {
      if (this.endpointService.connectingOperator && !this.endpointService.connectingOperator.isCaller && !this.endpointService.isOperator(this.endpointService.getEndpointById(endpointRtcId))) {
        this.rtcService.rtcClient.connectionEventHandler.callFailureListener(this.endpointService.connectingOperator.endpoint.rtcId, AlertCode.callDisconnected, null);
      }
      this.endpointService.connectingOperator = null;
      // Check for active confs.
      if (this.conferenceService.countParticipatingConferences()) {
        // We should be in a conferencing status.
        this.endpointService.myEndpoint.status = PresenceStatus.busy;
      } else {
        // go away to match our server state.
        this.endpointService.myEndpoint.status = PresenceStatus.away;
      }
    };

    const failCallback = () => {
      // We will likely get something else back that moves us, we don't have to do this now.
      console.log("Cancel active call failed. Probably no longer cancellable.");
    };

    this.endpointService.cancelCall(successCallback, failCallback);
  }
  
  /**
   * This operation conditionally fetches the endpoints used for stats information on the display.
   * If it is ourself, we actually want to retrieve the active conference active list.
   * If it is not ourself, we just want to see that single endpoint.
   * @param endpoint The endpoint to retrieve the endpoints that might have stats.
   * @returns The list of endpoint (ids) to display information for.
   */
  getStatsDisplayEndpointsList(endpoint: IEndpointRef): string[] {
    if (!endpoint) {
      return [];
    }
    // Is this me?
    if (this.endpointService.myEndpoint.rtcId == endpoint.rtcId) {
      return _.map(this.conferenceService.currentActiveConference?.active, (ep) => {
        return ep.rtcId;
      });
    } else {
      return [endpoint.rtcId];
    }
  }

  /**
   * Get a comma seperated list of endpoint names in conference with this target endpoint
   * (if it is a guest of this conference and not the owner, just return 
   * the conference's owner name)
   * @param endpoint the endpoint to query
   * @returns a list of names assocaited with the conference
   */
  getTargetEndpointsList(endpoint: IEndpointRef): string {
    let targetEndpointList = "";

    let conf : IActiveConference = this.conferenceService.getConferenceFromEndpoint(endpoint)

    if(conf)
    {
        if(conf.ownerId !== endpoint.userId)
        {
          // if this ep is NOT the master of this sub conf, then specify just the master's name
          targetEndpointList = conf.ownerName;
        }
        else
        {
          // if this agent is the master of this sub conf, then include all the active participants except the owner
          targetEndpointList = _.map(_.compact(_.filter(conf.active,
            (ep: IEndpoint) => {
              return (ep.rtcId != endpoint.rtcId);
            })),
            "name").join(', ');
        }
    }

    return targetEndpointList;
 }

 getHoldEndpointsList(endpoint: IEndpointRef): string {
   // Returns all endpoints on hold with the given endpoint
   let heldParties: IEndpointRef[] = [];
   _.forEach(this.conferenceService.findActiveConferencesByOwnerId(endpoint.userId),
     (conf: ActiveConference) => {
       heldParties = _.concat(heldParties, conf.held);
     });
   return _.map(_.uniq(heldParties), "name").join(', ');
 }

 /**
  * Reset the service and clear all stored data associated with the service...
  * use this only on component reload
  */
 public reset() : void
 {
    // nuke any peer connections we may have
    this.rtcService.hangupAll();
    // clear the conferences 
    this.conferenceService.conferences.clear();
    // clear the queues...
    this.conferenceService.conferenceSmMap.clear();
    this.endpointService.clearTimers();
    // clear endpoints... 
    this.endpointService.endpoints.clear();
    // clear SM map...
    this.conferenceService.peerSmMap.clear();
    // clear queue map
    this.conferenceService.queues.clear();
    // Clear chatroom service data
    Companion.getChatRoomService().clearAll();
 }

 
  /**
   * Update my endpoint status by conference status. if our active conference 
   * is closed we return to available (or start ready timer if we are an operator)
   */
  updateMyEndpointStatusByConferenceStatus(failure: boolean = false): void {
    //verify we have an active conference,
    let activeId = this.conferenceService.activeConference;
    let myEndpoint : IEndpoint = this.endpointService.myEndpoint;
    if(!activeId)
    {
      console.log("no active conf to update");
      return;
    }

    if (this.conferenceService.conferenceSmMap.has(this.conferenceService.activeConference)) {
      switch(this.conferenceService.conferenceSmMap.get(activeId).state)
      {
        case ActiveConferenceState.conference_connected:
          myEndpoint.status = PresenceStatus.busy;
        break;
        case ActiveConferenceState.conference_alone:
          myEndpoint.status = PresenceStatus.alone_in_conf;
          break;
        case ActiveConferenceState.conference_held:
          myEndpoint.status = PresenceStatus.onhold;
        break;
        case ActiveConferenceState.conference_closed:
          // Notify the business logic if possible.
          if(this.notifyConferenceComplete) {
            this.notifyConferenceComplete();
          } else { // I guess we just go available then.
            this.endpointService.markClientReady();
          }
        break;
        default:
          //
        break;
      }
    }
  }
  
  /**
   * start ready timer if necessary
   * @param currentStatus
   */
  startReadyTimer(currentStatus: PresenceStatus) {
    if (currentStatus === PresenceStatus.ready && !this.updateToAvailableTimer) {
      this.updateToAvailableTimer =
        setTimeout(() => {
          this.readyTimerFired();
        }, this.readyTimeLapse * 1000);
    }
  }

  /**
   * clear update to available timer
   */
  clearUpdateToAvailableTimer() {
    clearTimeout(this.updateToAvailableTimer);
    this.updateToAvailableTimer = null;
  }

  /**
   * ready timer has fired
   */
  readyTimerFired() {
    this.clearUpdateToAvailableTimer();
    if (this.endpointService.myEndpoint.status === PresenceStatus.ready) {
      this.endpointService.markClientReady();
    }
  }
  
  /**
   * Determine if we have the maximum number of endpoints participanting in a conference 
   * for this session, (Endpoints we are being monitored by do not count)
   * @returns flag indicating if we are (or exceed) configured maximum
   */
  maxParticipantsReached() : boolean
  {
    // don't count endpoints we are broadcasting too (being monitored by)
    let totalParticipantCount : number = this.conferenceService?.currentActiveConference?.active?.length || 0;
    return ( totalParticipantCount >=
        (this.localizationService.myLocalizationData.participant_panel.maxParticipantsToConnect || 2));
  }
}
