/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Injectable } from "@angular/core";
import { Browser, Companion } from "companion";
import { HelperWizardComponent } from "./helper-wizard.component";
import { MediaUtil } from "companion";
import { RestService } from "../shared/services/rest.service";

const MIC_TEST_RECORD_SECONDS: number = 3;

@Injectable()
export class HelperService {
  /**
   * flag if microphone is in test. recording and replay is happening
   */
  isMicTesting: boolean;

  /**
   * the signalling ip to use for network testing
   */
  networkTestingIP: string;

  /**
   * timer for mic record and playback
   */
  recordAndPlaybackTimer: any;

  /**
   * constructor
   * @param restService: RestService - rest service by dependency injection
   */
  constructor() {}

  /**
   * test browser
   * @param wizard: wizard component
   * @param step: string - the step keyword
   */
  testBrowserPromise(wizard: HelperWizardComponent, step: string): Promise<boolean> {
    // suggest installation
    let installUrl = Browser.getDownloadLink();
    // If on Android, try redirect to Chrome
    let showOpenInChromeLink = Browser.whichOS() === "AndroidOS" && Browser.whichBrowser() !== "Chrome";
    let openInChromeLink = "intent:" + window.location.href.substr(window.location.protocol.length) + "#Intent;";
    openInChromeLink += "scheme=https;";
    openInChromeLink += "package=com.android.chrome;";
    openInChromeLink += "S.browser_fallback_url=" + encodeURIComponent(Browser.getDownloadLink()) + ";";
    openInChromeLink += "end";
    wizard.viewModel.tests.browser.solutionMessage =
      wizard.localizationService.browserNotSupportedMessage +
      (installUrl ? " " + wizard.localizationService.browserInstallMessage : "");
    wizard.viewModel.tests.browser.solutionConfirmButtonText = showOpenInChromeLink
      ? "Open in Chrome"
      : installUrl
      ? "Install"
      : "OK";
    wizard.viewModel.tests.browser.solutionCancel = () => {
      delete wizard.viewModel.tests.browser.solutionMessage;
    };
    wizard.viewModel.tests.browser.solutionConfirm = () => {
      if (showOpenInChromeLink) {
        window.open(
          openInChromeLink,
          "targetWindow",
          "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1280,height=720"
        );
      } else if (installUrl) {
        window.open(
          installUrl,
          "targetWindow",
          "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1280,height=720"
        );
      }
    };
    return Promise.resolve(Browser.isBrowserSupport());
  }

  /**
   * stop test browser
   */
  stopTestBrowser() {
    // nothing needed here
  }

  /**
   * test camera
   * @param wizard: wizard component
   * @param step: string - the step keyword
   */
  testCameraPromise(wizard: HelperWizardComponent, step: string): Promise<boolean> {
    return Companion.getRTCService()
    .getCameraStream(false)
    .then((stream: MediaStream) => {
      return Companion.getDeviceService().getDevices(1);
    })
    .then(() => {
      let p = Promise.resolve(false);
      for (let i = 0; i < Companion.getDeviceService().cameraOptions.length; ++i) {
        p = p.then((result: boolean) => {
          if (result) {
            return Promise.resolve(true);
          }
          Companion.getDeviceService().selectPrimaryCamera(Companion.getDeviceService().cameraOptions[i]);
          return Companion.getRTCService()
          .getCameraStream(true)
          .then((stream: MediaStream) => {
            return Companion.getRTCService().setVideoSrcByElement(wizard.pip.nativeElement, stream)
            .then(() => {
              wizard.pip.nativeElement.volume = 0;
              wizard.pip.nativeElement.muted = true;
              return wizard.promptConfirmation(
                step,
                ["Do you see your desired camera view here?", Companion.getDeviceService().cameraOptions[i].label],
                i < Companion.getDeviceService().cameraOptions.length - 1
              );
            });
          });
        })
        .catch((error) => {
          return Promise.resolve(false);
        });
      }
      // P does not reject, only resolve false
      return p.then((result: boolean) => {
        if (result) {
          return Promise.resolve(true);
        } else {
          return Promise.reject(new Error("Fail to access camera. All devices been tested."));
        }
      });
    });
  }

  /**
   * stop test camera
   */
  stopTestCamera() {
    // nothing needed here
  }

  /**
   * test microphone
   * @param wizard: wizard component
   * @param step: string - the step keyword
   */
  testMicrophonePromise(wizard: HelperWizardComponent, step: string): Promise<boolean> {
    return Companion.getRTCService()
    .getCameraStream(false)
    .then((stream: MediaStream) => {
      return Companion.getDeviceService().getDevices(1);
    })
    .then(() => {
      let p = Promise.resolve(false);
      for (let i = 0; i < Companion.getDeviceService().micOptions.length; ++i) {
        p = p.then((result: boolean) => {
          this.stopTestMicrophone(wizard);
          if (result) {
            return Promise.resolve(true);
          }
          Companion.getDeviceService().selectMicrophone(Companion.getDeviceService().micOptions[i]);
          return Companion.getRTCService()
          .getCameraStream(true)
          .then((stream: MediaStream) => {
            wizard.viewModel.tests.microphone.stream = stream;
            this.recordAndPlay(stream, MIC_TEST_RECORD_SECONDS);
            return wizard.promptConfirmation(
              step,
              [
                "Do you hear the audio playback from your speaker?",
                Companion.getDeviceService().micOptions[i].label
              ],
              i < Companion.getDeviceService().micOptions.length - 1
            );
          })
          .catch((error) => {
            return Promise.resolve(false);
          });
        });
      }
      return p.then((result: boolean) => {
        this.stopTestMicrophone(wizard);
        if (result) {
          return Promise.resolve(true);
        } else {
          return Promise.reject(new Error("Fail to access microphone. All devices been tested."));
        }
      });
    });
  }

  recordAndPlay(stream, seconds: number = 5) {
    if (!stream) {
      return;
    }
    clearTimeout(this.recordAndPlaybackTimer);
    MediaUtil.recordForAFewSeconds(stream, seconds, true, stream.id + Date.now())
    .then((data: Blob) => {
      let replaySource = URL.createObjectURL(data);
      MediaUtil.startSpeakerTestAudio(null, replaySource);
    })
    .catch((error: any) => {
      console.log(`Failed to recordForAFewSeconds: ${JSON.stringify(error)}`);
    });
    this.recordAndPlaybackTimer = setTimeout(() => {
      this.recordAndPlay(stream, seconds);
    }, seconds * 1000);
  }

  /**
   * stop test microphone
   * @param wizard: wizard component
   */
  stopTestMicrophone(wizard: HelperWizardComponent) {
    this.isMicTesting = false;
    delete wizard.viewModel.tests.microphone.stream;
    clearTimeout(this.recordAndPlaybackTimer);
    MediaUtil.stopSpeakerTestAudio();
  }

  /**
   * test speaker
   * @param wizard: wizard component
   * @param step: string - the step keyword
   */
  testSpeakerPromise(wizard: HelperWizardComponent, step: string): Promise<boolean> {
    return Companion.getRTCService()
    .getCameraStream(false)
    .then((stream: MediaStream) => {
      return Companion.getDeviceService().getDevices(1);
    })
    .then(() => {
      let p = Promise.resolve(false);
      if (Companion.getDeviceService().speakerOptions.length > 0) {
        for (let i = 0; i < Companion.getDeviceService().speakerOptions.length; ++i) {
          p = p.then((result: boolean) => {
            this.stopTestSpeaker(wizard);
            if (result) {
              return Promise.resolve(result);
            }
            Companion.getDeviceService().selectSpeaker(Companion.getDeviceService().speakerOptions[i]);
            MediaUtil.startSpeakerTestAudio();
            wizard.viewModel.tests.speaker.stream = Companion.getRTCClient().speakerTestAudioDestinationNode.stream;
            return wizard.promptConfirmation(
              step,
              [
                "Do you hear the ringtone sound from your desired speaker?",
                Companion.getDeviceService().speakerOptions[i].label
              ],
              i < Companion.getDeviceService().speakerOptions.length - 1
            );
          });
        }
      } else {
        p = p.then((result: boolean) => {
          MediaUtil.startSpeakerTestAudio();
          wizard.viewModel.tests.speaker.stream = Companion.getRTCClient().speakerTestAudioDestinationNode.stream;
          return wizard.promptConfirmation(
            step,
            [
              "Do you hear the ringtone sound from your desired speaker?"
            ]
          );
        });
      }
      return p.then((result: boolean) => {
        this.stopTestSpeaker(wizard);
        if (result) {
          return Promise.resolve(true);
        } else {
          return Promise.reject(new Error("Fail to access speaker. All devices been tested."));
        }
      });
    });
  }

  /**
   * stop test speaker
   * @param wizard: wizard component
   */
  stopTestSpeaker(wizard: HelperWizardComponent) {
    delete wizard.viewModel.tests.speaker.stream;
    MediaUtil.stopSpeakerTestAudio();
  }
}

