/**
 * Copyright Compunetix Incorporated 2017-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { RestService } from "../../../client/scripts/shared/services/rest.service";
import { FormService } from "../form/form.service";
import {
  IConfig,
  IParameter,
  ParameterType,
  ConfigUtil,
  CollectLogsMode,
  IClientSettings,
  IVccConfig
} from "companion";
import { Injectable } from "@angular/core";
import {Store} from "client/scripts/Store/store.service";

@Injectable()
/**
 * config methods delegate
 */
export class ConfigService {
  constructor(private restService: RestService, private store: Store) {}

  /**
   * get system configuration
   */
  getConfig(): Promise<IConfig> {
    return new Promise((resolve: (data: IConfig) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getConfig", {})
      .subscribe(
        (data: IConfig) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get system configuration
   */
  getClientSettings(): Promise<IClientSettings> {
    return new Promise((resolve: (data: IClientSettings) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getClientSettings", {})
      .subscribe(
        (data: IClientSettings) => {
          this.store.update("settings", data);
          resolve(data);
        },
        (error: any) => reject(error)
      );
    });
  }

  /**
   * get VCC configuration
   */
  getVCCSettings(): Promise<IVccConfig> {
    return new Promise((resolve: (data: IVccConfig) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getVCCSettings", {})
      .subscribe(
        (data: IVccConfig) => {
          resolve(data);
        },
        (error: any) => reject(error)
      );
    });
  }

  /**
   * save config details
   */
  saveConfig(config: IConfig): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/saveConfig", config)
      .subscribe(
        (data: string) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * server restart
   */
  serverRestart(): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/serverRestart", {})
      .subscribe(
        (data: string) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get default config template parameters
   */
  getDefaultConfigParameters(): { [key: string]: IParameter } {
    return {
      ice_enabled: {
        key: "ice_enabled",
        title: "ICE Configuration",
        type: ParameterType[ParameterType.hidden]
      },
      ice_servers: {
        key: "ice_servers",
        title: "ICE Servers",
        type: ParameterType[ParameterType.account_list]
      },
      ssl_enabled: {
        key: "ssl_enabled",
        title: "SSL Configuration",
        type: ParameterType[ParameterType.hidden]
      },
      ssl_cert: {
        key: "ssl_cert",
        title: "SSL Cert",
        type: ParameterType[ParameterType.string]
      },
      ssl_key: {
        key: "ssl_key",
        title: "SSL Key",
        type: ParameterType[ParameterType.string]
      },
      ssl_chain: {
        key: "ssl_chain",
        title: "SSL Chain",
        type: ParameterType[ParameterType.string]
      },
      web_enabled: {
        key: "web_enabled",
        title: "Web Configuration",
        type: ParameterType[ParameterType.hidden]
      },
      web_domain: {
        key: "web_domain",
        title: "Domain",
        type: ParameterType[ParameterType.string]
      },
      web_proxy_paths: {
        key: "web_proxy_paths",
        title: "Web Server Proxy Paths",
        type: ParameterType[ParameterType.key_value_list],
        options: { key: "source", value: "target" }
      },
      web_redirect_paths: {
        key: "web_redirect_paths",
        title: "Web Server Redirect Paths",
        type: ParameterType[ParameterType.key_value_list],
        options: { key: "source", value: "target" }
      },
      web_cors_white_list: {
        key: "web_cors_white_list",
        title: "Web Server CORS White List Origins",
        type: ParameterType[ParameterType.list]
      },
      web_strict_content_security: {
        key: "web_strict_content_security",
        title: "Strict Content Security",
        type: ParameterType[ParameterType.checkbox]
      },
      web_csp_white_list: {
        key: "web_csp_white_list",
        title: "Web Server CSP White List Origins",
        type: ParameterType[ParameterType.list]
      },
      web_embed_white_list: {
        key: "web_embed_white_list",
        title: "Web Server Embed White List Origins",
        type: ParameterType[ParameterType.list]
      },
      web_use_http_redirect: {
        key: "web_use_http_redirect",
        title: "Web Server Use HTTP Redirect",
        type: ParameterType[ParameterType.checkbox]
      },
      web_use_secure_cookie: {
        key: "web_use_secure_cookie",
        title: "Web Server Use Secure Cookie",
        type: ParameterType[ParameterType.checkbox]
      },
      web_entry_submit_limiter: {
        key: "web_entry_submit_limiter",
        title: "Max Number of Entry Submit Requests per 15 minutes from same IP",
        type: ParameterType[ParameterType.number]
      },
      web_version_number: {
        key: "web_version_number",
        title: "Web Server Version Number",
        type: ParameterType[ParameterType.string]
      },
      web_http_port: {
        key: "web_http_port",
        title: "Http port",
        type: ParameterType[ParameterType.number]
      },
      web_https_port: {
        key: "web_https_port",
        title: "Https port",
        type: ParameterType[ParameterType.number]
      },
      web_server_disconnect_timeout: {
        key: "web_server_disconnect_timeout",
        title: "Server Disconnect Timeout After n Milliseconds",
        type: ParameterType[ParameterType.number]
      },
      ftp_enabled: {
        key: "ftp_enabled",
        title: "FTP Configuration",
        type: ParameterType[ParameterType.hidden]
      },
      ftp_protocol: {
        key: "ftp_protocol",
        title: "FTP Protocol",
        options: { FTP: "FTP", SFTP: "SFTP" },
        type: ParameterType[ParameterType.dropdown]
      },
      ftp_host: {
        key: "ftp_host",
        title: "FTP Host Url",
        type: ParameterType[ParameterType.string]
      },
      ftp_port: {
        key: "ftp_port",
        title: "FTP Port",
        type: ParameterType[ParameterType.number]
      },
      ftp_account_username: {
        key: "ftp_account_username",
        title: "FTP Account Username",
        type: ParameterType[ParameterType.string]
      },
      ftp_account_password: {
        key: "ftp_account_password",
        title: "FTP Account Password",
        type: ParameterType[ParameterType.string]
      },
      ftp_upload_folder_path: {
        key: "ftp_upload_folder_path",
        title: "FTP Upload Folder Path",
        type: ParameterType[ParameterType.string]
      },
      db_enabled: {
        key: "db_enabled",
        title: "DB Configuration",
        type: ParameterType[ParameterType.hidden]
      },
      db_use_pubsub: {
        key: "db_use_pubsub",
        title: "DB Session Storage Use PubSub",
        type: ParameterType[ParameterType.checkbox]
      },
      db_log_reports: {
        key: "db_log_reports",
        title: "DB Log User reports to DB",
        type: ParameterType[ParameterType.checkbox]
      },
      db_log_client_events: {
        key: "db_log_client_events",
        title: "Log Client Events to DB",
        type: ParameterType[ParameterType.checkbox]
      },
      db_log_chat_messages: {
        key: "db_log_chat_messages",
        title: "Log Chat Messages to DB",
        type: ParameterType[ParameterType.checkbox]
      },
      db_historical_ttl: {
        key: "db_historical_ttl",
        title: "DB historical data TTL (days)",
        type: ParameterType[ParameterType.number]
      },      
      vcc_enabled: {
        key: "vcc_enabled",
        title: "VCC Configuration",
        type: ParameterType[ParameterType.hidden]
      },
      vcc_ewt_moving_average: {
        key: "vcc_ewt_moving_average",
        title: "Queue Status: EWT moving average time window (seconds)",
        type: ParameterType[ParameterType.number]
      },
      vcc_ewt_refresh_period: {
        key: "vcc_ewt_refresh_period",
        title: "Queue Status: EWT calculation refresh period (seconds)",
        type: ParameterType[ParameterType.number]
      },
      vcc_calls_statistics_save: {
        key: "vcc_calls_statistics_save",
        title: "VCC Calls Statistics Save",
        type: ParameterType[ParameterType.checkbox]
      },
      vcc_calls_statistics_rate: {
        key: "vcc_calls_statistics_rate",
        title: "VCC Calls Statistics Save Rate (in minutes, default 1, maximum 15)",
        type: ParameterType[ParameterType.number]
      },
      vcc_calls_client_refresh_rate: {
        key: "vcc_calls_client_refresh_rate",
        title: "VCC Calls Client Refresh Rate (in seconds, default 1)",
        type: ParameterType[ParameterType.number]
      },
      collect_logs_enabled: {
        key: "collect_logs_enabled",
        title: "Browser Logs Configuration",
        type: ParameterType[ParameterType.checkbox]
      },
      collect_logs_mode: {
        key: "collect_logs_mode",
        title: "Mode",
        type: ParameterType[ParameterType.dropdown],
        options: { Server2Server: CollectLogsMode.Server2Server, Client2Server: CollectLogsMode.Client2Server },
      },
      collect_logs_server_url: {
        key: "collect_logs_server_url",
        title: "Log Collector Server URL",
        type: ParameterType[ParameterType.string],
      },
      collect_logs_auth_key: {
        key: "collect_logs_auth_key",
        title: "Authorization Key/API Key",
        type: ParameterType[ParameterType.password],
      },
      collect_logs_customer: {
        key: "collect_logs_customer",
        title: "Customer",
        type: ParameterType[ParameterType.string],
      },
      collect_logs_environment: {
        key: "collect_logs_environment",
        title: "Environment",
        type: ParameterType[ParameterType.string],
      },
      collect_logs_application: {
        key: "collect_logs_application",
        title: "Application",
        type: ParameterType[ParameterType.string],
      },
      collect_logs_max_items: {
        key: "collect_logs_max_items",
        title: "Maximum Items Collected [Max 10000]",
        type: ParameterType[ParameterType.number],
        value: 10000
      },
      collect_logs_webrtc_statistics: {
        key: "collect_logs_webrtc_statistics",
        title: "Enable WebRTC Statistics",
        type: ParameterType[ParameterType.checkbox]
      },
      collect_logs_stat_frequency: {
        key: "collect_logs_stat_frequency",
        title: "Statistics Collection Frequency (in milliseconds)",
        type: ParameterType[ParameterType.number],
        value: 1000
      },
      collect_logs_trigger_peer_connection: {
        key: "collect_logs_trigger_peer_connection",
        title: "Send Logs on Failed Peer Connection",
        type: ParameterType[ParameterType.checkbox]
      },
      collect_logs_trigger_server_connection: {
        key: "collect_logs_trigger_server_connection",
        title: "Send Logs on Failed Server Connection",
        type: ParameterType[ParameterType.checkbox]
      },
      collect_logs_trigger_ice_negotiation: {
        key: "collect_logs_trigger_ice_negotiation",
        title: "Send Logs on Failed ICE Negotiation",
        type: ParameterType[ParameterType.checkbox]
      },
      collect_logs_trigger_device_acquisition: {
        key: "collect_logs_trigger_device_acquisition",
        title: "Send Logs on Failed Device Acquisition",
        type: ParameterType[ParameterType.checkbox]
      },
      collect_logs_trigger_call_complete_trace: {
        key: "collect_logs_trigger_call_complete_trace",
        title: "Send Logs on Call Complete (Data Tracing)",
        type: ParameterType[ParameterType.checkbox]
      }
    };
  }

  /**
   * convert config data to parameters
   */
  convertConfigToParameters(data: IConfig): { [key: string]: IParameter } {
    let result: {
      [key: string]: IParameter;
    } = this.getDefaultConfigParameters();
    ConfigUtil.setFormValuesByConfigData(result, data);
    result["ice_enabled"].value = !!data.ICEConfig;
    result["ssl_enabled"].value = !!data.SSLConfig;
    result["web_enabled"].value = !!data.WebConfig;
    result["ftp_enabled"].value = !!data.FTPConfig;
    result["db_enabled"].value = !!data.DBConfig;
    result["vcc_enabled"].value = !!data.VccConfig;
    result["collect_logs_enabled"].value = !!data.CollectLogs;
    return result;
  }

  convertParametersToConfig(parameters: { [key: string]: IParameter }): IConfig {
    let config: IConfig = {};
    let parameterValues: {
      [key: string]: any;
    } = FormService.collectParameterValues(parameters);
    _.forEach(parameterValues, (value: any, parameterKey: string) => {
      ConfigUtil.setConfigValueByFormKey(config, parameterKey, value);
    });
    _.forEach(_.keys(parameters), (parameterKey: string) => {
      if (parameterKey.endsWith("_enabled")) {
        ConfigUtil.setConfigValueByFormKey(config, parameterKey, parameterValues[parameterKey]);
      }
    });
    _.forEach(_.keys(config), (parameterKey: string) => {
      if (config[parameterKey] == null) {
        config[parameterKey] = {};
      }
    });

    return config;
  }
}
