<!--
  Copyright Compunetix Incorporated 2022-2023
          All rights reserved
  This document and all information and ideas contained within are the
  property of Compunetix Incorporated and are confidential.
 
  Neither this document nor any part nor any information contained in it may
  be disclosed or furnished to others without the prior written consent of:
          Compunetix Incorporated
          2420 Mosside Blvd
          Monroeville, PA 15146
          http://www.compunetix.com
 
   Author:  kbender
-->
<div [hidden]="!isSharingContent" (resized)="onResized($event)" style="z-index: 999"> 
  <!-- file selector -->
  <div style="margin-top: 3em" class="text-center" [hidden]="fileSelected">
    <input
      type="file"
      (change)="fileUploaded($event)"
      placeholder="PDF file"
      accept=".pdf"
      id="contentFileInput"
    >
  </div>
  <div
  class="loading-screen"
  [hidden]="!loading"
  >
    <img class="spinner" src="images/spinner.gif" />
      <span>Please wait for file to load.</span>
  </div>

  <!-- controls -->
  <div [hidden]="!pdfSrc || !fileSelected" class="top text-left" style="width: width/4; position:fixed; top: 80px; z-index: 10; font-size: 1.5rem;">
    <button id="pen-btn" [ngClass]="(penSelected ? 'btn-primary' : 'btn-secondary')" title="Pen" (click)="switchToPenDraw();"><i aria-hidden="true" class="fa fa-pen"></i></button>
    <button id="high-btn" [ngClass]="(highlighterSelected ? 'btn-primary' : 'btn-secondary')" title="Highlighter" (click)="switchToHighDraw();"><i aria-hidden="true" class="fa fa-highlighter"></i></button>
    <!-- Erase button cannot function with pointer overlay <button id="erase-btn" (click)="switchToErase();">Erase</button> -->
    <button id="undo-btn" title="Undo" class="btn-secondary" (click)="undoDraw();"><i aria-hidden="true" class="fa fa-undo"></i></button>
    <button id="clear-btn" title="Clear" class="btn-secondary" (click)="clearDraw();"><i aria-hidden="true" class="fa fa-eraser"></i></button>
    <button id="page-prev" (click)="prevPage();"><i class="fa fa-square-caret-left"></i></button>
    <input id="page-num" type="text" (keyup)="setPage($event);" style="width: 2em" value={{pdfPage}}/>
    <label id="page-tot">/ {{pdfProxy?.numPages || 0}}</label>
    <button id="page-next" (click)="nextPage();"><i class="fa fa-square-caret-right"></i></button>
    <button id="pan-zoom" [ngClass]="(showPanAndZoom ? 'btn-primary' : 'btn-secondary')" (click)="panAndZoomButton();">P&Z</button>
    <button id="thumbnail-btn" [ngClass]="(showThumbnails ? 'btn-primary' : 'btn-secondary')" (click)="showThumbnailsButton();">T</button>
    <button [hidden]="!showThumbnails" id="window-begin" class="btn-primary" (click)="windowBegin();">|<</button>
    <button [hidden]="!showThumbnails" id="window-prev-set" class="btn-primary" (click)="windowPrevSet();"><<</button>
    <button [hidden]="!showThumbnails" id="window-prev-1" class="btn-primary" (click)="windowPrevSingle();"><</button>
    <button [hidden]="!showThumbnails" id="window-next-1" class="btn-primary" (click)="windowNextSingle();">></button>
    <button [hidden]="!showThumbnails" id="window-next-set" class="btn-primary" (click)="windowNextSet();">>></button>
    <button [hidden]="!showThumbnails" id="window-end" class="btn-primary" (click)="windowEnd();">>|</button>
  </div>
  <!-- thumbnail viewer -->
  <div *ngIf="pdfProxy" [hidden]="!pdfSrc || !fileSelected || !showThumbnails" class="thumbnail-gallery">
    <div>
      <div id="thumbs" class="child-container" [ngStyle]="{'display': 'flex', 'flex-direction': 'row'}" style="margin-top: 10px;">
          <div *ngFor='let _ of [].constructor(totalPages); let i = index'>
              <thumbnail-component 
                  [parentPdf]="pdfProxy"
                  [myPage]='$any(i+1)'
                  [thumbnailActive]="!thumbnailHidden(i+1)"
                  [hidden]="thumbnailHidden(i+1)"
                  (clicked)="thumbnailClicked($event)">
              </thumbnail-component>
          </div>
      </div>
    </div>
  </div>
  <!-- pan and zoom controls-->
  <div [hidden]="!pdfSrc || !fileSelected || !showPanAndZoom" class="pan-zoom-controls">
    <div class="container">
      <div class="child-container">
          <button id="zoom-out" (click)="zoomOut();"><i aria-hidden="true" class="fa fa-magnifying-glass-minus"></i></button>
          <button id="pan-up" (click)="panUp();"><i aria-hidden="true" class="fa fa-arrow-up"></i></button>
          <button id="zoom-in" (click)="zoomIn();"><i aria-hidden="true" class="fa fa-magnifying-glass-plus"></i></button>
      </div>
      <div class="child-container">
          <button id="pan-left" (click)="panLeft();"><i aria-hidden="true" class="fa fa-arrow-left"></i></button>
          <button id="pan-down" (click)="panDown();"><i aria-hidden="true" class="fa fa-arrow-down"></i></button>
          <button id="pan-right" (click)="panRight();"><i aria-hidden="true" class="fa fa-arrow-right"></i></button>
      </div>
  </div>
  </div>
  <!-- content area -->     
  <div [hidden]="!pdfSrc || !fileSelected" id="content-area" [style.width]="renderWidth + 'px'" [style.height]="renderHeight + 'px'">
      <pdf-viewer [src]="pdfSrc"
        [(page)]="pdfPage"
        [rotation]="0"
        [original-size]="false"
        [show-all]="false"
        [fit-to-page]="false"
        [zoom]="zoomScale"
        [render-text]="false"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        (pagechanging)="pagechanging($event)"
        [attr.width]="width"
        [attr.height]="height"
        (error)="onError($event)"
        (after-load-complete)="afterLoadComplete($event)"></pdf-viewer>
      <ng-whiteboard [options]="canvasOptions" 
          [selectedTool]="selectedTool"
          [strokeWidth]="strokeWidth"
          [strokeColor]="color"
          [fill]="color"
          (dataChange)="dataChange()"
          [canvasWidth]="width"
          [canvasHeight]="height"></ng-whiteboard>
      <canvas id="pointer-render"
        [attr.width]="width" 
        [attr.height]="height"></canvas>
  </div>
</div>
<!-- view area -->
<div id="view-area" style="display:none;">
  <canvas id="pdf-render" style="display:none;"
    [attr.width]="renderWidth" 
    [attr.height]="renderHeight"></canvas>
  <canvas id="whiteboard-render" style="display:none;"
    [attr.width]="renderWidth" 
    [attr.height]="renderHeight"></canvas>
  <canvas id="output-render" style="display:none;"
    [attr.width]="renderWidth" 
    [attr.height]="renderHeight"></canvas>
</div>