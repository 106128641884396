/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, NgZone } from "@angular/core";

import { IEndpoint, IEndpointService, PresenceStatus, Companion, IChatRoom } from "companion";
import { LocalizationService } from "../../localization/localization.service";

@Component({
  selector: "endpoint-select-item",
  templateUrl: "./endpoint-select-item.template.html"
})
/**
 * Endpoint selection view item
 */
export class EndpointSelectItemComponent {
  /**
   * current endpoint
   */
  @Input() endpoint: any;

  /**
   * current chat room
   */
  @Input() room: IChatRoom;

  /**
   * select event emitter
   */
  @Output("select") selectEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * unselect event emitter
   */
  @Output("unselect") unselectEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * presence status class for template use
   */
  public presenceStatusClass = PresenceStatus;

  private endpointService: IEndpointService = Companion.getEndpointService();

  myEndpoint: IEndpoint = this.endpointService.myEndpoint;

  constructor(private _ngZone: NgZone, public localizationService: LocalizationService) {
    // nothing needed here
  }

  /**
   * handler on selection changing event
   */
  onSelectionChanging() {
    if (this.endpoint.selected) {
      this.select();
    } else {
      this.unselect();
    }
  }

  /**
   * select this endpoint
   */
  select(): void {
    this.selectEmitter.emit(this.endpoint);
  }

  /**
   * unselect this endpoint
   */
  unselect(): void {
    this.unselectEmitter.emit(this.endpoint);
  }
}
