/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { LocalizationService } from "client/scripts/localization/localization.service";
import {
  IChatRoom,
  IMessage,
  IEndpoint,
  IMessageService,
  IChatRoomService,
  Companion, Browser
} from "companion";

@Component({
  selector: "chatroom-chatbox",
  templateUrl: "./chatroom-chatbox.template.html"
})

/**
 * chat box view component
 */
export class ChatRoomChatBox implements OnInit {
  private messageService: IMessageService = Companion.getMessageService();
  private chatRoomService: IChatRoomService = Companion.getChatRoomService();

  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }

  @Input() room: IChatRoom;
  @Input() currentEndpoint: IEndpoint;
  @Input() canModifyChatRoomParticipants: boolean;
  @Input() canDeleteSelf: boolean = false;
  @Output("backToRoomList") backToRoomListEmitter: EventEmitter<IChatRoom> = new EventEmitter<IChatRoom>();
  @Output("delete") deleteRoomEmitter: EventEmitter<IChatRoom> = new EventEmitter<IChatRoom>();
  @Output("closePanel") closePanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("toggleMenuPanel") toggleMenuPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("toggleEndpointSelectListPanel") toggleEndpointSelectListPanelEmitter: EventEmitter<
    IChatRoom
  > = new EventEmitter<IChatRoom>();

  /**
   * is mobile app
   */
  @Input() isMobileApp: boolean;

  /**
   * component load handler
   */
  ngOnInit() {
    this.room = this.room;
    this.chatRoomService.resetUnreadOfChatRoom(this.room);
  }

  /**
   * return to chat room list view
   */
  backToRoomList(): void {
    this.backToRoomListEmitter.emit(this.room);
  }

  /**
   * delete the room!
   */
  deleteRoom(): void {
    if(this.canDeleteSelf) {
      this.deleteRoomEmitter.emit(this.room);
    }
  }

  /**
   * send message
   * @param message:IMessage - IMessage object to send
   */
  sendMessage(message: IMessage): void {
    this.messageService.sendMessage(this.room, message);
    this.chatRoomService.addMessageToChatRoom(this.room, message, false);
  }

  /**
   * trigger close panel event
   */
  closePanel(): void {
    this.closePanelEmitter.emit(true);
    if (this.isMobileApp) {
      this.closeMenuPanel();
    }
  }

  /**
   * close menu panel
   */
  private closeMenuPanel(): void {
    this.toggleMenuPanelEmitter.emit(true);
  }


  /**
   * open and close endpoint selection sub panel
   * @param room: IChatRoom - room object the endpoint selecting list belongs to
   */
  toggleEndpointSelectListPanel(room: IChatRoom): void {
    this.toggleEndpointSelectListPanelEmitter.emit(room);
  }
}
