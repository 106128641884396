/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, OnInit } from "@angular/core";
import { LocalizationService } from "../localization/localization.service";
import { NotificationService } from "./notification.service";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { Alert, AlertLevel, Companion } from "companion";
import { TopBarMenuItemKey } from "../layout/top-bar/top-nav.service";

@Component({
  selector: "notification",
  templateUrl: "./notification.component.html"
})

/**
 * notification view
 */
export class NotificationComponent implements OnInit {
  isEditing: boolean = false;
  viewModel: any = {
    id: "",
    notification: "",
    editorTheme: "snow",
    editorModules: {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image"]
      ]
    }
  };

  get isSystemAdmin(): boolean {
    let currentUser = Companion.getUserService().currentUser;
    return currentUser.isAuthenticated && currentUser.permissions.modifyMcuConfig == 4;
  }

  constructor(public localizationService: LocalizationService, public notificationService: NotificationService) {}

  getLatestNotification() {
    if (!this.isEditing) {
      this.notificationService.getSystemNotification()
      .then((data: any) => {
        if (this.viewModel.notification !== data.notification) {
          Dispatcher.dispatch(ActionType.ResetTopBarNotification, { key: TopBarMenuItemKey.Notification, value: 1 });
        }
        this.viewModel.notification = data.notification;
      })
      .catch((error => console.log("Unable to get latest notification, ", error)));
    }
    let getLatestNotificationTimer = setTimeout(() => {
      clearTimeout(getLatestNotificationTimer);
      this.getLatestNotification();
    }, 30 * 1000);
  }
  ngOnInit() {
    this.getLatestNotification();
  }
  insertImageWithBlobData(data: Blob) {
    var reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = () => {
      let base64data = reader.result;
      this.viewModel.notification += "<img src='" + base64data + "' />";
    };
  }
  sendNotification() {
    this.notificationService
    .sendSystemNotification(this.viewModel.notification)
    .then(() => {
      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert("SEND_NOTIFICATION_SUCCESS", "Notification Sent Successfully.", AlertLevel.success)
      });
      this.isEditing = false;
    })
    .catch((error: Error) => {
      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert(error.message, "Failed to Send Notification.", AlertLevel.warning)
      });
    });
  }
  close() {
    Dispatcher.dispatch(ActionType.HideModalDialog, "notification");
  }
  cancel() {
    this.isEditing = false;
  }
  edit() {
    this.isEditing = true;
  }
}
