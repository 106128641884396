/**
 * Copyright Compunetix Incorporated 2016-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {
  AlertLevel,
  Browser,
  Companion,
  Cookie,
  CPMode,
  ICPMode,
  IDeviceService,
  IEndpoint,
  IEndpointService,
  IExpandedActiveConference,
  IRTCService,
  IVideo,
  VideoMediaConnectionMode,
  MediaUtil,
  RecordMode,
  VideoAspect,
  VideoResolution,
  AlertCode,
  EasyRTCService,
  IPosition,
  ConferenceUtil,
  IEndpointRef,
  EndpointService
} from "companion";
import {LocalizationService} from "../../localization/localization.service";
import {RestService} from "../../shared/services/rest.service";
import {AlertService} from "../../alert/alert.service";
import {VideoControlComponent} from "./video-control.component";
import {VoiceService} from "../../shared/services/voice.service";
import {ContentPresenterComponent} from "client/scripts/content-presenter/content-presenter.component";
import {ActionType, Dispatcher} from "client/scripts/shared/services/dispatcher";
import {saveAs} from "file-saver";
import { RemoteDesktopComponent } from "client/scripts/remote-control/remote-desktop.component";
import { FileUtility } from "client/scripts/shared/services/file-utility";

const defaultCPModeOnTwoPeers: number = 4;
const defaultCPMode: number = 1;
const defaultCPModeMobilePortrait: number = 100;
const defaultCPModeMobileLandscape: number = 101;


@Component({
  selector: "video-list",
  templateUrl: "./video-list.template.html"
})
/**
 * video view
 */
export class VideoListComponent implements OnInit, OnDestroy, AfterViewChecked {

  /**
   * video element id prefix
   */
  public static VideoElementIdPrefix: string = "ep-video-";

  /**
   * current video endpoints
   */
  @Input() videoEndpoints: IEndpointRef[];

  /**
   * the selected cpmode
   */
  @Input() cpmode: number;

  /**
   * flag if self view is on
   */
  @Input() isSelfViewOn: boolean;

  /**
   * flag if should auto select cpmode
   */
  @Input() autoSelect: boolean;

  /**
   * flag if left sidebar panels are showing
   */
  @Input() leftSidebarShown: boolean;

  /**
   * flag if right sidebar panels are showing
   */
  @Input() rightSidebarShown: boolean;

  @Input() isRecordingActive: boolean = false;

  /**
   * flag if is Operator mode
   */
  @Input() isOperator: boolean = false;

  /**
   * flag if is taking photo
   */
  @Input() takingPhoto: boolean = false;

  /**
   * flag if top toolbar is showing
   */
  @Input() isNavShown: boolean = true;

  /**
   * flag if it's in locked mode
   */
  @Input() isLocked: boolean;

  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean;

  /**
   * Fill or Fit video aspect
   */
  @Input() videoAspect: VideoAspect;

  /**
   * flag if clean mode is active in mobile devices
   */
  @Input() isCleanViewMode: boolean;

  /**
   * flag if in page panel is turn on
   */
  @Input() inPagePanel: boolean;

  /**
   * videos volume
   */
  @Input() videoVolume: number;

  /**
   * double click toggle full screen event
   */
  @Output("toggleFullScreen") toggleFullScreenEventEmitter: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * click on visible videos in mobile devices
   */
  @Output("toggleCleanViewMode") toggleCleanViewModeEmitter: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * take picture
   */
  @Output("takePic") takePic: EventEmitter<string> = new EventEmitter<string>();

  /**
   * videoControlComponent
   */
  @ViewChild(VideoControlComponent)
  VideoControlComponent: VideoControlComponent;

  /**
   * endpoints whose videos are to render
   */
  endpoints: IEndpoint[];

  /**
   * the visible videos
   */
  _visibleVideos: IVideo[] = [];

  _broadcastingVideos: IVideo[] = [];

  get allVideos(): IVideo[] {
    return _.concat(this._visibleVideos, this.broadcastingVideos, this.localVideos);
  }

  get visibleVideos(): IVideo[] {
    return this._visibleVideos;
  }

  set visibleVideos(videos: IVideo[]) {
    this._visibleVideos = videos
  }

  get broadcastingVideos(): IVideo[] {
    return this._broadcastingVideos;
  }

  set broadcastingVideos(videos: IVideo[]) {
    this._broadcastingVideos = videos
  }

  get localVideos(): IVideo[] {
    return _.values(this.myVideos);
  }

  /**
   * frame rate on canvas
   */
  frameRate: number = 30;

  /**
   * volume before user click mute
   */
  volumeBeforeMute: number = 1;

  /**
   * if this is a mobile device
   */
  isMobileDevice: boolean = Browser.isMobile();

  /**
   * timer to reset all streams
   */
  resetStreamTimer: any;

  /**
   * timer to redraw the canvas
   */
  canvasDrawTimeout: any;

  /**
   * timer to redraw the dom on canvas
   */
  domDrawTimeout: any;

  /**
   * record file name
   */
  recordFileName: string;

  /**
   * audio nodes to be recorded
   */
  audioNodesToRecord: { [streamId: string]: MediaStreamAudioSourceNode } = {};

  /**
   * destination node of audio mix of entire conference
   */
  audioMixDestinationNode: any;

  /**
   * flag if recording is in progress
   */
  isRecording: boolean = false;

  /**
   *Content Control component
   */
  @ViewChild(ContentPresenterComponent)
  contentShareComponent: ContentPresenterComponent;

  /**
   * Indicates the client is sharing content from content presenter
   */
  isSharingContent: boolean = false;
  
  /**
   *Content Control component
   */
  @ViewChild(RemoteDesktopComponent)
  remoteDesktopComponent: RemoteDesktopComponent;

  /**
   * Indicates the agent is attempting to remote control the guest.
   */
  isRemoteDesktop: boolean = false;

  /**
   * audio record mode
   */
  audioMode: RecordMode;

  /**
   * video record mode
   */
  videoMode: RecordMode = RecordMode.both;

  /**
   * video snapshot mode
   */
  snapShotVideoMode: RecordMode = RecordMode.remote;

  /**
   * recorded blobs container
   */
  blobs: any = [];

  /**
   * mixed audio tracks
   */
  mixedAudioTracks: MediaStreamTrack[] = [];

  /**
   * main view video
   */
  mainViewVideo: IVideo;

  /**
   * Flag indicating that loopback video is being shown in the main
   * window
   */
  loopbackInMainWindow: boolean = false;

  /**
   * display videos in collapse mode instead of composite mode
   */
  collapseMode: boolean;

  /**
   * is device in landscape mode
   */
  isLandscape: boolean;

  /**
   * Hold the dynamic main picture width here, it can be resized by handler
   */
   mainScreenWidth : number = 80;

   /**
    * indicates the window is being dragged
    */
   dragging: boolean = false;

  /**
   * force video display in collapse mode
   */
  @Input() alwaysInCollapseMode: boolean;

  /**
   * is map view shown
   */
  @Input() isMapShown;

  /**
   * if show primary pip
   */
   get showPrimaryPIP(): boolean {
    return !this.collapseMode && this.primaryPIP && !_.includes(this.visibleVideos, this.primaryPIP);
  }

  /**
   * primary pip video
   */
  primaryPIP: IVideo;

  myVideos: { [streamId: string]: IVideo } = {};
  otherVideos: { [streamId: string]: IVideo } = {};
  panelWidth: number;

  private rtcService: IRTCService = Companion.getRTCService();
  private endpointService: IEndpointService = Companion.getEndpointService();
  private deviceService: IDeviceService = Companion.getDeviceService();

  constructor(
    public localizationService: LocalizationService,
    private alertService: AlertService,
    private restService: RestService,
    private voiceService: VoiceService,
    private _ngZone: NgZone
  ) {
    Dispatcher.register(ActionType.ToggleContentShare, this.toggleContentShare.bind(this));
    Dispatcher.register(ActionType.ToggleRemoteDesktop, this.toggleRemoteDesktop.bind(this));
  }

  /**
   * double click event
   */
  toggleFullScreen(e: Event) {
    this.toggleFullScreenEventEmitter.emit(e);
  }

  /**
   * get default CPMode on the videos amount
   */
  getDefaultCPMode(): number {
    if (this.isMobileDevice) {
      return this.isLandscape ? defaultCPModeMobileLandscape : defaultCPModeMobilePortrait;
    } else if (this.visibleVideos.length === 2) {
      return defaultCPModeOnTwoPeers;
    } else {
      return defaultCPMode;
    }
  }

  /**
   * reset video canvas drawing
   */
  resetBackgroundCanvas() {
    if (this.canvasDrawTimeout) {
      window.cancelAnimationFrame(this.canvasDrawTimeout);
    }
    if (this.domDrawTimeout) {
      window.clearTimeout(this.domDrawTimeout);
    }
    this.canvasDrawTimeout = window.requestAnimationFrame(
      this.drawCanvas.bind(this, "video-scene-canvas", false, this.videoMode)
    );
  }

  /**
   * draw videos on canvas
   */
  drawCanvas(
    canvasDomId: string = "video-scene-canvas",
    oneTime: boolean = false,
    videoMode: RecordMode = this.videoMode,
    isSnapshot: boolean = false
  ) {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById(canvasDomId);
    if (!canvas) {
      return;
    }
    let largestResolutionOption: VideoResolution = _.find(
      this.rtcService.rtcClient.resolutionOptions,
      (resolution: VideoResolution) => {
        return resolution.width * resolution.height >= 1280 * 720;
      }
    ) || { width: 1280, height: 720 };
    canvas.width = largestResolutionOption.width;
    canvas.height = largestResolutionOption.height;
    let videosToRender: IVideo[];
    switch (videoMode) {
      case RecordMode.disabled:
        videosToRender = [];
        break;
      case RecordMode.local:
        videosToRender = _.values(this.myVideos);
        break;
      case RecordMode.remote:
        videosToRender = this.visibleVideos;
      break;
      case RecordMode.both:
      default:
        videosToRender = this.visibleVideos;
        const visibleVideosStreamIds = this.visibleVideos.map(v => v.stream.id);
        const myVideos = _.values(this.myVideos);
        videosToRender = videosToRender.concat(myVideos.filter(v => !visibleVideosStreamIds.includes(v.stream.id)));
        break;
    }

    let numberOfVideos = videosToRender.length;

    // add a space for inpage panel
    if (this.inPagePanel) {
      numberOfVideos = numberOfVideos + 1;
    }

    // draw videos
    _.forEach(videosToRender, (video: IVideo, index: number) => {
      let ep = EndpointService.getSharedInstance().getEndpointById(video.endpoint.rtcId);
      // single full screen.
      let mainVideoCanvasPosition: IPosition = {
        leftPercent: 0,
        topPercent: 0,
        widthPercent: 100,
        heightPercent: 100
      };

      if (numberOfVideos > 4) {
        // 5 and 6 view
        // 0 , 1,  2
        // 3,  4,  5
        let topPercentValue = (index < 2) ? 0 : 50;
        let leftPercentValue = 33 * index;
        if (index > 2) {
          leftPercentValue = 33 * (index - 3);
        }

        mainVideoCanvasPosition = {
          leftPercent: leftPercentValue,
          topPercent: topPercentValue,
          widthPercent: 33,
          heightPercent: 50
        };
      } else if (numberOfVideos > 3) {
        // 4 video
        // 0, 1
        // 2, 3
        mainVideoCanvasPosition = {
          leftPercent: (index % 2) ? 50 : 0,
          topPercent: (index > 1) ? 50 : 0,
          widthPercent: 25,
          heightPercent: 25
        };

      } else if (numberOfVideos > 2) {
        // three videos
        // index 1 is top row, 0 and 2 are bottom row
        //   1
        // 0,  2
        let topPercentValue = (index == 1) ? 0 : 50;

        mainVideoCanvasPosition = {
          leftPercent: 25 * index,
          topPercent: topPercentValue,
          widthPercent: 50,
          heightPercent: 50
        };
      } else if (numberOfVideos > 1) {
        // two videos
        mainVideoCanvasPosition = {
          leftPercent: 50 * index,
          topPercent: 0,
          widthPercent: 50,
          heightPercent: 100
        };
      }

      // currently only support up to 6 streams (not sure we would need to support more)
      if(index < 6)
      {
        this.rtcService.drawVideo(
          canvas,
          document.getElementById(
            VideoListComponent.VideoElementIdPrefix + video.endpoint.rtcId + "-" + video.stream.id
          ) as HTMLVideoElement,
          mainVideoCanvasPosition,
          ep?.cameraRotation || "0"
        );
      }
    });

    // draw in-page panel
    // this will always be the last in video number.
    if (this.inPagePanel) {
      // single full screen.
      let inPageCanvasPosition: IPosition = {
        leftPercent: 0,
        topPercent: 0,
        widthPercent: 100,
        heightPercent: 100
      };

      if (numberOfVideos == 5 || numberOfVideos == 6) {
        inPageCanvasPosition = {
          leftPercent: 66,
          topPercent: 50,
          widthPercent: 33,
          heightPercent: 50
        };
      } else if (numberOfVideos == 4 || numberOfVideos == 3) {
        inPageCanvasPosition = {
          leftPercent: 50,
          topPercent: 50,
          widthPercent: 25,
          heightPercent: 25
        };
      } else if (numberOfVideos == 2) {
        inPageCanvasPosition = {
          leftPercent: 50,
          topPercent: 0,
          widthPercent: 50,
          heightPercent: 100
        };
      }

      const mapImage: any = <HTMLImageElement>document.getElementById("map-image");
      if (mapImage && mapImage.src) {
        this.rtcService.drawImage(canvas, mapImage, inPageCanvasPosition);
      }
    }
    if (!oneTime) {
      this.canvasDrawTimeout = window.requestAnimationFrame(
        this.drawCanvas.bind(this, canvasDomId, oneTime, videoMode)
      );
    }
  }

  /**
   * draw dom to canvas
   */
  drawDomOnCanvas(inPagePanelId: string = "in-page-panel") {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById("in-page-canvas");
    if (!canvas) {
      return;
    }
    canvas.width = this.rtcService.rtcClient.currentSysPrimaryResolution.width;
    canvas.height = this.rtcService.rtcClient.currentSysPrimaryResolution.height;
    const context = canvas.getContext("2d");
    context.fillRect(0, 0, canvas.width, canvas.height);

    if (inPagePanelId) {
      let inPagePanelElement: any = document.getElementById(inPagePanelId);
      html2canvas(inPagePanelElement, {
        allowTaint: true,
        logging: false,
        canvas: canvas,
        width: canvas.width,
        height: canvas.height
      }).then((parsedCanvas: HTMLCanvasElement) => {
        // nothing needed here
      });
    }
    this.domDrawTimeout = setTimeout(() => {
      clearTimeout(this.domDrawTimeout);
      this.drawDomOnCanvas(inPagePanelId);
    }, 5 * 1000);
  }

  updateVideoPosition(video: IVideo): void {
    if (!video.endpoint || !video.stream || video.stream.hidden) {
      video.position = CPMode.getHiddenPosition();
    }
    const cpMode: ICPMode = new CPMode(
      this.visibleVideos.length,
      this.autoSelect ? this.getDefaultCPMode() : this.cpmode,
      true
    );
    if (this.collapseMode) {
      let screenVideo: IVideo = _.find(this.visibleVideos, { stream: { streamName: "screen", hidden: false } }) as IVideo;
      if (screenVideo) {
        if (
          screenVideo.endpoint.rtcId === video.endpoint.rtcId &&
          video.stream &&
          video.stream.streamName === "screen"
        ) {
          video.position = CPMode.getMainSideFullScreenPosition();
        } else {
          video.position = CPMode.getSubSidePosition(cpMode, this.visibleVideos.indexOf(video));
        }
      } else {
        if (video === this.mainViewVideo) {
          video.position = CPMode.getMainSideFullScreenPosition();
        } else {
          video.position = CPMode.getSubSidePosition(cpMode, this.visibleVideos.indexOf(video));
        }
      }
    } else {
      video.position = CPMode.getPosition(cpMode, this.visibleVideos.indexOf(video));
    }
    video.style = {
        position: "absolute",
        top: video.position.topPercent + "%",
        left: video.position.leftPercent + "%",
        width: video.position.widthPercent + "%",
        height: video.position.heightPercent + "%",
        color: this.localizationService.getValueByPath(".video_screen.backgroundColor") || "black"
    };
  }
    

  /**
   * reset stream after some milliseconds
   * @param milSeconds: number - timeout number
   */
  resetStreamOnTimer(milSeconds: number) {
    clearTimeout(this.resetStreamTimer);
    this.resetStreamTimer = setTimeout(() => {
      clearTimeout(this.resetStreamTimer);
      this.resetStreams();
      this.createOrPatchAudioNodesToRecord();
    }, milSeconds);
  }

  /**
   * reset streams of the video elements
   */
  resetStreams(): void {
    // update other videos
    _.forEach(this.otherVideos, (video: IVideo) => {
      this.resetStream(video.endpoint, video.stream);
    });

    // update my videos (the streams owned by me)
    _.forEach(this.myVideos, (video: IVideo) => {
      this.resetStream(video.endpoint, video.stream);
    });
    
    this.VideoControlComponent.updateAllVideoElementsVolume();
  }

  resetStream(endpoint: IEndpoint, stream: MediaStream): void {
    const videoElement: any = document.getElementById(
      VideoListComponent.VideoElementIdPrefix + endpoint.rtcId + "-" + stream.id
    );
    if (videoElement) {
      if (stream) {
        this.rtcService.setVideoSrcByElement(videoElement, stream)
          .catch((error) => {
            console.log("video list component resetStream - Failed to setVideoSrcByElement videoElement:", videoElement, "stream:", stream, "error:", error);
          });
        if (this.rtcService.rtcClient.selectedSpeakerOption) {
          this.rtcService.setAudioOutput(videoElement, this.rtcService.rtcClient.selectedSpeakerOption.deviceId)
            .catch((error) => {
              console.log("video list component resetStream - Failed to setAudioOutput videoElement:", videoElement, "deviceId:", this.rtcService.rtcClient.selectedSpeakerOption.deviceId, "error:", error);
            });
        }
      } else {
        videoElement.src = "";
      }
    } else {
      let resetStreamTimer = setTimeout(() => {
        clearTimeout(resetStreamTimer);
        this.resetStream(endpoint, stream);
      }, 1000);
    }
  }

  /**
   * stop canvas drawing
   */
  stopBackgroundCanvas() {
    if (this.canvasDrawTimeout) {
      window.cancelAnimationFrame(this.canvasDrawTimeout);
    }
    if (this.domDrawTimeout) {
      window.clearTimeout(this.domDrawTimeout);
    }
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById("video-scene-canvas");
    if (canvas) {
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  /**
   * create or patch audio nodes to record
   */
  createOrPatchAudioNodesToRecord() {
    if (!this.audioMixDestinationNode) {
      if (
        this.rtcService.rtcClient.audioContext &&
        this.rtcService.rtcClient.audioContext.createMediaStreamDestination
      ) {
        this.audioMixDestinationNode = this.rtcService.rtcClient.audioContext.createMediaStreamDestination();
      } else {
        return;
      }
    }
    let endpointsToBeRecorded =  _.filter(this.endpoints, (ep: IEndpoint) => {
      if (ep.transmitMode === VideoMediaConnectionMode.Broadcasting) {
        return false;
      }
      switch (this.audioMode) {
        case RecordMode.disabled:
          return false;
        case RecordMode.local:
          return ep.rtcId === this.endpointService.myEndpoint.rtcId;
        case RecordMode.remote:
          return ep.rtcId !== this.endpointService.myEndpoint.rtcId;
        case RecordMode.both:
        default:
          return true;
      }
    });

    if (ConferenceUtil.includesVoiceEndpointRef(this.videoEndpoints)) {
      this.voiceService.updateEndpointStreamsToRecord(this.audioMode, this.videoEndpoints, endpointsToBeRecorded);
    } else {
      this.voiceService.removeVoiceEndpointStreamsToRecord(endpointsToBeRecorded);
    }

    let activeStreams: MediaStream[] = _.compact(
      _.flatten(
        _.map(endpointsToBeRecorded, (ep: IEndpoint) => {
          return _.values(ep.streams);
        })
      )
    );

    // if we are recording both, make sure to add my own camera there (in some cases it isn't)
    // if we are video muted...
    if (!_.includes(_.map(activeStreams, "id"), EasyRTCService.getSharedInstance().rtcClient.cameraStream?.id) &&
        this.audioMode == RecordMode.both || this.audioMode == RecordMode.local) {
      activeStreams.push(EasyRTCService.getSharedInstance().rtcClient.cameraStream);
    }

    let activeStreamIds: string[] = _.map(activeStreams, "id");
    let obsoleteStreamIds: string[] = _.difference(_.keys(this.audioNodesToRecord), activeStreamIds);
    _.forEach(obsoleteStreamIds, (id: string) => {
      this.audioNodesToRecord[id].disconnect();
      delete this.audioNodesToRecord[id];
    });
    let newStreamIds: string[] = _.difference(activeStreamIds, _.keys(this.audioNodesToRecord));
    let newStreams: MediaStream[] = _.filter(activeStreams, (stream: MediaStream) => {
      return _.includes(newStreamIds, stream.id);
    });
    _.forEach(newStreams, (stream: MediaStream) => {
      if (stream.getAudioTracks().length > 0 && this.rtcService.rtcClient.audioContext) {
        this.audioNodesToRecord[stream.id] = this.rtcService.rtcClient.audioContext.createMediaStreamSource(stream);
        this.audioNodesToRecord[stream.id].connect(this.audioMixDestinationNode);
      }
    });
    this.mixedAudioTracks = this.audioMixDestinationNode ? this.audioMixDestinationNode.stream.getAudioTracks() : [];
  }

  /**
   * remove audio nodes to record
   */
  removeAudioNodesToRecord() {
    _.forEach(this.audioNodesToRecord, (audioNode: MediaStreamAudioSourceNode) => {
      audioNode.disconnect();
    });
    this.audioNodesToRecord = {};
  }

  /**
   * start recording
   */
  startRecord(audioMode: RecordMode, videoMode: RecordMode) {
    let audioOnly: boolean = this.videoMode === RecordMode.disabled;
    // check audio context ready for recording
    Companion.getRTCClient().prepareAudioContext();
    if (!this.rtcService.rtcClient.audioContext) {
      this.audioMode = RecordMode.disabled;
    }
    this.resetRecordingStreamPromise(audioMode, videoMode)
    .then(() => {
      let template =
      this.localizationService.getValueByPath(".record_panel.filenamePattern") ||
      "[DATE]_[TIME]_[LOCAL_NAME]";
      this.recordFileName = FileUtility.generateFileName(template, this.localizationService, ".webm");
      MediaUtil.startRecord(this.rtcService.rtcClient.recordingStream, this.recordFileName, audioOnly);
    })
    .catch((error: Error) => {
      this.rtcService.rtcClient.alertHandler("Failed to get recording stream: ", error ? error.message : undefined);
    });
  }

  /**
   * reset recording stream promise
   */
  resetRecordingStreamPromise(audioMode: RecordMode, videoMode: RecordMode): Promise<MediaStream> {
    this.resetCanvasAndAudioMix(audioMode, videoMode);
    return this.rtcService.getRecordingStream(
      true,
      this.frameRate,
      this.mixedAudioTracks,
      this.videoMode === RecordMode.disabled
    );
  }

  /**
   * reset canvas and audio mix
   */
  resetCanvasAndAudioMix(audioMode: RecordMode, videoMode: RecordMode): void {
    this.audioMode = audioMode;
    this.videoMode = videoMode;
    this.createOrPatchAudioNodesToRecord();
    if (this.videoMode !== RecordMode.disabled) {
      this.resetBackgroundCanvas();
    }
  }

  /**
   * reset broadcast stream promise
   */
  resetBroadcastStreamPromise(audioMode: RecordMode, videoMode: RecordMode): Promise<MediaStream> {
    this.resetCanvasAndAudioMix(audioMode, videoMode);
    return this.rtcService.getBroadcastStream(
      true,
      this.frameRate,
      this.mixedAudioTracks,
      this.videoMode === RecordMode.disabled
    );
  }

  /**
   * pause recording
   */
  pauseRecord() {
    MediaUtil.pauseRecord(this.rtcService.rtcClient.recordingStream);
  }

  /**
   * resume recording
   */
  resumeRecord() {
    MediaUtil.resumeRecord(this.rtcService.rtcClient.recordingStream);
  }

  /**
   * stop recording
   */
  stopRecord() {
    MediaUtil.stopRecord(this.rtcService.rtcClient.recordingStream)
    .then((data: Blob) => {
      this.rtcService.rtcClient.recordingStream.stop();
      const blobFile = new File([data], this.recordFileName, { type: "video/webm;codecs=h264" });
      if (
        this.localizationService.myLocalizationData.record_panel &&
        this.localizationService.myLocalizationData.record_panel.sendToFTP
      ) {
        let formData: FormData = new FormData();
        formData.append("rtcId", this.endpointService.myEndpoint.rtcId);
        formData.append("webm", blobFile, this.recordFileName);
        Companion.getConferenceService().alertHandler(
          AlertCode.recordUploading,
          "Sending the recorded video file to server...",
          AlertLevel.success
        );
        this.restService
          .upload("/uploadRecord", formData)
          .subscribe(
            () => {
              Companion.getConferenceService().alertHandler(
                AlertCode.recordUploadSuccess,
                "The recorded video file is uploaded successfully.",
                AlertLevel.success
              );
            },
            () => {
              Companion.getConferenceService().alertHandler(
                AlertCode.recordUploadFail,
                "Failed to upload the recorded video file.",
                AlertLevel.warning
              );
            }
          );
      } else {
        saveAs(blobFile, this.recordFileName);
        Companion.getConferenceService().alertHandler(
          AlertCode.recordFinished,
          "The recorded video file is downloaded automatically.",
          AlertLevel.success
        );
      }
    })
    .catch((error: Error) => {
      Companion.getConferenceService().alertHandler(
        AlertCode.recordFinishFail,
        "Failed to finish the recording.",
        AlertLevel.warning
      );
    });
  }

  /**
   * stop tracking faces
   */
  startTracking(videoElementId: string, canvasElementId: string) {
    let tracking: any = new (<any>window).tracking();
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById(canvasElementId);
    const context = canvas.getContext("2d");
    const tracker = new tracking.ObjectTracker("face");
    tracker.setInitialScale(4);
    tracker.setStepSize(2);
    tracker.setEdgesDensity(0.1);
    tracking.track("#" + videoElementId, tracker);

    tracker.on("track", (event: any) => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      event.data.forEach((rect: any) => {
        context.strokeStyle = "#a64ceb";
        context.strokeRect(rect.x, rect.y, rect.width, rect.height);
        context.font = "11px Helvetica";
        context.fillStyle = "#fff";
        context.fillText("x: " + rect.x + "px", rect.x + rect.width + 5, rect.y + 11);
        context.fillText("y: " + rect.y + "px", rect.x + rect.width + 5, rect.y + 22);
      });
    });
  }

  /**
   * change speaker id on all video elements
   */
  changeSinkId() {
    if (this.rtcService.rtcClient.selectedSpeakerOption) {
      for (let i = 0; i < this.endpoints.length; ++i) {
        const endpoint: IEndpoint = this.endpoints[i];
        _.forOwn(endpoint.streams, (stream: MediaStream, streamId: string) => {
          const element: any = document.getElementById(
            VideoListComponent.VideoElementIdPrefix + endpoint.rtcId + "-" + streamId
          );
          if (element) {
            this.rtcService.setAudioOutput(element, this.rtcService.rtcClient.selectedSpeakerOption.deviceId)
            .catch((error) => {
              console.log("video list component changeSinkId - Failed to setAudioOutput element:", element, "deviceId:", this.rtcService.rtcClient.selectedSpeakerOption.deviceId, "error:", error);
            });
          }
        });
      }
    }
  }

  hiddenVideo(endpoint) : boolean {
    return endpoint.transmitMode == VideoMediaConnectionMode.Broadcasting;
  }

  /**
   * reset all videos
   */
  videoReset(): void {    
    this.endpoints = _.compact(_.map(this.videoEndpoints, (epRef) => {
      return EndpointService.getSharedInstance().getEndpointById(epRef.rtcId);
    }));
    this.endpoints = _.filter(this.endpoints, (endpoint: IEndpoint) => {
      return _.keys(endpoint.streams).length > 0;
    });
    let endpointDict = _.keyBy(this.endpoints, 'rtcId');
    // update my videos
    let newMyVideos: IVideo[] = this.getVideosOfEndpoint(this.endpointService.myEndpoint);
    let newMyStreamIds: string[] = _.map(newMyVideos, "stream.id");
    let liveMyStreamIds: string[] = _.keys(this.myVideos);
    let toBeDeletedMyStreamIds: string[] = _.difference(liveMyStreamIds, newMyStreamIds);
    let toBeAddedMyStreamIds: string[] = _.difference(newMyStreamIds, liveMyStreamIds);
    _.forEach(toBeDeletedMyStreamIds, (streamId: string) => {
      delete this.myVideos[streamId];
    });
    _.forEach(toBeAddedMyStreamIds, (streamId: string) => {
      this.myVideos[streamId] = _.find(newMyVideos, (video: IVideo) => {
        return video.stream.id === streamId;
      });
    });
    // update other videos
    let newOtherVideos: IVideo[] = _.flatten(
      _.map(this.endpoints, (ep: IEndpoint) => {
        if (ep.rtcId === this.endpointService.myEndpoint.rtcId) {
          return [];
        } else {
          return this.getVideosOfEndpoint(ep);
        }
      })
    );
    let newOtherStreamIds: string[] = _.map(newOtherVideos, "stream.id");
    let liveOtherStreamIds: string[] = _.keys(this.otherVideos);
    let toBeDeletedOtherStreamIds: string[] = _.difference(liveOtherStreamIds, newOtherStreamIds);
    let toBeAddedOtherStreamIds: string[] = _.difference(newOtherStreamIds, liveOtherStreamIds);
    _.forEach(toBeDeletedOtherStreamIds, (streamId: string) => {
      delete this.otherVideos[streamId];
    });
    _.forEach(toBeAddedOtherStreamIds, (streamId: string) => {
      this.otherVideos[streamId] = _.find(newOtherVideos, (video: IVideo) => {
        return video.stream.id === streamId;
      });
    });
    // update primary pip n
    let screenPIP: IVideo = _.find(_.values(this.myVideos), (video: IVideo) => {
      return video.stream.streamName === "screen";
    });
    this.primaryPIP = screenPIP ? screenPIP : _.first(_.values(this.myVideos));
    // update visibleVideos
    const broadcastingVideos: IVideo[] = [];
    const nonBroadcastingVideos: IVideo[] = [];
    _.values(this.otherVideos).forEach((v: IVideo) => {
      if (endpointDict[v.endpoint.rtcId].transmitMode === VideoMediaConnectionMode.Broadcasting) {
        broadcastingVideos.push(v);
      } else {
        nonBroadcastingVideos.push(v);
      }
    });
    if ((_.keys(this.otherVideos).length - broadcastingVideos.length) > 0) {
      this.visibleVideos = nonBroadcastingVideos;
    } else if (this.primaryPIP) {
      this.visibleVideos = [this.primaryPIP];
    }

    this.broadcastingVideos = broadcastingVideos;
    let screenVideo: IVideo = _.findLast(_.values(this.allVideos), (video: IVideo) => {
      return video.stream.streamName === "screen" && !video.stream.hidden;
    });
    this.collapseMode = _.values(this.myVideos).length > 1 || !!screenVideo || this.isSharingContent;
    if (this.collapseMode) {
      this.showInMainView(screenVideo || _.values(this.otherVideos)[0] || _.values(this.myVideos)[0]);
    }
    // update video positions
    _.forEach(this.visibleVideos, (video: IVideo) => {
      this.updateVideoPosition(video);
    });

    // render all streams
    this.resetStreamOnTimer(100);

    this.updatePanelWidth();
  }

  getVideosOfEndpoint(endpoint: IEndpoint): IVideo[] {
    return _.compact(
      _.map(_.values(endpoint.streams), (stream: any) => {
        
        if (stream.hidden) {
          return undefined;
        }

        let item = { endpoint: endpoint as Extract<IEndpoint, IEndpointRef>, stream: stream, position: null };
        return item;
      })
    );
  }

  /**
   * init window size handler
   */
  appInit(): void {
    this.isLandscape = Browser.isLandscape();
    window.onresize = this.handleWindowResize.bind(this);
    this.videoReset();

    if(this.isMobileApp)
    {
      this.mainScreenWidth = 100;
    }
    else if(Cookie.getCookie("mainScreenWidth"))
    {
      this.mainScreenWidth = Math.max(Number(Cookie.getCookie("mainScreenWidth")), 2);
    }
  }


  updatePanelWidth() {
    const panelContainer = document.getElementById("in-page-panel");
    if (panelContainer) {
      this.panelWidth = panelContainer.offsetWidth;
    }
  }

  /**
   * window size handler
   */
  handleWindowResize(): void {
    jQuery(".popover").hide();
    const isLandscape = Browser.isLandscape();
    this.updatePanelWidth();
    if (isLandscape !== this.isLandscape) {
      this.isLandscape = isLandscape;
      this.videoReset();
    }
  }

  /**
   * component load handler
   */
  ngOnInit() {
    this.appInit();
  }

  ngAfterViewChecked() {
    this.updatePanelWidth();
  }

  /**
   * component unload handler
   */
  ngOnDestroy() {
    this.stopBackgroundCanvas();
    this.removeAudioNodesToRecord();
  }

  /**
   * take snapshot and draw it on canvas
   */
  drawSnapshot(videoMode: RecordMode = this.snapShotVideoMode) {
    this.snapShotVideoMode = videoMode;
    this.drawCanvas("snapshot-canvas", true, this.snapShotVideoMode, true);
  }

  /**
   * update volume on the video element of endpoint
   * @param endpoint: IEndpoint - the endpoint of video to update
   * @param videoElement: HTMLVideoElement - the video tag html element which loaded with the video
   * @param isMuted: boolean - if endpoint should be muted entirely
   * @param volume: number - the current volume to be set
   */
  updateVideoElementVolume(endpoint: IEndpoint, videoElement: HTMLVideoElement, isMuted: boolean, volume: number) {
    endpoint.volume = volume;
    if (videoElement) {
      videoElement.volume = volume;
      videoElement.muted = isMuted;
    }
  }

  showInMainView(video: IVideo, isPip = false) {
    if (isPip) {
      if (this.canFlipCamera) {
        this.flipCamera();
        return;
      }
    }

    if (video && !this.isMobileApp) {
      this.mainViewVideo = video;
      this.setMainVideoSrc();
    }
  }

  takePicture(rtcId: string) {
    this.takePic.emit(rtcId);
  }

  setMainVideoSrc(retryCount = 0) {
    let mainVideoElement: HTMLVideoElement;

    if(this.collapseMode)
    {
      mainVideoElement = document.getElementById("main-video") as HTMLVideoElement;
    }
    else
    {
      mainVideoElement = document.getElementById("video-scene")?.getElementsByClassName("main-video")?.item(0) as HTMLVideoElement;
    }

    if (mainVideoElement) {
      // audio output already in the side video panels
      // mute the main video screen to avoid echo
      this.rtcService.setVideoSrcByElement(mainVideoElement, this.mainViewVideo.stream, true)
      .catch((error) => {
        console.log("Failed to setVideoSrcByElement: ", error);
      });
      this.loopbackInMainWindow = this.mainViewVideo.endpoint.rtcId === this.endpointService.myEndpoint.rtcId && this.mainViewVideo.stream.streamName != "screen";
      this.resetStreamOnTimer(0);
    } else if (retryCount <= 3) {
      let setVideoSrcTimer = setTimeout(() => {
        clearTimeout(setVideoSrcTimer);
        this.setMainVideoSrc(++retryCount);
      }, 500);
    }
  }

  get canFlipCamera(): boolean {
    return this.isMobileDevice && Companion.getDeviceService().cameraOptions.length > 1;
  }

  flipCamera(): void {
    Companion.getDeviceService().switchCamera();
  }

  clickOnMainVisibleVideos(event: MouseEvent) {
    this.toggleCleanViewModeEmitter.emit(event);
  }

  /**
   * Drag the window resizer
   * @param event Mouse event
   */
  dragStart(event: Event) {
    
    // resize slider supported on mobile app.
    if(this.isMobileApp)
    {
      return;
    }

    // to prevent duplicate touch & click event trigger
    this.dragging = true;
    event.stopPropagation();
  }

  dblClick(event: MouseEvent) {
    if (!this.isSharingContent) {
      this.toggleFullScreen(event);
    }
  }

  /**
   * Called when the mouse is dragged across the video component,
   * resizes the main screen when dragging is active. and a mouse button
   * is being held
   * @param event Mouse event
   */
  drag(event: MouseEvent)
  {
    // resize slider supported on mobile app.
    if(this.isMobileApp)
    {
      return;
    }

    if(this.dragging && event.buttons > 0)
    {
      let videoContainer = document.getElementById("conference-video-container") as HTMLElement;
      
      // handle left side offset, and make sure we clamp cursosr to the bounds of 
      // the video container.
      let cursorX = event.clientX - videoContainer.offsetLeft;
      cursorX = Math.max(cursorX, 0);
      cursorX = Math.min(cursorX, videoContainer.clientWidth);

      // don't let us shrink smaller than 2 percent
      this.mainScreenWidth = Math.max(100 * cursorX / videoContainer.clientWidth, 2);
      Cookie.setCookie("mainScreenWidth", this.mainScreenWidth.toString(), 1, "/", null);
    }
    else
    {
      this.dragging = false;
    }
  }

  /**
   * Called when the finger is dragged on a touch screen.
   * @param event the touch event
   */
  touchMove(event: TouchEvent)
  {
    // resize slider supported on mobile app.
    if(this.isMobileApp)
    {
      return;
    }

    if(this.dragging && event.changedTouches)
    {
      let videoContainer = document.getElementById("conference-video-container") as HTMLElement;

      let cursorX = event.changedTouches[0].clientX - videoContainer.offsetLeft;
      cursorX = Math.max(cursorX, 0);
      cursorX = Math.min(cursorX, videoContainer.clientWidth);

      this.mainScreenWidth = Math.max(100 * cursorX / videoContainer.clientWidth, 2);
      Cookie.setCookie("mainScreenWidth", this.mainScreenWidth.toString(), 1, "/", null);
    }
    else
    {
      this.dragging = false;
    }
  }

  /**
   * Stop the drag when we release the mouse (or lift finger)
   * @param event Mouse event.
   */
  dragEnd(event: Event) {
    // resize slider supported on mobile app.
    if(this.isMobileApp)
    {
      return;
    }
    // to prevent duplicate touch & click event trigger
    event.stopPropagation();
    this.dragging = false;
  }

  /**
   * toggle content share.
   */
  toggleContentShare()
  {
    if(Companion.getRTCClient().screenShareEnabled)
    {
      Companion.getConferenceService().alertHandler(AlertCode.failToStartPresentation, "Presentation cannot start.", AlertLevel.warning);
      return;
    }

    if(!this.isSharingContent)
    {
      this.isSharingContent = true;
    }
    else
    {
      this.isSharingContent = false;
      this.contentShareComponent.stopContentShare();
    }

    // reset view...
    this.videoReset();
  }

  /**
   * toggle content share.
   */
  toggleRemoteDesktop(endpoint: IEndpoint)
  {
    if(!this.isRemoteDesktop)
    {
      this.isRemoteDesktop = true;
      this.remoteDesktopComponent.startConnect(endpoint);
    }
    else
    {
      this.isRemoteDesktop = false;
      this.remoteDesktopComponent.closeConnection();
    }

    // reset view...
    this.videoReset();
  }
}
