/**
 * Copyright Compunetix Incorporated 2017-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author: amaggi
 */

import {Component, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild} from "@angular/core";
import {
  CropperPosition,
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform
} from "ngx-image-cropper";
import {CallCenterService} from "client/scripts/call-center/call-center.service";
import {Companion, GlobalService, IUserService} from "companion";
import { DomSanitizer } from "@angular/platform-browser";


@Component({
  selector: "cropper",
  templateUrl: "./cropper.template.html"
})
export class CropperComponent implements OnChanges {

  @Input() imageToBeSaved: File;
  @Input() imageToBeSavedDimensions: Dimensions = {width: 0, height: 0};
  @Input() cameraRotation: number;
  @Input() autoCrop = false;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  userService: IUserService = Companion.getUserService();
  imageChangedEvent: any = "";
  sourceImageDimensions: Dimensions = {width: 0, height: 0};
  croppedImage: any = "";
  croppedImageURL: any = "";
  cropperPosition: CropperPosition = {x1: 0, y1: 0, x2: 1, y2: 1};
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  ready = false;
  showCropper = false;
  maintainAspectRatio = false;
  transform: ImageTransform = {};
  LEGAL_PAPER_ASPECT_RATIO = 1.64;
  A4_PAPER_ASPECT_RATIO = 1.41;

  constructor(private callCenterService: CallCenterService, private sanitizer: DomSanitizer) {}


  ngOnChanges(changes: SimpleChanges) {
    const currentImageStatus: SimpleChange = changes.imageToBeSaved;
    if (currentImageStatus && currentImageStatus.currentValue) {
      this.resetImage();
      this.canvasRotation = this.cameraRotation;
      this.ready = false;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    if (this.ready) {
      this.userService.currentUser.preferredCropperPosition = {
        x1: event.cropperPosition.x1 / this.sourceImageDimensions.width,
        y1: event.cropperPosition.y1 / this.sourceImageDimensions.height,
        x2: event.cropperPosition.x2 / this.sourceImageDimensions.width,
        y2: event.cropperPosition.y2 / this.sourceImageDimensions.height
      };
      GlobalService.setSessionUser(this.userService.currentUser);
    }
    this.croppedImage = event.blob;
    this.croppedImageURL = event.objectUrl;
    if (this.autoCrop && this.ready) {
      this.saveImage();
      this.closeCropper();
    }
  }

  get sanitizedCroppedImage() {
    return this.sanitizer.bypassSecurityTrustUrl(this.croppedImageURL);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.sourceImageDimensions = sourceImageDimensions;
    const savedCropperPosition = GlobalService.getSessionUser().preferredCropperPosition;
    const newCropperPosition = {} as CropperPosition;
    if (savedCropperPosition) {
      newCropperPosition.x1 = savedCropperPosition.x1 * this.sourceImageDimensions.width;
      newCropperPosition.y1 = savedCropperPosition.y1 * this.sourceImageDimensions.height;
      newCropperPosition.x2 = savedCropperPosition.x2 * this.sourceImageDimensions.width;
      newCropperPosition.y2 = savedCropperPosition.y2 * this.sourceImageDimensions.height;
    } else {
      newCropperPosition.x1 = 0;
      newCropperPosition.y1 = 0;
      newCropperPosition.x2 = this.sourceImageDimensions.width;
      newCropperPosition.y2 = this.sourceImageDimensions.height;
    }
    this.cropperPosition = newCropperPosition;
    this.ready = true;
  }

  loadImageFailed() {
    console.error("Failed to load image in the cropper");
    this.closeCropper();
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  saveImage() {
    saveAs(this.croppedImage, this.callCenterService.getSnapshotFileBasename() + ".png");

  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleMaintainAspectRatio() {
    this.maintainAspectRatio = !this.maintainAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  closeCropper() {
    this.imageToBeSaved = null;
    $("#cropperModal").modal("hide");
  }
}
