/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */

import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "nameFilter"})
export class NameFilterPipe implements PipeTransform {
  transform(values: any, search: string, key = "username"): any[] {
    if (!search) { return values; }
    search = (search.trim()).toLowerCase();
    return values.filter(val => (val[key]).toLowerCase().includes(search));
  }
}
