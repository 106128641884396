<!--
    Copyright Compunetix Incorporated 2016
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<div class="container-fluid row">
  <a
    class="col-8 user-list px-0 cursor-pointer"
    [ngClass]="{'col-12': !canModifyChatRoomParticipants}"
    [title]="filteredRoomTargets | concatEndpointNames : '\n'"
    (click)="toggleEndpointSelectListPanel($event)"
  >
  To:
    {{filteredRoomTargets | concatEndpointNames | truncate : 100 : '...'}}
  </a>
  <div
    class="col-3 text-right px-0"
    *ngIf="canModifyChatRoomParticipants"
  >
    <a
      tabindex
      class="btn btn-sm"
      [title]="localizationService.myLocalizationData.chat_panel.addParticipantButtonTooltip || 'Add More Participants to This Group Chat'"
      (click)="toggleEndpointSelectListPanel($event)"
    >
    {{localizationService.myLocalizationData.chat_panel.addParticipantButtonText || "Add Participants"}}
      <i
        class="fas fa-plus-circle"
        aria-hidden="true"
      ></i>
    </a>
  </div>
</div>

