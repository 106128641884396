/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

import { TopNavLoginBarComponent } from "./top-nav-login-bar.component";
import { TopNavLanguageComponent } from "./top-nav-language.component";
import { TopNavLinkComponent } from "./top-nav-link.component";
import { HttpClientModule } from "@angular/common/http";
import { TopBarService } from "./top-nav.service";
import { TopNavThemeComponent } from "./top-nav-theme.component";
import { TopNavControlComponent } from "client/scripts/layout/top-bar/top-nav-control.component";
import {SharedModule} from "client/scripts/shared/shared.module";

@NgModule({
  imports: [BrowserModule, FormsModule, HttpClientModule, BsDropdownModule.forRoot(), SharedModule],
  declarations: [TopNavLoginBarComponent, TopNavLanguageComponent, TopNavLinkComponent, TopNavThemeComponent, TopNavControlComponent],
  exports: [TopNavLoginBarComponent],
  providers: [TopBarService]
})
export class TopBarModule {}
