/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { 
  IEndpoint,
  PresenceStatus,
  ILocalization,
  IConferenceExpanded,
  IExpandedQueueConference,
  CameraPermission,
  PresenceState,
  Endpoint
} from "companion";

/**
 * view model for a paticipant(endpoint) list
 */
export class ParticipantList {
  constructor(
    public listType: string, // type of the list
    public _endpoints: IEndpoint[], // endpoints in the list
    public confId: string = "", // the conference identifier
    public confName: string = "Everyone", // the name of the list
    public showMyself: boolean = false, // flag if the list should display the current endpoint
    public hideTotalNumber: boolean = false, // flag if the list should display total number on title
    public sortWeight: number = 0 // Indicates the order of the list (in a list of lists) for sorting
  ) {
    // nothing needed here
  }

  get endpoints(): IEndpoint[] {
    return this._endpoints;
  }

  /**
   * get participant list
   * @param listType: string - list type of the list
   * @param conference: IConference - the conference which the list belongs to
   */
  public static getParticipantList(listType: string, conference: IConferenceExpanded, localization: ILocalization) {
    switch (listType) {
      case "publicWait":
        return new ParticipantList(listType, this.getListEndpoints(listType, conference, localization), listType,
         localization.participant_panel?.listTypes?.[listType] ?? "Queue", false);
      case "op":
        return new ParticipantList(listType, this.getListEndpoints(listType, conference, localization), listType,
        localization.participant_panel?.listTypes?.[listType] ?? "Agents", true);
      case "sp":
        return new ParticipantList(listType, this.getListEndpoints(listType, conference, localization), listType,
          localization.participant_panel?.listTypes?.[listType] ?? "Administrative", true);
      case "ep":
      default:
        return new ParticipantList(listType, this.getListEndpoints(listType, conference, localization), listType,
        localization.participant_panel?.listTypes?.["ep"] ?? "All", true, true, 100);
    }
  }
  
  /**
   * get participant list endpoints
   * @param listType: string - list type of the list
   * @param conference: IConference - the conference which the list belongs to
   */
  private static getListEndpoints(listType: string, conference: IConferenceExpanded, localization: ILocalization) {
    let results: IEndpoint[] = [];
    let queue = conference as IExpandedQueueConference;
    let connectingWeight = 0;
    let holdWeight = 0;
    switch (listType) {
      case "publicWait":
        // Remove no-permission items
        results = _.filter(queue?.publicWaitList, (ep:IEndpoint) => {
          if (localization.participant_panel?.hideGuestsWithDeviceProblems) {
            if (ep.cameraPermission != CameraPermission.allowed && 
                ep.microphonePermission != CameraPermission.allowed) {
              return (PresenceState.dnd === Endpoint.getPresenceStateByStatus(ep.status) || PresenceState.chat === Endpoint.getPresenceStateByStatus(ep.status)) &&
                      (PresenceStatus.available != ep.status && PresenceStatus.online != ep.status);
            } else {
              return true;
            }
          } else {
            return true;
          }
        }) || [];
        connectingWeight = 20; // higher than connected
        holdWeight = 6; // higher than connected below connecting
        break;
      case "op":
        results = queue?.operators || [];
        connectingWeight = 5; // same as available
        break;
      case "sp":
        results = queue?.reps || [];
        connectingWeight = 5; // same as available
        break;
      default:
        results = conference?.everyone || [];
        connectingWeight = 5; // same as available
        break;
    }
  
    _.forEach(_.compact(results), (ep: any) => {
      ep.index = ep?.name ? ep.name.toLowerCase() : "";
      let epsortWeight = 0;

      if (ep.status === PresenceStatus.connecting ||
          ep.status === PresenceStatus.ringing || 
          ep.status === PresenceStatus.callback) {
        epsortWeight += connectingWeight;
      }
      if (ep.status === PresenceStatus.busy) {
        epsortWeight += 15;
      }
      if (ep.status === PresenceStatus.onhold) {
        epsortWeight += 10 + holdWeight;
      }
      if (ep.status === PresenceStatus.observing) {
        epsortWeight += 6;
      }
      if (ep.status === PresenceStatus.available) {
        epsortWeight += 5;
      }

      if (ep.status === PresenceStatus.custom1 ||
        ep.status === PresenceStatus.custom2 ||
        ep.status === PresenceStatus.custom3 ||
        ep.status === PresenceStatus.custom4 ||
        ep.status === PresenceStatus.ready ||
        ep.status === PresenceStatus.away) {
          epsortWeight += 1;
      }
     ep.sortWeight = epsortWeight;
    });
    results = _.orderBy(results, ["sortWeight", "index"], ["desc", "asc"]);
    return results;
  }
}
