/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Injectable } from "@angular/core";
import { Companion, IUser } from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { ActionType, Dispatcher } from "../../shared/services/dispatcher";
import { TimeService } from "../../shared/services/time.service";
import { format, parse } from "date-fns";

@Injectable()
export class TopBarService {
  currentUser: IUser = Companion.getUserService().currentUser;

  topBarMenuItems: TopBarMenuItem[];

  topBarUpdateTimer: any;

  constructor(public localizationService: LocalizationService, public timeService: TimeService) {
    Dispatcher.register(ActionType.LoadTopBarMenu, this.LoadTopBarMenu.bind(this));
    Dispatcher.register(ActionType.OpenNotification, this.OpenNotification.bind(this));
    Dispatcher.register(ActionType.ResetTopBarNotification, this.ResetTopBarNotification.bind(this));
  }

  LoadTopBarMenu(payload: any) {
    clearTimeout(this.topBarUpdateTimer);
    this.topBarMenuItems = [
      {
        key: TopBarMenuItemKey.SystemInfo,
        hidden: !this.currentUser.isAuthenticated,
        title: format(parse((Date.now() - this.timeService.serverTimeDelay).toString(), "T", new Date()), "yyyy-MM-dd, h:mm:ss a"),
        color: this.localizationService.getValueByPath(".topnav.fontColor")
      },
      {
        key: TopBarMenuItemKey.AccountInfo,
        hidden: !this.currentUser.isAuthenticated,
        title: this.currentUser.username + (this.currentUser.company ? " | " + this.currentUser.company : ""),
        color: this.localizationService.getValueByPath(".topnav.fontColor"),
        clickAction: ActionType.OpenMyAccount
      },
      {
        key: TopBarMenuItemKey.Notification,
        hidden: !this.currentUser.isAuthenticated || !this.currentUser.permissions.seeSystemNotification,
        icon: "far fa-envelope",
        color: this.localizationService.getValueByPath(".topnav.fontColor"),
        clickAction: ActionType.OpenNotification
      }
    ];
    this.topBarUpdateTimer = setTimeout(this.LoadTopBarMenu.bind(this), 1 * 1000);
  }

  OpenNotification(payload: any) {
    this.resetTagCount(TopBarMenuItemKey.Notification, 0);
    Dispatcher.dispatch(ActionType.OpenModalDialog, "notification");
  }

  /**
   * reset top bar notification
   */
  ResetTopBarNotification(payload: any) {
    this.resetTagCount(payload.key, payload.value, payload.color);
  }

  /**
   * reset tag count
   */
  resetTagCount(itemKey: TopBarMenuItemKey, value: number = 0, color: string = "danger") {
    this.updateMenuItem({ key: itemKey, tagCount: value, tagColor: color });
  }

  /**
   * update menu item by key
   */
  updateMenuItem(item: TopBarMenuItem) {
    if (!item) {
      return;
    }
    let curItems: TopBarMenuItem[] = _.filter(this.topBarMenuItems, { key: item.key });
    _.forEach(curItems, (curItem: TopBarMenuItem) => {
      _.assignIn(curItem, item);
    });
  }
}
export enum TopBarMenuItemKey {
  SignIn,
  SignOut,
  AccountInfo,
  SystemInfo,
  Notification
}
export interface TopBarMenuItem {
  key: TopBarMenuItemKey;
  title?: string;
  icon?: string;
  color?: string;
  clickAction?: ActionType;
  tagColor?: string;
  tagCount?: number;
  hidden?: boolean;
  payload?: any;
}
