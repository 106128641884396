<!--
    Copyright Compunetix Incorporated 2022
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  amaggi, frivolta
-->

<div class="reports-container">

  <div class="container-fluid h-100" style="padding-bottom: 40px;">
    <div class="row h-100">
      <div class="col-md-3">
        <ul class="list-unstyled">
          <li>
            <input
              type="text"
              #drp="bsDaterangepicker"
              placeholder="Select a range"
              class="form-control range-picker"
              placement="right"
              bsDaterangepicker
              [bsConfig]="rangePickerConfig"
              [maxDate]="today"
              [(bsValue)]="selectedRange"
              (bsValueChange)="datePickerChanged()"
            >
          </li>
          <li>
             <div>
                 <fieldset class="border rounded-3">
                    <legend class="float-none w-auto px-3">System Report Frequency</legend>
                <label *ngFor="let systemReportFrequencyOption of systemReportFrequencyOptions; let idx = index" class="col-3">
                   <input type="radio" 
                          [(ngModel)]="selectedSystemReportFrequency"
                          name="systemReportFrequencySelection"
                          [disabled]="!DisplaySystemReportFrequencyOptions"
                          [checked]="(idx === 0)"
                          [value]="systemReportFrequencyOption"
                          (change)="SystemReportFrequencyChange()"> 
                   {{systemReportFrequencyOption}}
                </label>
                 </fieldset>
             </div>
          </li>
          <li *ngFor="let report of reports">
            <button class="btn-report btn box-shadow bg-white"
                    [ngClass]="{'active': report.title === selectedReport?.title}"
                    (click)="selectReport(report)">{{report.title}}</button>
          </li>
        </ul>
      </div>
      <div class="col-md-9 h-100">
        <div class="box-shadow preview-report bg-white h-100"
             [ngClass]="{'fullscreen': isFullscreen}">
          <div class="d-flex justify-content-between mb-2">
            <div>
              <button class="btn btn-link" (click)="reportFullScreen()">
                <img src="/images/dashboard/report-full.png" width="20" />
              </button>
              <button type="button" class="btn-download btn btn-primary text-uppercase"
                      [disabled]="GeneratePreviewOrDownloadIsDisabled"
                      (click)="generateReportPreview()">
                Generate Preview
                <span *ngIf="isDownloading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div>
            <div>
              <span [ngClass]="rangePreview?.subset ? 'pl-4 text-danger' : 'pl-4 text-success'" *ngIf="rangePreview">
                <span>{{rangePreview.start | date : 'mediumDate'}}</span> - <span>{{rangePreview.endDisplay | date : 'mediumDate'}}</span>
              </span>
            </div>
            <div>
              <button type="button" class="btn-download btn btn-primary text-uppercase"
                      [disabled]="GeneratePreviewOrDownloadIsDisabled"
                      (click)="download()">
                Download
                <span *ngIf="isDownloading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          <div class="table">
            <div class="h-100" *ngIf="PreviewAvailable; else emptyTpl">
              <ngx-datatable
                #reportDatatable
                class="material striped expandable mb-4 h-100"
                [rows]="dataRows"
                [columns]="columns"
                columnMode="force"
                [headerHeight]="50"
                [footerHeight]="50"
                [rowHeight]="50"
                [scrollbarV]="true"
                [scrollbarH]="true"
              >
              </ngx-datatable>
            </div>

            <ng-template #emptyTpl>
              <div>
                <div class="empty-report"></div>
                <div class="message">
                  Select report type, time range [and frequency] and select "Generate Preview"
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
