/**
 * Copyright Compunetix Incorporated 2017-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  Ramadevi Gavirneni
 */
import {Component, EventEmitter, Input, Output} from "@angular/core";
import { IEndpoint, Companion, ISkillSet, ISkillProficiency, StatsUtil, ConferenceUtil } from "../../../companion";
import { CallCenterService } from "client/scripts/call-center/call-center.service";
import { IEndpointRef } from "companion/endpoint/endpoint.interface";
import { LocalizationService } from "../localization/localization.service";

@Component({
  selector: "operator-info",
  templateUrl: "./operator-info.template.html"
})
export class OperatorInfoComponent {
  @Input() endpoint: IEndpoint;
  @Input() canSendLogs: boolean;
  @Output("requestToSendLogs") requestToSendLogs: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  constructor(private callCenterService: CallCenterService, protected localizationService: LocalizationService) {}

  /** This is used in the template to decide if we are displaying a conference (you will appear in the list but don't have any stats).
   * If you have more than 1 entry (in conference and you asked for your own info). you will get extra info in the display about who the stats are for.
   */
  get statsEps(): IEndpoint[] {
    return _.compact(_.map(this.callCenterService.getStatsDisplayEndpointsList(this.endpoint), (id) => {
      return Companion.getEndpointService().getEndpointById(id);
    }));
  }

  connectionInfoHtml(ep: IEndpoint) : string {
    if (ep.callQuality)
      return StatsUtil.htmlQualityData(ep.callQuality)
    return ""
  }

  epCallQualityInboundValue(ep: IEndpoint): number {
    if (ep?.callQuality)
      return ep.callQuality?.mos?.[0]?.inbound;
    return 0;
  }
  epCallQualityOutboundValue(ep: IEndpoint): number {
    if (ep?.callQuality)
      return ep.callQuality?.mos?.[0]?.outbound;
    return 0;
  }

  trackByEndpoint(index: number, endpoint: IEndpoint) {
    return endpoint.rtcId;
  }

  sendLogs() {
    this.requestToSendLogs.emit(this.endpoint);
  }

  getSkillCategoryString(tags: ISkillSet): string {
    return ConferenceUtil.getSkillSetCategoryToString(tags);
  }

  getSkillLanguageString(tags: ISkillSet): string {
    return ConferenceUtil.getSkillSetLanguageToString(tags);
  }

  getOperatorIdentString(rtcId: string, callSid: string): string {
    let result = ""
    if (rtcId) {
      result += rtcId;
    }
    if (callSid) {
      if (rtcId) {
        result += " "
      }
      result += `(Voice: ${callSid})`;
    }
    return result;
  }

   getTargetEndpointsList(endpoint: IEndpoint): string {
      return this.callCenterService.getTargetEndpointsList(endpoint);
   }

   getHoldEndpointsList(endpoint: IEndpoint): string
   {
     return this.callCenterService.getHoldEndpointsList(endpoint);
   }
}
