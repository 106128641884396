/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {AlertCode, Companion, ILocalization, IMenuItem, PresenceStatus} from "companion";

import { LocalizationService } from "../../localization/localization.service";
import { RestService } from "../../shared/services/rest.service";
import { UserManagementService } from "../../user-management/user-management.service";
import { Dispatcher, ActionType } from "../../shared/services/dispatcher";

@Component({
  selector: "top-bar",
  templateUrl: "./top-nav-login-bar.template.html"
})
/**
 * top nav login bar
 */
export class TopNavLoginBarComponent implements OnInit {
  /**
   * flag if view is in locked mode
   */
  @Input() isLocked: boolean;

  /**
   * flag if nav shown
   */
  @Input() isNavShown: boolean = true;

  /**
   * flag if user is logged in
   */
  @Input() isLoggedin: boolean;

  /**
   * list of accessible themes to choose from
   */
  @Input() accessibleThemes: string[];

  /**
   * theme filter
   */
  @Input() themeFilter: string;

  /**
   * my endpoint current status
   */
  @Input() presenceStatus: PresenceStatus;

  /**
   * flag if it's mobile app
   */
  @Input() isMobile: boolean = false;

  /**
   * flag if it's in config page
   */
  @Input() isConfig: boolean = true;

  /**
   * flag if current view mode supports presence status changed by user through this view
   */
  @Input() statusChangeable: boolean;

  /**
   * emit event when language changed
   */
  @Output("languageChanged") languageChangedEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * emit event when theme language changed
   */
  @Output("themeChanged") themeChangedEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * emit event when theme filter changed
   */
  @Output("themeFilterChanged") themeFilterChangedEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * logout event emitter
   */
  @Output("logout") logoutEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output("updateMyStatus") updateMyStatusEmitter: EventEmitter<PresenceStatus> = new EventEmitter<PresenceStatus>();

  /**
   * style
   */
  style: string;

  /**
   * language
   */
  language: string;

  constructor(
    private router: Router,
    public localizationService: LocalizationService,
    private restService: RestService,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute
  ) {
    // nothing needed here
  }

  /**
   * close login modal
   */
  closeLoginModal() {
    jQuery("#loginModal").modal("hide");
  }

  /**
   * log out
   */
  logout(): void {
    Dispatcher.dispatch(ActionType.LogOut);
    this.logoutEmitter.emit(true);
  }

  /**
   * language changed handler
   */
  languageChanged(code: string) {
    if (this.language != code) {
      this.language = code;
      this.themeUpdate();
    }
  }

  /**
   * theme filter changed handler
   */
  themeFilterChanged(theme: string) {
    this.themeFilterChangedEmitter.emit(theme);
  }

  themeUpdate() {
    this.localizationService
    .getLocalizationData(this.style, this.language)
    .catch((err: Error) => {
      return Promise.reject(err);
    })
    .then((data: ILocalization) => {
      this.languageChangedEmitter.emit(this.language);
      this.themeChangedEmitter.emit(this.style);
    })
    .catch((err: Error) => {
      Companion.getConferenceService().alertHandler(AlertCode.getLocalizationDataFail,
        this.style + ", " + this.language
      );
      this.router.navigate(["/not-found"]);
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.style = this.route.snapshot.params["style"] || this.localizationService.myLocalizationData.style;
      this.language = this.route.snapshot.params["language"] || this.localizationService.myLocalizationData.language;
      if (this.style) {
        this.themeUpdate();
      } else {
        this.style = "default";
        this.themeUpdate();
      }
    });
  }

  updateMyStatus(status: PresenceStatus) {
    this.updateMyStatusEmitter.emit(status);
  }

  openMenu(menu: IMenuItem) {
    window.open(menu.url);
  }
}
