/**
 * Copyright Compunetix Incorporated 2016
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
  selector: "chatroom-searchbox",
  template:
    '<div><input #input type="text" name="searchBox" class="form-control searchbox"' +
    ' placeholder="search" (input) = "update.emit(input.value)" /></div>'
})

/**
 * Chat room search box
 */
export class ChatRoomSearchBox implements OnInit {
  @Output() update = new EventEmitter();

  /**
   * component load handler
   */
  ngOnInit() {
    this.update.emit("");
  }
}
