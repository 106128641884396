/**
 * Copyright Compunetix Incorporated 2017 - 2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {IParameter, ParameterType} from "companion";
import { FieldComponent } from "./field.component";

@Component({
  selector: "form-panel",
  templateUrl: "./form.template.html"
})

/**
 * generic form
 */
export class FormComponent {
  /**
   * delete event emitter
   */
  @Output("delete") deleteEventEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  /**
   * save event emitter
   */
  @Output("save") saveEventEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  /**
   * second save event emitter
   */
  @Output("secondSave") secondSaveEventEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  /**
   * cancel event emitter
   */
  @Output("cancel")
  cancelEventEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  /**
   * second cancel event emitter
   */
  @Output("secondCancel")
  secondCancelEventEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  /**
   * import event emitter
   */
  @Output("import") importEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  /**
   * export event emitter
   */
  @Output("export") exportEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  /**
   * change event emitter
   */
  @Output("valueChanged") changeEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * form parameters
   */
  @Input() parameters: { [key: string]: IParameter };

  /**
   * the form
   */
  @Input() form: FormGroup;

  /**
   * flag if delete is enabled
   */
  @Input() deleteEnabled: boolean = true;

  /**
   * flag if cancel is enabled
   */
  @Input() cancelEnabled: boolean = true;

  /**
   * flag if save is enabled
   */
  @Input() importExportEnabled: boolean = true;

  /**
   * flag if display with accordion layout
   */
  @Input() inAccordionLayout: boolean = true;

  /**
   * flag if top control buttons shown
   */
  @Input() topControlsEnabled: boolean = true;

  /**
   * flag if save button is enabled
   */
  @Input() saveButtonEnabled: boolean = true;

  /**
   * save button text
   */
  @Input() saveButtonText: string = "Save";

  /**
   * save button class
   */
  @Input() saveButtonClass: string;

  /**
   * second save button text
   */
  @Input() secondSaveButtonText: string;

  /**
   * second save button class
   */
  @Input() secondSaveButtonClass: string;

  /**
   * cancel button text
   */
  @Input() cancelButtonText: string = "Cancel";

  /**
   * cancel button class
   */
  @Input() cancelButtonClass: string;

  /**
   * second cancel button text
   */
  @Input() secondCancelButtonText: string;

  /**
   * second cancel button class
   */
  @Input() secondCancelButtonClass: string;

  /**
   * flag if bottom buttons shall be small
   */
  @Input() bottomButtonSmall: boolean = false;

  /**
   * flag if editing form
   */
  @Input() isEditing: boolean = false;

  @ViewChildren("FormField") formFields: QueryList<FieldComponent>;

  togglePasswords: boolean;

  /**
   * check if this field is valid
   */
  get isValid(): boolean {
    let anyErrorMessage = _.find(this.parameters, (p: IParameter) => {
      return !!p.errorMessage;
    });
    return anyErrorMessage == null && this.form.valid;
  }

  /**
   * save
   */
  save() {
    this.saveEventEmitter.emit(this.form);
  }

  /**
   * second save
   */
  secondSave() {
    this.secondSaveEventEmitter.emit(this.form);
  }

  /**
   * delete this form
   */
  delete() {
    this.deleteEventEmitter.emit(this.form);
  }

  /**
   * cancel any modification
   */
  cancel() {
    this.cancelEventEmitter.emit();
  }

  /**
   * second cancel any modification
   */
  secondCancel() {
    this.secondCancelEventEmitter.emit();
  }

  /**
   * export current view data
   */
  export() {
    this.exportEventEmitter.emit();
  }

  /**
   * change event on any field of the form
   */
  valueChanged(fieldKey: string) {
    this.changeEventEmitter.emit(fieldKey);
  }

  /**
   * import
   */
  import(fileInput: any) {
    if (!fileInput.target.files || fileInput.target.files.length === 0) {
      return;
    }
    let localizationFile: File = fileInput.target.files[0];
    var reader = new FileReader();
    reader.onload = this.onFileLoaded.bind(this);
    reader.readAsText(localizationFile);
  }

  /**
   * update view from uploaded file
   */
  onFileLoaded(event: any) {
    this.importEventEmitter.emit(event);
  }

  focusOnFirstField() {
    if (this.formFields) {
      let focusableField = this.formFields.find((field: FieldComponent) => {
        return field.isFocusable;
      });
      if (focusableField) {
        focusableField.focus();
      }
    }
  }

  toggleLockables(enabled: boolean) {
    this.togglePasswords = false;
    const enablerTimer = setTimeout(() => {
      clearTimeout(enablerTimer);
      this.togglePasswords = enabled;
    }, 100);

  }
}
