<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
  -->
<div class="container">
  <div class="row">
    <div class="col-12">
      <form
        (ngSubmit)="onSubmit()"
        #roleDetailsForm="ngForm"
        autocomplete="off"
      >
        <div class="row">
          <h4 class="col-sm-12">Role Name</h4>
          <div class="form-group col-sm-12">
            <input
              type="text"
              class="form-control"
              required
              name="roleName"
              [(ngModel)]="role.name"
              #roleName="ngModel"
              placeholder="Role Name"
            >
            <div
              [hidden]="roleName.valid || roleName.pristine"
              class="alert alert-danger"
            >
              Name is required
            </div>
          </div>
          <h4 class="col-sm-12">Permissions</h4>
          <div
            class="form-group col-sm-4"
            *ngFor="let permissionKey of allPersmissions | keys"
          >
            <label>
              {{permissionKey}}
            </label>
            <select
              [(ngModel)]="role.permissions[permissionKey]"
              name="{{permissionKey}}"
              class="form-control"
            >
              <option value=0>None</option>
              <option value=1>In Group</option>
              <option value=2>Public</option>
              <option value=3>In Group Or Public</option>
              <option value=4>Always</option>
            </select>
          </div>
          <h4 class="col-sm-12">Roles Managable</h4>
          <div
            class="form-group col-sm-4"
            *ngFor="let role of roles"
          >
            <label class="custom-control custom-checkbox">
              <input
                type="checkbox"
                name="{{'assignable-role-' + role.name}}"
                [(ngModel)]="assignableRoles[role['_id']]"
              >
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description">
                {{role.name}}
              </span>
            </label>
          </div>
          <div class="form-group col-sm-12">
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="!roleDetailsForm.form.valid"
            >Submit</button>
            <button
              type="button"
              (click)="onCancel()"
              class="btn btn-primary"
            >Cancel</button>
          </div>
        </div>
      </form>
    </div>
    <div
      [hidden]="!submitted"
      class="alert alert-info full-width"
    >
      <h2>Wait</h2>
    </div>
    <div
      [hidden]="!failed"
      class="alert alert-danger full-width"
    >
      <h2>
        {{errorMessage}}
      </h2>
    </div>
  </div>
</div>