<div
  class="card"
  [ngClass]="{
  'realtime': tileStyle === 'realtime',
  'total1' : tileStyle === 'dailyTotal1',
  'total2' : tileStyle === 'dailyTotal2'
  }"
>
  <div class="card-body">
    <div class="title">{{title}}</div>

    <div *ngIf="tileStyle === 'realtime'" class="tile-realtime">
      <div class="value">{{value}}</div>
      <img *ngIf="icon" [src]=icon [alt]="title" [title]="title" />
      <div *ngIf="subLabel" class="total-maximum">
        <div class="subLabel">{{subLabel}}</div>
        <div class="value subValue">{{subValue}}</div>
      </div>
    </div>

    <div *ngIf="tileStyle === 'dailyTotal1'"  class="tile-total1">
      <div class="total-average">
        <div class="label">{{label}}</div>
        <div class="value">{{value | formatTime}}</div>
      </div>
      <div class="total-maximum">
        <div class="subLabel">{{subLabel}}</div>
        <div class="value subValue">{{subValue | formatTime}}</div>
      </div>
    </div>

    <div *ngIf="tileStyle === 'dailyTotal2'"  class="tile-total2">
      <div class="value">{{value}}</div>
      <img [src]=icon [alt]="title" [title]="title" />
    </div>

    <perc-compare [percentageData]="comparePercentage"></perc-compare>
    <perc-compare [percentageData]="comparePercentage2"></perc-compare>

    <ng-content></ng-content>
  </div>
</div>
