/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IRole } from "./role.interface";
export class Role implements IRole {
  constructor(
    // role name
    public name: string,
    // permissions
    public permissions: any = Object(),
    // list of role ids assignable to users
    public rolesAssignable: string[] = []
  ) {
    // nothing to do
  }
}
