<!--
    Copyright Compunetix Incorporated 2017-2024
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender
-->
<div class="guest-item-container">
  <div class="info-section">
    <div
    class="avatar badge badge-success"
    *ngIf="endpoint.isVoiceEp"
    style="font-size: 1.9rem"
    (click)="openGuestInfoModal()"
    >
      <i class="fas fa-phone-alt"></i>
      <div [ngSwitch]="endpoint.voiceDirection" class="voice-badge">
        <span *ngSwitchCase="endpoint.voiceDirection === VoiceDirection.DialIn">DIAL IN<</span>
        <span *ngSwitchCase="endpoint.voiceDirection === VoiceDirection.DialOut">DIAL OUT</span>
        <span *ngSwitchCase="endpoint.voiceDirection === VoiceDirection.CallBack">CALL BACK</span>
      </div>
    </div>
    <div
      class="avatar badge badge-info"
      *ngIf="!endpoint.isVoiceEp"
      style="font-size: 1.9rem"
      (click)="openGuestInfoModal()"
    > 
        <i class="fas fa-desktop" [hidden]="!!endpoint?.deviceInfo?.isMobile" style="vertical-align: sub"></i>
        <i class="fas fa-mobile-alt" [hidden]="!endpoint?.deviceInfo?.isMobile" style="vertical-align: sub"></i>
      <div>
        <ng-container *ngIf="endpointService.connectedWithMe(endpoint)">
          <meter-gauge
            canvasHeight=20
            canvasWidth=40
            [max]="gaugeOptions.maxValue"
            min=0
            [options]="gaugeOptions"
            [value]="epGaugeValue">
          </meter-gauge>
        </ng-container>
      </div>
    </div>
    <div class="d-flex">
      <div class="name-section">
        <label [title]="safeName" [innerHTML]="endpoint.uiName | safeHtml | truncate:20:'...'"></label>
        <label [hidden]="!endpoint.skillTags || (!endpoint.skillTags.language && !endpoint.skillTags.category)" 
        title="{{categoryAndLanguage}}" [innerHTML]="categoryAndLanguage"></label>
        <div class="control-section">
          <i class="fas fa-info-circle" *ngIf="showDevicesWarning"
            style="font-size: 1.3rem;"
            [title]="endpoint.cameraPermission == CameraPermission.disallowed ? 'Camera or microphone permission not allowed' : 'Camera and microphone permission pending'"
            [ngClass]="{
            'text-danger': endpoint.cameraPermission == CameraPermission.disallowed
            }">
          </i>
          <a
            tabindex
            title="Toggle Remote Control"
            class="btn btn-link p-0"
            (click)='toggleRemoteControl()'
            *ngIf="endpoint.remoteIdentity"
          >
            <svg-icon class="svg-icon" src="/images/connect_now/share.svg" [svgStyle]="{ 'width.px':16 }" [applyClass]="true"></svg-icon>
          </a>
          <a
            tabindex
            title="Toggle Notepad"
            class="btn btn-link p-0"
            *ngIf="notepadViewEnabled"
            (click)='toggleNotepad()'
          >
            <i class="far fa-sticky-note" aria-hidden="true" [hidden]="isNotepadShown"></i>
            <i class="fas fa-sticky-note" aria-hidden="true" [hidden]="!isNotepadShown"></i>
          </a>
          <a
            tabindex
            [title]="localizationService.myLocalizationData.participant_panel?.chatWithConjunctionText||'Chat With' + endpoint.uiName"
            class="btn btn-link p-0"
            *ngIf="endpointService.connectedWithMe(endpoint)"
            (click)='peerChat(endpoint)'
          >
            <i class="fa-regular fa-comment" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
   <div class="d-flex align-items-start">
     <div class="present-section no-overflow">
       <label
         class="tag"
         [ngClass]="'text-' + (endpoint.status | labelStyle)"
       >
          <span class="text-uppercase text-default" [hidden]="!localizationService.myLocalizationData.map_panel || endpoint.location">
            {{localizationService.myLocalizationData?.errorMessages?.["NO_LOCATION"] || "NO LOCATION"}}
          </span>
         <span [title]="localizationService.myLocalizationData.presenceStatus[presenceStatusClass[endpoint.status]] || presenceStatusClass[endpoint.status]"
               class="text-uppercase" *ngIf="endpoint.status !== presenceStatusClass.busy"
         >
            {{localizationService.myLocalizationData.presenceStatus[presenceStatusClass[endpoint.status]] || presenceStatusClass[endpoint.status]}}
           <span class="text-capitalize name-string" *ngIf="endpoint.status === presenceStatusClass.onhold"><br><span class="text-lowercase">with</span> {{getAssociatedConfName() | truncate:18:'...'}}</span>
          </span>
         <span [title]="localizationService.myLocalizationData.participant_panel?.participantStatusText || 'CONFERENCING'"
               *ngIf="endpoint.status === presenceStatusClass.busy && getAssociatedConfName()"
         >
            {{ localizationService.myLocalizationData.participant_panel?.participantStatusText || "CONFERENCING"}}
          </span><br>
         <span class="name-string"
               [title]="getAssociatedConfName()"
               *ngIf="(endpoint.status === presenceStatusClass.busy || endpoint.status === presenceStatusClass.callback_connected_external)
                  && getAssociatedConfName()"
               [innerHTML]="getAssociatedConfName()"
         >
          </span>
         <span class="time-string">
            {{timeString}}
          </span>
       </label>
     </div>
   </div>
  </div>
  <div class="buttons-section margin-top text-center">
    <button
      tabindex
      [title]="localizationService.myLocalizationData.participant_panel?.selectButtonText || 'Select'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      *ngIf="
      (viewMode === 'op' || viewMode === 'sp')
      && !transferHasSelected
      && getState() !== presenceStateClass.away
      && endpoint.status !== presenceStatusClass.ringing
      && endpoint.status !== presenceStatusClass.connecting
      && endpoint.status !== presenceStatusClass.callback_connected_external
      && endpointService.connectedWithMe(endpoint)"
      (click)='toggleTransferSelection(endpoint)'
    >
      {{localizationService.myLocalizationData.participant_panel?.selectButtonText || 'Select'}}
    </button>
    <button
      tabindex
      [title]="localizationService.myLocalizationData.participant_panel?.unselectButtonText || 'Unselect'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      *ngIf="(viewMode === 'op' || viewMode === 'sp') && transferHasSelected && endpointService.connectedWithMe(endpoint)"
      (click)='toggleTransferSelection(null)'
    >
      {{ localizationService.myLocalizationData.participant_panel?.unselectButtonText || 'Unselect'}}
    </button>

    <button
      tabindex
      [title]="localizationService.myLocalizationData.participant_panel?.transferToQueueButtonText || 'Transfer'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      *ngIf="queueTransferEnabled"
      (click)="startTransfer(endpoint)"
    >
      {{ localizationService.myLocalizationData.participant_panel?.transferToQueueButtonText || 'Transfer'}}
    </button>

    <button
      tabindex
      [title]="endpoint.cameraPermission ? (localizationService.myLocalizationData.participant_panel?.connectButtonText || 'Connect') :
               'Connection not possible due to camera and microphone permission issue'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      [attr.disabled]="disableConnect(endpoint)?'':null"
      *ngIf="
      endpoint.status !== presenceStatusClass.callback_connected_external &&
      getState() !== presenceStateClass.dnd
      && getState() !== presenceStateClass.away
      && (getMyState() !== presenceStateClass.dnd || endpointService.myEndpoint.isInCustomState)
      && !transferHasSelected
      && !isMaxParticipantsReached"
      (click)='peerVideoChat(endpoint)'
    >
      {{ (endpoint.status === presenceStatusClass.callback && !localizationService.myLocalizationData.twilioConfig) ? 
        (localizationService.myLocalizationData.participant_panel?.callbackHandleExternalText || 'Callback External') : 
        (localizationService.myLocalizationData.participant_panel?.connectButtonText || 'Connect')}}
    </button>

    <button
      tabindex
      class="btn btn-outline-primary btn-control wrapOrHide"
      *ngIf="
      (endpoint.status === presenceStatusClass.callback_connected_external)
      && !transferHasSelected
      && endpointService.connectedWithMe(endpoint)"
      (click)='disconnectPeer(endpoint)'
    > 
    {{ localizationService.myLocalizationData.participant_panel?.callbackConfirmButtonText || 'Acknowledge'}}
    </button>

    <button
      tabindex
      [title]="localizationService.myLocalizationData.participant_panel?.disconnectButtonText || 'Disconnect'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      *ngIf="
      getState()===presenceStateClass.dnd
      && endpoint.status!==presenceStatusClass.ringing
      && endpointService.connectedWithMe(endpoint)
      && !transferHasSelected"
      (click)='disconnectPeer(endpoint)'
    >
      {{ localizationService.myLocalizationData.participant_panel?.disconnectButtonText || 'Disconnect'}}
    </button>
    <button
      tabindex
      [title]="localizationService.myLocalizationData.participant_panel?.disconnectButtonText || 'Disconnect'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      *ngIf="
      getState()===presenceStateClass.dnd
      && endpoint.status===presenceStatusClass.ringing
      && endpoint.dialOutInviterRtcId === endpointService.myEndpoint.rtcId
      && !transferHasSelected"
      (click)='hangupRingingCall(endpoint)'
    >
      {{ localizationService.myLocalizationData.participant_panel?.disconnectButtonText || 'Disconnect'}}
    </button>
    <button
      tabindex
      [title]="localizationService.myLocalizationData.participant_panel?.holdButtonText || 'Hold'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      *ngIf="
      endpointService.connectedWithMe(endpoint)
      && endpoint.status !== presenceStatusClass.callback_connected_external
      && !endpointService.inHoldWithMe(endpoint)
      && !transferHasSelected"
      (click)="putOnHold(endpoint, true)"
    >
      {{ localizationService.myLocalizationData.participant_panel?.holdButtonText || 'Hold'}}
    </button>
    <button
      tabindex
      [title]="localizationService.myLocalizationData.participant_panel?.resumeButtonText || 'Resume'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      [attr.disabled]="allowResume(endpoint)?null:''"
      *ngIf="
      endpointService.inHoldWithMe(endpoint)"
      (click)="putOnHold(endpoint, false)"
    >
      {{ localizationService.myLocalizationData.participant_panel?.resumeButtonText || 'Resume'}}
    </button>
    <button
      tabindex
      [title]="localizationService.myLocalizationData.participant_panel?.takeOverButtonText || 'Take Over'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      *ngIf="endpointService.availableForTakeOver(endpoint) &&
             getMyState()!==presenceStateClass.dnd"
      (click)="takeOver(endpoint)"
    >
      {{ localizationService.myLocalizationData.participant_panel?.takeOverButtonText || 'Take Over'}}
    </button>
    <button
      tabindex
      [title]="localizationService.myLocalizationData.participant_panel?.cameraSwitchButtonText || 'Switch Camera'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      *ngIf="cameraSwitchEnabled
      && getState()===presenceStateClass.dnd
      && endpointService.connectedWithMe(endpoint)
      && !transferHasSelected
      && !endpoint.isVoiceEp"
      [disabled]="switchProcessingTimeout"
      (click)='switchCamera()'
    >
      {{ localizationService.myLocalizationData.participant_panel?.cameraSwitchButtonText || "Switch Camera" }}
    </button>
    <button
    tabindex
    [title]="localizationService.myLocalizationData.participant_panel?.holdConferenceButtonText || 'Leave Conference'"
    class="btn btn-outline-primary btn-control wrapOrHide"
    *ngIf="
    getState() === presenceStateClass.dnd
    && endpointService.connectedWithMe(endpoint)
    && !endpointService.amISteppedAway(endpoint)"
    (click)='stepAwayFromConference(endpoint)'
  >
    {{ localizationService.myLocalizationData.participant_panel?.holdConferenceButtonText || 'Leave Conference'}}
  </button>
  <button
    tabindex
    [title]="localizationService.myLocalizationData.participant_panel?.resumeGuestConferenceButtonText || 'Rejoin Conference'"
    class="btn btn-outline-primary btn-control wrapOrHide"
    *ngIf="endpointService.amISteppedAway(endpoint) &&
           getMyState() !== presenceStateClass.dnd"
    (click)='rejoinConference(endpoint)'
  >
    {{ localizationService.myLocalizationData.participant_panel?.resumeGuestConferenceButtonText || 'Rejoin Conference'}}
  </button>
  </div>
</div>

<div
  class="modal fade"
  id="TransferQueueModal"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-content">
    <div class="modal-body bg-white">
      <!-- Iterate over the queus-->

      <!-- CANCEL BUTTON -->
      <button
      tabindex
      [title]="'Cancel'"
      class="btn btn-outline-primary btn-control wrapOrHide"
      (click)='cancelTransfer(endpoint)'>
      Cancel
    </button>        
    </div>
  </div>
</div>
