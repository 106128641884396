/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  jporter
 */

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { SharedModule } from "../../shared/shared.module";
import { LayoutModule } from "../../layout/layout.module";

import { SharedFolderPanelComponent } from "./sharedfolder-panel.component";
import { FileStatusStylePipe } from "./file-status-style.pipe";

@NgModule({
  imports: [BrowserModule, SharedModule, LayoutModule],
  declarations: [FileStatusStylePipe, SharedFolderPanelComponent],
  exports: [SharedFolderPanelComponent]
})
export class SharedFolderModule {}
