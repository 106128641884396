/**
 * Copyright Compunetix Incorporated 2020-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

var CryptoJS = require("crypto-js");
const crypto = require("crypto");
/**
 * crypto util
 */
export class CryptoUtil {
  /**
   * the CryptoJS lib
   */
  static CryptoJSLib: any;

  /**
   * init this crypto util with crypto lib
   * @param CryptoJSLib: any - the crypto js lib
   */
  static init(CryptoJSLib: any = CryptoJS) {
    this.CryptoJSLib = CryptoJSLib;
  }

  /**
   * encrypt plain text into cipher text
   * @param originalText: original plain text to encrypt
   * @param key: key to use for encryption
   */
  static encrypt(originalText: string, key: string): string {
    if (originalText == null) {
      return null;
    }
    var ciphertext = this.CryptoJSLib.AES.encrypt(originalText, key, {
      mode: this.CryptoJSLib.mode.ECB,
      padding: this.CryptoJSLib.pad.Pkcs7
      }).toString();
    return ciphertext;
  }

  /**
   * decrypt cipher text into plain text
   * @param cipherText: cipher text to decrypt
   * @param key: key to use for decryption
   */
  static decrypt(cipherText: string, key: string): string {
    if (cipherText == null) {
      return null;
    }
    var bytes  = this.CryptoJSLib.AES.decrypt(cipherText, key, {
      mode: this.CryptoJSLib.mode.ECB,
      padding: this.CryptoJSLib.pad.Pkcs7
      });
    var decryptedData = bytes.toString(this.CryptoJSLib.enc.Utf8);
    return decryptedData;
  }

  static generateBase64Key(utf8key: string) : string
  {
    return this.CryptoJSLib.enc.Base64.parse(utf8key);
  }

    
/**
 * helper function to encrypt the details. for a remote connection
 * @param value - remote connection paramater details...
 * @returns 
 */
  static encryptRemoteConnParams(params: any, secretKey : string) {
    const iv = crypto.randomBytes(16);
    // USE AES for now to match the guac server.
    const cipher = crypto.createCipheriv("AES-256-CBC", secretKey, iv);

    let crypted = cipher.update(JSON.stringify(params), 'utf8', 'base64');
    crypted += cipher.final('base64');
    console.log(iv);
    const data = {
        iv: iv.toString('base64'),
        value: crypted
    };

    return Buffer.from(JSON.stringify(data)).toString('base64'); //Buffer.from(JSON.stringify(value), 'base64').toString();
  };
}
