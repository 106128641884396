/**
 * Copyright Compunetix Incorporated 2016-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import {
  IEndpoint,
  ICoordinates,
  PresenceState,
  PresenceStatus,
  IConnector,
  IEndpointRef,
  StatusReason,
} from "./endpoint.interface";
import { IPosition } from "../video/position.interface";
import { Position } from "../video/position";
import { ICallQuality, IPCStats } from "../util/stats";
import { VideoMediaConnectionMode } from "../services/rtc.client.interface";
import * as _ from "lodash";
import { ClientState } from "../conference/client-sm";

export class Endpoint implements IEndpoint {
  constructor(
    public rtcId: string,
    public name?: string,
    public userId?: string,
    public phoneNumber?: string,
    public displayPhoneNumber?: string,
    public confId?: string,
    public confName?: string,
    public isMainConf?: boolean,
    public connections: string[] = [],
    public status: PresenceStatus = PresenceStatus.offline,
    public enteredOn: number = Date.now(),
    public transmitMode: VideoMediaConnectionMode = VideoMediaConnectionMode.Conference,
    public streams: {
      [streamId: string]: MediaStream;
    } = {},
    public browser?: string,
    public mic?: string,
    public camera?: string,
    public speaker?: string,
    public isMicMuted?: boolean,
    public isCameraMuted?: boolean,
    public isAudioMuted?: boolean,
    public isVideoMuted?: boolean,
    public position?: IPosition,
    public volume: number = 1,
    public showVideo: boolean = true,
    public mostRecentChatRoomId?: string,
    public theme?: string,
    public lastStats?: IPCStats,
    public callQuality?: ICallQuality,
    public isHost: boolean = false,
    public location?: ICoordinates,
    public passcode?: string,
    public applicationId?: string,
    public connectorInfo?: IConnector,
    public groupId?: string,
    public lastMessage?: string,
    public note?: string,
    public supportDualChannel: boolean = true
  ) {
    if (position === null) {
      this.position = new Position(0, 0, 0, 0);
    }
  }

  public get state(): PresenceState {
    return Endpoint.getPresenceStateByStatus(this.status);
  }

  public get isInCustomState(): boolean {
    return this.status === PresenceStatus.custom1 ||
           this.status === PresenceStatus.custom2 ||
           this.status === PresenceStatus.custom3 ||
           this.status === PresenceStatus.custom4;
  }

  public get uiName(): string {
    return [decodeURIComponent(this.name), this.phoneNumber].join(" ");
  }

  /**
   * get presence state value by status
   * @param status: PresenceStatus - the status value
   */
  static getPresenceStateByStatus(status: PresenceStatus): PresenceState {
    switch (status) {
      case PresenceStatus.onhold:
      case PresenceStatus.busy:
      case PresenceStatus.work:
      case PresenceStatus.recording:
      case PresenceStatus.observing:
      case PresenceStatus.renegotiating:
      case PresenceStatus.disconnecting:
      case PresenceStatus.ringing:
      case PresenceStatus.custom1:
      case PresenceStatus.custom2:
      case PresenceStatus.custom3:
      case PresenceStatus.custom4:
        return PresenceState.dnd;
      case PresenceStatus.connecting:
      case PresenceStatus.callback:
      case PresenceStatus.callback_connected_external:
      case PresenceStatus.available:
      case PresenceStatus.ready:
      case PresenceStatus.online:
        return PresenceState.chat;
      case PresenceStatus.away:
      case PresenceStatus.invisible:
        return PresenceState.away;
      case PresenceStatus.offline:
      default:
        return PresenceState.xa;
    }
  }

  static getPresenceFromClientState(state: ClientState, reason : StatusReason, isVoiceEp : boolean): PresenceStatus {
    switch (state) {
      case ClientState.available:
        return PresenceStatus.available;
      case ClientState.ask_to_answer_queue:
      case ClientState.ask_to_answer_transfer:
      case ClientState.available_ringing:
      case ClientState.unavailable_ringing:
      case ClientState.conferencing_wait_head_of_line:
      case ClientState.conferencing_wait_ringing:
        if(isVoiceEp)
        {
          return PresenceStatus.callback;
        }
        return PresenceStatus.ringing;
      case ClientState.conferencing:
        if(reason === StatusReason.on_hold)
        {
          return PresenceStatus.onhold;
        }
        if(reason === StatusReason.callback_external)
        {
          return PresenceStatus.callback_connected_external;
        }
        return PresenceStatus.busy;
      case ClientState.transfer_pending:
        return PresenceStatus.onhold; // for now we put this guy on hold.
      case ClientState.conferencing_intent:
      case ClientState.conferencing_intent_pending_invite_reply:
      case ClientState.conferencing_wait_in_line:
        if(isVoiceEp)
        {
          return PresenceStatus.callback;
        }
        return PresenceStatus.connecting;
      case ClientState.conferencing_intent_pending_invite_reply_drop_req:
      case ClientState.conferencing_drop_req:
        return PresenceStatus.disconnecting;
      case ClientState.unavailable:
        switch (reason) {
          case StatusReason.recess:
            return PresenceStatus.ready;
          case StatusReason.custom1:
            return PresenceStatus.custom1;
          case StatusReason.custom2:
            return PresenceStatus.custom2;
          case StatusReason.custom3:
            return PresenceStatus.custom3;
          case StatusReason.custom4:
            return PresenceStatus.custom4;
          case StatusReason.work:
            return PresenceStatus.work;
          default:
            return PresenceStatus.away;
        }
      case ClientState.offline:
      default:
        return PresenceStatus.away;
    }
  }

  static toRef(endpoint: IEndpoint) : IEndpointRef {
    if (endpoint) {
      let ref: IEndpointRef = {
        rtcId: endpoint.rtcId,
        userId: endpoint?.userId,
        name: endpoint?.name,
        isVoiceEp: endpoint?.isVoiceEp,
        twilioCallId: endpoint?.twilioCallId
      } as IEndpointRef;
      return ref;
    } else {
      return null;
    }
  }
}
