/**
 * Copyright Compunetix Incorporated 2022-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender
 */

import { Component, OnInit } from "@angular/core";
import { AlertService } from "client/scripts/alert/alert.service";
import { GroupManagementService } from "client/scripts/group-management/group-management.service";
import { LocalizationService } from "client/scripts/localization/localization.service";
import { ActionType, Dispatcher } from "client/scripts/shared/services/dispatcher";
import { Companion, ConferenceUtil, IEndpoint, ISkillSet, ISkillTags } from "companion";

@Component({
    selector: "transfer-dialog",
    templateUrl: "./transfer-dialog.template.html"
})

/**
 * Transfer dialog queue modal for transfering guest endpoints based on available skill Tags.
 */
export class TransferDialogComponent implements OnInit {
    // the set of skills associated with the current group.
    // used to build the transfer buttons.
    skillSet: ISkillSet;
    // the endpoint to be transfered
    endpoint: IEndpoint;

    confirmationShown: boolean = false;

    currentModalLanguage: string = null;
    currentModalCategory: string = null;

    // a map that tracks online operators and estimated wait time...
    categoryTransferData : Map<string, any> = null;

    constructor(private groupManagementService: GroupManagementService, private localizationService: LocalizationService) 
    {
        // nothing to do here.
    }

    ngOnInit() {
        // get the list
        Dispatcher.register(ActionType.OpenTransferQueueDialog, this.OpenTransferDialog.bind(this));
        this.resetModalSelections();
    }

    // show the transfer dialog
    OpenTransferDialog(payload: IEndpoint) {
        // get the endpoint that is to be transfered.
        this.endpoint = payload;

        // make sure we have the latest list of
        this.groupManagementService.getTransferDataForTheme(this.localizationService.myLocalizationData.style, this.endpoint.skillTags?.category)
        .then((data: any) => {
            this.skillSet = { category: data?.transferCategories, language: data.queueConfig?.skillSet?.language };
            this.categoryTransferData = data.transferData;

            if(data.queueConfig?.skillSet?.langauge?.length > 1 || data.queueConfig?.skillSet?.categorySubskills?.length > 1)
            {
                // if there is only one queue language or category automatically select it
                if(data.queueConfig?.skillSet?.language.length == 1)
                {
                    this.currentModalLanguage = data.queueConfig?.skillSet?.language[0];
                }

                $("#TransferQueueModal").modal("show");
            }
            else
            {
                // if there are no queue options, the transfer dialog is not needed, and instead we automatically transfer 
                // with no skills selected (return to queue.)
                this.transferToQueue(null);
            }

        })
        .catch((error) => {
          console.log("Failed to getGroupsQueueConfig: ", error);
        });
    }

    // the cancel button was clicked
    cancelTransfer() {
        $("#TransferQueueModal").modal("hide");
        this.resetModalSelections();
    }

    setModalLanguage(language: string) {
        this.currentModalLanguage = language;
    }

    setModalCategory(category: string) {
        this.currentModalCategory = category;
        let noOpMsg: string;
        let sameQueueMsg: string;
        if (this.categoryTransferData[this.currentModalCategory][this.currentModalLanguage].opCount == 0){
            noOpMsg = "There are no online operators in this queue."
        }

        if (this.endpoint.skillTags.category == this.currentModalCategory && this.endpoint.skillTags.language == this.currentModalLanguage){
            sameQueueMsg = "This will return the user to the end of the line in the same queue."
        }

        if (noOpMsg || sameQueueMsg) {
            this.confirmationShown = true;
            // This is a potentially detrimental situation, confirm it.
            AlertService.createAlertWithButtons(`You have selected ${this.currentModalCategory} - ${this.currentModalLanguage}. ${noOpMsg||""}${sameQueueMsg||""} Are you sure?`, {
                confirm: {
                    label: "Ok",
                    className: "btn-success",
                },
                cancel: {
                    label: "Cancel",
                    className: "btn-default",
                },
                }
            ).then((result: any) => {
                this.confirmationShown = false;
                if (result) {
                    this.transferToQueue({category: this.currentModalCategory, language: this.currentModalLanguage});
                } else {
                    this.resetModalSelections();
                }
            });
        } else {
            this.transferToQueue({category: this.currentModalCategory, language: this.currentModalLanguage})
        }
    }

    resetModalSelections() {
        this.currentModalCategory = null;
        this.currentModalLanguage = null;
    }

    transferToQueue(skillTags: ISkillTags) {
        // DISPATCH
        console.log("SEND TO QUEUE", skillTags, this.endpoint.rtcId);
        let queueName; 
        // Determine from transfer data if possible.
        if (this.categoryTransferData && Object.keys(this.categoryTransferData).length > 0) {
            queueName = this.categoryTransferData[skillTags?.category]?.[skillTags?.language]?.queueName;
        }

        if (!queueName) { // Try to generate from skill tags and group if we didn't get one.
            queueName = ConferenceUtil.GuestWaitRoomPrefix + ConferenceUtil.getQueueName(this.endpoint.groupId, skillTags);
        }
        Companion.getEndpointService().transferToQueue(this.endpoint, queueName);
        $("#TransferQueueModal").modal("hide");
        this.resetModalSelections();
        Dispatcher.dispatch(ActionType.TransferQueueSubmitted);
    }

    /**
     * Builds a string for the tooltip that shows the Agents, waiting guests, and
     * estimated wait time.
     */
    getDataForCategoryAndLanguage(category : string, language : string) : string
    {
        if(this.categoryTransferData && this.categoryTransferData[category])
        {
            let data : string;
            let opCount = this.categoryTransferData[category][language]?.opCount;
            let queueCount = this.categoryTransferData[category][language]?.queueCount;
            let waitInterval = this.categoryTransferData[category][language]?.waitTime;

            // if we have no operators, don't return status, just say NO Operator available and invalidate the button.
            if(opCount)
            {
                // the estimated wait time for our endpoint is the wait time multiplied the current queue count plus one
                // (since will be at the next position in that queue were we to transfer)
                let estimatedWaitTime = queueCount ? (queueCount + 1) * waitInterval : 0;
                data = "Agents: " +  opCount + "\n";
                data += "Waiting Guests: " + queueCount + "\n";
                data += "Wait time: " +  (estimatedWaitTime ?  (estimatedWaitTime + " Minutes"): "No Wait");
                // build the tool tip
                return data;
            }
            else
            {
                return "No Agents Available";
            }
        }
        return "Data Not Available";
    }

    /**
     * is operator available
     */
    public isOperatorAvailable(category : string, language : string)
    {
        return this.categoryTransferData[category]?.[language]?.opCount;
    }
}
