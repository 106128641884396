/**
 * Copyright Compunetix Incorporated 2020
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActionType, Dispatcher } from "client/scripts/shared/services/dispatcher";

@Component({
  selector: "pdf-viewer",
  templateUrl: "pdf-viewer.component.html"
})

/**
 * pdf viewer
 */
export class PDFViewerComponent {
  /**
   * src uri of pdf
   */
  pdfSrc: SafeUrl;

  /**
   * a timer to auto close the viewer
   */
  autoCloseTimer: any;

  constructor(private sanitizer: DomSanitizer) {
    Dispatcher.register(ActionType.OpenPDFViewer, (payload: { url: string, autoCloseAfterSec: number }) => {
      if (this.autoCloseTimer) {
        clearTimeout(this.autoCloseTimer);
      }
      if (payload.url) {
        this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(payload.url);
        Dispatcher.dispatch(ActionType.OpenModalDialog, "pdf-viewer-modal");
        let updateHeightTimer = setTimeout(() => {
          clearTimeout(updateHeightTimer);
          $("#pdfviewer").height($("#pdf-viewer-modal").height() * 0.75);
        }, 500);
        if (payload.autoCloseAfterSec) {
          this.autoCloseTimer = setTimeout(() => {
            clearTimeout(this.autoCloseTimer);
            Dispatcher.dispatch(ActionType.HideModalDialog, "pdf-viewer-modal");
          }, payload.autoCloseAfterSec * 1000);
        }
      } else {
        this.pdfSrc = null;
        Dispatcher.dispatch(ActionType.HideModalDialog, "pdf-viewer-modal");
      }
    });
  }
}
