/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { IRole } from "./role.interface";
import { IRoleService } from "./role.service.interface";

/**
 * role methods delegate
 */
export class RoleService implements IRoleService {
  private static sharedInstance: IRoleService;
  public roles: IRole[];

  constructor() {
    this.roles = [];
  }

  /**
   * get shared singleton object
   */
  static getSharedInstance(): IRoleService {
    if (!RoleService.sharedInstance) {
      RoleService.sharedInstance = new RoleService();
    }
    return RoleService.sharedInstance;
  }

  /**
   * create new role record
   * @param role: IRole - role object to create
   */
  createRole(role: IRole) {
    this.roles.push(role);
  }
}
