<!--
    Copyright Compunetix Incorporated 2022
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  amaggi
-->

<div
  class="guest-wait-container animated fadeIn"
  [style.top]="localizationService.myLocalizationData.connect_screen.waitTop"
  [style.width]="localizationService.myLocalizationData.connect_screen.waitWidth"
  [style.bottom.rem]="localizationService.myLocalizationData.connect_screen.waitTop?'':5"
  [style.backgroundColor]="localizationService.myLocalizationData.connect_screen.connectPanelBackgroundColor"
>
  <!--    Connecting/On Hold/Invisible label text-->
  <div
  class="connect-info"
  id="connecting-info-title"
  [ngStyle]="{
    'color': localizationService.myLocalizationData.connect_screen.connectingLabelColor,
    'font-size': localizationService.myLocalizationData.connect_screen.connectingLabelSize,
    'background-color': localizationService.myLocalizationData.connect_screen.connectingLabelBackgroundColor
  }"
  >
    <div class="text-center" [innerHTML]="contextConnectingTitle | trustHtml"></div>
    <div class="text-center" *ngIf="touchless && showAutodropWarning"
      [style.backgroundColor]="localizationService.myLocalizationData.connect_screen.backgroundColor">
      <label id="touchless-counter-label" style="font-size: 2.5rem;" for="touchless-counter">
        {{localizationService.myLocalizationData.connect_screen.touchless_autodrop_message || ''}}
      </label>
      
      <div class="spinner">
        <div class="spinner-label" [style.color]="localizationService.myLocalizationData.connect_screen.fontColor">
          <span id="touchless-counter">{{touchlessAutodropCount | number: '2.0-0'}}</span>
        </div>
        <div class="spinner-anim">
          <div class="spinner-item"></div>
          <div class="spinner-item"></div>
          <div class="spinner-item"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="connect-info"
       *ngIf="!showCallbackPanel && !onHold && ((touchless && !showAutodropWarning) || !touchless)"
       [hidden]="!localizationService.myLocalizationData.connect_screen.connectInfoVisible"
  >
    <div class="info-label" *ngIf="skillTags?.category && skillTags?.language">{{skillTags.category}} ({{skillTags.language}})</div>
    <div class="info-label">{{connectInfoWaitTimeLabel}}</div>
    <div class="info-label">{{connectInfoPositionLabel}}</div>
  </div>

  <div class="connect-info" 
   id="connect-info-mobile-footer" 
   [hidden]="!isMobile || !localizationService.myLocalizationData.connect_screen.mobileFooter || onHold">
   <div class="text-center">{{localizationService.myLocalizationData.connect_screen.mobileFooter}}</div>
  </div>

  <div class="guest-wait-callback-buttons" *ngIf="!showCallbackPanel">
    <!--Call Back Button-->
    <button
      tabindex
      class="btn btn-warning full-width btn-lg text-uppercase"
      [hidden]="!localizationService.myLocalizationData.connect_screen.callBackButtonVisible || onHold"
      [title]="localizationService.myLocalizationData.connect_screen.callBackButtonText || 'CALL BACK'"
      (touchstart)="callBack()"
      (mousedown)="callBack()"
      [ngStyle]="{
          'color': localizationService.myLocalizationData.connect_screen.callBackButtonTextColor || 'black',
          'background': localizationService.myLocalizationData.connect_screen.callBackButtonBackgroundColor || 'white'
        }"
      [ngClass]="{
          'btn-round animated slideInDown': !localizationService.myLocalizationData.connect_screen.useRectangleButton
        }"
    >
      {{localizationService.myLocalizationData.connect_screen.callBackButtonText || 'CALL BACK'}}
    </button>
    <!--Disconnect Button-->
    <button
      tabindex
      class="btn btn-warning full-width btn-lg text-uppercase"
      [hidden]="!localizationService.myLocalizationData.connect_screen.dropCallButtonVisible"
      [title]="localizationService.myLocalizationData.connect_screen.dropCallButtonText || 'DISCONNECT'"
      (touchstart)="disconnect($event)"
      (mousedown)="disconnect($event)"
      [ngStyle]="{
          'color': localizationService.myLocalizationData.connect_screen.dropCallButtonTextColor || 'black',
          'background': localizationService.myLocalizationData.connect_screen.dropCallButtonBackgroundColor || 'white'
        }"
      [ngClass]="{
          'btn-round animated slideInDown': !localizationService.myLocalizationData.connect_screen.useRectangleButton
        }"
    >
      {{localizationService.myLocalizationData.connect_screen.dropCallButtonText || 'DISCONNECT'}}
    </button>
  </div>

  <!-- Not shown unless button above is clicked.-->
  <call-back-panel
    *ngIf="showCallbackPanel"
    class="w-100"
    (dialOutData)="dialTelephoneNumber($event)"
    (cancelCallBack)="cancel()"
  ></call-back-panel>

</div>


