/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";

import { FooterComponent } from "./footer.component";
import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from "client/scripts/shared/shared.module";

@NgModule({
  imports: [BrowserModule, FormsModule, HttpClientModule, SharedModule],
  declarations: [FooterComponent],
  bootstrap: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule {}
