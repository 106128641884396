/**
 * Copyright Compunetix Incorporated 2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { MediaUtil } from "../util/media";
import { IVideoFilter } from "./video-filter.interface";

import * as bodySegmentation from '@tensorflow-models/body-segmentation';
import { BodyPixMultiplier, BodyPixQuantBytes, BodyPixOutputStride } from "@tensorflow-models/body-segmentation/dist/body_pix/impl/types";
import { Segmentation } from "@tensorflow-models/body-segmentation/dist/shared/calculators/interfaces/common_interfaces";

import '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
import { EasyRTCService } from "../services/rtc.service.easy";

/**
 * tensor flow video filter util
 */
export class TensorFlowVideoFilter implements IVideoFilter {

  /**
   * is active
   */
  isActive: boolean;
  private video: any;
  private canvas: HTMLCanvasElement;
  private frameCanvas: HTMLCanvasElement;
  private maskCanvas: any;
  private virtualBackgroundImageElement: any;
  private filteredStream: any;
  private width: number;
  private height: number;
  private framerate: number;

    // table of quality settings
    private QUALITY_SETTINGS: any = { 
      1 : { framerate: 8, internalResolution : .25},
      2 : { framerate: 10, internalResolution : .5},
      3 : { framerate : 15, internalResolution : .75},
      4 : { framerate : 24, internalResolution : 1},
    }

  constructor(
    public stream?: MediaStream,
    public multiplier: BodyPixMultiplier = 0.5,
    public stride: BodyPixOutputStride = 8,
    public quantBytes: BodyPixQuantBytes = 4,
    public internalResolution: number = 0.25,
    public segmentationThreshold: number = 0.2,
    public backgroundBlurAmount: number = 15,
    public edgeBlurAmount: number = 15,
    public virtualBackgroundImage?: string,
    public qualitySetting: number = 4
  ) {
    // nothing needed here
  }
  /**
   * create a new stream by adding filter
   * @param stream: MediaStream - media stream
   */
  createFilteredStream(): Promise<MediaStream> {
    this.width = EasyRTCService.getSharedInstance().rtcClient.currentSysPrimaryResolution.width;
    this.height = EasyRTCService.getSharedInstance().rtcClient.currentSysPrimaryResolution.height;
    this.framerate = EasyRTCService.getSharedInstance().rtcClient.primaryVideoFrameRate;
    // reset
    if (this.isActive) {
      this.stopFilteredStream();
    }
    // prepare elements
    if (!this.video) {
      this.video = document.createElement("video") as HTMLVideoElement;
      document.body.appendChild(this.video);
      this.video.style.display = "none";
    }
    this.video.width = this.width;
    this.video.height = this.height;
    this.video.style.display = "none";
    if (!this.canvas) {
      this.canvas = document.createElement("canvas") as HTMLCanvasElement;
      document.body.appendChild(this.canvas);
      this.canvas.id = "default-canvas";
      this.canvas.style.display = "none";
    }
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    if (!this.maskCanvas) {
      this.maskCanvas = document.createElement("canvas") as HTMLCanvasElement;
      document.body.appendChild(this.maskCanvas);
      this.maskCanvas.id = "mask-canvas";
      this.maskCanvas.style.display = "none";
    }
    this.maskCanvas.width = this.width;
    this.maskCanvas.height = this.height;
    if (!this.frameCanvas) {
      this.frameCanvas = document.createElement("canvas") as HTMLCanvasElement;
      document.body.appendChild(this.frameCanvas);
      this.frameCanvas.id = "frame-canvas";
      this.frameCanvas.style.display = "none";
    }
    this.frameCanvas.width = this.width;
    this.frameCanvas.height = this.height;
    this.virtualBackgroundImageElement = document.getElementById("virtual-background-image") as HTMLImageElement;
    this.virtualBackgroundImageElement.width = this.width;
    this.virtualBackgroundImageElement.height = this.height;
    // start
    this.isActive = true;
    return EasyRTCService.getSharedInstance().setVideoSrcByElement(this.video, this.stream, true)
    .then(() => {
      return this.startFilteredStream(this.video, this.canvas);
    })
    .then(() => {
      this.filteredStream = (this.canvas as any).captureStream(this.framerate);
      return this.filteredStream;
    });
  }

  /**
   * stop and clean up
   */
  stopFilteredStream() {
    this.isActive = false;
    if (this.filteredStream) {
      MediaUtil.stopStream(this.filteredStream);
      delete this.filteredStream;
    }
    if (this.canvas) {
      const ctx = this.canvas.getContext("2d");
      ctx.clearRect(0,0,this.canvas.width, this.canvas.height);
    }
    if (this.maskCanvas) {
      const ctx = this.maskCanvas.getContext("2d");
      ctx.clearRect(0,0,this.maskCanvas.width, this.maskCanvas.height);
    }
    if (this.frameCanvas) {
      const ctx = this.frameCanvas.getContext("2d");
      ctx.clearRect(0,0,this.frameCanvas.width, this.frameCanvas.height);
    }
    if (this.video) {
      document.body.removeChild(this.video);
      delete this.video;
    }
  }

  /**
   * load TensorFlow BodyPix on the video, and trigger segment process
   */
  startFilteredStream(video : HTMLVideoElement, canvas : HTMLCanvasElement): Promise<void> {
    return new Promise((resolve: () => void, reject: (error: Error) => void) => {
      
      const BODY_PIX_CONFIG : bodySegmentation.BodyPixModelConfig = {
        architecture: 'MobileNetV1',
        outputStride: this.stride,
        multiplier: this.multiplier,
        quantBytes: this.quantBytes,
      };

      bodySegmentation.createSegmenter(bodySegmentation.SupportedModels.BodyPix, BODY_PIX_CONFIG).then((segmenter : bodySegmentation.BodySegmenter) =>
      {
          this.foreverSegment(segmenter, video, canvas);
          let segmentInitDelay = setTimeout(() => {
            clearTimeout(segmentInitDelay);
            resolve();
          }, 1000);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * segment the video frame and trigger a draw. report for the next available frame at the time
   */
  foreverSegment(segmenter : bodySegmentation.BodySegmenter, video : HTMLVideoElement, canvas : HTMLCanvasElement) {
    this.captureVideoImage(video);

    if(this.updateQualitySettings())
    {
      segmenter.dispose();
      this.startFilteredStream(video, canvas);
      return;
    }
    // if there is a change, leave out early
    return segmenter.segmentPeople(this.frameCanvas, {
      multiSegmentation : false,
      segmentBodyParts : false,
      segmentationThreshold : this.segmentationThreshold
    })
    .then((segmentations : Segmentation[]) => {
      if (this.virtualBackgroundImage && segmentations[0] && this.isActive) {
        // virtual background
        return this.drawVirtualBackground(canvas, this.frameCanvas, segmentations[0]);
      }
      else if (segmentations[0] && this.isActive) {
        // blurry background
        return this.drawBlurryBackground(canvas, this.frameCanvas, segmentations[0]);
      }

      return Promise.resolve();
    }).then(() =>
    {
      if (this.isActive)
      {
         let sendFrameTimer = setTimeout(() => {
           clearTimeout(sendFrameTimer);
           this.foreverSegment(segmenter, this.video, this.canvas);
         }, 1000 / this.framerate);
      }
    });
  }

  captureVideoImage(video) {
    if (video)
    {
       const ctx = this.frameCanvas.getContext("2d");
       ctx.clearRect(0,0,this.frameCanvas.width, this.frameCanvas.height);
       ctx.drawImage(video, 0, 0, this.frameCanvas.width, this.frameCanvas.height);
    }
  }

  /**
   * draw virtual background on canvas
   */
  drawVirtualBackground(canvas : HTMLCanvasElement, image, segmentation : Segmentation) : Promise<void> {
    // render person mask on canvas
    return bodySegmentation.toBinaryMask(segmentation, { r: 0, g: 0, b: 0, a: 255 }, { r: 0, g: 0, b: 0, a: 0 }).then((personMask : ImageData) => {
      return bodySegmentation.drawMask(
        this.maskCanvas, this.maskCanvas, personMask, 1, this.edgeBlurAmount).then(() => {
          
        // draw the original image on the final canvas
        const ctx : CanvasRenderingContext2D = canvas.getContext("2d");
        ctx.save();
        ctx.drawImage(image, 0, 0, this.width, this.height);
        // "destination-in" - "The existing canvas content is kept where both the
        // new shape and existing canvas content overlap. Everything else is made
        // transparent."
        // crop what's not the person using the mask from the original image
        ctx.globalCompositeOperation = "destination-in";
        ctx.drawImage(this.maskCanvas, 0, 0, this.width, this.height);
        ctx.globalCompositeOperation = "destination-over";
        ctx.drawImage(this.virtualBackgroundImageElement, 0, 0, this.width, this.height);

        ctx.restore();
        return Promise.resolve();
      });
    });
  }

  /**
   * @param canvas the canvas to draw the image on
   * @param image the image to process
   * @param segmentation the segmenation data
   */
  drawBlurryBackground(canvas : HTMLCanvasElement, image, segmentation : Segmentation) : Promise<void> {
    // blurry background
    return bodySegmentation.drawBokehEffect(canvas, image, segmentation,  .5, this.backgroundBlurAmount, this.edgeBlurAmount);
  }

  /**
   * Helper function to update the quality settings, returns true if quality settings have changed.
   */
  updateQualitySettings() : boolean 
  {
      // only update this if it changed...
      if(this.internalResolution != this.QUALITY_SETTINGS[this.qualitySetting].internalResolution || 
        this.framerate != this.QUALITY_SETTINGS[this.qualitySetting].framerate)
      {
        this.internalResolution = this.QUALITY_SETTINGS[this.qualitySetting].internalResolution;
        this.frameCanvas.height = this.height * this.internalResolution;
        this.frameCanvas.width = this.width * this.internalResolution;

        this.maskCanvas.width =  this.width * this.internalResolution;
        this.maskCanvas.height = this.height * this.internalResolution;

        this.framerate = this.QUALITY_SETTINGS[this.qualitySetting].framerate;
        return true;
      }
      else
      {
        return false;
      }
  }
}
