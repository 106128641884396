/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IVideoFilter } from "./video-filter.interface";
import { MediaPipeVideoFilter } from "./video-filter.mediapipe";
import { TensorFlowVideoFilter } from "./video-filter.tensorflow";

export class VideoFilterFactory {
  /**
   * shared instance of TensorFlow Video Filter
   */
  private static tensorFlowVideoFilter = new TensorFlowVideoFilter();

  /**
   * shared instance of MediaPipe Video Filter
   */
  private static mediaPipeVideoFilter = new MediaPipeVideoFilter();

  /**
   * get video filter by segmentation method
   * @param method: SegmentMethod - segmentation method
   */
  public static getVideoFilter(method: SegmentMethod, stream?: MediaStream, virtualBackgroundImage?: string): IVideoFilter {
    let selectedVideoFilter: IVideoFilter;
    switch (method) {
      case SegmentMethod.TensorFlow:
        selectedVideoFilter = this.tensorFlowVideoFilter;
        break;
      case SegmentMethod.MediaPipe:
        selectedVideoFilter = this.mediaPipeVideoFilter;
      default:
        break;
    }
    if (selectedVideoFilter && stream) {
        selectedVideoFilter.stream = stream;
    }
    if (selectedVideoFilter && virtualBackgroundImage) {
        selectedVideoFilter.virtualBackgroundImage = virtualBackgroundImage;
    }
    return selectedVideoFilter
  }
}

/**
 * image segmentation method
 */
export enum SegmentMethod {
  MediaPipe,
  TensorFlow,
}
