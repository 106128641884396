/**
 * Copyright Compunetix Incorporated 2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { EasyRTCService } from "../services/rtc.service.easy";
import { MediaUtil } from "../util/media";
import { IVideoFilter } from "./video-filter.interface";
import { FilesetResolver, ImageSegmenter, ImageSegmenterResult, MPMask } from "@mediapipe/tasks-vision";

/**
 * MediaPipe video filter util
 */
export class MediaPipeVideoFilter implements IVideoFilter {

  /**
   * is active
   */
  isActive: boolean;
  private video: HTMLVideoElement;
  // The canvas we draw the result too
  private canvas: HTMLCanvasElement;
  // Holds the frame before mask calculation is performed
  private frameCanvas: HTMLCanvasElement;
  // The mask Canvas
  private maskCanvas: HTMLCanvasElement;
  private virtualBackgroundImageElement: any;
  private filteredStream: any;
  private width: number;
  private height: number;


  private internalResolution : number;
  private framerate: number;
  private imageSegmenter: ImageSegmenter;
  
  // table of quality settings
  private QUALITY_SETTINGS: any = { 
    1 : { framerate: 8, internalResolution : .25},
    2 : { framerate: 10, internalResolution : .5},
    3 : { framerate : 15, internalResolution : .75},
    4 : { framerate : 24, internalResolution : 1},
  }

  /**
   * qualitySetting - from 0 to 1 = the proportion of the source resolution that we use to 
   * calculate, lower = better performance, higher = better quality;
   * this is determined by the quality setting.
   */
  constructor(
    public stream?: MediaStream,
    public backgroundBlurAmount: number = 15,
    public virtualBackgroundImage?: string,
    public qualitySetting : number = 4,
    public edgeBlurAmount : number = 15,
    public segmentationThreshold: number = .2
  ) {
    //
  }


  /**
   * create a new stream by adding filter
   * @param stream: MediaStream - media stream
   */
  createFilteredStream(): Promise<MediaStream> {
    this.width = EasyRTCService.getSharedInstance().rtcClient.currentSysPrimaryResolution.width;
    this.height = EasyRTCService.getSharedInstance().rtcClient.currentSysPrimaryResolution.height;
    this.framerate = EasyRTCService.getSharedInstance().rtcClient.filteredVideoFrameRate;
    // reset
    if (this.isActive) {
      this.stopFilteredStream();
    }
    if (!this.imageSegmenter) {
      this.initMediaPipeSegmentation();
    }
    // prepare elements
    if (!this.video) {
      this.video = document.createElement("video") as HTMLVideoElement;
      document.body.appendChild(this.video);
      this.video.style.display = "none";
    }
    // we should reduce this i think... if we want to make it more performant...
    this.video.width = this.width;
    this.video.height = this.height;
    if (!this.canvas) {
      this.canvas = document.createElement("canvas") as HTMLCanvasElement;
      this.canvas.id = "default-canvas";
      document.body.appendChild(this.canvas);
      this.canvas.style.display = "none";
    }
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    if (!this.frameCanvas) {
      this.frameCanvas = document.createElement("canvas") as HTMLCanvasElement;
      document.body.appendChild(this.frameCanvas);
      this.frameCanvas.id = "frame-Canvas";
      this.frameCanvas.style.display = "none";
    }
    this.frameCanvas.width = this.width;
    this.frameCanvas.height = this.height;
    if (!this.maskCanvas) {
      this.maskCanvas = document.createElement("canvas") as HTMLCanvasElement;
      document.body.appendChild(this.frameCanvas);
      this.maskCanvas.id = "mask-canvas";
      this.maskCanvas.style.display = "none";
    }
    this.virtualBackgroundImageElement = document.getElementById("virtual-background-image") as HTMLImageElement;
    this.virtualBackgroundImageElement.width = this.width;
    this.virtualBackgroundImageElement.height = this.height;
    // start
    this.isActive = true;
    return EasyRTCService.getSharedInstance().setVideoSrcByElement(this.video, this.stream, true)
    .then(() => {
      this.foreverSegment();
    })
    .then(() => {
      this.filteredStream = (this.canvas as any).captureStream(this.framerate);
      return this.filteredStream;
    });
  }

  initMediaPipeSegmentation() {

    FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm").then((fileSet: any) =>
    {
      ImageSegmenter.createFromOptions(fileSet, {
        baseOptions: {
          modelAssetPath:
            "https://storage.googleapis.com/mediapipe-models/image_segmenter/selfie_segmenter_landscape/float16/latest/selfie_segmenter_landscape.tflite",
          delegate: "GPU"
        },
        outputCategoryMask: false,
        outputConfidenceMasks: true,
        runningMode: "VIDEO"
      }).then((imgSeg : ImageSegmenter) =>
      {
        this.imageSegmenter = imgSeg;
      });
    })
  }

  /**
   * stop and clean up
   */
  stopFilteredStream() {
    this.isActive = false;
    if (this.imageSegmenter) {
      this.imageSegmenter.close();
      delete this.imageSegmenter;
    }
    if (this.filteredStream) {
      MediaUtil.stopStream(this.filteredStream);
      delete this.filteredStream;
    }
    if (this.canvas) {
      document.body.removeChild(this.canvas);
      delete this.canvas;
    }
    if (this.video) {
      document.body.removeChild(this.video);
      delete this.video;
    }
  }

  /**
   * segment the video frame and trigger a draw. report for the next available frame at the time
   */
  foreverSegment() {
    // reapply the quality settings as they may have changed...
    if(this.QUALITY_SETTINGS[this.qualitySetting])
    {
      // only update this if it changed...
      if(this.internalResolution != this.QUALITY_SETTINGS[this.qualitySetting].internalResolution)
      {
        this.internalResolution = this.QUALITY_SETTINGS[this.qualitySetting].internalResolution;
        this.video.height = this.height * this.internalResolution;
        this.video.width = this.width * this.internalResolution;
      }

      this.framerate = this.QUALITY_SETTINGS[this.qualitySetting].framerate;
    }

    // draw Video onto canvas for this frame...
    this.captureVideoImage(this.video);

    if (this.video && this.video.readyState >=2 && !this.video.paused && this.isActive && this.imageSegmenter) {
      this.imageSegmenter.segmentForVideo(this.video, performance.now(), this.handleSegment.bind(this));
    } else {
      // try again next time.
      if (this.isActive)
      {
         let sendFrameTimer = setTimeout(() => {
           clearTimeout(sendFrameTimer);
           this.foreverSegment();
         }, 1000 / this.framerate);
      }
    }
  }

    /**
     * Handle the result of the segmenter
     */
    handleSegment(result : ImageSegmenterResult)
    {
      this.createMask(result);

      // blurry background
      this.drawBackground(this.frameCanvas, this.canvas, this.maskCanvas);
  
      let sendFrameTimer = setTimeout(() => {
        clearTimeout(sendFrameTimer);
        this.foreverSegment();
      }, 1000 / this.framerate);
    }

  /**
   * draw virtual background on canvas
   */
  drawBackground(image, canvas, maskCanvas) {
    // draw the original image on the final canvas
    const ctx : CanvasRenderingContext2D  = canvas.getContext("2d");
    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    // "destination-in" - "The existing canvas content is kept where both the
    // new shape and existing canvas content overlap. Everything else is made
    // transparent."
    // crop what's not the person using the mask from the original image
    ctx.filter = `blur(${this.edgeBlurAmount}px)`;
    ctx.globalCompositeOperation = "destination-in";
    ctx.drawImage(maskCanvas, 0, 0, this.width, this.height);
    ctx.globalCompositeOperation = "destination-over";

    if(!!this.virtualBackgroundImage)
    {
      ctx.filter = `none`;
      ctx.drawImage(this.virtualBackgroundImageElement, 0, 0, this.width, this.height);
    }
    else
    {
      ctx.filter = `blur(${this.backgroundBlurAmount}px)`;
      ctx.drawImage(image, 0, 0, this.width, this.height);
    }

    ctx.restore();
  }
  
  captureVideoImage(video) {
    if (video)
    {
       const ctx = this.frameCanvas.getContext("2d");
       ctx.clearRect(0,0,this.frameCanvas.width, this.frameCanvas.height);
       ctx.drawImage(video, 0, 0, this.frameCanvas.width, this.frameCanvas.height);
    }
  }

  createMask(result : ImageSegmenterResult)
  {
    const cxt : CanvasRenderingContext2D = this.maskCanvas.getContext("2d", { willReadFrequently : true});
    const { width, height } = result.confidenceMasks[0];
    // SET WILL READ FREQUENTLY to true.
    let imageData : Uint8ClampedArray = cxt.getImageData(0, 0, width, height).data;
    this.maskCanvas.width = width;
    this.maskCanvas.height = height;
    const mask: Float32Array = result.confidenceMasks[0].getAsFloat32Array();
    let lowerThreshold : number = this.segmentationThreshold;
    let upperThreshold : number = .8;
    for (let i = 0; i < mask.length; i++) {
        // clamp low conf and high conf values...
        let blurFactor;
        if(mask[i] < lowerThreshold )
        {
          blurFactor = 0;
        }
        else if(mask[i] > upperThreshold)
        {
          blurFactor = 255;
        }
        else
        {
          // remake the range from 0 to for proper edge blur
          blurFactor = (mask[i] - lowerThreshold) / (upperThreshold - lowerThreshold) * 255;
        }
        // 0,0,0, 255
        imageData[i * 4] = 0;
        imageData[i * 4 + 1] = 0;
        imageData[i * 4 + 2] = 0;
        imageData[i * 4 + 3] = blurFactor; // WE CAN modify the opacity based on our 
    }
    const uint8Array = new Uint8ClampedArray(imageData.buffer);
    const dataNew = new ImageData(uint8Array, width, height);
    cxt.putImageData(dataNew, 0, 0);
  }
}
