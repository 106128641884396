/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ColorPickerModule } from "ngx-color-picker";
import { SharedModule } from "../../../client/scripts/shared/shared.module";
import { FormModule } from "../form/form.module";
import { LayoutModule } from "./../layout/layout.module";

import { FlavorFactoryComponent } from "./flavor-factory.component";

import { SidebarComponent } from "./sidebar/sidebar.component";
import { StyleListComponent } from "./sidebar/style-list.component";
import { StyleItemComponent } from "./sidebar/style-item.component";

import { FlavorPanelComponent } from "./flavor/flavor-panel.component";

import { StyleSearchPipe } from "./sidebar/style-search.pipe";

import { FlavorService } from "./flavor/flavor.service";

import { HttpClientModule } from "@angular/common/http";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    ColorPickerModule,
    SharedModule,
    FormModule,
    LayoutModule
  ],
  declarations: [
    FlavorFactoryComponent,
    SidebarComponent,
    StyleListComponent,
    StyleItemComponent,
    FlavorPanelComponent,
    StyleSearchPipe
  ],
  providers: [FlavorService],
  exports: [FlavorFactoryComponent]
})
export class FlavorFactoryModule {}
