/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi
 */
import {Component, Output, EventEmitter, Input} from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import {Companion, IEndpoint, IEndpointService, PresenceStatus} from "companion";

@Component({
  selector: "top-nav-control",
  templateUrl: "./top-nav-control.template.html"
})

/**
 * top nav language switch component in the login bar
 */
export class TopNavControlComponent {

  /**
   * flag if it's mobile app
   */
  @Input() isMobile: boolean = false;

  /**
   * flag if current view mode supports presence status changed by user through this view
   */
  @Input() statusChangeable: boolean;

  @Output("updateMyStatus") updateMyStatusEmitter: EventEmitter<PresenceStatus> = new EventEmitter<PresenceStatus>();

  public presenceStatusClass = PresenceStatus;
  private endpointService: IEndpointService = Companion.getEndpointService();
  myEndpoint: IEndpoint = this.endpointService.myEndpoint;

  constructor(public localizationService: LocalizationService) {
    // nothing to do
  }

  get statusOptions(): PresenceStatus[] {
    const options = [PresenceStatus.available];
    if (this.localizationService.myLocalizationData.presenceStatus[this.presenceStatusClass[PresenceStatus.custom1]]) {
      options.push(PresenceStatus.custom1);
    }
    if (this.localizationService.myLocalizationData.presenceStatus[this.presenceStatusClass[PresenceStatus.custom2]]) {
      options.push(PresenceStatus.custom2);
    }
    if (this.localizationService.myLocalizationData.presenceStatus[this.presenceStatusClass[PresenceStatus.custom3]]) {
      options.push(PresenceStatus.custom3);
    }
    if (this.localizationService.myLocalizationData.presenceStatus[this.presenceStatusClass[PresenceStatus.custom4]]) {
      options.push(PresenceStatus.custom4);
    }

    return options;
  }

  canChangeStatus(): boolean {
    return this.statusChangeable &&
      (this.myEndpoint.status === PresenceStatus.available ||
        this.myEndpoint.status === PresenceStatus.away ||
        this.myEndpoint.status === PresenceStatus.ready ||
        this.myEndpoint.status === PresenceStatus.custom1 ||
        this.myEndpoint.status === PresenceStatus.custom2 ||
        this.myEndpoint.status === PresenceStatus.custom3 ||
        this.myEndpoint.status === PresenceStatus.custom4);
  }

  /**
   * update my endpoint status
   * @param status: PresenceStatus - the new status
   */
  changeSelectedOption(status: PresenceStatus) {
    this.updateMyStatusEmitter.emit(status);
  }
}
