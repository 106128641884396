/**
 * Copyright Compunetix Incorporated 2016-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Directive, Input, SimpleChanges } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { IRTCService, Companion, IEndpoint, IEndpointService, IDeviceService } from "companion";
@Directive({
  selector: "video-window"
})

/**
 * video window
 */
export class VideoWindowComponent {
  /**
   * endpoint which this video window belongs to
   */
  @Input() endpoint: IEndpoint;
  /**
   * media stream to load video on
   */
  @Input() stream: MediaStream;
  /**
   * video element id
   */
  @Input() videoElementId: string;

  /**
   * video window title
   */
  @Input() title: string;

  subWindow: Window;

  checkWindowCloseTimeout: any;

  checkStreamCloseTimeout: any;

  videoOnlyStream: any;

  rtcService: IRTCService = Companion.getRTCService();
  endpointService: IEndpointService = Companion.getEndpointService();
  deviceService: IDeviceService = Companion.getDeviceService();

  myEndpoint: IEndpoint = this.endpointService.myEndpoint;
  constructor(private localizationService: LocalizationService) {
    // nothing needed here
  }

  open(onCloseCallback: () => void, stream?: MediaStream, endpoint?: IEndpoint) {
    this.stream = stream || this.stream;
    this.videoOnlyStream = new MediaStream();
    if (this.stream && this.stream.getVideoTracks()[0]) {
      this.videoOnlyStream.addTrack(this.stream.getVideoTracks()[0]);
    }
    this.endpoint = endpoint || this.endpoint;
    let videoWindowId: string = "video-window-" + (this.videoOnlyStream ? this.videoOnlyStream.id : "");
    let videoElement: HTMLVideoElement = document.getElementById(this.videoElementId) as HTMLVideoElement;
    if (!videoElement) {
      return;
    }
    let subWindowHtml: string = "<!DOCTYPE html>";
    subWindowHtml += '<html lang="en">';
    subWindowHtml += "<head>";
    subWindowHtml += '<meta charset="UTF-8">';
    subWindowHtml += '<meta name="viewport" content="width=device-width, initial-scale=1.0">';
    subWindowHtml += "<title>" + this.title + "</title>";
    subWindowHtml += "</head>";
    subWindowHtml += '<body style="background-color:#000;">';
    subWindowHtml +=
      '<video autoplay playsinline muted id="' + videoWindowId + '" style="width:100%;height:100%;"><video>';
    subWindowHtml += "</body>";
    subWindowHtml += "</html>";
    let windowLeft = screen.width - videoElement.videoWidth;
    let windowTop = 0;
    let windowWidth = videoElement.videoWidth;
    let windowHeight = videoElement.videoHeight + 10;
    this.subWindow = window.open(
      null,
      "_blank",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,left=" +
        windowLeft +
        ",top=" +
        windowTop +
        ",width=" +
        windowWidth +
        ",height=" +
        windowHeight
    );
    this.subWindow.document.write(subWindowHtml);
    this.resetStream();
    this.listenOnWindowClose(onCloseCallback);
    this.listenOnStreamClose(() => {
      this.close();
    });
  }

  close() {
    this.videoOnlyStream = null;
    this.subWindow.close();
  }

  listenOnWindowClose(onCloseCallback: () => void) {
    this.checkWindowCloseTimeout = setTimeout(() => {
      clearTimeout(this.checkStreamCloseTimeout);
      if (this.subWindow.closed) {
        this.videoOnlyStream = null;
        onCloseCallback();
      } else {
        this.listenOnWindowClose(onCloseCallback);
      }
    }, 0.1 * 1000);
  }

  listenOnStreamClose(onCloseCallback: () => void) {
    this.checkStreamCloseTimeout = setTimeout(() => {
      clearTimeout(this.checkStreamCloseTimeout);
      if (this.stream == null || !this.stream.active) {
        onCloseCallback();
      } else {
        this.listenOnStreamClose(onCloseCallback);
      }
    }, 0.1 * 1000);
  }

  /**
   * reset streams of visible endpoints on video elements
   */
  resetStream(): void {
    let videoId: string = "video-window-" + (this.videoOnlyStream ? this.videoOnlyStream.id : "");
    var videoElement: any = this.subWindow.document.getElementById(videoId);
    if (videoElement) {
      if (this.videoOnlyStream) {
        videoElement.muted = true;
        if (videoElement.srcObject !== this.videoOnlyStream) {
          this.rtcService.setVideoSrcByElement(videoElement, this.videoOnlyStream)
          .catch((error) => {
            console.log("Failed to setVideoSrcByElement: ", error);
          });
        }

        if (this.rtcService.rtcClient.selectedSpeakerOption) {
          this.rtcService.setAudioOutput(videoElement, this.rtcService.rtcClient.selectedSpeakerOption.deviceId)
          .catch((error) => {
            console.log("Failed to setAudioOutput: ", error);
          });
        }
      } else {
        videoElement.src = "";
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
     for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
           case'stream': {
              if (this.videoOnlyStream &&
                  changes.stream.previousValue?.getVideoTracks() &&
                  changes.stream.currentValue?.getVideoTracks()) {
                     this.videoOnlyStream.removeTrack(changes.stream.previousValue?.getVideoTracks()[0]);
                     this.videoOnlyStream.addTrack(changes.stream.currentValue?.getVideoTracks()[0]);
              }
           }
           break;
        }
      }
    }
  }

}
