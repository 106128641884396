/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input } from "@angular/core";
import { IEndpoint, IEndpointService, Companion, IToolBarItem, IVideo } from "companion";
import { LocalizationService } from "../../localization/localization.service";

@Component({
  selector: "video-control",
  templateUrl: "./video-control.template.html"
})

/**
 * video control view
 */
export class VideoControlComponent {
  /**
   * all videos to control
   */
  @Input()
  videos: IVideo[];

  /**
   * the overall volume
   */
  @Input()
  overallVolume: number = 1;

  /**
   * volume before user click mute
   */
  volumeBeforeMute: number;

  /**
   * flag if audio entirely muted
   */
  isSoundMuted: boolean = false;

  /**
   * Library to template linkage
   */
  Math = Math;

  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }

  /**
   * toggle audio
   */
  toggleMuteAudio() {
    this.isSoundMuted = !this.isSoundMuted;
    if (this.isSoundMuted) {
      this.volumeBeforeMute = this.overallVolume;
      this.overallVolume = 0;
    } else {
      this.overallVolume = this.volumeBeforeMute;
    }
    this.updateAllVideoElementsVolume();
  }

  /**
   * change overall volume
   */
  volumeChange() {
    this.overallVolume = +this.overallVolume;
    if (this.overallVolume === 0) {
      this.isSoundMuted = true;
    } else {
      this.isSoundMuted = false;
      this.volumeBeforeMute = this.overallVolume;
    }
    this.updateAllVideoElementsVolume();
  }

  /**
   * update volume on all video elements
   */
  updateAllVideoElementsVolume() {  
    let endpointService: IEndpointService = Companion.getEndpointService();
    for (var i = 0; i < this.videos.length; ++i) {
      let video = this.videos[i];
      let videoElement: any = document.getElementById("ep-video-" + video?.endpoint?.rtcId + "-" + video?.stream?.id);
        if (videoElement) {
          if (video.endpoint.rtcId === endpointService.myEndpoint.rtcId) {
            endpointService.updateEndpointVolume(video.endpoint, true, 0, videoElement);
          } else {
            endpointService.updateEndpointVolume(video.endpoint, this.isSoundMuted, this.overallVolume, videoElement);
          }
        }
    }
    this.updateVolumeSlider();
  }

  /**
   * update volume slider
   */
  updateVolumeSlider() {
    let slider = $(".range-slider")[0];
    if (!slider) {
      return;
    }
    let thumb = $(".range-slider__thumb")[0];
    if (!thumb) {
      return;
    }
    let bar = $(".range-slider__bar")[0];
    if (!slider || !thumb || !bar) {
      return;
    }
    let pct = this.overallVolume * 100 * ((slider.clientHeight - thumb.clientHeight) / slider.clientHeight);
    thumb.style.bottom = pct + "%";
    bar.style.height = (pct / 100) * slider.clientHeight + thumb.clientHeight / 2 + "px";
  }

  /**
   * mouseover event handler
   */
  onMouseEnter(e: any) {
    $(".range-slider")
      .show()
      .removeClass("fadeOut")
      .addClass("fadeIn");
    this.updateVolumeSlider();
  }

  /**
   * mouseleave event handler
   */
  onMouseLeave(e: any) {
    $(".range-slider")
      .removeClass("fadeIn")
      .addClass("fadeOut")
      .hide();
  }
}
