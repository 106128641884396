/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { IChatRoom, IEndpoint } from "companion";

@Component({
  selector: "chatroom-userbox",
  templateUrl: "./chatroom-userbox.template.html"
})

/**
 * Chat room user box
 */
export class ChatRoomUserBox {
  @Input() room: IChatRoom;
  @Input() currentEndpoint: IEndpoint;
  @Input() canModifyChatRoomParticipants: boolean;
  @Output("toggleEndpointSelectListPanel") toggleEndpointSelectListPanelEmitter: EventEmitter<
    IChatRoom
  > = new EventEmitter<IChatRoom>();
  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }

  /**
   * Identify any targets in the room that are not me to keep the display short.
   */
  get filteredRoomTargets(): IEndpoint[] {
    return _.filter(this.room.targets, (ep) => {
      return ep.rtcId != this.currentEndpoint.rtcId;
    })
  }
  /**
   * trigger open and close endpoint selection panel
   */
  toggleEndpointSelectListPanel(event: any): void {
    event.stopPropagation();
    if (!this.canModifyChatRoomParticipants) {
      return;
    }
    this.toggleEndpointSelectListPanelEmitter.emit(this.room);
  }
}
