/**
 * Copyright Compunetix Incorporated 2022-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender
 */

import { Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import { LocalizationService } from "../localization/localization.service";
import { LineCapEnum, LineJoinEnum, ToolsEnum, NgWhiteboardService} from 'ng-whiteboard';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { NgxResize, NgxResizeResult } from 'ngx-resize';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { NgWhiteboardComponent } from 'ng-whiteboard';
import { ActionType, Dispatcher } from '../shared/services/dispatcher';
import { Companion } from 'companion/companion';


@Component({
  selector: 'content-presenter',
  templateUrl: './content-presenter.template.html',
  hostDirectives: [{ directive: NgxResize, outputs: ['ngxResize'] }],
  styleUrls: ['./content-presenter.style.css']
})

/**
 * Content presenter allows us to display multimedia content, mark it up, 
 * and send it as a video stream to other consumers.
 */
export class ContentPresenterComponent implements OnInit {
  private cursorImage: HTMLImageElement = new Image();
  protected pdfSrc: string;
  protected pdfPage: number;
  public zoomScale: number = 1;
  protected pdfProxy: PDFDocumentProxy = null;
  protected selectedTool: ToolsEnum;
  protected strokeWidth: number;
  protected color: string;
  protected canvasOptions: any; // Background Color not in typed options object!
  protected width : number = 0.00;  // Float
  protected height : number = 0.00; // Float
  protected penSelected : boolean = false;
  protected highlighterSelected : boolean = false;
  protected fileSelected : boolean = false;
  protected showPanAndZoom : boolean = false;
  protected showThumbnails : boolean = false;
  
  protected loading = false;

  protected totalPages!: number;
  private minThumb = 1; // The minimum thumbnail to show for the view
  private maxThumb = 2; // The maximum thumbnail to show for the view
  private thumbSpread = 2; // Set this to how far off of the left and right you want the spread.
  private thumbWindowCenter = 1; // resets on page change.

  protected renderWidth = 1280; // the width to work from.
  protected renderHeight = 720; // the height to work from.
  private panPixelScalar = 100; // how much to pan based on zooming.
  private viewOffsetX = 0;
  private viewOffsetY = 0;

  @Input() isSharingContent: boolean;
  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  @ViewChild(NgWhiteboardComponent) private whiteboardComponent: NgWhiteboardComponent;

  constructor(private whiteboardService: NgWhiteboardService, private localizationService: LocalizationService) {
    this.pdfSrc;
    this.pdfPage = 0; 
    this.strokeWidth = 2;   
    this.color = "rgba(0, 0, 0, 1)"
    this.height = this.renderHeight;
    this.width = this.renderWidth;
    this.canvasOptions = {
      lineCap: LineCapEnum.BUTT,
      lineJoin: LineJoinEnum.BEVEL,
      drawingEnabled: true,
      backgroundColor: 'transparent'
    };    
  }

  prevPage(): void {
    this.zoomScale = 1;
    this.viewOffsetX = 0;
    this.viewOffsetY = 0;
    if (this.pdfPage > 1) {
      this.pdfPage--;
      this.whiteboardService.erase();
    }
    setTimeout(() => {
      this.updatePdfRender();
      }, 250);
  }
  nextPage(): void {
    this.zoomScale = 1;
    this.viewOffsetX = 0;
    this.viewOffsetY = 0;
    this.pdfPage++;
    this.whiteboardService.erase();
    setTimeout(() => {
      this.updatePdfRender();
      }, 250);
  }
  setPage(event): void {
    this.zoomScale = 1;
    this.viewOffsetX = 0;
    this.viewOffsetY = 0;
    const newPage:number = parseInt(event.target.value);
    if (newPage) {
      this.pdfPage = newPage;
    }
  }
  pagechanging(e: any) {
    this.pdfPage = e.pageNumber; // the page variable
    this.clearDraw();

    this.thumbWindowCenter = this.pdfPage;
    this.setWindowAroundPage(this.thumbWindowCenter);

    setTimeout(() => {
      this.updatePdfRender();
      this.drawComposite();
      }, 250);
  }

  switchToPenDraw() {
    this.penSelected = !this.penSelected;
    this.highlighterSelected = false;
    this.selectedTool = this.penSelected ? ToolsEnum.BRUSH : ToolsEnum.SELECT;
    this.color = "rgba(0, 0, 0, 1)"
    this.strokeWidth = 2;
  }
  switchToHighDraw() {
    this.highlighterSelected = !this.highlighterSelected;
    this.penSelected = false;
    this.selectedTool = this.highlighterSelected ? ToolsEnum.BRUSH : ToolsEnum.SELECT;
    this.color = "rgba(255, 255, 0, 0.5)";
    this.strokeWidth = 12;
  }
  switchToErase() {
    //TODO maybe
    this.highlighterSelected = false;
    this.penSelected = false
    this.selectedTool = ToolsEnum.ERASER;
  }
  undoDraw() {
    this.whiteboardService.undo();
    setTimeout(() => {
      this.dataChange();
      }, 250);
  }
  redoDraw() {
    this.whiteboardService.redo();
    setTimeout(() => {
      this.dataChange();
      }, 250);
  }
  clearDraw() {
    this.whiteboardService.erase();
    setTimeout(() => {
      this.dataChange();
      }, 250);
  }

  dataChange() {
    var wb_ctx = this.getWhiteboardRenderCanvas()?.getContext("2d");
    if (wb_ctx) {
      this.updateWhiteboardRender(wb_ctx, this.getWhiteboardImageData());
    }
    this.drawComposite();
  }

  updatePdfRender(): void {
    let pdfRender = this.getPdfRenderCanvas();
    let pdfCanvas = this.getPdfCanvas()

    if (pdfRender && pdfCanvas?.width && pdfCanvas?.height) {
      this.width = pdfCanvas.width;
      this.height = pdfCanvas.height;

      var ctx = pdfRender.getContext("2d");
      if (ctx) {
        // clear the image
        ctx.clearRect(0,0, this.renderWidth, this.renderHeight);
        ctx.drawImage(this.getPdfCanvas(),
                      this.viewOffsetX,
                      this.viewOffsetY, 
                      this.width/this.zoomScale,
                      this.height/this.zoomScale,
                      0, 
                      0,
                      this.renderWidth, this.renderHeight);
      }
    }
  }

  updateWhiteboardRender(ctx: CanvasRenderingContext2D, svgElem: SVGImageElement): void {
    if(!svgElem || !ctx)
    {
      console.warn("missing element to update", svgElem, ctx)
      return;
    }

    var svgURL = new XMLSerializer().serializeToString(svgElem);
    var img = new Image();
    var srcWidth = this.width;
    var srcHeight = this.height;
    var srcScale = this.zoomScale
    img.onload = function() {
      ctx.clearRect(0,0, ctx.canvas.width, ctx.canvas.height);
      ctx.drawImage(img,0,0, srcWidth/srcScale, srcHeight/srcScale, 0, 0, ctx.canvas.width, ctx.canvas.height);
    }
    img.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURL);
  }

  ngOnInit(): void {   
    (window as any).pdfWorkerSrc = '../companion/libs/pdf.worker.min.js';
  }

  @HostListener('ngxResize', ['$event'])
  onResized(event: NgxResizeResult) {
    // hide scrollbars here, it's not an elelgant solution but the css for the viewer is buried in the node module
    // where we don't have access to it
    this.getPdfViewContainer()?.style?.setProperty("overflow", "hidden");
  }

  initializeContent(): void {
    this.setupCanvases();
    
    // Create composite render loop necessary?
    setInterval(() => {
      this.updatePdfRender();
      var wb_ctx = this.getWhiteboardRenderCanvas()?.getContext("2d");
      if (wb_ctx) {
        this.updateWhiteboardRender(wb_ctx, this.getWhiteboardImageData());
      }
      this.drawComposite();
      }, 1000);
  }

  getPointerCanvas(): HTMLCanvasElement {
    return document.getElementById("pointer-render") as HTMLCanvasElement;
  }

  getPdfViewerElem(): HTMLElement {
    return document.querySelector("#content-area > pdf-viewer") as HTMLElement;
  }

  getPdfPageElem(): HTMLElement {
    return document.querySelector('#content-area > pdf-viewer > div > div > div') as HTMLElement;
  }

  getOutputCanvas(): HTMLCanvasElement {
    return document.getElementById('output-render') as HTMLCanvasElement;
  }

  getPdfCanvas(): HTMLCanvasElement {
    return document.querySelector('#content-area > pdf-viewer > div > div > div > div > canvas') as HTMLCanvasElement;
  }
  getPdfRenderCanvas(): HTMLCanvasElement {
    return document.getElementById('pdf-render') as HTMLCanvasElement;
  }
  getWhiteboardRenderCanvas(): HTMLCanvasElement {
    return document.getElementById('whiteboard-render') as HTMLCanvasElement;
  }
  getPdfDom(): HTMLElement {
    return document.querySelector('#content-area > pdf-viewer') as HTMLElement;
  }

  getWhiteboardDom(): HTMLElement {
    return document.querySelector('#content-area > ng-whiteboard') as HTMLElement;
  }
  getWhiteboardClickDom(): HTMLElement {
    return document.querySelector('#svgcontent') as HTMLElement;
  }
  getWhiteboardImageData(): SVGImageElement  {
    return document.querySelector('#content-area > ng-whiteboard > #svgroot > #svgcontent') as SVGImageElement;
  }

  getPdfViewContainer(): HTMLDivElement {
    return document.querySelector('#content-area > pdf-viewer > .ng2-pdf-viewer-container') as HTMLDivElement;
  }
  
  drawComposite(pdfUpdate:boolean = false): void {
    let output_canvas = this.getOutputCanvas();
    if (output_canvas) {
      var output_ctx = output_canvas.getContext("2d");
      if (output_ctx && output_canvas.width > 0 && output_canvas.height > 0) {
        output_ctx.clearRect(0, 0, output_canvas.width, output_canvas.height);
        output_ctx.drawImage(this.getPdfRenderCanvas(), 0, 0);
        output_ctx.drawImage(this.getWhiteboardRenderCanvas(), 0, 0);
        output_ctx.drawImage(this.getPointerCanvas(),0,0, this.width/this.zoomScale, this.height/this.zoomScale, 0, 0, output_ctx.canvas.width, output_ctx.canvas.height);
      }
    }
  }

  setupCanvases(): void {    
    this.getPointerCanvas().addEventListener('mousedown', (event) => {
      var new_event = new MouseEvent(event.type, event)
      if (this.selectedTool != ToolsEnum.SELECT) {
        this.getWhiteboardClickDom()?.dispatchEvent(new_event);
      }
      this.updatePointerPosition(event);
    });
    this.getPointerCanvas().addEventListener('drag', (event) => {
      var new_event = new MouseEvent(event.type, event)
      if (this.selectedTool != ToolsEnum.SELECT) {
        this.getWhiteboardClickDom()?.dispatchEvent(new_event);
      }
      this.updatePointerPosition(event);
    });
    this.getPointerCanvas().addEventListener('dragover', (event) => {
      var new_event = new MouseEvent(event.type, event)
      if (this.selectedTool != ToolsEnum.SELECT) {
        this.getWhiteboardClickDom()?.dispatchEvent(new_event);
      }
      this.updatePointerPosition(event);
    });
    this.getPointerCanvas().addEventListener('dragstart', (event) => {
      var new_event = new MouseEvent(event.type, event)
      if (this.selectedTool != ToolsEnum.SELECT) {
        this.getWhiteboardClickDom()?.dispatchEvent(new_event);
      }
      this.updatePointerPosition(event);
    });
    this.getPointerCanvas().addEventListener('dragend', (event) => {
      var new_event = new MouseEvent(event.type, event)
      if (this.selectedTool != ToolsEnum.SELECT) {
        this.getWhiteboardClickDom()?.dispatchEvent(new_event);
      }
      this.updatePointerPosition(event);
    });
    this.getPointerCanvas().addEventListener('click', (event) => {
      var new_event = new MouseEvent(event.type, event)
      if (this.selectedTool != ToolsEnum.SELECT) {
        this.getWhiteboardClickDom()?.dispatchEvent(new_event);
      }
      this.updatePointerPosition(event);
    });
    this.getPointerCanvas().addEventListener('mouseup', (event) => {
      var new_event = new MouseEvent(event.type, event)
      if (this.selectedTool != ToolsEnum.SELECT) {
        this.getWhiteboardClickDom()?.dispatchEvent(new_event);
      }
      this.updatePointerPosition(event);
    });
    this.getPointerCanvas().addEventListener('mousemove', (event) => {
      var new_event = new MouseEvent(event.type, event)
      this.getWhiteboardClickDom()?.dispatchEvent(new_event);
      this.updatePointerPosition(event);
    });

    this.getPointerCanvas().addEventListener('mouseout', (event) => {
      let pointer_canvas = this.getPointerCanvas();
      var context = pointer_canvas.getContext("2d");
      
      if (context) {
        context.clearRect(0, 0, pointer_canvas.width, pointer_canvas.height);
      }
      this.drawComposite();
    });
  }


  updatePointerPosition(event: MouseEvent) : void {
    let pointer_canvas = this.getPointerCanvas();
    var context = pointer_canvas.getContext("2d");
    var rect = pointer_canvas.getBoundingClientRect();
    var posx = event.clientX - rect.x;
    var posy = event.clientY - rect.y;
    if (context) {
      context.clearRect(0, 0, pointer_canvas.width, pointer_canvas.height);
      const imgPath: string = this.localizationService.getValueByPath(".contentShare_panel.cursorImage") || undefined
      if (imgPath) {
        this.cursorImage.src = imgPath;
        const cursorPx: number =  this.localizationService.getValueByPath(".contentShare_panel.cursorPx") || 16;
      
        context.drawImage(this.cursorImage, posx, posy, cursorPx, cursorPx);
      } else {
        // Draw diagonal double-quarter circle + dot reticle
        context.strokeStyle = "black";
        context.fillStyle = "black";
        context.beginPath();
        context.arc(posx, posy, 10, 0, Math.PI/2);
        context.stroke();
        context.beginPath();
        context.arc(posx, posy, 10, Math.PI, -Math.PI/2);
        context.stroke();
        context.beginPath();
        context.arc(posx, posy, 2, 0, 2 * Math.PI);
        context.fill();
      }
    }

    this.drawComposite();
  }

  
  sendCaptureStream(canvasFunc: Function) {
    if (!this.isSharingContent) {
      // resolve, if we somehow triggered this when not sharing.
      return Promise.resolve();
    }
    if (canvasFunc()?.width == this.renderWidth && canvasFunc()?.height == this.renderHeight) {
      let contentStream: MediaStream = canvasFunc().captureStream(3) as MediaStream;

      let contentTrack: MediaStreamTrack = contentStream.getTracks()[0];

      const constraints = {
        width: {min: this.renderWidth, ideal: this.renderWidth},
        height: {min:  this.renderHeight, ideal: this.renderHeight},
        advanced: [
          {width: this.renderWidth, height: this.height},
          {aspectRatio: this.width/this.height}
        ]
      };

      contentTrack.applyConstraints(constraints).then(() => {

        let contentTrackSettings: MediaTrackSettings = contentTrack.getSettings();

        console.log(JSON.stringify(contentTrackSettings));
        if (contentTrackSettings.width == this.renderWidth && contentTrackSettings.height == this.renderHeight) {
          Companion.getRTCService().shareContentStream(contentStream, this.shareFailed.bind(this));
          Promise.resolve();
        }
        else {
          setTimeout(() => {this.sendCaptureStream(canvasFunc)}, 250);
        }
      }).catch((e) => {
        setTimeout(() => {this.sendCaptureStream(canvasFunc)}, 250);
      });
    } else {
      setTimeout(() => {this.sendCaptureStream(canvasFunc)}, 250);
    }
  }

  private shareFailed(error: Error)
  {
    // just toggle off the content when we fail
    Dispatcher.dispatch(ActionType.ToggleContentShare);
  }

  /**
   * startContentShare
   */
   public startContentShare()
   {
    this.initializeContent();

    // Wait on the output canvas to be configured.
    setTimeout(() => {this.sendCaptureStream(this.getOutputCanvas)}, 250);
   }

   public stopContentShare()
   {
      // clear whitboard...
      this.clearDraw();
      // reset page number to 1
      this.pdfPage = 0;
      this.zoomScale = 1;
      this.viewOffsetX = 0;
      this.viewOffsetY = 0;
      this.height = this.renderHeight;
      this.width = this.renderWidth;
      //this.pdfSrc = null;
      //this.pdfComponent.clear();
      this.highlighterSelected = false;
      this.penSelected = false
      this.selectedTool = ToolsEnum.SELECT;
      // clear file input
      let fileInput = document.querySelector('#contentFileInput') as HTMLInputElement;
      this.fileSelected = false;

      if(fileInput)
      {
        fileInput.files = null;
        fileInput.value = null;
      }
      Companion.getRTCService().stopContentSharing();
   }

   fileUploaded(event: any)
   {
    this.fileSelected = true;
    let fileList: FileList = event.target.files;
    this.loading = true;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let objUrl = reader.result as string;
        if(this.pdfSrc === objUrl)
        {
          this.afterLoadComplete(this.pdfProxy);
        }
        else
        {
          this.pdfSrc = objUrl;
        }
      };
    }
   }

   applyPageSizeOnInit(pdfPageElemFunc: Function, pdfViewerElemFunc: Function) {
        console.log("Attempting to apply page size to PDF content...");
        // Init pdf size based on new page.
        if (pdfPageElemFunc()?.style?.width && pdfPageElemFunc()?.style?.height) {
          pdfViewerElemFunc().style.width = pdfPageElemFunc().style.width;
          pdfViewerElemFunc().style.height = pdfPageElemFunc().style.height;
          console.log("Applied!",  pdfViewerElemFunc().style.height, pdfViewerElemFunc().style.width);
          // here is where we can consider ourselves done loading
          this.loading = false;
          this.pdfComponent?.updateSize();
          this.updatePdfRender();
          this.startContentShare();
        }
        else {
          setTimeout(() => {this.applyPageSizeOnInit(pdfPageElemFunc, pdfViewerElemFunc)}, 250);
        }
    }

   // Read total pages after load.
   // and start content share.
   afterLoadComplete(pdf: PDFDocumentProxy) { 
    this.pdfProxy = pdf;
    this.pdfPage = 1;
    
    this.totalPages = pdf.numPages;
    // Hold for page to load.
    this.setWindowAroundPage(this.pdfPage);
    setTimeout(() => {this.applyPageSizeOnInit(this.getPdfPageElem, this.getPdfViewerElem)}, 250);
  }

  onError(error: any) {
    console.log("PDF_ERROR:%s", JSON.stringify(error))
  }

  panAndZoomButton()
  {
    this.showPanAndZoom = !this.showPanAndZoom;
  }

  showThumbnailsButton()
  {
    this.showThumbnails = !this.showThumbnails;
  }

  thumbnailHidden(pageNum: number) : boolean {
    if (pageNum && pageNum >= this.minThumb && pageNum <= this.maxThumb) {
      return false;
    } else {
      return true;
    }
  }

  thumbnailClicked(pageNum: number) {
    this.zoomScale = 1;
    this.viewOffsetX = 0;
    this.viewOffsetY = 0;
    this.whiteboardService.erase();
    console.log("You clicked page", pageNum);
    this.pdfPage = pageNum;
    setTimeout(() => {
      this.updatePdfRender();
      }, 250);
  };

  windowPrevSingle() {
    this.setWindowAroundPage(this.thumbWindowCenter-1);
  }

  windowNextSingle() {
    this.setWindowAroundPage(this.thumbWindowCenter+1);
  }

  windowPrevSet() {
    this.setWindowAroundPage(this.thumbWindowCenter-this.thumbSpread * 2 - 1);
  }

  windowNextSet() {
    this.setWindowAroundPage(this.thumbWindowCenter+this.thumbSpread * 2 + 1);
  }

  windowBegin() {
    this.setWindowAroundPage(1+this.thumbSpread);
  }

  windowEnd() {
    this.setWindowAroundPage(this.totalPages - this.thumbSpread);
  }

  setWindowAroundPage(pageNumber: number) {
    this.thumbWindowCenter = pageNumber;
    if (this.thumbWindowCenter < (1+this.thumbSpread)) {
      this.thumbWindowCenter = 1+this.thumbSpread;
    }
    if (this.thumbWindowCenter > (this.totalPages - this.thumbSpread)) {
      this.thumbWindowCenter = (this.totalPages - this.thumbSpread);
    }
    let minView = pageNumber - this.thumbSpread;
    let maxView = pageNumber + this.thumbSpread;

    if (minView <= 1) {
      minView = 1;
      maxView = minView + this.thumbSpread * 2;
    }

    if (maxView >= this.totalPages) {
      maxView = this.totalPages;
      minView = maxView - this.thumbSpread * 2;
    }

    this.minThumb = minView;
    this.maxThumb = maxView;
  }


  zoomOut() {
    this.whiteboardService.erase();
    if (this.zoomScale - 0.25 < 1) {
      this.zoomScale = 1;
      this.viewOffsetX = 0;
      this.viewOffsetY = 0;
    } else {
      this.zoomScale -= 0.25;
    }
    // hide scrollbars here, it's not an elelgant solution but the css for the viewer is buried in the node module
    // where we don't have access to it
    this.getPdfViewContainer()?.style?.setProperty("overflow", "hidden");
  }

  zoomIn() {
    this.whiteboardService.erase();
    this.zoomScale += 0.25;
    // hide scrollbars here, it's not an elelgant solution but the css for the viewer is buried in the node module
    // where we don't have access to it
    this.getPdfViewContainer()?.style?.setProperty("overflow", "hidden");
  }

  panUp() {
    this.whiteboardService.erase();
    let panAmount = this.panPixelScalar*(1/this.zoomScale);
    if (this.viewOffsetY - panAmount < 0) {
      this.viewOffsetY = 0;
    } else {
      this.viewOffsetY -= panAmount;
    }
    console.log(this.viewOffsetX, this.viewOffsetY);
    this.getPdfViewContainer().scroll(this.viewOffsetX, this.viewOffsetY);
  }

  panDown() {
    this.whiteboardService.erase();
    let panAmount = this.panPixelScalar*(1/this.zoomScale);
    let maxOffsetY = ((this.renderHeight*this.zoomScale) - this.renderHeight);
    console.log(this.viewOffsetY, panAmount, maxOffsetY);
    if ((this.viewOffsetY + panAmount) > maxOffsetY) {
      this.viewOffsetY = maxOffsetY;
    } else {
      this.viewOffsetY += panAmount;
    }
    console.log(this.viewOffsetX, this.viewOffsetY);
    this.getPdfViewContainer().scroll(this.viewOffsetX, this.viewOffsetY);
    
  }

  panLeft() {
    this.whiteboardService.erase();
    let panAmount = this.panPixelScalar*(1/this.zoomScale);
    if (this.viewOffsetX - panAmount < 0) {
      this.viewOffsetX = 0;
    } else {
      this.viewOffsetX -= panAmount;
    }
    console.log(this.viewOffsetX, this.viewOffsetY);
    this.getPdfViewContainer().scroll(this.viewOffsetX, this.viewOffsetY);
    
  }

  panRight() {
    this.whiteboardService.erase();
    let panAmount = this.panPixelScalar*(1/this.zoomScale);
    let maxOffsetX = ((this.renderWidth*this.zoomScale) - this.renderWidth);
    console.log(this.viewOffsetX, panAmount, maxOffsetX);
    if ((this.viewOffsetX + panAmount) > maxOffsetX) {
      this.viewOffsetX = maxOffsetX;
    } else {
      this.viewOffsetX += panAmount;
    }
    console.log(this.viewOffsetX, this.viewOffsetY);
    this.getPdfViewContainer().scroll(this.viewOffsetX, this.viewOffsetY);
  }
}